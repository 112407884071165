import React from "react";
import FormHeader from "../../../../Form/FormHeader";
import {PulseTable} from "pulse_table";
import {getAssignedStaffFromCalendarEntry} from "../../Helpers/availableShiftsListHelpers";
import {AvailableShiftItemProps} from "../Admin/ShiftsOverviewListTable";

const AssignedStaffMembersTable = (props: AvailableShiftItemProps) => {
    return (
        <React.Fragment>
            <div className="mt-4">
                <FormHeader headerName={"Assigned staff members"} />
                <PulseTable
                    items={toAssignedTableRow(props)}
                    headers={{
                        assignedMember: "Assigned Staff Member",
                        section: "Section",
                        group: "Group"
                    }}
                    noItemsSection={
                        <div className="row ml-0 mr-0 fadeIn">
                            <div className="col">
                                <h5 className="text-center p-3">
                                    There are no staff members assigned to the shift.
                                </h5>
                            </div>
                        </div>
                    }
                />
            </div>
        </React.Fragment>
    );
};

export default AssignedStaffMembersTable;

interface AssignedTableRow {
    assignedMember: string;
    section: string;
    group: string;
}

function toAssignedTableRow(props: AvailableShiftItemProps): AssignedTableRow[] {
    const assignedStaff = getAssignedStaffFromCalendarEntry(props.entry);

    return assignedStaff.map((item) => {
        return {
            assignedMember: item.user.staffName,
            section: item.section.name,
            group: item.group.name
        };
    });
}
