import {routeNames} from "../../../Navigation/routeNames";

export function getAttendanceRegisterPath(
    calendarName: string,
    calendarId: number,
    entryId: number,
    month?: number
) {
    return `${routeNames.calendar.path}/${encodeURI(calendarName)}${
        routeNames.attendanceRegister.path
    }?calendarId=${calendarId}&entryId=${entryId}${getMonthQueryString(month)}`;
}

export function getMonthQueryString(month?: number | string | null) {
    return month ? `&month=${+month}` : "";
}
