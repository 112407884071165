import {useContext} from "react";
import {ShiftOverviewContext} from "../Provider/ShiftOverviewProvider";

export const useShiftOverviewContext = () => {
    const overviewContext = useContext(ShiftOverviewContext);

    if (!overviewContext)
        throw Error(
            "Can't access context outside provider. Please ensure tree is wrapped in <ShiftOverviewProvider>"
        );

    return overviewContext;
};
