import React from "react";
import FormActionContainer from "../../Form/FormActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../Button/MCButton";
import {Modal, useModal} from "pulse-modal";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../store/Store";
import {SMSFormDetails} from "../../../store/sms/actions/SMSActionTypes";
import {showErrorToast, showSuccessToast} from "../../../utils/toastUtils";
import {sendMassSms} from "../../../store/sms/actions/SMSActions";
import SMSStaffListButton from "./SMSStaffListButton";

const SMSActions = ({onClose}: SMSModalConfirmationProps) => {
    const dispatch = useDispatch();
    const {toggle, isShown} = useModal();
    const smsStore = useSelector(
        (state: RootStore) =>
            state.sms.data || {staffMembers: [], bodyText: "", sendToEventsStaff: true}
    );

    const sendSMS = async () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(sendMassSms(smsStore));
        if (!success) {
            showErrorToast("Could not sms bulk SMS");
            return;
        }
        toggle();
        onClose();
        showSuccessToast(`Sent SMS to ${smsStore.staffMembers.length} staff member(s)!`);
    };

    return (
        <React.Fragment>
            <FormActionContainer>
                <MCButton
                    size={ButtonSize.Large}
                    innerValue={"Send"}
                    onClick={() => {
                        const valid = validateSMS(smsStore);
                        if (!valid) return;
                        toggle();
                    }}
                    colour={ButtonColourOptions.Yellow}
                    roundedCorner
                />
                <MCButton
                    size={ButtonSize.Large}
                    innerValue={"Cancel"}
                    onClick={onClose}
                    colour={ButtonColourOptions.DarkBlue}
                    roundedCorner
                />
                <SMSStaffListButton />
            </FormActionContainer>
            <Modal
                modalSize={"sm"}
                title={"SMS Confirmation"}
                bodyChildren={
                    <div className="row ml-0 mr-0">
                        <p className="mb-0">Are you sure you want to sent this SMS?</p>
                    </div>
                }
                onClose={toggle}
                isShown={isShown}
                footerChildren={
                    <div className="ml-0 mr-0">
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Yes"}
                            onClick={sendSMS}
                            colour={ButtonColourOptions.Yellow}
                            roundedCorner
                        />
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Cancel"}
                            onClick={toggle}
                            colour={ButtonColourOptions.DarkBlue}
                            roundedCorner
                        />
                    </div>
                }
            />
        </React.Fragment>
    );
};

export default SMSActions;

export interface SMSModalConfirmationProps {
    onClose: () => void;
}

function validateSMS(sms: SMSFormDetails): boolean {
    if (sms.staffMembers.length === 0) {
        showErrorToast("Please select a group to send an SMS to!");
        return false;
    }

    if (sms.bodyText.length === 0) {
        showErrorToast("Please enter SMS body!");
        return false;
    }

    if (sms.bodyText.length > 154) {
        showErrorToast("SMS body is too long! Please ensure message is 154 characters or less!");
        return false;
    }

    return true;
}
