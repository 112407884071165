import React, {useEffect} from "react";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../Navigation/routeNames";
import {useDispatch, useSelector} from "react-redux";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {RootStore} from "../../../store/Store";
import {CalendarEntryListRequest} from "../../../api/grs";
import {usePageUrl} from "../../Hooks/usePageUrl";
import {getCalendarEntriesList} from "../../../store/calendarEntryList/actions/CalendarEntryListActions";
import AttendanceOverviewTable from "./Components/AttendanceOverviewTable";
import {WithServiceState} from "store-fetch-wrappers/dist";
import {useCalendarPageDetails} from "../../Hooks/useCalendarPageDetails";
import {getCalendarPathForCalendarChildRoutes, getVenuesListPath} from "../VenueList/VenueList";
import FilterContainer from "../../Filters/FilterContainer";
import AttendanceOverviewFilters from "./Components/AttendanceOverviewFilters";
import {getEventReportPath} from "../Reports/EventReport/Helpers/eventReportHelpers";

const AttendanceOverviewServiceWrapper = WithServiceState(AttendanceOverviewTable);

const AttendanceOverview = () => {
    const dispatch = useDispatch();
    const calendarEntryListStore = useSelector((state: RootStore) => state.calendarEntryList);
    const {fullPath} = usePageUrl();
    const {calendarName, calendarId} = useCalendarPageDetails();
    useEffect(() => {
        setupRoutes();
    }, []);

    /** Sets up routes for the venues page. */
    const setupRoutes = () => {
        if (!calendarId) return;
        const navigationItems: NavigationItem[] = [
            {
                name: routeNames.groupRosteringSystem.name,
                path: routeNames.groupRosteringSystem.path
            },
            {
                name: calendarName,
                path: getCalendarPathForCalendarChildRoutes(calendarName, +calendarId)
            },
            {
                name: routeNames.eventReport.name,
                path: getEventReportPath(calendarName, +calendarId)
            },
            {
                name: routeNames.venueList.name,
                path: getVenuesListPath(calendarName, +calendarId)
            },
            {
                name: routeNames.attendanceOverview.name,
                path: fullPath
            }
        ];

        dispatch(setNavigationItems(navigationItems));
    };

    /** Gets a list of entries for the calendar */
    const onEntryListRequestChanged = (request: CalendarEntryListRequest) => {
        dispatch(getCalendarEntriesList(request));
    };
    return (
        <React.Fragment>
            <div className="page-container">
                <FilterContainer closed={false}>
                    <AttendanceOverviewFilters onRequestChanged={onEntryListRequestChanged} />
                </FilterContainer>
                <AttendanceOverviewServiceWrapper
                    showLoadingText={true}
                    loaderType={"overlay"}
                    loaderWheelType={"three-ring"}
                    placeholderBlockCount={1}
                    {...calendarEntryListStore}
                />
            </div>
        </React.Fragment>
    );
};

export default AttendanceOverview;
