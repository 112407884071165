import React, {useEffect} from "react";
import {routeNames} from "../../../../Navigation/routeNames";
import {nullifyCalendarEntryStore} from "../../../../../store/calendarEntry/actions/CalendarEntryActions";
import {
    getDutyManagerReportByReportId,
    nullifyDutyManagerReportStore
} from "../../../../../store/dutyManagerReport/actions/DutyManagerReportActions";
import {useDispatch, useSelector} from "react-redux";
import {useQuery} from "../../../../Hooks/useQuery";
import {useParams} from "react-router-dom";
import {usePageUrl} from "../../../../Hooks/usePageUrl";
import {NavigationItem} from "../../../../../store/navigation/actions/NavigationActionTypes";
import {WithServiceState} from "store-fetch-wrappers";
import {DutyManagerReportEditableContext} from "./Context/DutyManagerReportEditable";
import {RootStore} from "../../../../../store/Store";
import EditDutyManagerReportForm from "./Components/EditDutyManagerReportForm";
import {setNavigationItems} from "../../../../../store/navigation/actions/NavigationActions";
const DutyManagerReportFormServiceWrapper = WithServiceState(EditDutyManagerReportForm);

const EditDutyManagerReport = () => {
    const dispatch = useDispatch();
    const query = useQuery();
    const params: any = useParams();
    const {fullPath} = usePageUrl();
    const dutyManagerReportStore = useSelector((state: RootStore) => state.dutyManagerReport);

    useEffect(() => {
        const calendarEntryId = query.get("calendarEntryId");
        if (!calendarEntryId) return;
        dispatch(getDutyManagerReportByReportId(+params.reportId, +calendarEntryId));

        const redirectUri = localStorage.getItem("calendar_entry_redirect_uri");
        const redirectItem = redirectUri
            ? {
                  name: routeNames.editCalendarEntry.name,
                  path: redirectUri
              }
            : {
                  name: routeNames.dutyManagerReportList.name,
                  path: routeNames.dutyManagerReportList.path
              };

        const navItems: NavigationItem[] = [
            {
                name: routeNames.groupRosteringSystem.name,
                path: routeNames.groupRosteringSystem.path
            },
            redirectItem,
            {
                name: routeNames.editDutyManagerReport.name,
                path: fullPath
            }
        ];

        dispatch(setNavigationItems(navItems));
        return function () {
            dispatch(nullifyDutyManagerReportStore());
            dispatch(nullifyCalendarEntryStore());
        };
    }, []);
    return (
        <React.Fragment>
            <DutyManagerReportEditableContext.Provider value={true}>
                <DutyManagerReportFormServiceWrapper
                    showLoadingText={true}
                    placeholderBlockCount={1}
                    loaderType={"overlay"}
                    loaderWheelType={"three-ring"}
                    {...dutyManagerReportStore}
                />
            </DutyManagerReportEditableContext.Provider>
        </React.Fragment>
    );
};

export default EditDutyManagerReport;
