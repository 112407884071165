import React from "react";
import {CalendarEntry} from "../../../../../../api/grs";
import FormRow from "../../../../../Form/FormRow";

const ReadOnlyEntryNotes = (props: CalendarEntry) => {
    return (
        <React.Fragment>
            <FormRow rowName={"Entry Notes"}>
                <p className="mb-0">{props.notes ? props.notes : "Notes were not added"}</p>
            </FormRow>
        </React.Fragment>
    );
};

export default ReadOnlyEntryNotes;
