import React from "react";
import {AvailableShiftEntrySectionProps} from "../../../../../AvailableShifts/Calendar/Components/AvailableShiftEntrySection";
import {useAvailableShiftsInfoUtils} from "../../../../../AvailableShifts/Calendar/Hooks/useAvailableShiftsInfoUtils";
import AvailableRolesGroup from "./AvailableRolesGroup";

function AvailableRolesSection(props: AvailableShiftEntrySectionProps) {
    const {showSection} = useAvailableShiftsInfoUtils();
    return (
        <React.Fragment>
            {showSection(props.section) && (
                <div className="available-shift-calendar-info-wrapper">
                    {props.section.groupings.map((group, index) => {
                        return (
                            <AvailableRolesGroup
                                section={props.section}
                                entry={props.entry}
                                group={group}
                                key={index}
                                callback={props.callback}
                            />
                        );
                    })}
                </div>
            )}
        </React.Fragment>
    );
}

export default AvailableRolesSection;
