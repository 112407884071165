import {useQuery} from "../../../Hooks/useQuery";
import {getActiveShiftViewFromString} from "../../../../utils/enumUtils";
import moment, {Moment} from "moment/moment";
import {StaffInterestQueryFields} from "../../../../api/grs";
import {
    ActiveShiftView,
    ShiftOverviewFilters,
    shiftOverviewQuery
} from "../Provider/ShiftOverviewProvider";
import {useUserModuleAccessLevel} from "../../../Hooks/useUserModuleAccessLevel";
import {StaffAccessLevel} from "../../../../api/staff";

export function useShiftOverviewRequestBuilder() {
    const query = useQuery();
    const moduleAccessLevel = useUserModuleAccessLevel();
    function getStaffInterestQueryRequest() {
        const view = query.get(shiftOverviewQuery.view);
        const currentView = view
            ? getActiveShiftViewFromString(view)
            : ActiveShiftView.ShiftOverview;
        const filters = getPayloadFromView(currentView);

        return {
            request: getRequestFromFilters(filters),
            filters
        };
    }

    function getPayloadFromView(view: ActiveShiftView): ShiftOverviewFilters {
        switch (moduleAccessLevel) {
            case StaffAccessLevel.SystemAdministrator:
                return getAdminPayload(view);
            default:
                return getStaffPayload();
        }
    }

    function getStaffPayload(): ShiftOverviewFilters {
        const month = query.get(shiftOverviewQuery.month);
        return {
            view: ActiveShiftView.BulkAccept,
            month: month ? moment.unix(+month) : moment()
        };
    }

    function getAdminPayload(view: ActiveShiftView): ShiftOverviewFilters {
        const day = query.get(shiftOverviewQuery.day);
        const week = query.get(shiftOverviewQuery.week);
        switch (view) {
            case ActiveShiftView.ShiftOverview:
                return {
                    view,
                    day: day ? moment.unix(+day) : moment()
                };
            case ActiveShiftView.BulkAccept:
                return {
                    view,
                    week: week
                        ? moment.unix(+week).clone().startOf("isoWeek")
                        : moment().clone().startOf("isoWeek")
                };
        }
    }
    function getRequestFromFilters(entity: ShiftOverviewFilters): StaffInterestQueryFields {
        //Staff member is accessing it
        if (moduleAccessLevel !== StaffAccessLevel.SystemAdministrator) {
            return getMonthDateRange(entity.month);
        }
        switch (entity.view) {
            case ActiveShiftView.BulkAccept:
                return getWeekDateRange(entity.week);
            case ActiveShiftView.ShiftOverview:
                return getDayDateRange(entity.day);
        }
    }

    function getMonthDateRange(quarter?: Moment): StaffInterestQueryFields {
        if (!quarter) {
            const now = moment();
            return {
                startDate: now.clone().startOf("month").unix(),
                endDate: now.clone().endOf("month").unix()
            };
        }

        return {
            startDate: quarter.clone().startOf("month").unix(),
            endDate: quarter.clone().endOf("month").unix()
        };
    }

    function getWeekDateRange(week?: Moment): StaffInterestQueryFields {
        if (!week) {
            const now = moment();
            return {
                startDate: now.clone().startOf("isoWeek").unix(),
                endDate: now.clone().endOf("isoWeek").unix()
            };
        }

        return {
            startDate: week.clone().startOf("isoWeek").unix(),
            endDate: week.clone().endOf("isoWeek").unix()
        };
    }

    function getDayDateRange(day?: Moment): StaffInterestQueryFields {
        if (!day) {
            const now = moment();
            return {
                startDate: now.clone().startOf("day").unix(),
                endDate: now.clone().endOf("day").unix()
            };
        }

        return {
            startDate: day.clone().startOf("day").unix(),
            endDate: day.clone().endOf("day").unix()
        };
    }

    return {
        getStaffInterestQueryRequest
    };
}
