import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {useQuery} from "../../Hooks/useQuery";
import {getCalendar, nullifyCalendarStore} from "../../../store/calendar/actions/CalendarActions";
import {RootStore} from "../../../store/Store";
import {WithServiceState} from "store-fetch-wrappers";
import EditCalendarForm from "./Components/EditCalendarForm";
import {useXeroRoutes} from "../Xero/Hooks/useXeroRoutes";

const ServiceWrapper = WithServiceState(EditCalendarForm);

/** Parent Component to edit a calendar */
const EditCalendar = () => {
    const dispatch = useDispatch();
    const query = useQuery();

    const calendarStore = useSelector((state: RootStore) => state.calendar);
    const {getSystemAdminRoutes} = useXeroRoutes();

    useEffect(() => {
        window.scrollTo({top: 0});
        dispatch(setNavigationItems(getSystemAdminRoutes()));

        //Get the query string, get the calendar if it exists.
        const id = query.get("id");
        if (!id) return;

        dispatch(getCalendar(+id));

        return function () {
            //Flush stale data out of the store once this component has been unmounted
            dispatch(nullifyCalendarStore());
        };
    }, []);

    return (
        <ServiceWrapper
            showLoadingText={true}
            placeholderBlockCount={1}
            loaderType={"overlay"}
            loaderWheelType={"three-ring"}
            {...calendarStore}
        />
    );
};

export default EditCalendar;
