import {
    CalendarSummaryStats,
    CalendarTypeEnum,
    EventsReportEntry,
    StaffReportEntry,
    StaffWageReportEntry
} from "../api/grs";
import {formatUnixToDDMMYYYY, formatUnixToDDMMYYYYHHmmss, formatUnixToHHmm} from "./momentUtils";
import {getUiFriendlyText} from "./textUtils";

/** Base interface for downloadable reports. */
interface BaseData {
    calendarName?: string;
    entryName?: string;
    startDate: string;
    endDate: string;
}

/** For the staff report. Extends the base data */
export interface StaffReportDataStructure extends BaseData {
    wage: string;
    state: string;
}

/** For the staff wage report. Extends the base data */
export interface StaffWageReportDataStructure extends BaseData {
    staffMember: string;
    startTime: string;
    endTime: string;
    billing: string;
    hoursWorked: string;
    rate: string;
    amount: string;
}

/** For the events report. Extends the base data */
interface EventsReportDataStructure extends BaseData {
    sections: string;
}

interface EventSections {
    sectionName: string;
    groups: EventGroups[];
}

interface EventGroups {
    groupName: string;
    assignments: EventAssignments[];
}

interface EventAssignments {
    staffName: string;
    state: string;
}

/** Returns the venue name or the entry name depending on if the calendar is a frontline or event calendar */
export function getEntryOrVenueName(
    entry: StaffReportEntry,
    summaries: CalendarSummaryStats[]
): string {
    const name = entry.venueName ? entry.venueName : entry.entryName;

    const index = summaries.findIndex(
        (el: CalendarSummaryStats) => el.summary.id === entry.calendarId
    );

    if (index < 0) return name;

    const calendar = summaries[index].summary;

    if (calendar.calendarType === CalendarTypeEnum.Frontline) {
        return entry.entryName;
    }

    return name;
}

/** Generates the structure for the staff assignment report file */
export function staffAssignmentReportFile(
    entries: StaffReportEntry[],
    calendarSummaries: CalendarSummaryStats[]
): StaffReportDataStructure[] {
    const structure: StaffReportDataStructure[] = [];

    for (const entry of entries) {
        const startDate = getStartDateForStaffAssignment(entry);
        const endDate = getEndDateForStaffAssignment(entry);
        const state = getUiFriendlyText(entry.state);

        structure.push({
            calendarName: entry.calendarName,
            entryName: getEntryOrVenueName(entry, calendarSummaries),
            startDate,
            endDate,
            state,
            wage: `£${entry.wage}`
        });
    }

    return structure;
}

function getStartDateForStaffAssignment(entry: StaffReportEntry): string {
    if (entry.actualStartDate) {
        return formatUnixToDDMMYYYYHHmmss(entry.actualStartDate);
    }
    return formatUnixToDDMMYYYYHHmmss(entry.entryStartDate);
}

function getEndDateForStaffAssignment(entry: StaffReportEntry): string {
    if (entry.actualEndDate) {
        return formatUnixToDDMMYYYYHHmmss(entry.actualEndDate);
    }
    return formatUnixToDDMMYYYYHHmmss(entry.entryEndDate);
}

/** Generates the structure for the staff wage report file */
export function wageReportFile(entries: StaffWageReportEntry[]): StaffWageReportDataStructure[] {
    let structure: StaffWageReportDataStructure[] = [];

    for (const entry of entries) {
        const startDate = formatUnixToDDMMYYYY(entry.startDate);
        const endDate = formatUnixToDDMMYYYY(entry.endDate);

        if (entry.staff.length === 0) {
            structure.push({
                staffMember: "",
                calendarName: entry.calendarName,
                startDate,
                endDate,
                startTime: formatUnixToHHmm(entry.startDate),
                endTime: formatUnixToHHmm(entry.endDate),
                billing: entry.billingType,
                hoursWorked: "",
                rate: "",
                amount: ""
            });
            continue;
        }
        for (const assignment of entry.staff) {
            structure.push({
                staffMember: assignment.staffName,
                calendarName: entry.calendarName,
                startDate,
                endDate,
                startTime: formatUnixToHHmm(assignment.startDate),
                endTime: formatUnixToHHmm(assignment.endDate),
                billing: entry.billingType,
                hoursWorked: assignment.numHours.toFixed(2).toString(),
                rate: assignment.assignmentRate.toString(),
                amount: assignment.wage.toFixed(2).toString()
            });
        }

        //Sort alphabetically
        structure = structure.sort(function (a, b) {
            if (a.staffMember < b.staffMember) {
                return -1;
            }

            if (a.staffMember > b.staffMember) {
                return 1;
            }
            return 0;
        });
    }
    return structure;
}

// function getStartTimeForWageReport(
//     entry: StaffWageReportEntry,
//     assignment: StaffWageReportEntryStaffMember
// ): string {}

/** Generates the structure for the events report file */
export function eventReportFile(
    entries: EventsReportEntry[],
    calendarName: string
): EventsReportDataStructure[] {
    const structure: EventsReportDataStructure[] = [];
    for (const entry of entries) {
        const startDate = formatUnixToDDMMYYYYHHmmss(entry.startDate);
        const endDate = formatUnixToDDMMYYYYHHmmss(entry.endDate);

        const sections: EventSections[] = [];

        for (const section of entry.sections) {
            const groups: EventGroups[] = [];

            for (const group of section.groups) {
                const assignments: EventAssignments[] = [];

                for (const assignment of group.assignments) {
                    const state = getUiFriendlyText(assignment.state);
                    assignments.push({
                        staffName: assignment.staffName || "Assignments not made",
                        state
                    });
                }

                groups.push({
                    groupName: group.groupName,
                    assignments
                });
            }

            sections.push({
                sectionName: section.sectionName,
                groups
            });
        }
        //Yes, I know what this does.....
        const sectionsObj = JSON.stringify(sections)
            .replace(/['"]+/g, "")
            .replace(/[[\]']+/g, "")
            .split(":")
            .join(": ")
            .split(",")
            .join(", ");

        structure.push({
            calendarName,
            startDate,
            endDate,
            sections: sectionsObj
        });
    }

    return structure;
}
