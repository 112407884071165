import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../store/Store";
import {setNavigationItems} from "../../../../store/navigation/actions/NavigationActions";
import {useParams} from "react-router-dom";
import {getCalendarGroup} from "../../../../store/calendarGroup/actions/CalendarGroupActions";

import {WithServiceState} from "store-fetch-wrappers/dist";
import EditCalendarGroupForm from "./Components/EditCalendarGroupForm";
import {useXeroRoutes} from "../../Xero/Hooks/useXeroRoutes";

const ServiceWrapper = WithServiceState(EditCalendarGroupForm);
function EditCalendarGroups() {
    const dispatch = useDispatch();
    const calendarGroupStore = useSelector((state: RootStore) => state.calendarGroup);
    const params: any = useParams();

    const {getSystemAdminRoutes} = useXeroRoutes();

    useEffect(() => {
        window.scrollTo({top: 0});
        dispatch(setNavigationItems(getSystemAdminRoutes()));

        dispatch(getCalendarGroup(params.id));
    }, []);
    return (
        <React.Fragment>
            <div className="page-container mt-4">
                <ServiceWrapper
                    loaderWheelType={"three-ring"}
                    loaderType={"overlay"}
                    showLoadingText={false}
                    {...calendarGroupStore}
                />
            </div>
        </React.Fragment>
    );
}

export default EditCalendarGroups;
