import {CalendarEntry} from "../../../api/grs";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const OVERLAPPING_ENTRY_LIST_STORE_STATE = createStoreState("overlapping_entry_list");

interface OverlappingEntryListStoreLoading extends StoreServiceData<CalendarEntry[]> {
    type: typeof OVERLAPPING_ENTRY_LIST_STORE_STATE.LOADING;
}

interface OverlappingEntryListStoreError extends StoreServiceData<CalendarEntry[]> {
    type: typeof OVERLAPPING_ENTRY_LIST_STORE_STATE.ERROR;
}

interface OverlappingEntryListStoreSuccess extends StoreServiceData<CalendarEntry[]> {
    type: typeof OVERLAPPING_ENTRY_LIST_STORE_STATE.SUCCESS;
}

export type OverlappingEntryListDispatchTypes =
    | OverlappingEntryListStoreLoading
    | OverlappingEntryListStoreError
    | OverlappingEntryListStoreSuccess;
