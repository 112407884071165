import React from "react";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../Button/MCButton";
import {Modal, useModal} from "pulse-modal";

const ReserveUser = (props: Props) => {
    const {isShown, toggle} = useModal();
    return (
        <React.Fragment>
            <MCButton
                size={ButtonSize.Large}
                innerValue={"Reserve"}
                onClick={toggle}
                colour={ButtonColourOptions.LightBlue}
                roundedCorner
            />
            <Modal
                modalSize={"sm"}
                title={"Add To Reserve List"}
                bodyChildren={
                    <React.Fragment>
                        <div className="row ml-0 mr-0">
                            <p className="mb-0">
                                Are you sure you want to add this staff member to the reserve list?
                            </p>
                        </div>
                    </React.Fragment>
                }
                footerChildren={
                    <React.Fragment>
                        <div className="row ml-0 mr-0">
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"Yes"}
                                onClick={async () => {
                                    toggle();
                                    await props.onConfirm();
                                }}
                                colour={ButtonColourOptions.Yellow}
                                roundedCorner
                            />
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"Cancel"}
                                onClick={toggle}
                                roundedCorner
                                colour={ButtonColourOptions.DarkBlue}
                            />
                        </div>
                    </React.Fragment>
                }
                onClose={toggle}
                isShown={isShown}
            />
        </React.Fragment>
    );
};

export default ReserveUser;

interface Props {
    onConfirm: () => Promise<void>;
}
