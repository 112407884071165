import React from "react";
import {CalendarMonthSummaryStats} from "../../../../../api/grs";
import EventCalendarStackedBarChart from "./BarChart/EventCalendarStackedBarChart";

const EventCalendarFutureEventStats = ({monthStats, barLimit}: Props) => {
    const containsEvent = () => {
        return monthStats.length > 0;
    };
    return (
        <React.Fragment>
            {containsEvent() ? (
                <React.Fragment>
                    <h6 className="body-font mt-2">Upcoming Months</h6>
                    {monthStats.map((item, index) => {
                        if (index <= barLimit - 1) {
                            return <EventCalendarStackedBarChart key={index} {...item} />;
                        }
                    })}
                </React.Fragment>
            ) : (
                <h6 className="body-font mt-2 text-center">
                    There Are No Upcoming Events Scheduled For The Future!{" "}
                </h6>
            )}
        </React.Fragment>
    );
};

export default EventCalendarFutureEventStats;

interface Props {
    monthStats: CalendarMonthSummaryStats[];
    barLimit: number;
}
