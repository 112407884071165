import {
    AssignmentState,
    CalendarEntry,
    CalendarTypeEnum,
    StaffAssignment,
    StaffAssignmentGrouping,
    StaffBlockSection,
    UserInterest,
    Venue
} from "../../../../api/grs";
import {formatUnixToHHmmddd} from "../../../../utils/momentUtils";
import {ClinicalGrade, UserData} from "../../../../api/staff";
import {showErrorToast} from "../../../../utils/toastUtils";

/** Gets location name for shift */
export function getLocationName(
    entry: CalendarEntry,
    venues: Array<Venue> | undefined | null
): string {
    if (!venues) return "New Calendar Entry";
    switch (entry.calendarType) {
        case CalendarTypeEnum.Event:
            if (!venues) return "New Calendar Entry";
            // eslint-disable-next-line no-case-declarations
            const venue = venues.find((el: Venue) => el.id === entry.venueId);
            if (!venue) return "New Calendar Entry";
            return venue.name;
        case CalendarTypeEnum.Frontline:
            return entry.description ? entry.description : "New Calendar Entry";
    }
    return "New Calendar Entry";
}

export interface AssignedElsewhere {
    assigned: boolean;
    location: string;
}

/** Gets the count of the slots taken in an entry */
export function getTakenSpaces(entry: CalendarEntry) {
    let takenCount = 0;
    for (const section of entry.requiredStaff.sections) {
        for (const group of section.groupings) {
            const filteredAssignments = group.assignments.filter(
                (el: StaffAssignment) => el.state !== AssignmentState.Unassigned
            );

            takenCount += filteredAssignments.length;
        }
    }

    return takenCount;
}

/** Gets the count of the free slots in an entry */
export function getAvailableSpaces(entry: CalendarEntry) {
    let availableSpaceCount = 0;
    for (const section of entry.requiredStaff.sections) {
        for (const group of section.groupings) {
            const filteredAssignments = group.assignments.filter(
                (el: StaffAssignment) => el.state === AssignmentState.Unassigned
            );

            availableSpaceCount += filteredAssignments.length;
        }
    }

    return availableSpaceCount;
}

/** Gets the formatted shift times*/
export function getShiftTimes(entry: CalendarEntry) {
    const formattedStartTime = formatUnixToHHmmddd(entry.startDate);
    const formattedEndTime = formatUnixToHHmmddd(entry.endDate);

    return `${formattedStartTime} - ${formattedEndTime}`;
}

/** Gets the count of the free slots in an entry */
export function getFilteredInterest(entry: CalendarEntry, interestList: UserInterest[]) {
    return interestList.filter((interest: UserInterest) => interest.entryId === entry.id).length;
}

export function getAssignedStaffFromCalendarEntry(entry: CalendarEntry) {
    const assigned: UserInterest[] = [];
    for (const section of entry.requiredStaff.sections) {
        for (const group of section.groupings) {
            for (const assignment of group.assignments) {
                if (assignment.staffMember && assignment.staffMember.staffId.length > 0) {
                    assigned.push({
                        entryId: entry.id,
                        user: assignment.staffMember,
                        section: {
                            id: section.id,
                            name: section.name
                        },
                        group: {
                            id: group.name,
                            name: group.name
                        },
                        reserveNotification: false
                    });
                }
            }
        }
    }
    return assigned;
}

export function canUserBeAssigned(
    entry: CalendarEntry,
    userInterest: UserInterest,
    staffList: UserData[]
): boolean {
    const {requiredStaff} = entry;

    //Check for clinical grade
    const userClinicalGrade = getUserClinicalGrade(staffList, userInterest.user.staffId);
    if (!userClinicalGrade) return false;

    switch (userClinicalGrade) {
        case ClinicalGrade.FirstResponder:
            return validateFirstResponder(requiredStaff.sections, userInterest);
        case ClinicalGrade.EAC:
        case ClinicalGrade.Paramedic:
        case ClinicalGrade.Technician:
        case ClinicalGrade.Nurse:
        case ClinicalGrade.None:
            return validateStaff(requiredStaff.sections, userInterest, userClinicalGrade);
    }
}

function validateFirstResponder(
    sections: StaffBlockSection[],
    userInterest: UserInterest
): boolean {
    const group = getGroup(sections, userInterest);
    if (!group) return false;

    //Get the first assignment possible in the group.
    const firstAvailableAssignment = group.assignments.filter(
        (el: StaffAssignment) => el.state === AssignmentState.Unassigned
    )[0];

    return !!firstAvailableAssignment;
}

function validateStaff(
    sections: StaffBlockSection[],
    userInterest: UserInterest,
    clinicalGrade: ClinicalGrade
): boolean {
    const group = getGroup(sections, userInterest);
    if (!group) return false;

    //Get the first assignment possible in the group.
    const firstAvailableAssignment = getFirstUnassignedAssignment(group);

    // If there is space, we assign the user to their respective group
    if (firstAvailableAssignment) {
        return true;
    }

    const nextAvailableAssignment = checkForNextAvailableAssignment(
        sections,
        clinicalGrade,
        0,
        userInterest
    );

    return !!nextAvailableAssignment;
}

/** Validates assignment for user interest */
export function validateAssignmentInEntry(
    entry: CalendarEntry,
    userInterest: UserInterest,
    assignedRate: number,
    staffList: UserData[]
): StaffAssignment | null | undefined {
    const {requiredStaff} = entry;

    //Check for clinical grade
    const userClinicalGrade = getUserClinicalGrade(staffList, userInterest.user.staffId);
    if (!userClinicalGrade) return;

    switch (userClinicalGrade) {
        case ClinicalGrade.FirstResponder:
            return assignFirstResponder(requiredStaff.sections, userInterest, assignedRate);
        case ClinicalGrade.EAC:
        case ClinicalGrade.Paramedic:
        case ClinicalGrade.Technician:
        case ClinicalGrade.Nurse:
        case ClinicalGrade.None:
            return assignStaffToShift(
                requiredStaff.sections,
                userInterest,
                assignedRate,
                userClinicalGrade
            );
    }
}

export function getUserClinicalGrade(
    staffList: UserData[],
    userName: string
): ClinicalGrade | undefined {
    const index = staffList.findIndex((user: UserData) => user.username === userName);

    return staffList[index] ? staffList[index].clinicalGrade : undefined;
}

function assignStaffToShift(
    sections: StaffBlockSection[],
    userInterest: UserInterest,
    assignedRate: number,
    clinicalGrade: ClinicalGrade
): StaffAssignment | null | undefined {
    const group = getGroup(sections, userInterest);
    if (!group) return;

    //Get the first assignment possible in the group.
    const firstAvailableAssignment = getFirstUnassignedAssignment(group);

    // If there is space, we assign the user to their respective group
    if (firstAvailableAssignment) {
        //Create a new assignment with some of the old details
        return {
            ...firstAvailableAssignment,
            rate: assignedRate,
            staffMember: userInterest.user,
            state: AssignmentState.WaitingAccept
        };
    }

    const nextAvailableAssignment = checkForNextAvailableAssignment(
        sections,
        clinicalGrade,
        assignedRate,
        userInterest
    );

    if (nextAvailableAssignment) {
        return nextAvailableAssignment;
    }
    showErrorToast(
        "There are no available spaces for this staff member in matching groups, contact system administrator"
    );
    return;
}

function checkForNextAvailableAssignment(
    sections: StaffBlockSection[],
    clinicalGrade: ClinicalGrade,
    assignedRate: number,
    userInterest: UserInterest
): StaffAssignment | null | undefined {
    for (const section of sections) {
        for (const group of section.groupings) {
            const index = group.clinicalGrades.findIndex(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                (grade: ClinicalGrade) => grade === clinicalGrade
            );
            // Clinical Grade does not match what we are asking for
            if (index < 0) continue;

            const firstAvailableAssignment = getFirstUnassignedAssignment(group);

            if (firstAvailableAssignment) {
                return {
                    ...firstAvailableAssignment,
                    rate: assignedRate,
                    staffMember: userInterest.user,
                    state: AssignmentState.WaitingAccept
                };
            }
        }
    }
}

function getFirstUnassignedAssignment(group: StaffAssignmentGrouping): StaffAssignment | undefined {
    return group.assignments.filter(
        (el: StaffAssignment) => el.state === AssignmentState.Unassigned
    )[0];
}

function assignFirstResponder(
    sections: StaffBlockSection[],
    userInterest: UserInterest,
    assignedRate: number
): StaffAssignment | null | undefined {
    const group = getGroup(sections, userInterest);
    if (!group) return;

    //Get the first assignment possible in the group.
    const firstAvailableAssignment = group.assignments.filter(
        (el: StaffAssignment) => el.state === AssignmentState.Unassigned
    )[0];
    if (!firstAvailableAssignment) {
        showErrorToast("This group is full, contact system administrator");
        return;
    }

    //Create a new assignment with some of the old details
    return {
        ...firstAvailableAssignment,
        rate: assignedRate,
        staffMember: userInterest.user,
        state: AssignmentState.WaitingAccept
    };
}

function getGroup(
    sections: StaffBlockSection[],
    userInterest: UserInterest
): StaffAssignmentGrouping | undefined {
    //Look for the section the user requested
    const sectionIndex = sections.findIndex(
        (el: StaffBlockSection) => el.id === userInterest.section.id
    );
    if (sectionIndex < 0) {
        return;
    }
    const section = sections[sectionIndex];

    const groupIndex = section.groupings.findIndex(
        (el: StaffAssignmentGrouping) => el.id === userInterest.group.id
    );
    if (groupIndex < 0) {
        return;
    }
    return section.groupings[groupIndex];
}
