import React, {useContext} from "react";
import {VehicleDetails} from "../../../../../../../store/dutyManagerReport/actions/DutyManagerReportActionTypes";
import {useDispatch} from "react-redux";
import {
    removeVehicleDetails,
    setVehicle
} from "../../../../../../../store/dutyManagerReport/actions/DutyManagerReportActions";
import {DutyManagerReportEditableContext} from "../../Context/DutyManagerReportEditable";
import DriverSection from "../Drivers/DriverSection";
import {DebounceInput} from "react-debounce-input";
import FormHeaderWithAction from "../../../../../../Form/FormHeaderWithAction";
import {IconType} from "../../../../../../Icon/Icon";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../../../Button/MCButton";
import {Modal, useModal} from "pulse-modal";
import FormRow from "../../../../../../Form/FormRow";
import {showSuccessToast} from "../../../../../../../utils/toastUtils";

const VehicleDetailItem = (props: VehicleDetailItemProps) => {
    const editable = useContext(DutyManagerReportEditableContext);
    const dispatch = useDispatch();
    const {toggle, isShown} = useModal();
    const updateVehicleDetails = (event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedDetails: VehicleDetails = {
            ...props.details,
            vehicleRegistration: event.target.value
        };

        dispatch(setVehicle(updatedDetails));
    };

    const deleteVehicle = () => {
        dispatch(removeVehicleDetails({uid: props.details.uid}));
        toggle();
        showSuccessToast("Vehicle details deleted");
    };
    return (
        <React.Fragment>
            <div className="section-borders">
                <FormHeaderWithAction
                    headerName={"Vehicle Details"}
                    showIcon={editable}
                    icon={IconType.Bin}
                    onClick={toggle}
                    tooltipText={"Delete Vehicle"}
                />
                <FormRow
                    rowName={"Vehicle Registration"}
                    columnDetailClassName={editable ? "pl-0 pr-0" : ""}
                >
                    {editable ? (
                        <DebounceInput
                            debounceTimeout={300}
                            value={props.details.vehicleRegistration}
                            className="input-fields"
                            onChange={updateVehicleDetails}
                            placeholder="Enter vehicle registration here"
                        />
                    ) : (
                        <p className="mb-0">{props.details.vehicleRegistration}</p>
                    )}
                </FormRow>
                <DriverSection {...props} />
            </div>
            <Modal
                isShown={isShown}
                onClose={toggle}
                title={"Delete Vehicle"}
                modalSize={"sm"}
                bodyChildren={
                    <React.Fragment>
                        <div className="row ml-0 mr-0">
                            <p>Are you sure you want to delete this vehicle details?</p>
                        </div>
                    </React.Fragment>
                }
                footerChildren={
                    <React.Fragment>
                        <div className="row mt-3 ml-0 mr-0">
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"Yes"}
                                onClick={deleteVehicle}
                                colour={ButtonColourOptions.Yellow}
                                roundedCorner
                            />
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"No"}
                                onClick={toggle}
                                colour={ButtonColourOptions.DarkBlue}
                                roundedCorner
                            />
                        </div>
                    </React.Fragment>
                }
            />
        </React.Fragment>
    );
};

export default VehicleDetailItem;

export interface VehicleDetailItemProps {
    details: VehicleDetails;
    vehicleList: VehicleDetails[];
}
