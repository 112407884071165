import React, {useEffect} from "react";
import RotaForm from "./Components/RotaForm";
import {useDispatch} from "react-redux";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {fetchCalendarSummaryStats} from "../../../store/calendarSummaryStats/actions/CalendarSummaryStatsActions";
import {useXeroRoutes} from "../Xero/Hooks/useXeroRoutes";

function StaffRota() {
    const dispatch = useDispatch();
    const {getSystemAdminRoutes} = useXeroRoutes();

    useEffect(() => {
        window.scrollTo({top: 0});
        dispatch(setNavigationItems(getSystemAdminRoutes()));
        dispatch(fetchCalendarSummaryStats());
    }, []);
    return (
        <React.Fragment>
            <div className="page-container mt-4">
                <RotaForm />
            </div>
        </React.Fragment>
    );
}

export default StaffRota;
