import React from "react";

function TableWrapper({children, parentRender, className}: Props) {
    function renderParent() {
        switch (parentRender) {
            case "tbody":
                return <tbody>{children}</tbody>;
            case "thead":
                return <thead>{children}</thead>;
            default:
                return <React.Fragment>{children}</React.Fragment>;
        }
    }

    return <table className={`pulse-table fadeIn ${className || ""}`}>{renderParent()}</table>;
}

export default TableWrapper;

interface Props {
    children: React.ReactNode;
    parentRender?: ParentRender;
    className?: string;
}

type ParentRender = "thead" | "tbody";
