import {Dispatch} from "redux";
import {XERO_INVOICES_STORE, XeroInvoicesDispatchTypes} from "./XeroInvoicesActionTypes";
import {AutoInvoiceListRequest} from "../../../api/grs";
import {postDataToServiceWithRedux} from "store-fetch-wrappers";
import GrsApiModel from "../../apiModel/GrsApiModel";
import {statusCodeCallback} from "../../helpers/storeHelpers";
import {RootStore} from "../../Store";
import {showErrorToast} from "../../../utils/toastUtils";
import {AxiosError} from "axios";

export function nullifyXeroInvoicesStore() {
    return async function (dispatch: Dispatch<XeroInvoicesDispatchTypes>) {
        dispatch({
            type: XERO_INVOICES_STORE.SUCCESS,
            loading: false,
            error: null,
            data: {
                month: 0,
                year: 0,
                pageNum: 0,
                invoices: [],
                totalInvoices: 0,
                numPerPage: 50
            }
        });
    };
}

export function getInvoices(request: AutoInvoiceListRequest) {
    return async function (dispatch: Dispatch<XeroInvoicesDispatchTypes>) {
        try {
            return await postDataToServiceWithRedux(
                XERO_INVOICES_STORE,
                dispatch,
                () => GrsApiModel.getInvoiceApi().listXeroAutoInvoices(request),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: XERO_INVOICES_STORE.ERROR,
                loading: false,
                error: e
            });
        }
    };
}

export function triggerIndividualInvoice(staffId: string, staffName: string) {
    return async function (dispatch: Dispatch<XeroInvoicesDispatchTypes>, store: () => RootStore) {
        try {
            const state = store().xeroInvoices.data;
            if (!state) return;
            const resp = await GrsApiModel.getInvoiceApi().xeroTriggerIndividualAutoInvoice({
                year: state.year,
                month: state.month,
                staffId
            });

            return resp.data;
        } catch (e: any) {
            const {message} = e.response.data as AxiosError;
            // Use 500 server message first
            if (message) {
                showErrorToast(message);
                return;
            }
            showErrorToast(`Could not generate invoice for ${staffName}`);
        }
    };
}
