import React from "react";
import {EventCalendarEntry, FrontlineCalendarEntry} from "../../../../../../../api/grs";
import CalendarEntryActivityState from "../CalendarEntryActivityState";
import {isFrontlineCalendarEntry} from "../../../../../../../store/calendarEntry/actions/CalendarEntryActions";
import HourlyBilledTimeDetails from "../HourlyBilledTimeDetails";
import CalendarEntryNotes from "../CalendarEntryNotes";

const CalendarEntryFrontlineDetails = (props: EventCalendarEntry | FrontlineCalendarEntry) => {
    return (
        <React.Fragment>
            <CalendarEntryActivityState {...props} />
            {isFrontlineCalendarEntry(props) && <HourlyBilledTimeDetails {...props} />}
            <CalendarEntryNotes {...props} />
        </React.Fragment>
    );
};

export default CalendarEntryFrontlineDetails;
