import React from "react";

const MCButton = ({
    className,
    size,
    colour,
    innerValue,
    onClick,
    disabled,
    scalableFont,
    roundedCorner
}: MCButtonProps) => {
    return (
        <button
            className={`btn-base${size} ${roundedCorner ? "rounded-corner" : ""} ${
                scalableFont ? "scaled-font" : ""
            } ${colour}-btn${size} ${className ? className : ""} font-weight-normal`}
            onClick={onClick}
            disabled={disabled}
        >
            {innerValue}
        </button>
    );
};

export default MCButton;

export interface MCButtonProps {
    className?: string;
    size: ButtonSize;
    innerValue: string | JSX.Element | JSX.Element[];
    onClick: () => void;
    colour: ButtonColourOptions;
    disabled?: boolean;
    scalableFont?: boolean;
    roundedCorner?: boolean;
}

// eslint-disable-next-line no-shadow
export enum ButtonColourOptions {
    Green = "green",
    Red = "red",
    Orange = "orange",
    LightBlue = "light-blue",
    DarkBlue = "dark-blue",
    Yellow = "yellow"
}

// eslint-disable-next-line no-shadow
export enum ButtonSize {
    ExtraLarge = "-xl",
    Large = "-lg",
    Small = ""
}
