import {useSelector} from "react-redux";
import {RootStore} from "../../../../../store/Store";
import {useEffect, useState} from "react";
import {DDProps} from "../../../../Dropdown/Helpers/dropdownUtils";
import {MultiValue} from "react-select";
import {CalendarSummary, CalendarSummaryStats, CalendarTypeEnum} from "../../../../../api/grs";

export function useCalendarSummariesDropdown(
    calendarIds: number[],
    onChange: (ids: number[], viewType: CalendarTypeEnum) => void
) {
    const summariesStore = useSelector((state: RootStore) => state.calendarSummaryStats);
    const [filterOptions, setFilterOptions] = useState<DDProps[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<MultiValue<DDProps>>([]);

    useEffect(() => {
        if (!summariesStore.data) return;
        if (summariesStore.loading) return;

        processSummariesIncoming(summariesStore.data);
    }, [summariesStore]);

    useEffect(() => {
        if (calendarIds.length === 0) {
            setSelectedOptions([]);
            return;
        }
        setSelectedOptions(getSelectedFilterOptionsFromCalendarIds(calendarIds, filterOptions));
    }, [calendarIds, filterOptions]);

    function processSummariesIncoming(summaries: CalendarSummaryStats[]) {
        setFilterOptions(getOptions(summaries));
    }

    function getOptions(summaries: CalendarSummaryStats[]): DDProps[] {
        return summaries.map((item) => {
            return {
                label: getCalendarInformation(item.summary),
                value: item.summary.id
            };
        });
    }

    function getCalendarInformation(calendar: CalendarSummary): string {
        const archivedText = isArchivedText(calendar);
        return `${calendar.name} - (${calendar.calendarType}) ${archivedText}`;
    }

    function getSelectedFilterOptionsFromCalendarIds(
        ids: number[],
        incOptions: DDProps[]
    ): DDProps[] {
        const options: DDProps[] = [];

        for (const id of ids) {
            const foundOption = incOptions.find((item) => item.value === id);
            if (!foundOption) continue;

            options.push(foundOption);
        }

        return options;
    }

    function handleFiltersChanged(newValue: MultiValue<DDProps>) {
        setSelectedOptions(newValue);

        const ids = newValue.map((item) => +item.value);
        const viewType = getViewTypeFromCalendars(newValue);
        onChange(ids, viewType);
    }

    function getViewTypeFromCalendars(incOptions: MultiValue<DDProps>): CalendarTypeEnum {
        const {data} = summariesStore;
        if (!data) return CalendarTypeEnum.Frontline;

        const calendars: CalendarSummary[] = [];
        for (const opt of incOptions) {
            const calendar = data.find((it) => it.summary.id === +opt.value);
            if (!calendar) continue;

            calendars.push(calendar.summary);
        }

        for (const summary of calendars) {
            if (summary.calendarType === CalendarTypeEnum.Frontline) {
                return CalendarTypeEnum.Frontline;
            }
        }

        return CalendarTypeEnum.Event;
    }

    return {
        summariesStore,
        options: filterOptions,
        value: selectedOptions,
        handleFiltersChanged
    };
}

export function isArchivedText(summary: CalendarSummary): string {
    return summary.archived ? " - (Archived)" : "";
}
