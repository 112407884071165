import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {AutoInvoiceListResponse} from "../../../api/grs";
import {XERO_INVOICES_STORE} from "../actions/XeroInvoicesActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<AutoInvoiceListResponse>({
    month: 0,
    year: 0,
    pageNum: 0,
    invoices: [],
    totalInvoices: 0,
    numPerPage: 50
});

const xeroInvoicesReducer = (
    state: StoreServiceData<AutoInvoiceListResponse> = defaultState,
    action: ServiceActionTypes<AutoInvoiceListResponse>
) => createReducer(state, action, XERO_INVOICES_STORE, () => showErrorToast(action.error));

export default xeroInvoicesReducer;
