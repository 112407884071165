import {EventCalendarEntry, FrontlineCalendarEntry} from "../../../api/grs";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const CALENDAR_ENTRY_LIST_STORE_STATE = createStoreState("calendar_entry_list");

interface CalendarEntryListStoreLoading
    extends StoreServiceData<EventCalendarEntry[] | FrontlineCalendarEntry[]> {
    type: typeof CALENDAR_ENTRY_LIST_STORE_STATE.LOADING;
}

interface CalendarEntryListStoreError
    extends StoreServiceData<EventCalendarEntry[] | FrontlineCalendarEntry[]> {
    type: typeof CALENDAR_ENTRY_LIST_STORE_STATE.ERROR;
}

interface CalendarEntryListStoreSuccess
    extends StoreServiceData<EventCalendarEntry[] | FrontlineCalendarEntry[]> {
    type: typeof CALENDAR_ENTRY_LIST_STORE_STATE.SUCCESS;
}

export type CalendarEntryListDispatchTypes =
    | CalendarEntryListStoreLoading
    | CalendarEntryListStoreError
    | CalendarEntryListStoreSuccess;
