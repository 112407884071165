import React, {Fragment, ReactNode} from "react";
import {StaffInterestQueryResponse, Venue} from "../../../../../api/grs";
import {useSelector} from "react-redux";
import {RootStore} from "../../../../../store/Store";
import {PulseTable} from "pulse_table";
import {InterestedActionItemProp} from "../Tables/InterestedStaffMembersTable";
import UnregisterInterest from "../Actions/UnregisterInterest";
import {useUserData} from "../../../../Hooks/useUserData";
import {UserData} from "../../../../../api/staff";
import {getBulkShiftTime, getLocationName, getShiftDetails} from "../Helpers/shiftOverviewHelpers";

function BulkUnregisterListTable(props: StaffInterestQueryResponse) {
    const venueList = useSelector((state: RootStore) => state.venueList.data || []);
    const user = useUserData();
    return (
        <Fragment>
            <PulseTable
                items={toBulkAcceptTableRow(props, venueList, user)}
                headers={{
                    shiftLocation: "Shift Location",
                    shiftTimes: "Shift Time",
                    shiftDetails: "Shift Details",
                    actions: "Actions"
                }}
                customRenderers={{
                    shiftLocation: (item: BulkAcceptTableRow) => item.shiftLocation,
                    shiftTimes: (item: BulkAcceptTableRow) => item.shiftTimes,
                    shiftDetails: (item: BulkAcceptTableRow) => item.shiftDetails,
                    actions: (item: BulkAcceptTableRow) => (
                        <React.Fragment>
                            <UnregisterInterest
                                {...item.actions}
                                location={"shift-interest-list"}
                            />
                        </React.Fragment>
                    )
                }}
                noItemsSection={
                    <div className="row ml-0 mr-0 fadeIn">
                        <div className="col">
                            <h5 className="text-center p-3">
                                There are no pending staff register requests for this week
                            </h5>
                        </div>
                    </div>
                }
            />
        </Fragment>
    );
}

export default BulkUnregisterListTable;

interface BulkAcceptTableRow {
    shiftLocation: ReactNode;
    shiftTimes: ReactNode;
    shiftDetails: ReactNode;
    actions: InterestedActionItemProp;
}

function toBulkAcceptTableRow(
    resp: StaffInterestQueryResponse,
    venueList: Venue[],
    currentUser: UserData
): BulkAcceptTableRow[] {
    //Filter the entries to the ones for the logged in user
    return resp.interestList
        .filter(({user}) => user.staffId === currentUser.username)
        .map((interest) => {
            const entry = resp.entries.find((ent) => ent.id === interest.entryId)!;
            const venue = venueList.find((ven) => ven.id === entry.venueId);
            const calendar = (resp.calendarSummaries || []).find(
                (cal) => cal.id === entry.calendarId
            );
            return {
                shiftLocation: getLocationName(entry, venue, calendar),
                shiftTimes: getBulkShiftTime(entry),
                shiftDetails: getShiftDetails(interest),
                actions: {
                    interest: interest,
                    entry: entry
                }
            };
        });
}
