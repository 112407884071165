import React, {useContext, useEffect} from "react";
import {DutyManagerReport} from "../../../../../../api/grs";
import {DutyManagerReportEditableContext} from "../Context/DutyManagerReportEditable";
import {useUserData} from "../../../../../Hooks/useUserData";
import {useQuery} from "../../../../../Hooks/useQuery";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {nullifyCalendarEntryStore} from "../../../../../../store/calendarEntry/actions/CalendarEntryActions";
import {
    nullifyDutyManagerReportStore,
    saveDutyManagerReportToService
} from "../../../../../../store/dutyManagerReport/actions/DutyManagerReportActions";
import {StaffAccessLevel} from "../../../../../../api/staff";
import {CalendarMonthView} from "../../../../AdminCalendar/Helpers/calendarUtils";
import {getPathToGlobalStaffCalendar} from "../../../../GlobalCalendar/Helpers/globalCalendarHelpers";
import {validateDutyManagerForm} from "../Helpers/dutyManagerReportHelpers";
import {Modal, useModal} from "pulse-modal";
import {showErrorToast, showSuccessToast} from "../../../../../../utils/toastUtils";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../../Button/MCButton";
import DutyManagerWarning from "../DutyManagerWarning";
import ReportFormDetails from "./Details/ReportFormDetails";
import VehicleSection from "./Vehicle/VehicleSection";
import DutyManagerFormDetails from "./DutyManagerFormDetails";
import BackgroundContainer from "../../../../../Containers/BackgroundContainer";
import FormActionContainer from "../../../../../Form/FormActionContainer";
import useMcConfig from "../../../../../Hooks/useMcConfig";
import {getUserAccessLevel} from "../../../../../../utils/userDataUtils";

const EditDutyManagerReportForm = (props: DutyManagerReport) => {
    const editable = useContext(DutyManagerReportEditableContext);
    const user = useUserData();
    const query = useQuery();
    const history = useHistory();
    const dispatch = useDispatch();
    const {isShown, toggle} = useModal();
    const {config} = useMcConfig();

    useEffect(() => {
        return function () {
            dispatch(nullifyDutyManagerReportStore());
            dispatch(nullifyCalendarEntryStore());
        };
    }, []);

    const preSaveCheck = async () => {
        const accessLevel = getUserAccessLevel(user, config);
        if (accessLevel.length === 0) return;
        const valid = validateDutyManagerForm(JSON.parse(props.payload));

        if (!valid.valid) {
            for (const error of valid.messages) {
                showErrorToast(error);
            }
            return;
        }

        switch (accessLevel) {
            case StaffAccessLevel.DutyManager:
                toggle();
                return;
            case StaffAccessLevel.SystemAdministrator:
                await saveReport();
                return;
        }
    };

    const saveReport = async () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(saveDutyManagerReportToService());

        if (!success) return;
        showSuccessToast(
            props.id > 0 ? "Updated Duty Manager Report" : "Saved Duty Manager Report"
        );
        await backToLastLocation();
    };

    const backToLastLocation = async () => {
        const dmListRedirect = localStorage.getItem("duty_manager_list_uri");
        if (dmListRedirect) {
            history.push(dmListRedirect);
            return;
        }
        const redirectUri = localStorage.getItem("calendar_entry_redirect_uri");
        if (!redirectUri) {
            const accessLevel = getUserAccessLevel(user, config);
            const calendarView =
                accessLevel === StaffAccessLevel.SystemAdministrator
                    ? CalendarMonthView.Global
                    : CalendarMonthView.Staff;
            history.push(getPathToGlobalStaffCalendar(calendarView, query));
            return;
        }

        history.push(redirectUri);
    };
    return (
        <React.Fragment>
            <BackgroundContainer background={"background-stars"}>
                <DutyManagerWarning />
                <ReportFormDetails {...props} />
                <VehicleSection {...JSON.parse(props.payload)} />
                <DutyManagerFormDetails {...JSON.parse(props.payload)} />
                <FormActionContainer>
                    <React.Fragment>
                        {editable && (
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue="Save"
                                onClick={preSaveCheck}
                                colour={ButtonColourOptions.Yellow}
                                roundedCorner
                            />
                        )}
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue="Back"
                            onClick={backToLastLocation}
                            colour={ButtonColourOptions.DarkBlue}
                            roundedCorner
                        />
                    </React.Fragment>
                </FormActionContainer>
            </BackgroundContainer>
            <Modal
                modalSize={"sm"}
                title={"Save Report"}
                bodyChildren={
                    <div className="row ml-0 mr-0">
                        <p className="mb-0">
                            Are you sure you want to save this report? Once saved, this form cannot
                            be edited!
                        </p>
                    </div>
                }
                footerChildren={
                    <div className="row ml-0 mr-0">
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue="Yes"
                            onClick={saveReport}
                            colour={ButtonColourOptions.Yellow}
                            roundedCorner
                        />
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue="No"
                            onClick={toggle}
                            colour={ButtonColourOptions.DarkBlue}
                            roundedCorner
                        />
                    </div>
                }
                onClose={toggle}
                isShown={isShown}
            />
        </React.Fragment>
    );
};

export default EditDutyManagerReportForm;
