import {StaffAccessLevel, UserData} from "../../../../api/staff";
import {NavigationItem} from "../../../../store/navigation/actions/NavigationActionTypes";
import {
    CalendarMonthView,
    getCalendarPathForGlobalStaffCalendar
} from "../../AdminCalendar/Helpers/calendarUtils";
import {routeNames} from "../../../Navigation/routeNames";
import {PlatformOS} from "../../../Hooks/usePlatform";
import {getAvailableShiftsListRoute} from "../../Reports/StaffReport/StaffReport";

export function getNavItemsForNotificationPage(
    user: UserData,
    platform: PlatformOS
): NavigationItem[] {
    switch (user.accessLevel) {
        case StaffAccessLevel.SystemAdministrator:
            return [
                {
                    name: routeNames.groupRosteringSystem.name,
                    path: routeNames.groupRosteringSystem.path
                },
                {
                    name: CalendarMonthView.Global,
                    path: getCalendarPathForGlobalStaffCalendar(CalendarMonthView.Global)
                },
                {
                    name: routeNames.pendingNotifications.name,
                    path: routeNames.pendingNotifications.path
                }
            ];
        case StaffAccessLevel.DutyManager:
        case StaffAccessLevel.Staff:
            return [
                {
                    name: CalendarMonthView.Staff,
                    path: getCalendarPathForGlobalStaffCalendar(CalendarMonthView.Staff)
                },
                {
                    name: routeNames.staffReport.name,
                    path: routeNames.staffReport.path
                },
                {
                    name: routeNames.pendingNotifications.name,
                    path: routeNames.pendingNotifications.path
                },
                {
                    name: routeNames.availableShiftsList.name,
                    path: routeNames.availableShiftsList.path
                },
                ...getAvailableShiftsListRoute(platform)
            ];
    }
}
