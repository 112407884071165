import React from "react";
import {CalendarGroup} from "../../../api/grs";
import {useCalendarGroupDropdown} from "../Hooks/useCalendarGroupDropdown";
import {Loading} from "store-fetch-wrappers";
import Select from "react-select";

function CalendarGroupDropdown({groupId, onChange}: Props) {
    const {value, handleFiltersChanged, store, options} = useCalendarGroupDropdown(
        onChange,
        groupId
    );
    return (
        <React.Fragment>
            {store.loading ? (
                <Loading type={"three-ring"} showLoadingText={false} />
            ) : (
                <Select
                    className="search-bar"
                    classNamePrefix="select-options"
                    options={options}
                    onChange={handleFiltersChanged}
                    isSearchable={true}
                    value={value}
                    noOptionsMessage={() => "No Calendar Groups Found"}
                    placeholder="Select Calendar Group"
                    isDisabled={false}
                    isClearable={true}
                    menuPortalTarget={document.body}
                />
            )}
        </React.Fragment>
    );
}

export default CalendarGroupDropdown;

interface Props {
    onChange: (group: CalendarGroup | undefined) => void;
    groupId?: number;
}
