import React, {createContext, ReactNode, useEffect, useReducer} from "react";
import moment, {Moment} from "moment";
import {useHistory, useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";
import {StaffInterestQueryFields} from "../../../../api/grs";
import {getStaffInterestForDates} from "../../../../store/staffInterestQuery/actions/StaffInterestQueryResponseActions";
import {useShiftOverviewRequestBuilder} from "../Hooks/useShiftOverviewRequestBuilder";
import {useUserModuleAccessLevel} from "../../../Hooks/useUserModuleAccessLevel";
import {StaffAccessLevel} from "../../../../api/staff";

function ShiftOverviewProvider({children}: Props) {
    const reduxDispatch = useDispatch();
    const {getStaffInterestQueryRequest} = useShiftOverviewRequestBuilder();
    const [state, dispatch] = useReducer(shiftOverviewReducer, initialState);
    const location = useLocation();
    const history = useHistory();
    const moduleAccessLevel = useUserModuleAccessLevel();

    useEffect(() => {
        switch (moduleAccessLevel) {
            case StaffAccessLevel.SystemAdministrator:
                history.push({
                    search: `?week=${moment().unix()}&view=${ActiveShiftView.BulkAccept}`
                });
                break;
            default:
                history.push({
                    search: `?month=${moment().unix()}&view=${ActiveShiftView.BulkAccept}`
                });
        }
    }, [moduleAccessLevel]);

    useEffect(() => {
        if (!location.search) return;
        const {filters, request} = getStaffInterestQueryRequest();
        fetchData(request);

        dispatch({type: SHIFT_OVERVIEW_ACTION.SET_FILTERS, payload: filters});
    }, [location.search]);

    function fetchData(request: StaffInterestQueryFields) {
        reduxDispatch(getStaffInterestForDates(request));
    }

    const defaultValue = (): ProviderValue => {
        return {
            state: state.data,
            setFilters: (newState: ShiftOverviewFilters) => {
                dispatch({type: SHIFT_OVERVIEW_ACTION.SET_FILTERS, payload: newState});
            }
        };
    };

    return (
        <ShiftOverviewContext.Provider value={defaultValue()}>
            {children}
        </ShiftOverviewContext.Provider>
    );
}

export default ShiftOverviewProvider;

interface Props {
    children: ReactNode;
}

// eslint-disable-next-line no-shadow
export enum ActiveShiftView {
    BulkAccept = "BulkAccept",
    ShiftOverview = "ShiftOverview"
}

const initialState = createStoreEntity<ShiftOverviewFilters>({
    view: ActiveShiftView.ShiftOverview,
    day: moment()
});

function shiftOverviewReducer(
    state: StoreEntity<ShiftOverviewFilters> = initialState,
    action: StoreActionWithType<ShiftOverviewFilters>
) {
    switch (action.type) {
        case SHIFT_OVERVIEW_ACTION.SET_FILTERS:
            return {data: action.payload};
        default:
            return state;
    }
}

export const ShiftOverviewContext = createContext<ProviderValue | null>(null);

interface ProviderValue {
    request?: StaffInterestQueryFields;
    state: ShiftOverviewFilters;
    setFilters: (newValue: ShiftOverviewFilters) => void;
}

interface StoreEntity<TEntity> {
    data: TEntity;
}

interface StoreActionWithType<TEntity> {
    type: string;
    payload: TEntity;
}

function createStoreEntity<T>(data: T): StoreEntity<T> {
    return {
        data
    };
}

export const SHIFT_OVERVIEW_ACTION = {
    SET_FILTERS: "SET_FILTERS"
} as const;

export interface ShiftOverviewFilters {
    day?: Moment;
    week?: Moment;
    month?: Moment;
    view: ActiveShiftView;
}

export const shiftOverviewQuery = {
    week: "week",
    day: "day",
    view: "view",
    month: "month"
};
