import moment, {Moment} from "moment";
import {CalendarEventItem} from "../../../store/fullCalendarItemList/actions/FullCalendarItemListActionTypes";
import deepCopy from "../../../utils/csvUtils";

/** Gets dates for week */
export function getDatesForIsoWeek(currentDate: Moment): number[] {
    //Monday
    const startOfWeek = currentDate.clone().startOf("isoWeek");
    //Sunday
    const endOfWeek = currentDate.clone().endOf("isoWeek");

    const date = startOfWeek.clone().subtract(1, "day");

    const dates: number[] = [];
    while (date.isBefore(endOfWeek, "day")) {
        dates.push(
            ...Array(7)
                .fill(0)
                .map(() => date.add(1, "day").clone().unix())
        );
    }

    return dates;
}

/** Get dates for 8 days after the start of the week. */
export function getDatesForCalendarEntrySorting(currentDate: Moment): number[] {
    //Monday
    const startOfWeek = currentDate.clone().startOf("isoWeek");

    const date = startOfWeek.clone().subtract(1, "day");
    const dates: number[] = [];

    for (let i = 0; i < 8; ++i) {
        dates.push(date.add(1, "day").clone().unix());
    }

    return dates;
}

export function sortFrontlineEntries(
    epochTimes: number[],
    entries: CalendarEventItem[]
): WeekViewCalendarItems {
    const copy = deepCopy(entries);
    const rowCount = calculateNumberOfRows(copy);

    const tableData: UnsortedWeekViewWeekItem = {days: []};
    for (let i = 0; i < epochTimes.length - 1; ++i) {
        const filteredFrontLineDays: UnsortedWeekViewDayItem = {
            entries: [],
            startOfDay: epochTimes[i]
        };
        const filteredFrontlineEntries: CalendarEventItem[] = [];

        for (const entry of copy) {
            const start = moment(entry.start).unix();
            let end = moment(entry.end).unix();
            if (end > moment.unix(start).endOf("day").unix()) {
                end = moment.unix(start).endOf("day").unix();
            }
            //Do this to make sure the generated epochs dates are at the start of the day. not an hour ahead
            const startOfCurrentDay = moment.unix(epochTimes[i]).startOf("day").unix();
            const startOfNextDay = moment
                .unix(epochTimes[i + 1])
                .startOf("day")
                .unix();

            if (start >= startOfCurrentDay && end < startOfNextDay) {
                filteredFrontlineEntries.push(entry);
            }
        }
        filteredFrontLineDays.entries = sortEntriesAlphabetically(filteredFrontlineEntries);
        const undefinedEntriesToGen = rowCount - filteredFrontLineDays.entries.length;

        for (let j = 0; j < undefinedEntriesToGen; ++j) {
            filteredFrontLineDays.entries.push(undefined);
        }

        tableData.days.push(filteredFrontLineDays);
    }

    return sortFrontlineEntriesHorizontally(tableData, rowCount);
}

function sortFrontlineEntriesHorizontally(
    entries: UnsortedWeekViewWeekItem,
    rowCount: number
): WeekViewCalendarItems {
    const weekViewCalendar: WeekViewCalendarItems = {rows: []};

    for (let i = 0; i < rowCount; ++i) {
        const weekDayEntries: WeekViewWeekItem = {entries: []};
        for (let j = 0; j < entries.days.length; ++j) {
            const day = entries.days[j];
            weekDayEntries.entries.push(day.entries[i]);
        }
        weekViewCalendar.rows.push(weekDayEntries);
    }

    return weekViewCalendar;
}

/** Sorts the frontline entries alphabetically */
export function sortEntriesAlphabetically(entries: CalendarEventItem[]) {
    return entries.sort((a: CalendarEventItem, b: CalendarEventItem) => {
        if (a.title && b.title) {
            if (a.title > b.title) {
                return 1;
            }
            if (a.title < b.title) {
                return -1;
            }

            return 0;
        }

        return 0;
    });
}

/** Calculates the number of rows in the frontline calendar */
export function calculateNumberOfRows(entries: CalendarEventItem[]) {
    //Minimum of 2 rows
    if (entries.length === 0) return 0;

    const frequency: any[] = [];
    const epochValues: number[] = [];

    //Get the epoch values from the start date (00:00) of each entry.
    for (const entry of entries) {
        epochValues.push(moment(entry.start).startOf("day").unix());
    }

    //Calculate the mode of each value that appears.
    for (const i in epochValues) {
        frequency[epochValues[i]] = (frequency[epochValues[i]] || 0) + 1;
    }

    // Get values of object and sort them from highest to lowest.
    const values = Object.values(frequency).sort(function (a: number, b: number) {
        return b - a;
    });

    return values[0]; //Number of rows.
}

export function getQueryStringsForCreateFrontlineCalendarEntry(
    startDate: number,
    calendarId: number,
    currentWeekEpoch: number
) {
    return `?startDate=${startDate}&calendarId=${calendarId}&week=${currentWeekEpoch}`;
}

export function getQueryStringsForViewFrontlineCalendarEntry(
    currentWeekEpoch: number,
    calendarId: number
) {
    return `?calendarId=${calendarId}&week=${currentWeekEpoch}`;
}

export interface WeekViewCalendarItems {
    rows: WeekViewWeekItem[];
}

export interface WeekViewWeekItem {
    entries: Array<CalendarEventItem | undefined>;
}

export interface UnsortedWeekViewDayItem {
    entries: Array<CalendarEventItem | undefined>;
    startOfDay: number;
}

export interface UnsortedWeekViewWeekItem {
    days: Array<UnsortedWeekViewDayItem>;
}
