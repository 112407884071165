import React from "react";
import {StaffAssignmentGrouping, StaffBlockSection} from "../../../../../../api/grs";
import AddCalendarGrouping from "./AddCalendarGrouping";
import EditCalendarGrouping from "./EditCalendarGrouping";
import {Modal, useModal} from "pulse-modal";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../../Button/MCButton";
import FormRow from "../../../../../Form/FormRow";
import {DebounceInput} from "react-debounce-input";

const StaffSection = (props: GlobalStaffBlockUpdateProps<StaffBlockSection>) => {
    const {isShown, toggle} = useModal();

    /** Deletes tab from tabs */
    const deleteTab = () => {
        props.onItemChanged({updateType: StaffBlockUpdateType.Delete, newItem: props.item});
        toggle();
    };

    const onGroupAdded = (newGroup: StaffAssignmentGrouping) => {
        props.onItemChanged({
            updateType: StaffBlockUpdateType.Update,
            newItem: {...props.item, groupings: [...props.item.groupings, newGroup]}
        });
    };

    const onStaffGroupingChanged = (action: ItemChangedAction<StaffAssignmentGrouping>) => {
        switch (action.updateType) {
            case StaffBlockUpdateType.Delete:
                props.onItemChanged({
                    updateType: StaffBlockUpdateType.Update,
                    newItem: deleteGroupingFromSection(action.newItem, props.item)
                });
                break;
            case StaffBlockUpdateType.Update:
                props.onItemChanged({
                    updateType: StaffBlockUpdateType.Update,
                    newItem: updateGroupingFromSection(action.newItem, props.item)
                });
                break;
        }
    };
    return (
        <React.Fragment>
            <FormRow rowName={"Section Name"} columnDetailClassName={"pl-0 pr-0"}>
                <DebounceInput
                    debounceTimeout={300}
                    className="input-fields w-100"
                    value={props.item.name}
                    onChange={(event) =>
                        props.onItemChanged({
                            newItem: {...props.item, name: event.target.value},
                            updateType: StaffBlockUpdateType.Update
                        })
                    }
                    placeholder="Enter section name here..."
                />
            </FormRow>
            <div className="row">
                <div className="ml-3 w-100">
                    {props.item.groupings.map((el: StaffAssignmentGrouping) => {
                        return (
                            <EditCalendarGrouping
                                onItemChanged={onStaffGroupingChanged}
                                item={el}
                                key={el.id}
                            />
                        );
                    })}
                    <AddCalendarGrouping onGroupAdded={onGroupAdded} />
                </div>
            </div>
            <div className="row ml-0 mr-0">
                <div className="col d-flex justify-content-end">
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue="Delete"
                        colour={ButtonColourOptions.Red}
                        disabled={false}
                        onClick={toggle}
                        roundedCorner
                    />
                </div>
            </div>
            <Modal
                isShown={isShown}
                onClose={toggle}
                title={"Delete Section"}
                modalSize={"sm"}
                bodyChildren={
                    <div className="row ml-0 mr-0">
                        <p>Are you sure you want to delete this section?</p>
                    </div>
                }
                footerChildren={
                    <div className="row ml-0 mr-0">
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Yes"}
                            onClick={deleteTab}
                            colour={ButtonColourOptions.Yellow}
                            roundedCorner
                        />
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"No"}
                            onClick={toggle}
                            colour={ButtonColourOptions.DarkBlue}
                            roundedCorner
                        />
                    </div>
                }
            />
        </React.Fragment>
    );
};

export default StaffSection;

export interface GlobalStaffBlockUpdateProps<T> {
    item: T;
    onItemChanged: (newItem: ItemChangedAction<T>) => void;
}

export interface ItemChangedAction<T> {
    newItem: T;
    updateType: StaffBlockUpdateType;
}

// eslint-disable-next-line no-shadow
export enum StaffBlockUpdateType {
    Update = "Update",
    Delete = "Delete",
    Add = "Add"
}

function deleteGroupingFromSection(
    grouping: StaffAssignmentGrouping,
    section: StaffBlockSection
): StaffBlockSection {
    const index = section.groupings.findIndex((item) => item.id === grouping.id);

    section.groupings.splice(index, 1);
    return section;
}

function updateGroupingFromSection(
    grouping: StaffAssignmentGrouping,
    section: StaffBlockSection
): StaffBlockSection {
    const index = section.groupings.findIndex((item) => item.id === grouping.id);

    section.groupings[index] = grouping;
    return section;
}
