import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setCalendarEntry} from "../../../../../../store/calendarEntry/actions/CalendarEntryActions";
import {BillingType, CalendarEntry} from "../../../../../../api/grs";
import {RootStore} from "../../../../../../store/Store";
import GenericTypeDropdown from "../../../../../Dropdown/Components/GenericTypeDropdown";
import {IconType} from "../../../../../Icon/Icon";
import FormRowWithTooltip from "../../../../../Form/FormRowWithTooltip";

const CalendarEntryBillingDropdown = (props: CalendarEntry) => {
    const calendar = useSelector((state: RootStore) => state.calendar.data);
    const dispatch = useDispatch();

    const onBillingTypeChanged = (item: number | string) => {
        if (item.toString().length === 0) {
            dispatch(
                setCalendarEntry({
                    ...props,
                    billingType: undefined
                })
            );
            return;
        }

        const billingType = getBillingTypeFromString(item.toString());
        dispatch(
            setCalendarEntry({
                ...props,
                billingType
            })
        );
    };

    const tooltipText = () => {
        if (!calendar) return "This field will determine how the staff will be paid.";
        return `
            This field will determine how the staff will be paid. This calendar (${
                calendar.name
            }) is originally ${getBillingType(
            calendar.billingType
        )} Changing this field will override the original way of payment for this calendar.`;
    };

    const getBillingType = (billingType: BillingType): string => {
        switch (billingType) {
            case BillingType.Daily:
                return `a ${billingType} billed calendar.`;
            case BillingType.Hourly:
                return `an ${billingType} billed calendar.`;
        }
    };

    return (
        <React.Fragment>
            <FormRowWithTooltip
                tooltipText={tooltipText()}
                icon={IconType.Question}
                rowName={"Billing Type"}
                columnDetailClassName={"pl-0 pr-0"}
            >
                <GenericTypeDropdown
                    enumOptions={BillingType}
                    splitByCapitalLetter={false}
                    clearable={false}
                    searchable={false}
                    changeOption={onBillingTypeChanged}
                    id={props.billingType}
                    disabled={false}
                />
            </FormRowWithTooltip>
        </React.Fragment>
    );
};

export default CalendarEntryBillingDropdown;

function getBillingTypeFromString(value: string): BillingType {
    return BillingType[value as keyof typeof BillingType];
}
