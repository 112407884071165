import React from "react";
import {StaffWageReportEntry} from "../../../../../api/grs";
import {PulseTable} from "pulse_table";
import {formatUnixToDoMMMMYYYY} from "../../../../../utils/momentUtils";
import SelectedWageReportStats from "./SelectedWageReportStats";
import FormActionContainer from "../../../../Form/FormActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../Button/MCButton";
import {useDispatch} from "react-redux";
import {setSelectedWageReportEntry} from "../../../../../store/selectedWageReport/actions/SelectedWageReportActions";
import {wageReportFile} from "../../../../../utils/reportUtils";
import {jsonToCsv} from "../../../../../utils/csvUtils";

const SelectedWageReport = (props: StaffWageReportEntry) => {
    const dispatch = useDispatch();

    /** Downloads the wage report */
    const downloadWageReport = () => {
        const date = formatUnixToDoMMMMYYYY(props.startDate);
        const fileName = `${props.entryName} - Wage Report - ${date}.csv`;
        const report = wageReportFile([props]);

        //Generates a downloadable link.
        jsonToCsv(fileName, report);
    };

    return (
        <React.Fragment>
            <div className="fadeIn">
                <div className="mt-4">
                    <PulseTable
                        items={toTableRow(props)}
                        headers={{
                            shiftDetails: "Shift Details",
                            calendar: "Calendar",
                            billingType: "Billing Type",
                            shiftDate: "Shift Date"
                        }}
                        noItemsSection={<div />}
                    />
                </div>
                <div className="mt-4">
                    <SelectedWageReportStats {...props} />
                </div>
                <FormActionContainer>
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Download"}
                        onClick={downloadWageReport}
                        colour={ButtonColourOptions.Yellow}
                        roundedCorner
                    />
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Back"}
                        onClick={() => {
                            dispatch(setSelectedWageReportEntry(null));
                        }}
                        colour={ButtonColourOptions.DarkBlue}
                        roundedCorner
                    />
                </FormActionContainer>
            </div>
        </React.Fragment>
    );
};

export default SelectedWageReport;

interface TableRow {
    shiftDetails: string;
    calendar: string;
    billingType: string;
    shiftDate: string;
}

function toTableRow(report: StaffWageReportEntry): TableRow[] {
    return [
        {
            shiftDetails: report.entryName,
            calendar: report.calendarName,
            billingType: report.billingType,
            shiftDate: formatUnixToDoMMMMYYYY(report.startDate)
        }
    ];
}
