import GrsApiModel from "../../apiModel/GrsApiModel";
import {EventsReportEntry, EventsReportRequest, EventsReportResponse} from "../../../api/grs";
import {EVENT_REPORT_STORE, EventReportDispatchTypes} from "./EventReportActionTypes";
import {Dispatch} from "redux";
import {getDataFromServiceWithData} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";

/** Removes stale data from store */
export const nullifyEventReportStore = () => {
    return async (dispatch: Dispatch<EventReportDispatchTypes>) => {
        dispatch({
            type: EVENT_REPORT_STORE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

/** gets Event Report requested for month for calendar */
export const getEventReportForCalendar = (request: EventsReportRequest) => {
    return async (dispatch: Dispatch<EventReportDispatchTypes>) => {
        try {
            const response = await getDataFromServiceWithData(
                EVENT_REPORT_STORE,
                dispatch,
                () => GrsApiModel.getReportsApi().getEventsReport(request),
                statusCodeCallback
            );

            dispatch({
                type: EVENT_REPORT_STORE.SUCCESS,
                error: null,
                loading: false,
                data: processReportsIncoming(response)
            });
        } catch (e: any) {
            dispatch({
                type: EVENT_REPORT_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

function processReportsIncoming(
    response: EventsReportResponse | undefined | null
): EventsReportResponse | undefined | null {
    return {
        ...response,
        entries: orderEventsDateAsc(response?.entries)
    };
}

function orderEventsDateAsc(entries: EventsReportEntry[] | undefined): EventsReportEntry[] {
    if (!entries) return [];

    return entries.sort((a, b) => {
        return a.startDate - b.startDate;
    });
}
