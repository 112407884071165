import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {Region} from "../../../../api/staff";
import {REGION_STORE} from "../actions/RegionActionTypes";
import {showErrorToast} from "../../../../utils/toastUtils";

const initialState = createDefaultStoreState<Region>(null);

const regionReducer = (
    state: StoreServiceData<Region> = initialState,
    action: ServiceActionTypes<Region>
) => createReducer(state, action, REGION_STORE, () => showErrorToast(action.error));

export default regionReducer;
