import React from "react";
import {AvailableShiftEntrySectionGroupProps} from "../../../../../AvailableShifts/Calendar/Components/AvailableShiftEntrySectionGroup";
import {useAvailableShiftsInfoUtils} from "../../../../../AvailableShifts/Calendar/Hooks/useAvailableShiftsInfoUtils";
import AvailableRolesDetails from "./AvailableRolesDetails";

function AvailableRolesGroup(props: AvailableShiftEntrySectionGroupProps) {
    const {showGroup} = useAvailableShiftsInfoUtils();
    return (
        <React.Fragment>
            {showGroup(props.group) && (
                <AvailableRolesDetails
                    group={props.group}
                    section={props.section}
                    entry={props.entry}
                    callback={props.callback}
                />
            )}
        </React.Fragment>
    );
}

export default AvailableRolesGroup;
