//React hook to check if the user has access to the module they're on

import {StaffAccessLevel, UserData} from "../../api/staff";
import {useUserData} from "./useUserData";
import useMcConfig from "./useMcConfig";
import {McConfig} from "../../McConfigPlugin";

export function useUserModuleAccessLevel(): StaffAccessLevel {
    const user = useUserData();
    const {config} = useMcConfig();

    if (!user.username.length) return StaffAccessLevel.Staff;
    if (!config.auth) return user.accessLevel;
    if (!user.accessDetail) return user.accessLevel;

    const {moduleName} = config.systemDetails;

    return user.accessDetail[moduleName] || user.accessLevel;
}

//function to check if the user has access to the module they're on
export function getUserModuleAccessLevel(user: UserData, config: McConfig): StaffAccessLevel {
    const {moduleName} = config.systemDetails;

    if (!user.accessDetail) return user.accessLevel;

    return user.accessDetail[moduleName] || user.accessLevel;
}
