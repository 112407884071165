import React, {useEffect, useState} from "react";
import {InvoiceLine, StaffMemberInvoice} from "../../../../../../../../api/grs";
import {formatUnixToDoMMMMYYYY} from "../../../../../../../../utils/momentUtils";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const numeral = require("numeral");

export function useInvoiceLinesTable(props: StaffMemberInvoice) {
    const [items, setItems] = useState<TableRow[]>([]);
    function getHeaders() {
        return {
            location: "Location",
            date: "Date",
            accountCode: "Account Code",
            shiftDetails: "Shift Costs",
            billingType: "Billing"
        };
    }

    useEffect(() => {
        setItems(getTableRows(props.lines));
    }, [props]);

    function getTableRows(entity: InvoiceLine[]): TableRow[] {
        return entity.map(
            ({calendarName, venueName, date, accountCode, amount, numberHours, type, rate}) => {
                return {
                    location: getLocationMarkup({calendarName, venueName}),
                    date: formatUnixToDoMMMMYYYY(date),
                    accountCode,
                    shiftDetails: getShiftDetailsMarkup({amount, numberHours, type, rate}),
                    billingType: type
                };
            }
        );
    }

    function getLocationMarkup({calendarName, venueName}: Partial<InvoiceLine>) {
        return (
            <React.Fragment>
                <h6 className="inner-table-item-header">{calendarName}</h6>
                <p className="mb-0">{venueName}</p>
            </React.Fragment>
        );
    }

    function getShiftDetailsMarkup({
        amount,
        numberHours,
        type,
        rate
    }: Pick<InvoiceLine, "amount" | "numberHours" | "type" | "rate">) {
        return (
            <React.Fragment>
                <h6 className="inner-table-item-header">{getFormattedWage(amount)}</h6>
                {getBillingTypeInfo({rate, type, numberHours})}
            </React.Fragment>
        );
    }

    function getBillingTypeInfo({
        type,
        numberHours,
        rate
    }: Pick<InvoiceLine, "numberHours" | "type" | "rate">) {
        switch (type) {
            case "Daily":
                return (
                    <p>
                        <b>Rate:</b> {getFormattedWage(rate)} p/d
                    </p>
                );
            case "Hourly":
                return (
                    <React.Fragment>
                        <p className="mb-1">
                            <b>Hours:</b> {numberHours}
                        </p>
                        <p>
                            <b>Rate:</b> {getFormattedWage(rate)} p/h
                        </p>
                    </React.Fragment>
                );
        }
    }

    function getFormattedWage(wage: number): string {
        return `£${numeral(wage).format("0,0.00")}`;
    }

    return {
        items,
        headers: getHeaders()
    };
}

interface TableRow {
    location: React.ReactNode;
    accountCode: string;
    date: React.ReactNode;
    shiftDetails: React.ReactNode;
    billingType: string;
}
