import {
    AssignmentState,
    BillingType,
    CalendarSummaryStats,
    CalendarTypeEnum,
    StaffReportEntry
} from "../../../../../api/grs";
import {formatUnixToHHmmddd} from "../../../../../utils/momentUtils";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const numeral = require("numeral");

export function getShiftDetails(
    entry: StaffReportEntry,
    summaries: CalendarSummaryStats[]
): string {
    const index = summaries.findIndex(
        (el: CalendarSummaryStats) => el.summary.id === entry.calendarId
    );

    if (index < 0) return "New Calendar Entry";

    const calendar = summaries[index].summary;

    switch (calendar.calendarType) {
        case CalendarTypeEnum.Event:
            return entry.venueName || "New Calendar Entry";
        case CalendarTypeEnum.Frontline:
            return entry.entryName;
    }
}

export function getStartDate(entry: StaffReportEntry): string {
    if (entry.actualStartDate) {
        return formatUnixToHHmmddd(entry.actualStartDate);
    }
    return formatUnixToHHmmddd(entry.entryStartDate);
}

export function getEndDate(entry: StaffReportEntry): string {
    if (entry.actualEndDate) {
        return formatUnixToHHmmddd(entry.actualEndDate);
    }
    return formatUnixToHHmmddd(entry.entryEndDate);
}

export function getShiftAccepted(entry: StaffReportEntry): string {
    switch (entry.state) {
        case AssignmentState.Accepted:
        case AssignmentState.Attended:
        case AssignmentState.BriefingAccepted:
            return "YES";
        default:
            return "NO";
    }
}

export function getBriefingAccepted(entry: StaffReportEntry): string {
    switch (entry.state) {
        case AssignmentState.Attended:
        case AssignmentState.BriefingAccepted:
            return "YES";
        default:
            return "NO";
    }
}

export function getStaffWage(entry: StaffReportEntry): string {
    const shiftWage = getWageForStaffReport(entry);

    return `£${numeral(shiftWage).format("0,0.00")}`;
}

export function getWageForStaffReport(report: StaffReportEntry) {
    switch (report.billingType) {
        case BillingType.Daily:
            return report.wage ?? 0;
        case BillingType.Hourly:
            //Both fields can be undefined
            const rate = report.rate ?? 0;
            const numOfHours = report.numHours ?? 0;
            return rate * numOfHours;
        default:
            return 0;
    }
}
