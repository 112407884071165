import React, {useEffect, useState} from "react";
import {InterestedActionItemProp} from "../Tables/InterestedStaffMembersTable";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../Button/MCButton";
import {Modal, useModal} from "pulse-modal";
import {DebounceInput} from "react-debounce-input";
import {showErrorToast} from "../../../../../utils/toastUtils";
import {CalendarEntry, UserEntriesRequest} from "../../../../../api/grs";
import {checkIfUserHasOverlappingEntries} from "../../../AdminCalendarEntry/Components/Admin/StaffManagement/Blocks/Assignment/Helpers/staffAssignmentHelpers";
import {StaffAssignmentFormError} from "../../../../../utils/formUtils";
import moment from "moment";
import {checkUserEventsOverlap} from "../../../../../store/overlappingEntriesList/actions/OverlappingEntryListActions";
import {useDispatch} from "react-redux";

const AssignUser = (props: Props) => {
    const dispatch = useDispatch();
    const {toggle: rateToggle, isShown: isRateShown} = useModal();
    const {toggle: validAssignmentToggle, isShown: isValidAssignmentShown} = useModal();

    const [rate, setRate] = useState<number>(0);
    const [validAssignmentAction, setValidAssignmentAction] = useState<StaffAssignmentFormError>();

    useEffect(() => {
        if (isRateShown) setRate(0);
    }, [isRateShown]);

    async function validateAssignment() {
        const {action} = props;

        // @ts-ignore
        const overlappingEvents: CalendarEntry[] = await getOverlappingEvents(
            action.interest.user.staffId,
            action.entry
        );

        const checkIfUserIsDoubleBooked = await checkIfUserHasOverlappingEntries(
            overlappingEvents,
            action.interest.user,
            action.entry
        );

        if (checkIfUserIsDoubleBooked.valid) {
            rateToggle();
            return;
        }

        setValidAssignmentAction(checkIfUserIsDoubleBooked);
        validAssignmentToggle();
    }

    const getOverlappingEvents = async (userId: string, entity: CalendarEntry) => {
        const {startDate} = entity;

        const request: UserEntriesRequest = {
            startDate: moment.unix(startDate).clone().startOf("day").unix(),
            endDate: moment.unix(startDate).clone().endOf("day").unix(),
            userId
        };

        try {
            return await dispatch(checkUserEventsOverlap(request));
        } catch (e: any) {
            showErrorToast("Could not check if staff member has overlapping events");
        }
    };

    return (
        <React.Fragment>
            <MCButton
                size={ButtonSize.Large}
                roundedCorner
                innerValue={"Accept"}
                onClick={validateAssignment}
                colour={ButtonColourOptions.Yellow}
            />
            <Modal
                isShown={isValidAssignmentShown}
                onClose={validAssignmentToggle}
                title={"Assignment Overlap"}
                modalSize={"sm"}
                bodyChildren={
                    <div className="row ml-0 mr-0">
                        <p>{validAssignmentAction?.message}</p>
                    </div>
                }
                footerChildren={
                    <div className="row ml-0 mr-0">
                        {validAssignmentAction?.canBeAssigned && (
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"Save"}
                                onClick={async () => {
                                    rateToggle();
                                }}
                                colour={ButtonColourOptions.Yellow}
                                roundedCorner
                            />
                        )}
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Cancel"}
                            onClick={validAssignmentToggle}
                            colour={ButtonColourOptions.DarkBlue}
                            roundedCorner
                        />
                    </div>
                }
            />
            <Modal
                modalSize={"sm"}
                title={"Set Rate"}
                bodyChildren={
                    <React.Fragment>
                        <div className="row ml-0 mr-0">
                            <h6 className="mb-0">Set rate of assignment (£):</h6>
                            <DebounceInput
                                debounceTimeout={300}
                                value={rate}
                                onChange={(event) => setRate(+event.target.value)}
                                type={"number"}
                                className="input-fields"
                            />
                        </div>
                    </React.Fragment>
                }
                footerChildren={
                    <React.Fragment>
                        <div className="row ml-0 mr-0">
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"Save"}
                                onClick={async () => {
                                    if (rate <= 0) {
                                        showErrorToast("Rate must be greater than 0");
                                        return;
                                    }
                                    rateToggle();
                                    await props.onRateConfirmed(rate);
                                }}
                                colour={ButtonColourOptions.Yellow}
                                roundedCorner
                            />
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"Cancel"}
                                onClick={rateToggle}
                                roundedCorner
                                colour={ButtonColourOptions.DarkBlue}
                            />
                        </div>
                    </React.Fragment>
                }
                onClose={rateToggle}
                isShown={isRateShown}
            />
        </React.Fragment>
    );
};

export default AssignUser;

interface Props {
    onRateConfirmed: (rate: number) => Promise<void>;
    action: InterestedActionItemProp;
}
