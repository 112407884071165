import React from "react";
import FormHeader from "../../../../../Form/FormHeader";
import {CalendarEntry, CalendarTypeEnum} from "../../../../../../api/grs";
import ReadOnlyEntryEventDetails from "./EventCalendar/ReadOnlyEntryEventDetails";
import ReadOnlyFrontlineDetails from "./FrontlineCalendar/ReadOnlyFrontlineDetails";

const ReadOnlyEntryDetails = (props: CalendarEntry) => {
    /** Renders the correct entry details based on the calendar type */
    const renderCorrectEntryDetails = (calendarType: CalendarTypeEnum) => {
        switch (calendarType) {
            case CalendarTypeEnum.Event:
                return <ReadOnlyEntryEventDetails {...props} />;
            case CalendarTypeEnum.Frontline:
                return <ReadOnlyFrontlineDetails {...props} />;
            default:
                return <div />;
        }
    };
    return (
        <React.Fragment>
            <FormHeader headerName={"Event Details"} />
            {renderCorrectEntryDetails(props.calendarType)}
        </React.Fragment>
    );
};

export default ReadOnlyEntryDetails;
