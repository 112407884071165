import React, {useEffect, useState} from "react";
import {CalendarEntryListRequest, CalendarTypeEnum} from "../../../../../api/grs";
import {useDispatch} from "react-redux";
import GlobalMonthViewCalendar from "./../Desktop/Month/GlobalMonthViewCalendar";
import {WithServiceState} from "store-fetch-wrappers/dist";
import {useUserData} from "../../../../Hooks/useUserData";
import GlobalWeekViewCalendar from "./../Desktop/Week/GlobalWeekViewCalendar";
import {useGlobalCalendar} from "../../Hooks/useGlobalCalendar";
import {
    getShiftsForGlobalCalendar,
    nullifyGlobalCalendarStore
} from "../../../../../store/globalCalendar/actions/GlobalCalendarActions";
import {nullifyCalendarEntriesListStore} from "../../../../../store/calendarEntryList/actions/CalendarEntryListActions";
import FilterContainer from "../../../../Filters/FilterContainer";
import GlobalCalendarWeekFilters from "./Week/GlobalCalendarWeekFilters";
import GlobalCalendarMonthFilters from "./Month/GlobalCalendarMonthFilters";
import moment from "moment";
import {useQuery} from "../../../../Hooks/useQuery";

const GlobalMonthlyCalendarServiceWrapper = WithServiceState(GlobalMonthViewCalendar);
const GlobalWeeklyCalendarServiceWrapper = WithServiceState(GlobalWeekViewCalendar);

const GlobalCalendarDesktop = () => {
    const {store, showWeekElements} = useGlobalCalendar();
    const dispatch = useDispatch();
    const user = useUserData();
    const query = useQuery();
    const [request, setRequest] = useState<CalendarEntryListRequest>(getInitialRequest(query));

    useEffect(() => {
        return function () {
            dispatch(nullifyCalendarEntriesListStore());
            dispatch(nullifyGlobalCalendarStore());
        };
    }, []);

    /** Gets a list of entries for the calendar */
    const onEntryListRequestChanged = (incomingRequest: CalendarEntryListRequestWithViewType) => {
        //Prevents hammering of service
        if (JSON.stringify(incomingRequest) === JSON.stringify(request)) return;
        setRequest(incomingRequest);
    };

    useEffect(() => {
        if (!request) return;
        if (user.username.length === 0) return;
        dispatch(getShiftsForGlobalCalendar(request));
    }, [user, request]);

    return (
        <React.Fragment>
            <FilterContainer closed={false}>
                <React.Fragment>
                    {showWeekElements ? (
                        <GlobalCalendarWeekFilters onChange={onEntryListRequestChanged} />
                    ) : (
                        <GlobalCalendarMonthFilters onChange={onEntryListRequestChanged} />
                    )}
                </React.Fragment>
            </FilterContainer>
            {showWeekElements ? (
                <GlobalWeeklyCalendarServiceWrapper
                    showLoadingText={true}
                    loaderType={"overlay"}
                    loaderWheelType={"three-ring"}
                    placeholderBlockCount={1}
                    loading={store.loading}
                    error={store.error}
                    data={{
                        items: store.data?.results || [],
                        initialTime: request
                            ? moment.unix(request.startDate).add(1, "week")
                            : moment().startOf("isoWeek")
                    }}
                />
            ) : (
                <GlobalMonthlyCalendarServiceWrapper
                    showLoadingText={true}
                    loaderType={"overlay"}
                    loaderWheelType={"three-ring"}
                    placeholderBlockCount={1}
                    loading={store.loading}
                    error={store.error}
                    data={{
                        ...store.data?.results
                    }}
                />
            )}
        </React.Fragment>
    );
};

export default GlobalCalendarDesktop;

function getInitialRequest(query: any): CalendarEntryListRequest {
    const viewType = query.get("viewType");

    if (!viewType) {
        return getRequestForMonth(query);
    }

    switch (viewType) {
        case CalendarTypeEnum.Frontline:
            return getRequestForWeek(query);
        case CalendarTypeEnum.Event:
            return getRequestForMonth(query);
        default:
            return getRequestForMonth(query);
    }
}

function getRequestForMonth(query: any): CalendarEntryListRequest {
    const cIds = query.get("calendarIds");
    const month = query.get("month");
    const start = month ? moment.unix(+month) : moment();
    const startDate = start.clone().startOf("month");
    const endDate = start.clone().endOf("month");

    return {
        startDate: startDate.subtract(8, "days").unix(),
        endDate: endDate.add(8, "days").unix(),
        additionalCalendarIds: cIds ? getIdsFromQueryString(cIds) : undefined
    };
}

function getRequestForWeek(query: any): CalendarEntryListRequest {
    const cIds = query.get("calendarIds");
    const week = query.get("week");
    const start = week ? moment.unix(+week) : moment();
    const startDate = start.clone().startOf("isoWeek");
    const endDate = start.clone().endOf("isoWeek");

    return {
        startDate: startDate.unix(),
        endDate: endDate.unix(),
        additionalCalendarIds: cIds ? getIdsFromQueryString(cIds) : undefined
    };
}

function getIdsFromQueryString(value?: string): number[] | undefined {
    if (!value) return;

    return value.split(",").map((it) => +it);
}

export interface CalendarEntryListRequestWithViewType extends CalendarEntryListRequest {
    viewType?: CalendarTypeEnum;
}
