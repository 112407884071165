import {Enum} from "../../../utils/enumUtils";
import {StaffAccessLevel, UserData} from "../../../api/staff";
import {
    EventCalendarEntry,
    FrontlineCalendarEntry,
    StaffBlockTemplateListEntry,
    StaffLink,
    Venue
} from "../../../api/grs";
import moment from "moment";
import {formatUnixToHHmmddd} from "../../../utils/momentUtils";
import {StaffLinkAddedDetails} from "../../Pages/AdminCalendarEntry/Helpers/calenderEntryHelpers";

/** Props used for the staff dropdown */
export interface StaffDropdownProps extends BasicDropdownProps {
    entry?: EventCalendarEntry | FrontlineCalendarEntry;
    searchable: boolean;
    accessLevelFilter?: StaffAccessLevel;
    changeOption: (staffMember: StaffLink) => void;
    clearable: boolean;
}

/** Props used for the staff dropdown */
export interface StaffBlockDropdownProps extends BasicDropdownProps {
    searchable: boolean;
    changeOption: (staffBlock: StaffBlockTemplateListEntry | undefined) => void;
    clearable: boolean;
}

/** Props used for the venue dropdown */
export interface VenueDropdownProps {
    calendarId?: number;
    initialVenue: Venue;
    changeOption: (venue: Venue) => void;
    clearable: boolean;
}

/** Props used for the staff assignment dropdown */
export interface StaffAssignmentDropdownProps {
    initialAssignment?: StaffLink;
    changeOption: (newAssignment?: StaffLinkAddedDetails) => void;
    isClearable: boolean;
    disabled?: boolean;
}

/** Props used for the generic type dropdown */
export interface GenericTypeDropDownProps<E extends Enum<E>> extends ExtendedDropdownProps {
    enumOptions: E;
    splitByCapitalLetter: boolean;
    appendToBody?: boolean;
}

/** Props used for the extended props for dropdown props */
export interface ExtendedDropdownProps extends DropDownProps {
    clearable: boolean;
    searchable: boolean;
}

/** Interface for Time Dropdown. Adds clearable field */
export interface TimeDropdownProps extends LimitedDropdownProps {
    clearable: boolean;
    entry: EventCalendarEntry | FrontlineCalendarEntry;
    optionsPerHour: number;
    daysToGenerate: number;
    isSearchable: boolean;
}

/** Props used for the extended props for basic dropdown props */
export interface DropDownProps extends BasicDropdownProps {
    changeOption: (id: number | string) => void;
}

/** limited access (id = undefined) and basic needed for dropdown props */
export interface LimitedDropdownProps {
    id?: number | string;
    disabled: boolean;
    changeOption: (id?: number | string) => void;
}

/** bare minimum needed for dropdown props */
export interface BasicDropdownProps {
    id: number | string | undefined;
    disabled: boolean;
}

/** Dropdown component takes these as arguments for its object. */
export interface DDProps {
    value: number | string;
    label: string;
}

/** Sorts the list of staff into dropdown props to be used by staff dropdown */
export function sortUserDataToDropdownProps(users: UserData[]): DDProps[] {
    const ddProps: DDProps[] = [];

    for (const user of users) {
        ddProps.push({
            label: `${user.firstName} ${user.lastName}`,
            value: user.username
        });
    }

    return ddProps;
}

/** Sorts the list of venues into dropdown props to be used by staff dropdown */
export function sortVenuesToDropdownProps(venues: Venue[]): DDProps[] {
    const ddProps: DDProps[] = [];
    for (const venue of venues) {
        ddProps.push({
            label: venue.name,
            value: venue.id
        });
    }

    return ddProps;
}

/** Sorts the list of assigned staff members in an entry into dropdown props to be used by staff dropdown */
export function sortAssignedStaffInEntryToDropdownProps(
    entry: EventCalendarEntry | FrontlineCalendarEntry
): DDProps[] {
    const ddProps: DDProps[] = [];

    for (const section of entry.requiredStaff.sections) {
        for (const group of section.groupings) {
            for (const assignment of group.assignments) {
                const staff = assignment.staffMember;

                if (staff && staff.staffId.length > 0 && staff.staffName.length > 0) {
                    ddProps.push({
                        label: staff.staffName,
                        value: staff.staffId
                    });
                }
            }
        }
    }

    return ddProps;
}

/** Converts the staffLinkList to dd props */
export function convertStaffLinkDetailsToDropdownProps(
    staffList: StaffLinkAddedDetails[]
): DDProps[] {
    const ddProps: DDProps[] = [];

    for (const staff of staffList) {
        ddProps.push({
            label: staff.staffName,
            value: staff.staffId
        });
    }
    return ddProps;
}

/** Get the selected item of the dropdown props from the id */
export function getSelectedDropdownOption(id: string | number | undefined, options: DDProps[]) {
    const index = options.findIndex((el: DDProps) => id === el.value);

    if (index < 0) {
        return options[0];
    }

    return options[index];
}

/** Generates time options for the entry */
export function generateDailyTimeOptions(
    entry: EventCalendarEntry | FrontlineCalendarEntry,
    optionsPerHour: number,
    numberOfDaysInHours = 24
): DDProps[] {
    const startDate = moment.unix(entry.startDate).startOf("day").unix();
    const options = generateTimeOptions(optionsPerHour, numberOfDaysInHours).map(
        (time: number) => startDate + time
    );
    const ddProps: DDProps[] = [];
    for (const timeOption of options) {
        ddProps.push({
            value: timeOption,
            label: formatUnixToHHmmddd(+timeOption)
        });
    }

    return ddProps;
}

/** Math functions to get epoch time in a readable format for the UI */
function generateTimeOptions(optionsPerHour: number, numberOfDaysInHours: number): number[] {
    const timeOptions: number[] = [];

    //3600 = 1 Hour
    const timeSegmentsPerHour = 3600 / optionsPerHour;
    let timeOffset = 0;

    //24 = Number of hours in a day.
    for (let i = 0; i < numberOfDaysInHours * optionsPerHour; ++i) {
        timeOptions.push(timeOffset);
        //Increment after it has been pushed to the list to get the starting hour of the day
        timeOffset += timeSegmentsPerHour;
    }
    return timeOptions;
}
