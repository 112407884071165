import {Calendar} from "../../../api/grs";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const CALENDAR_STORE_STATE = createStoreState("calendar");

interface CalendarLoading extends StoreServiceData<Calendar> {
    type: typeof CALENDAR_STORE_STATE.LOADING;
}
interface CalendarError extends StoreServiceData<Calendar> {
    type: typeof CALENDAR_STORE_STATE.ERROR;
}
interface CalendarSuccess extends StoreServiceData<Calendar> {
    type: typeof CALENDAR_STORE_STATE.SUCCESS;
}

export type CalendarDispatchTypes = CalendarLoading | CalendarError | CalendarSuccess;
