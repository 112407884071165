import React, {useEffect} from "react";
import {routeNames} from "../../Navigation/routeNames";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {useDispatch, useSelector} from "react-redux";
import {getVenuesForCalendarId} from "../../../store/venueList/actions/VenueListActions";
import VenueListTable from "./Components/VenueListTable";
import {WithServiceState} from "store-fetch-wrappers/dist";
import {RootStore} from "../../../store/Store";
import {usePageUrl} from "../../Hooks/usePageUrl";
import {useCalendarPageDetails} from "../../Hooks/useCalendarPageDetails";
import {getAttendanceOverviewPath} from "../AttendanceOverview/Helpers/attendanceOverviewHelpers";
import {getEventReportPath} from "../Reports/EventReport/Helpers/eventReportHelpers";

const ServiceWrapper = WithServiceState(VenueListTable);

const VenueList = () => {
    const dispatch = useDispatch();
    const {fullPath} = usePageUrl();
    const venueListStore = useSelector((state: RootStore) => state.venueList);
    const {calendarId, calendarName} = useCalendarPageDetails();

    useEffect(() => {
        if (!calendarId) return;
        //Navigation items.
        const navItems: NavigationItem[] = [
            {
                name: routeNames.groupRosteringSystem.name,
                path: routeNames.groupRosteringSystem.path
            },
            {
                name: calendarName,
                path: getCalendarPathForCalendarChildRoutes(calendarName, +calendarId)
            },
            {
                name: routeNames.eventReport.name,
                path: getEventReportPath(calendarName, +calendarId)
            },
            {
                name: routeNames.venueList.name,
                path: fullPath
            },
            {
                name: routeNames.attendanceOverview.name,
                path: getAttendanceOverviewPath(calendarName, +calendarId)
            }
        ];

        dispatch(getVenuesForCalendarId(+calendarId));
        dispatch(setNavigationItems(navItems));
    }, []);
    return (
        <React.Fragment>
            <div className="page-container mt-4">
                <ServiceWrapper
                    placeholderBlockCount={1}
                    loaderWheelType={"three-ring"}
                    loaderType={"overlay"}
                    showLoadingText={true}
                    {...venueListStore}
                />
            </div>
        </React.Fragment>
    );
};

export default VenueList;

export function getVenuesListPath(calendarName: string, calendarId: number): string {
    return `${routeNames.calendar.path}/${encodeURI(calendarName)}${
        routeNames.venueList.path
    }?id=${calendarId}`;
}

/** Creates the link to go back to the calendar for sidebar and actions that occur in child routes of the calendar. */
export function getCalendarPathForCalendarChildRoutes(calendarName: string, calendarId: number) {
    return `${routeNames.calendar.path}/${encodeURI(calendarName)}?id=${calendarId}`;
}
