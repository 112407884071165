import {Dispatch} from "redux";
import {
    USER_INTEREST_LIST_STORE_STATE,
    UserInterestListDispatchTypes
} from "./UserInterestListActionTypes";
import GrsApiModel from "../../apiModel/GrsApiModel";
import {getDataFromServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";

/** Nullifies the venue list store. */
export const nullifyUserInterestListStore = () => {
    return async (dispatch: Dispatch<UserInterestListDispatchTypes>) => {
        dispatch({
            type: USER_INTEREST_LIST_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

/** Nullifies the venue list store. */
export const getUserInterestsForCalendarEntry = (entryId: number) => {
    return async (dispatch: Dispatch<UserInterestListDispatchTypes>) => {
        try {
            return await getDataFromServiceWithRedux(
                USER_INTEREST_LIST_STORE_STATE,
                dispatch,
                () => GrsApiModel.getCalendarApi().listInterestsForEntry(entryId),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: USER_INTEREST_LIST_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
