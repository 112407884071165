import React from "react";
import {AvailableShiftEntrySectionGroupProps} from "../../../../../AvailableShifts/Calendar/Components/AvailableShiftEntrySectionGroup";
import UrgentActionButton from "../../../../../AvailableShifts/Calendar/Components/UrgentActionButton";
import {useAvailableShiftsInfoUtils} from "../../../../../AvailableShifts/Calendar/Hooks/useAvailableShiftsInfoUtils";

function AvailableRolesDetails(props: AvailableShiftEntrySectionGroupProps) {
    const {urgentActionNeeded} = useAvailableShiftsInfoUtils();
    return (
        <React.Fragment>
            <div className="available-shift-calendar-info-item">
                <div className="available-shift-calendar-item-space-wrapper">
                    <div className="available-shift-calendar-event-item-spaces">Space(s)</div>
                    <div className="available-shift-calendar-event-item-space-count">
                        {props.group.availableSlotCount}
                    </div>
                </div>
                <div className="available-shift-calendar-event-item-details">
                    <div className="available-shift-calendar-event-item-detail-item-header">
                        {props.group.name}
                    </div>
                    <div className="available-shift-calendar-event-item-detail-item-body">
                        {props.section.name}
                    </div>
                </div>
                <div className="available-shift-calendar-action-wrapper">
                    {urgentActionNeeded(props.group) ? (
                        <UrgentActionButton {...props} isMobileView />
                    ) : (
                        <p className="mb-0">N/A</p>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}

export default AvailableRolesDetails;
