import React, {useEffect, useState} from "react";
import {GrsChangeEvent} from "../../../../../../utils/filterUtils";
import EntrySettingsToggle from "./EntrySettingsToggle";

const EntrySettings = (props: GrsChangeEvent<EntrySettingsProps>) => {
    const [entrySettings, setEntrySettings] = useState<EntrySettingsProps>({
        showEditButtons: false,
        showStaffDetails: true
    });
    /** Shows settings for staff details*/
    const onShowStaffDetailsToggled = (value: boolean) => {
        setEntrySettings({
            ...entrySettings,
            showStaffDetails: value
        });
    };

    /** Shows settings for staff details*/
    const onShowEditButtons = (value: boolean) => {
        setEntrySettings({
            ...entrySettings,
            showEditButtons: value
        });
    };

    useEffect(() => {
        props.onChange(entrySettings);
    }, [entrySettings]);

    return (
        <div className="row ml-0 mr-0 mt-3">
            <div className="col-sm-3">
                <div className="row ml-0 mr-0">
                    <div className="d-inline-block">
                        <h5 className="mb-0">
                            <b>Show Staff</b>
                        </h5>
                    </div>
                    <div className="ml-3 d-inline-block">
                        <EntrySettingsToggle
                            initialValue={true}
                            tooltipText={"Show staff details"}
                            onChange={onShowStaffDetailsToggled}
                        />
                    </div>
                </div>
            </div>
            <div className="col-sm-5">
                <div className="row ml-0 mr-0">
                    <div className="d-inline-block">
                        <h5 className="mb-0">
                            <b>Show Edit Controls</b>
                        </h5>
                    </div>
                    <div className="ml-3 d-inline-block">
                        <EntrySettingsToggle
                            initialValue={false}
                            tooltipText={"Show edit controls"}
                            onChange={onShowEditButtons}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EntrySettings;

export interface EntrySettingsProps {
    showStaffDetails: boolean;
    showEditButtons: boolean;
}
