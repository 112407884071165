import React from "react";
import {CalendarEventItem} from "../../store/fullCalendarItemList/actions/FullCalendarItemListActionTypes";
import {Moment} from "moment";
import {WeekViewWeekItem} from "./Helpers/weekViewCalendarHelpers";
import WeekViewDay, {WeekViewDayClickArgs} from "./Components/WeekViewDay";
import {useWeekViewCalendar} from "./Hooks/useWeekViewCalendar";
import {formatUnixToCustomFormat} from "../../utils/momentUtils";

const WeekViewCalendar = ({items, onEventClick, currentDate}: WeekViewCalendarProps) => {
    const {events, dates, getComplianceMarkup} = useWeekViewCalendar({
        items,
        onEventClick,
        currentDate
    });

    return (
        <React.Fragment>
            <div className="week-view-calendar-wrapper">
                <table className="week-view-calendar overflow-auto">
                    <tbody>
                        <tr className="week-view-calendar-row">
                            {dates.map((date: number, key: number) => {
                                return (
                                    <th
                                        key={key}
                                        className="text-center week-day-header-label week-view-calendar-cell pb-5 pt-1 week-view-calendar-cell-width"
                                    >
                                        {formatUnixToCustomFormat(date, "ddd")}
                                    </th>
                                );
                            })}
                        </tr>
                        <tr className="week-view-calendar-row">
                            {dates.map((date: number, key: number) => {
                                return (
                                    <td
                                        key={key}
                                        className="text-right body-font week-view-calendar-cell pb-3 pr-3 pt-1 week-view-calendar-cell-width"
                                        align={"right"}
                                    >
                                        <p>{formatUnixToCustomFormat(date, "MMM Do")}</p>

                                        {getComplianceMarkup(key)}
                                    </td>
                                );
                            })}
                        </tr>
                        {events.rows.length > 0 &&
                            events.rows.map((week: WeekViewWeekItem, weekIndex: number) => {
                                return (
                                    <tr className="week-view-calendar-row" key={weekIndex}>
                                        {week.entries.map(
                                            (
                                                day: CalendarEventItem | undefined,
                                                dayIndex: number
                                            ) => {
                                                return (
                                                    <WeekViewDay
                                                        key={dayIndex}
                                                        currentWeek={currentDate}
                                                        startTime={dates[dayIndex]}
                                                        onEventClick={onEventClick}
                                                        entry={day}
                                                    />
                                                );
                                            }
                                        )}
                                    </tr>
                                );
                            })}
                        <tr className="week-view-calendar-row">
                            {dates.map((date: number, key: number) => {
                                return (
                                    <WeekViewDay
                                        key={key}
                                        currentWeek={currentDate}
                                        startTime={date}
                                        onEventClick={onEventClick}
                                    />
                                );
                            })}
                        </tr>
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
};

export default WeekViewCalendar;

export interface WeekViewCalendarProps {
    items: CalendarEventItem[];
    currentDate: Moment;
    onEventClick: (args: WeekViewDayClickArgs) => void;
}
