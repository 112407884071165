import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {UserInterest} from "../../../api/grs";

export const USER_INTEREST_LIST_STORE_STATE = createStoreState("user_interest_list");

interface UserInterestListStoreLoading extends StoreServiceData<UserInterest[]> {
    type: typeof USER_INTEREST_LIST_STORE_STATE.LOADING;
}
interface UserInterestListStoreError extends StoreServiceData<UserInterest[]> {
    type: typeof USER_INTEREST_LIST_STORE_STATE.ERROR;
}
interface UserInterestListStoreSuccess extends StoreServiceData<UserInterest[]> {
    type: typeof USER_INTEREST_LIST_STORE_STATE.SUCCESS;
}

export type UserInterestListDispatchTypes =
    | UserInterestListStoreError
    | UserInterestListStoreSuccess
    | UserInterestListStoreLoading;
