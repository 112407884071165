import React from "react";
import Tooltip from "../Tooltip/Tooltip";
import Icon, {IconType} from "../Icon/Icon";
import {Modal, useModal} from "pulse-modal";
import {Loading} from "store-fetch-wrappers";
import MCButton, {ButtonColourOptions, ButtonSize} from "../Button/MCButton";
import {showSuccessToast} from "../../utils/toastUtils";

const DeleteTableItemAction = ({loading, itemName, onConfirm, modalTitle, modalMessage}: Props) => {
    const {isShown, toggle} = useModal();

    const onDeletionConfirmed = async () => {
        const success = await onConfirm();

        if (!success) return;

        showSuccessToast(`Deleted ${itemName}`);
        toggle();
    };
    return (
        <React.Fragment>
            <span className={"ml-3"}>
                <Tooltip
                    tooltipText={`Delete ${itemName}`}
                    size={"md"}
                    place={"left"}
                    rootElement="span"
                >
                    <Icon
                        rootElement="Span"
                        className="cursor-pointer"
                        icon={IconType.Bin}
                        size={"Medium"}
                        onClick={toggle}
                    />
                </Tooltip>
            </span>
            <Modal
                isShown={isShown}
                onClose={toggle}
                title={modalTitle}
                modalSize={"sm"}
                bodyChildren={
                    <div className={"row ml-0 mr-0"}>
                        <p>{modalMessage}</p>
                        {loading && <Loading type={"three-ring"} showLoadingText={false} />}
                    </div>
                }
                footerChildren={
                    <div className={"row ml-0 mr-0"}>
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Yes"}
                            onClick={onDeletionConfirmed}
                            colour={ButtonColourOptions.Yellow}
                            roundedCorner
                        />
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"No"}
                            onClick={toggle}
                            colour={ButtonColourOptions.DarkBlue}
                            roundedCorner
                        />
                    </div>
                }
            />
        </React.Fragment>
    );
};

export default DeleteTableItemAction;

interface Props {
    loading: boolean;
    modalTitle: string;
    modalMessage: string;
    onConfirm: () => Promise<boolean>;
    itemName: string;
}
