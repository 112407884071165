import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {XeroTrackingInfo} from "../../../api/grs";

export const XERO_TRACKING_STORE = createStoreState("xero_tracking_store_state");

interface XeroTrackingLoading extends StoreServiceData<XeroTrackingInfo> {
    type: typeof XERO_TRACKING_STORE.LOADING;
}

interface XeroTrackingError extends StoreServiceData<XeroTrackingInfo> {
    type: typeof XERO_TRACKING_STORE.ERROR;
}

interface XeroTrackingSuccess extends StoreServiceData<XeroTrackingInfo> {
    type: typeof XERO_TRACKING_STORE.SUCCESS;
}

export type XeroTrackingDispatchTypes =
    | XeroTrackingLoading
    | XeroTrackingError
    | XeroTrackingSuccess;
