import {ClinicalGrade, UserData} from "../../../api/staff";
import {capitalizeFirstLetter, getUiFriendlyTextWithSpaces} from "../../../utils/textUtils";
import {CalendarEntry, Venue} from "../../../api/grs";

// The max amount of characters a tag can be
export const maxTagLength = 100;

export interface SelectedSMSStaffMember {
    staffId: string;
    staffName: string;
    selectedByTags: string[];
}

/** Gets filters for dropdown */
export function getSmsFiltersFromStaffList(staffList: UserData[]): string[] {
    const tags: string[] = [];
    for (const user of staffList) {
        tags.push(...processUserFlags(user.flags));

        if (user.clinicalGrade === ClinicalGrade.None) continue;

        tags.push(user.clinicalGrade.toLowerCase());
    }

    return toUniqueArray(alphabetizeAsc(tags));
}

function processUserFlags(flags: string[] | undefined): string[] {
    if (!flags) return [];

    return flags
        .map((flag) => flag.toLowerCase())
        .filter((lwr) => lwr.length > 0 && lwr.length < maxTagLength);
}

/** Gets staffIds based on tags selected */
export function getStaffMembersFromSelectedTags(
    tags: string[],
    staffList: UserData[]
): SelectedSMSStaffMember[] {
    const alphabetizedTags = alphabetizeAsc(tags);

    if (tags.length === 1) {
        return getORTags(alphabetizedTags, staffList);
    }

    return getANDTags(alphabetizedTags, staffList);
}

function getANDTags(tags: string[], staffList: UserData[]): SelectedSMSStaffMember[] {
    const smsStaffMember: SelectedSMSStaffMember[] = [];
    for (const staff of staffList) {
        const tagsForStaffMember = alphabetizeAsc(getAllApplicableTagsForUser(staff));

        if (!stringArrayEquals(tagsForStaffMember, tags)) {
            continue;
        }

        smsStaffMember.push({
            staffId: staff.username,
            staffName: getStaffName(staff),
            selectedByTags: tagsForStaffMember
        });
    }
    return toUniqueArray(smsStaffMember);
}

function getORTags(tags: string[], staffList: UserData[]): SelectedSMSStaffMember[] {
    const smsStaffMember: SelectedSMSStaffMember[] = [];
    for (const tag of tags) {
        for (const staff of staffList) {
            if (staff.clinicalGrade.toLowerCase() === tag) {
                smsStaffMember.push({
                    staffId: staff.username,
                    staffName: getStaffName(staff),
                    selectedByTags: [tag]
                });
            }
            const tagExistsInFlags = doesTagExistInUserFlags(staff, tag);
            if (!tagExistsInFlags) continue;

            smsStaffMember.push({
                staffId: staff.username,
                staffName: getStaffName(staff),
                selectedByTags: [tag]
            });
        }
    }

    return toUniqueArray(smsStaffMember);
}

function alphabetizeAsc(array: string[]): string[] {
    return array.sort((a, b) => a.localeCompare(b));
}

function getAllApplicableTagsForUser(user: UserData): string[] {
    return [...processUserFlags(user.flags), user.clinicalGrade.toLowerCase()].filter(
        (tag) => tag !== ClinicalGrade.None.toLowerCase()
    );
}

export function processTag(tag: string) {
    switch (tag) {
        case ClinicalGrade.FirstResponder.toLowerCase():
            return "First Responder";
        case ClinicalGrade.EAC.toLowerCase():
            return "Emergency Ambulance Crew";
        default:
            return getUiFriendlyTextWithSpaces(tag);
    }
}

/** Check for strict equals for tag */
function doesTagExistInUserFlags(user: UserData, tag: string) {
    if (!user.flags) return false;
    for (const flag of user.flags) {
        if (flag.toLowerCase() === tag) {
            return true;
        }
    }
    return false;
}

function toUniqueArray<T>(incomingArray: T[]): T[] {
    return incomingArray.filter((item, i, array) => array.indexOf(item) === i);
}

function getStaffName({firstName, lastName}: UserData) {
    return `${firstName} ${lastName}`;
}

function stringArrayEquals(a: string[], b: string[]): boolean {
    return (
        Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index])
    );
}

export function getMetaDataForRequest(
    staffMembers: SelectedSMSStaffMember[],
    sendToEventStaff: boolean,
    entry?: CalendarEntry,
    venues?: Venue[]
): {[p: string]: string} {
    const staffMetaTags = sendToEventStaff ? undefined : getMetaFromStaffTags(staffMembers);
    const venueMeta = venues && entry ? getVenueNameMeta(entry, venues) : undefined;
    const entryMeta = entry ? getShiftNameMeta(entry) : undefined;

    return {
        ...venueMeta,
        ...entryMeta,
        ...staffMetaTags
    };
}

function getShiftNameMeta(entry: CalendarEntry): {[p: string]: string} {
    const meta = entry.description || "New Calendar Entry";
    return {
        [meta]: meta
    };
}

function getVenueNameMeta(entry: CalendarEntry, venues: Venue[]): {[p: string]: string} {
    const venue = venues.find((v) => v.id === entry.venueId);
    const meta = venue ? venue.name : "Deleted Venue";
    return {
        [meta]: meta
    };
}

function getMetaFromStaffTags(staffMembers: SelectedSMSStaffMember[]): {[p: string]: string} {
    const metas: string[] = [];

    for (const staff of staffMembers) {
        metas.push(...staff.selectedByTags);
    }

    return metas.reduce(
        (a, v) => ({...a, [decipherMetaDataValue(v)]: decipherMetaDataValue(v)}),
        {}
    );
}

function decipherMetaDataValue(value: string): string {
    switch (value) {
        case "eac":
            return "Emergency Ambulance Crew";
        case "firstresponder":
            return "First Responder";
        default:
            return capitalizeFirstLetter(value);
    }
}
