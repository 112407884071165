/** Converts the minutes to milliseconds */
export function minutesToMilliseconds(x: number) {
    return x * 60000; //60000 = 1 minute in milliseconds
}

/** Gets the percentage from 2 numbers */
export function getPercentage(currentValue: number, totalValue: number) {
    if (totalValue === 0) {
        return 0;
    }

    return Math.ceil((currentValue / totalValue) * 100);
}

/** Generates a random number */
export function getRandomNumber(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

/** Calculates percentages */
export function getFlooredPercentage(value: number, max: number): number {
    return Math.floor((value / max) * 100);
}

export function isEven(value: number): boolean {
    return value % 2 === 0;
}
