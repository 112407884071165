import {
    CalendarEntry,
    CalendarSummary,
    CalendarTypeEnum,
    UserInterest,
    Venue
} from "../../../../../api/grs";
import React, {Fragment, ReactNode} from "react";
import {formatUnixToCustomFormat} from "../../../../../utils/momentUtils";
import {getShiftTimes} from "../../Helpers/availableShiftsListHelpers";

export function getBulkShiftTime(entry: CalendarEntry) {
    return (
        <Fragment>
            <p className="mb-0">
                <b>{formatUnixToCustomFormat(entry.startDate, "dddd Do")}</b>
            </p>
            <p className="mb-0">{getShiftTimes(entry)}</p>
        </Fragment>
    );
}

export function getShiftDetails({section, group}: UserInterest) {
    return (
        <Fragment>
            <p className="mb-0">
                <b>{section.name}</b>
            </p>
            <p className="mb-0">{group.name}</p>
        </Fragment>
    );
}

export function getLocationName(
    entry: CalendarEntry,
    venue?: Venue,
    calendarSummary?: CalendarSummary
): ReactNode {
    const entryFallbackName = "New Calendar Entry";
    const calendarName = `${calendarSummary ? `${calendarSummary.name}` : ""}`;
    const venueName = `${venue ? `${venue.name}` : entryFallbackName}`;
    switch (entry.calendarType) {
        case CalendarTypeEnum.Event:
            return (
                <Fragment>
                    <p className="mb-0">
                        <b>{venueName}</b>
                    </p>
                    <p className="mb-0">{calendarName}</p>
                </Fragment>
            );
        case CalendarTypeEnum.Frontline:
            return entry.description || entryFallbackName;
    }
}
