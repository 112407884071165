import React from "react";
import {CalendarEventItem} from "../../../../../../store/fullCalendarItemList/actions/FullCalendarItemListActionTypes";
import {Moment} from "moment";
import WeekViewCalendar from "../../../../../Calendar/WeekViewCalendar";
import {usePageUrl} from "../../../../../Hooks/usePageUrl";
import {getQueryStringsForViewFrontlineCalendarEntry} from "../../../../../Calendar/Helpers/weekViewCalendarHelpers";
import {useHistory, useParams} from "react-router-dom";
import {routeNames} from "../../../../../Navigation/routeNames";
import {WeekViewDayClickArgs} from "../../../../../Calendar/Components/WeekViewDay";

function GlobalWeekViewCalendar(props: Props) {
    const {fullPath} = usePageUrl();
    const params: any = useParams();
    const history = useHistory();

    function viewCalendarEntry({entry}: WeekViewDayClickArgs) {
        if (!entry) return;
        localStorage.setItem("calendar_redirect", fullPath);
        const path = `${routeNames.calendar.path}/${params.calendarName}${routeNames.editCalendarEntry.path}/${entry.id}`;
        const search = getQueryStringsForViewFrontlineCalendarEntry(
            props.initialTime.unix(),
            +entry.groupId
        );
        history.push({
            pathname: path,
            search
        });
    }
    return (
        <React.Fragment>
            {props.initialTime && (
                <WeekViewCalendar
                    items={props.items}
                    currentDate={props.initialTime}
                    onEventClick={viewCalendarEntry}
                />
            )}
        </React.Fragment>
    );
}

export default GlobalWeekViewCalendar;

interface Props {
    items: CalendarEventItem[];
    initialTime: Moment;
}
