import {useParams} from "react-router-dom";
import {useQuery} from "./useQuery";

export function useCalendarPageDetails() {
    const params: any = useParams();
    const query = useQuery();

    const calendarId = query.get("id");
    const calendarName = decodeURI(params.calendarName);

    return {calendarId, calendarName};
}
