import {StoreServiceData} from "store-fetch-wrappers";
import {createStoreState} from "store-fetch-wrappers/dist";
import {Region} from "../../../../api/staff";

export const REGION_STORE = createStoreState("region");

interface RegionLoading extends StoreServiceData<Region> {
    type: typeof REGION_STORE.LOADING;
}
interface RegionError extends StoreServiceData<Region> {
    type: typeof REGION_STORE.ERROR;
}
interface RegionSuccess extends StoreServiceData<Region> {
    type: typeof REGION_STORE.SUCCESS;
}

export type RegionDispatchTypes = RegionLoading | RegionError | RegionSuccess;
