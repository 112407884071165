import React from "react";
import {EventCalendarEntry, FrontlineCalendarEntry} from "../../../../../../api/grs";
import {useDispatch} from "react-redux";
import {setCalendarEntry} from "../../../../../../store/calendarEntry/actions/CalendarEntryActions";
import FormRow from "../../../../../Form/FormRow";
import {DebounceInput} from "react-debounce-input";

const CalendarEntryNotes = (props: EventCalendarEntry | FrontlineCalendarEntry) => {
    const dispatch = useDispatch();
    return (
        <React.Fragment>
            <div className="row ml-0 mr-0">
                <div className="col p-0">
                    <FormRow rowName={"Entry Notes"} columnDetailClassName={"pl-0 pr-0"}>
                        <DebounceInput
                            style={{minHeight: `100px`}}
                            className="input-fields text-area-inputs"
                            element={"textarea"}
                            value={props.notes}
                            onChange={(event) => {
                                dispatch(
                                    setCalendarEntry({
                                        ...props,
                                        notes: event.target.value
                                    })
                                );
                            }}
                        />
                    </FormRow>
                </div>
            </div>
        </React.Fragment>
    );
};

export default CalendarEntryNotes;
