import {Dispatch} from "redux";
import {
    GlobalStaffBlockListDispatchTypes,
    GLOBAL_STAFF_BLOCK_LIST_STORE
} from "./GlobalStaffBlockListActionTypes";
import GrsApiModel from "../../apiModel/GrsApiModel";
import {getDataFromServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";

export const nullifyGlobalStaffBlockListStore = () => {
    return async (dispatch: Dispatch<GlobalStaffBlockListDispatchTypes>) => {
        dispatch({
            type: GLOBAL_STAFF_BLOCK_LIST_STORE.SUCCESS,
            error: null,
            loading: false,
            data: []
        });
    };
};

export const getStaffBlockListFromService = () => {
    return async (dispatch: Dispatch<GlobalStaffBlockListDispatchTypes>) => {
        try {
            return await getDataFromServiceWithRedux(
                GLOBAL_STAFF_BLOCK_LIST_STORE,
                dispatch,
                () => GrsApiModel.getCalendarApi().listStaffBlockTemplates(),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: GLOBAL_STAFF_BLOCK_LIST_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
