import {AvailableShiftGrouping, AvailableShiftSection} from "../../../../../api/grs";
import {useUserData} from "../../../../Hooks/useUserData";
import {canUserSeeGroup, canUserSeeSection} from "../Helpers/availableShiftsListCalendar";

export function useAvailableShiftsInfoUtils() {
    const user = useUserData();

    const showSection = (section: AvailableShiftSection) =>
        canUserSeeSection(user.clinicalGrade, section);

    const showGroup = (group: AvailableShiftGrouping) => canUserSeeGroup(user.clinicalGrade, group);

    const urgentActionNeeded = (group: AvailableShiftGrouping) => {
        if (group.availableSlotCount === 0) {
            return false;
        }
        if (group.takenSlotCount === 0) {
            return true;
        }
        return group.takenSlotCount > 0 && group.availableSlotCount > 0;
    };

    const indicatorClassName = (group: AvailableShiftGrouping) => {
        if (group.availableSlotCount === 0) {
            return "assignment-base attended interactive";
        }
        if (group.takenSlotCount === 0) {
            return "assignment-base unassigned interactive";
        }
        if (group.takenSlotCount > 0 && group.availableSlotCount > 0) {
            return "assignment-base waitingAccept interactive";
        }
        return "";
    };

    const tooltipText = (group: AvailableShiftGrouping) => {
        if (group.availableSlotCount === 0) {
            return "There are no more spaces left on in this group";
        }
        if (group.takenSlotCount === 0) {
            return "Staff member required";
        }
        if (group.takenSlotCount > 0 && group.availableSlotCount > 0) {
            return "Staff member required";
        }

        return "";
    };

    return {
        showSection,
        showGroup,
        urgentActionNeeded,
        tooltipText,
        indicatorClassName
    };
}
