import {Dispatch} from "redux";
import {XERO_TRACKING_STORE, XeroTrackingDispatchTypes} from "./XeroTrackingActionTypes";
import GrsApiModel from "../../apiModel/GrsApiModel";
import {showErrorToast} from "../../../utils/toastUtils";

export function nullifyXeroTrackingStore() {
    return async function (dispatch: Dispatch<XeroTrackingDispatchTypes>) {
        dispatch({
            type: XERO_TRACKING_STORE.SUCCESS,
            loading: false,
            error: null,
            data: {
                categories: []
            }
        });
    };
}

export function getXeroTrackingInfo() {
    return async function (dispatch: Dispatch<XeroTrackingDispatchTypes>) {
        try {
            const resp = await GrsApiModel.getInvoiceApi().getXeroTrackingFullInfo();

            if (resp.data) {
                dispatch({
                    type: XERO_TRACKING_STORE.SUCCESS,
                    loading: false,
                    error: null,
                    data: resp.data
                });

                return true;
            }

            return false;
        } catch (e: any) {
            dispatch({
                type: XERO_TRACKING_STORE.ERROR,
                loading: false,
                error: e
            });
            showErrorToast("Could not get Xero Tracking Information");
        }
    };
}
