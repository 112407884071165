import {Dispatch} from "redux";
import {REGION_LIST_STORE, RegionListDispatchTypes} from "./RegionListActionTypes";
import {getDataFromServiceWithRedux} from "store-fetch-wrappers";
import StaffApiModel from "../../../apiModel/StaffApiModel";
import {statusCodeCallback} from "../../../helpers/storeHelpers";

export const clearRegionListStore = () => {
    return async (dispatch: Dispatch<RegionListDispatchTypes>) => {
        dispatch({
            type: REGION_LIST_STORE.SUCCESS,
            error: null,
            loading: false,
            data: []
        });
    };
};

export const getRegionListing = () => {
    return async (dispatch: Dispatch<RegionListDispatchTypes>) => {
        try {
            return await getDataFromServiceWithRedux(
                REGION_LIST_STORE,
                dispatch,
                () => StaffApiModel.getRegionsApi().getAllRegions(),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: REGION_LIST_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
