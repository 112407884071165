import React, {Fragment, ReactNode} from "react";
import {StaffInterestQueryResponse, Venue} from "../../../../../api/grs";
import {useSelector} from "react-redux";
import {RootStore} from "../../../../../store/Store";
import {PulseTable} from "pulse_table";
import {InterestedActionItemProp} from "../Tables/InterestedStaffMembersTable";
import AcceptRegisterRequest from "../Actions/AcceptRegisterRequest";
import {getLocationName, getShiftDetails} from "../Helpers/shiftOverviewHelpers";
import {ClinicalGrade, UserData} from "../../../../../api/staff";
import {getUiFriendlyText} from "../../../../../utils/textUtils";

function BulkAcceptListTable(props: StaffInterestQueryResponse) {
    const venueList = useSelector((state: RootStore) => state.venueList.data || []);
    const users = useSelector((state: RootStore) => state.staffList.data || []).toMap("username");
    return (
        <Fragment>
            <PulseTable
                items={toBulkAcceptTableRow(props, venueList, users)}
                headers={{
                    staffMember: "Staff Member",
                    clinicalGrade: "Clinical Grade",
                    shiftLocation: "Shift Location",
                    shiftDetails: "Shift Details",
                    actions: "Actions"
                }}
                customRenderers={{
                    shiftLocation: (item: BulkAcceptTableRow) => item.shiftLocation,
                    shiftDetails: (item: BulkAcceptTableRow) => item.shiftDetails,
                    actions: (item: BulkAcceptTableRow) => (
                        <React.Fragment>
                            <AcceptRegisterRequest {...item.actions} location={"shifts-list"} />
                        </React.Fragment>
                    )
                }}
                noItemsSection={
                    <div className="row ml-0 mr-0 fadeIn">
                        <div className="col">
                            <h5 className="text-center p-3">
                                There are no pending staff register requests for this week
                            </h5>
                        </div>
                    </div>
                }
            />
        </Fragment>
    );
}

export default BulkAcceptListTable;

interface BulkAcceptTableRow {
    staffMember: string;
    clinicalGrade: string;
    shiftLocation: ReactNode;
    shiftDetails: ReactNode;
    actions: InterestedActionItemProp;
}

function toBulkAcceptTableRow(
    resp: StaffInterestQueryResponse,
    venueList: Venue[],
    userMap: Map<string | number, UserData>
): BulkAcceptTableRow[] {
    return resp.interestList.map((interest) => {
        const entry = resp.entries.find((ent) => ent.id === interest.entryId)!;
        const venue = venueList.find((ven) => ven.id === entry.venueId);
        const calendar = (resp.calendarSummaries || []).find((cal) => cal.id === entry.calendarId);
        const clinicalGrade = userMap.get(interest.user.staffId)?.clinicalGrade;
        return {
            staffMember: interest.user.staffName,
            clinicalGrade: getUiFriendlyClinicalGrade(clinicalGrade),
            shiftLocation: getLocationName(entry, venue, calendar),
            shiftDetails: getShiftDetails(interest),
            actions: {
                interest: interest,
                entry: entry
            }
        };
    });
}

function getUiFriendlyClinicalGrade(clinicalGrade?: ClinicalGrade) {
    if (!clinicalGrade) return "";

    switch (clinicalGrade) {
        case ClinicalGrade.None:
            return "Clinical Grade not set";
        case ClinicalGrade.EAC:
            return "Emergency Ambulance Crew";
        default:
            return getUiFriendlyText(clinicalGrade);
    }
}

declare global {
    interface Array<T> {
        toMap(keyIdentifier?: string): Map<number | string, T>;
    }
}

Array.prototype.toMap = function (keyIdentifier?: string) {
    return new Map(this.map((item) => [keyIdentifier ? item[keyIdentifier] : item.id, item]));
};
