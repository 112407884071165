import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {EventCalendarEntry, FrontlineCalendarEntry} from "../../../api/grs";

export const CALENDAR_ENTRY_STORE_STATE = createStoreState("calendar_entry");

interface CalendarEntryStoreLoading
    extends StoreServiceData<EventCalendarEntry | FrontlineCalendarEntry> {
    type: typeof CALENDAR_ENTRY_STORE_STATE.LOADING;
}
interface CalendarEntryStoreError
    extends StoreServiceData<EventCalendarEntry | FrontlineCalendarEntry> {
    type: typeof CALENDAR_ENTRY_STORE_STATE.ERROR;
}
interface CalendarEntryStoreSuccess
    extends StoreServiceData<EventCalendarEntry | FrontlineCalendarEntry> {
    type: typeof CALENDAR_ENTRY_STORE_STATE.SUCCESS;
}

export type CalendarEntryDispatchTypes =
    | CalendarEntryStoreLoading
    | CalendarEntryStoreError
    | CalendarEntryStoreSuccess;
