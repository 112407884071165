import {Dispatch} from "redux";
import {
    HORIZONTAL_CALENDAR_STORE,
    HorizontalCalendarDispatchTypes,
    HorizontalCalendarState
} from "./HorizontalCalendarActionTypes";

/** Clears the state of the store. */
export const nullifyHorizontalCalendarStore = () => {
    return async (dispatch: Dispatch<HorizontalCalendarDispatchTypes>) => {
        dispatch({
            type: HORIZONTAL_CALENDAR_STORE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

/** Clears the state of the store. */
export const setHorizontalCalendarState = (state: HorizontalCalendarState) => {
    return async (dispatch: Dispatch<HorizontalCalendarDispatchTypes>) => {
        dispatch({
            type: HORIZONTAL_CALENDAR_STORE.LOADING,
            error: null,
            loading: true
        });

        dispatch({
            type: HORIZONTAL_CALENDAR_STORE.SUCCESS,
            error: null,
            loading: false,
            data: state
        });
    };
};
