import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {DutyManagerReportPagedResponse} from "../../../api/grs";

export const DUTY_MANAGER_REPORT_PAGED_STORE_STATE = createStoreState("duty_manager_report_paged");

interface DutyManagerReportPagedLoading extends StoreServiceData<DutyManagerReportPagedResponse> {
    type: typeof DUTY_MANAGER_REPORT_PAGED_STORE_STATE.LOADING;
}
interface DutyManagerReportPagedError extends StoreServiceData<DutyManagerReportPagedResponse> {
    type: typeof DUTY_MANAGER_REPORT_PAGED_STORE_STATE.ERROR;
}
interface DutyManagerReportPagedSuccess extends StoreServiceData<DutyManagerReportPagedResponse> {
    type: typeof DUTY_MANAGER_REPORT_PAGED_STORE_STATE.SUCCESS;
}

export type DutyManagerReportPagedDispatchTypes =
    | DutyManagerReportPagedSuccess
    | DutyManagerReportPagedError
    | DutyManagerReportPagedLoading;
