import {useContext, useState} from "react";
import {CalendarEntryContext} from "../../../../../../../../Context/CalendarEntryContext";
import {RepeatEntryRequest, RepeatType} from "../../../../../../../../../api/grs";
import moment from "moment";
import {showErrorToast} from "../../../../../../../../../utils/toastUtils";
import {useDispatch} from "react-redux";
import {createRepetitiveEntries} from "../../../../../../../../../store/repeatEntry/actions/RepeatEntryActions";
import {formatUnixToDDMMYYYY} from "../../../../../../../../../utils/momentUtils";

export function useRepetitiveEntryForm() {
    const entry = useContext(CalendarEntryContext);
    const dispatch = useDispatch();
    const [request, setRequest] = useState<RepeatEntryRequest>({
        baseEntryId: entry.id,
        type: RepeatType.Day,
        spacing: 1,
        endDateExclusive: moment().add(1, "day").startOf("day").unix()
    });

    function updateRequest(newRequest: RepeatEntryRequest): void {
        setRequest(newRequest);
    }

    async function save(): Promise<boolean> {
        const valid = validateRequest();
        if (!valid) return false;
        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return await dispatch(createRepetitiveEntries(request));
        } catch (e: any) {
            showErrorToast("Could not create repetitive entries. Contact System Administrator.");
            return false;
        }
    }

    function validateRequest(): boolean {
        const now = moment();
        const {endDateExclusive, spacing} = request;
        if (endDateExclusive < now.unix()) {
            showErrorToast("End date cannot be before today's date!");
            return false;
        }
        if (spacing <= 0) {
            showErrorToast("Spacing value must be more than 0!");
            return false;
        }
        if (spacing > 365) {
            showErrorToast("Spacing value must be less than or equal to 365!");
        }

        return true;
    }

    function repeatFrequencyMessage(): string {
        const {endDateExclusive, type, spacing} = request;

        const frequency = spacing > 1 ? `${spacing} ${type}s` : type;
        const end = formatUnixToDDMMYYYY(endDateExclusive);

        return `Entry will repeat every ${frequency} until the start of ${end}`;
    }

    return {
        updateRequest,
        save,
        request,
        repeatFrequencyMessage
    };
}
