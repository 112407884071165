import React, {useEffect, useState} from "react";
import {
    AssignmentState,
    CalendarEntry,
    EventCalendarEntry,
    FrontlineCalendarEntry,
    StaffAssignment
} from "../../../../api/grs";
import {getTotalAssignments} from "../../AdminCalendarEntry/Helpers/calenderEntryHelpers";
import {useDispatch} from "react-redux";
import {nullifyCalendarEntryStore} from "../../../../store/calendarEntry/actions/CalendarEntryActions";
import {useHistory} from "react-router-dom";
import {getAttendanceOverviewPath} from "../../AttendanceOverview/Helpers/attendanceOverviewHelpers";
import {useQuery} from "../../../Hooks/useQuery";
import {saveStaffAttendedToService} from "../../../../store/notifications/attended/actions/AttendedActions";
import FormHeader from "../../../Form/FormHeader";
import {PulseTable} from "pulse_table";
import NoItemsMessage from "../../../Table/NoItemsMessage";
import AttendanceToggle from "./AttendanceToggle";
import FormActionContainer from "../../../Form/FormActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import {showSuccessToast} from "../../../../utils/toastUtils";
import {useCalendarPageDetails} from "../../../Hooks/useCalendarPageDetails";

const AttendanceRegisterTable = (props: EventCalendarEntry | FrontlineCalendarEntry) => {
    const history = useHistory();
    const [assignments, setAssignments] = useState<StaffAssignment[]>([]);
    const dispatch = useDispatch();
    const query = useQuery();
    const {calendarName} = useCalendarPageDetails();

    useEffect(() => {
        return function () {
            dispatch(nullifyCalendarEntryStore());
        };
    }, []);

    useEffect(() => {
        const filteredAssignments = getTotalAssignments(props).filter(
            (assignment: StaffAssignment) => assignment.state !== AssignmentState.Unassigned
        );
        setAssignments(filteredAssignments);
    }, [props]);

    /** Update the list of assignments in place */
    const onAssignmentStateChanged = (incomingAssignment: StaffAssignment) => {
        if (!incomingAssignment.staffMember) return;
        const index = assignments.findIndex(
            (assignment: StaffAssignment) =>
                assignment.staffMember?.staffId === incomingAssignment.staffMember?.staffId
        );
        const assignment = assignments[index];
        assignment.state = incomingAssignment.state;
        setAssignments(
            assignments.map((item: StaffAssignment) =>
                item.id === assignment.id ? assignment : item
            )
        );
    };

    /** Save assignment */
    const saveAttendedAssignments = async () => {
        const staffIds: string[] = [];

        for (const assignment of assignments) {
            const staffMember = assignment.staffMember;
            if (!staffMember) continue;

            if (assignment.state === AssignmentState.Attended) {
                staffIds.push(staffMember.staffId);
            }
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(
            saveStaffAttendedToService({entryId: props.id, staffIds})
        );

        if (!success) return;
        showSuccessToast(`Saved attendance for ${props.description}`);
        backToMonthlyAttendance();
    };

    const backToMonthlyAttendance = () => {
        const month = query.get("month");
        const calendarId = query.get("calendarId");
        if (!calendarId) return;
        const path = getAttendanceOverviewPath(
            calendarName,
            +calendarId,
            month ? +month : undefined
        );

        history.push(path);
    };

    return (
        <React.Fragment>
            <FormHeader headerName={props.description || ""} />
            <PulseTable
                items={toTableRow(props)}
                headers={{
                    staff: "Staff",
                    attended: "Attended?"
                }}
                customRenderers={{
                    staff: (item: TableRow) => (
                        <React.Fragment>
                            <p className={"mb-0"}>{item.staff.staffMember?.staffName}</p>
                        </React.Fragment>
                    ),
                    attended: (item: TableRow) => (
                        <React.Fragment>
                            <AttendanceToggle
                                assignment={item.staff}
                                onChange={onAssignmentStateChanged}
                            />
                        </React.Fragment>
                    )
                }}
                noItemsSection={
                    <NoItemsMessage message={"There are no assignments for this shift"} />
                }
            />
            <FormActionContainer>
                <MCButton
                    size={ButtonSize.Large}
                    innerValue={"Save"}
                    onClick={saveAttendedAssignments}
                    colour={ButtonColourOptions.Yellow}
                    roundedCorner
                />
                <MCButton
                    size={ButtonSize.Large}
                    innerValue={"Cancel"}
                    onClick={backToMonthlyAttendance}
                    colour={ButtonColourOptions.DarkBlue}
                    roundedCorner
                />
            </FormActionContainer>
        </React.Fragment>
    );
};

export default AttendanceRegisterTable;

interface TableRow {
    staff: StaffAssignment;
    attended: AssignmentState;
}

function toTableRow(entry: CalendarEntry): TableRow[] {
    const filteredAssignments = getTotalAssignments(entry).filter(
        (assignment: StaffAssignment) => assignment.state !== AssignmentState.Unassigned
    );

    return filteredAssignments.map((item) => {
        return {
            staff: item,
            attended: item.state
        };
    });
}
