import {StoreServiceData} from "store-fetch-wrappers";
import {createStoreState} from "store-fetch-wrappers/dist";
import {GeoLocation, Region} from "../../../../api/staff";

export const GEOLOCATION_STORE = createStoreState("geolocation");

interface GeoLocationLoading extends StoreServiceData<GeoLocation> {
    type: typeof GEOLOCATION_STORE.LOADING;
}
interface GeoLocationError extends StoreServiceData<Region> {
    type: typeof GEOLOCATION_STORE.ERROR;
}
interface GeoLocationSuccess extends StoreServiceData<Region> {
    type: typeof GEOLOCATION_STORE.SUCCESS;
}

export type GeoLocationDispatchTypes = GeoLocationSuccess | GeoLocationError | GeoLocationLoading;
