import React, {useEffect} from "react";
import {DutyManagerReportEditableContext} from "./Context/DutyManagerReportEditable";
import {useDispatch, useSelector} from "react-redux";
import {useUserData} from "../../../../Hooks/useUserData";
import {usePageUrl} from "../../../../Hooks/usePageUrl";
import {useQuery} from "../../../../Hooks/useQuery";
import {RootStore} from "../../../../../store/Store";
import {
    getDutyManagerReportByReportId,
    nullifyDutyManagerReportStore
} from "../../../../../store/dutyManagerReport/actions/DutyManagerReportActions";
import {nullifyCalendarEntryStore} from "../../../../../store/calendarEntry/actions/CalendarEntryActions";
import EditDutyManagerReportForm from "./Components/EditDutyManagerReportForm";
import {setNavigationItems} from "../../../../../store/navigation/actions/NavigationActions";
import {getNavItemsForViewDutyManagerRoute} from "./Helpers/dutyManagerReportHelpers";
import {useParams} from "react-router-dom";
import {WithServiceState} from "store-fetch-wrappers/dist";
import useMcConfig from "../../../../Hooks/useMcConfig";
const DutyManagerReportFormServiceWrapper = WithServiceState(EditDutyManagerReportForm);

const ViewDutyManagerReport = () => {
    const dispatch = useDispatch();
    const user = useUserData();
    const {fullPath} = usePageUrl();
    const query = useQuery();
    const params: any = useParams();
    const dutyManagerReportStore = useSelector((state: RootStore) => state.dutyManagerReport);
    const {config} = useMcConfig();

    useEffect(() => {
        const calendarEntryId = query.get("calendarEntryId");
        if (!calendarEntryId) return;
        dispatch(getDutyManagerReportByReportId(+params.reportId, +calendarEntryId));
        return function () {
            dispatch(nullifyDutyManagerReportStore());
            dispatch(nullifyCalendarEntryStore());
        };
    }, []);

    useEffect(() => {
        if (user.username.length === 0) return;
        const redirectUri = localStorage.getItem("calendar_entry_redirect_uri");
        if (!redirectUri) return;
        dispatch(
            setNavigationItems(
                getNavItemsForViewDutyManagerRoute(user, fullPath, redirectUri, query, config)
            )
        );
    }, [user]);
    return (
        <React.Fragment>
            <DutyManagerReportEditableContext.Provider value={false}>
                <DutyManagerReportFormServiceWrapper
                    showLoadingText={true}
                    placeholderBlockCount={1}
                    loaderType={"overlay"}
                    loaderWheelType={"three-ring"}
                    {...dutyManagerReportStore}
                />
            </DutyManagerReportEditableContext.Provider>
        </React.Fragment>
    );
};

export default ViewDutyManagerReport;
