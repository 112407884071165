import {createContext} from "react";
import {
    CalendarEntryState,
    CalendarTypeEnum,
    EventCalendarEntry,
    FrontlineCalendarEntry
} from "../../api/grs";

/** Calendar Entry Context, initialised with default data so that null checks don't need to be made. */
export const CalendarEntryContext = createContext<EventCalendarEntry | FrontlineCalendarEntry>({
    id: 0,
    version: 0,
    calendarType: CalendarTypeEnum.Event,
    state: CalendarEntryState.Active,
    startDate: 0,
    endDate: 0,
    actualStartDate: undefined,
    actualEndDate: undefined,
    description: "New Calendar Entry",
    notes: "",
    requiredStaff: {sections: []},
    calendarId: 0,
    venueId: 0
});
