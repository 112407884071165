import React, {useEffect, useState} from "react";
import {
    EventReportListItemResponse,
    EventsReportType
} from "../../../../../store/eventReportList/actions/EventReportListActionTypes";
import {toArray} from "../../../../../utils/sortingUtils";
import {DonutChart, getDonutStat} from "../../../../../utils/donutChartUtils";
import {getPercentage} from "../../../../../utils/mathUtils";
import {getConfirmedStaffCountForEventReport} from "../Helpers/eventReportHelpers";
import moment from "moment";
import {
    formatUnixToMMMMYYYY,
    formatUnixToQYYYY,
    formatUnixToYYYY
} from "../../../../../utils/momentUtils";
import {
    vibrantBluePrimary,
    vibrantGreenPrimary,
    vibrantRedPrimary,
    vibrantYellowPrimary
} from "../../../../../utils/colourUtils";
import DonutChartList from "../../../../DonutChart/DonutChartList";

/** Component that holds the starts for the Event Reports */
const EventReportStats = (props: EventReportListItemResponse[]) => {
    const [donutList, setDonutList] = useState<DonutChart[]>([]);

    /** When props change, generate new donut charts that reflect the data received. */
    useEffect(() => {
        const reports: EventReportListItemResponse[] = toArray(props);

        const localDonutList: DonutChart[] = [];
        //Cycle through all reports and generate donut chart for each
        for (const report of reports) {
            const {totalAssignmentCount, confirmedCount} =
                getConfirmedStaffCountForEventReport(report);

            const stat: DonutChart = getDonutStat(
                `${getPercentage(confirmedCount, totalAssignmentCount)}%`,
                getEventStatChartLabel(report),
                totalAssignmentCount,
                confirmedCount,
                getDonutChartColour(report.reportType)
            );

            localDonutList.push(stat);
        }
        setDonutList(localDonutList);
    }, [props]);

    /** Generates a prefix and will return a different suffix dependent on the type of report it is */
    const getEventStatChartLabel = (report: EventReportListItemResponse) => {
        if (!report.startDateInclusive) return "Events Staffed";
        const prefix = "Events Staffed -";
        switch (report.reportType) {
            case EventsReportType.Monthly:
                return `${prefix} ${formatUnixToMMMMYYYY(
                    moment.unix(report.startDateInclusive).unix()
                )}`;
            case EventsReportType.Quarterly:
                return `${prefix} ${formatUnixToQYYYY(
                    moment.unix(report.startDateInclusive).unix()
                )}`;
            case EventsReportType.Yearly:
                return `${prefix} ${formatUnixToYYYY(
                    moment.unix(report.startDateInclusive).unix()
                )}`;
            default:
                return "Events Staffed";
        }
    };

    /** Gets the colour of the donut chart based on report type. */
    const getDonutChartColour = (reportType: EventsReportType) => {
        switch (reportType) {
            case EventsReportType.Monthly:
                return vibrantGreenPrimary;
            case EventsReportType.Quarterly:
                return vibrantYellowPrimary;
            case EventsReportType.Yearly:
                return vibrantBluePrimary;
            default:
                return vibrantRedPrimary;
        }
    };

    return (
        <React.Fragment>
            <div className="mt-4">
                <DonutChartList headerText={"Events Report Stats"} chartData={donutList} />
            </div>
        </React.Fragment>
    );
};

export default EventReportStats;
