import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {MOBILE_CALENDAR_VIEW, MobileCalendarView} from "../actions/MobileCalendarActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<MobileCalendarView>({
    staffShifts: {
        raw: {},
        converted: {}
    },
    availableShifts: {
        raw: {},
        converted: {}
    },
    dateRange: {startDateInclusive: 0, endDateExclusive: 0}
});

const mobileCalendarReducer = (
    state: StoreServiceData<MobileCalendarView> = defaultState,
    action: ServiceActionTypes<MobileCalendarView>
) => createReducer(state, action, MOBILE_CALENDAR_VIEW, () => showErrorToast(action.error));

export default mobileCalendarReducer;
