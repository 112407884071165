import React from "react";
import {CalendarEntry} from "../../../../../../api/grs";
import FormHeader from "../../../../../Form/FormHeader";
import CalendarEntryStaffBlockOverride from "./CalendarEntryStaffBlockOverride";
import CalendarEntryStaffBlock from "./Blocks/CalendarEntryStaffBlock";

const CalendarEntryStaffManagement = (props: CalendarEntry) => {
    return (
        <React.Fragment>
            <FormHeader headerName={"Staff Management"} />
            <CalendarEntryStaffBlockOverride {...props} />
            <CalendarEntryStaffBlock {...props} />
        </React.Fragment>
    );
};

export default CalendarEntryStaffManagement;
