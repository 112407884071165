import {Dispatch} from "redux";
import {SMS_STORE, SMSDispatchTypes, SMSFormDetails} from "./SMSActionTypes";
import {
    getMetaDataForRequest,
    SelectedSMSStaffMember
} from "../../../components/SMS/Helpers/smsHelpers";
import Store, {RootStore} from "../../Store";
import {postDataToServiceWithRedux} from "store-fetch-wrappers";
import StaffApiModel from "../../apiModel/StaffApiModel";
import {statusCodeCallback} from "../../helpers/storeHelpers";
import {
    AssignmentState,
    CalendarEntry,
    EventCalendarEntry,
    FrontlineCalendarEntry,
    StaffAssignment,
    Venue
} from "../../../api/grs";
import {BulkSMSRequest, UserData} from "../../../api/staff";

/** Nukes store of stale data */
export const nullifySMSStore = () => {
    return async (dispatch: Dispatch<SMSDispatchTypes>) => {
        dispatch({
            type: SMS_STORE.SUCCESS,
            error: null,
            loading: false,
            data: {
                staffMembers: [],
                bodyText: "",
                sendToEventsStaff: true
            }
        });
    };
};

export const setSMSStaffMembers = (staffMembers: SelectedSMSStaffMember[]) => {
    return async (dispatch: Dispatch<SMSDispatchTypes>, getState: () => RootStore) => {
        const smsStore = getState().sms.data;
        if (!smsStore) return;
        dispatch({
            type: SMS_STORE.SUCCESS,
            error: null,
            loading: false,
            data: {
                ...smsStore,
                staffMembers
            }
        });
    };
};

export const setSMSBody = (bodyText: string) => {
    return async (dispatch: Dispatch<SMSDispatchTypes>, getState: () => RootStore) => {
        const smsStore = getState().sms.data;
        if (!smsStore) return;
        dispatch({
            type: SMS_STORE.SUCCESS,
            error: null,
            loading: false,
            data: {
                ...smsStore,
                bodyText
            }
        });
    };
};

export const setSMSSendingOption = (sendToEventsStaff: boolean) => {
    return async (dispatch: Dispatch<SMSDispatchTypes>, getState: () => RootStore) => {
        const smsStore = getState().sms.data;
        const entry = getState().calendarEntry.data;
        if (!smsStore) return;
        if (!entry) return;
        dispatch({
            type: SMS_STORE.SUCCESS,
            error: null,
            loading: false,
            data: {
                ...smsStore,
                sendToEventsStaff,
                staffMembers: []
            }
        });

        if (sendToEventsStaff) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await Store.dispatch(setSMSEventStaffMembers(entry));
        }
    };
};

export const setSMSEventStaffMembers = (entry: CalendarEntry) => {
    return async (dispatch: Dispatch<SMSDispatchTypes>, getState: () => RootStore) => {
        const smsStore = getState().sms.data;
        if (!smsStore) return;
        dispatch({
            type: SMS_STORE.SUCCESS,
            error: null,
            loading: false,
            data: {
                ...smsStore,
                staffMembers: getEventsStaffMembersForSelectedMembers(entry)
            }
        });
    };
};

export const sendMassSms = (smsDetails: SMSFormDetails) => {
    return async (dispatch: Dispatch<SMSDispatchTypes>, getState: () => RootStore) => {
        const loggedInUser = getState().auth.data;
        const entry = getState().calendarEntry.data;
        const venues = getState().venueList.data || [];

        if (!entry) {
            // Fail silently as this shouldn't be possible
            return;
        }
        if (!loggedInUser) {
            // Fail silently as this shouldn't be possible
            return;
        }
        const bulkSmsRequest = getBulkSmsRequest(
            smsDetails,
            loggedInUser,
            smsDetails.sendToEventsStaff,
            entry,
            venues
        );

        try {
            return await postDataToServiceWithRedux(
                SMS_STORE,
                dispatch,
                () => StaffApiModel.getContactApi().bulkSms(bulkSmsRequest),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: SMS_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

export function getEventsStaffMembersForSelectedMembers(
    entry?: CalendarEntry | undefined | null
): SelectedSMSStaffMember[] {
    if (!entry) return [];
    const assignments = getAssignmentsWithLocation(entry);
    return assignments.map((item) => {
        return {
            selectedByTags: [item.section],
            staffId: item.staffMember?.staffId || "STAFF MEMBER NOT FOUND",
            staffName: item.staffMember?.staffName || "STAFF MEMBER NOT FOUND"
        };
    });
}
interface StaffAssignmentWithLocation extends StaffAssignment {
    section: string;
}

/** Gets all assignments regardless of assignment state within an entry */
export function getAssignmentsWithLocation(
    entry: EventCalendarEntry | FrontlineCalendarEntry
): StaffAssignmentWithLocation[] {
    const totalAssignments: StaffAssignmentWithLocation[] = [];

    for (const section of entry.requiredStaff.sections) {
        for (const grouping of section.groupings) {
            for (const assignment of grouping.assignments) {
                totalAssignments.push({
                    ...assignment,
                    section: `${section.name}: ${grouping.name}`
                });
            }
        }
    }

    return totalAssignments.filter((assignment) => assignment.state !== AssignmentState.Unassigned);
}

function getBulkSmsRequest(
    {bodyText, staffMembers}: SMSFormDetails,
    {username}: UserData,
    sendToEventStaff: boolean,
    entry?: CalendarEntry,
    venues?: Venue[]
): BulkSMSRequest {
    return {
        staffIds: staffMembers.map((item) => item.staffId),
        message: bodyText,
        senderUsername: username,
        metadata: getMetaDataForRequest(staffMembers, sendToEventStaff, entry, venues)
    };
}
