import {EventsReportResponse} from "../../../api/grs";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const EVENT_REPORT_LIST_STORE = createStoreState("event_report_list");

interface EventReportListLoading extends StoreServiceData<EventReportListItemResponse[]> {
    type: typeof EVENT_REPORT_LIST_STORE.LOADING;
}
interface EventReportListError extends StoreServiceData<EventReportListItemResponse[]> {
    type: typeof EVENT_REPORT_LIST_STORE.ERROR;
}
interface EventReportListSuccess extends StoreServiceData<EventReportListItemResponse[]> {
    type: typeof EVENT_REPORT_LIST_STORE.SUCCESS;
}

export type EventReportListDispatchTypes =
    | EventReportListSuccess
    | EventReportListLoading
    | EventReportListError;

// eslint-disable-next-line no-shadow
export enum EventsReportType {
    Monthly = "Monthly",
    Yearly = "Yearly",
    Quarterly = "Quarterly"
}

export interface EventReportListItemResponse extends EventsReportResponse {
    reportType: EventsReportType;
}
