import {Calendar, ClinicalGrade, StaffAssignmentGrouping} from "../../../../api/grs";
import {GenericNameValueToggle} from "../../../../utils/sortingUtils";
import {showErrorToast} from "../../../../utils/toastUtils";

/** Checks to see if the Calendar form is valid. Returns validity and any error messages. */
export function isCalendarFormValid(calendar: Calendar): boolean {
    let valid = true;

    //Check to see if the calendar name is invalid
    if (calendar.name.length < 3) {
        valid = false;
        showErrorToast("Calendar name must contain 3 or more characters.");
    }

    //Check for if any section names are invalid
    for (const section of calendar.staffBlock.sections) {
        if (section.name.length < 3) {
            valid = false;
            showErrorToast(
                "One or more sections have invalid names. Section names must contain 3 or more characters."
            );
            break;
        }
    }

    //Check for if any group names in a section are invalid
    for (const section of calendar.staffBlock.sections) {
        for (const group of section.groupings) {
            if (group.name.length < 3) {
                valid = false;
                showErrorToast(
                    `A group in the section '${section.name}' is invalid. Group names must contain 3 or more characters.`
                );
                break;
            }
        }
    }

    return valid;
}

/** This updates the Staff Assignment Grouping Clinical grades and returns updated state for the checkbox */
export function updateStaffGroupingClinicalGrades(
    details: StaffAssignmentGrouping,
    toggleValue: GenericNameValueToggle
) {
    // If the new value coming in is false, it means we are trying to add it to the array (Checking against previous state)
    if (!toggleValue.checked) {
        const selectedClinicalGrade: ClinicalGrade = getClinicalGradeFromString(toggleValue.value);

        return {
            ...details,
            clinicalGrades: [...details.clinicalGrades, selectedClinicalGrade]
        };
    }

    const index = details.clinicalGrades.findIndex((el: ClinicalGrade) => el === toggleValue.value);
    //If we can't find it in the clinical grades, just return the original object.
    if (index < 0) return details;

    //We filter out the selected option to not be in filtered list.
    const updatedGrades = details.clinicalGrades.filter((el: ClinicalGrade, i: number) => {
        return i !== index;
    });

    return {
        ...details,
        clinicalGrades: updatedGrades
    };
}

/** Gets Clinical Grade from string */
export function getClinicalGradeFromString(value: string): ClinicalGrade {
    return ClinicalGrade[value as keyof typeof ClinicalGrade];
}
