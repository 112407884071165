import React from "react";
import {useServerPagination} from "./Hooks/useServerPagination";
import ReactPaginate from "react-paginate";
import Icon, {IconType} from "../Icon/Icon";

function ServerPagination({resultsPerPage, totalResults, onPageChanged, initialPageNumber}: Props) {
    const {pageCount, changePage, pageNumber} = useServerPagination({
        resultsPerPage,
        totalResults,
        onPageChanged,
        initialPageNumber
    });
    return (
        <React.Fragment>
            <div className="pagination-wrapper pt-5">
                <ReactPaginate
                    pageCount={pageCount}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={1}
                    containerClassName={"pagination"}
                    activeClassName={"active-page"}
                    onPageChange={changePage}
                    forcePage={pageNumber}
                    previousLabel={
                        <Icon
                            rootElement={"Span"}
                            rootElementClassName="d-grid"
                            className="nav-links next-previous-icon"
                            icon={IconType.Previous}
                            size={"Small"}
                        />
                    }
                    nextLabel={
                        <Icon
                            rootElement={"Span"}
                            rootElementClassName="d-grid"
                            className="nav-links next-previous-icon"
                            icon={IconType.Next}
                            size={"Small"}
                        />
                    }
                    pageClassName={"other-pages"}
                    breakClassName={"other-pages"}
                    nextClassName={"next-page"}
                    previousClassName={"previous-page"}
                    pageLinkClassName={"pagination-link"}
                    activeLinkClassName={"active-pagination-link"}
                />
            </div>
        </React.Fragment>
    );
}

export default ServerPagination;

interface Props {
    resultsPerPage: number;
    totalResults: number;
    onPageChanged: (newPageNum: number) => void;
    initialPageNumber?: number;
}
