import React from "react";
import {AssignmentState, CalendarMonthSummaryStats} from "../../../../../../api/grs";
import moment from "moment-timezone";
import FormRow from "../../../../../Form/FormRow";
import EventCalendarStackedBarChartSegment from "./EventCalendarStackedBarChartSegment";
import useMcConfig from "../../../../../Hooks/useMcConfig";

const EventCalendarStackedBarChart = (props: CalendarMonthSummaryStats) => {
    const {config} = useMcConfig();
    const monthName = () => {
        const month = moment()
            .month(props.month - 1)
            .tz(config.timezone)
            .format("MMMM");

        return `${month} ${props.year}`;
    };
    return (
        <React.Fragment>
            <FormRow
                rowName={monthName()}
                columnHeaderClassName={"pl-0 pr-0"}
                columnDetailClassName={"pl-0 pr-0"}
                headerClassName={"body-font point-nine-em"}
            >
                <div className="bar-chart-container">
                    <EventCalendarStackedBarChartSegment
                        monthStats={props}
                        assignmentState={AssignmentState.Unassigned}
                    />
                    <EventCalendarStackedBarChartSegment
                        monthStats={props}
                        assignmentState={AssignmentState.WaitingAccept}
                    />
                    <EventCalendarStackedBarChartSegment
                        monthStats={props}
                        assignmentState={AssignmentState.Accepted}
                    />
                    <EventCalendarStackedBarChartSegment
                        monthStats={props}
                        assignmentState={AssignmentState.BriefingAccepted}
                    />
                </div>
            </FormRow>
        </React.Fragment>
    );
};

export default EventCalendarStackedBarChart;
