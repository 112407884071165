import React from "react";
import {
    AvailableShiftCalendarEntry,
    AvailableShiftSection,
    CalendarTypeEnum
} from "../../../../../api/grs";
import {
    formatUnixToDDMMYYYY,
    formatUnixToHHmm,
    formatUnixToHHmmddd
} from "../../../../../utils/momentUtils";
import AvailableShiftEntrySection from "./AvailableShiftEntrySection";
import FormRow from "../../../../Form/FormRow";
import {useSelector} from "react-redux";
import {RootStore} from "../../../../../store/Store";
import {ShiftInterestContext} from "../Context/ShiftInterestContext";
import {useUserData} from "../../../../Hooks/useUserData";

const AvailableShiftsEntryForm = (props: AvailableShiftsEntryFormProps) => {
    const staffInterestQueryResponse = useSelector(
        (state: RootStore) => state.staffInterestQueryResponse.data
    );
    const user = useUserData();
    const eventNameTitle = () => {
        switch (props.entry.calendarType) {
            case CalendarTypeEnum.Event:
                return "Event Description";
            case CalendarTypeEnum.Frontline:
                return "Vehicle Call Sign";
            default:
                return "Shift Details";
        }
    };

    const eventNameDesc = () => {
        switch (props.entry.calendarType) {
            case CalendarTypeEnum.Event:
                return props.entry.venueName;
            case CalendarTypeEnum.Frontline:
                return props.entry.description;
            default:
                return "New Calendar Entry";
        }
    };

    const startTime = () => {
        switch (props.entry.calendarType) {
            case CalendarTypeEnum.Frontline:
                return formatUnixToHHmmddd(props.entry.startDate);
            case CalendarTypeEnum.Event:
                return formatUnixToHHmm(props.entry.startDate);
            default:
                return formatUnixToHHmm(props.entry.startDate);
        }
    };

    const endTime = () => {
        switch (props.entry.calendarType) {
            case CalendarTypeEnum.Frontline:
                return formatUnixToHHmmddd(props.entry.endDate);
            case CalendarTypeEnum.Event:
                return formatUnixToHHmm(props.entry.endDate);
            default:
                return formatUnixToHHmm(props.entry.endDate);
        }
    };

    //Get all areas where the user has registered interest for the shift
    const filteredInterest =
        staffInterestQueryResponse?.interestList
            .filter((interest) => interest.entryId === props.entry.id)
            .filter((interest) => interest.user.staffId === user.username) || [];

    return (
        <ShiftInterestContext.Provider value={filteredInterest[0]}>
            <div className="overflow-auto">
                <FormRow rowName={eventNameTitle()} formRowClassName={"pb-2"}>
                    <p>{eventNameDesc()}</p>
                </FormRow>
                <FormRow rowName={"Date"} formRowClassName={"pb-2"}>
                    <p>{formatUnixToDDMMYYYY(props.entry.startDate)}</p>
                </FormRow>
                <FormRow rowName={"Start Time"} formRowClassName={"pb-2"}>
                    <p>{startTime()}</p>
                </FormRow>
                <FormRow rowName={"End Time"} formRowClassName={"pb-2"}>
                    <p>{endTime()}</p>
                </FormRow>
                <FormRow rowName={"Availability:"} formRowClassName={"pb-2"}>
                    {props.entry.requiredStaff.map(
                        (section: AvailableShiftSection, index: number) => {
                            return (
                                <AvailableShiftEntrySection
                                    section={section}
                                    entry={props.entry}
                                    key={index}
                                    callback={props.callback}
                                />
                            );
                        }
                    )}
                </FormRow>
            </div>
        </ShiftInterestContext.Provider>
    );
};

export default AvailableShiftsEntryForm;

export interface AvailableShiftsEntryFormProps {
    callback: () => void;
    entry: AvailableShiftCalendarEntry;
}
