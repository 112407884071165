import React, {useCallback, useEffect, useState} from "react";
import {
    EventCalendarEntry,
    FrontlineCalendarEntry,
    Venue,
    VenueStateEnum
} from "../../../../../../api/grs";
import {setCalendarEntry} from "../../../../../../store/calendarEntry/actions/CalendarEntryActions";
import {useDispatch} from "react-redux";
import VenueDropdown from "../../../../../Dropdown/Components/VenueDropdown";
import FormRow from "../../../../../Form/FormRow";
import {getGeoLocation} from "../../../../../../store/geolocation/single/actions/GeoLocationActions";

/** Controls the venue that exists in the entry */
const CalendarEntryVenue = (props: EventCalendarEntry | FrontlineCalendarEntry) => {
    const dispatch = useDispatch();
    const [venue, setVenue] = useState<Venue>({
        id: props.venueId ? props.venueId : 0,
        calendarId: 0,
        name: "",
        state: VenueStateEnum.Active,
        address: "",
        postcode: ""
    });

    /** Change event callback from Venue Dropdown */
    const onVenueChanged = useCallback(
        (newVenue: Venue | null | undefined) => {
            if (!newVenue) return;
            setVenue(newVenue);
            dispatch(getGeoLocation(newVenue.postcode));
        },
        [venue]
    );

    /** When the venue changes, detect change and update the store. */
    useEffect(() => {
        const details: EventCalendarEntry | FrontlineCalendarEntry = {
            ...props,
            venueId: venue.id
        };

        dispatch(setCalendarEntry(details));
    }, [venue]);

    return (
        <React.Fragment>
            <FormRow rowName={"Select Venue"} columnDetailClassName={"pl-0 pr-0"}>
                <VenueDropdown onChange={onVenueChanged} venueId={venue.id} clearable={false} />
            </FormRow>
        </React.Fragment>
    );
};

export default CalendarEntryVenue;
