import React, {useEffect} from "react";
import {routeNames} from "../../Navigation/routeNames";
import {useDispatch, useSelector} from "react-redux";
import {useCalendarPageDetails} from "../../Hooks/useCalendarPageDetails";
import {RootStore} from "../../../store/Store";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {getCalendarPathForCalendarChildRoutes, getVenuesListPath} from "../VenueList/VenueList";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {fetchVenueById} from "../../../store/venue/actions/VenueActions";
import {WithServiceState} from "store-fetch-wrappers";
import EditVenueForm from "./Components/EditVenueForm";
import {useQuery} from "../../Hooks/useQuery";
import {getAttendanceOverviewPath} from "../AttendanceOverview/Helpers/attendanceOverviewHelpers";
import {getEventReportPath} from "../Reports/EventReport/Helpers/eventReportHelpers";
import {getXeroTrackingInfo} from "../../../store/xeroTracking/actions/XeroTrackingActions";
const ServiceWrapper = WithServiceState(EditVenueForm);

const EditVenue = () => {
    const dispatch = useDispatch();
    const {calendarId, calendarName} = useCalendarPageDetails();
    const query = useQuery();

    const venueStore = useSelector((state: RootStore) => state.venue);

    useEffect(() => {
        if (!calendarId) return;
        const venueId = query.get("venueId");
        if (!venueId) return;

        //Navigation items.
        const navItems: NavigationItem[] = [
            {
                name: routeNames.groupRosteringSystem.name,
                path: routeNames.groupRosteringSystem.path
            },
            {
                name: calendarName,
                path: getCalendarPathForCalendarChildRoutes(calendarName, +calendarId)
            },
            {
                name: routeNames.eventReport.name,
                path: getEventReportPath(calendarName, +calendarId)
            },
            {
                name: routeNames.venueList.name,
                path: getVenuesListPath(calendarName, +calendarId)
            },
            {
                name: routeNames.attendanceOverview.name,
                path: getAttendanceOverviewPath(calendarName, +calendarId)
            }
        ];

        dispatch(setNavigationItems(navItems));

        dispatch(fetchVenueById(+venueId));
        dispatch(getXeroTrackingInfo());
    }, []);
    return (
        <React.Fragment>
            <div className="mt-4 page-container">
                <ServiceWrapper
                    loaderWheelType={"three-ring"}
                    loaderType={"overlay"}
                    showLoadingText={true}
                    {...venueStore}
                />
            </div>
        </React.Fragment>
    );
};

export default EditVenue;

/** Gets the full path of the Edit Venues Route */
export function getEditVenuesPath(calendarName: string, calendarId: number, venueId: number) {
    return `${routeNames.calendar.path}/${encodeURI(calendarName)}${
        routeNames.editVenues.path
    }?id=${calendarId}&venueId=${venueId}`;
}
