import React from "react";
import {CalendarEntry} from "../../../../../../api/grs";
import CalendarEntryHeader from "../../../../AdminCalendarEntry/Components/Admin/General/CalendarEntryHeader";
import ReadOnlyEntryDescription from "./ReadOnlyEntryDescription";
import ReadOnlyEntryVenue from "./ReadOnlyEntryVenue";
import FormHeader from "../../../../../Form/FormHeader";

const ReadOnlyEntryGeneralDetails = (props: CalendarEntry) => (
    <React.Fragment>
        <CalendarEntryHeader {...props} />
        <FormHeader headerName={"General Details"} />
        <ReadOnlyEntryDescription {...props} />
        <ReadOnlyEntryVenue {...props} />
    </React.Fragment>
);

export default ReadOnlyEntryGeneralDetails;
