import {useEffect, useState} from "react";
import {useQuery} from "../../../../Hooks/useQuery";
import {ArchivedView, EventCalendarRequest} from "../../Slices/Components/EventCalendarFilters";
import {ActiveDashView, GrsDashQueryStrings} from "./useGrsDashContainer";
import {
    getActiveDashViewFromString,
    getArchiveFilterFromString
} from "../../../../../utils/enumUtils";
import {useHistory} from "react-router-dom";

export function useGrsDashFilters({initialFetch}: Props) {
    const [request, setRequest] = useState<EventCalendarRequest>();
    const query = useQuery();
    const history = useHistory();

    useEffect(() => {
        const archivedFilter = query.get(GrsDashQueryStrings.archivedFilter);
        const dashViewFilter = query.get(GrsDashQueryStrings.dashView);

        const initialRequest: EventCalendarRequest = {
            archiveView: archivedFilter
                ? getArchiveFilterFromString(archivedFilter)
                : ArchivedView.Unarchived,
            dashView: dashViewFilter
                ? getActiveDashViewFromString(dashViewFilter)
                : ActiveDashView.Tiles
        };

        initialFetch(initialRequest);
        updateRequest(initialRequest);
    }, []);

    function updateRequest(entity: EventCalendarRequest) {
        setRequest(entity);
        buildUrlHistory(entity);
    }

    function buildUrlHistory({dashView, archiveView}: EventCalendarRequest): void {
        history.push({
            search: `?archivedFilter=${archiveView}&dashView=${dashView}`
        });
    }

    return {
        request,
        updateRequest
    };
}

interface Props {
    initialFetch: (request: EventCalendarRequest) => void;
}
