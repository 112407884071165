import React from "react";
import {ChartData} from "../../../utils/donutChartUtils";
import {DataEntry} from "react-minimal-pie-chart/types/commonTypes";
import HorizontalBarChart from "./HorizontalBarChart";
import {nanoid} from "nanoid";
import FormRow from "../../Form/FormRow";

const HorizontalBarChartContainer = ({
    labelCharts,
    chartData
}: HorizontalBarChartContainerProps) => {
    return (
        <React.Fragment>
            {chartData.totalValue > 0 ? (
                <div className="horizontal-bar-chart-container">
                    {chartData.percentageDataEntries.map((entry: DataEntry, index: number) => {
                        return (
                            <React.Fragment key={index}>
                                {labelCharts ? (
                                    <FormRow
                                        formRowClassName={"pb-2"}
                                        rowName={entry.title ? entry.title.toString() : ""}
                                        columnHeaderClassName={"pl-0 pr-0"}
                                        columnDetailClassName={"pl-0 pr-0"}
                                        headerClassName={"body-font point-nine-em"}
                                    >
                                        <HorizontalBarChart
                                            uid={nanoid()}
                                            maxValue={100}
                                            currentValue={entry.value}
                                            barColour={entry.color}
                                            tooltipText={getTooltipText(chartData, index, entry)}
                                        />
                                    </FormRow>
                                ) : (
                                    <HorizontalBarChart
                                        uid={nanoid()}
                                        maxValue={100}
                                        currentValue={entry.value}
                                        barColour={entry.color}
                                        tooltipText={getTooltipText(chartData, index, entry)}
                                    />
                                )}
                            </React.Fragment>
                        );
                    })}
                    {chartData.outerLabel.length > 0 && (
                        <h5 className="text-center pt-2 mb-0 header-font">
                            {chartData.outerLabel} ({chartData.totalValue})
                        </h5>
                    )}
                </div>
            ) : (
                <div className="row ml-0 mr-0 fadeIn">
                    <div className="col">
                        <h5 className="text-center p-3">Stats do not exist for these parameters</h5>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default HorizontalBarChartContainer;

interface HorizontalBarChartContainerProps {
    chartData: ChartData;
    labelCharts?: boolean;
}

function getTooltipText(chartData: ChartData, index: number, entry: DataEntry): string {
    return `${entry.title}: ${chartData.rawValueDataEntries[index].value} / ${chartData.totalValue} (${entry.value}%)`;
}
