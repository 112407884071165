import {CalendarEventItem} from "../../fullCalendarItemList/actions/FullCalendarItemListActionTypes";
import {AvailableShiftCalendarEntry, CalendarEntry} from "../../../api/grs";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {DateRange} from "../../../utils/filterUtils";

export interface MobileCalendarView {
    availableShifts: ShiftBlock<AvailableShiftCalendarEntry, CalendarEventItem>;
    staffShifts: ShiftBlock<CalendarEntry, CalendarEventItem>;
    dateRange: DateRange;
}

export interface ShiftBlock<T, T1> {
    raw: {[key: string | number]: T};
    converted: {[key: string | number]: T1};
}

export const MOBILE_CALENDAR_VIEW = createStoreState("mobile_calendar_view");

interface MobileCalendarViewLoading extends StoreServiceData<MobileCalendarView> {
    type: typeof MOBILE_CALENDAR_VIEW.LOADING;
}

interface MobileCalendarViewError extends StoreServiceData<MobileCalendarView> {
    type: typeof MOBILE_CALENDAR_VIEW.ERROR;
}

interface MobileCalendarViewSuccess extends StoreServiceData<MobileCalendarView> {
    type: typeof MOBILE_CALENDAR_VIEW.SUCCESS;
}

export type MobileCalendarViewDispatchTypes =
    | MobileCalendarViewLoading
    | MobileCalendarViewError
    | MobileCalendarViewSuccess;
