import React, {useContext} from "react";
import {UserInterest} from "../../../../../../../../../api/grs";
import {CalendarEntryContext} from "../../../../../../../../Context/CalendarEntryContext";
import {useDispatch} from "react-redux";
import {getUserInterestsForCalendarEntry} from "../../../../../../../../../store/userInterest/actions/UserInterestListActions";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../../../../../Button/MCButton";
import InterestedStaffMembersTable from "../../../../../../../AvailableShiftsList/Components/Tables/InterestedStaffMembersTable";
import {toArray} from "../../../../../../../../../utils/sortingUtils";

const InterestedStaffListForEntry = (props: UserInterest[]) => {
    const entry = useContext(CalendarEntryContext);
    const dispatch = useDispatch();
    const onEntryRefreshed = () => {
        dispatch(getUserInterestsForCalendarEntry(entry.id));
    };
    return (
        <React.Fragment>
            <div className="row ml-0 mr-0">
                <div className="col pr-0 d-flex justify-content-end">
                    {entry.id > 0 && (
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Refresh"}
                            onClick={onEntryRefreshed}
                            colour={ButtonColourOptions.DarkBlue}
                            roundedCorner
                        />
                    )}
                </div>
            </div>
            <InterestedStaffMembersTable
                location={"entry"}
                entry={entry}
                userInterest={toArray(props)}
            />
        </React.Fragment>
    );
};

export default InterestedStaffListForEntry;
