import GrsApiModel from "../../apiModel/GrsApiModel";
import {StaffReportEntry, StaffReportRequest, StaffReportResponse} from "../../../api/grs";
import {STAFF_REPORT_STORE, StaffReportDispatchTypes} from "./StaffReportActionTypes";
import {Dispatch} from "redux";
import {getDataFromServiceWithData} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";
import Store from "../../Store";
import {fetchCalendarSummaryStats} from "../../calendarSummaryStats/actions/CalendarSummaryStatsActions";
import {generateBlankStaffReportResponse} from "../reducer/StaffReportReducer";

/** Nukes store of stale data */
export const nullifyStaffAssignmentReportStore = () => {
    return async (dispatch: Dispatch<StaffReportDispatchTypes>) => {
        dispatch({
            type: STAFF_REPORT_STORE.SUCCESS,
            error: null,
            loading: false,
            data: generateBlankStaffReportResponse()
        });
    };
};

/** Gets staff member assignment report */
export const getStaffMemberAssignmentsReport = (args: StaffReportRequest) => {
    return async (dispatch: Dispatch<StaffReportDispatchTypes>) => {
        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await Store.dispatch(fetchCalendarSummaryStats());

            const reports = await getDataFromServiceWithData(
                STAFF_REPORT_STORE,
                dispatch,
                () => GrsApiModel.getReportsApi().getStaffMemberAssignmentsReport(args),
                statusCodeCallback
            );

            dispatch({
                type: STAFF_REPORT_STORE.SUCCESS,
                error: null,
                loading: false,
                data: processReportsIncoming(reports)
            });
        } catch (e: any) {
            dispatch({
                type: STAFF_REPORT_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

// Sort reports by startDate ascending
function processReportsIncoming(
    data: StaffReportResponse | null | undefined
): StaffReportResponse | undefined | null {
    if (!data) return null;

    if (data.entries.length === 0) return data;

    const entries = data.entries.sort((a: StaffReportEntry, b: StaffReportEntry) => {
        if (a.entryStartDate > b.entryStartDate) {
            return 1;
        }

        if (a.entryStartDate < b.entryStartDate) {
            return -1;
        }

        return 0;
    });

    return {
        ...data,
        entries
    };
}
