import {STAFF_WAGE_REPORT_STORE} from "../actions/StaffWageReportActionTypes";
import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {StaffWageReportResponse} from "../../../api/grs";
import {showErrorToast} from "../../../utils/toastUtils";

/** Default state of the store. */
const defaultState = createDefaultStoreState<StaffWageReportResponse>({entries: []});

/** Infer type of the reducer for code completion and quality */
const staffWageReportReducer = (
    state: StoreServiceData<StaffWageReportResponse> = defaultState,
    action: ServiceActionTypes<StaffWageReportResponse>
): StoreServiceData<StaffWageReportResponse> =>
    createReducer(state, action, STAFF_WAGE_REPORT_STORE, () => showErrorToast(action.error));

export default staffWageReportReducer;
