import React, {useEffect, useState} from "react";
import {RequestFilterProps} from "../../../../Filters/helpers/filterHelpers";
import {StaffLink, StaffReportRequest} from "../../../../../api/grs";
import {useUserData} from "../../../../Hooks/useUserData";
import moment, {Moment} from "moment";
import {StaffAccessLevel} from "../../../../../api/staff";
import DatePicker from "react-datepicker";
import DatePickerInputButton from "../../../../Button/DatePickerInputButton";
import AuthAmI from "../../../../AuthAmI/AuthAmI";
import StaffDropdown from "../../../../Dropdown/Components/StaffDropdown";
import {useHistory} from "react-router-dom";
import {useQuery} from "../../../../Hooks/useQuery";

const StaffReportFilters = (props: RequestFilterProps<StaffReportRequest>) => {
    const user = useUserData();
    const history = useHistory();
    const query = useQuery();
    const [startDate, setStartDate] = useState<Moment>();
    const [selectedStaffMember, setSelectedStaffMember] = useState<StaffLink>({
        staffId: "",
        staffName: ""
    });

    useEffect(() => {
        const year = query.get("year");
        const month = query.get("month");

        setStartDate(getMomentFromMonthYear(month, year));
    }, []);

    function getMomentFromMonthYear(month: string | null, year: string | null): Moment {
        if (!month) return moment();
        if (!year) return moment();

        return moment().set({year: +year, month: +month - 1});
    }

    /** Fired when new staff member is selected */
    const onSelectedStaffMember = (staff: StaffLink) => {
        setSelectedStaffMember(staff);
    };

    /** Selected when the date changes */
    const onDateChanged = (date: Date | null) => {
        if (!date) return;

        setStartDate(moment(date));
    };

    /** If the user isn't an admin, we get their own staff report. */
    useEffect(() => {
        if (user.username.length === 0) return;
        switch (user.accessLevel) {
            case StaffAccessLevel.DutyManager:
            case StaffAccessLevel.Staff:
                setSelectedStaffMember({
                    staffId: user.username,
                    staffName: `${user.firstName} ${user.lastName}`
                });
                break;
        }
    }, [user]);

    // Watch for changes before emitting to parent
    useEffect(() => {
        if (!startDate) return;
        if (selectedStaffMember.staffId.length === 0) return;

        props.onRequestChanged({
            startDateInclusive: startDate.clone().startOf("month").unix(),
            endDateExclusive: startDate.clone().endOf("month").unix(),
            staffId: selectedStaffMember.staffId
        });

        history.push({
            search: `?year=${startDate.clone().year()}&month=${startDate.clone().month() + 1}`
        });
    }, [startDate, selectedStaffMember]);

    return (
        <table className="filters-table mt-3">
            <tbody className="filters-lg">
                <tr>
                    <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                        <th className="filter-heading w-50 pl-3 pr-3">Staff Member</th>
                    </AuthAmI>
                    <th className="filter-heading w-50 pl-3 pr-3">Date</th>
                </tr>
                <tr>
                    <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                        <td className="filter-heading w-50 pl-3 pr-3 pb-2">
                            <StaffDropdown
                                clearable={false}
                                searchable={true}
                                changeOption={onSelectedStaffMember}
                                id={selectedStaffMember.staffId}
                                disabled={false}
                            />
                        </td>
                    </AuthAmI>
                    <td className="filter-heading w-50 pl-3 pr-3 pb-2">
                        {startDate && (
                            <DatePicker
                                selected={startDate.toDate()}
                                onChange={onDateChanged}
                                showMonthYearPicker
                                dateFormat="MMMM yyyy"
                                portalId="root-portal"
                                customInput={<DatePickerInputButton />}
                            />
                        )}
                    </td>
                </tr>
            </tbody>
            <tbody className="filters-md">
                <tr>
                    <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                        <th className="filter-heading w-50 pl-3 pr-3">Staff Member</th>
                    </AuthAmI>
                    <th className="filter-heading w-50 pl-3 pr-3">Date</th>
                </tr>
                <tr>
                    <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                        <td className="filter-heading w-50 pl-3 pr-3 pb-2">
                            <StaffDropdown
                                clearable={false}
                                searchable={true}
                                changeOption={onSelectedStaffMember}
                                id={selectedStaffMember.staffId}
                                disabled={false}
                            />
                        </td>
                    </AuthAmI>
                    <td className="filter-heading w-50 pl-3 pr-3 pb-2">
                        {startDate && (
                            <DatePicker
                                selected={startDate.toDate()}
                                onChange={onDateChanged}
                                showMonthYearPicker
                                dateFormat="MMMM yyyy"
                                portalId="root-portal"
                                customInput={<DatePickerInputButton />}
                            />
                        )}
                    </td>
                </tr>
            </tbody>
            <tbody className="filters-sm">
                <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                    <tr>
                        <th className="filter-heading w-100 pl-3 pr-3">Staff Member</th>
                    </tr>
                    <tr>
                        <td className="filter-heading w-100 pl-3 pr-3 pb-2">
                            <StaffDropdown
                                clearable={false}
                                searchable={true}
                                changeOption={onSelectedStaffMember}
                                id={selectedStaffMember.staffId}
                                disabled={false}
                            />
                        </td>
                    </tr>
                </AuthAmI>
                <tr>
                    <th className="filter-heading w-100 pl-3 pr-3">Date</th>
                </tr>
                <tr>
                    <td className="filter-heading w-100 pl-3 pr-3 pb-2">
                        {startDate && (
                            <DatePicker
                                selected={startDate.toDate()}
                                onChange={onDateChanged}
                                showMonthYearPicker
                                dateFormat="MMMM yyyy"
                                portalId="root-portal"
                                customInput={<DatePickerInputButton />}
                            />
                        )}
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default StaffReportFilters;
