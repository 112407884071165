import React from "react";

function ExpandingTableHeading({headerValue, headerKey}: Props) {
    return (
        <React.Fragment>
            {headerKey !== "id" && (
                <th align="left" className="pulse-table-header-item" id={headerKey.toString()}>
                    {headerValue}
                </th>
            )}
        </React.Fragment>
    );
}

export default ExpandingTableHeading;

interface Props {
    headerKey: string;
    headerValue: string;
}
