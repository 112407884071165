import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {DutyManagerReport} from "../../../api/grs";

export const DUTY_MANAGER_REPORT_LISTING_STORE_STATE = createStoreState(
    "duty_manager_report_listing"
);

interface DutyManagerReportListingLoading extends StoreServiceData<DutyManagerReport[]> {
    type: typeof DUTY_MANAGER_REPORT_LISTING_STORE_STATE.LOADING;
}
interface DutyManagerReportListingError extends StoreServiceData<DutyManagerReport[]> {
    type: typeof DUTY_MANAGER_REPORT_LISTING_STORE_STATE.ERROR;
}
interface DutyManagerReportListingSuccess extends StoreServiceData<DutyManagerReport[]> {
    type: typeof DUTY_MANAGER_REPORT_LISTING_STORE_STATE.SUCCESS;
}

export type DutyManagerReportListingDispatchTypes =
    | DutyManagerReportListingLoading
    | DutyManagerReportListingError
    | DutyManagerReportListingSuccess;
