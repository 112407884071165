import React from "react";
import {ExpandingTableData} from "../Helpers/TableHelpers";
import {useExpandingRowCell} from "../Hooks/useExpandingRowCell";

function ExpandingTableRowCell<T extends ExpandingTableData>({
    customRenderer,
    item,
    itemKey,
    viewActionText,
    onExpandableItemClicked,
    expanded
}: ExpandingTableRowCellProps<T>) {
    const {markup} = useExpandingRowCell({
        customRenderer,
        item,
        itemKey,
        viewActionText,
        onExpandableItemClicked,
        expanded
    });

    return (
        <React.Fragment>
            {itemKey !== "id" && <React.Fragment>{markup}</React.Fragment>}
        </React.Fragment>
    );
}

export default ExpandingTableRowCell;

export interface ExpandingTableRowCellProps<T extends ExpandingTableData> {
    customRenderer: Partial<Record<keyof T, (it: T) => React.ReactNode>>[keyof T] | undefined;
    item: T;
    itemKey: string;
    viewActionText: string;
    onExpandableItemClicked: (action: ExpandRenderAction<T>) => void;
    expanded: boolean;
}

export interface ExpandRenderAction<T> {
    item: T;
    action: ExpandAction;
}

// eslint-disable-next-line no-shadow
export enum ExpandAction {
    View = "View"
}
