import GrsApiModel from "../../apiModel/GrsApiModel";
import {EventsReportRequest} from "../../../api/grs";
import {
    EVENT_REPORT_LIST_STORE,
    EventReportListDispatchTypes,
    EventReportListItemResponse,
    EventsReportType
} from "./EventReportListActionTypes";
import {Dispatch} from "redux";
import moment from "moment";

/** Removes stale data from store */
export const nullifyEventReportListStore = () => {
    return async (dispatch: Dispatch<EventReportListDispatchTypes>) => {
        dispatch({
            type: EVENT_REPORT_LIST_STORE.SUCCESS,
            error: null,
            loading: false,
            data: []
        });
    };
};

/** gets Event Report requested for month for calendar */
export const getEventReportListForCalendar = (request: EventsReportRequest) => {
    return async (dispatch: Dispatch<EventReportListDispatchTypes>) => {
        try {
            // Set loading
            dispatch({
                type: EVENT_REPORT_LIST_STORE.LOADING,
                error: null,
                loading: true
            });

            // Create Request for Yearly and Quarterly reports
            const startOfYear = moment.unix(request.startDateInclusive).startOf("year").unix();
            const endOfYear = moment.unix(request.startDateInclusive).endOf("year").unix();
            const startOfQuarter = moment
                .unix(request.startDateInclusive)
                .startOf("quarter")
                .unix();
            const endOfQuarter = moment.unix(request.startDateInclusive).endOf("quarter").unix();

            const yearlyReportRequest: EventsReportRequest = {
                ...request,
                startDateInclusive: startOfYear,
                endDateExclusive: endOfYear
            };

            const quarterlyReportRequest: EventsReportRequest = {
                ...request,
                startDateInclusive: startOfQuarter,
                endDateExclusive: endOfQuarter
            };

            // Create empty array ready for request data
            const reportList: EventReportListItemResponse[] = [];
            const monthlyReports = await GrsApiModel.getReportsApi().getEventsReport(request); // Monthly
            const yearlyReports = await GrsApiModel.getReportsApi().getEventsReport(
                yearlyReportRequest
            ); // Yearly
            const quarterlyReports = await GrsApiModel.getReportsApi().getEventsReport(
                quarterlyReportRequest
            ); // Quarterly

            // Push all to array with their report type.
            reportList.push(
                {...monthlyReports.data, reportType: EventsReportType.Monthly},
                {...yearlyReports.data, reportType: EventsReportType.Yearly},
                {...quarterlyReports.data, reportType: EventsReportType.Quarterly}
            );

            //Update store.
            dispatch({
                type: EVENT_REPORT_LIST_STORE.SUCCESS,
                error: null,
                loading: false,
                data: reportList
            });
        } catch (e: any) {
            dispatch({
                type: EVENT_REPORT_LIST_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
