import React from "react";
import {
    AvailableShiftCalendarEntry,
    AvailableShiftGrouping,
    AvailableShiftSection
} from "../../../../../api/grs";
import AvailableShiftEntrySectionGroup from "./AvailableShiftEntrySectionGroup";
import {useAvailableShiftsInfoUtils} from "../Hooks/useAvailableShiftsInfoUtils";

const AvailableShiftEntrySection = (props: AvailableShiftEntrySectionProps) => {
    const {showSection} = useAvailableShiftsInfoUtils();
    return (
        <React.Fragment>
            {showSection(props.section) && (
                <div className="mt-2">
                    {props.section.groupings.map((group: AvailableShiftGrouping, index: number) => {
                        return (
                            <AvailableShiftEntrySectionGroup
                                section={props.section}
                                entry={props.entry}
                                group={group}
                                key={index}
                                callback={props.callback}
                            />
                        );
                    })}
                </div>
            )}
        </React.Fragment>
    );
};

export default AvailableShiftEntrySection;

export interface AvailableShiftEntrySectionProps {
    section: AvailableShiftSection;
    entry: AvailableShiftCalendarEntry;
    callback: () => void;
}
