import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {StaffReportResponse} from "../../../api/grs";

export const STAFF_REPORT_STORE = createStoreState("staff_report");

interface StaffReportLoading extends StoreServiceData<StaffReportResponse> {
    type: typeof STAFF_REPORT_STORE.LOADING;
}
interface StaffReportError extends StoreServiceData<StaffReportResponse> {
    type: typeof STAFF_REPORT_STORE.ERROR;
}
interface StaffReportSuccess extends StoreServiceData<StaffReportResponse> {
    type: typeof STAFF_REPORT_STORE.SUCCESS;
}

export type StaffReportDispatchTypes = StaffReportSuccess | StaffReportError | StaffReportLoading;
