import React from "react";
import {setCalendarEntry} from "../../../../../../store/calendarEntry/actions/CalendarEntryActions";
import {getEntryStateFromString} from "../../../Helpers/calenderEntryHelpers";
import {useDispatch} from "react-redux";
import {
    CalendarEntryState,
    EventCalendarEntry,
    FrontlineCalendarEntry
} from "../../../../../../api/grs";
import GenericTypeDropdown from "../../../../../Dropdown/Components/GenericTypeDropdown";
import FormRow from "../../../../../Form/FormRow";

const CalendarEntryActivityState = (props: EventCalendarEntry | FrontlineCalendarEntry) => {
    const dispatch = useDispatch();
    const onEntryStateChanged = (id: string | number) => {
        dispatch(
            setCalendarEntry({
                ...props,
                state: getEntryStateFromString(id.toString())
            })
        );
    };
    return (
        <React.Fragment>
            <div className="row ml-0 mr-0">
                <div className="col-sm-6 pl-0 calendar-entry-input-override">
                    <FormRow rowName={"Entry State"} columnDetailClassName={"pl-0 pr-0"}>
                        <GenericTypeDropdown
                            enumOptions={CalendarEntryState}
                            splitByCapitalLetter={false}
                            clearable={false}
                            searchable={false}
                            changeOption={onEntryStateChanged}
                            id={props.state}
                            disabled={false}
                        />
                    </FormRow>
                </div>
            </div>
        </React.Fragment>
    );
};

export default CalendarEntryActivityState;
