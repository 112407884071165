import React, {useContext} from "react";
import {
    DrivingDirection,
    RemoveDriverDetailsArgs,
    SetDriverDetailArgs,
    SetDriverDirectionArgs,
    VehicleDriver
} from "../../../../../../../store/dutyManagerReport/actions/DutyManagerReportActionTypes";
import {StaffLink} from "../../../../../../../api/grs";
import {DutyManagerReportEditableContext} from "../../Context/DutyManagerReportEditable";
import {useDispatch} from "react-redux";
import {
    removeDriverDetails,
    setDriverDetails,
    setDriverDirection
} from "../../../../../../../store/dutyManagerReport/actions/DutyManagerReportActions";
import CalendarEntryStaffAssignmentDropdown from "../../../../../../Dropdown/Components/CalendarEntryStaffAssignmentDropdown";
import {StaffLinkAddedDetails} from "../../../../../AdminCalendarEntry/Helpers/calenderEntryHelpers";
import GenericTypeDropdown from "../../../../../../Dropdown/Components/GenericTypeDropdown";
import FormHeaderWithAction from "../../../../../../Form/FormHeaderWithAction";
import {IconType} from "../../../../../../Icon/Icon";
import {Modal, useModal} from "pulse-modal";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../../../Button/MCButton";
import FormRow from "../../../../../../Form/FormRow";
import {getUiFriendlyText} from "../../../../../../../utils/textUtils";
import {showSuccessToast} from "../../../../../../../utils/toastUtils";

const DriverDetailItem = (props: DriverDetailItemProps) => {
    const dispatch = useDispatch();
    const editable = useContext(DutyManagerReportEditableContext);
    const {toggle, isShown} = useModal();

    const deleteDriverDetails = () => {
        const args: RemoveDriverDetailsArgs = {
            uid: props.driver.uid
        };
        dispatch(removeDriverDetails(args));
        toggle();
        showSuccessToast("Driver details deleted");
    };

    const onDriverChanged = (driver: StaffLinkAddedDetails | undefined) => {
        if (!driver) return;
        const newAssignment: StaffLink = {
            staffId: driver ? driver.staffId : "",
            staffName: driver ? driver.staffName : ""
        };
        const args: SetDriverDetailArgs = {
            vehicleUID: props.vehicleUid,
            previousAssignmentUID: props.driver.uid,
            newAssignment
        };
        dispatch(setDriverDetails(args));
    };

    const selectedDrivingDirectionChanged = (item: string | number) => {
        const drivingDirection = getDrivingDirectionFromString(item.toString());
        const args: SetDriverDirectionArgs = {
            assignmentUID: props.driver.uid,
            newDirection: drivingDirection
        };
        dispatch(setDriverDirection(args));
    };

    const getDrivingDirectionFromString = (value: string): DrivingDirection => {
        return DrivingDirection[value as keyof typeof DrivingDirection];
    };

    return (
        <React.Fragment>
            <FormHeaderWithAction
                headerName={"Driver Details"}
                showIcon={editable}
                icon={IconType.Bin}
                onClick={toggle}
                tooltipText={"Delete Driver"}
            />
            <FormRow rowName={"Driver Name"} columnDetailClassName={editable ? "pl-0 pr-0" : ""}>
                {editable ? (
                    <CalendarEntryStaffAssignmentDropdown
                        initialAssignment={{
                            staffId: props.driver.staffId,
                            staffName: props.driver.staffName
                        }}
                        isClearable={true}
                        changeOption={onDriverChanged}
                        disabled={!editable}
                    />
                ) : (
                    <p className="mb-0">{props.driver.staffName}</p>
                )}
            </FormRow>
            <FormRow
                rowName={"Driving Direction"}
                columnDetailClassName={editable ? "pl-0 pr-0" : ""}
            >
                {editable ? (
                    <GenericTypeDropdown
                        enumOptions={DrivingDirection}
                        splitByCapitalLetter={false}
                        clearable={false}
                        searchable={false}
                        changeOption={selectedDrivingDirectionChanged}
                        id={
                            props.driver.drivingDirection
                                ? props.driver.drivingDirection
                                : DrivingDirection.Inbound
                        }
                        disabled={false}
                    />
                ) : (
                    <p className="mb-0">
                        {props.driver.drivingDirection
                            ? getUiFriendlyText(props.driver.drivingDirection)
                            : "Direction not specified"}
                    </p>
                )}
            </FormRow>
            <Modal
                isShown={isShown}
                onClose={toggle}
                title={"Delete Vehicle"}
                modalSize={"sm"}
                bodyChildren={
                    <React.Fragment>
                        <div className="row ml-0 mr-0">
                            <p>Are you sure you want to delete this drivers details?</p>
                        </div>
                    </React.Fragment>
                }
                footerChildren={
                    <React.Fragment>
                        <div className="row mt-3 ml-0 mr-0">
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"Yes"}
                                onClick={deleteDriverDetails}
                                colour={ButtonColourOptions.Yellow}
                                roundedCorner
                            />
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"No"}
                                onClick={toggle}
                                colour={ButtonColourOptions.DarkBlue}
                                roundedCorner
                            />
                        </div>
                    </React.Fragment>
                }
            />
        </React.Fragment>
    );
};

export default DriverDetailItem;

interface DriverDetailItemProps {
    driver: VehicleDriver;
    vehicleUid: string;
}
