import React, {useEffect, useState} from "react";
import TimeDropdown from "../../../../../../../Dropdown/Components/TimeDropdown";
import {useSelector} from "react-redux";
import {RootStore} from "../../../../../../../../store/Store";
import FormRow from "../../../../../../../Form/FormRow";
import {StaffAssignment} from "../../../../../../../../api/grs";

const OverrideTimes = (props: OverrideTimesProps) => {
    const entry = useSelector((state: RootStore) => state.calendarEntry.data);
    const [start, setStart] = useState<number>(
        props.assignment.startDate ? props.assignment.startDate : 0
    );
    const [end, setEnd] = useState<number>(props.assignment.endDate ? props.assignment.endDate : 0);

    /** Updates start time */
    const onStartTimeSelected = (time?: number | string) => {
        if (!time) return;

        setStart(+time);
    };

    /** Updates end time */
    const onEndTimeSelected = (time?: number | string) => {
        if (!time) return;
        setEnd(+time);
    };

    useEffect(() => {
        props.onChange({
            ...props.assignment,
            startDate: start,
            endDate: end
        });
    }, [start, end]);

    /** default each time to 0 because this component will be null if the option isn't checked */
    return (
        <React.Fragment>
            {entry && (
                <React.Fragment>
                    <FormRow rowName={"Start Time"} columnDetailClassName={"pl-0 pr-0"}>
                        <TimeDropdown
                            entry={entry}
                            id={start}
                            optionsPerHour={4}
                            clearable={false}
                            disabled={false}
                            daysToGenerate={1}
                            changeOption={onStartTimeSelected}
                            isSearchable={true}
                        />
                    </FormRow>
                    <FormRow rowName={"End Time"} columnDetailClassName={"pl-0 pr-0"}>
                        <TimeDropdown
                            entry={entry}
                            id={end}
                            optionsPerHour={4}
                            clearable={false}
                            disabled={false}
                            daysToGenerate={2}
                            changeOption={onEndTimeSelected}
                            isSearchable={true}
                        />
                    </FormRow>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default OverrideTimes;

interface OverrideTimesProps {
    assignment: StaffAssignment;
    onChange: (newAssignment: StaffAssignment) => void;
}
