import React, {useContext, useEffect, useState} from "react";
import {CalendarEntryContext} from "../../../Context/CalendarEntryContext";
import {useDispatch, useSelector} from "react-redux";
import {
    getDutyManagerReportByEntryId,
    nullifyDutyManagerReportListingStore
} from "../../../../store/dutyManagerReportListing/actions/DutyManagerReportListingActions";
import {RootStore} from "../../../../store/Store";
import {DutyManagerReportListing} from "../../../../api/grs";
import {useHistory} from "react-router-dom";
import {usePageUrl} from "../../../Hooks/usePageUrl";
import {useUserData} from "../../../Hooks/useUserData";
import {StaffAccessLevel} from "../../../../api/staff";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import {
    getLinkToCreateDutyManagerForm,
    getPathToEditDutyManagerReportRoute,
    getPathToViewDutyManagerReportRoute
} from "../../Reports/DutyManager/DutyManagerReport/Helpers/dutyManagerReportHelpers";
import useMcConfig from "../../../Hooks/useMcConfig";
import {getUserAccessLevel} from "../../../../utils/userDataUtils";

const DutyManagerReportButton = () => {
    const entry = useContext(CalendarEntryContext);
    const user = useUserData();
    const dispatch = useDispatch();
    const [buttonText, setButtonText] = useState<string>("");
    const [reportListing, setReportListing] = useState<DutyManagerReportListing>();
    const dutyManagerReportListingStore = useSelector(
        (state: RootStore) => state.dutyManagerReportListing
    );
    const {fullPath} = usePageUrl();
    const history = useHistory();
    const {config} = useMcConfig();
    useEffect(() => {
        dispatch(getDutyManagerReportByEntryId(entry.id));

        return function () {
            dispatch(nullifyDutyManagerReportListingStore());
        };
    }, []);

    useEffect(() => {
        if (user.username.length === 0) return;
        const loading = dutyManagerReportListingStore.loading;
        const results = dutyManagerReportListingStore.data;
        if (loading) {
            setButtonText("Loading");
            return;
        }
        if (!results) return;
        const accessLevel = getUserAccessLevel(user, config);
        if (results.length > 0) {
            const report = results[0];
            setReportListing(report);
            switch (accessLevel) {
                case StaffAccessLevel.SystemAdministrator:
                    setButtonText("Edit Report");
                    return;
                case StaffAccessLevel.DutyManager:
                    if (user.username === report.dutyManagerId) {
                        setButtonText("View Report");
                        return;
                    }
                    return;
            }
        }
        setButtonText("Create Report");
    }, [
        user,
        dutyManagerReportListingStore.data,
        dutyManagerReportListingStore.error,
        dutyManagerReportListingStore.loading
    ]);

    //Goes to the right route for user
    const viewCreateDutyManagerReport = () => {
        localStorage.setItem("calendar_entry_redirect_uri", fullPath);
        if (!entry.venueId) return;
        if (!reportListing) {
            // Create path
            const path = getLinkToCreateDutyManagerForm(entry.id, entry.venueId);
            history.push(path);
            return;
        }
        const accessLevel = getUserAccessLevel(user, config);
        switch (accessLevel) {
            case StaffAccessLevel.SystemAdministrator:
                const editPath = getPathToEditDutyManagerReportRoute(reportListing);
                history.push(editPath);
                return;
            case StaffAccessLevel.DutyManager:
                if (user.username === reportListing.dutyManagerId) {
                    const viewPath = getPathToViewDutyManagerReportRoute(reportListing);
                    history.push(viewPath);
                    return;
                }
        }
    };

    const showButton = () => {
        if (user.username.length === 0) return false;
        if (!reportListing) return true;

        const accessLevel = getUserAccessLevel(user, config);

        switch (accessLevel) {
            case StaffAccessLevel.SystemAdministrator:
                return true;
            case StaffAccessLevel.DutyManager:
                return user.username === reportListing.dutyManagerId;
        }
    };
    return (
        <React.Fragment>
            {showButton() && (
                <MCButton
                    size={ButtonSize.Large}
                    innerValue={buttonText}
                    onClick={viewCreateDutyManagerReport}
                    colour={ButtonColourOptions.DarkBlue}
                    roundedCorner
                    disabled={dutyManagerReportListingStore.loading}
                    className={"mr-3"}
                />
            )}
        </React.Fragment>
    );
};

export default DutyManagerReportButton;
