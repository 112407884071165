import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {CalendarComplianceResponse} from "../../../api/grs";

export const CALENDAR_COMPLIANCE_STORE = createStoreState("calendar_compliance");

interface CalendarComplianceLoading extends StoreServiceData<CalendarComplianceResponse> {
    type: typeof CALENDAR_COMPLIANCE_STORE.LOADING;
}

interface CalendarComplianceError extends StoreServiceData<CalendarComplianceResponse> {
    type: typeof CALENDAR_COMPLIANCE_STORE.ERROR;
}

interface CalendarComplianceSuccess extends StoreServiceData<CalendarComplianceResponse> {
    type: typeof CALENDAR_COMPLIANCE_STORE.SUCCESS;
}

export type CalendarComplianceDispatchTypes =
    | CalendarComplianceSuccess
    | CalendarComplianceError
    | CalendarComplianceLoading;
