import React from "react";
import {useDispatch} from "react-redux";
import {addStaffAssignment} from "../../../../../../../../store/calendarEntry/actions/CalendarEntryActions";

/** Component that allows for a new assignment to be added to the Calendar entry */
const AddCalendarEntryStaffAssignment = (props: AddCalendarEntryStaffAssignmentProps) => {
    const dispatch = useDispatch();

    /** Creates a blank assignment */
    const addBlankAssignment = () => {
        dispatch(addStaffAssignment(props.groupId));
    };

    return (
        <div className="add cursor-pointer" onClick={addBlankAssignment}>
            <div className="cross-vertical" />
            <div className="cross-horizontal" />
        </div>
    );
};
interface AddCalendarEntryStaffAssignmentProps {
    groupId: string;
}
export default AddCalendarEntryStaffAssignment;
