import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {useEffect} from "react";
import {
    nullifyVenueStore,
    saveVenueToService,
    setVenue
} from "../../../../../store/venue/actions/VenueActions";
import {nullifyXeroTrackingStore} from "../../../../../store/xeroTracking/actions/XeroTrackingActions";
import {showErrorToast, showSuccessToast} from "../../../../../utils/toastUtils";
import {getVenuesListPath} from "../../../VenueList/VenueList";
import {Venue} from "../../../../../api/grs";
import {RootStore} from "../../../../../store/Store";

export function useEditVenueForm(props: Venue) {
    const dispatch = useDispatch();
    const params: any = useParams();
    const history = useHistory();
    const xeroTrackingStore = useSelector((state: RootStore) => state.xeroTracking);

    useEffect(() => {
        return function () {
            dispatch(nullifyVenueStore());
            dispatch(nullifyXeroTrackingStore());
        };
    }, []);

    function updateVenue(newVenue: Venue) {
        dispatch(setVenue(newVenue));
    }

    /** Saves venue. Will check to see if the venue being saved is valid */
    const saveVenue = async () => {
        const isValid = validateVenue(props);
        if (!isValid) return;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(saveVenueToService(props));

        if (!success) return;
        showSuccessToast(`Saved ${props.name}`);
        backToVenueList();
    };

    const backToVenueList = () => {
        const calendarName = decodeURI(params.calendarName);
        const path = getVenuesListPath(calendarName, props.calendarId);

        history.push(path);
    };

    function validateVenue(venue: Venue): boolean {
        let valid = true;

        if (venue.name.length < 3) {
            valid = false;
            showErrorToast("The Venue Name field must have a minimum of 3 characters.");
        }

        if (venue.address.length < 5) {
            valid = false;
            showErrorToast("The Address field must have a minimum of 5 characters.");
        }

        if (venue.postcode.length < 3) {
            valid = false;
            showErrorToast("The Postcode field must have a minimum of 3 characters.");
        }

        return valid;
    }

    return {
        backToVenueList,
        saveVenue,
        updateVenue,
        xeroTrackingStore
    };
}
