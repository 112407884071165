import React from "react";
import {useCarousel} from "../Hooks/useCarousel";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../components/Button/MCButton";

const Carousel = ({children, slot, ...rest}: CarouselProps) => {
    const {handlers, activeIndex, updateIndex} = useCarousel({children, slot, ...rest});
    return (
        <div className="pulse-carousel">
            <div className="indicators">
                {React.Children.map(children, (child, index) => {
                    return (
                        <MCButton
                            size={ButtonSize.Small}
                            innerValue={rest.buttonNames[index]}
                            onClick={() => updateIndex(index)}
                            colour={
                                index === activeIndex
                                    ? ButtonColourOptions.Yellow
                                    : ButtonColourOptions.DarkBlue
                            }
                        />
                    );
                })}
            </div>
            {slot}
            <div
                {...handlers}
                className="pulse-carousel-inner mt-2"
                style={{transform: `translateX(-${activeIndex * 100}%)`}}
            >
                {React.Children.map(children, (child, index) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    return React.cloneElement(child, {width: "100%"});
                })}
            </div>
        </div>
    );
};

export default Carousel;

export interface CarouselProps {
    children: React.ReactNode;
    slot: React.ReactNode;
    buttonNames: string[];
}
