import React from "react";
import {ExpandingTableData, TableProps} from "./Helpers/TableHelpers";
import ExpandingTableHeader from "./Components/ExpandingTableHeader";
import TableWrapper from "./Components/TableWrapper";
import ExpandingTableRow from "./Components/ExpandingTableRow";

function PulseExpandingTable<T extends ExpandingTableData>({
    headers,
    customRenderers,
    noItemsSection,
    items,
    expandedRow,
    viewActionText,
    initialExpandedRows
}: TableProps<T>) {
    return (
        <React.Fragment>
            {items.length > 0 ? (
                <React.Fragment>
                    <div className="pulse-table-wrapper">
                        <TableWrapper>
                            <ExpandingTableHeader headers={headers} />
                            <tbody>
                                {items.map((item, index) => {
                                    return (
                                        <ExpandingTableRow
                                            key={index}
                                            item={item}
                                            customRenderers={customRenderers}
                                            rowIndex={index}
                                            expandedRow={expandedRow}
                                            viewActionText={viewActionText}
                                            initialExpandedRows={initialExpandedRows}
                                        />
                                    );
                                })}
                            </tbody>
                        </TableWrapper>
                    </div>
                </React.Fragment>
            ) : (
                <React.Fragment>{noItemsSection}</React.Fragment>
            )}
        </React.Fragment>
    );
}
export default PulseExpandingTable;
