import React from "react";
import {ButtonColourOptions, ButtonSize} from "../../Button/MCButton";
import {Modal, useModal} from "pulse-modal";
import SMSStaffListTable from "./SMSStaffListTable";
import {useSelector} from "react-redux";
import {RootStore} from "../../../store/Store";
import MCButtonWithIndicator from "../../Button/MCButtonWithIndicator";

const SMSStaffListButton = () => {
    const {toggle, isShown} = useModal();
    const smsStore = useSelector(
        (state: RootStore) =>
            state.sms.data || {staffMembers: [], bodyText: "", sendToEventsStaff: true}
    );
    return (
        <React.Fragment>
            <MCButtonWithIndicator
                size={ButtonSize.Large}
                innerValue={"View Staff Members"}
                onClick={toggle}
                colour={ButtonColourOptions.DarkBlue}
                roundedCorner
                numberBubbleValue={smsStore.staffMembers.length}
            />
            <Modal
                modalSize={"md"}
                title={"Staff List"}
                bodyChildren={<SMSStaffListTable {...smsStore} />}
                onClose={toggle}
                isShown={isShown}
            />
        </React.Fragment>
    );
};

export default SMSStaffListButton;
