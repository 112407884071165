import {Dispatch} from "redux";
import {
    DUTY_MANAGER_REPORT_PAGED_STORE_STATE,
    DutyManagerReportPagedDispatchTypes
} from "./DutyManagerReportPagedActionTypes";
import {DutyManagerReportPagedRequest} from "../../../api/grs";
import GrsApiModel from "../../apiModel/GrsApiModel";
import {postDataToServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";
import {generateBlankDutyManagerReportPagedResponse} from "../reducer/DutyManagerReportPagedReducer";

/** Clears data in store */
export const nullifyDutyManagerReportPagedStore = () => {
    return async (dispatch: Dispatch<DutyManagerReportPagedDispatchTypes>) => {
        dispatch({
            type: DUTY_MANAGER_REPORT_PAGED_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: generateBlankDutyManagerReportPagedResponse()
        });
    };
};

/** Gets paged duty manager reports */
export const fetchPagedDutyManagerReports = (request: DutyManagerReportPagedRequest) => {
    return async (dispatch: Dispatch<DutyManagerReportPagedDispatchTypes>) => {
        try {
            return postDataToServiceWithRedux(
                DUTY_MANAGER_REPORT_PAGED_STORE_STATE,
                dispatch,
                () => GrsApiModel.getCalendarApi().getDutyManagerReportsPaged(request),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: DUTY_MANAGER_REPORT_PAGED_STORE_STATE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};
