import React, {useEffect} from "react";
import {routeNames} from "../../Navigation/routeNames";
import {useDispatch, useSelector} from "react-redux";
import {
    CalendarMonthView,
    getCalendarPathForGlobalStaffCalendar
} from "../AdminCalendar/Helpers/calendarUtils";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {nullifyStaffInterestQueryResponseStore} from "../../../store/staffInterestQuery/actions/StaffInterestQueryResponseActions";
import {RootStore} from "../../../store/Store";
import {
    fetchAllVenues,
    nullifyVenueListStore
} from "../../../store/venueList/actions/VenueListActions";
import {getAllMedicareStaffMembers} from "../../../store/staffList/actions/StaffListActions";
import AvailableShiftsListTable from "./Components/AvailableShiftsListTable";
import {WithServiceState} from "store-fetch-wrappers/dist";
import {useXeroRoutes} from "../Xero/Hooks/useXeroRoutes";
import ShiftOverviewProvider from "./Provider/ShiftOverviewProvider";
import {useUserData} from "../../Hooks/useUserData";
import useMcConfig from "../../Hooks/useMcConfig";
import {getUserAccessLevel} from "../../../utils/userDataUtils";
import {StaffAccessLevel} from "../../../api/staff";
import {getAvailableShiftsListRoute} from "../Reports/StaffReport/StaffReport";
import {usePlatform} from "../../Hooks/usePlatform";

const ShiftsListServiceWrapper = WithServiceState(AvailableShiftsListTable);

const AvailableShiftsList = () => {
    const dispatch = useDispatch();
    const staffQueryStore = useSelector((state: RootStore) => state.staffInterestQueryResponse);
    const {getSystemAdminRoutes} = useXeroRoutes();
    const user = useUserData();
    const {config} = useMcConfig();
    const platform = usePlatform();

    useEffect(() => {
        window.scrollTo({top: 0});

        dispatch(getAllMedicareStaffMembers());
        dispatch(fetchAllVenues());

        return function () {
            dispatch(nullifyVenueListStore());
            dispatch(nullifyStaffInterestQueryResponseStore());
        };
    }, []);

    useEffect(() => {
        if (!config.auth) return;
        if (!user.username) return;

        dispatch(setNavigationItems(getRoutes()));
    }, [user, config]);

    function getRoutes() {
        const userAccessLevel = getUserAccessLevel(user, config);

        switch (userAccessLevel) {
            case StaffAccessLevel.SystemAdministrator:
                return getSystemAdminRoutes();
            default:
                return [
                    {
                        name: CalendarMonthView.Staff,
                        path: getCalendarPathForGlobalStaffCalendar(CalendarMonthView.Staff)
                    },
                    {
                        name: routeNames.staffReport.name,
                        path: routeNames.staffReport.path
                    },
                    {
                        name: routeNames.pendingNotifications.name,
                        path: routeNames.pendingNotifications.path
                    },
                    {
                        name: routeNames.availableShiftsList.name,
                        path: routeNames.availableShiftsList.path
                    },
                    ...getAvailableShiftsListRoute(platform)
                ];
        }
    }
    return (
        <ShiftOverviewProvider>
            <div className="page-container">
                <ShiftsListServiceWrapper
                    placeholderBlockCount={1}
                    showLoadingText={true}
                    loaderType={"overlay"}
                    loaderWheelType={"three-ring"}
                    {...staffQueryStore}
                />
            </div>
        </ShiftOverviewProvider>
    );
};
export default AvailableShiftsList;

export function getPathToGlobalStaffCalendar(
    calendarView: CalendarMonthView,
    query: URLSearchParams
) {
    const month = query.get("month");

    return `${routeNames.globalCalendar.path}/${encodeURI(calendarView)}${getMonthQueryString(
        month
    )}`;
}

export function getMonthQueryString(month?: number | string | null) {
    return month ? `&month=${+month}` : "";
}
