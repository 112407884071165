import React from "react";
import {AssignmentState, EventsReportEntryStaffSection} from "../../../../../api/grs";
import {PulseTable} from "pulse_table";
import NoItemsMessage from "../../../../Table/NoItemsMessage";
import {getUiFriendlyText} from "../../../../../utils/textUtils";
import Tooltip from "../../../../Tooltip/Tooltip";

/** Component which shows the details of an entry in the Events Report. */
const EventReportSummarySection = (props: EventsReportEntryStaffSection) => {
    const indicatorClassName = (assignmentState: AssignmentState) => {
        switch (assignmentState) {
            case AssignmentState.Unassigned:
                return "assignment-base unassigned";
            case AssignmentState.WaitingAccept:
                return "assignment-base waitingAccept";
            default:
                return "";
        }
    };

    const tooltipText = (item: TableRow) => {
        switch (item.state) {
            case AssignmentState.Unassigned:
                return "Assignment has not been made!";
            case AssignmentState.WaitingAccept:
                return `${item.staffName} has not actioned assignment request! Awaiting response`;
            default:
                return "";
        }
    };

    return (
        <React.Fragment>
            <PulseTable
                items={toTableRow(props)}
                headers={{
                    groupName: "Group Name",
                    state: "Assignment State",
                    staffName: "Staff Member"
                }}
                customRenderers={{
                    state: (item: TableRow) => (
                        <React.Fragment>
                            <div className="d-inline-block">
                                <p className="mb-0">{getUiFriendlyText(item.state)}</p>
                            </div>
                            <div className="d-inline-block ml-3">
                                <Tooltip
                                    rootElement={"span"}
                                    place={"right"}
                                    tooltipText={tooltipText(item)}
                                    size={"lg"}
                                >
                                    <div className="read-only cursor-pointer">
                                        <div className={indicatorClassName(item.state)} />
                                    </div>
                                </Tooltip>
                            </div>
                        </React.Fragment>
                    )
                }}
                noItemsSection={
                    <NoItemsMessage message={"There are no actions needed for this section"} />
                }
            />
        </React.Fragment>
    );
};

export default EventReportSummarySection;

interface TableRow {
    groupName: string;
    state: AssignmentState;
    staffName: string;
}

function toTableRow(props: EventsReportEntryStaffSection): TableRow[] {
    return getAssignmentSections(props);
}

function getAssignmentSections(props: EventsReportEntryStaffSection) {
    const rows: TableRow[] = [];
    for (const group of props.groups) {
        for (const assignment of group.assignments) {
            const groupName = formatSectionInformationToString(props.sectionName, group.groupName);
            switch (assignment.state) {
                case AssignmentState.Unassigned:
                    rows.push({
                        groupName,
                        state: assignment.state,
                        staffName: "Assignment not made"
                    });
                    break;
                case AssignmentState.WaitingAccept:
                    rows.push({
                        groupName,
                        state: assignment.state,
                        staffName: assignment.staffName || "Assignment not made"
                    });
                    break;
            }
        }
    }
    return rows;
}

function formatSectionInformationToString(sectionName: string, groupName: string): string {
    if (sectionName !== groupName) {
        return `${sectionName}: (${groupName})`;
    } else {
        return `${sectionName}`;
    }
}
