import {CalendarGroup, CalendarSummaryStats} from "../../../../../api/grs";
import {toArray} from "../../../../../utils/sortingUtils";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../../../Navigation/routeNames";
import {useSelector} from "react-redux";
import {RootStore} from "../../../../../store/Store";
import {isArchivedText} from "../../AddEdit/Hooks/useCalendarSummariesDropdown";

export function useCalendarGroupTable(list: CalendarGroup[]) {
    const history = useHistory();
    const summaries = useSelector((state: RootStore) => state.calendarSummaryStats.data || []);

    function goToEditCalendarGroup(id: number): void {
        const path = `${routeNames.editCalendarGroups.path}/${id}`;
        history.push(path);
    }

    function goToAddCalendarGroup(): void {
        history.push(routeNames.addCalendarGroups.path);
    }

    function tabledCalendarGroups(): TableRow[] {
        return toArray(list).map((item) => {
            return {
                name: item.name,
                calendarInfo: getSummariesFromCalendarIds(item.calendarIds, summaries),
                actions: item.id
            };
        });
    }

    function getSummariesFromCalendarIds(
        ids: number[],
        summaryList: CalendarSummaryStats[]
    ): (string | undefined)[] {
        return ids.map((id) => {
            const summary = summaryList.find((item) => item.summary.id === id);
            if (summary) {
                const archivedText = isArchivedText(summary.summary);
                return `${summary.summary.name} - (${summary.summary.calendarType}) ${archivedText}`;
            }

            return undefined;
        });
    }

    function tableHeaders() {
        return {
            name: "Name",
            calendarInfo: "Calendars",
            actions: "Actions"
        };
    }

    return {
        tabledCalendarGroups,
        goToEditCalendarGroup,
        tableHeaders,
        goToAddCalendarGroup
    };
}

interface TableRow {
    name: string;
    actions: number;
    calendarInfo: (string | undefined)[];
}
