import React, {useState} from "react";
import {
    DutyManagerReportPagedRequest,
    DutyManagerReportPagedResponse
} from "../../../../../../api/grs";
import FilterContainer from "../../../../../Filters/FilterContainer";
import DutyManagerReportPagedFilters from "./DutyManagerReportPagedFilters";
import {useDispatch, useSelector} from "react-redux";
import {fetchPagedDutyManagerReports} from "../../../../../../store/dutyManagerReportPaged/actions/DutyManagerReportPagedActions";
import {formatUnixToLLL} from "../../../../../../utils/momentUtils";
import {PulseTableWithServerPagination} from "pulse_table";
import {pagedRequestConfig} from "../../../../../Filters/helpers/filterHelpers";
import DeleteTableItemAction from "../../../../../Table/DeleteTableItemAction";
import {RootStore} from "../../../../../../store/Store";
import {deleteDutyManagerReportById} from "../../../../../../store/dutyManagerReport/actions/DutyManagerReportActions";
import TableItemAction from "../../../../../Table/TableItemAction";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../../../../Navigation/routeNames";
import {usePageUrl} from "../../../../../Hooks/usePageUrl";
import {IconType} from "../../../../../Icon/Icon";

const DutyManagerReportPagedTable = (props: DutyManagerReportPagedResponse) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {fullPath} = usePageUrl();
    const [dmReportRequest, setDmRequest] = useState<DutyManagerReportPagedRequest>();
    const {loading} = useSelector((state: RootStore) => state.dutyManagerReport);

    const onRequestChanged = (request: DutyManagerReportPagedRequest) => {
        if (!dmReportRequest) {
            setDmRequest(request);
            fetchPagedReports(request);
            return;
        }
        if (JSON.stringify(request) === JSON.stringify(dmReportRequest)) return;
        setDmRequest(request);
        fetchPagedReports(request);
    };

    // Fetches Audits
    const fetchPagedReports = (request: DutyManagerReportPagedRequest) => {
        dispatch(fetchPagedDutyManagerReports(request));
    };

    const viewDmReport = (item: TableRow) => {
        localStorage.setItem("duty_manager_list_uri", fullPath);
        const path = getPathToViewDutyManagerReportRoute(item.actions);
        history.push(path);
    };

    return (
        <React.Fragment>
            <FilterContainer closed={false}>
                <DutyManagerReportPagedFilters onRequestChanged={onRequestChanged} />
            </FilterContainer>
            <PulseTableWithServerPagination
                resultsPerPage={pagedRequestConfig.resultsPerPage}
                totalResults={props.totalEntries}
                onPageChanged={(newPageNum) => {
                    if (!dmReportRequest) return;
                    fetchPagedReports({
                        ...dmReportRequest,
                        pageNum: newPageNum
                    });
                }}
                items={toTableRow(props)}
                headers={{
                    venueName: "Venue Name",
                    createdBy: "Created By",
                    dateCreated: "Date Created",
                    actions: "Actions"
                }}
                customRenderers={{
                    actions: (item: TableRow) => (
                        <React.Fragment>
                            <TableItemAction
                                tooltipText={`View Duty Manager Report`}
                                onClick={() => viewDmReport(item)}
                                icon={IconType.Eye}
                            />
                            <DeleteTableItemAction
                                loading={loading}
                                modalTitle={"Delete Report"}
                                modalMessage={
                                    "Are you sure you want to delete this duty manager report?"
                                }
                                onConfirm={async () => {
                                    if (!dmReportRequest) return false;
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    const success: boolean = await dispatch(
                                        deleteDutyManagerReportById(item.actions.reportId)
                                    );

                                    if (!success) return false;

                                    fetchPagedReports(dmReportRequest);
                                    return true;
                                }}
                                itemName={"Duty Manager Report"}
                            />
                        </React.Fragment>
                    )
                }}
                noItemsSection={
                    <div className="row ml-0 mr-0 fadeIn">
                        <div className="col">
                            <h5 className="text-center p-3">
                                There are no duty manager reports that match the filters
                            </h5>
                        </div>
                    </div>
                }
            />
        </React.Fragment>
    );
};

export default DutyManagerReportPagedTable;

interface TableRow {
    venueName: string;
    createdBy: string;
    dateCreated: string;
    actions: DutyManagerAction;
}

interface DutyManagerAction {
    reportId: number;
    calendarEntryId: number;
}

function toTableRow(resp: DutyManagerReportPagedResponse): TableRow[] {
    return resp.entries.map((item) => {
        return {
            venueName: item.venueName,
            createdBy: item.dutyManagerName,
            dateCreated: formatUnixToLLL(item.date),
            actions: {
                calendarEntryId: item.calendarEntryId,
                reportId: item.id
            }
        };
    });
}

function getPathToViewDutyManagerReportRoute({calendarEntryId, reportId}: DutyManagerAction) {
    return `${routeNames.viewDutyManagerReport.path}/${reportId}?calendarEntryId=${calendarEntryId}`;
}
