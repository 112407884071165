import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {CalendarGroup} from "../../../api/grs";
import {CALENDAR_GROUP_STORE} from "../actions/CalendarGroupActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<CalendarGroup>(null);

const calendarGroupReducer = (
    state: StoreServiceData<CalendarGroup> = defaultState,
    action: ServiceActionTypes<CalendarGroup>
) => createReducer(state, action, CALENDAR_GROUP_STORE, () => showErrorToast(action.error));

export default calendarGroupReducer;
