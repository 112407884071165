import React from "react";
import {EventCalendarEntry, FrontlineCalendarEntry} from "../../../../api/grs";
import {CalendarEntryContext} from "../../../Context/CalendarEntryContext";
import {useHistory} from "react-router-dom";
import {getLinkToGlobalCalendarFromReadOnlyEntry} from "../Helpers/readOnlyCalendarHelpers";
import {useQuery} from "../../../Hooks/useQuery";
import CalendarEntryStats from "../../AdminCalendarEntry/Components/Stats/CalendarEntryStats";
import BackgroundContainer from "../../../Containers/BackgroundContainer";
import ReadOnlyEntryGeneralDetails from "./Readonly/General/ReadOnlyEntryGeneralDetails";
import ReadOnlyEntryDetails from "./Readonly/EventDetails/ReadOnlyEntryDetails";
import ReadOnlyStaffManagement from "./Readonly/StaffManagement/ReadOnlyStaffManagement";
import FormHeader from "../../../Form/FormHeader";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import CalendarEntryDutyManagerActions from "./Readonly/CalendarEntryDutyManagerActions";

const ReadOnlyCalendarEntryForm = (props: EventCalendarEntry | FrontlineCalendarEntry) => {
    const history = useHistory();
    const query = useQuery();

    const goBackToLastScreen = () => {
        const previousLocation = localStorage.getItem("calendar_redirect");
        if (previousLocation) {
            history.replace(previousLocation);
            return;
        }

        const path = getLinkToGlobalCalendarFromReadOnlyEntry(query);
        history.replace(path);
    };
    return (
        <React.Fragment>
            <BackgroundContainer background={"background-stars"} pageContainerClass={"pt-4"}>
                <CalendarEntryContext.Provider value={props}>
                    <ReadOnlyEntryGeneralDetails {...props} />
                    <ReadOnlyEntryDetails {...props} />
                    <ReadOnlyStaffManagement {...props} />
                    <CalendarEntryDutyManagerActions />
                    <FormHeader headerName={"Shift Actions"} />
                    <div className="row ml-0 mr-0 mt-3">
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue="Back"
                            onClick={goBackToLastScreen}
                            colour={ButtonColourOptions.DarkBlue}
                            className="mr-3"
                            roundedCorner
                        />
                    </div>
                </CalendarEntryContext.Provider>
                <CalendarEntryStats {...props} />
            </BackgroundContainer>
        </React.Fragment>
    );
};

export default ReadOnlyCalendarEntryForm;
