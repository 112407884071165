import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useUserData} from "../../Hooks/useUserData";
import {nullifyCalendarStore} from "../../../store/calendar/actions/CalendarActions";
import {nullifyCalendarEntriesListStore} from "../../../store/calendarEntryList/actions/CalendarEntryListActions";
import {fetchAllVenues} from "../../../store/venueList/actions/VenueListActions";
import GlobalCalendarWrapper from "./Components/GlobalCalendarWrapper";
import {usePageUrl} from "../../Hooks/usePageUrl";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {getCalendarGroupList} from "../../../store/calendarGroupList/actions/CalendarGroupListActions";
import {PlatformOS, usePlatform} from "../../Hooks/usePlatform";
import {StaffAccessLevel} from "../../../api/staff";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {CalendarMonthView} from "../AdminCalendar/Helpers/calendarUtils";
import {routeNames} from "../../Navigation/routeNames";
import {getAvailableShiftsListRoute} from "../Reports/StaffReport/StaffReport";
import {useXeroRoutes} from "../Xero/Hooks/useXeroRoutes";
import useMcConfig from "../../Hooks/useMcConfig";
import {getUserAccessLevel} from "../../../utils/userDataUtils";

const GlobalCalendar = () => {
    const dispatch = useDispatch();
    const user = useUserData();
    const {fullPath} = usePageUrl();
    const platform = usePlatform();
    const {getSystemAdminRoutes} = useXeroRoutes();
    const {config} = useMcConfig();

    useEffect(() => {
        dispatch(fetchAllVenues());
        dispatch(getCalendarGroupList());
        return function () {
            dispatch(nullifyCalendarStore());
            dispatch(nullifyCalendarEntriesListStore());
        };
    }, []);

    useEffect(() => {
        if (!user.username.length) return;
        const accessLevel = getUserAccessLevel(user, config);
        const navItems = getNavItemsForGlobalCalendarPage(accessLevel, fullPath, platform);

        dispatch(setNavigationItems(navItems));
    }, [user, config]);

    function getNavItemsForGlobalCalendarPage(
        accessLevel: StaffAccessLevel,
        calendarPath: string,
        targetPlatform: PlatformOS
    ): NavigationItem[] {
        switch (accessLevel) {
            case StaffAccessLevel.SystemAdministrator:
                return getSystemAdminRoutes();
            case StaffAccessLevel.Staff:
            case StaffAccessLevel.DutyManager:
                return [
                    {
                        name: CalendarMonthView.Staff,
                        path: calendarPath
                    },
                    {
                        name: routeNames.staffReport.name,
                        path: routeNames.staffReport.path
                    },
                    {
                        name: routeNames.pendingNotifications.name,
                        path: routeNames.pendingNotifications.path
                    },
                    {
                        name: routeNames.availableShiftsList.name,
                        path: routeNames.availableShiftsList.path
                    },
                    ...getAvailableShiftsListRoute(targetPlatform)
                ];
        }
    }

    return (
        <React.Fragment>
            <div className="page-container pb-4">
                <GlobalCalendarWrapper />
            </div>
        </React.Fragment>
    );
};

export default GlobalCalendar;
