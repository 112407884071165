import React from "react";
import {useXeroInvoices} from "./Hooks/useXeroInvoices";
import {WithServiceState} from "store-fetch-wrappers/dist";
import XeroInvoicesList from "./Components/XeroInvoicesList";

const ServiceWrapper = WithServiceState(XeroInvoicesList);

function XeroInvoices() {
    const {invoiceStore} = useXeroInvoices();
    return (
        <React.Fragment>
            <div className="page-container pt-4">
                <ServiceWrapper
                    loaderWheelType={"three-ring"}
                    loaderType={"overlay"}
                    showLoadingText={false}
                    {...invoiceStore}
                />
            </div>
        </React.Fragment>
    );
}

export default XeroInvoices;
