import React from "react";
import {CalendarEntry} from "../../../../../../api/grs";
import FormRow from "../../../../../Form/FormRow";
import {getUiFriendlyText} from "../../../../../../utils/textUtils";

const ReadOnlyEntryActivityState = (props: CalendarEntry) => (
    <React.Fragment>
        <FormRow rowName={"Entry State"}>
            <p className="mb-0">{getUiFriendlyText(props.state)}</p>
        </FormRow>
    </React.Fragment>
);

export default ReadOnlyEntryActivityState;
