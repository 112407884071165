import React from "react";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../../../Navigation/routeNames";
import {StaffBlockTemplateListEntry} from "../../../../../api/grs";
import {PulseTable} from "pulse_table";
import {toArray} from "../../../../../utils/sortingUtils";
import DeleteTableItemAction from "../../../../Table/DeleteTableItemAction";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../../store/Store";
import {deleteGlobalStaffBlock} from "../../../../../store/globalStaffBlock/actions/GlobalStaffBlockActions";
import {getStaffBlockListFromService} from "../../../../../store/globalStaffBlockList/actions/GlobalStaffBlockListActions";
import TableItemAction from "../../../../Table/TableItemAction";
import {IconType} from "../../../../Icon/Icon";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../Button/MCButton";

const GlobalStaffBlockList = (props: StaffBlockTemplateListEntry[]) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {loading} = useSelector((state: RootStore) => state.globalStaffBlock);

    const editGlobalStaffBlock = (row: StaffBlockListTableRow) => {
        const path = `${routeNames.editGlobalStaffBlock.path}/${row.blockId}`;
        history.push(path);
    };

    const goToAddGlobalStaffBlock = () => {
        const path = `${routeNames.addGlobalStaffBlock.path}`;
        history.push(path);
    };

    return (
        <React.Fragment>
            <div className="row mt-5 fadeIn ml-0 mr-0">
                <div className="col pr-0 d-flex justify-content-end">
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Add Global Staff Block"}
                        onClick={goToAddGlobalStaffBlock}
                        colour={ButtonColourOptions.Yellow}
                        roundedCorner
                    />
                </div>
            </div>
            <PulseTable
                items={getItemsForTable(toArray(props))}
                headers={{
                    name: "Name",
                    blockId: "Actions"
                }}
                noItemsSection={
                    <div className="row ml-0 mr-0 fadeIn">
                        <div className="col">
                            <h5 className="text-center p-3">
                                There are no global staff blocks defined! Add a custom staff block
                                from the option in the navigation menu
                            </h5>
                        </div>
                    </div>
                }
                customRenderers={{
                    blockId: (item: StaffBlockListTableRow) => (
                        <React.Fragment>
                            <TableItemAction
                                tooltipText={`Edit ${item.name}`}
                                onClick={() => editGlobalStaffBlock(item)}
                                icon={IconType.Edit}
                            />
                            <DeleteTableItemAction
                                loading={loading}
                                itemName={item.name}
                                modalMessage={"Are you sure you want to delete this staff block?"}
                                modalTitle={"Delete Staff Block"}
                                onConfirm={async () => {
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    const success: boolean = await dispatch(
                                        deleteGlobalStaffBlock(item.blockId)
                                    );

                                    if (!success) return false;

                                    dispatch(getStaffBlockListFromService());
                                    return true;
                                }}
                            />
                        </React.Fragment>
                    )
                }}
            />
        </React.Fragment>
    );
};

export default GlobalStaffBlockList;

export interface StaffBlockListTableRow {
    name: string;
    blockId: number;
}

function getItemsForTable(items: StaffBlockTemplateListEntry[]): StaffBlockListTableRow[] {
    return items.map((item: StaffBlockTemplateListEntry) => {
        return {
            name: item.name,
            blockId: item.id
        };
    });
}
