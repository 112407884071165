import React from "react";
import {
    CalendarTypeEnum,
    EventCalendarEntry,
    FrontlineCalendarEntry
} from "../../../../../../api/grs";
import {setCalendarEntry} from "../../../../../../store/calendarEntry/actions/CalendarEntryActions";
import {useDispatch} from "react-redux";
import FormRow from "../../../../../Form/FormRow";
import {DebounceInput} from "react-debounce-input";

/** Contains the Logic for Calendar Entry Description. Input label will change based on calendar type */
const CalendarEntryDescription = (props: EventCalendarEntry | FrontlineCalendarEntry) => {
    const dispatch = useDispatch();

    //Updates Entry description in the store
    const updateEntryDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
        const details: EventCalendarEntry | FrontlineCalendarEntry = {
            ...props,
            description: event.target.value
        };

        dispatch(setCalendarEntry(details));
    };

    const getFieldName = () => {
        switch (props.calendarType) {
            case CalendarTypeEnum.Frontline:
                return "Vehicle Call Sign";
            case CalendarTypeEnum.Event:
                return "Event Description";
        }
    };
    return (
        <React.Fragment>
            <FormRow rowName={getFieldName()} columnDetailClassName={"pl-0 pr-0"}>
                <DebounceInput
                    debounceTimeout={300}
                    value={props.description}
                    onChange={updateEntryDescription}
                    className="input-fields"
                />
            </FormRow>
            {props.calendarType === CalendarTypeEnum.Event ? (
                <FormRow rowName={"Subtitle"} columnDetailClassName={"pl-0 pr-0"}>
                    <DebounceInput
                        debounceTimeout={300}
                        value={props.subTitle}
                        onChange={(event) => {
                            const {value} = event.target;

                            dispatch(
                                setCalendarEntry({
                                    ...props,
                                    subTitle: value
                                })
                            );
                        }}
                        className="input-fields"
                        placeholder="Enter subtitle here..."
                    />
                </FormRow>
            ) : null}
        </React.Fragment>
    );
};

export default CalendarEntryDescription;
