import {Loading} from "store-fetch-wrappers";
import React from "react";

const OverlayLoading = () => (
    <div className="xero-page-wrapper">
        <div className="loading-overlay fadeIn">
            <div className="loading-overlay-content">
                <div className="text-center">
                    <Loading type={"three-ring"} showLoadingText={true} />
                </div>
            </div>
        </div>
    </div>
);

export default OverlayLoading;
