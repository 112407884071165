import {GroupSubSystem, TopBarItem} from "../Header/Helpers/topbarHelpers";
import {useUserData} from "../../../../../Hooks/useUserData";
import {StaffAccessLevel, UserData} from "../../../../../../api/staff";

export function useCorrectSystemLink(item: TopBarItem | GroupSubSystem) {
    const user = useUserData();

    if (user.username.length === 0) return "";

    return getCorrectLink(item, user);
}

function getCorrectLink<T extends {adminUrl?: string; staffUrl?: string; moduleName?: string}>(
    item: T,
    user: UserData
): string {
    const accessLevel = user.accessDetail![item.moduleName || ""] || user.accessLevel;
    switch (accessLevel) {
        case StaffAccessLevel.SystemAdministrator:
            return item.adminUrl ?? "";
        case StaffAccessLevel.DutyManager:
        case StaffAccessLevel.Staff:
            return item.staffUrl ?? "";
    }
    return "";
}

//ToDo: Go through GRS as Pseudo admin and update routes
