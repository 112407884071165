import {Dispatch} from "redux";
import {CALENDAR_GROUP_STORE, CalendarGroupDispatchTypes} from "./CalendarGroupActionTypes";
import {
    deleteDataFromServiceWithRedux,
    getDataFromServiceWithRedux,
    postDataToServiceWithRedux
} from "store-fetch-wrappers";
import GrsApiModel from "../../apiModel/GrsApiModel";
import {statusCodeCallback} from "../../helpers/storeHelpers";
import {CalendarGroup, CalendarTypeEnum} from "../../../api/grs";
import store from "../../Store";
import {fetchCalendarSummaryStats} from "../../calendarSummaryStats/actions/CalendarSummaryStatsActions";

export function nullifyCalendarGroupStore() {
    return async (dispatch: Dispatch<CalendarGroupDispatchTypes>) => {
        dispatch({
            type: CALENDAR_GROUP_STORE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
}

export function createNewCalendarGroup() {
    return async (dispatch: Dispatch<CalendarGroupDispatchTypes>) => {
        dispatch({
            type: CALENDAR_GROUP_STORE.SUCCESS,
            error: null,
            loading: false,
            data: {
                name: "",
                id: 0,
                calendarIds: [],
                viewType: CalendarTypeEnum.Frontline
            }
        });
    };
}

export function setCalendarGroup(group: CalendarGroup | undefined) {
    return async (dispatch: Dispatch<CalendarGroupDispatchTypes>) => {
        dispatch({
            type: CALENDAR_GROUP_STORE.SUCCESS,
            error: null,
            loading: false,
            data: group
        });
    };
}

export function getCalendarGroup(id: number) {
    return async (dispatch: Dispatch<CalendarGroupDispatchTypes>) => {
        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await store.dispatch(fetchCalendarSummaryStats());
            return await getDataFromServiceWithRedux(
                CALENDAR_GROUP_STORE,
                dispatch,
                () => GrsApiModel.getCalendarApi().getCalendarGroupById(id),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: CALENDAR_GROUP_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
}

export function deleteCalendarGroupById(id: number) {
    return async (dispatch: Dispatch<CalendarGroupDispatchTypes>) => {
        try {
            return await deleteDataFromServiceWithRedux(
                CALENDAR_GROUP_STORE,
                dispatch,
                () => GrsApiModel.getCalendarApi().deleteCalendarGroup(id),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: CALENDAR_GROUP_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
}

export function saveCalendarGroup(group: CalendarGroup) {
    return async (dispatch: Dispatch<CalendarGroupDispatchTypes>) => {
        try {
            return await postDataToServiceWithRedux(
                CALENDAR_GROUP_STORE,
                dispatch,
                () => GrsApiModel.getCalendarApi().saveCalendarGroup(group),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: CALENDAR_GROUP_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
}
