import React from "react";
import {AssignmentState, StaffAssignment} from "../../../../../../../api/grs";
import {getClassForStaffAssignment} from "../../../../../AdminCalendarEntry/Helpers/calenderEntryHelpers";

const ReadOnlyCalendarEntryStaffAssignment = (props: StaffAssignment) => {
    const getAssignmentName = () => {
        switch (props.state) {
            case AssignmentState.Unassigned:
                return AssignmentState.Unassigned;
            default:
                return props.staffMember?.staffName;
        }
    };
    return (
        <React.Fragment>
            <div className="row ml-0 mr-0">
                <div className="d-inline-block mr-3">
                    <div className="read-only">
                        <div className={getClassForStaffAssignment(props.state)} id={props.id} />
                    </div>
                </div>
                <div className="d-inline-block">
                    <div className="read-only">
                        <p className="mb-0 staff-details-information">{getAssignmentName()}</p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ReadOnlyCalendarEntryStaffAssignment;
