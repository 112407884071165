import autoTable, {RowInput, Styles} from "jspdf-autotable";
import {CalendarSummaryStats, StaffReportResponse} from "../../../../../api/grs";
import {
    getBriefingAccepted,
    getEndDate,
    getShiftAccepted,
    getShiftDetails,
    getStaffWage,
    getStartDate
} from "./staffReportHelpers";
import {formatUnixToDDMMYYYY} from "../../../../../utils/momentUtils";
import jsPDF from "jspdf";
import {magistralFont} from "./Fonts/Magistral-Bold-normal";
import {montserratRegularFont} from "./Fonts/Montserrat-Regular-normal";
import mcLogo from "../../../../../images/Medicare-Logo-White.png";

interface PdfProps {
    head: RowInput[];
    body: RowInput[];
    headStyles?: Partial<Styles> | undefined;
    bodyStyles?: Partial<Styles> | undefined;
}

export function printStaffReportPdf(
    response: StaffReportResponse,
    summaries: CalendarSummaryStats[],
    fileName: string
): void {
    const doc = new jsPDF();

    createPdfHeader(doc, fileName);
    createPdfBody(response, summaries, doc);
    createPdfFooter(doc);

    doc.save(`${fileName}.pdf`);
}

function createPdfHeader(doc: jsPDF, fileName: string): void {
    autoTable(doc, {
        headStyles: {
            ...getHeadStyle(doc),
            cellPadding: {top: 6, bottom: 6, right: 3, left: 3},
            fontSize: 16
        },
        head: [[`Medicare EMS Group UK - ${fileName}`]]
    });
}

function createPdfBody(
    response: StaffReportResponse,
    summaries: CalendarSummaryStats[],
    doc: jsPDF
): void {
    const {headStyles, bodyStyles, head, body} = generatePdfOptions(response, summaries, doc);
    autoTable(doc, {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        startY: doc.lastAutoTable.finalY,
        headStyles,
        bodyStyles,
        head,
        body
    });
}
function createPdfFooter(doc: jsPDF): void {
    const year = new Date().getFullYear();
    autoTable(doc, {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        startY: doc.lastAutoTable.finalY,
        headStyles: {
            ...getHeadStyle(doc),
            cellPadding: {top: 6, bottom: 6, right: 3, left: 3},
            fontSize: 12
        },
        head: [[""]],
        didDrawCell: function (data) {
            if (data.section === "head" && data.column.index === 0) {
                const img = new Image();
                img.src = mcLogo;
                doc.addImage(
                    img,
                    "png",
                    116,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    doc.lastAutoTable.finalY + 5,
                    76.5,
                    9.5
                );
            }
        }
    });

    autoTable(doc, {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        startY: doc.lastAutoTable.finalY,
        headStyles: {
            ...getHeadStyle(doc),
            cellPadding: {top: 6, bottom: 6, right: 3, left: 3},
            fontSize: 12,
            halign: "right"
        },
        head: [[`© ${year} Medicare EMS Group UK Limited`]]
    });
}

function generatePdfOptions(
    response: StaffReportResponse,
    summaries: CalendarSummaryStats[],
    doc: jsPDF
): PdfProps {
    return {
        headStyles: getHeadStyle(doc),
        bodyStyles: getBodyStyles(doc),
        head: [
            ["Shift Details", "Date", "Shift Accepted", "Briefing Accepted", "Start", "End", "Wage"]
        ],
        body: response.entries.map((entry) => {
            return [
                getShiftDetails(entry, summaries),
                formatUnixToDDMMYYYY(entry.entryStartDate),
                getShiftAccepted(entry),
                getBriefingAccepted(entry),
                getStartDate(entry),
                getEndDate(entry),
                getStaffWage(entry)
            ];
        })
    };
}

function getBodyStyles(doc: jsPDF): Partial<Styles> {
    doc.addFileToVFS("Montserrat-Regular-normal.ttf", montserratRegularFont);
    doc.addFont("Montserrat-Regular-normal.ttf", "Montserrat-Regular", "normal");
    return {
        font: "Montserrat-Regular",
        cellPadding: 3,
        valign: "middle"
    };
}

function getHeadStyle(doc: jsPDF): Partial<Styles> {
    doc.addFileToVFS("Magistral-Bold-normal.ttf", magistralFont);
    doc.addFont("Magistral-Bold-normal.ttf", "Magistral", "normal");
    return {
        fillColor: [26, 42, 53],
        font: "Magistral",
        cellPadding: 3,
        valign: "middle"
    };
}
