import React, {useContext} from "react";
import {AssignmentState, StaffAssignment} from "../../../../../../../../api/grs";
import {CalendarEntrySettingsContext} from "../../../../../../../Context/CalendarEntrySettingsContext";
import {Modal, useModal} from "pulse-modal";
import {getClassForStaffAssignment} from "../../../../../Helpers/calenderEntryHelpers";
import EditCalendarEntryStaffAssignmentForm from "./EditCalendarEntryStaffAssignmentForm";

/** Assignment details for Calendar Entry */
const CalendarEntryStaffAssignment = (props: StaffAssignment) => {
    const settings = useContext(CalendarEntrySettingsContext);
    const {toggle, isShown} = useModal();

    const getAssignmentName = () => {
        switch (props.state) {
            case AssignmentState.Unassigned:
                return AssignmentState.Unassigned;
            default:
                return props.staffMember?.staffName;
        }
    };

    return (
        <React.Fragment>
            {settings.showStaffDetails ? (
                <React.Fragment>
                    <div className="row ml-0 mr-0">
                        <div className="d-inline-block mr-3">
                            <div className="admin">
                                <div
                                    className={getClassForStaffAssignment(props.state)}
                                    id={props.id}
                                    onClick={toggle}
                                />
                            </div>
                        </div>
                        <div className="d-inline-block">
                            <div className="admin">
                                <p className="mb-0">{getAssignmentName()}</p>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            ) : (
                <div className="admin">
                    <div
                        className={getClassForStaffAssignment(props.state)}
                        id={props.id}
                        onClick={toggle}
                    />
                </div>
            )}
            <Modal
                isShown={isShown}
                onClose={toggle}
                title={
                    props.state === AssignmentState.Unassigned
                        ? "Add Assignment"
                        : "Edit Assignment"
                }
                modalSize={"md"}
                bodyChildren={
                    <EditCalendarEntryStaffAssignmentForm assignment={props} onClose={toggle} />
                }
            />
        </React.Fragment>
    );
};

export default CalendarEntryStaffAssignment;
