import React from "react";
import {StaffMemberInvoice} from "../../../../../../../api/grs";
import {useInvoiceErrorsTable} from "./Hooks/useInvoiceErrorsTable";
import InvoiceErrorTableRow from "./InvoiceErrorTableRow";
import InvoiceErrorsTableHeader from "./InvoiceErrorsTableHeader";

function InvoiceErrorsTable(props: StaffMemberInvoice) {
    const {headers, items} = useInvoiceErrorsTable(props);
    return (
        <React.Fragment>
            <InvoiceErrorsTableHeader headers={headers} />
            {items.map((item, key) => {
                return <InvoiceErrorTableRow {...item} key={key} />;
            })}
        </React.Fragment>
    );
}

export default InvoiceErrorsTable;
