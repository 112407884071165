import React from "react";
import {CalendarEntry, StaffBlockSection} from "../../../../../../../api/grs";
import {useState} from "react";
import EntrySettings, {EntrySettingsProps} from "../../Settings/EntrySettings";
import {CalendarEntrySettingsContext} from "../../../../../../Context/CalendarEntrySettingsContext";
import AddCalendarEntrySection from "./Section/AddCalendarEntrySection";
import CalendarEntrySection from "./Section/CalendarEntrySection";

const CalendarEntryStaffBlock = (props: CalendarEntry) => {
    const [entrySettings, setEntrySettings] = useState<EntrySettingsProps>({
        showEditButtons: false,
        showStaffDetails: false
    });

    /** Shows settings for staff details*/
    const onSettingsChanged = (value: EntrySettingsProps) => {
        setEntrySettings(value);
    };

    return (
        <React.Fragment>
            <EntrySettings onChange={onSettingsChanged} />
            <div className="pb-3">
                <CalendarEntrySettingsContext.Provider value={entrySettings}>
                    {props.requiredStaff.sections.map((el: StaffBlockSection) => {
                        return <CalendarEntrySection {...el} key={el.id} />;
                    })}
                    {entrySettings.showEditButtons && <AddCalendarEntrySection />}
                </CalendarEntrySettingsContext.Provider>
            </div>
        </React.Fragment>
    );
};

export default CalendarEntryStaffBlock;
