import {useContext, useEffect, useState} from "react";
import {AvailableStaffLinkListContext} from "../../Context/AvailableStaffLinkListContext";
import {CalendarEntryContext} from "../../Context/CalendarEntryContext";
import {useSelector} from "react-redux";
import {RootStore} from "../../../store/Store";
import {StaffLinkAddedDetails} from "../../Pages/AdminCalendarEntry/Helpers/calenderEntryHelpers";
import {DropdownOption} from "../Types/types";
import {Venue} from "../../../api/grs";
import {convertStaffLinkDetailsToDropdownProps} from "../Helpers/dropdownUtils";
import {GeoLocation} from "../../../api/staff";
import {EntryAssignmentDropdownProps} from "../Components/EntryAssignmentDropdown";
import {SingleValue} from "react-select";

export function useEntryAssignmentDropdown(props: EntryAssignmentDropdownProps) {
    const availableStaff = useContext(AvailableStaffLinkListContext);
    const entry = useContext(CalendarEntryContext);
    const venueList = useSelector((state: RootStore) => state.venueList);
    const [staffOptions, setStaffOptions] = useState<
        (GroupedOptionsWithDistance | DropdownOptionWithDistance)[]
    >([]);
    const geoLocation = useSelector((state: RootStore) => state.geoLocation);

    const [selectedOption, setSelectedOption] = useState<DropdownOptionWithDistance | undefined>(
        undefined
    );

    useEffect(() => {
        if (!props.initialAssignment) {
            setSelectedOption(undefined);
            return;
        }
        if (!props.initialAssignment.staffId.length) {
            setSelectedOption(undefined);
            return;
        }

        setSelectedOption({
            label: props.initialAssignment.staffName,
            value: props.initialAssignment.staffId
        });
    }, [props.initialAssignment]);

    useEffect(() => {
        setStaffOptions(getGroupedAssignments(availableStaff));
    }, [availableStaff]);

    function getGroupedAssignments(
        staffList: StaffLinkAddedDetails[]
    ): (GroupedOptionsWithDistance | DropdownOptionWithDistance)[] {
        const regionId = getRegionId();

        if (regionId) {
            return getGroupedAssignmentsWithDistances(staffList, regionId);
        }

        return [getNearestStaffOptions("Staff Members", staffList)];
    }

    function getGroupedAssignmentsWithDistances(
        staffList: StaffLinkAddedDetails[],
        regionId: number
    ): GroupedOptionsWithDistance[] {
        const nearest = getNearestStaffOptions(
            "Staff within region",
            staffList.filter((staff) => filterByRegion(staff, regionId))
        );

        const rest = staffList.filter((staff) => !filterByRegion(staff, regionId));
        return [
            nearest,
            getGroupedOptions(
                nearest.options.length > 0 ? "Other Staff Members" : "Staff Members",
                rest
            )
        ] as GroupedOptionsWithDistance[];
    }

    function getRegionId(): number | undefined {
        const venue = getVenueForEntry();
        if (!venue) return;
        const {regionId} = venue;

        return regionId;
    }

    function getNearestStaffOptions(
        label: string,
        staffList: StaffLinkAddedDetails[]
    ): GroupedOptionsWithDistance {
        const options: DropdownOptionWithDistance[] = staffList
            .map((staff) => {
                return {
                    label: staff.staffName,
                    value: staff.staffId,
                    distance: getDistanceBetweenGeoLocationsInMiles(
                        staff.location,
                        geoLocation.data
                    )
                };
            })
            .sort((a, b) => a.distance - b.distance);

        return {
            label,
            options
        };
    }

    function filterByRegion(staff: StaffLinkAddedDetails, regionId: number) {
        return (staff.regionIds || []).includes(regionId);
    }

    function getGroupedOptions(
        label: string,
        staffList: StaffLinkAddedDetails[]
    ): GroupedOptionsWithDistance {
        return {
            label,
            options: convertStaffLinkDetailsToDropdownProps(staffList)
        };
    }

    function getDistanceBetweenGeoLocationsInMiles(
        location1?: GeoLocation | null,
        location2?: GeoLocation | null
    ): number {
        if (!location1 || !location2) return 0;

        const R = 6371e3; // metres
        const φ1 = (location1.latitude * Math.PI) / 180; // φ, λ in radians
        const φ2 = (location2.latitude * Math.PI) / 180;
        const Δφ = ((location2.latitude - location1.latitude) * Math.PI) / 180;
        const Δλ = ((location2.longitude - location1.longitude) * Math.PI) / 180;

        const a =
            Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
            Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return meterToMiles(R * c);
    }

    function meterToMiles(meterValue: number) {
        return meterValue / 1609;
    }

    function getVenueForEntry(): Venue | undefined {
        const {data} = venueList;
        if (!data) return;

        return data.find((venue) => venue.id === entry.venueId);
    }

    function onDropdownOptionChanged(newValue: SingleValue<DropdownOptionWithDistance>) {
        if (!newValue) {
            props.changeOption(undefined);
            setSelectedOption(undefined);
            return;
        }
        const assignment = availableStaff.find((item) => item.staffId === newValue.value);

        setSelectedOption(assignment ? newValue : undefined);
        props.changeOption(assignment);
    }

    return {
        staffOptions,
        selectedOption,
        onDropdownOptionChanged
    };
}

export interface GroupedOptionsWithDistance {
    label: string;
    options: DropdownOptionWithDistance[];
}

export interface GroupedOption {
    label: string;
    options: DropdownOption[];
}

export interface DropdownOptionWithDistance extends DropdownOption {
    distance?: number;
}
