import {StaffInterestQueryResponse} from "../../../api/grs";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const STAFF_INTEREST_QUERY_RESPONSE_STORE = createStoreState(
    "staff_interest_query_response"
);

interface StaffInterestQueryResponseReportLoading
    extends StoreServiceData<StaffInterestQueryResponse> {
    type: typeof STAFF_INTEREST_QUERY_RESPONSE_STORE.LOADING;
}
interface StaffInterestQueryResponseReportError
    extends StoreServiceData<StaffInterestQueryResponse> {
    type: typeof STAFF_INTEREST_QUERY_RESPONSE_STORE.ERROR;
}
interface StaffInterestQueryResponseReportSuccess
    extends StoreServiceData<StaffInterestQueryResponse> {
    type: typeof STAFF_INTEREST_QUERY_RESPONSE_STORE.SUCCESS;
}

export type StaffInterestQueryResponseDispatchTypes =
    | StaffInterestQueryResponseReportLoading
    | StaffInterestQueryResponseReportError
    | StaffInterestQueryResponseReportSuccess;
