import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {setCalendarEntry} from "../../../../../../../store/calendarEntry/actions/CalendarEntryActions";
import {EventCalendarEntry} from "../../../../../../../api/grs";
import TimeDropdown from "../../../../../../Dropdown/Components/TimeDropdown";
import FormRow from "../../../../../../Form/FormRow";

/** For Event Calendar Entry. Shows Venue and Depot Time and the options */
const EventCalendarTimes = (props: EventCalendarEntry) => {
    const dispatch = useDispatch();

    const [depotTime, setDepotTime] = useState<number>(props.depotTime);
    const [venueTime, setVenueTime] = useState<number>(props.venueTime);

    /** Triggered when start time has been changed */
    const onDepotTimeChanged = useCallback(
        (id?: string | number) => {
            if (!id) return;
            setDepotTime(+id);
        },
        [depotTime]
    );

    /** Triggered when end time has been changed */
    const onVenueTimeChanged = useCallback(
        (id?: string | number) => {
            if (!id) return;
            setVenueTime(+id);
        },
        [venueTime]
    );

    /** Update Calendar Entry with new depot time */
    useEffect(() => {
        dispatch(
            setCalendarEntry({
                ...props,
                depotTime
            })
        );
    }, [depotTime]);

    /** Update Calendar Entry with new venue time */
    useEffect(() => {
        dispatch(
            setCalendarEntry({
                ...props,
                venueTime
            })
        );
    }, [venueTime]);

    return (
        <React.Fragment>
            <div className="row ml-0 mr-0 mt-3">
                <div className="col-sm-6 pl-0 calendar-entry-input-override">
                    <FormRow rowName={"Depot Time"} columnDetailClassName={"pl-0 pr-0"}>
                        <TimeDropdown
                            entry={props}
                            id={depotTime}
                            optionsPerHour={4}
                            clearable={false}
                            disabled={false}
                            daysToGenerate={1}
                            changeOption={onDepotTimeChanged}
                            isSearchable={true}
                        />
                    </FormRow>
                </div>
                <div className="col-sm-6 pr-0 calendar-entry-input-override">
                    <FormRow rowName={"Venue Time"} columnDetailClassName={"pl-0 pr-0"}>
                        <TimeDropdown
                            entry={props}
                            id={venueTime}
                            optionsPerHour={4}
                            clearable={false}
                            disabled={false}
                            daysToGenerate={1}
                            changeOption={onVenueTimeChanged}
                            isSearchable={true}
                        />
                    </FormRow>
                </div>
            </div>
        </React.Fragment>
    );
};

export default EventCalendarTimes;
