import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {Modal, useModal} from "pulse-modal";
import {addStaffBlockSection} from "../../../../../../../../store/calendarEntry/actions/CalendarEntryActions";
import {IconType} from "../../../../../../../Icon/Icon";
import FormRow from "../../../../../../../Form/FormRow";
import {DebounceInput} from "react-debounce-input";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../../../../Button/MCButton";
import {showErrorToast} from "../../../../../../../../utils/toastUtils";
import FormHeaderWithAction from "../../../../../../../Form/FormHeaderWithAction";

/** Allows for a new staff block to be added to the Calendar Entry */
const AddCalendarEntrySection = () => {
    const dispatch = useDispatch();
    const [sectionName, setSectionName] = useState("");
    const {isShown, toggle} = useModal();

    /** Set section name */
    const updateStaffBlockSectionName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSectionName(event.target.value);
    };

    /** Saves the section. Checks to see if the name is valid before allowing the action to be completed */
    const addSection = async () => {
        if (sectionName.length < 3) {
            showErrorToast("Section name must be more than 3 characters long");
            return;
        }

        await dispatch(addStaffBlockSection(sectionName));

        toggle();
    };
    return (
        <React.Fragment>
            <FormHeaderWithAction
                headerName={"Add Section"}
                icon={IconType.Add}
                onClick={toggle}
                showIcon={true}
                tooltipText={"Add Section"}
            />
            <Modal
                isShown={isShown}
                onClose={toggle}
                title={"Add Section"}
                modalSize={"sm"}
                bodyChildren={
                    <React.Fragment>
                        <FormRow rowName={"New Section Name"} columnDetailClassName={"pl-0 pr-0"}>
                            <DebounceInput
                                debounceTimeout={300}
                                placeholder={"Enter new section name..."}
                                className="input-fields"
                                value={sectionName}
                                onChange={updateStaffBlockSectionName}
                            />
                        </FormRow>
                    </React.Fragment>
                }
                footerChildren={
                    <React.Fragment>
                        <div className="row mt-3 ml-0 mr-0">
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"Save"}
                                onClick={addSection}
                                colour={ButtonColourOptions.Yellow}
                                roundedCorner
                            />
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"Cancel"}
                                onClick={toggle}
                                colour={ButtonColourOptions.DarkBlue}
                                roundedCorner
                            />
                        </div>
                    </React.Fragment>
                }
            />
        </React.Fragment>
    );
};

export default AddCalendarEntrySection;
