import React from "react";
import {CalendarCompliance} from "../../../../api/grs";
import {toArray} from "../../../../utils/sortingUtils";
import CalendarComplianceTile from "./CalendarComplianceTile";

function CalendarComplianceTileContainer(props: CalendarCompliance[]) {
    return (
        <React.Fragment>
            <h5 className="dash-tile-section-title">GRS Scheduling Dashboard</h5>
            <div className="dash-tiles-wrapper" data-testid={"dash-tiles-container"}>
                {toArray(props).map((compliance, index) => {
                    return <CalendarComplianceTile {...compliance} key={index} />;
                })}
            </div>
        </React.Fragment>
    );
}

export default CalendarComplianceTileContainer;
