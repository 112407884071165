import {useEffect, useState} from "react";
import {useQuery} from "../../../../../Hooks/useQuery";
import moment, {Moment} from "moment";

export function useGlobalCalendarMobileFilters() {
    const [currentDate, setCurrentDate] = useState<Moment>();
    const query = useQuery();

    useEffect(() => {
        const day = query.get(MobileCalendarQueryString.day);

        setCurrentDate(day ? moment.unix(+day) : moment());
    }, []);

    return {
        currentDate
    };
}

export const MobileCalendarQueryString = {
    day: "day"
};
