import React, {useEffect, useRef, useState} from "react";
import {AssignmentState, StaffAssignment} from "../../../../api/grs";
import Toggle, {ToggleSize} from "../../../Toggle/Toggle";

const AttendanceToggle = (props: AttendanceRegisterTableItemProps) => {
    /** Hold previous assignment state in case user made a mistake */
    const previousState = useRef<AssignmentState>(props.assignment.state);
    const [toggle, setToggle] = useState<boolean>(true);

    useEffect(() => {
        switch (props.assignment.state) {
            case AssignmentState.Accepted:
            case AssignmentState.BriefingAccepted:
            case AssignmentState.Unassigned:
            case AssignmentState.WaitingAccept:
                setToggle(false);
                break;
            case AssignmentState.Attended:
                setToggle(true);
                break;
        }
    }, [props]);

    /** When the toggle value has changed */
    const onAttendedToggleChanged = (attended: boolean) => {
        setToggle((attended = !attended));

        const updatedAssignment: StaffAssignment = {
            ...props.assignment,
            state: attended ? AssignmentState.Attended : previousState.current
        };

        props.onChange(updatedAssignment);
    };

    return (
        <Toggle
            checked={toggle}
            onChange={() => onAttendedToggleChanged(toggle)}
            size={ToggleSize.Small}
        />
    );
};

export default AttendanceToggle;

interface AttendanceRegisterTableItemProps {
    assignment: StaffAssignment;
    onChange: (event: StaffAssignment) => void;
}
