import {STAFF_LIST_STORE_STATE, StaffListDispatchTypes} from "./StaffListActionTypes";
import {Dispatch} from "redux";
import StaffApiModel from "../../apiModel/StaffApiModel";
import {StaffAccessLevel, UserComplexRequest, UserData} from "../../../api/staff";
import {RootStore} from "../../Store";

import {getDataFromServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";

/** Nukes store of stale data */
export const nullifyStaffListStore = () => {
    return async (dispatch: Dispatch<StaffListDispatchTypes>) => {
        dispatch({
            type: STAFF_LIST_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

/** Gets all staff members in the MC database */
export const getAllMedicareStaffMembers = () => {
    return async (dispatch: Dispatch<StaffListDispatchTypes>, state: () => RootStore) => {
        try {
            await getDataFromServiceWithRedux(
                STAFF_LIST_STORE_STATE,
                dispatch,
                () => StaffApiModel.getUsersApi().getAllUsers(),
                statusCodeCallback
            );
            const staffList = state().staffList.data;
            return staffList ? staffList : [];
        } catch (e: any) {
            dispatch({
                type: STAFF_LIST_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

/** Gets all staff members in the MC database */
export const getAllMedicareStaffMembersComplex = (request: UserComplexRequest) => {
    return async (dispatch: Dispatch<StaffListDispatchTypes>, state: () => RootStore) => {
        try {
            await getDataFromServiceWithRedux(
                STAFF_LIST_STORE_STATE,
                dispatch,
                () => StaffApiModel.getUsersApi().getUsersComplex(request),
                statusCodeCallback
            );
            const staffList = state().staffList.data;
            return staffList ? staffList : [];
        } catch (e: any) {
            dispatch({
                type: STAFF_LIST_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

/** Gets all staff members in the MC database and then returns staff members who match the filter */
export const getStaffMembersByAccessLevel = (accessLevel: StaffAccessLevel) => {
    return async (dispatch: Dispatch<StaffListDispatchTypes>, state: () => RootStore) => {
        try {
            await getDataFromServiceWithRedux(
                STAFF_LIST_STORE_STATE,
                dispatch,
                () => StaffApiModel.getUsersApi().getAllUsers(),
                statusCodeCallback
            );

            const staffList = state().staffList.data;
            return staffList
                ? staffList.filter((el: UserData) => el.accessLevel === accessLevel)
                : [];
        } catch (e: any) {
            dispatch({
                type: STAFF_LIST_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
