import React, {Fragment} from "react";
import moment from "moment-timezone";
import {useHistory} from "react-router-dom";
import DatePicker from "react-datepicker";
import DatePickerInputButton from "../../../Button/DatePickerInputButton";
import {ActiveShiftView, ShiftOverviewFilters} from "../Provider/ShiftOverviewProvider";
import FilterContainer from "../../../Filters/FilterContainer";
import {getActiveShiftViewFromString} from "../../../../utils/enumUtils";
import ShiftViewButton from "./ShiftViewButton";
import useMcConfig from "../../../Hooks/useMcConfig";
import {isSameISOWeek} from "date-fns";
import AuthAmI from "../../../AuthAmI/AuthAmI";
import {StaffAccessLevel} from "../../../../api/staff";
import {useShiftOverviewContext} from "../Hooks/useShiftOverviewContext";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import {formatUnixToDDMMYYYY, formatUnixToMMMMYYYY} from "../../../../utils/momentUtils";
import {useUserModuleAccessLevel} from "../../../Hooks/useUserModuleAccessLevel";

const AvailableShiftListFilters = () => {
    const {state} = useShiftOverviewContext();
    const history = useHistory();
    const {config} = useMcConfig();
    const moduleAccessLevel = useUserModuleAccessLevel();

    const getIsoWeekPickerDateFormat = (): string | string[] | undefined => {
        if (!state.week) return [];
        const startOfIsoWeek = state.week.clone().startOf("isoWeek");
        const endOfIsoWeek = state.week.clone().endOf("isoWeek");

        return `${startOfIsoWeek.tz(config.timezone).format("DD/MM/YYYY")} - ${endOfIsoWeek
            .tz(config.timezone)
            .format("DD/MM/YYYY")}`;
    };

    const buildUrlHistory = (request: ShiftOverviewFilters) => {
        const currentView = getCurrentView(request);
        history.push({
            search: `?${currentView}=${request[currentView]?.unix()}&view=${request.view}`
        });
    };

    function getCurrentView(request: ShiftOverviewFilters) {
        if (moduleAccessLevel !== StaffAccessLevel.SystemAdministrator) return "month";

        return request.view === ActiveShiftView.ShiftOverview ? "day" : "week";
    }

    const getIsoWeekDayClassName = (dateLeft: Date, dateRight: Date) => {
        return isSameISOWeek(dateLeft, dateRight) ? "react-datepicker__day--selected" : "";
    };

    function getSwitchedState(view: ActiveShiftView) {
        switch (view) {
            case ActiveShiftView.BulkAccept:
                return {
                    week: state.day,
                    day: undefined
                };
            case ActiveShiftView.ShiftOverview:
                return {
                    week: undefined,
                    day: state.week
                };
        }
    }

    const previousWeekButtonText = () => {
        if (!state.week) return "";
        const unixTime = state.week.clone().subtract(1, "week").startOf("isoWeek").unix();
        return `Previous (${formatUnixToDDMMYYYY(unixTime)})`;
    };

    const nextWeekButtonText = () => {
        if (!state.week) return "";
        const unixTime = state.week.clone().add(1, "week").startOf("isoWeek").unix();
        return `Next (${formatUnixToDDMMYYYY(unixTime)})`;
    };

    const previousMonthButtonText = () => {
        if (!state.month) return "";
        const unixTime = state.month.clone().subtract(1, "month").startOf("month").unix();
        return `Previous (${formatUnixToMMMMYYYY(unixTime)})`;
    };

    const nextMonthButtonText = () => {
        if (!state.month) return "";
        const unixTime = state.month.clone().add(1, "month").startOf("month").unix();
        return `Next (${formatUnixToMMMMYYYY(unixTime)})`;
    };
    return (
        <Fragment>
            <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                <div className="dash-tile-button-group">
                    {Object.keys(ActiveShiftView).map((view) => {
                        return (
                            <ShiftViewButton
                                key={view}
                                onClick={(newView) => {
                                    if (newView === state?.view) return;
                                    buildUrlHistory({
                                        ...state,
                                        ...getSwitchedState(newView),
                                        view: getActiveShiftViewFromString(newView)
                                    });
                                }}
                                view={getActiveShiftViewFromString(view)}
                                isActive={state.view === view}
                            />
                        );
                    })}
                </div>
            </AuthAmI>
            <FilterContainer closed={false}>
                <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                    <div className="filter-inner-container-wrapper">
                        {state.view === ActiveShiftView.ShiftOverview ? (
                            <div className="filter-item">
                                <h6>Date</h6>
                                <DatePicker
                                    selected={state.day?.toDate()}
                                    onChange={(date) => {
                                        if (!date) return;

                                        buildUrlHistory({
                                            ...state,
                                            day: moment(date),
                                            month: undefined,
                                            week: undefined
                                        });
                                    }}
                                    dateFormat="dd/MM/yyyy"
                                    portalId="root-portal"
                                    customInput={<DatePickerInputButton />}
                                    calendarStartDay={1}
                                />
                            </div>
                        ) : (
                            <div className="filter-item">
                                <h6>Week</h6>
                                <DatePicker
                                    selected={state.week?.toDate()}
                                    onChange={(date) => {
                                        if (!date) return;

                                        buildUrlHistory({
                                            ...state,
                                            day: undefined,
                                            month: undefined,
                                            week: moment(date)
                                        });
                                    }}
                                    dateFormat={getIsoWeekPickerDateFormat()}
                                    dayClassName={(date: Date) =>
                                        getIsoWeekDayClassName(date, state.week!.toDate())
                                    }
                                    calendarStartDay={1}
                                    portalId="root-portal"
                                    customInput={<DatePickerInputButton />}
                                />
                            </div>
                        )}
                    </div>
                    <Fragment>
                        {state.view === ActiveShiftView.BulkAccept && (
                            <div className="filter-week-controls-container">
                                <MCButton
                                    size={ButtonSize.Large}
                                    innerValue={previousWeekButtonText()}
                                    onClick={() => {
                                        const prevWeek = state.week?.clone().subtract(1, "week");
                                        buildUrlHistory({
                                            ...state,
                                            day: undefined,
                                            month: undefined,
                                            week: prevWeek
                                        });
                                    }}
                                    colour={ButtonColourOptions.DarkBlue}
                                    roundedCorner
                                />
                                <MCButton
                                    size={ButtonSize.Large}
                                    innerValue={nextWeekButtonText()}
                                    onClick={() => {
                                        const nextWeek = state.week?.clone().add(1, "week");
                                        buildUrlHistory({
                                            ...state,
                                            day: undefined,
                                            month: undefined,
                                            week: nextWeek
                                        });
                                    }}
                                    colour={ButtonColourOptions.DarkBlue}
                                    roundedCorner
                                />
                            </div>
                        )}
                    </Fragment>
                </AuthAmI>
                <AuthAmI accessLevels={[StaffAccessLevel.Staff, StaffAccessLevel.DutyManager]}>
                    <div className="filter-inner-container-wrapper">
                        <div className="filter-item">
                            <h6>Month</h6>
                            <DatePicker
                                selected={state.month?.toDate()}
                                onChange={(date) => {
                                    if (!date) return;

                                    buildUrlHistory({
                                        ...state,
                                        month: moment(date),
                                        week: undefined,
                                        day: undefined
                                    });
                                }}
                                showMonthYearPicker
                                dateFormat="MMMM yyyy"
                                portalId="root-portal"
                                customInput={<DatePickerInputButton />}
                                calendarStartDay={1}
                            />
                        </div>
                    </div>
                    <div className="filter-week-controls-container">
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={previousMonthButtonText()}
                            onClick={() => {
                                const prevMonth = state.month?.clone().subtract(1, "month");
                                buildUrlHistory({
                                    ...state,
                                    day: undefined,
                                    week: undefined,
                                    month: prevMonth
                                });
                            }}
                            colour={ButtonColourOptions.DarkBlue}
                            roundedCorner
                        />
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={nextMonthButtonText()}
                            onClick={() => {
                                const nextMonth = state.month?.clone().add(1, "month");
                                buildUrlHistory({
                                    ...state,
                                    day: undefined,
                                    week: undefined,
                                    month: nextMonth
                                });
                            }}
                            colour={ButtonColourOptions.DarkBlue}
                            roundedCorner
                        />
                    </div>
                </AuthAmI>
            </FilterContainer>
        </Fragment>
    );
};

export default AvailableShiftListFilters;
