import React, {useEffect} from "react";
import {routeNames} from "../../Navigation/routeNames";
import {useDispatch, useSelector} from "react-redux";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {usePageUrl} from "../../Hooks/usePageUrl";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {CalendarMonthView} from "../AdminCalendar/Helpers/calendarUtils";
import {useQuery} from "../../Hooks/useQuery";
import {getLinkToGlobalCalendarFromReadOnlyEntry} from "./Helpers/readOnlyCalendarHelpers";
import {useParams} from "react-router-dom";
import {
    fetchCalendarEntry,
    nullifyCalendarEntryStore
} from "../../../store/calendarEntry/actions/CalendarEntryActions";
import ReadOnlyCalendarEntryForm from "./Components/ReadOnlyCalendarEntryForm";
import {WithServiceState} from "store-fetch-wrappers/dist";
import {RootStore} from "../../../store/Store";
import {getCalendar, nullifyCalendarStore} from "../../../store/calendar/actions/CalendarActions";

const CalendarEntryServiceWrapper = WithServiceState(ReadOnlyCalendarEntryForm);

const ReadOnlyCalendarEntry = () => {
    const dispatch = useDispatch();
    const {fullPath} = usePageUrl();
    const query = useQuery();
    const params: any = useParams();
    const calendarEntryStore = useSelector((state: RootStore) => state.calendarEntry);
    const calendarStore = useSelector((state: RootStore) => state.calendar);
    useEffect(() => {
        const navItems: NavigationItem[] = [
            {
                name: CalendarMonthView.Staff,
                path: getLinkToGlobalCalendarFromReadOnlyEntry(query)
            },
            {
                name: routeNames.readOnlyEntry.name,
                path: fullPath
            }
        ];

        dispatch(setNavigationItems(navItems));
        const calendarId = query.get("calendarId");
        if (!calendarId) return;

        dispatch(getCalendar(+calendarId));
        dispatch(fetchCalendarEntry(+params.id));
        //Clean the stale data from the store.
        return function () {
            dispatch(nullifyCalendarStore());
            dispatch(nullifyCalendarEntryStore());
        };
    }, []);
    return (
        <React.Fragment>
            <CalendarEntryServiceWrapper
                showLoadingText={true}
                loaderType={"overlay"}
                loaderWheelType={"three-ring"}
                placeholderBlockCount={1}
                {...calendarEntryStore}
            />
        </React.Fragment>
    );
};

export default ReadOnlyCalendarEntry;
