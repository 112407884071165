import {routeNames} from "../../../Navigation/routeNames";
import {CalendarMonthView} from "../../AdminCalendar/Helpers/calendarUtils";

export function getLinkToGlobalCalendarFromReadOnlyEntry(query: URLSearchParams) {
    const encodedCalendarName = encodeURI(CalendarMonthView.Staff);
    return `${routeNames.globalCalendar.path}/${encodedCalendarName}${getMonthQueryForRoute(
        query
    )}`;
}

function getMonthQueryForRoute(query: URLSearchParams) {
    const month = query.get("month");

    return month ? `?month=${month}` : "";
}
