import {Moment} from "moment";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export interface HorizontalCalendarProps_EXPERIMENTAL {
    // Optional prop to pass a custom date to use instead of today
    currentDate: Moment;
    // Callback executed when user taps on a date
    onSelectDate: (date: Moment) => void;
    // Number of days to show before today or custom current date
    showDaysAfterCurrent?: number;
    // Number of days to show after
    showDaysBeforeCurrent?: number;
}

export interface HorizontalCalendarProps {
    // Optional prop to pass a custom date to use instead of today
    currentDate: Moment;
    // Callback executed when user taps on a date
    onSelectDate: (date: Moment) => void;
}

export interface HorizontalCalendarState {
    // Last Date Selected for reference to reset back to
    lastSelectedDate?: Moment;
    // // Currently chosen date index
    currentDateIndex?: number;
    // // for rendering month(s) and year(s) above the dates
    // Array of dates to show
    dates: Array<Moment>;
    // Store each day with to help with scrolling to specific days
    // and calculating which days are visible on the screen
    dayWidths: Map<number, number>;
}

export const HORIZONTAL_CALENDAR_STORE = createStoreState("horizontal_calendar");

interface HorizontalCalendarLoading extends StoreServiceData<HorizontalCalendarState> {
    type: typeof HORIZONTAL_CALENDAR_STORE.LOADING;
}

interface HorizontalCalendarError extends StoreServiceData<HorizontalCalendarState> {
    type: typeof HORIZONTAL_CALENDAR_STORE.ERROR;
}

interface HorizontalCalendarSuccess extends StoreServiceData<HorizontalCalendarState> {
    type: typeof HORIZONTAL_CALENDAR_STORE.SUCCESS;
}

export type HorizontalCalendarDispatchTypes =
    | HorizontalCalendarLoading
    | HorizontalCalendarError
    | HorizontalCalendarSuccess;
