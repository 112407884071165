import React from "react";
import {CalendarEntry} from "../../../../../../api/grs";
import {formatUnixToHHmmddd} from "../../../../../../utils/momentUtils";
import FormRow from "../../../../../Form/FormRow";

const ReadOnlyEntryTimeDetails = (props: CalendarEntry) => {
    return (
        <React.Fragment>
            <div className="row ml-0 mr-0 mt-3">
                <div className="col-sm-6 pl-0 calendar-entry-input-override">
                    <FormRow rowName={"Scheduled Shift Time"}>
                        <p className="mb-0">{formatUnixToHHmmddd(props.startDate)}</p>
                    </FormRow>
                </div>
                <div className="col-sm-6 pr-0 calendar-entry-input-override">
                    <FormRow rowName={"Scheduled Finish Time"}>
                        <p className="mb-0">{formatUnixToHHmmddd(props.endDate)}</p>
                    </FormRow>
                </div>
            </div>
            <div className="row ml-0 mr-0 mt-3">
                <div className="col-sm-6 pl-0 calendar-entry-input-override">
                    <FormRow rowName={"Actual Shift Time"}>
                        {props.actualStartDate ? (
                            <p className="mb-0">{formatUnixToHHmmddd(props.actualStartDate)}</p>
                        ) : (
                            <p className="mb-0">Actual shift time not specified</p>
                        )}
                    </FormRow>
                </div>
                <div className="col-sm-6 pr-0 calendar-entry-input-override">
                    <FormRow rowName={"Actual Finish Time"}>
                        {props.actualEndDate ? (
                            <p className="mb-0">{formatUnixToHHmmddd(props.actualEndDate)}</p>
                        ) : (
                            <p className="mb-0">Actual finish time not specified</p>
                        )}
                    </FormRow>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ReadOnlyEntryTimeDetails;
