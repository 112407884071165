import React from "react";
import FormRowWithTooltip from "../../../Form/FormRowWithTooltip";
import {IconType} from "../../../Icon/Icon";
import PulseDatePicker from "../../../DatePicker/PulseDatePicker";
import moment from "moment";
import FormActionContainer from "../../../Form/FormActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import {useRotaForm} from "../Hooks/useRotaForm";
import GenericTypeDropdown from "../../../Dropdown/Components/GenericTypeDropdown";
import {AssignmentState, RepeatAssignmentConflictResolution} from "../../../../api/grs";
import {showSuccessToast} from "../../../../utils/toastUtils";
import FormRow from "../../../Form/FormRow";
import FormHeader from "../../../Form/FormHeader";
import CalendarSummaryFilters from "../../CalendarGroups/AddEdit/Components/CalendarSummaryFilters";
import {Loading} from "store-fetch-wrappers";

function RotaForm() {
    const {
        save,
        request,
        updateRequest,
        getAssignmentStateFromString,
        getConflictResFromString,
        message,
        backToGRS,
        store
    } = useRotaForm();
    return (
        <React.Fragment>
            <FormHeader headerName={"Staff Rota"} />
            <FormRowWithTooltip
                icon={IconType.Question}
                tooltipText={"Select calendars of which a rota will need to be created"}
                rowName={"Applicable Calendars"}
                columnDetailClassName={"pl-0 pr-0"}
            >
                <CalendarSummaryFilters
                    calendarIds={request.calendarIds}
                    onChange={(calendarIds) => {
                        updateRequest({
                            ...request,
                            calendarIds
                        });
                    }}
                />
            </FormRowWithTooltip>
            <FormRowWithTooltip
                icon={IconType.Question}
                tooltipText={
                    "The date to copy the rota from. The shifts will be inclusive of the selected date."
                }
                rowName={"Copy from"}
                columnDetailClassName={"pl-0 pr-0"}
            >
                <PulseDatePicker
                    onChange={(value) => {
                        if (!value) return;
                        updateRequest({
                            ...request,
                            copyStartDateInclusive: moment(value).startOf("day").unix()
                        });
                    }}
                    initialDate={request.copyStartDateInclusive}
                />
            </FormRowWithTooltip>
            <FormRowWithTooltip
                icon={IconType.Question}
                tooltipText={
                    "The date to copy the rota to. The shifts will be exclusive of the selected date."
                }
                rowName={"Copy to"}
                columnDetailClassName={"pl-0 pr-0"}
            >
                <PulseDatePicker
                    onChange={(value) => {
                        if (!value) return;
                        updateRequest({
                            ...request,
                            copyEndDateExclusive: moment(value).startOf("day").unix()
                        });
                    }}
                    initialDate={request.copyEndDateExclusive}
                />
            </FormRowWithTooltip>
            <FormRowWithTooltip
                icon={IconType.Question}
                tooltipText={
                    "This will create a rota based on the range selected until the specified date"
                }
                rowName={"Repeat until"}
                columnDetailClassName={"pl-0 pr-0"}
            >
                <PulseDatePicker
                    onChange={(value) => {
                        if (!value) return;
                        updateRequest({
                            ...request,
                            repeatEndDateExclusive: moment(value).startOf("day").unix()
                        });
                    }}
                    initialDate={request.repeatEndDateExclusive}
                />
            </FormRowWithTooltip>
            <FormRowWithTooltip
                icon={IconType.Question}
                tooltipText={
                    "If an event is already in place (same start date, venue and calendar), use this conflict resolution strategy"
                }
                rowName={"Conflict Resolution"}
                columnDetailClassName={"pl-0 pr-0"}
            >
                <GenericTypeDropdown
                    enumOptions={RepeatAssignmentConflictResolution}
                    splitByCapitalLetter={true}
                    clearable={false}
                    searchable={false}
                    changeOption={(item) => {
                        updateRequest({
                            ...request,
                            conflictResolution: getConflictResFromString(item.toString())
                        });
                    }}
                    id={request.conflictResolution}
                    disabled={false}
                />
            </FormRowWithTooltip>
            <FormRowWithTooltip
                icon={IconType.Question}
                tooltipText={"Override assignment states to a set state. DEFAULT is ACCEPTED"}
                rowName={"Override Assignment State"}
                columnDetailClassName={"pl-0 pr-0"}
            >
                <GenericTypeDropdown
                    enumOptions={AssignmentState}
                    splitByCapitalLetter={true}
                    clearable={false}
                    searchable={false}
                    changeOption={(item) => {
                        updateRequest({
                            ...request,
                            assignmentState: getAssignmentStateFromString(item.toString())
                        });
                    }}
                    id={request.assignmentState}
                    disabled={false}
                />
            </FormRowWithTooltip>
            <FormRow rowName={"Frequency"}>
                <p>{message()}</p>
            </FormRow>
            {store.loading && <Loading type={"three-ring"} showLoadingText={false} />}
            <FormActionContainer>
                <MCButton
                    size={ButtonSize.Large}
                    innerValue={"Save"}
                    onClick={async () => {
                        const success = await save();

                        if (!success) return;
                        showSuccessToast(`Success! ${message()}`);
                        backToGRS();
                    }}
                    colour={ButtonColourOptions.Yellow}
                    roundedCorner
                />
                <MCButton
                    size={ButtonSize.Large}
                    innerValue={"Cancel"}
                    onClick={backToGRS}
                    colour={ButtonColourOptions.DarkBlue}
                    roundedCorner
                />
            </FormActionContainer>
        </React.Fragment>
    );
}

export default RotaForm;
