import {Dispatch} from "redux";
import {
    EXPANDING_TABLE_STORE,
    ExpandingTableDispatchTypes,
    ExpandTableRow,
    TableRedirect
} from "./ExpandingTableActionTypes";
import {RootStore} from "../../../../store/Store";

export const nullifyExpandingTableStore = () => {
    return async (dispatch: Dispatch<ExpandingTableDispatchTypes>) => {
        dispatch({
            type: EXPANDING_TABLE_STORE.SUCCESS,
            error: null,
            loading: false,
            data: {rows: []}
        });
    };
};

export const updateOpenedRow = (row: ExpandTableRow) => {
    return async (dispatch: Dispatch<ExpandingTableDispatchTypes>, state: () => RootStore) => {
        const currentRows = state().expandingTable.data?.rows || [];

        const rows = getRowState(currentRows, row);
        updateLocalStorage(rows);
        dispatch({
            type: EXPANDING_TABLE_STORE.SUCCESS,
            error: null,
            loading: false,
            data: {rows}
        });
    };
};

function updateLocalStorage(rows: ExpandTableRow[]) {
    const redirect: TableRedirect = {
        url: location.href,
        selectedRow: rows || []
    };

    localStorage.setItem("oplog_redirect", JSON.stringify(redirect));
}

function getRowState(currentRows: ExpandTableRow[], incomingRow: ExpandTableRow): ExpandTableRow[] {
    if (currentRows.length === 0) {
        return [incomingRow];
    }
    const item = currentRows.find((row) => row.id === incomingRow.id);

    if (item) {
        if (incomingRow.expandAction !== item.expandAction) {
            return [
                {
                    ...item,
                    expandAction: incomingRow.expandAction
                }
            ];
        }

        return [];
    }

    return [incomingRow];
}
