import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const REGISTER_INTEREST_STORE = createStoreState("register_interest");

interface ResendStoreLoading extends StoreServiceData<string> {
    type: typeof REGISTER_INTEREST_STORE.LOADING;
}
interface ResendStoreError extends StoreServiceData<string> {
    type: typeof REGISTER_INTEREST_STORE.ERROR;
}
interface ResendStoreSuccess extends StoreServiceData<string> {
    type: typeof REGISTER_INTEREST_STORE.SUCCESS;
}

export type RegisterInterestDispatchTypes =
    | ResendStoreLoading
    | ResendStoreError
    | ResendStoreSuccess;
