import React from "react";
import {PlatformOS, usePlatform} from "../../../Hooks/usePlatform";
import GlobalCalendarDesktop from "./Desktop/GlobalCalendarDesktop";
import AuthAmI from "../../../AuthAmI/AuthAmI";
import {StaffAccessLevel} from "../../../../api/staff";
import GlobalCalendarMobile from "./Mobile/GlobalCalendarMobile";

const GlobalCalendarWrapper = () => {
    const platform = usePlatform();

    return (
        <React.Fragment>
            {platform === PlatformOS.Desktop ? (
                <GlobalCalendarDesktop />
            ) : (
                <React.Fragment>
                    <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                        <GlobalCalendarDesktop />
                    </AuthAmI>
                    <AuthAmI accessLevels={[StaffAccessLevel.Staff, StaffAccessLevel.DutyManager]}>
                        <GlobalCalendarMobile />
                    </AuthAmI>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default GlobalCalendarWrapper;
