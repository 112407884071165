import {Dispatch} from "redux";
import {
    AddDriverDetailArgs,
    CreateDutyManagerReportArgs,
    DrivingDirection,
    DUTY_MANAGER_REPORT_STORE_STATE,
    DutyManagerReportDispatchTypes,
    DutyManagerReportPayload,
    RemoveDriverDetailsArgs,
    SetDriverDetailArgs,
    SetDriverDirectionArgs,
    VehicleDetails,
    VehicleDriver
} from "./DutyManagerReportActionTypes";
import {UserData} from "../../../api/staff";
import store, {RootStore} from "../../Store";
import {fetchVenueById} from "../../venue/actions/VenueActions";
import {DutyManagerReport, Venue} from "../../../api/grs";
import moment from "moment";
import {nanoid} from "nanoid";
import GrsApiModel from "../../apiModel/GrsApiModel";
import {postDataToServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";
import {
    fetchCalendarEntry,
    fetchCalendarEntrySimple
} from "../../calendarEntry/actions/CalendarEntryActions";

/** Clears data in store */
export const nullifyDutyManagerReportStore = () => {
    return async (dispatch: Dispatch<DutyManagerReportDispatchTypes>) => {
        dispatch({
            type: DUTY_MANAGER_REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: null
        });
    };
};

//Local mutations
export const setPayload = (payload: DutyManagerReportPayload) => {
    return async (dispatch: Dispatch<DutyManagerReportDispatchTypes>, state: () => RootStore) => {
        const existingReport = state().dutyManagerReport.data;
        if (!existingReport) return;

        const report: DutyManagerReport = {
            ...existingReport,
            payload: JSON.stringify(payload)
        };

        dispatch({
            type: DUTY_MANAGER_REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: report
        });
    };
};

/** Clears data in store */
export const createNewDutyManagerReport = (args: CreateDutyManagerReportArgs, user: UserData) => {
    return async (dispatch: Dispatch<DutyManagerReportDispatchTypes>) => {
        if (!args.venueId) return;

        try {
            dispatch({
                type: DUTY_MANAGER_REPORT_STORE_STATE.LOADING,
                loading: true,
                error: null
            });
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const venue: Venue = await store.dispatch(fetchVenueById(args.venueId));

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await store.dispatch(fetchCalendarEntrySimple(args.calendarEntryId));

            if (!venue) return;

            const payload: DutyManagerReportPayload = {
                vehicles: [],
                damageAndDefects: "",
                equipmentAndConsumables: "",
                operationalComments: ""
            };

            const report: DutyManagerReport = {
                id: 0,
                calendarEntryId: args.calendarEntryId,
                dutyManagerId: user.username,
                dutyManagerName: `${user.firstName} ${user.lastName}`,
                venueId: venue.id,
                venueName: venue.name,
                date: moment().unix(),
                payload: JSON.stringify(payload)
            };

            dispatch({
                type: DUTY_MANAGER_REPORT_STORE_STATE.SUCCESS,
                loading: false,
                error: null,
                data: report
            });
        } catch (e: any) {
            dispatch({
                type: DUTY_MANAGER_REPORT_STORE_STATE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};

export const addNewDriverDetails = (args: AddDriverDetailArgs) => {
    return async (dispatch: Dispatch<DutyManagerReportDispatchTypes>, state: () => RootStore) => {
        const reportCopy = state().dutyManagerReport.data;
        if (!reportCopy) return;

        const payload: DutyManagerReportPayload = JSON.parse(reportCopy.payload);

        const vehicleIndex = payload.vehicles.findIndex(
            (el: VehicleDetails) => el.uid === args.vehicleUID
        );

        if (vehicleIndex < 0) return; //Doesn't exist.

        //Get the vehicle.
        const vehicleDetails = payload.vehicles[vehicleIndex];

        vehicleDetails.assignments.push({
            uid: nanoid(),
            staffId: "",
            staffName: "",
            drivingDirection: DrivingDirection.Inbound
        });

        payload.vehicles[vehicleIndex] = vehicleDetails;

        //Set report.
        const report: DutyManagerReport = {
            ...reportCopy,
            payload: JSON.stringify(payload)
        };

        dispatch({
            type: DUTY_MANAGER_REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: report
        });
    };
};

export const setDriverDetails = (args: SetDriverDetailArgs) => {
    return async (dispatch: Dispatch<DutyManagerReportDispatchTypes>, state: () => RootStore) => {
        const reportCopy = state().dutyManagerReport.data;
        if (!reportCopy) return;

        const payload: DutyManagerReportPayload = JSON.parse(reportCopy.payload);

        const vehicleIndex = payload.vehicles.findIndex(
            (el: VehicleDetails) => el.uid === args.vehicleUID
        );

        if (vehicleIndex < 0) return; //Doesn't exist.

        //Get the vehicle.
        const vehicleDetails = payload.vehicles[vehicleIndex];

        //Find the previous assignment by staffId
        const staffIndex = vehicleDetails.assignments.findIndex(
            (el: VehicleDriver) => el.uid === args.previousAssignmentUID
        );

        if (staffIndex < 0) return; //Doesn't exist.

        //Set assignment at the correct index. and update the payload.
        vehicleDetails.assignments[staffIndex] = {
            ...vehicleDetails.assignments[staffIndex],
            staffId: args.newAssignment.staffId,
            staffName: args.newAssignment.staffName
        };

        payload.vehicles[vehicleIndex] = vehicleDetails;

        //Set report.
        const report: DutyManagerReport = {
            ...reportCopy,
            payload: JSON.stringify(payload)
        };

        dispatch({
            type: DUTY_MANAGER_REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: report
        });
    };
};

export const setDriverDirection = (args: SetDriverDirectionArgs) => {
    return async (dispatch: Dispatch<DutyManagerReportDispatchTypes>, state: () => RootStore) => {
        const reportCopy = state().dutyManagerReport.data;
        if (!reportCopy) return;

        const payload: DutyManagerReportPayload = JSON.parse(reportCopy.payload);

        for (const vehicle of payload.vehicles) {
            const index = vehicle.assignments.findIndex(
                (el: VehicleDriver) => el.uid === args.assignmentUID
            );

            //Found the assignment, kill the loop
            if (index > -1) {
                vehicle.assignments[index] = {
                    ...vehicle.assignments[index],
                    drivingDirection: args.newDirection
                };
                break;
            }
        }

        const report: DutyManagerReport = {
            ...reportCopy,
            payload: JSON.stringify(payload)
        };

        dispatch({
            type: DUTY_MANAGER_REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: report
        });
    };
};

export const removeDriverDetails = (args: RemoveDriverDetailsArgs) => {
    return async (dispatch: Dispatch<DutyManagerReportDispatchTypes>, state: () => RootStore) => {
        const reportCopy = state().dutyManagerReport.data;
        if (!reportCopy) return;

        const payload: DutyManagerReportPayload = JSON.parse(reportCopy.payload);

        for (const vehicle of payload.vehicles) {
            const index = vehicle.assignments.findIndex((el: VehicleDriver) => el.uid === args.uid);

            //Found the assignment, kill the loop
            if (index > -1) {
                vehicle.assignments.splice(index, 1);
                break;
            }
        }

        const report: DutyManagerReport = {
            ...reportCopy,
            payload: JSON.stringify(payload)
        };

        dispatch({
            type: DUTY_MANAGER_REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: report
        });
    };
};

export const addNewVehicle = () => {
    return async (dispatch: Dispatch<DutyManagerReportDispatchTypes>, state: () => RootStore) => {
        const reportCopy = state().dutyManagerReport.data;
        if (!reportCopy) return;
        const payload: DutyManagerReportPayload = JSON.parse(reportCopy.payload);

        payload.vehicles.push({
            assignments: [],
            vehicleRegistration: "",
            uid: nanoid()
        });

        const report: DutyManagerReport = {
            ...reportCopy,
            payload: JSON.stringify(payload)
        };
        dispatch({
            type: DUTY_MANAGER_REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: report
        });
    };
};

export const setVehicle = (args: VehicleDetails) => {
    return async (dispatch: Dispatch<DutyManagerReportDispatchTypes>, state: () => RootStore) => {
        const reportCopy = state().dutyManagerReport.data;
        if (!reportCopy) return;

        const payload: DutyManagerReportPayload = JSON.parse(reportCopy.payload);

        const index = payload.vehicles.findIndex((el: VehicleDetails) => el.uid === args.uid);
        if (index < 0) return;

        payload.vehicles[index] = args;

        //Set report.
        const report: DutyManagerReport = {
            ...reportCopy,
            payload: JSON.stringify(payload)
        };

        dispatch({
            type: DUTY_MANAGER_REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: report
        });
    };
};

export const removeVehicleDetails = (args: RemoveDriverDetailsArgs) => {
    return async (dispatch: Dispatch<DutyManagerReportDispatchTypes>, state: () => RootStore) => {
        const reportCopy = state().dutyManagerReport.data;
        if (!reportCopy) return;

        const payload: DutyManagerReportPayload = JSON.parse(reportCopy.payload);

        const index = payload.vehicles.findIndex((el: VehicleDetails) => el.uid === args.uid);
        if (index < 0) return;

        payload.vehicles.splice(index, 1);

        const report: DutyManagerReport = {
            ...reportCopy,
            payload: JSON.stringify(payload)
        };

        dispatch({
            type: DUTY_MANAGER_REPORT_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: report
        });
    };
};

// Service calls
export const saveDutyManagerReportToService = () => {
    return async (dispatch: Dispatch<DutyManagerReportDispatchTypes>, state: () => RootStore) => {
        const report = state().dutyManagerReport.data;
        if (!report) return false;

        try {
            return await postDataToServiceWithRedux(
                DUTY_MANAGER_REPORT_STORE_STATE,
                dispatch,
                () => GrsApiModel.getCalendarApi().saveDutyManagerReport(report),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: DUTY_MANAGER_REPORT_STORE_STATE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};

export const getDutyManagerReportByReportId = (id: number, entryId: number) => {
    return async (dispatch: Dispatch<DutyManagerReportDispatchTypes>) => {
        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await store.dispatch(fetchCalendarEntry(entryId));
            return await postDataToServiceWithRedux(
                DUTY_MANAGER_REPORT_STORE_STATE,
                dispatch,
                () => GrsApiModel.getCalendarApi().getDutyManagerReportById(id),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: DUTY_MANAGER_REPORT_STORE_STATE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};

export const deleteDutyManagerReportById = (id: number) => {
    return async (dispatch: Dispatch<DutyManagerReportDispatchTypes>) => {
        try {
            return await postDataToServiceWithRedux(
                DUTY_MANAGER_REPORT_STORE_STATE,
                dispatch,
                () => GrsApiModel.getCalendarApi().deleteDutyManagerReport(id),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: DUTY_MANAGER_REPORT_STORE_STATE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};
