import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {StaffBlockTemplate} from "../../../api/grs";

export const GLOBAL_STAFF_BLOCK_STORE = createStoreState("global_staff_block");

interface GlobalStaffBlockLoading extends StoreServiceData<StaffBlockTemplate> {
    type: typeof GLOBAL_STAFF_BLOCK_STORE.LOADING;
}
interface GlobalStaffBlockError extends StoreServiceData<StaffBlockTemplate> {
    type: typeof GLOBAL_STAFF_BLOCK_STORE.ERROR;
}
interface GlobalStaffBlockSuccess extends StoreServiceData<StaffBlockTemplate> {
    type: typeof GLOBAL_STAFF_BLOCK_STORE.SUCCESS;
}

export type GlobalStaffBlockDispatchTypes =
    | GlobalStaffBlockLoading
    | GlobalStaffBlockError
    | GlobalStaffBlockSuccess;
