import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useUserData} from "../../../Hooks/useUserData";
import {setNavigationItems} from "../../../../store/navigation/actions/NavigationActions";
import {useQuery} from "../../../Hooks/useQuery";
import {AvailableShiftsRequestUi} from "../../../../api/grs";
import {listAvailableShiftEntries} from "../../../../store/availableShiftsEntryList/actions/AvailableShiftsEntryListActions";
import {RootStore} from "../../../../store/Store";
import AvailableShiftsCalendarFilters from "./Components/AvailableShiftsCalendarFilters";
import FilterContainer from "../../../Filters/FilterContainer";
import {WithServiceState} from "store-fetch-wrappers/dist";
import AvailableShiftsCalendarView from "./Components/AvailableShiftsCalendarView";
import {useXeroRoutes} from "../../Xero/Hooks/useXeroRoutes";
import {StaffAccessLevel, UserData} from "../../../../api/staff";
import {NavigationItem} from "../../../../store/navigation/actions/NavigationActionTypes";
import {CalendarMonthView} from "../../AdminCalendar/Helpers/calendarUtils";
import {getPathToGlobalStaffCalendar} from "../../AvailableShiftsList/AvailableShiftsList";
import {routeNames} from "../../../Navigation/routeNames";
import useMcConfig from "../../../Hooks/useMcConfig";
import {getUserAccessLevel} from "../../../../utils/userDataUtils";

const AvailableShiftsWrapper = WithServiceState(AvailableShiftsCalendarView);

const AvailableShiftsCalendar = () => {
    const dispatch = useDispatch();
    const user = useUserData();
    const query = useQuery();
    const availableShiftsStore = useSelector((state: RootStore) => state.availableShiftsEntryList);
    const {getSystemAdminRoutes} = useXeroRoutes();
    const {config} = useMcConfig();

    useEffect(() => {
        if (user.username.length === 0) return;

        dispatch(setNavigationItems(getNavigationItems(user)));
    }, [user]);

    function getNavigationItems(targetUser: UserData): NavigationItem[] {
        const accessLevel = getUserAccessLevel(targetUser, config);
        switch (accessLevel) {
            case StaffAccessLevel.SystemAdministrator:
                return getSystemAdminRoutes();
            case StaffAccessLevel.DutyManager:
            case StaffAccessLevel.Staff:
                return [
                    {
                        name: CalendarMonthView.Staff,
                        path: getPathToGlobalStaffCalendar(CalendarMonthView.Staff, query)
                    },
                    {
                        name: routeNames.staffReport.name,
                        path: routeNames.staffReport.path
                    },
                    {
                        name: routeNames.pendingNotifications.name,
                        path: routeNames.pendingNotifications.path
                    },
                    {
                        name: routeNames.availableShiftsList.name,
                        path: routeNames.availableShiftsList.path
                    },
                    {
                        name: routeNames.availableShifts.name,
                        path: routeNames.availableShifts.path
                    }
                ];
        }
    }

    const onFiltersChanged = (request: AvailableShiftsRequestUi) => {
        dispatch(listAvailableShiftEntries(request));
    };
    return (
        <React.Fragment>
            <div className="page-container">
                <FilterContainer closed={false}>
                    <AvailableShiftsCalendarFilters onRequestChanged={onFiltersChanged} />
                </FilterContainer>

                <AvailableShiftsWrapper
                    placeholderBlockCount={1}
                    showLoadingText={true}
                    loaderWheelType={"three-ring"}
                    loaderType={"overlay"}
                    {...availableShiftsStore}
                />
            </div>
        </React.Fragment>
    );
};

export default AvailableShiftsCalendar;
