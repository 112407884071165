import React, {useEffect, useState} from "react";
import {
    DDProps,
    generateDailyTimeOptions,
    getSelectedDropdownOption,
    TimeDropdownProps
} from "../Helpers/dropdownUtils";
import Select, {SingleValue} from "react-select";

/** Time dropdown for Calendar entry */
const TimeDropdown = (props: TimeDropdownProps) => {
    const [timeOptions, setTimeOptions] = useState<DDProps[]>([]);
    const [selectedTimeOption, setStartTimeOption] = useState<DDProps | undefined>();

    /** Upon mounting the component, and when props entry has changed, generate the time options the calendar entry */
    useEffect(() => {
        const startOptions = generateDailyTimeOptions(
            props.entry,
            props.optionsPerHour,
            props.daysToGenerate * 24
        );

        setTimeOptions(startOptions);

        if (!props.id) {
            setStartTimeOption(undefined);
            props.changeOption(undefined);
            return;
        }
        const option = getSelectedDropdownOption(props.id, startOptions);
        setStartTimeOption(option);
        props.changeOption(option.value);
    }, [props.entry]);

    /** When the time option is changed, update the parent with the callback.
     * Component can be cleared, so prop coming in needs to be null checked */
    const handleTimeOptionChanged = (p: SingleValue<DDProps>) => {
        if (!p) {
            setStartTimeOption(undefined);
            props.changeOption(undefined);
            return;
        }
        setStartTimeOption(p);

        props.changeOption(p.value);
    };

    return (
        <React.Fragment>
            <Select
                className="search-bar"
                classNamePrefix="select-options"
                options={timeOptions}
                onChange={handleTimeOptionChanged}
                value={selectedTimeOption}
                noOptionsMessage={() => "No Time Options Found"}
                placeholder="Select Time..."
                isDisabled={props.disabled}
                isClearable={props.clearable}
                isSearchable={props.isSearchable}
                maxMenuHeight={150}
            />
        </React.Fragment>
    );
};

export default TimeDropdown;
