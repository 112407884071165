import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {StaffReportResponse} from "../../../api/grs";
import {STAFF_REPORT_STORE} from "../actions/StaffReportActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";

/** Default state of the store. */
const defaultState = createDefaultStoreState<StaffReportResponse>(
    generateBlankStaffReportResponse()
);

/** Infer type of the reducer for code completion and quality */
const staffReportReducer = (
    state: StoreServiceData<StaffReportResponse> = defaultState,
    action: ServiceActionTypes<StaffReportResponse>
): StoreServiceData<StaffReportResponse> =>
    createReducer(state, action, STAFF_REPORT_STORE, () => showErrorToast(action.error));

export default staffReportReducer;

export function generateBlankStaffReportResponse(): StaffReportResponse {
    return {
        endDateExclusive: 0,
        entries: [],
        staffId: "",
        startDateExclusive: 0
    };
}
