import {createRef, useEffect, useState} from "react";
import moment, {Moment} from "moment";
import useWindowSize from "../../../components/Hooks/useWindowResize";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../store/Store";
import {
    nullifyHorizontalCalendarStore,
    setHorizontalCalendarState
} from "../Store/actions/HorizontalCalendarActions";
import {
    HorizontalCalendarProps,
    HorizontalCalendarState
} from "../Store/actions/HorizontalCalendarActionTypes";
import {formatUnixToMMMM, formatUnixToMMMMYYYY, formatUnixToYYYY} from "../../../utils/momentUtils";
import {useHistory} from "react-router-dom";

export function useHorizontalCalendar(props: HorizontalCalendarProps) {
    const {data} = useSelector((state: RootStore) => state.horizontalCalendar);
    const dispatch = useDispatch();
    const {width} = useWindowSize();
    const scrollViewRef = createRef<HTMLDivElement>();
    const [visibleDate, setVisibleDate] = useState<string>("");
    const history = useHistory();

    useEffect(() => {
        const now = props.currentDate.clone().startOf("day");
        const dates = getDates(now);
        const index = dates.findIndex((item) => item.unix() === now.unix());
        dispatch(
            setHorizontalCalendarState({
                currentDateIndex: index,
                dates,
                dayWidths: new Map<number, number>(),
                lastSelectedDate: dates[index]
            })
        );

        props.onSelectDate(dates[index]);

        updateUrlQuery(dates[index]);

        return function () {
            dispatch(nullifyHorizontalCalendarStore());
        };
    }, []);

    function getDates(targetDate: Moment): Array<Moment> {
        const startOfCurrentMonth = targetDate.clone().startOf("month");

        // Go `showDaysBeforeCurrent` ago before today or custom `currentDate`
        const startDay = moment(startOfCurrentMonth).subtract(1, "days");
        // Number of days in total
        const totalDaysCount = startOfCurrentMonth.daysInMonth();

        // And return an array of `totalDaysCount` dates
        return [...Array(totalDaysCount)].map(() => startDay.add(1, "day").clone());
    }

    function resetToCurrentDate() {
        if (!data) return;
        const now = moment().startOf("day");
        const dates = getDates(now);
        const index = dates.findIndex((item) => item.unix() === now.unix());

        dispatch(
            setHorizontalCalendarState({
                ...data,
                currentDateIndex: index,
                dates,
                lastSelectedDate: dates[index]
            })
        );

        scrollToCurrentDay(index, "auto");
        setVisibleDate(formatUnixToMMMMYYYY(now.unix()));
        props.onSelectDate(dates[index]);
    }

    function getVisibleDates(scrollX: number): Array<Moment> {
        if (!data) return [];
        const {dates, dayWidths} = data;

        if (!width) return [];

        let datePositionX = 0;
        let firstVisibleDateIndex: number | undefined = undefined;
        let lastVisibleDateIndex: number | undefined = undefined;

        const widthObject = Object.fromEntries(dayWidths.entries());

        // Iterate through `dayWidths` to  $FlowFixMe
        Object.values(widthObject).some((w: number, index: number) => {
            if (
                firstVisibleDateIndex === undefined && // not set yet
                datePositionX >= scrollX // first date visible
            ) {
                firstVisibleDateIndex = index > 0 ? index - 1 : index;
            }

            if (
                lastVisibleDateIndex === undefined && // not set yet
                datePositionX >= scrollX + width // first date not visible behind the right edge
            ) {
                lastVisibleDateIndex = index;
            }

            // Increment date position by its width for the next iteration
            datePositionX += w;

            // return true when both first and last visible days found to break out of loop
            return !!(firstVisibleDateIndex && lastVisibleDateIndex);
        });

        // Return a subset of visible dates only
        return dates.slice(firstVisibleDateIndex, lastVisibleDateIndex);
    }

    function getVisibleMonthAndYear(scrollX: number): VisibleMonthYears | undefined {
        if (!data) return;
        const allDatesRendered = getAllDatesRendered(data);

        if (!allDatesRendered) {
            return;
        }

        const visibleDates = getVisibleDates(scrollX);

        if (!visibleDates) {
            return;
        }

        const visibleMonths: string[] = [];
        const visibleYears: string[] = [];

        visibleDates.forEach((date: Moment) => {
            const month = formatUnixToMMMM(date.unix());
            const year = formatUnixToYYYY(date.unix());
            if (!visibleMonths.includes(month)) {
                visibleMonths.push(month);
            }
            if (!visibleYears.includes(year)) {
                visibleYears.push(year);
            }
        });

        return {
            visibleMonths,
            visibleYears
        };
    }

    function onSelectDay(index: number) {
        if (!data) return;
        const {dates} = data;
        const {onSelectDate} = props;

        dispatch(
            setHorizontalCalendarState({
                ...data,
                currentDateIndex: index,
                lastSelectedDate: dates[index]
            })
        );
        scrollToCurrentDay(index);

        // We don't want spam the request above so we allow the animations to happen, just not the request
        if (index === data.currentDateIndex) return;
        onSelectDate(dates[index]);

        updateUrlQuery(dates[index]);
    }

    function scrollToCurrentDay(currentDateIndex: number, behavior: ScrollBehavior = "smooth") {
        if (!data) return;
        if (!width) return;
        if (!scrollViewRef.current) return;
        const {dayWidths} = data;

        // Make sure we have all required values
        if (!currentDateIndex === undefined || currentDateIndex === null) {
            return;
        }

        // Put all day width values into a simple array $FlowFixMe
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const dayWidthsArray: Array<number> = Array.from(dayWidths.values());
        // Total width all days take
        const allDaysWidth = dayWidthsArray.reduce((total, w) => w + total, 0);
        // Current day button width
        const currentDayWidth = dayWidthsArray[currentDateIndex];
        // Minimal possible X position value to prevent scrolling before the first day
        const minX = 0;
        // Maximum possible X position value to prevent scrolling after the last day
        const maxX = allDaysWidth > width ? allDaysWidth - width : 0; // no scrolling if there's nowhere to scroll

        let scrollToX;

        scrollToX =
            dayWidthsArray
                // get all days before the target one
                .slice(0, currentDateIndex + 1)
                // and calculate the total width
                .reduce((total, w) => w + total, 0) -
            // Subtract half of the screen width so the target day is centered
            width / 2 -
            currentDayWidth / 2;

        // Do not scroll over the left edge
        if (scrollToX < minX) {
            scrollToX = 0;
        }
        // Do not scroll over the right edge
        else if (scrollToX > maxX) {
            scrollToX = maxX;
        }

        scrollViewRef.current.scrollTo({left: scrollToX, behavior});

        const visibleDates = getUpdatedVisibleMonthAndYear(scrollToX);
        if (!visibleDates) return;
        setVisibleDate(visibleDates);
    }

    function onRenderDay(index: number, w: number) {
        if (!data) return;

        const updatedState: HorizontalCalendarState = {
            ...data,
            dayWidths: data.dayWidths.set(index, w)
        };

        dispatch(setHorizontalCalendarState(updatedState));

        const allDatesRendered = getAllDatesRendered(updatedState);
        if (!allDatesRendered) return;
        if (!updatedState.currentDateIndex) return;

        scrollToCurrentDay(updatedState.currentDateIndex, "auto");
    }

    function getAllDatesRendered(updatedState: HorizontalCalendarState): boolean {
        const totalDays = props.currentDate.daysInMonth();
        const dayWidthLength = Array.from(updatedState.dayWidths.keys());
        return dayWidthLength.length >= totalDays;
    }

    // Format as a string the month(s) and the year(s) of the dates currently visible
    function getUpdatedVisibleMonthAndYear(scrollX: number): string | undefined {
        const visibleDates = getVisibleMonthAndYear(scrollX);
        if (!data) return;
        if (!visibleDates) return;
        const {visibleMonths, visibleYears} = visibleDates;
        const {dates} = data;

        // No `visibleMonths` or `visibleYears` yet
        if (!visibleMonths || !visibleYears) {
            // Return the month and the year of the very first date
            if (dates) {
                const firstDate = dates[0];
                return `${formatUnixToMMMM(firstDate.unix())}, ${formatUnixToYYYY(
                    firstDate.unix()
                )}`;
            }
            return undefined;
        }

        // One or two months withing the same year
        if (visibleYears.length === 1) {
            return `${visibleMonths.join(" – ")}  ${visibleYears[0]}`;
        }

        // Two months within different years
        return visibleMonths.map((month, index) => `${month}, ${visibleYears[index]}`).join(" – ");
    }

    function goToNextMonth() {
        if (!data) return;
        const {dates} = data;
        const first = firstOrNull(dates);
        if (!first) return;
        const nextMonth = first.clone().add("1", "month");

        updateNewDateState(nextMonth, data.lastSelectedDate);
    }

    function goToPreviousMonth() {
        if (!data) return;
        const {dates} = data;
        const first = firstOrNull(dates);
        if (!first) return;

        const nextMonth = first.clone().subtract("1", "month");
        updateNewDateState(nextMonth, data.lastSelectedDate);
    }

    function updateNewDateState(newMonth: Moment, lastSelected: Moment | undefined) {
        if (!data) return;
        const newDates = getDates(newMonth);
        const index = getUpdatedCurrentIndex(newDates, lastSelected);

        dispatch(
            setHorizontalCalendarState({
                ...data,
                dates: newDates,
                currentDateIndex: index
            })
        );
        setVisibleDate(formatUnixToMMMMYYYY(newMonth.unix()));
    }

    function getUpdatedCurrentIndex(dates: Moment[], lastSelected: Moment | undefined) {
        const index = dates.findIndex((d) => d.unix() === lastSelected?.unix());

        return index > -1 ? index : undefined;
    }

    function firstOrNull<T>(arr: T[]): T | undefined {
        return arr[0];
    }

    function updateUrlQuery(date: Moment) {
        history.push({
            search: `?day=${date.unix()}&isGlobalCalendar=true`
        });
    }

    return {
        state: data,
        onRenderDay,
        onSelectDay,
        visibleDate,
        scrollViewRef,
        resetToCurrentDate,
        goToPreviousMonth,
        goToNextMonth
    };
}

interface VisibleMonthYears {
    visibleMonths: string[];
    visibleYears: string[];
}
