import React, {useEffect} from "react";
import {routeNames} from "../../Navigation/routeNames";
import {useDispatch, useSelector} from "react-redux";
import {useQuery} from "../../Hooks/useQuery";
import {RootStore} from "../../../store/Store";
import {usePageUrl} from "../../Hooks/usePageUrl";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {getAttendanceOverviewPath} from "../AttendanceOverview/Helpers/attendanceOverviewHelpers";
import {fetchCalendarEntry} from "../../../store/calendarEntry/actions/CalendarEntryActions";
import {WithServiceState} from "store-fetch-wrappers/dist";
import AttendanceRegisterTable from "./Components/AttendanceRegisterTable";
import {getCalendarPathForCalendarChildRoutes, getVenuesListPath} from "../VenueList/VenueList";
import {useCalendarPageDetails} from "../../Hooks/useCalendarPageDetails";
import {getEventReportPath} from "../Reports/EventReport/Helpers/eventReportHelpers";

const AttendanceRegisterServiceWrapper = WithServiceState(AttendanceRegisterTable);

const AttendanceRegister = () => {
    const dispatch = useDispatch();
    const query = useQuery();
    const calendarEntryStore = useSelector((state: RootStore) => state.calendarEntry);
    const {fullPath} = usePageUrl();
    const {calendarName} = useCalendarPageDetails();

    useEffect(() => {
        const entryId = query.get("entryId");
        if (!entryId) return;

        dispatch(fetchCalendarEntry(+entryId));

        setupRoutes();
    }, []);

    /** Sets up routes for the venues page. */
    const setupRoutes = () => {
        const calendarId = query.get("calendarId");
        if (!calendarId) return;

        const calendarPath = getCalendarPathForCalendarChildRoutes(calendarName, +calendarId);
        const navigationItems: NavigationItem[] = [
            {
                name: routeNames.groupRosteringSystem.name,
                path: routeNames.groupRosteringSystem.path
            },
            {
                name: calendarName,
                path: calendarPath
            },
            {
                name: routeNames.eventReport.name,
                path: getEventReportPath(calendarName, +calendarId)
            },
            {
                name: routeNames.venueList.name,
                path: getVenuesListPath(calendarName, +calendarId)
            },
            {
                name: routeNames.attendanceOverview.name,
                path: getAttendanceOverviewPath(calendarName, +calendarId)
            },
            {
                name: routeNames.attendanceRegister.name,
                path: fullPath
            }
        ];

        dispatch(setNavigationItems(navigationItems));
    };
    return (
        <React.Fragment>
            <div className="page-container mt-4">
                <AttendanceRegisterServiceWrapper
                    showLoadingText={true}
                    loaderType={"overlay"}
                    loaderWheelType={"three-ring"}
                    placeholderBlockCount={1}
                    {...calendarEntryStore}
                />
            </div>
        </React.Fragment>
    );
};

export default AttendanceRegister;
