import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {
    CalendarEventItem,
    FULL_CALENDAR_LIST_ITEM_STORE
} from "../actions/FullCalendarItemListActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";

/** Default state of the store. */
const defaultState = createDefaultStoreState<CalendarEventItem[]>([]);

/** Infer type of the reducer for code completion and quality */
const fullCalendarListItemReducer = (
    state: StoreServiceData<CalendarEventItem[]> = defaultState,
    action: ServiceActionTypes<CalendarEventItem[]>
): StoreServiceData<CalendarEventItem[]> =>
    createReducer(state, action, FULL_CALENDAR_LIST_ITEM_STORE, () => showErrorToast(action.error));

export default fullCalendarListItemReducer;
