import React from "react";
import {CalendarEventItem} from "../../../../../../store/fullCalendarItemList/actions/FullCalendarItemListActionTypes";
import {ShiftBlock} from "../../../../../../store/mobileCalendar/actions/MobileCalendarActionTypes";
import {DateRange} from "../../../../../../utils/filterUtils";
import MobileEventListItem from "./MobileEventListItem";
import NoItemsMessage from "../../../../../Table/NoItemsMessage";

function MobileEventListContainer<T>({
    shiftItems,
    noItemsMessage,
    isAvailableShiftsItem,
    dateRange
}: Props<T>) {
    return (
        <React.Fragment>
            <div className="mobile-calendar-events-wrapper">
                {Object.keys(shiftItems.converted).length > 0 ? (
                    Object.keys(shiftItems.converted).map((key, index) => {
                        const calendarEventItem = shiftItems.converted[key];
                        const rawEntry = shiftItems.raw[+key];

                        return (
                            <MobileEventListItem
                                key={index}
                                //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                //@ts-ignore
                                rawEntry={rawEntry}
                                calendarEventItem={calendarEventItem}
                                isAvailableShiftsItem={isAvailableShiftsItem}
                                dateRange={dateRange}
                            />
                        );
                    })
                ) : (
                    <NoItemsMessage message={noItemsMessage} />
                )}
            </div>
        </React.Fragment>
    );
}

export default MobileEventListContainer;

interface Props<T> {
    shiftItems: ShiftBlock<T, CalendarEventItem>;
    noItemsMessage: string;
    isAvailableShiftsItem?: boolean;
    dateRange: DateRange;
}
