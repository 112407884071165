import React from "react";
import {DutyManagerReport} from "../../../../../../../api/grs";
import {PulseTable} from "pulse_table";
import NoItemsMessage from "../../../../../../Table/NoItemsMessage";
import {formatUnixToDDMMYYYY} from "../../../../../../../utils/momentUtils";

const ReportFormDetails = (props: DutyManagerReport) => (
    <React.Fragment>
        <PulseTable
            items={toTableRow(props)}
            headers={{
                createdBy: "Created by",
                dateCreated: "Date created",
                venue: "Associated venue"
            }}
            noItemsSection={
                <NoItemsMessage message={"There are no details assigned to this report."} />
            }
        />
    </React.Fragment>
);

export default ReportFormDetails;

interface TableRow {
    createdBy: string;
    dateCreated: string;
    venue: string;
}

function toTableRow(report: DutyManagerReport): TableRow[] {
    return [
        {
            createdBy: report.dutyManagerName,
            dateCreated: formatUnixToDDMMYYYY(report.date),
            venue: report.venueName
        }
    ];
}
