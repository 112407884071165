import React from "react";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../../../../Button/MCButton";
import {Modal, useModal} from "pulse-modal";
import RepeatEntryForm from "./Components/RepeatEntryForm";

function RepeatEntryButton() {
    const {toggle, isShown} = useModal();
    return (
        <React.Fragment>
            <MCButton
                size={ButtonSize.Large}
                innerValue={"Repeat Entry"}
                onClick={toggle}
                colour={ButtonColourOptions.DarkBlue}
                roundedCorner
                className={"mr-3"}
            />
            <Modal
                isShown={isShown}
                onClose={toggle}
                title={"Repeat Entry"}
                modalSize={"md"}
                bodyChildren={<RepeatEntryForm onClose={toggle} />}
            />
        </React.Fragment>
    );
}

export default RepeatEntryButton;
