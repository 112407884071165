import React from "react";
import {Venue} from "../../../../api/grs";
import {PulseTable} from "pulse_table";
import {getUiFriendlyText} from "../../../../utils/textUtils";
import {toArray} from "../../../../utils/sortingUtils";
import TableItemAction from "../../../Table/TableItemAction";
import DeleteTableItemAction from "../../../Table/DeleteTableItemAction";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../store/Store";
import {deleteVenueById} from "../../../../store/venue/actions/VenueActions";
import {getVenuesForCalendarId} from "../../../../store/venueList/actions/VenueListActions";
import {useHistory} from "react-router-dom";
import {useCalendarPageDetails} from "../../../Hooks/useCalendarPageDetails";
import {getEditVenuesPath} from "../../Venue/EditVenue";
import {IconType} from "../../../Icon/Icon";
import TableActionContainer from "../../../Table/TableActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import {getAddVenuePath} from "../../Venue/AddVenue";

const VenueListTable = (props: Venue[]) => {
    const dispatch = useDispatch();
    const {loading} = useSelector((state: RootStore) => state.venue);
    const history = useHistory();
    const {calendarId, calendarName} = useCalendarPageDetails();

    const editVenue = (item: TableRow) => {
        if (!calendarId) return;
        const path = getEditVenuesPath(calendarName, +calendarId, item.action.id);
        history.push(path);
    };

    return (
        <React.Fragment>
            <TableActionContainer>
                <MCButton
                    size={ButtonSize.Large}
                    innerValue={"Add Venue"}
                    onClick={() => {
                        if (!calendarId) return;
                        const path = getAddVenuePath(calendarName, +calendarId);
                        history.push(path);
                    }}
                    roundedCorner
                    colour={ButtonColourOptions.Yellow}
                />
            </TableActionContainer>
            <PulseTable
                items={toTableRow(toArray(props))}
                headers={{
                    name: "Name",
                    state: "State",
                    address: "Address",
                    postcode: "Postcode",
                    action: "Actions"
                }}
                customRenderers={{
                    action: (item: TableRow) => (
                        <React.Fragment>
                            <div className="row ml-0 mr-0">
                                <TableItemAction
                                    tooltipText={`Edit ${item.name}`}
                                    onClick={() => editVenue(item)}
                                    icon={IconType.Edit}
                                />
                                <DeleteTableItemAction
                                    itemName={item.name}
                                    loading={loading}
                                    modalTitle={"Delete Venue"}
                                    modalMessage={"Are you sure you want to delete this venue?"}
                                    onConfirm={async () => {
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        const success: boolean = await dispatch(
                                            deleteVenueById(item.action.id)
                                        );

                                        if (!success) return false;

                                        dispatch(getVenuesForCalendarId(item.action.calendarId));
                                        return true;
                                    }}
                                />
                            </div>
                        </React.Fragment>
                    )
                }}
                noItemsSection={
                    <div className="row ml-0 mr-0 fadeIn">
                        <div className="col">
                            <h5 className="text-center p-3">
                                {/* eslint-disable-next-line react/no-unescaped-entities */}
                                There are no venues for this calendar. Click "Add Venues" to create
                                a venue
                            </h5>
                        </div>
                    </div>
                }
            />
        </React.Fragment>
    );
};

export default VenueListTable;

interface TableRow {
    name: string;
    state: string;
    address: string;
    postcode: string;
    action: Venue;
}

function toTableRow(venueList: Venue[]): TableRow[] {
    return venueList.map((item) => {
        return {
            name: item.name,
            state: getUiFriendlyText(item.state),
            address: item.address,
            postcode: item.postcode,
            action: item
        };
    });
}
