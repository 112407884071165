import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const REPEAT_ASSIGNMENT_STORE = createStoreState("repeat_assignment");

interface RepeatAssignmentLoading extends StoreServiceData<string> {
    type: typeof REPEAT_ASSIGNMENT_STORE.LOADING;
}
interface RepeatAssignmentError extends StoreServiceData<string> {
    type: typeof REPEAT_ASSIGNMENT_STORE.ERROR;
}
interface RepeatAssignmentSuccess extends StoreServiceData<string> {
    type: typeof REPEAT_ASSIGNMENT_STORE.SUCCESS;
}

export type RepeatAssignmentDispatchTypes =
    | RepeatAssignmentLoading
    | RepeatAssignmentError
    | RepeatAssignmentSuccess;
