import React, {useEffect, useState} from "react";
import {StaffBlock, StaffBlockSection} from "../../../../../../api/grs";
import {nanoid} from "nanoid";
import StaffSection, {
    GlobalStaffBlockUpdateProps,
    ItemChangedAction,
    StaffBlockUpdateType
} from "./StaffSection";

const StaffBlockManagement = (props: GlobalStaffBlockUpdateProps<StaffBlock>) => {
    const [tabsProps, setTabsProps] = useState<TabsProps<StaffBlockSection>>();
    const [markupTabs, setMarkupTabs] = useState<JSX.Element>(<div />);

    useEffect(() => {
        const {sections} = props.item;
        //Set the current tab to be the first (in this case, it will be undefined)
        if (!tabsProps) {
            setTabsProps({tabs: sections, currentTab: sections[0]});
            return;
        }

        //Set the current tab to be the first (in this case, it will be undefined)
        if (sections.length === 0) {
            setTabsProps({tabs: sections, currentTab: sections[0]});
            return;
        }

        //If there is no current tab. Default to undefined || 0
        if (!tabsProps.currentTab) {
            setTabsProps({tabs: sections, currentTab: sections[0]});
            return;
        }

        //We get the current tab this way as it finds the most upto date version of itself from the data in the store.
        //Otherwise, it does't update properly as its new state doesn't match the current state.
        const index = sections.findIndex(
            (el: StaffBlockSection) => el.id === tabsProps.currentTab.id
        );

        //Means section has been deleted from the tabs
        if (index < 0) {
            setTabsProps({tabs: sections, currentTab: sections[0]});
            return;
        }

        const currentTab = sections[index];
        setTabsProps({tabs: sections, currentTab: currentTab});
    }, [props]);

    /** Changed the active tab */
    const handleSelectTab = (tab: StaffBlockSection) => {
        if (!tabsProps) return;

        setTabsProps({
            ...tabsProps,
            currentTab: tab
        });
    };

    /** Creates tabs upon tab props changing */
    useEffect(() => {
        createTabs();
    }, [tabsProps]);

    /** Adds a new tab to the tabs*/
    const addTab = () => {
        if (!tabsProps) return;

        const newSection: StaffBlockSection = {
            id: nanoid(),
            name: `New Section`,
            groupings: []
        };
        setTabsProps({
            ...tabsProps,
            currentTab: newSection
        });
        const staffBlock: StaffBlock = {
            ...props.item,
            sections: [...props.item.sections, newSection]
        };
        props.onItemChanged({newItem: staffBlock, updateType: StaffBlockUpdateType.Update});
    };

    /** Creates all the tabs */
    const createTabs = () => {
        if (!tabsProps) return <div />;

        const {tabs, currentTab} = tabsProps;

        const allTabs = tabs.map((el: StaffBlockSection) => {
            return (
                <li key={el.id}>
                    <button
                        className={currentTab.id === el.id ? "pulse-tab active" : "pulse-tab"}
                        onClick={() => handleSelectTab(el)}
                    >
                        {el.name}
                    </button>
                </li>
            );
        });
        const markup = (
            <ul className="nav pulse-nav-tabs">
                {allTabs}
                <li>
                    <button className="pulse-tab" onClick={addTab}>
                        +
                    </button>
                </li>
            </ul>
        );

        setMarkupTabs(markup);
    };

    const onStaffBlockSectionChanged = (action: ItemChangedAction<StaffBlockSection>) => {
        if (!tabsProps) return;
        const newSection = action.newItem;
        const staffBlock = props.item;
        switch (action.updateType) {
            case StaffBlockUpdateType.Delete:
                props.onItemChanged({
                    newItem: deleteSectionForStaffBlock(newSection, staffBlock),
                    updateType: StaffBlockUpdateType.Update
                });
                break;
            case StaffBlockUpdateType.Update:
                props.onItemChanged({
                    newItem: updateSectionForStaffBlock(newSection, staffBlock),
                    updateType: StaffBlockUpdateType.Update
                });
                break;
        }
    };

    return (
        <React.Fragment>
            {markupTabs}
            <div className="pulse-tab-content">
                {tabsProps && (
                    <div>
                        {tabsProps.tabs.length > 0 ? (
                            <StaffSection
                                item={tabsProps.currentTab}
                                onItemChanged={onStaffBlockSectionChanged}
                            />
                        ) : (
                            <p className="text-center pt-lg-5">
                                No current staff assignments exist. Click the
                                <b> Plus </b> button to start managing staff
                            </p>
                        )}
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

export default StaffBlockManagement;

function updateSectionForStaffBlock(
    section: StaffBlockSection,
    staffBlock: StaffBlock
): StaffBlock {
    const index = staffBlock.sections.findIndex((item) => item.id === section.id);
    staffBlock.sections[index] = section;
    return staffBlock;
}

function deleteSectionForStaffBlock(
    section: StaffBlockSection,
    staffBlock: StaffBlock
): StaffBlock {
    const index = staffBlock.sections.findIndex((item) => item.id === section.id);
    staffBlock.sections.splice(index, 1);
    return staffBlock;
}

export interface TabsProps<T> {
    tabs: T[];
    currentTab: T;
}
