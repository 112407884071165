import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {setNavigationItems} from "../../../../store/navigation/actions/NavigationActions";
import {
    getStaffBlockListFromService,
    nullifyGlobalStaffBlockListStore
} from "../../../../store/globalStaffBlockList/actions/GlobalStaffBlockListActions";
import {RootStore} from "../../../../store/Store";
import GlobalStaffBlockList from "./Components/GlobalStaffBlockList";
import {WithServiceState} from "store-fetch-wrappers/dist";
import {useXeroRoutes} from "../../Xero/Hooks/useXeroRoutes";

const ServiceWrapper = WithServiceState(GlobalStaffBlockList);

const ManageStaffBlocks = () => {
    const dispatch = useDispatch();

    const globalStaffBlockListStore = useSelector((state: RootStore) => state.globalStaffBlockList);
    const {getSystemAdminRoutes} = useXeroRoutes();

    useEffect(() => {
        window.scrollTo({top: 0});
        dispatch(setNavigationItems(getSystemAdminRoutes()));

        dispatch(getStaffBlockListFromService());

        return function () {
            dispatch(nullifyGlobalStaffBlockListStore());
        };
    }, []);
    return (
        <React.Fragment>
            <div className="page-container mt-4">
                <ServiceWrapper
                    showLoadingText={true}
                    loaderWheelType={"three-ring"}
                    loaderType={"overlay"}
                    placeholderBlockCount={1}
                    {...globalStaffBlockListStore}
                />
            </div>
        </React.Fragment>
    );
};

export default ManageStaffBlocks;
