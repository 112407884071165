import {GLOBAL_STAFF_BLOCK_LIST_STORE} from "../actions/GlobalStaffBlockListActionTypes";
import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {StaffBlockTemplateListEntry} from "../../../api/grs";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<StaffBlockTemplateListEntry[]>([]);

const globalStaffBlockListReducer = (
    state: StoreServiceData<StaffBlockTemplateListEntry[]> = defaultState,
    action: ServiceActionTypes<StaffBlockTemplateListEntry[]>
) =>
    createReducer<StaffBlockTemplateListEntry[]>(state, action, GLOBAL_STAFF_BLOCK_LIST_STORE, () =>
        showErrorToast(action.error)
    );

export default globalStaffBlockListReducer;
