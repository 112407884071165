import React from "react";
import {ExpandingTableData, TableProps} from "./Helpers/TableHelpers";
import PulseExpandingTable from "./PulseExpandingTable";
import ServerPagination from "../../components/Pagination/ServerPagination";
import {pagedRequestConfig} from "../../components/Filters/helpers/filterHelpers";

function PulseExpandingTableWithServerPagination<T extends ExpandingTableData>({
    resultsPerPage,
    totalResults,
    onPageChanged,
    initialPageNumber,
    ...rest
}: PulseExpandingTableWithServerPaginationProps<T>) {
    return (
        <React.Fragment>
            <PulseExpandingTable {...rest} />
            {rest.items.length > 0 && (
                <ServerPagination
                    resultsPerPage={pagedRequestConfig.resultsPerPage}
                    onPageChanged={onPageChanged}
                    totalResults={totalResults}
                    initialPageNumber={initialPageNumber}
                />
            )}
        </React.Fragment>
    );
}
export default PulseExpandingTableWithServerPagination;

interface PulseExpandingTableWithServerPaginationProps<T extends ExpandingTableData>
    extends TableProps<T> {
    resultsPerPage: number;
    totalResults: number;
    onPageChanged: (newPageNum: number) => void;
    initialPageNumber?: number;
}
