import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {CalendarGroup} from "../../../api/grs";

export const CALENDAR_GROUP_LIST_STORE = createStoreState("calendar_group_list");

interface CalendarGroupListLoading extends StoreServiceData<CalendarGroup[]> {
    type: typeof CALENDAR_GROUP_LIST_STORE.LOADING;
}
interface CalendarGroupListError extends StoreServiceData<CalendarGroup[]> {
    type: typeof CALENDAR_GROUP_LIST_STORE.ERROR;
}
interface CalendarGroupListSuccess extends StoreServiceData<CalendarGroup[]> {
    type: typeof CALENDAR_GROUP_LIST_STORE.SUCCESS;
}

export type CalendarGroupListDispatchTypes =
    | CalendarGroupListLoading
    | CalendarGroupListError
    | CalendarGroupListSuccess;
