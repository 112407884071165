import React from "react";
import {CalendarEntry} from "../../../../../../api/grs";
import CalendarEntryHeader from "./CalendarEntryHeader";
import FormHeader from "../../../../../Form/FormHeader";
import CalendarEntryDescription from "./CalendarEntryDescription";
import CalendarEntryVenue from "./CalendarEntryVenue";
import CalendarEntryBillingDropdown from "./CalendarEntryBillingDropdown";

const CalendarEntryGeneralDetails = (props: CalendarEntry) => (
    <React.Fragment>
        <CalendarEntryHeader {...props} />
        <FormHeader headerName={"General Details"} />
        <CalendarEntryDescription {...props} />
        <CalendarEntryVenue {...props} />
        <CalendarEntryBillingDropdown {...props} />
    </React.Fragment>
);

export default CalendarEntryGeneralDetails;
