import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {SELECTED_WAGE_REPORT_STORE} from "../actions/SelectedWageReportActionTypes";
import {StaffWageReportEntry} from "../../../api/grs";
import {showErrorToast} from "../../../utils/toastUtils";

/** Default state of the store. */
const defaultState = createDefaultStoreState<StaffWageReportEntry>(null);

/** Infer type of the reducer for code completion and quality */
const selectedWageReportReducer = (
    state: StoreServiceData<StaffWageReportEntry> = defaultState,
    action: ServiceActionTypes<StaffWageReportEntry>
): StoreServiceData<StaffWageReportEntry> =>
    createReducer(state, action, SELECTED_WAGE_REPORT_STORE, () => showErrorToast(action.error));

export default selectedWageReportReducer;
