import {Dispatch} from "redux";
import {STAFF_WAGE_REPORT_STORE, StaffWageReportDispatchTypes} from "./StaffWageReportActionTypes";
import GrsApiModel from "../../apiModel/GrsApiModel";
import {
    StaffWageReportEntry,
    StaffWageReportRequest,
    StaffWageReportResponse
} from "../../../api/grs";
import {getDataFromServiceWithData} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";

/** Nukes store of stale data */
export const nullifyStaffWageReport = () => {
    return async (dispatch: Dispatch<StaffWageReportDispatchTypes>) => {
        dispatch({
            type: STAFF_WAGE_REPORT_STORE.SUCCESS,
            error: null,
            loading: false,
            data: {
                entries: []
            }
        });
    };
};

/** fetches staff wage report */
export const fetchStaffWageReport = (request: StaffWageReportRequest) => {
    return async (dispatch: Dispatch<StaffWageReportDispatchTypes>) => {
        try {
            const reports = await getDataFromServiceWithData(
                STAFF_WAGE_REPORT_STORE,
                dispatch,
                () => GrsApiModel.getReportsApi().getStaffWageReport(request),
                statusCodeCallback
            );

            dispatch({
                type: STAFF_WAGE_REPORT_STORE.SUCCESS,
                error: null,
                loading: false,
                data: processReportsIncoming(reports)
            });
        } catch (e: any) {
            dispatch({
                type: STAFF_WAGE_REPORT_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

//Sort by date ascending
function processReportsIncoming(
    data: StaffWageReportResponse | null | undefined
): StaffWageReportResponse | null | undefined {
    if (!data) return null;

    if (!data.entries) return data;

    if (data.entries.length === 0) return data;

    const entries = sortWageReportsByDateAscending(data.entries);

    return {
        ...data,
        entries
    };
}

function sortWageReportsByDateAscending(entries: StaffWageReportEntry[]): StaffWageReportEntry[] {
    return entries.sort((a: StaffWageReportEntry, b: StaffWageReportEntry) => {
        if (a.startDate > b.startDate) {
            return 1;
        }

        if (a.startDate < b.startDate) {
            return -1;
        }

        return 0;
    });
}
