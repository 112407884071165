import React from "react";
import GlobalCalendarMobileFilters from "./GlobalCalendarMobileFilters";
import {useGlobalCalendarMobile} from "./Hooks/useGlobalCalendarMobile";
import MobileEventListContainer from "./Components/MobileEventListContainer";
import {WithServiceState} from "store-fetch-wrappers/dist";
import Carousel from "../../../../../modules/Carousel/Components/Carousel";
import {CarouselItem} from "../../../../../modules/Carousel/Components/CarouselItem";
import {DateRange} from "../../../../../utils/filterUtils";
const ServiceWrapper = WithServiceState(MobileEventListContainer);

function GlobalCalendarMobile() {
    const {mobileCalendarStore, fetchData} = useGlobalCalendarMobile();
    return (
        <React.Fragment>
            <Carousel
                buttonNames={["My Shifts", "Available Shifts"]}
                slot={
                    <GlobalCalendarMobileFilters
                        onDateSelected={async (date) => {
                            const range: DateRange = {
                                startDateInclusive: date.clone().startOf("day").unix(),
                                endDateExclusive: date.clone().add(1, "day").startOf("day").unix()
                            };

                            await fetchData(range);
                        }}
                    />
                }
            >
                <CarouselItem>
                    <ServiceWrapper
                        {...mobileCalendarStore}
                        data={{
                            shiftItems: mobileCalendarStore.data?.staffShifts,
                            noItemsMessage: "No shifts for this day",
                            dateRange: mobileCalendarStore.data?.dateRange
                        }}
                        loaderType={"overlay"}
                        loaderWheelType={"three-ring"}
                        showLoadingText={false}
                    />
                </CarouselItem>
                <CarouselItem>
                    <ServiceWrapper
                        {...mobileCalendarStore}
                        data={{
                            shiftItems: mobileCalendarStore.data?.availableShifts,
                            noItemsMessage: "There are no available shifts",
                            dateRange: mobileCalendarStore.data?.dateRange,
                            isAvailableShiftsItem: true
                        }}
                        loaderType={"overlay"}
                        loaderWheelType={"three-ring"}
                        showLoadingText={false}
                    />
                </CarouselItem>
            </Carousel>
        </React.Fragment>
    );
}

export default GlobalCalendarMobile;
