import {routeNames} from "../../../Navigation/routeNames";
import {getMonthQueryString} from "../../AvailableShiftsList/AvailableShiftsList";

export function getAttendanceOverviewPath(
    calendarName: string,
    calendarId: number,
    month?: number
) {
    return `${routeNames.calendar.path}/${encodeURI(calendarName)}${
        routeNames.attendanceOverview.path
    }?id=${calendarId}${getMonthQueryString(month)}`;
}
