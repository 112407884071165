import {CalendarMonthView} from "../../AdminCalendar/Helpers/calendarUtils";
import {routeNames} from "../../../Navigation/routeNames";
import {EventClickArg} from "@fullcalendar/react";
import {getMonthQueryString} from "../../AttendanceRegister/Helpers/attendanceRegisterHelpers";

export function canDutyManagerViewEvent(args: EventClickArg, dutyManagerId: string): boolean {
    let canView = false;
    const {assignments} = args.event.extendedProps;
    if (assignments.length === 0) return canView;

    for (const assignment of assignments) {
        if (!assignment.staffMember) continue;

        if (assignment.staffMember.staffId === dutyManagerId) {
            canView = true;
        }
    }
    return canView;
}

export function getViewPathForAdminForGlobalCalendar(args: EventClickArg, month: number) {
    const encodedCalendarName = encodeURI(CalendarMonthView.Global);
    const pathname = `${routeNames.calendar.path}/${encodedCalendarName}${routeNames.editCalendarEntry.path}/${args.event.id}`;

    return `${pathname}?calendarId=${args.event.groupId}&month=${month}&isGlobalCalendar=true`;
}

export function getViewPathForDutyManagerForGlobalCalendar(args: EventClickArg, month: number) {
    const encodedCalendarName = encodeURI(CalendarMonthView.Staff);
    const pathname = `${routeNames.globalCalendar.path}/${encodedCalendarName}${routeNames.readOnlyEntry.path}/${args.event.id}`;

    return `${pathname}?calendarId=${args.event.groupId}&month=${month}&isGlobalCalendar=true`;
}

export function getViewPathForDutyManagerForGlobalCalendarMobile(
    calendarId: number,
    entryId: number,
    day: number
) {
    const encodedCalendarName = encodeURI(CalendarMonthView.Staff);
    const pathname = `${routeNames.globalCalendar.path}/${encodedCalendarName}${routeNames.readOnlyEntry.path}/${entryId}`;

    return `${pathname}?calendarId=${calendarId}&day=${day}&isGlobalCalendar=true`;
}

export function getPathToGlobalStaffCalendar(
    calendarView: CalendarMonthView,
    query: URLSearchParams
) {
    const month = query.get("month");

    return `${routeNames.globalCalendar.path}/${encodeURI(calendarView)}${getMonthQueryString(
        month
    )}`;
}
