import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {setNavigationItems} from "../../../../../store/navigation/actions/NavigationActions";
import {useXeroRoutes} from "../../Hooks/useXeroRoutes";
import GrsApiModel from "../../../../../store/apiModel/GrsApiModel";
import {useQuery} from "../../../../Hooks/useQuery";
import {showErrorToast} from "../../../../../utils/toastUtils";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../../../Navigation/routeNames";

export function useXeroCallback() {
    const dispatch = useDispatch();
    const {getCallbackRoutes} = useXeroRoutes();
    const query = useQuery();
    const history = useHistory();

    useEffect(() => {
        dispatch(setNavigationItems(getCallbackRoutes()));

        handleXeroCallback();
    }, []);

    async function handleXeroCallback() {
        try {
            const code = query.get("code") || undefined;
            const state = query.get("state") || undefined;
            const response = await GrsApiModel.getInvoiceApi().xeroCallback(code, state);

            if (response.status === 200) {
                history.replace(routeNames.xeroInvoices.path);
            }
        } catch (e: any) {
            showErrorToast("Could not connect with Xero");
            history.replace(routeNames.xeroLogin.path);
        }
    }
}
