import React from "react";
import {AvailableShiftsEntryFormProps} from "../../../../../AvailableShifts/Calendar/Components/AvailableShiftsEntryForm";
import {AvailableShiftSection} from "../../../../../../../api/grs";
import AvailableRolesSection from "./AvailableRolesSection";
import {getTimeTextForEntry} from "../../../../../../../store/fullCalendarItemList/actions/FullCalendarItemListActions";

export function AvailableRolesContainer(props: AvailableShiftsEntryFormProps) {
    return (
        <React.Fragment>
            <div className="available-shift-calendar-event-item-detail-item-header">
                {props.entry.description}
            </div>
            <div className="available-shift-calendar-event-item-detail-item-body pb-3">
                {getTimeTextForEntry(props.entry.startDate, props.entry.endDate)}
            </div>
            {props.entry.requiredStaff.map((section: AvailableShiftSection, index: number) => {
                return (
                    <AvailableRolesSection
                        section={section}
                        entry={props.entry}
                        key={index}
                        callback={props.callback}
                    />
                );
            })}
        </React.Fragment>
    );
}
