import React from "react";
import GenericTypeDropdown from "../../../../Dropdown/Components/GenericTypeDropdown";
import {RequestFilterProps} from "../../../../Filters/helpers/filterHelpers";
import {ActiveDashView} from "../../Shared/Hooks/useGrsDashContainer";
import {getArchiveFilterFromString} from "../../../../../utils/enumUtils";

const EventCalendarFilters = ({request, onRequestChanged}: Props) => {
    return (
        <React.Fragment>
            <table className="filters-table mt-3">
                <tbody className="filters-lg">
                    <tr>
                        <th className="filter-heading w-50 pl-3 pr-3">View</th>
                    </tr>
                    <tr>
                        <td className="filter-heading w-50 pl-3 pr-3 pb-2">
                            {request && (
                                <GenericTypeDropdown
                                    enumOptions={ArchivedView}
                                    splitByCapitalLetter={false}
                                    clearable={false}
                                    searchable={false}
                                    changeOption={(item) => {
                                        const option = getArchiveFilterFromString(item.toString());
                                        onRequestChanged({
                                            ...request,
                                            archiveView: option
                                        });
                                    }}
                                    id={request.archiveView}
                                    disabled={false}
                                    appendToBody
                                />
                            )}
                        </td>
                    </tr>
                </tbody>
                <tbody className="filters-md">
                    <tr>
                        <th className="filter-heading w-50 pl-3 pr-3">View</th>
                    </tr>
                    <tr>
                        <td className="filter-heading w-50 pl-3 pr-3 pb-2">
                            {request && (
                                <GenericTypeDropdown
                                    enumOptions={ArchivedView}
                                    splitByCapitalLetter={false}
                                    clearable={false}
                                    searchable={false}
                                    changeOption={(item) => {
                                        const option = getArchiveFilterFromString(item.toString());
                                        onRequestChanged({
                                            ...request,
                                            archiveView: option
                                        });
                                    }}
                                    id={request.archiveView}
                                    disabled={false}
                                    appendToBody
                                />
                            )}
                        </td>
                    </tr>
                </tbody>
                <tbody className="filters-sm">
                    <tr>
                        <th className="filter-heading w-50 pl-3 pr-3">View</th>
                    </tr>
                    <tr>
                        <td className="filter-heading w-50 pl-3 pr-3 pb-2">
                            {request && (
                                <GenericTypeDropdown
                                    enumOptions={ArchivedView}
                                    splitByCapitalLetter={false}
                                    clearable={false}
                                    searchable={false}
                                    changeOption={(item) => {
                                        const option = getArchiveFilterFromString(item.toString());
                                        onRequestChanged({
                                            ...request,
                                            archiveView: option
                                        });
                                    }}
                                    id={request.archiveView}
                                    disabled={false}
                                    appendToBody
                                />
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>
        </React.Fragment>
    );
};

export default EventCalendarFilters;

interface Props extends RequestFilterProps<EventCalendarRequest> {
    request: EventCalendarRequest;
}

export interface EventCalendarRequest {
    archiveView: ArchivedView;
    dashView: ActiveDashView;
}

// eslint-disable-next-line no-shadow
export enum ArchivedView {
    All = "All",
    Archived = "Archived",
    Unarchived = "Unarchived"
}

function getViewFromString(value: string): ArchivedView {
    return ArchivedView[value as keyof typeof ArchivedView];
}
