import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {AutoInvoiceListResponse} from "../../../api/grs";

export const XERO_INVOICES_STORE = createStoreState("xero_store_state");

interface XeroInvoicesLoading extends StoreServiceData<AutoInvoiceListResponse> {
    type: typeof XERO_INVOICES_STORE.LOADING;
}

interface XeroInvoicesError extends StoreServiceData<AutoInvoiceListResponse> {
    type: typeof XERO_INVOICES_STORE.ERROR;
}

interface XeroInvoicesSuccess extends StoreServiceData<AutoInvoiceListResponse> {
    type: typeof XERO_INVOICES_STORE.SUCCESS;
}

export type XeroInvoicesDispatchTypes =
    | XeroInvoicesLoading
    | XeroInvoicesError
    | XeroInvoicesSuccess;
