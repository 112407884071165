import {Dispatch} from "redux";
import {
    CALENDAR_ENTRY_LIST_STORE_STATE,
    CalendarEntryListDispatchTypes
} from "./CalendarEntryListActionTypes";
import {CalendarEntryListRequest} from "../../../api/grs";
import GrsApiModel from "../../apiModel/GrsApiModel";
import {postDataToServiceWithData} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";

/** Nukes store of stale data */
export const nullifyCalendarEntriesListStore = () => {
    return async (dispatch: Dispatch<CalendarEntryListDispatchTypes>) => {
        dispatch({
            type: CALENDAR_ENTRY_LIST_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

/** gets a list of calendar entries */
export const getCalendarEntriesList = (args: CalendarEntryListRequest) => {
    return async (dispatch: Dispatch<CalendarEntryListDispatchTypes>) => {
        try {
            const entries = await postDataToServiceWithData(
                CALENDAR_ENTRY_LIST_STORE_STATE,
                dispatch,
                () => GrsApiModel.getCalendarApi().listCalendarEntries(args),
                statusCodeCallback
            );

            dispatch({
                type: CALENDAR_ENTRY_LIST_STORE_STATE.SUCCESS,
                loading: false,
                error: null,
                data: entries
            });

            return entries;
        } catch (e: any) {
            dispatch({
                type: CALENDAR_ENTRY_LIST_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
