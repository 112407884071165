import React from "react";
import Icon, {IconType} from "../Icon/Icon";
import Tooltip from "../Tooltip/Tooltip";

const TableItemAction = ({tooltipText, onClick, icon}: Props) => {
    return (
        <React.Fragment>
            <Tooltip tooltipText={tooltipText} size={"md"} place={"left"} rootElement="span">
                <Icon
                    rootElement="Span"
                    className="cursor-pointer"
                    icon={icon}
                    size={"Medium"}
                    onClick={onClick}
                />
            </Tooltip>
        </React.Fragment>
    );
};

export default TableItemAction;

interface Props {
    tooltipText: string;
    onClick: (e: any) => void;
    icon: IconType;
}
