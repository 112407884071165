import React, {useContext} from "react";
import {CalendarEntryContext} from "../../../../Context/CalendarEntryContext";
import {CalendarTypeEnum} from "../../../../../api/grs";
import DutyManagerReportButton from "../DutyManagerReportButton";

const CalendarEntryDutyManagerActions = () => {
    const entry = useContext(CalendarEntryContext);
    return (
        <React.Fragment>
            <div className="row ml-0 mr-0 mt-3">
                {entry.calendarType === CalendarTypeEnum.Event && <DutyManagerReportButton />}
            </div>
        </React.Fragment>
    );
};

export default CalendarEntryDutyManagerActions;
