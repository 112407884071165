import {Dispatch} from "redux";
import {
    OVERLAPPING_ENTRY_LIST_STORE_STATE,
    OverlappingEntryListDispatchTypes
} from "./OverlappingEntryListActionTypes";
import GrsApiModel from "../../apiModel/GrsApiModel";
import {UserEntriesRequest} from "../../../api/grs";
import {RootStore} from "../../Store";
import {postDataToServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";

/** Nukes store of stale data */
export const nullifyOverlappingEntriesListStore = () => {
    return async (dispatch: Dispatch<OverlappingEntryListDispatchTypes>) => {
        dispatch({
            type: OVERLAPPING_ENTRY_LIST_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

/** Nukes store of stale data */
export const checkUserEventsOverlap = (request: UserEntriesRequest) => {
    return async (
        dispatch: Dispatch<OverlappingEntryListDispatchTypes>,
        state: () => RootStore
    ) => {
        try {
            await postDataToServiceWithRedux(
                OVERLAPPING_ENTRY_LIST_STORE_STATE,
                dispatch,
                () => GrsApiModel.getCalendarApi().getUserEntries(request),
                statusCodeCallback
            );

            const data = state().overlappingEntryList.data;

            return data ? data : [];
        } catch (e: any) {
            dispatch({
                type: OVERLAPPING_ENTRY_LIST_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
