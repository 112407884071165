import React from "react";
import {AutoInvoiceListResponse, AutoInvoiceRecord} from "../../../../../api/grs";
import moment from "moment";
import {formatUnixToMMMMYYYY} from "../../../../../utils/momentUtils";
import Tooltip from "../../../../Tooltip/Tooltip";
import Icon, {IconType} from "../../../../Icon/Icon";

export function useXeroInvoicesTable(props: AutoInvoiceListResponse) {
    function toTableRow(): XeroInvoicesTableRow[] {
        if (!props.invoices) return [];
        return props.invoices?.map((item, index) => {
            return {
                id: (index + 1).toString(),
                staffName: getStaffMemberMarkup(item),
                date: getDateFromInvoiceRow(item),
                column1: "",
                column2: "",
                actions: item
            };
        });
    }

    function getDateFromInvoiceRow({month, year}: AutoInvoiceRecord) {
        const momentObj = moment().set({month: month - 1, year});

        return formatUnixToMMMMYYYY(momentObj.unix());
    }

    function headers() {
        return {
            staffName: "Staff Member",
            date: "Date",
            column1: " ",
            column2: " ",
            actions: "Actions"
        };
    }

    function getStaffMemberMarkup({
        staffName,
        rawData
    }: Pick<AutoInvoiceRecord, "staffName" | "rawData">) {
        return (
            <React.Fragment>
                <p className="mb-0">
                    {staffName} {rawData.incomplete && getIncompleteMarkup(staffName)}
                </p>
            </React.Fragment>
        );
    }

    function getIncompleteMarkup(staffName: string) {
        return (
            <Tooltip
                tooltipText={`Invoice for ${staffName} could not be generated due to incomplete shift details. Click row to expand.`}
                size={"md"}
                theme={"dark"}
                place={"right"}
            >
                <Icon
                    rootElement={"Span"}
                    icon={IconType.Circle}
                    size={"Small"}
                    className={"pb-1 ml-2 invoice-incomplete"}
                />
            </Tooltip>
        );
    }

    return {
        items: toTableRow(),
        headers: headers()
    };
}

interface XeroInvoicesTableRow {
    id: string;
    staffName: React.ReactNode;
    date: string;
    actions: AutoInvoiceRecord;
    column1: string;
    column2: string;
}
