import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {
    EVENT_REPORT_LIST_STORE,
    EventReportListItemResponse
} from "../actions/EventReportListActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";

/** Default state of the store. */
const defaultState = createDefaultStoreState<EventReportListItemResponse[]>([]);

/** Infer type of the reducer for code completion and quality */
const eventReportListReducer = (
    state: StoreServiceData<EventReportListItemResponse[]> = defaultState,
    action: ServiceActionTypes<EventReportListItemResponse[]>
): StoreServiceData<EventReportListItemResponse[]> =>
    createReducer(state, action, EVENT_REPORT_LIST_STORE, () => showErrorToast(action.error));

export default eventReportListReducer;
