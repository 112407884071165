import {StoreServiceData, ServiceActionTypes} from "store-fetch-wrappers";
import {PENDING_NOTIFICATIONS_STORE_STATE} from "../actions/PendingNotificationsActionTypes";
import {StaffPendingAction} from "../../../api/grs";
import {createDefaultStoreState, createReducer} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<StaffPendingAction[]>([]);

const pendingNotificationsReducer = (
    state: StoreServiceData<StaffPendingAction[]> = defaultState,
    action: ServiceActionTypes<StaffPendingAction[]>
): StoreServiceData<StaffPendingAction[]> =>
    createReducer(state, action, PENDING_NOTIFICATIONS_STORE_STATE, () =>
        showErrorToast(action.error)
    );
export default pendingNotificationsReducer;
