import React, {useContext} from "react";
import {
    AddDriverDetailArgs,
    VehicleDriver
} from "../../../../../../../store/dutyManagerReport/actions/DutyManagerReportActionTypes";
import {DutyManagerReportEditableContext} from "../../Context/DutyManagerReportEditable";
import {useDispatch, useSelector} from "react-redux";
import {addNewDriverDetails} from "../../../../../../../store/dutyManagerReport/actions/DutyManagerReportActions";
import DriverDetailItem from "./DriverDetailItem";
import {getTotalAssignments} from "../../../../../AdminCalendarEntry/Helpers/calenderEntryHelpers";
import {AssignmentState, StaffLink} from "../../../../../../../api/grs";
import {VehicleDetailItemProps} from "../Vehicle/VehicleDetailItem";
import deepCopy from "../../../../../../../utils/csvUtils";
import {AvailableStaffLinkListContext} from "../../../../../../Context/AvailableStaffLinkListContext";
import FormHeaderWithAction from "../../../../../../Form/FormHeaderWithAction";
import {IconType} from "../../../../../../Icon/Icon";
import {RootStore} from "../../../../../../../store/Store";

const DriverSection = (props: VehicleDetailItemProps) => {
    const dispatch = useDispatch();
    const editable = useContext(DutyManagerReportEditableContext);
    const entry = useSelector((state: RootStore) => state.calendarEntry.data);

    const addDriverToVehicle = () => {
        const args: AddDriverDetailArgs = {
            vehicleUID: props.details.uid
        };
        dispatch(addNewDriverDetails(args));
    };

    const getAvailableStaff = () => {
        if (!entry) return [];
        const totalAssignments = getTotalAssignments(entry).filter(
            (assignment) => assignment.state !== AssignmentState.Unassigned
        );
        const staffList: StaffLink[] = [];
        const assignedDrivers: StaffLink[] = [];

        for (const vehicle of props.vehicleList) {
            for (const driver of vehicle.assignments) {
                if (driver.staffId.length > 0) {
                    assignedDrivers.push({
                        staffId: driver.staffId,
                        staffName: driver.staffName
                    });
                }
            }
        }

        for (const assignment of totalAssignments) {
            if (assignment.staffMember) {
                staffList.push(assignment.staffMember);
            }
        }

        const availableDrivers = deepCopy(staffList);

        for (let i = 0; i < assignedDrivers.length; ++i) {
            for (let j = 0; j < availableDrivers.length; ++j) {
                if (assignedDrivers[i].staffId === availableDrivers[j].staffId) {
                    availableDrivers.splice(j, 1);
                }
            }
        }

        return availableDrivers;
    };
    return (
        <React.Fragment>
            <AvailableStaffLinkListContext.Provider value={getAvailableStaff()}>
                <FormHeaderWithAction
                    headerName={"Drivers"}
                    icon={IconType.Add}
                    onClick={addDriverToVehicle}
                    showIcon={editable}
                    tooltipText={"Add Drivers"}
                />
                {props.details.assignments.length === 0 ? (
                    <div className="row ml-0 mr-0 mt-2">
                        <div className="col">
                            <p className="align-items-center align-self-center text-center mb-2">
                                {/* eslint-disable-next-line react/no-unescaped-entities */}
                                Drivers haven't been assigned for this vehicle. Click the 'Plus' to
                                start
                            </p>
                        </div>
                    </div>
                ) : (
                    props.details.assignments.map((driver: VehicleDriver, index: number) => {
                        return (
                            <DriverDetailItem
                                key={index}
                                driver={driver}
                                vehicleUid={props.details.uid}
                            />
                        );
                    })
                )}
            </AvailableStaffLinkListContext.Provider>
        </React.Fragment>
    );
};

export default DriverSection;
