import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {CALENDAR_ENTRY_LIST_STORE_STATE} from "../actions/CalendarEntryListActionTypes";
import {EventCalendarEntry, FrontlineCalendarEntry} from "../../../api/grs";
import {showErrorToast} from "../../../utils/toastUtils";

/** Default state of the store. */
const defaultState = createDefaultStoreState<EventCalendarEntry[] | FrontlineCalendarEntry[]>([]);

/** Infer type of the reducer for code completion and quality */
const calendarEntryListReducer = (
    state: StoreServiceData<EventCalendarEntry[] | FrontlineCalendarEntry[]> = defaultState,
    action: ServiceActionTypes<EventCalendarEntry[] | FrontlineCalendarEntry[]>
): StoreServiceData<EventCalendarEntry[] | FrontlineCalendarEntry[]> =>
    createReducer(state, action, CALENDAR_ENTRY_LIST_STORE_STATE, () =>
        showErrorToast(action.error)
    );

export default calendarEntryListReducer;
