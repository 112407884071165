import React, {useEffect, useState} from "react";
import GrsApiModel from "../../../../store/apiModel/GrsApiModel";
import {AxiosResponse} from "axios";
import {LoadingWheel} from "react-state-helpers";
import {routeNames} from "../../../Navigation/routeNames";
import {useHistory} from "react-router-dom";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import {handleAssignmentErrorMessages} from "../../../Notifications/ShiftActions";
import {showSuccessToast} from "../../../../utils/toastUtils";

const AssignmentActions = (props: AssignmentActionProps) => {
    const [success, setSuccess] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const history = useHistory();

    useEffect(() => {
        //Resetting state when the state changes
        setSuccess(false);

        handleRetry(props).then();
    }, [props]);

    const handleRetry = async (action: AssignmentActionProps) => {
        if (action.isBriefingRoute) {
            await acceptBriefing(action.entryId);
            return;
        }

        if (action.confirm) {
            await acceptAssignment(action.entryId);
            return;
        }

        await rejectAssignment(action.entryId);
    };

    const acceptAssignment = async (id: number) => {
        setLoading(true);
        debugger;
        try {
            const request = await GrsApiModel.getStaffApi().acceptAssignment(id);
            await handleRequest(request, onRequestComplete, AcceptanceType.Accepted);
            // eslint-disable-next-line no-shadow
        } catch (error: any) {
            handleAssignmentErrorMessages(error, props.entryId);
        }
    };

    const rejectAssignment = async (id: number) => {
        setLoading(true);

        try {
            const request = await GrsApiModel.getStaffApi().rejectAssignment(id);
            await handleRequest(request, onRequestComplete, AcceptanceType.Rejected);
            // eslint-disable-next-line no-shadow
        } catch (error: any) {
            handleAssignmentErrorMessages(error, props.entryId);
        }
    };

    const onRequestComplete = () => {
        setSuccess(true);
        setLoading(false);
    };

    const acceptBriefing = async (id: number) => {
        setLoading(true);
        try {
            const request = await GrsApiModel.getStaffApi().acceptBriefing(id);
            await handleRequest(request, onRequestComplete, AcceptanceType.BriefingAccepted);
            // eslint-disable-next-line no-shadow
        } catch (error: any) {
            handleAssignmentErrorMessages(error, props.entryId);
        }
    };

    const handleRequest = async (
        request: AxiosResponse<void, any>,
        onSuccess: () => void,
        acceptanceType: AcceptanceType
    ) => {
        if (request.status === 200) {
            onSuccess();
            switch (acceptanceType) {
                case AcceptanceType.Accepted:
                    showSuccessToast("Successfully accepted assignment");
                    break;
                case AcceptanceType.BriefingAccepted:
                    showSuccessToast("Successfully accepted briefing");
                    break;
                case AcceptanceType.Rejected:
                    showSuccessToast("Successfully rejected assignment");
                    break;
            }
        }
    };

    const pageHeader = () => {
        if (success) {
            if (props.isBriefingRoute) {
                return "Accepted Briefing";
            }
            return props.confirm ? "Accepted" : "Rejected";
        }

        if (!success) {
            if (props.isBriefingRoute) {
                return "Accept Briefing Error";
            }
            return props.confirm ? "Accept Error" : "Reject Error";
        }

        if (props.isBriefingRoute) {
            return "Accepting Briefing";
        }

        return props.confirm ? "Accepting Assignment" : "Rejecting Assignment";
    };

    const pageBody = () => {
        if (success) {
            if (props.isBriefingRoute) {
                return "You have successfully accepted the briefing";
            }
            return props.confirm
                ? "You have successfully accepted this assignment!"
                : "You have successfully rejected this assignment!";
        }

        if (!success) {
            return "If the problem persists, please contact your system administrator!";
        }

        const adjective = props.confirm ? "acceptance" : "rejection";
        if (props.isBriefingRoute) {
            return `Confirming your acceptance of this briefing,
                    please do not leave this page until complete!`;
        }
        return `Confirming your ${adjective} of this assignment,
                    please do not leave this page until complete!`;
    };

    const goToNotificationsPage = () => {
        history.push(routeNames.pendingNotifications.path);
    };
    return (
        <React.Fragment>
            <div style={{marginTop: `105px`}}>
                <h1 className="mt-5 text-center">{pageHeader()}</h1>
                {loading && <LoadingWheel />}
                <div className="row mt-5">
                    <div className="col">
                        <p className="text-center">{pageBody()}</p>
                    </div>
                </div>
                {!success && (
                    <React.Fragment>
                        <div className="row">
                            <div className="col d-flex justify-content-center">
                                <MCButton
                                    size={ButtonSize.Large}
                                    innerValue={"Retry"}
                                    onClick={() => handleRetry(props)}
                                    colour={ButtonColourOptions.Yellow}
                                    roundedCorner
                                />
                            </div>
                        </div>
                    </React.Fragment>
                )}

                <div className="row mt-5">
                    <div className="col">
                        <p className="text-center">
                            Click the button below to return to the notifications page
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col d-flex justify-content-center">
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Notifications"}
                            onClick={goToNotificationsPage}
                            colour={ButtonColourOptions.Yellow}
                            roundedCorner
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AssignmentActions;

export interface AssignmentActionProps {
    entryId: number;
    confirm: boolean;
    isBriefingRoute: boolean;
}

// eslint-disable-next-line no-shadow
enum AcceptanceType {
    Accepted = "Accepted",
    Rejected = "Rejected",
    BriefingAccepted = "BriefingAccepted"
}
