import {Dispatch} from "redux";
import {
    MOBILE_CALENDAR_VIEW,
    MobileCalendarViewDispatchTypes,
    ShiftBlock
} from "./MobileCalendarActionTypes";
import {DateRange} from "../../../utils/filterUtils";
import {CalendarEventItem} from "../../fullCalendarItemList/actions/FullCalendarItemListActionTypes";
import GrsApiModel from "../../apiModel/GrsApiModel";
import {convertEntriesListToCalendarEventItem} from "../../fullCalendarItemList/actions/FullCalendarItemListActions";
import {showErrorToast} from "../../../utils/toastUtils";
import {AvailableShiftCalendarEntry, CalendarEntry} from "../../../api/grs";
import {sortEventsForAvailableShiftsCalendar} from "../../../components/Pages/AvailableShifts/Calendar/Helpers/availableShiftsListCalendar";
import {UserData} from "../../../api/staff";
import {RootStore} from "../../Store";

export const nullifyMobileCalendarViewStore = () => {
    return async (dispatch: Dispatch<MobileCalendarViewDispatchTypes>) => {
        dispatch({
            type: MOBILE_CALENDAR_VIEW.SUCCESS,
            loading: false,
            error: null,
            data: {
                staffShifts: {
                    raw: {},
                    converted: {}
                },
                availableShifts: {
                    raw: {},
                    converted: {}
                },
                dateRange: {startDateInclusive: 0, endDateExclusive: 0}
            }
        });
    };
};

export const getMobileCalendarViewItems = (dateRange: DateRange, user: UserData) => {
    return async (dispatch: Dispatch<MobileCalendarViewDispatchTypes>, state: () => RootStore) => {
        const {data} = state().mobileCalendarView;
        if (!data) return;
        try {
            dispatch({
                type: MOBILE_CALENDAR_VIEW.SUCCESS,
                loading: true,
                error: null,
                data
            });
            const staffShifts = await getStaffShifts(dateRange);
            const availableShifts = await getAvailableShifts(dateRange, user);

            dispatch({
                type: MOBILE_CALENDAR_VIEW.SUCCESS,
                loading: false,
                error: null,
                data: {
                    staffShifts,
                    availableShifts,
                    dateRange
                }
            });
        } catch (e: any) {
            dispatch({
                type: MOBILE_CALENDAR_VIEW.ERROR,
                loading: false,
                error: e
            });
        }
    };
};

async function getStaffShifts({
    startDateInclusive,
    endDateExclusive
}: DateRange): Promise<ShiftBlock<CalendarEntry, CalendarEventItem>> {
    try {
        const resp = await GrsApiModel.getCalendarApi().listCalendarEntriesWithCalendars({
            startDate: startDateInclusive,
            endDate: endDateExclusive
        });

        if (resp.status === 200 && resp.data) {
            return {
                converted: toMap(await convertEntriesListToCalendarEventItem(resp.data.entries)),
                raw: toMap(resp.data.entries)
            };
        }
        return {
            raw: {},
            converted: {}
        };
    } catch (e) {
        showErrorToast("Could not get staff shifts for the date specified");
        return {
            raw: {},
            converted: {}
        };
    }
}

async function getAvailableShifts(
    {startDateInclusive, endDateExclusive}: DateRange,
    user: UserData
): Promise<ShiftBlock<AvailableShiftCalendarEntry, CalendarEventItem>> {
    try {
        const resp = await GrsApiModel.getCalendarApi().listShiftInterestEntries({
            startDate: startDateInclusive,
            endDate: endDateExclusive,
            calendarIds: []
        });

        if (resp.status === 200 && resp.data) {
            return {
                converted: toMap(sortEventsForAvailableShiftsCalendar(resp.data, user)),
                raw: toMap(resp.data)
            };
        }
        return {
            raw: {},
            converted: {}
        };
    } catch (e) {
        showErrorToast("Could not get available shifts for the date specified");
        return {
            raw: {},
            converted: {}
        };
    }
}

function toMap<T extends {id: string | number}>(arr: T[]): {[key: string | number]: T} {
    return arr.reduce(function (map: {[key: string | number]: T}, item) {
        map[item.id] = item;
        return map;
    }, {});
}
