import {AbsenceApi, ContactApi, LocationsApi, RegionsApi, UsersApi} from "../../api/staff";

export default class StaffApiModel {
    private static instance: StaffApiModel;
    private static usersApi: UsersApi;
    private static contactApi: ContactApi;
    private static absenceApi: AbsenceApi;
    private static regionsApi: RegionsApi;
    private static locationsApi: LocationsApi;

    public static getUsersApi(): UsersApi {
        if (!StaffApiModel.usersApi) {
            StaffApiModel.usersApi = new UsersApi({
                basePath: process.env.REACT_APP_STAFF_API,
                isJsonMime(): boolean {
                    return false;
                }
            });
        }

        return StaffApiModel.usersApi;
    }

    public static getContactApi(): ContactApi {
        if (!StaffApiModel.contactApi) {
            StaffApiModel.contactApi = new ContactApi({
                basePath: process.env.REACT_APP_STAFF_API,
                isJsonMime(): boolean {
                    return false;
                }
            });
        }

        return StaffApiModel.contactApi;
    }

    public static getAbsenceApi(): AbsenceApi {
        if (!StaffApiModel.absenceApi) {
            StaffApiModel.absenceApi = new AbsenceApi({
                basePath: process.env.REACT_APP_STAFF_API,
                isJsonMime(): boolean {
                    return false;
                }
            });
        }

        return StaffApiModel.absenceApi;
    }

    public static getRegionsApi(): RegionsApi {
        if (!StaffApiModel.regionsApi) {
            StaffApiModel.regionsApi = new RegionsApi({
                basePath: process.env.REACT_APP_STAFF_API,
                isJsonMime(): boolean {
                    return false;
                }
            });
        }

        return StaffApiModel.regionsApi;
    }

    public static getLocationsApi(): LocationsApi {
        if (!StaffApiModel.locationsApi) {
            StaffApiModel.locationsApi = new LocationsApi({
                basePath: process.env.REACT_APP_STAFF_API,
                isJsonMime(): boolean {
                    return false;
                }
            });
        }

        return StaffApiModel.locationsApi;
    }
}
