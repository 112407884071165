import {useState} from "react";

export function useCalendarComplianceTile() {
    const [showEditIcon, setShowEditIcon] = useState<boolean>(false);

    return {
        showEditIcon,
        setShowEditIcon
    };
}
