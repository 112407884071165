import {Dispatch} from "redux";
import {GEOLOCATION_STORE, GeoLocationDispatchTypes} from "./GeoLocationActionTypes";
import {getDataFromServiceWithRedux} from "store-fetch-wrappers";
import StaffApiModel from "../../../apiModel/StaffApiModel";
import {statusCodeCallback} from "../../../helpers/storeHelpers";

export const clearGeoLocationStore = () => {
    return async (dispatch: Dispatch<GeoLocationDispatchTypes>) => {
        dispatch({
            type: GEOLOCATION_STORE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

export const getGeoLocation = (postcode: string) => {
    return async (dispatch: Dispatch<GeoLocationDispatchTypes>) => {
        try {
            //ToDo: Check for 204, show error if 204
            return await getDataFromServiceWithRedux(
                GEOLOCATION_STORE,
                dispatch,
                () =>
                    StaffApiModel.getLocationsApi().getGeoLocationForPostcode(
                        encodeURIComponent(postcode)
                    ),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: GEOLOCATION_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
