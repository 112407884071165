import React from "react";
import {StaffAssignment} from "../../../../../../../../api/grs";
import {useDispatch} from "react-redux";
import {Modal, useModal} from "pulse-modal";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../../../../Button/MCButton";
import {removeStaffAssignment} from "../../../../../../../../store/calendarEntry/actions/CalendarEntryActions";

const DeleteAssignmentSlot = (props: StaffAssignment) => {
    const dispatch = useDispatch();
    const {toggle, isShown} = useModal();

    /** Deletes staff assignment */
    const deleteAssignmentSlot = () => {
        dispatch(removeStaffAssignment(props.id));
    };

    return (
        <React.Fragment>
            <MCButton
                size={ButtonSize.Large}
                innerValue="Delete Slot"
                onClick={toggle}
                colour={ButtonColourOptions.Red}
                roundedCorner
            />
            <Modal
                isShown={isShown}
                onClose={toggle}
                title={"Delete Assignment"}
                modalSize={"sm"}
                bodyChildren={
                    <div className="row ml-0 mr-0">
                        <p>Are you sure you want to delete this assignment?</p>
                    </div>
                }
                footerChildren={
                    <div className="row ml-0 mr-0">
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue="Yes"
                            onClick={deleteAssignmentSlot}
                            colour={ButtonColourOptions.Yellow}
                            roundedCorner
                        />
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue="Cancel"
                            onClick={toggle}
                            colour={ButtonColourOptions.DarkBlue}
                            roundedCorner
                        />
                    </div>
                }
            />
        </React.Fragment>
    );
};

export default DeleteAssignmentSlot;
