import React, {useContext, useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import moment, {Moment} from "moment";
import {useDispatch} from "react-redux";
import {CalendarEntryContext} from "../../../../../../Context/CalendarEntryContext";
import {Modal, useModal} from "pulse-modal";
import {showErrorToast} from "../../../../../../../utils/toastUtils";
import {setCalendarEntry} from "../../../../../../../store/calendarEntry/actions/CalendarEntryActions";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../../../Button/MCButton";
import DatePickerInputButton from "../../../../../../Button/DatePickerInputButton";

const RescheduleCalendarEntry = () => {
    const entry = useContext(CalendarEntryContext);
    const [startDate, setStartDate] = useState<Moment>(moment());
    const dispatch = useDispatch();
    const {toggle, isShown} = useModal();

    /** Watch for changes to entry */
    useEffect(() => {
        setStartDate(moment.unix(entry.startDate).startOf("day"));
    }, [entry]);

    /** Selected when the date changes */
    const onDateChanged = (date: Date | null) => {
        if (!date) return;

        setStartDate(moment(date));
    };

    /** Changes the date of the event */
    const rescheduleEvent = () => {
        const newStartDate = startDate.clone().startOf("day").unix();
        const startOfToday = moment().startOf("day");

        if (newStartDate < startOfToday.unix()) {
            showErrorToast("Cannot reschedule an event for a past date");
            return;
        }

        dispatch(
            setCalendarEntry({
                ...entry,
                startDate: newStartDate,
                endDate: newStartDate
            })
        );

        toggle();
    };
    return (
        <React.Fragment>
            <MCButton
                size={ButtonSize.Large}
                innerValue="Reschedule Event"
                onClick={toggle}
                colour={ButtonColourOptions.LightBlue}
                roundedCorner
            />
            <Modal
                isShown={isShown}
                onClose={toggle}
                title="Reschedule Entry"
                modalSize={"sm"}
                bodyChildren={
                    <React.Fragment>
                        <div className="row ml-0 mr-0">
                            <div className="col pl-0 pr-0 text-center">
                                <h6 className="mb-0">Select a new date</h6>
                            </div>
                        </div>
                        <div className="row ml-0 mr-0 m-auto">
                            <div className="col pl-0 pr-0 text-center">
                                <DatePicker
                                    selected={startDate.toDate()}
                                    onChange={onDateChanged}
                                    dateFormat="do MMMM yyyy"
                                    customInput={<DatePickerInputButton />}
                                    calendarStartDay={1}
                                    inline
                                />
                            </div>
                        </div>
                    </React.Fragment>
                }
                footerChildren={
                    <div className="row ml-0 mr-0 mt-3">
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue="Apply"
                            onClick={rescheduleEvent}
                            colour={ButtonColourOptions.Yellow}
                            roundedCorner
                        />
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue="Cancel"
                            onClick={toggle}
                            colour={ButtonColourOptions.Red}
                            roundedCorner
                        />
                    </div>
                }
            />
        </React.Fragment>
    );
};

export default RescheduleCalendarEntry;
