import React, {useContext} from "react";
import CalendarEntryStaffDetails from "./Components/CalendarEntryStaffDetails";
import {CalendarEntryContext} from "../../../../../Context/CalendarEntryContext";
import {CalendarTypeEnum} from "../../../../../../api/grs";
import DutyManagerReportButton from "../../../../ReadOnlyCalendarEntry/Components/DutyManagerReportButton";
import InterestedStaffButton from "./Components/InterestedStaff/InterestedStaffButton";
import SMSButton from "../../../../../SMS/Components/SMSButton";
import RepeatEntryButton from "./Components/RepeatEntry/RepeatEntryButton";

const CalendarEntryAdminActions = () => {
    const entry = useContext(CalendarEntryContext);
    return (
        <React.Fragment>
            <div className="row ml-0 mr-0 mt-3">
                <CalendarEntryStaffDetails />
                {entry.id > 0 && (
                    <React.Fragment>
                        <InterestedStaffButton />
                        {entry.calendarType === CalendarTypeEnum.Event && (
                            <DutyManagerReportButton />
                        )}
                        <SMSButton />
                        <RepeatEntryButton />
                    </React.Fragment>
                )}
            </div>
        </React.Fragment>
    );
};

export default CalendarEntryAdminActions;
