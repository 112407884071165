import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {setNavigationItems} from "../../../../store/navigation/actions/NavigationActions";
import {RootStore} from "../../../../store/Store";
import {WithServiceState} from "store-fetch-wrappers/dist";
import CalendarGroupTable from "./Components/CalendarGroupTable";
import {getCalendarGroupList} from "../../../../store/calendarGroupList/actions/CalendarGroupListActions";
import {useXeroRoutes} from "../../Xero/Hooks/useXeroRoutes";

const ServiceWrapper = WithServiceState(CalendarGroupTable);

function ManageCalendarGroups() {
    const dispatch = useDispatch();
    const calendarGroupListStore = useSelector((state: RootStore) => state.calendarGroupList);
    const {getSystemAdminRoutes} = useXeroRoutes();

    useEffect(() => {
        window.scrollTo({top: 0});
        dispatch(setNavigationItems(getSystemAdminRoutes()));

        dispatch(getCalendarGroupList());
    }, []);

    return (
        <React.Fragment>
            <div className="page-container mt-4">
                <ServiceWrapper
                    loaderWheelType={"three-ring"}
                    loaderType={"overlay"}
                    showLoadingText={false}
                    {...calendarGroupListStore}
                />
            </div>
        </React.Fragment>
    );
}

export default ManageCalendarGroups;
