import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {GeoLocation} from "../../../../api/staff";
import {GEOLOCATION_STORE} from "../actions/GeoLocationActionTypes";
import {showErrorToast} from "../../../../utils/toastUtils";

const initialState = createDefaultStoreState<GeoLocation>(null);

const geoLocationReducer = (
    state: StoreServiceData<GeoLocation> = initialState,
    action: ServiceActionTypes<GeoLocation>
) => createReducer(state, action, GEOLOCATION_STORE, () => showErrorToast(action.error));

export default geoLocationReducer;
