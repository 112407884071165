import React, {useContext} from "react";
import {useDispatch} from "react-redux";
import {Modal, useModal} from "pulse-modal";
import {CalendarEntryContext} from "../../../../../../Context/CalendarEntryContext";
import {deleteCalendarEntryFromService} from "../../../../../../../store/calendarEntry/actions/CalendarEntryActions";
import {showErrorToast, showSuccessToast} from "../../../../../../../utils/toastUtils";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../../../Button/MCButton";

const DeleteCalendarEntry = (props: CalendarEntryActionProps) => {
    const {toggle, isShown} = useModal();
    const dispatch = useDispatch();
    const entry = useContext(CalendarEntryContext);

    /** Deletes Event */
    const deleteEvent = async () => {
        try {
            await dispatch(deleteCalendarEntryFromService(entry.id));
            toggle();
            props.onActionSuccess();
            showSuccessToast("Deleted entry");
        } catch (e: any) {
            showErrorToast("Could not delete Calendar Entry");
        }
    };
    return (
        <React.Fragment>
            <MCButton
                size={ButtonSize.Large}
                innerValue="Delete"
                onClick={toggle}
                colour={ButtonColourOptions.Red}
                roundedCorner
                className="mr-3"
            />
            <Modal
                isShown={isShown}
                onClose={toggle}
                title="Delete Entry"
                modalSize={"sm"}
                bodyChildren={
                    <div className="row ml-0 mr-0">
                        <p>Are you sure you want to delete this entry?</p>
                    </div>
                }
                footerChildren={
                    <div className="row ml-0 mr-0">
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue="Yes"
                            onClick={deleteEvent}
                            colour={ButtonColourOptions.Yellow}
                            roundedCorner
                        />
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue="No"
                            onClick={toggle}
                            colour={ButtonColourOptions.DarkBlue}
                            roundedCorner
                        />
                    </div>
                }
            />
        </React.Fragment>
    );
};

export default DeleteCalendarEntry;

export interface CalendarEntryActionProps {
    onActionSuccess: () => void;
}
