import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {UserInterest} from "../../../api/grs";
import {USER_INTEREST_LIST_STORE_STATE} from "../actions/UserInterestListActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";

/** Default state of the store. */
const defaultState = createDefaultStoreState<UserInterest[]>([]);

/** Infer type of the reducer for code completion and quality */
const userInterestListReducer = (
    state: StoreServiceData<UserInterest[]> = defaultState,
    action: ServiceActionTypes<UserInterest[]>
): StoreServiceData<UserInterest[]> =>
    createReducer(state, action, USER_INTEREST_LIST_STORE_STATE, () =>
        showErrorToast(action.error)
    );

export default userInterestListReducer;
