import React from "react";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../Button/MCButton";
import {XeroStartData} from "../../../../../api/grs";
import xeroLogo from "../../../../../images/xero-logo.png";

//ToDo: Test flow when disconnecting
function XeroLoginPortal(props: XeroStartData) {
    return (
        <React.Fragment>
            <div className="xero-page-wrapper">
                <div className="xero-login-card">
                    <img src={xeroLogo} alt={"Xero Logo"} className="xero-logo" />
                    <div className="xero-login-status">
                        Not connected. <br></br> Click below to link Xero with GRS
                    </div>
                    <div className="xero-login-button-wrapper">
                        <MCButton
                            size={ButtonSize.ExtraLarge}
                            innerValue={"Connect to Xero"}
                            onClick={() => {
                                window.open(props.loginUrl, "_self");
                            }}
                            roundedCorner
                            colour={ButtonColourOptions.DarkBlue}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default XeroLoginPortal;
