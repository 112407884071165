import React from "react";
import {CalendarGroup} from "../../../../../api/grs";
import {useCalendarGroupTable} from "../Hooks/useCalendarGroupTable";
import {PulseTable} from "pulse_table";
import NoItemsMessage from "../../../../Table/NoItemsMessage";
import TableItemAction from "../../../../Table/TableItemAction";
import {IconType} from "../../../../Icon/Icon";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../Button/MCButton";
import DeleteTableItemAction from "../../../../Table/DeleteTableItemAction";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../../store/Store";
import {deleteCalendarGroupById} from "../../../../../store/calendarGroup/actions/CalendarGroupActions";
import {getCalendarGroupList} from "../../../../../store/calendarGroupList/actions/CalendarGroupListActions";

function CalendarGroupTable(props: CalendarGroup[]) {
    const {tabledCalendarGroups, goToEditCalendarGroup, tableHeaders, goToAddCalendarGroup} =
        useCalendarGroupTable(props);

    const {loading} = useSelector((state: RootStore) => state.calendarGroupList);
    const dispatch = useDispatch();

    return (
        <React.Fragment>
            <div className="row mt-5 fadeIn ml-0 mr-0">
                <div className="col pr-0 d-flex justify-content-end">
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Add Calendar Group"}
                        onClick={goToAddCalendarGroup}
                        colour={ButtonColourOptions.Yellow}
                        roundedCorner
                    />
                </div>
            </div>
            <PulseTable
                items={tabledCalendarGroups()}
                headers={tableHeaders()}
                noItemsSection={<NoItemsMessage message={"There are no calendar groups set"} />}
                customRenderers={{
                    calendarInfo: (item) => (
                        <React.Fragment>
                            {item.calendarInfo.length > 0 ? (
                                <React.Fragment>
                                    {item.calendarInfo.map((info, key) => {
                                        return (
                                            <p key={key} className="mb-0">
                                                {info}
                                            </p>
                                        );
                                    })}
                                </React.Fragment>
                            ) : (
                                <p className="mb-0">There are no calendars in this group</p>
                            )}
                        </React.Fragment>
                    ),
                    actions: (item) => (
                        <React.Fragment>
                            <TableItemAction
                                tooltipText={`Edit ${item.name}`}
                                onClick={() => goToEditCalendarGroup(item.actions)}
                                icon={IconType.Edit}
                            />
                            <DeleteTableItemAction
                                loading={loading}
                                itemName={item.name}
                                modalMessage={
                                    "Are you sure you want to delete this calendar group?"
                                }
                                modalTitle={"Delete Calendar Group"}
                                onConfirm={async () => {
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    const success: boolean = await dispatch(
                                        deleteCalendarGroupById(item.actions)
                                    );

                                    if (!success) return false;

                                    dispatch(getCalendarGroupList());
                                    return true;
                                }}
                            />
                        </React.Fragment>
                    )
                }}
            />
        </React.Fragment>
    );
}

export default CalendarGroupTable;
