import React, {useContext, useEffect, useRef, useState} from "react";
import {
    AssignmentState,
    CalendarEntry,
    ResendUserNotificationsRequest,
    StaffAssignment,
    UserEntriesRequest
} from "../../../../../../../../api/grs";
import {useToggle} from "../../../../../../../Hooks/useToggle";
import {useDispatch} from "react-redux";
import {Modal, useModal} from "pulse-modal";
import {StaffAssignmentFormError} from "../../../../../../../../utils/formUtils";
import deepCopy from "../../../../../../../../utils/csvUtils";
import {StaffLinkAddedDetails} from "../../../../../Helpers/calenderEntryHelpers";
import {
    checkIfUserHasOverlappingEntries,
    validateExternalAssignment,
    validateStaffAssignment
} from "./Helpers/staffAssignmentHelpers";
import {setStaffAssignment} from "../../../../../../../../store/calendarEntry/actions/CalendarEntryActions";
import {showErrorToast, showSuccessToast} from "../../../../../../../../utils/toastUtils";
import {resendNotification} from "../../../../../../../../store/notifications/resend/actions/ResendActions";
import moment from "moment";
import {checkUserEventsOverlap} from "../../../../../../../../store/overlappingEntriesList/actions/OverlappingEntryListActions";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../../../../Button/MCButton";
import Toggle, {ToggleSize} from "../../../../../../../Toggle/Toggle";
import GenericTypeDropdown from "../../../../../../../Dropdown/Components/GenericTypeDropdown";
import FormRow from "../../../../../../../Form/FormRow";
import {DebounceInput} from "react-debounce-input";
import OverrideTimes from "./OverrideTimes";
import DeleteAssignmentSlot from "./DeleteAssignmentSlot";
import {CalendarEntryContext} from "../../../../../../../Context/CalendarEntryContext";
import EntryAssignmentDropdown from "../../../../../../../Dropdown/Components/EntryAssignmentDropdown";

const EditCalendarEntryStaffAssignmentForm = (props: EditCalendarEntryStaffAssignmentForm) => {
    const initialAssignment = useRef<StaffAssignment>(props.assignment);
    const [assignment, setAssignment] = useState<StaffAssignment>(props.assignment);
    const [showOverrideTimes, setShowOverrideTimes] = useToggle(false);
    const dispatch = useDispatch();
    const entry = useContext(CalendarEntryContext);

    const {isShown, toggle} = useModal();
    const [validAssignmentAction, setValidAssignmentAction] = useState<StaffAssignmentFormError>({
        canBeAssigned: false,
        valid: false,
        message: ""
    });
    useEffect(() => {
        //Set the assignment in the ref, so it can't be reset.
        initialAssignment.current = deepCopy(props.assignment);
        //Have a state that we can mutate.
        setAssignment(initialAssignment.current);

        if (initialAssignment.current.startDate) {
            setShowOverrideTimes(true);
        }
    }, [props]);

    /** Callback fired from dropdown for the assignment being changed */
    const onAssignmentChanged = (newAssignment?: StaffLinkAddedDetails) => {
        if (!newAssignment) return;
        setAssignment({
            ...assignment,
            staffMember: {
                staffId: newAssignment.staffId,
                staffName: newAssignment.staffName
            }
        });
    };

    /** Update rate of assignment */
    const onRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = event.target;
        const regex = /^[+-]?((\d+(\.\d*)?)|(\.\d))$/;
        if (value.length > 0) {
            // Not a decimal value
            if (!regex.test(value)) return;

            // Decimal place -> greater than 2 places
            if (countDecimals(+value) > 2) return;
        }
        setAssignment({
            ...assignment,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            rate: value
        });
    };

    function countDecimals(value: number) {
        if (Math.floor(value) === value) return 0;
        return value.toString().split(".")[1].length || 0;
    }

    /** Saves the assignment with validation */
    const saveAssignment = async () => {
        if (!entry) return;
        if (!assignment.staffMember) return;

        const {staffId, external} = assignment.staffMember;

        if (external) {
            const validExternalAssignment = validateExternalAssignment(assignment);
            if (!validExternalAssignment.valid) {
                for (const errors of validExternalAssignment.messages) {
                    showErrorToast(errors);
                }
                return;
            }
            dispatch(
                setStaffAssignment({
                    ...assignment,
                    startDate: showOverrideTimes ? assignment.startDate : undefined,
                    endDate: showOverrideTimes ? assignment.endDate : undefined
                })
            );
            props.onClose();
            return;
        }

        const validAssignment = validateStaffAssignment(assignment);

        /** Assignment isn't valid */
        if (!validAssignment.valid) {
            for (const errors of validAssignment.messages) {
                showErrorToast(errors);
            }
            return;
        }
        // Everything apart the staff member have been updated
        if (initialAssignment.current.staffMember) {
            const initialStaffId = initialAssignment.current.staffMember.staffId;
            if (staffId === initialStaffId) {
                //Update assignment and close modal
                dispatch(setStaffAssignment(assignment));
                props.onClose();
            }
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const entries: CalendarEntry[] = await getUserOverlapping(staffId);

        const checkIfUserIsDoubleBooked = await checkIfUserHasOverlappingEntries(
            entries,
            assignment.staffMember,
            entry
        );

        //If the user isn't double booked, we want to set their assignment
        if (checkIfUserIsDoubleBooked.valid) {
            setAssignmentStateForStaffMember();
            props.onClose();
            return;
        }

        setValidAssignmentAction(checkIfUserIsDoubleBooked);
        toggle();
    };

    const resendUserNotification = async () => {
        if (!entry) return;
        if (!assignment.staffMember) {
            showErrorToast("Staff member could not be found");
            return;
        }

        try {
            const request: ResendUserNotificationsRequest = {
                entryId: entry.id,
                staffId: assignment.staffMember.staffId
            };
            await dispatch(resendNotification(request));
            showSuccessToast("Resent notification");
            props.onClose();
        } catch (e: any) {
            showErrorToast("Could not resend notification");
        }
    };

    /** Sets the staff assignment */
    const setAssignmentStateForStaffMember = () => {
        dispatch(
            setStaffAssignment({
                ...assignment,
                state: AssignmentState.WaitingAccept,
                startDate: showOverrideTimes ? assignment.startDate : undefined,
                endDate: showOverrideTimes ? assignment.endDate : undefined
            })
        );

        // Hides modal if it is shown
        if (isShown) {
            toggle();
        }

        // Close parent modal
        props.onClose();
    };

    /** Generates requests for overlapping entries for staff member */
    const getUserOverlapping = async (userId: string) => {
        if (!entry) return;
        const request: UserEntriesRequest = {
            startDate: moment.unix(entry.startDate).clone().startOf("day").unix(),
            endDate: moment.unix(entry.startDate).clone().endOf("day").unix(),
            userId
        };

        try {
            return await dispatch(checkUserEventsOverlap(request));
        } catch (e: any) {
            showErrorToast("Could not check if staff member has overlapping events");
        }
    };

    /** Will only fire if "toggled" is true */
    const overrideTimesChanged = (newAssignment: StaffAssignment) => {
        setAssignment(newAssignment);
    };
    //
    /** Resets assignment */
    const resetAssignment = () => {
        setAssignment(initialAssignment.current);

        props.onClose();
    };

    const getResendNotificationText = (state: AssignmentState) => {
        switch (state) {
            default:
                return "";
            case AssignmentState.WaitingAccept:
                return `Note: Clicking resend will send ${assignment.staffMember?.staffName}
                    an email to confirm their attendance to this shift.`;

            case AssignmentState.Accepted:
                return `Note: Briefing document can only be resent within 5 days of the event.`;
        }
    };

    const renderActionButton = (state: AssignmentState) => {
        if (initialAssignment.current.staffMember && assignment.staffMember) {
            const initialStaffMember = initialAssignment.current.staffMember;
            const currentStaffMember = assignment.staffMember;
            if (initialStaffMember.staffId !== currentStaffMember.staffId) {
                return (
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue="Save"
                        onClick={saveAssignment}
                        colour={ButtonColourOptions.Yellow}
                        roundedCorner
                    />
                );
            }
        }
        switch (state) {
            case AssignmentState.WaitingAccept:
            case AssignmentState.Accepted:
                return (
                    <React.Fragment>
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue="Save"
                            onClick={saveAssignment}
                            colour={ButtonColourOptions.Yellow}
                            roundedCorner
                        />
                        {!assignment.staffMember?.external && (
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue="Resend"
                                onClick={resendUserNotification}
                                colour={ButtonColourOptions.Yellow}
                                roundedCorner
                            />
                        )}
                    </React.Fragment>
                );
            case AssignmentState.Unassigned:
            case AssignmentState.BriefingAccepted:
            case AssignmentState.Attended:
                return (
                    <React.Fragment>
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue="Save"
                            onClick={saveAssignment}
                            colour={ButtonColourOptions.Yellow}
                            roundedCorner
                        />
                    </React.Fragment>
                );
        }
    };

    const getStatusText = (state: AssignmentState) => {
        switch (state) {
            case AssignmentState.Accepted:
                return "Staff member confirmed for the day.";
            case AssignmentState.WaitingAccept:
                return "Invitation sent, awaiting response.";
            case AssignmentState.BriefingAccepted:
                return "Staff member accepted briefing and confirmed for the day.";
            case AssignmentState.Attended:
                return "Staff member attended this event.";
            case AssignmentState.Unassigned:
                return "Assignment has not been made";
            default:
                return "";
        }
    };

    const onExternalStaffNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!assignment.staffMember) return;

        setAssignment({
            ...assignment,
            staffMember: {
                ...assignment.staffMember,
                staffName: event.target.value
            }
        });
    };

    const onExternalStaffEmailChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!assignment.staffMember) return;

        setAssignment({
            ...assignment,
            staffMember: {
                ...assignment.staffMember,
                staffId: event.target.value
            }
        });
    };

    const onExternalAssignmentStateChanged = (id: string | number) => {
        setAssignment({
            ...assignment,
            state: getAssignmentStateFromString(id.toString())
        });
    };

    const getAssignmentStateFromString = (value: string) => {
        return AssignmentState[value as keyof typeof AssignmentState];
    };

    const onExternalAssignmentToggled = () => {
        if (!assignment.staffMember) return;
        let {external} = assignment.staffMember;
        setAssignment({
            ...assignment,
            staffMember: {
                ...assignment.staffMember,
                external: (external = !external),
                staffId: "",
                staffName: ""
            },
            state: external ? AssignmentState.Accepted : AssignmentState.Unassigned,
            rate: 0
        });
    };

    return (
        <React.Fragment>
            <FormRow rowName={"External Assignment?"}>
                <Toggle
                    checked={assignment.staffMember?.external}
                    onChange={onExternalAssignmentToggled}
                    size={ToggleSize.Small}
                    disabled={false}
                />
            </FormRow>
            {assignment.staffMember?.external ? (
                <React.Fragment>
                    <FormRow rowName={"External Staff Name"} columnDetailClassName="pl-0 pr-0">
                        <DebounceInput
                            debounceTimeout={300}
                            className="input-fields"
                            value={assignment.staffMember?.staffName}
                            onChange={onExternalStaffNameChanged}
                            placeholder={"Enter external staff name..."}
                        />
                    </FormRow>
                    <FormRow rowName={"External Staff Email"} columnDetailClassName="pl-0 pr-0">
                        <DebounceInput
                            debounceTimeout={300}
                            className="input-fields"
                            value={assignment.staffMember?.staffId}
                            onChange={onExternalStaffEmailChanged}
                            placeholder={"Enter external staff email..."}
                        />
                    </FormRow>
                    <FormRow rowName={"Assignment State"} columnDetailClassName="pl-0 pr-0">
                        <GenericTypeDropdown
                            enumOptions={ExternalAssignmentState}
                            splitByCapitalLetter={true}
                            clearable={false}
                            searchable={false}
                            changeOption={onExternalAssignmentStateChanged}
                            id={props.assignment.state}
                            disabled={false}
                        />
                    </FormRow>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <FormRow rowName={"Staff Member"} columnDetailClassName={"pl-0 pr-0"}>
                        <EntryAssignmentDropdown
                            initialAssignment={assignment.staffMember}
                            changeOption={onAssignmentChanged}
                            isSearchable={true}
                        />
                    </FormRow>
                </React.Fragment>
            )}
            <FormRow rowName={"Rate (£)"} columnDetailClassName="pl-0 pr-0">
                <input
                    className="input-fields"
                    value={assignment.rate}
                    onChange={onRateChange}
                    type="text"
                    placeholder={"Enter staff rate..."}
                />
            </FormRow>
            <FormRow rowName={"Override assignment times?"}>
                <Toggle
                    checked={showOverrideTimes}
                    onChange={setShowOverrideTimes}
                    size={ToggleSize.Small}
                    disabled={false}
                />
            </FormRow>
            {showOverrideTimes && (
                <OverrideTimes assignment={assignment} onChange={overrideTimesChanged} />
            )}
            <FormRow rowName={"Current status:"}>
                <p>
                    <i>{getStatusText(props.assignment.state)}</i>
                </p>
                <p>
                    {getResendNotificationText(props.assignment.state).length > 0 && (
                        <i>{getResendNotificationText(props.assignment.state)}</i>
                    )}
                </p>
            </FormRow>
            <div className="row mt-5 ml-0 mr-0">
                {renderActionButton(assignment.state)}
                <MCButton
                    size={ButtonSize.Large}
                    innerValue="Cancel"
                    onClick={resetAssignment}
                    colour={ButtonColourOptions.DarkBlue}
                    roundedCorner
                />
                <DeleteAssignmentSlot {...props.assignment} />
            </div>
            <Modal
                isShown={isShown}
                onClose={toggle}
                title={"Assignment Overlap"}
                modalSize={"sm"}
                bodyChildren={
                    <div className="row ml-0 mr-0">
                        <p>{validAssignmentAction.message}</p>
                    </div>
                }
                footerChildren={
                    <div className="row ml-0 mr-0">
                        {validAssignmentAction.canBeAssigned && (
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"Save"}
                                onClick={setAssignmentStateForStaffMember}
                                colour={ButtonColourOptions.Yellow}
                                roundedCorner
                            />
                        )}
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Cancel"}
                            onClick={toggle}
                            colour={ButtonColourOptions.DarkBlue}
                            roundedCorner
                        />
                    </div>
                }
            />
        </React.Fragment>
    );
};

export default EditCalendarEntryStaffAssignmentForm;

// /** As this form appears in a modal, we extend the modal children component props to allow for the toggle callback to be passed down */
interface EditCalendarEntryStaffAssignmentForm {
    assignment: StaffAssignment;
    onClose: () => void;
}

// eslint-disable-next-line no-shadow
enum ExternalAssignmentState {
    Accepted = "Accepted",
    Attended = "Attended",
    BriefingAccepted = "BriefingAccepted"
}
