import {StaffWageReportResponse} from "../../../api/grs";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const STAFF_WAGE_REPORT_STORE = createStoreState("staff_wage_report");

interface StaffWageReportStoreLoading extends StoreServiceData<StaffWageReportResponse> {
    type: typeof STAFF_WAGE_REPORT_STORE.LOADING;
}
interface StaffWageReportStoreError extends StoreServiceData<StaffWageReportResponse> {
    type: typeof STAFF_WAGE_REPORT_STORE.ERROR;
}
interface StaffWageReportStoreSuccess extends StoreServiceData<StaffWageReportResponse> {
    type: typeof STAFF_WAGE_REPORT_STORE.SUCCESS;
}

export type StaffWageReportDispatchTypes =
    | StaffWageReportStoreSuccess
    | StaffWageReportStoreError
    | StaffWageReportStoreLoading;
