import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {CalendarSummaryStats} from "../../../api/grs";
import {CALENDAR_SUMMARY_STATS_STORE} from "../actions/CalendarSummaryStatsActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";

/** Default state of the store. */
const defaultState = createDefaultStoreState<CalendarSummaryStats[]>([]);

/** Infer type of the reducer for code completion and quality */
const calendarSummaryStatsReducer = (
    state: StoreServiceData<CalendarSummaryStats[]> = defaultState,
    action: ServiceActionTypes<CalendarSummaryStats[]>
): StoreServiceData<CalendarSummaryStats[]> =>
    createReducer(state, action, CALENDAR_SUMMARY_STATS_STORE, () => showErrorToast(action.error));

export default calendarSummaryStatsReducer;
