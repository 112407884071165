import React, {useEffect, useState} from "react";
import {StaffAccessLevel, UserData} from "../../../../../api/staff";
import {useUserData} from "../../../../Hooks/useUserData";
import {useSelector} from "react-redux";
import {RootStore} from "../../../../../store/Store";
import {StaffReportResponse} from "../../../../../api/grs";
import StaffReportDonutList from "./StaffReportDonutList";

const StaffReportStats = (props: StaffReportResponse) => {
    const [selectedStaffMember, setSelectedStaffMember] = useState<string>("");
    const staffListStore = useSelector((state: RootStore) => state.staffList);

    const user = useUserData();

    useEffect(() => {
        switch (user.accessLevel) {
            case StaffAccessLevel.Staff:
            case StaffAccessLevel.DutyManager:
                const fullName = `${user.firstName} ${user.lastName}`;
                setSelectedStaffMember(fullName);
                break;
        }
    }, []);

    useEffect(() => {
        if (!staffListStore.data) return;
        const index = staffListStore.data.findIndex(
            (el: UserData) => el.username === props.staffId
        );

        if (index < 0) return;

        const staffMember = staffListStore.data[index];
        const fullName = `${staffMember.firstName} ${staffMember.lastName}`;

        setSelectedStaffMember(fullName);
    }, [props]);

    return (
        <React.Fragment>
            {props.entries.length > 0 && (
                <React.Fragment>
                    <StaffReportDonutList
                        entries={props.entries}
                        headerText={`Monthly Stats for ${selectedStaffMember}`}
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default StaffReportStats;
