import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setNavigationItems} from "../../../../store/navigation/actions/NavigationActions";
import {RootStore} from "../../../../store/Store";
import {WithServiceState} from "store-fetch-wrappers/dist";
import WageReportTable from "./Components/WageReportTable";
import {useXeroRoutes} from "../../Xero/Hooks/useXeroRoutes";

const ServiceWrapper = WithServiceState(WageReportTable);

/** Parent component for the Wage report */
const WageReport = () => {
    const dispatch = useDispatch();
    const staffWageReportStore = useSelector((state: RootStore) => state.staffWageReport);
    const {getSystemAdminRoutes} = useXeroRoutes();

    useEffect(() => {
        window.scrollTo({top: 0});
        dispatch(setNavigationItems(getSystemAdminRoutes()));
    }, []);

    return (
        <React.Fragment>
            <div className="page-container">
                <ServiceWrapper
                    loaderWheelType={"three-ring"}
                    loaderType={"overlay"}
                    showLoadingText={true}
                    {...staffWageReportStore}
                />
            </div>
        </React.Fragment>
    );
};

export default WageReport;
