import {VENUE_LIST_STORE_STATE, VenueListDispatchTypes} from "./VenueListActionTypes";
import {Dispatch} from "redux";
import GrsApiModel from "../../apiModel/GrsApiModel";
import {RootStore} from "../../Store";
import {Venue} from "../../../api/grs";
import {getDataFromServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";

/** Nullifies the venue list store. */
export const nullifyVenueListStore = () => {
    return async (dispatch: Dispatch<VenueListDispatchTypes>) => {
        dispatch({
            type: VENUE_LIST_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

/** Gets all venues from the MC DB */
export const fetchAllVenues = () => {
    return async (dispatch: Dispatch<VenueListDispatchTypes>, state: () => RootStore) => {
        try {
            await getDataFromServiceWithRedux(
                VENUE_LIST_STORE_STATE,
                dispatch,
                () => GrsApiModel.getVenueApi().getAllVenues(),
                statusCodeCallback
            );

            const venues = state().venueList.data;

            return venues ? venues : [];
        } catch (e: any) {
            dispatch({
                type: VENUE_LIST_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

/** Gets a list of venues for a specific calendar. */
export const getVenuesForCalendarId = (calendarId: number) => {
    return async (dispatch: Dispatch<VenueListDispatchTypes>, getState: () => RootStore) => {
        try {
            await getDataFromServiceWithRedux(
                VENUE_LIST_STORE_STATE,
                dispatch,
                () => GrsApiModel.getVenueApi().getVenuesForCalendarId(calendarId),
                statusCodeCallback
            );

            const data: Venue[] | undefined | null = getState().venueList.data;

            return data ? data : [];
        } catch (e: any) {
            dispatch({
                type: VENUE_LIST_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
