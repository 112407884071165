import React from "react";
import {useUserData} from "../../../../../Hooks/useUserData";
import {formatUnixToCustomFormat, formatUnixToHHmmddd} from "../../../../../../utils/momentUtils";
import {BillingType, CalendarEntry} from "../../../../../../api/grs";
import {MobileEventListItemProps} from "../Components/MobileEventListItem";
import {useDispatch} from "react-redux";
import {DateRange} from "../../../../../../utils/filterUtils";
import {getMobileCalendarViewItems} from "../../../../../../store/mobileCalendar/actions/MobileCalendarActions";
import {useAvailableShiftsInfoUtils} from "../../../../AvailableShifts/Calendar/Hooks/useAvailableShiftsInfoUtils";
import {isCalendarEntry} from "../../../../../../store/calendarEntry/actions/CalendarEntryActions";
import {StaffAccessLevel} from "../../../../../../api/staff";
import {getTotalAssignments} from "../../../../AdminCalendarEntry/Helpers/calenderEntryHelpers";
import moment from "moment";
import {usePageUrl} from "../../../../../Hooks/usePageUrl";
import {getViewPathForDutyManagerForGlobalCalendarMobile} from "../../../Helpers/globalCalendarHelpers";
import {useQuery} from "../../../../../Hooks/useQuery";
import {MobileCalendarQueryString} from "./useGlobalCalendarMobileFilters";
import {useHistory} from "react-router-dom";

export function useMobileEventListItem({calendarEventItem, rawEntry}: MobileEventListItemProps) {
    const user = useUserData();
    const dispatch = useDispatch();
    const {showSection, showGroup} = useAvailableShiftsInfoUtils();
    const {fullPath} = usePageUrl();
    const query = useQuery();
    const history = useHistory();

    function onStaffCalendarItemClicked() {
        //Only duty managers can click into events
        if (user.accessLevel !== StaffAccessLevel.DutyManager) return;
        //If it isn't calendar entry, we don't want to work with this.
        if (!isCalendarEntry(rawEntry)) return;

        const canView = canDutyManagerViewAssignment(rawEntry, user.username);

        if (!canView) return;

        const day = query.get(MobileCalendarQueryString.day);

        const path = getViewPathForDutyManagerForGlobalCalendarMobile(
            rawEntry.calendarId,
            rawEntry.id,
            day ? +day : moment().startOf("day").unix()
        );
        localStorage.setItem("calendar_redirect", fullPath);

        history.push(path);
    }

    function canDutyManagerViewAssignment(entry: CalendarEntry, dutyManagerId: string) {
        const assignments = getTotalAssignments(entry);

        if (assignments.length === 0) return false;

        for (const assignment of assignments) {
            if (!assignment.staffMember) continue;

            if (assignment.staffMember.staffId !== dutyManagerId) continue;

            return true;
        }

        return false;
    }

    function getDateText(start: Date, end: Date) {
        const startMoment = moment(start);
        const endMoment = moment(end);

        const endOfDayStartMoment = startMoment.clone().endOf("day");
        const startOfDayEndMoment = endMoment.clone().startOf("day");

        const startString = formatUnixToCustomFormat(startMoment.unix(), "DD");
        const endString = formatUnixToCustomFormat(endMoment.unix(), "DD");

        // Event straddles days
        if (startOfDayEndMoment.unix() > endOfDayStartMoment.unix()) {
            return `${startString}-${endString}`;
        }

        return startString;
    }

    function getAssignmentGroupName(): React.ReactNode {
        if (!user.username.length) return;

        const found = calendarEventItem.assignments.find(
            (item) => item.staffMember?.staffId === user.username
        );
        if (!found) return;
        return (
            <div className="mobile-calendar-event-item-detail-item-group-name">
                {found.groupName}
            </div>
        );
    }

    function getAvailableGroupSpaceCount() {
        //Is calendar entry, we don't want to work with this.
        if (isCalendarEntry(rawEntry)) return;

        const sections = rawEntry.requiredStaff.filter((section) => showSection(section));
        const groups = sections
            .map((section) => section.groupings.filter((group) => showGroup(group)))
            .flat();
        return (
            <div className="mobile-calendar-event-item-detail-item-group-name">
                Available Groups: {groups.length}
            </div>
        );
    }

    function getTimeText() {
        if (
            calendarEventItem.depotTime &&
            calendarEventItem.billingType &&
            calendarEventItem.billingType === BillingType.Daily
        ) {
            return formatUnixToHHmmddd(calendarEventItem.depotTime);
        }
        return calendarEventItem.timeText;
    }

    function getLatestShifts(dateRange: DateRange) {
        dispatch(getMobileCalendarViewItems(dateRange, user));
    }

    return {
        getAssignmentGroupName,
        getTimeText,
        getLatestShifts,
        getAvailableGroupSpaceCount,
        onStaffCalendarItemClicked,
        getDateText
    };
}
