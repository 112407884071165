import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../store/Store";
import {UserData} from "../../../api/staff";
import {DDProps} from "../../Dropdown/Helpers/dropdownUtils";
import Select, {MultiValue} from "react-select";
import {
    getSmsFiltersFromStaffList,
    getStaffMembersFromSelectedTags,
    processTag
} from "../Helpers/smsHelpers";
import {setSMSStaffMembers} from "../../../store/sms/actions/SMSActions";
import FormRowWithTooltip from "../../Form/FormRowWithTooltip";
import {IconType} from "../../Icon/Icon";

const SMSFilters = () => {
    const staffList = useSelector((state: RootStore) => state.staffList.data || []);
    const [filterOptions, setFilterOptions] = useState<DDProps[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<MultiValue<DDProps>>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        processStaffListIncoming(staffList);
    }, []);

    useEffect(() => {
        processStaffListIncoming(staffList);
    }, [staffList]);

    const processStaffListIncoming = (incomingStaffList: UserData[]) => {
        const tags = getSmsFiltersFromStaffList(incomingStaffList);

        const options = sortTagsToDropdownProps(tags);

        setFilterOptions(options);
    };

    const sortTagsToDropdownProps = (tags: string[]): DDProps[] => {
        return tags.map((tag) => {
            return {
                label: processTag(tag),
                value: tag
            };
        });
    };

    const handleFiltersSelected = (newValue: MultiValue<DDProps>) => {
        if (staffList.length === 0) return;
        setSelectedOptions(newValue);

        const selectedFilters = getTagsFromSelectedOptions(newValue);
        dispatch(setSMSStaffMembers(getStaffMembersFromSelectedTags(selectedFilters, staffList)));
    };

    const getTagsFromSelectedOptions = (options: MultiValue<DDProps>): string[] => {
        if (!options) {
            return [];
        }
        if (options.length === 0) {
            return [];
        }

        return options.map((opt) => opt.value.toString());
    };

    return (
        <React.Fragment>
            <FormRowWithTooltip
                tooltipText={`When ONE filter is selected, the filter will operate on an OR basis. Meaning that if "PARAMEDIC" is selected, 
                    All paramedics will be included! When TWO or MORE filters are selected, the filter will operate on an AND basis. 
                    Meaning that if "PARAMEDIC" and "NORTH" have been selected. Staff members that have both matching Paramedic tags and North
                    tags will be selected.`}
                icon={IconType.Question}
                rowName={"Filters"}
                columnDetailClassName={"pl-0 pr-0"}
            >
                <Select
                    className="search-bar"
                    classNamePrefix="select-options"
                    options={filterOptions}
                    onChange={handleFiltersSelected}
                    isSearchable={false}
                    value={selectedOptions}
                    isMulti={true}
                    noOptionsMessage={() => "No Filters Found"}
                />
            </FormRowWithTooltip>
        </React.Fragment>
    );
};

export default SMSFilters;
