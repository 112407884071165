import {useEffect, useState} from "react";
import {DropdownOption, ServiceDropdownCore} from "../../Types/types";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../store/Store";

export function useDropdownCore<T>({
    storeName,
    reduxDispatchFetch,
    reduxDispatchClear,
    storeKeyValue,
    storeKeyLabel
}: ServiceDropdownCore) {
    const [options, setOptions] = useState<DropdownOption[]>([]);
    const reduxStore = useSelector((state: RootStore) => state[storeName]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (reduxDispatchFetch) {
            dispatch(reduxDispatchFetch());
        }
        return () => {
            if (reduxDispatchClear) {
                dispatch(reduxDispatchClear());
            }
        };
    }, []);

    useEffect(() => {
        if (!reduxStore) return;

        const {data} = reduxStore;
        if (!Array.isArray(data)) return;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        processDataIncoming(data);
    }, [reduxStore]);

    function processDataIncoming(data: T[]) {
        const generated = getDropdownOptionsFromReduxStore(data);
        setOptions(generated);
    }

    function getDropdownOptionsFromReduxStore(list: T[]): DropdownOption[] {
        return list.map((item) => {
            return {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                value: item[storeKeyValue],
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                label: item[storeKeyLabel]
            };
        });
    }

    return {
        options,
        reduxStore
    };
}
