import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {SMS_STORE, SMSFormDetails} from "../actions/SMSActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<SMSFormDetails>({
    staffMembers: [],
    bodyText: "",
    sendToEventsStaff: true
});

const smsReducer = (
    state: StoreServiceData<SMSFormDetails> = defaultState,
    action: ServiceActionTypes<SMSFormDetails>
) => createReducer(state, action, SMS_STORE, () => showErrorToast(action.error));

export default smsReducer;
