import React from "react";
import HorizontalCalendar from "../../../../../modules/HorizontalDatePicker/HorizontalCalendar";
import {Moment} from "moment";
import {useGlobalCalendarMobileFilters} from "./Hooks/useGlobalCalendarMobileFilters";

function GlobalCalendarMobileFilters({onDateSelected}: Props) {
    const {currentDate} = useGlobalCalendarMobileFilters();
    return (
        <React.Fragment>
            {currentDate && (
                <HorizontalCalendar onSelectDate={onDateSelected} currentDate={currentDate} />
            )}
        </React.Fragment>
    );
}

export default GlobalCalendarMobileFilters;

interface Props {
    onDateSelected: (date: Moment) => void;
}
