import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const RESEND_STORE = createStoreState("resend");

interface ResendStoreLoading extends StoreServiceData<string> {
    type: typeof RESEND_STORE.LOADING;
}
interface ResendStoreError extends StoreServiceData<string> {
    type: typeof RESEND_STORE.ERROR;
}
interface ResendStoreSuccess extends StoreServiceData<string> {
    type: typeof RESEND_STORE.SUCCESS;
}

export type ResendDispatchTypes = ResendStoreLoading | ResendStoreError | ResendStoreSuccess;
