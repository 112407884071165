import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {EventsReportResponse} from "../../../api/grs";
import {EVENT_REPORT_STORE} from "../actions/EventReportActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";

/** Default state of the store. */
const defaultState = createDefaultStoreState<EventsReportResponse>(null);

/** Infer type of the reducer for code completion and quality */
const eventReportReducer = (
    state: StoreServiceData<EventsReportResponse> = defaultState,
    action: ServiceActionTypes<EventsReportResponse>
): StoreServiceData<EventsReportResponse> =>
    createReducer(state, action, EVENT_REPORT_STORE, () => showErrorToast(action.error));

export default eventReportReducer;
