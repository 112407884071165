import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {DUTY_MANAGER_REPORT_PAGED_STORE_STATE} from "../actions/DutyManagerReportPagedActionTypes";
import {DutyManagerReportPagedResponse} from "../../../api/grs";
import {showErrorToast} from "../../../utils/toastUtils";

/** Default state of the store. */
const defaultState = createDefaultStoreState<DutyManagerReportPagedResponse>(
    generateBlankDutyManagerReportPagedResponse()
);

/** Infer type of the reducer for code completion and quality */
const dutyManagerReportPagedReducer = (
    state: StoreServiceData<DutyManagerReportPagedResponse> = defaultState,
    action: ServiceActionTypes<DutyManagerReportPagedResponse>
): StoreServiceData<DutyManagerReportPagedResponse> =>
    createReducer(state, action, DUTY_MANAGER_REPORT_PAGED_STORE_STATE, () =>
        showErrorToast(action.error)
    );

export default dutyManagerReportPagedReducer;

export function generateBlankDutyManagerReportPagedResponse(): DutyManagerReportPagedResponse {
    return {
        entries: [],
        numPerPage: 0,
        pageNum: 0,
        totalEntries: 0
    };
}
