import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {StaffBlockTemplateListEntry} from "../../../api/grs";

export const GLOBAL_STAFF_BLOCK_LIST_STORE = createStoreState("global_staff_block_list");

interface GlobalStaffBlockListLoading extends StoreServiceData<StaffBlockTemplateListEntry[]> {
    type: typeof GLOBAL_STAFF_BLOCK_LIST_STORE.LOADING;
}
interface GlobalStaffBlockListError extends StoreServiceData<StaffBlockTemplateListEntry[]> {
    type: typeof GLOBAL_STAFF_BLOCK_LIST_STORE.ERROR;
}
interface GlobalStaffBlockListSuccess extends StoreServiceData<StaffBlockTemplateListEntry[]> {
    type: typeof GLOBAL_STAFF_BLOCK_LIST_STORE.SUCCESS;
}

export type GlobalStaffBlockListDispatchTypes =
    | GlobalStaffBlockListLoading
    | GlobalStaffBlockListError
    | GlobalStaffBlockListSuccess;
