import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {REPEAT_ENTRY_STORE} from "../actions/RepeatEntryActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<string>(null);

const repeatEntryReducer = (
    state: StoreServiceData<string> = defaultState,
    action: ServiceActionTypes<string>
) => createReducer(state, action, REPEAT_ENTRY_STORE, () => showErrorToast(action.error));

export default repeatEntryReducer;
