import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {Venue} from "../../../api/grs";

export const VENUE_LIST_STORE_STATE = createStoreState("venue_list");

interface VenueListStoreLoading extends StoreServiceData<Venue[]> {
    type: typeof VENUE_LIST_STORE_STATE.LOADING;
}
interface VenueListStoreError extends StoreServiceData<Venue[]> {
    type: typeof VENUE_LIST_STORE_STATE.ERROR;
}
interface VenueListStoreSuccess extends StoreServiceData<Venue[]> {
    type: typeof VENUE_LIST_STORE_STATE.SUCCESS;
}

export type VenueListDispatchTypes =
    | VenueListStoreError
    | VenueListStoreSuccess
    | VenueListStoreLoading;
