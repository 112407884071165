import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {AvailableShiftCalendarEntry} from "../../../api/grs";

export const AVAILABLE_SHIFTS_ENTRY_LIST_STORE_STATE = createStoreState(
    "available_shifts_entry_list"
);

interface AvailableShiftsEntryListStoreLoading
    extends StoreServiceData<AvailableShiftCalendarEntry[]> {
    type: typeof AVAILABLE_SHIFTS_ENTRY_LIST_STORE_STATE.LOADING;
}

interface AvailableShiftsEntryListStoreError
    extends StoreServiceData<AvailableShiftCalendarEntry[]> {
    type: typeof AVAILABLE_SHIFTS_ENTRY_LIST_STORE_STATE.ERROR;
}

interface AvailableShiftsEntryListStoreSuccess
    extends StoreServiceData<AvailableShiftCalendarEntry[]> {
    type: typeof AVAILABLE_SHIFTS_ENTRY_LIST_STORE_STATE.SUCCESS;
}

export type AvailableShiftsEntryListDispatchTypes =
    | AvailableShiftsEntryListStoreLoading
    | AvailableShiftsEntryListStoreError
    | AvailableShiftsEntryListStoreSuccess;
