import React, {useEffect} from "react";
import {WithServiceState} from "store-fetch-wrappers/dist";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../../store/Store";
import {setNavigationItems} from "../../../../../store/navigation/actions/NavigationActions";
import DutyManagerReportPagedTable from "./Components/DutyManagerReportPagedTable";
import {useXeroRoutes} from "../../../Xero/Hooks/useXeroRoutes";

const ReportListingServiceWrapper = WithServiceState(DutyManagerReportPagedTable);

const DutyManagerReportPaged = () => {
    const dispatch = useDispatch();
    const dutyManagerReportPagedStore = useSelector(
        (state: RootStore) => state.dutyManagerReportPaged
    );
    const {getSystemAdminRoutes} = useXeroRoutes();

    useEffect(() => {
        window.scrollTo({top: 0});
        dispatch(setNavigationItems(getSystemAdminRoutes()));
    }, []);

    return (
        <React.Fragment>
            <div className="page-container">
                <ReportListingServiceWrapper
                    showLoadingText={true}
                    placeholderBlockCount={1}
                    loaderType={"overlay"}
                    loaderWheelType={"three-ring"}
                    {...dutyManagerReportPagedStore}
                />
            </div>
        </React.Fragment>
    );
};

export default DutyManagerReportPaged;
