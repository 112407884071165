import React from "react";
import DatePicker from "react-datepicker";
import {GrsChangeEvent} from "../../../../../../utils/filterUtils";
import {CalendarEntryListRequest} from "../../../../../../api/grs";
import DatePickerInputButton from "../../../../../Button/DatePickerInputButton";
import CalendarGroupDropdown from "../../../../../Dropdown/Components/CalendarGroupDropdown";
import AuthAmI from "../../../../../AuthAmI/AuthAmI";
import {StaffAccessLevel} from "../../../../../../api/staff";
import {useGlobalCalendarMonthFilters} from "./Hooks/useGlobalCalendarMonthFilters";

const GlobalCalendarMonthFilters = (props: GrsChangeEvent<CalendarEntryListRequest>) => {
    const {startDate, groupId, onDateChanged, onGroupChanged} =
        useGlobalCalendarMonthFilters(props);

    return (
        <React.Fragment>
            <table className="filters-table mt-3">
                <tbody className="filters-lg">
                    <tr>
                        <th className="filter-heading w-50 pl-3 pr-3">Date</th>
                        <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                            <th className="filter-heading w-50 pl-3 pr-3">Groups</th>
                        </AuthAmI>
                    </tr>
                    <tr>
                        <td className="filter-heading w-50 pl-3 pr-3 pb-2">
                            {startDate && (
                                <DatePicker
                                    selected={startDate.toDate()}
                                    onChange={onDateChanged}
                                    showMonthYearPicker
                                    dateFormat="MMMM yyyy"
                                    portalId="root-portal"
                                    customInput={<DatePickerInputButton />}
                                />
                            )}
                        </td>
                        <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                            <td className="filter-heading w-50 pl-3 pr-3 pb-2">
                                <CalendarGroupDropdown
                                    onChange={onGroupChanged}
                                    groupId={groupId}
                                />
                            </td>
                        </AuthAmI>
                    </tr>
                </tbody>
                <tbody className="filters-md">
                    <tr>
                        <th className="filter-heading w-50 pl-3 pr-3">Date</th>
                        <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                            <th className="filter-heading w-50 pl-3 pr-3">Groups</th>
                        </AuthAmI>
                    </tr>
                    <tr>
                        <td className="filter-heading w-50 pl-3 pr-3 pb-2">
                            {startDate && (
                                <React.Fragment>
                                    <DatePicker
                                        selected={startDate.toDate()}
                                        onChange={onDateChanged}
                                        showMonthYearPicker
                                        dateFormat="MMMM yyyy"
                                        portalId="root-portal"
                                        customInput={<DatePickerInputButton />}
                                    />
                                </React.Fragment>
                            )}
                        </td>
                        <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                            <td className="filter-heading w-50 pl-3 pr-3 pb-2">
                                <CalendarGroupDropdown
                                    onChange={onGroupChanged}
                                    groupId={groupId}
                                />
                            </td>
                        </AuthAmI>
                    </tr>
                </tbody>
                <tbody className="filters-sm">
                    <tr>
                        <th className="filter-heading w-100 pl-3 pr-3">Date</th>
                    </tr>
                    <tr>
                        <td className="filter-heading w-100 pl-3 pr-3 pb-2">
                            {startDate && (
                                <DatePicker
                                    selected={startDate.toDate()}
                                    onChange={onDateChanged}
                                    showMonthYearPicker
                                    dateFormat="MMMM yyyy"
                                    portalId="root-portal"
                                    customInput={<DatePickerInputButton />}
                                />
                            )}
                        </td>
                    </tr>
                    <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                        <tr>
                            <th className="filter-heading w-50 pl-3 pr-3">Groups</th>
                        </tr>
                        <tr>
                            <td className="filter-heading w-50 pl-3 pr-3 pb-2">
                                <CalendarGroupDropdown
                                    onChange={onGroupChanged}
                                    groupId={groupId}
                                />
                            </td>
                        </tr>
                    </AuthAmI>
                </tbody>
            </table>
        </React.Fragment>
    );
};

export default GlobalCalendarMonthFilters;
