import React, {useEffect} from "react";
import {useUserData} from "../../Hooks/useUserData";
import {useDispatch, useSelector} from "react-redux";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {getNavItemsForNotificationPage} from "./Helpers/notificationsHelper";
import NotificationListTable from "./Components/NotificationListTable";
import {WithServiceState} from "store-fetch-wrappers/dist";
import {RootStore} from "../../../store/Store";
import {getPendingNotifications} from "../../../store/pendingNotifications/actions/PendingNotificationsActions";
import {usePlatform} from "../../Hooks/usePlatform";
const ServiceWrapper = WithServiceState(NotificationListTable);

const PendingNotifications = () => {
    const user = useUserData();
    const dispatch = useDispatch();
    const platform = usePlatform();
    const pendingActionsStore = useSelector((state: RootStore) => state.pendingNotifications);

    useEffect(() => {
        if (user.username.length === 0) return;

        dispatch(setNavigationItems(getNavItemsForNotificationPage(user, platform)));
        dispatch(getPendingNotifications());
    }, [user]);
    return (
        <React.Fragment>
            <ServiceWrapper
                showLoadingText={true}
                placeholderBlockCount={1}
                loaderType={"overlay"}
                loaderWheelType={"three-ring"}
                {...pendingActionsStore}
            />
        </React.Fragment>
    );
};

export default PendingNotifications;
