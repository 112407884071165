import React from "react";
import {EventCalendarEntry} from "../../../../../../../api/grs";
import FormRow from "../../../../../../Form/FormRow";
import {formatUnixToHHmmddd} from "../../../../../../../utils/momentUtils";

const ReadOnlyEventCalendarTimes = (props: EventCalendarEntry) => (
    <React.Fragment>
        <div className="row ml-0 mr-0 mt-3">
            <div className="col-sm-6 pl-0 calendar-entry-input-override">
                <FormRow rowName={"Depot Time"}>
                    <p className="mb-0">{formatUnixToHHmmddd(props.depotTime)}</p>
                </FormRow>
            </div>
            <div className="col-sm-6 pr-0 calendar-entry-input-override">
                <FormRow rowName={"Venue Time"}>
                    <p className="mb-0">{formatUnixToHHmmddd(props.venueTime)}</p>
                </FormRow>
            </div>
        </div>
    </React.Fragment>
);

export default ReadOnlyEventCalendarTimes;
