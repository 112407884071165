import {useSelector} from "react-redux";
import {RootStore} from "../../../store/Store";
import {useEffect, useState} from "react";
import {DDProps} from "../Helpers/dropdownUtils";
import {CalendarGroup} from "../../../api/grs";
import {SingleValue} from "react-select";

export function useCalendarGroupDropdown(
    onChange: (group: CalendarGroup | undefined) => void,
    groupId?: number
) {
    const calendarGroupListStore = useSelector((state: RootStore) => state.calendarGroupList);
    const [options, setOptions] = useState<DDProps[]>([]);
    const [selectedOption, setSelectedOption] = useState<SingleValue<DDProps>>();

    useEffect(() => {
        if (calendarGroupListStore.loading) return;
        if (!calendarGroupListStore.data) return;

        processCalendarGroupIncoming(calendarGroupListStore.data);
    }, [calendarGroupListStore]);

    useEffect(() => {
        if (!calendarGroupListStore.data) {
            setSelectedOption(undefined);
            onChange(undefined);
            return;
        }
        if (!groupId) {
            setSelectedOption(undefined);
            onChange(undefined);
            return;
        }
        const group = getSelectedOption(groupId, calendarGroupListStore.data);

        const opt = group ? {label: group.name, value: group.id} : undefined;

        setSelectedOption(opt);
        onChange(group);
    }, [groupId, calendarGroupListStore]);

    function processCalendarGroupIncoming(entities: CalendarGroup[]) {
        setOptions(toDDProps(entities));
    }

    function toDDProps(entities: CalendarGroup[]): DDProps[] {
        return entities.map((item) => {
            return {
                value: item.id,
                label: item.name
            };
        });
    }

    function getSelectedOption(id: number, groups: CalendarGroup[]): CalendarGroup | undefined {
        return groups.find((it) => it.id === id);
    }

    function handleFiltersChanged(newValue: SingleValue<DDProps>) {
        if (!calendarGroupListStore.data) return;
        if (!newValue) {
            setSelectedOption(newValue);
            onChange(undefined);
            return;
        }
        setSelectedOption(newValue);
        const group = calendarGroupListStore.data.find((it) => it.id === +newValue.value);
        onChange(group);
    }

    return {
        store: calendarGroupListStore,
        options,
        value: selectedOption,
        handleFiltersChanged
    };
}
