import React from "react";
import {
    BillingType,
    CalendarTypeEnum,
    EventCalendarEntry,
    FrontlineCalendarEntry
} from "../../../../../../../api/grs";
import {useSelector} from "react-redux";
import {RootStore} from "../../../../../../../store/Store";
import ReadOnlyEntryActivityState from "../ReadOnlyEntryActivityState";
import ReadOnlyEntryTimeDetails from "../ReadOnlyEntryTimeDetails";
import ReadOnlyEventCalendarTimes from "./ReadOnlyEventCalendarTimes";
import {isEventCalendarEntry} from "../../../../../../../store/calendarEntry/actions/CalendarEntryActions";
import ReadOnlyEntryNotes from "../ReadOnlyEntryNotes";

const ReadOnlyEntryEventDetails = (props: EventCalendarEntry | FrontlineCalendarEntry) => {
    const calendar = useSelector((state: RootStore) => state.calendar.data);

    const getBillingType = () => {
        if (props.billingType) {
            return props.billingType;
        }

        return calendar?.billingType || BillingType.Hourly;
    };
    return (
        <React.Fragment>
            <ReadOnlyEntryActivityState {...props} />
            {getBillingType() === BillingType.Hourly && <ReadOnlyEntryTimeDetails {...props} />}
            {calendar && calendar.calendarType === CalendarTypeEnum.Event && (
                <React.Fragment>
                    {isEventCalendarEntry(props) && <ReadOnlyEventCalendarTimes {...props} />}
                </React.Fragment>
            )}
            <ReadOnlyEntryNotes {...props} />
        </React.Fragment>
    );
};

export default ReadOnlyEntryEventDetails;
