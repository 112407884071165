import {CalendarEntryListRequest, CalendarGroup} from "../../../../api/grs";
import {useHistory} from "react-router-dom";
import moment from "moment";

export function useBaseCalendarFilters() {
    const history = useHistory();

    function getCalendarGroupQueryStrings(group: CalendarGroup | undefined) {
        if (!group) return [];

        const searchStrings: string[] = [];

        searchStrings.push(`calendarGroup=${group.id}`);

        const str = Object.keys(group)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            .map((key) => key + "=" + group[key])
            .join("&");
        searchStrings.push(str);

        return searchStrings;
    }

    function getIdsFromQueryString(value?: string): number[] | undefined {
        if (!value) return;

        return value.split(",").map((it) => +it);
    }

    function buildRequestUrl(
        incRequest: CalendarEntryListRequest,
        incGroup: CalendarGroup | undefined,
        isWeekView: boolean
    ) {
        const searchStrings: string[] = [];
        //Start date is set 8 days prior to get any events happening in the windows outside the month on the ui.
        //Here, we reset it

        searchStrings.push(
            isWeekView ? getWeekQueryString(incRequest) : getMonthQueryString(incRequest)
        );

        searchStrings.push(...getCalendarGroupQueryStrings(incGroup));

        searchStrings.push(`isGlobalCalendar=true`);

        const flattened = searchStrings.join("&");

        history.push({
            search: `?${flattened}`
        });
    }

    function getMonthQueryString(incRequest: CalendarEntryListRequest) {
        const fixedMonth = moment
            .unix(incRequest.startDate)
            .endOf("month")
            .add(1, "day")
            .startOf("day")
            .unix();

        return `month=${fixedMonth}&isWeekView=false`;
    }

    function getWeekQueryString(incRequest: CalendarEntryListRequest) {
        return `week=${incRequest.startDate}&isWeekView=true`;
    }

    return {
        getIdsFromQueryString,
        buildRequestUrl
    };
}
