import React from "react";
import DatePickerInputButton from "../../../../Button/DatePickerInputButton";
import DatePicker from "react-datepicker";
import moment, {Moment} from "moment";
import {AutoInvoiceListRequest} from "../../../../../api/grs";
import {pagedRequestConfig} from "../../../../Filters/helpers/filterHelpers";

function XeroInvoiceFilters({request, onRequestChanged}: Props) {
    function getMomentFromMonthYear(month: number, year: number): Moment {
        return moment().clone().set({year, month});
    }
    return (
        <React.Fragment>
            <div className="filter-inner-container-wrapper">
                {request && (
                    <div className="filter-item">
                        <h6>Date</h6>
                        <DatePicker
                            selected={getMomentFromMonthYear(
                                request.month - 1,
                                request.year
                            ).toDate()}
                            onChange={(newDate) => {
                                if (!newDate) return;
                                const momentStamp = moment(newDate);
                                const newReq: AutoInvoiceListRequest = {
                                    month: momentStamp.month() + 1,
                                    year: momentStamp.year(),
                                    pageNum: 0,
                                    numPerPage: pagedRequestConfig.resultsPerPage
                                };
                                onRequestChanged(newReq);
                            }}
                            showMonthYearPicker
                            dateFormat="MMMM yyyy"
                            portalId="root-portal"
                            customInput={<DatePickerInputButton />}
                        />
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}

export default XeroInvoiceFilters;

interface Props {
    request?: AutoInvoiceListRequest;
    onRequestChanged: (newRequest: AutoInvoiceListRequest) => void;
}
