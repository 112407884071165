import React from "react";
import FormHeader from "../../../../Form/FormHeader";
import {PulseTable} from "pulse_table";
import Tooltip from "../../../../Tooltip/Tooltip";
import Icon, {IconType} from "../../../../Icon/Icon";
import {CalendarEntry, UserInterest} from "../../../../../api/grs";

import {AvailableShiftItemProps} from "../Admin/ShiftsOverviewListTable";
import AcceptRegisterRequest from "../Actions/AcceptRegisterRequest";

const InterestedStaffMembersTable = (props: InterestedStaffMembersTableProps) => {
    const tooltipText = () => {
        return (
            "Note: If user cannot be assigned to their chosen group of interest, " +
            "system will try to place them in a group that is suited to their clinical grade. " +
            "If no slots are available, user will not be able to be assigned. This behaviour is " +
            "excluded for First Responders."
        );
    };

    return (
        <div className="mt-4">
            {props.location === "shifts-list" && (
                <FormHeader headerName={"Interested staff members"} />
            )}
            <PulseTable
                items={toInterestedTableRow(props)}
                headers={{
                    interestedMember: "Interested Staff Member",
                    section: "Section",
                    group: "Group",
                    action: "Actions"
                }}
                customRenderers={{
                    group: (item: InterestedTableRow) => (
                        <React.Fragment>
                            <p className="mb-0">
                                {item.group}{" "}
                                <Tooltip
                                    tooltipText={tooltipText()}
                                    size={"lg"}
                                    place={"left"}
                                    rootElement="span"
                                >
                                    <Icon
                                        rootElement="Span"
                                        className="cursor-pointer"
                                        icon={IconType.Question}
                                        size={"Large"}
                                    />
                                </Tooltip>
                            </p>
                        </React.Fragment>
                    ),
                    action: (item: InterestedTableRow) => {
                        return (
                            <React.Fragment>
                                <AcceptRegisterRequest {...item.action} location={props.location} />
                            </React.Fragment>
                        );
                    }
                }}
                noItemsSection={
                    <div className="row ml-0 mr-0 fadeIn">
                        <div className="col">
                            <h5 className="text-center p-3">
                                There are no staff members interested in the shift.
                            </h5>
                        </div>
                    </div>
                }
            />
        </div>
    );
};

export default InterestedStaffMembersTable;

interface InterestedTableRow {
    interestedMember: string;
    section: string;
    group: string;
    action: InterestedActionItemProp;
}

export interface InterestedActionItemProp {
    entry: CalendarEntry;
    interest: UserInterest;
}

function toInterestedTableRow(props: AvailableShiftItemProps): InterestedTableRow[] {
    const filteredInterest = props.userInterest.filter((item) => item.entryId === props.entry.id);
    return filteredInterest.map((item) => {
        return {
            interestedMember: item.user.staffName,
            section: item.section.name,
            group: item.group.name,
            action: {
                entry: props.entry,
                interest: item
            }
        };
    });
}

export interface InterestedStaffMembersTableProps extends AvailableShiftItemProps {
    location: "entry" | "shifts-list";
}
