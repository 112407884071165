import {routeNames} from "../../../../../Navigation/routeNames";
import {DutyManagerReportPayload} from "../../../../../../store/dutyManagerReport/actions/DutyManagerReportActionTypes";
import {FormErrors} from "../../../../../../utils/formUtils";
import {DutyManagerReportListing} from "../../../../../../api/grs";
import {StaffAccessLevel, UserData} from "../../../../../../api/staff";
import {NavigationItem} from "../../../../../../store/navigation/actions/NavigationActionTypes";
import {CalendarMonthView} from "../../../../AdminCalendar/Helpers/calendarUtils";
import {getPathToGlobalStaffCalendar} from "../../../../GlobalCalendar/Helpers/globalCalendarHelpers";
import {McConfig} from "../../../../../../McConfigPlugin";
import {getUserAccessLevel} from "../../../../../../utils/userDataUtils";

export function getLinkToCreateDutyManagerForm(entryId: number, venueId: number) {
    return `${routeNames.createDutyManagerReport.path}?calendarEntryId=${entryId}&venueId=${venueId}`;
}

export function validateDutyManagerForm(reportFormPayload: DutyManagerReportPayload): FormErrors {
    let valid = true;
    const messages: string[] = [];
    for (const vehicle of reportFormPayload.vehicles) {
        //If a field is invalid, find it, set the form to be invalid. and kill the search
        if (vehicle.vehicleRegistration.length < 3) {
            valid = false;
            messages.push("One or more Vehicle registrations are invalid");
            break;
        }
    }

    return {
        valid,
        messages
    };
}

export function getPathToEditDutyManagerReportRoute(report: DutyManagerReportListing) {
    return `${routeNames.editDutyManagerReport.path}/${report.id}?calendarEntryId=${report.calendarEntryId}`;
}

export function getPathToViewDutyManagerReportRoute(report: DutyManagerReportListing) {
    return `${routeNames.viewDutyManagerReport.path}/${report.id}?calendarEntryId=${report.calendarEntryId}`;
}

export function getNavItemsForViewDutyManagerRoute(
    user: UserData,
    currentPath: string,
    redirectPath: string,
    query: URLSearchParams,
    config: McConfig
): NavigationItem[] {
    const accessLevel = getUserAccessLevel(user, config);
    switch (accessLevel) {
        case StaffAccessLevel.DutyManager:
            return [
                {
                    name: CalendarMonthView.Staff,
                    path: getPathToGlobalStaffCalendar(CalendarMonthView.Staff, query)
                },
                {
                    name: routeNames.readOnlyEntry.name,
                    path: redirectPath
                },
                {
                    name: routeNames.viewDutyManagerReport.name,
                    path: currentPath
                }
            ];
        case StaffAccessLevel.SystemAdministrator:
            return [
                {
                    name: routeNames.groupRosteringSystem.name,
                    path: routeNames.groupRosteringSystem.path
                },
                {
                    name: routeNames.dutyManagerReportList.name,
                    path: routeNames.dutyManagerReportList.path
                },
                {
                    name: routeNames.viewDutyManagerReport.name,
                    path: currentPath
                }
            ];
        default:
            return [];
    }
}
