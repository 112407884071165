import {Dispatch} from "redux";
import {
    REGISTER_INTEREST_STORE,
    RegisterInterestDispatchTypes
} from "./RegisterInterestActionTypes";
import GrsApiModel from "../../../apiModel/GrsApiModel";
import {
    DeleteUserInterestRequest,
    LogUserInterestReserve,
    UserInterest,
    UserInterestShiftRequest
} from "../../../../api/grs";
import {
    deleteDataFromServiceWithRedux,
    getDataFromServiceWithRedux,
    postDataToServiceWithRedux
} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../../helpers/storeHelpers";

export const nullifyRegisterInterestActionStore = () => {
    return async (dispatch: Dispatch<RegisterInterestDispatchTypes>) => {
        dispatch({
            type: REGISTER_INTEREST_STORE.SUCCESS,
            data: null,
            error: null,
            loading: false
        });
    };
};

export const registerUserShiftInterest = (args: UserInterestShiftRequest) => {
    return async (dispatch: Dispatch<RegisterInterestDispatchTypes>) => {
        try {
            return await getDataFromServiceWithRedux(
                REGISTER_INTEREST_STORE,
                dispatch,
                () => GrsApiModel.getCalendarApi().registerShiftInterest(args),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: REGISTER_INTEREST_STORE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};

export const registerUserInterest = (args: UserInterest) => {
    return async (dispatch: Dispatch<RegisterInterestDispatchTypes>) => {
        try {
            return await postDataToServiceWithRedux(
                REGISTER_INTEREST_STORE,
                dispatch,
                () => GrsApiModel.getCalendarApi().registerInterest(args),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: REGISTER_INTEREST_STORE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};

export const deleteUserInterest = (args: DeleteUserInterestRequest) => {
    return async (dispatch: Dispatch<RegisterInterestDispatchTypes>) => {
        try {
            return await deleteDataFromServiceWithRedux(
                REGISTER_INTEREST_STORE,
                dispatch,
                () => GrsApiModel.getCalendarApi().deleteInterest(args),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: REGISTER_INTEREST_STORE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};

export const reserveUserInterest = (args: LogUserInterestReserve) => {
    return async (dispatch: Dispatch<RegisterInterestDispatchTypes>) => {
        try {
            return await postDataToServiceWithRedux(
                REGISTER_INTEREST_STORE,
                dispatch,
                () => GrsApiModel.getCalendarApi().setInterestAsReserve(args),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: REGISTER_INTEREST_STORE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};
