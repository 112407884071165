import React from "react";
import {CalendarSummaryStats} from "../../../../../api/grs";
import EventCalendarCard from "./EventCalendarCard";
import {toArray} from "../../../../../utils/sortingUtils";

const EventCalendarCardContainer = (props: CalendarSummaryStats[]) => {
    return (
        <React.Fragment>
            <div className="group-rostering-system-wrapper">
                {toArray(props).map((item, index) => {
                    return <EventCalendarCard {...item} key={index} />;
                })}
            </div>
        </React.Fragment>
    );
};

export default EventCalendarCardContainer;
