import React from "react";

function InvoiceLinesTableRow({location, accountCode, date, shiftDetails, billingType}: Props) {
    return (
        <React.Fragment>
            <tr className="expanding-table-inner-table-row">
                <td className="expanding-table-inner-table-row-item" valign={"middle"} colSpan={1}>
                    {location}
                </td>
                <td className="expanding-table-inner-table-row-item" valign={"middle"} colSpan={1}>
                    {date}
                </td>
                <td className="expanding-table-inner-table-row-item" valign={"middle"} colSpan={1}>
                    {accountCode}
                </td>
                <td className="expanding-table-inner-table-row-item" valign={"middle"} colSpan={1}>
                    {shiftDetails}
                </td>
                <td className="expanding-table-inner-table-row-item" valign={"middle"} colSpan={1}>
                    {billingType}
                </td>
            </tr>
        </React.Fragment>
    );
}

export default InvoiceLinesTableRow;

interface Props {
    location: React.ReactNode;
    accountCode: string;
    date: React.ReactNode;
    shiftDetails: React.ReactNode;
    billingType: string;
}
