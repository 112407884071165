import React from "react";
import {StaffMemberInvoice} from "../../../../../../../api/grs";
import HeaderWithActions from "../../../../../../../modules/Table/Components/HeaderWithActions";
import ExpandingTableInnerRow from "../../../../../../../modules/Table/Components/ExpandingTableInnerRow";
import InvoiceLinesTable from "./InvoiceLinesTable";

function InvoiceLinesTableContainer(props: StaffMemberInvoice) {
    return (
        <React.Fragment>
            {props.lines.length > 0 && (
                <React.Fragment>
                    {props.errors.length > 0 && (
                        <React.Fragment>
                            <ExpandingTableInnerRow
                                height={20}
                                colSpan={5}
                                className={"inner-table-spacer"}
                            />
                        </React.Fragment>
                    )}
                    <ExpandingTableInnerRow height={20} colSpan={5} />
                    <ExpandingTableInnerRow colSpan={5}>
                        <HeaderWithActions
                            headerName={"Invoice Lines"}
                            actions={[]}
                            textColour={"green"}
                        />
                    </ExpandingTableInnerRow>
                    <InvoiceLinesTable {...props} />
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default InvoiceLinesTableContainer;
