import React from "react";
import {useXeroCallback} from "./Hooks/useXeroCallback";
import OverlayLoading from "../../../Loading/OverlayLoading";

function XeroCallback() {
    useXeroCallback();

    return (
        <React.Fragment>
            <OverlayLoading />
        </React.Fragment>
    );
}

export default XeroCallback;
