import {GLOBAL_STAFF_BLOCK_STORE} from "../actions/GlobalStaffBlockActionTypes";
import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {StaffBlockTemplate} from "../../../api/grs";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<StaffBlockTemplate>(null);

const globalStaffBlockReducer = (
    state: StoreServiceData<StaffBlockTemplate> = defaultState,
    action: ServiceActionTypes<StaffBlockTemplate>
) =>
    createReducer<StaffBlockTemplate>(state, action, GLOBAL_STAFF_BLOCK_STORE, () =>
        showErrorToast(action.error)
    );

export default globalStaffBlockReducer;
