import {
    AssignmentState,
    Calendar,
    CalendarEntryState,
    EventCalendarEntry,
    FrontlineCalendarEntry,
    StaffAssignment,
    StaffLink
} from "../../../../api/grs";
import {routeNames} from "../../../Navigation/routeNames";
import {ClinicalGrade, GeoLocation, UserData} from "../../../../api/staff";
import deepCopy from "../../../../utils/csvUtils";
import {getClinicalGradeFromString} from "../../../../utils/enumUtils";

/** Gets the month or query string from window location search params. One or the other will exist on a calendar entry */
export function getMonthOrWeekQueryFromCalendarEntryUrl() {
    const month = window.location.search.split("month=")[1];
    const week = window.location.search.split("week=")[1];
    if (month) return `&month=${+month}`;
    if (week) return `&week=${+week}`;
}

/** Returns the correct link for the sidebar from the entry to the calendar */
export function getPathToCalendarFromCalendarEntry(calendar: Calendar) {
    return `${routeNames.calendar.path}/${encodeURI(calendar.name)}?id=${
        calendar.id
    }${getMonthOrWeekQueryFromCalendarEntryUrl()}`;
}

/** Returns the correct link for the sidebar for the Add Calendar Entry Route */
export function getAddCalendarEntryPath(calendar: Calendar, startDate: number) {
    return `${routeNames.calendar.path}/${encodeURI(calendar.name)}${
        routeNames.addCalendarEntry.path
    }?startDate=${startDate}&calendarId=${calendar.id}${getMonthOrWeekQueryFromCalendarEntryUrl()}`;
}

/** Returns the correct link for the sidebar for the Add Calendar Entry Route */
export function getEditCalendarEntryPath(calendar: Calendar, entryId: number) {
    return `${routeNames.calendar.path}/${encodeURI(calendar.name)}${
        routeNames.editCalendarEntry.path
    }/${entryId}?calendarId=${calendar.id}${getMonthOrWeekQueryFromCalendarEntryUrl()}`;
}

/** Checks the state of the assignment and returns the correct class to show in the ui. */
export function getClassForStaffAssignment(state: AssignmentState) {
    switch (state) {
        case AssignmentState.Accepted:
            return "assignment-base accepted cursor-pointer";

        case AssignmentState.Unassigned:
            return "assignment-base free cursor-pointer";

        case AssignmentState.WaitingAccept:
            return "assignment-base waitingAccept cursor-pointer";

        case AssignmentState.Attended:
        case AssignmentState.BriefingAccepted:
            return "assignment-base attended cursor-pointer";

        default:
            return "assignment-base free cursor-pointer";
    }
}

/** Gets all assignments regardless of assignment state within an entry */
export function getTotalAssignments(
    entry: EventCalendarEntry | FrontlineCalendarEntry
): StaffAssignment[] {
    const totalAssignments: StaffAssignment[] = [];

    for (const section of entry.requiredStaff.sections) {
        for (const grouping of section.groupings) {
            for (const assignment of grouping.assignments) {
                totalAssignments.push(assignment);
            }
        }
    }

    return totalAssignments;
}

/** Gets all assignments regardless of assignment state within an entry */
export function getTotalAssignmentsWithGroupName(
    entry: EventCalendarEntry | FrontlineCalendarEntry
): StaffAssignmentWithGroupName[] {
    const totalAssignments: StaffAssignmentWithGroupName[] = [];

    for (const section of entry.requiredStaff.sections) {
        for (const grouping of section.groupings) {
            for (const assignment of grouping.assignments) {
                totalAssignments.push({
                    ...assignment,
                    groupName: grouping.name
                });
            }
        }
    }

    return totalAssignments;
}

/** Gets all assignments FILLED assignments. It will not show any UNASSIGNED assignments */
export function getFilledAssignments(
    entry: EventCalendarEntry | FrontlineCalendarEntry
): StaffAssignment[] {
    const totalAssignments = getTotalAssignments(entry);

    return totalAssignments.filter(
        (assignment: StaffAssignment) => assignment.state !== AssignmentState.Unassigned
    );
}

/** Adds optional email field and mandatory clinical grade to staff link */
export interface StaffLinkAddedDetails extends StaffLink {
    email?: string;
    clinicalGrade?: ClinicalGrade;
    location?: GeoLocation;
    regionIds?: number[];
}

/** Looks at staff in the entry and in the list, if they exist in the entry, they aren't added to the available staff list
 * Converts them into a GRS friendly format*/
export function getAvailableStaff(
    entry: EventCalendarEntry | FrontlineCalendarEntry,
    users: UserData[]
): StaffLinkAddedDetails[] {
    const filledAssignments = getFilledAssignments(entry);
    const userCopy = deepCopy(users);

    //Filter through the arrays, matching the UIDs of both data sets.
    //If the UIDs match, we remove them from the staff list as they're already assigned somewhere in the event.
    //ToDo: This is ugly, refactor soon
    for (let i = 0; i < filledAssignments.length; ++i) {
        for (let j = 0; j < userCopy.length; ++j) {
            if (filledAssignments[i].staffMember?.staffId === userCopy[j].username) {
                userCopy.splice(j, 1);
            }
        }
    }

    return convertUserDataListToStaffLinkWithEmailList(userCopy);
}

/** Gets assigned users in calendar entry and converts them to staff link with email */
export function getAssignedStaffWithEmailList(
    entry: EventCalendarEntry | FrontlineCalendarEntry,
    users: UserData[]
): StaffLinkAddedDetails[] {
    const filledAssignments = getFilledAssignments(entry);
    const assignedUsers: UserData[] = [];

    // Cycle through all assignments and marry them up with the staff database, if we found them, push their
    // staff db details to a list and convert that
    for (const assignment of filledAssignments) {
        const staffMember = assignment.staffMember;
        if (!staffMember) continue;
        const index = users.findIndex((user: UserData) => user.username === staffMember.staffId);
        if (index < 0) continue;

        assignedUsers.push(users[index]);
    }

    return convertUserDataListToStaffLinkWithEmailList(assignedUsers);
}

/** Converts the user list from Staff management into workable format for the GRS */
export function convertUserDataListToStaffLinkWithEmailList(
    users: UserData[]
): StaffLinkAddedDetails[] {
    const staffList: StaffLinkAddedDetails[] = [];

    for (const user of users) {
        staffList.push({
            staffId: user.username,
            staffName: `${user.firstName} ${user.lastName}`,
            /** Hacky way of doing this as clinical grades are the same in StaffManagement and GRS but Typescript be typescript yo */
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            clinicalGrade: getClinicalGradeFromString(user.clinicalGrade.toString()),
            email: user.email,
            regionIds: user.regions,
            location: user.location
        });
    }

    return staffList;
}

/** Filtered the available staff by the clinical grade. */
export function filterStaffLinkAddedDetailsByClinicalGrade(
    clinicalGrades: ClinicalGrade[],
    staffList: StaffLinkAddedDetails[]
): StaffLinkAddedDetails[] {
    //Clinical Grades don't exist, we don't need to do any filtering, return the original list.
    if (clinicalGrades.length === 0) return staffList;

    const newStaffList: StaffLinkAddedDetails[] = [];

    for (const staff of staffList) {
        if (!staff.clinicalGrade) continue;

        if (clinicalGrades.includes(staff.clinicalGrade)) {
            newStaffList.push(staff);
        }
    }
    return newStaffList;
}

/** Gets the entry state enum from string */
export function getEntryStateFromString(value: string): CalendarEntryState {
    return CalendarEntryState[value as keyof typeof CalendarEntryState];
}

export interface StaffAssignmentWithGroupName extends StaffAssignment {
    groupName: string;
}
