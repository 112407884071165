import React from "react";

function InvoiceErrorTableRow({location, date, error}: Props) {
    return (
        <React.Fragment>
            <tr className="expanding-table-inner-table-row">
                <td className="expanding-table-inner-table-row-item" valign={"middle"} colSpan={1}>
                    {location}
                </td>
                <td className="expanding-table-inner-table-row-item" valign={"middle"} colSpan={1}>
                    {date}
                </td>
                <td className="expanding-table-inner-table-row-item" valign={"middle"} colSpan={3}>
                    {error}
                </td>
            </tr>
        </React.Fragment>
    );
}

export default InvoiceErrorTableRow;

interface Props {
    location: React.ReactNode;
    date: string;
    error: string;
}
