import {useEffect, useState} from "react";
import {getPageCount} from "pulse_table/dist/components/PulseTableWithLocalPagination";

export function useServerPagination({
    resultsPerPage,
    totalResults,
    initialPageNumber,
    onPageChanged
}: Props) {
    const [pageNumber, setPageNumber] = useState(initialPageNumber);
    const [pageCount, setPageCount] = useState<number>(1);

    useEffect(() => {
        if (totalResults === 0 || resultsPerPage === 0) return;
        setPageCount(getPageCount(totalResults, resultsPerPage));
    }, [totalResults, resultsPerPage]);

    const changePage = (data: any) => {
        const selected = data.selected !== undefined ? data.selected : data;
        setPageNumber(selected);
        onPageChanged(selected);
    };

    return {
        pageCount,
        changePage,
        pageNumber
    };
}

interface Props {
    resultsPerPage: number;
    totalResults: number;
    onPageChanged: (newPageNum: number) => void;
    initialPageNumber?: number;
}
