import {useDispatch} from "react-redux";
import {CalendarGroup} from "../../../../../api/grs";
import {
    nullifyCalendarGroupStore,
    saveCalendarGroup,
    setCalendarGroup
} from "../../../../../store/calendarGroup/actions/CalendarGroupActions";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../../../Navigation/routeNames";
import {showErrorToast} from "../../../../../utils/toastUtils";

export function useCalendarGroupForm() {
    const dispatch = useDispatch();
    const history = useHistory();

    function updateForm(newForm: CalendarGroup) {
        dispatch(setCalendarGroup(newForm));
    }

    function nullifyStore() {
        dispatch(nullifyCalendarGroupStore());
    }

    function backToCalendarGroupList() {
        history.push(routeNames.manageCalendarGroups.path);
    }

    async function save(group: CalendarGroup): Promise<boolean> {
        const valid = validateGroup(group);

        if (!valid) return false;

        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return await dispatch(saveCalendarGroup(group));
        } catch (e: any) {
            showErrorToast("Could not save calendar group. Contact System Administrator.");
            return false;
        }
    }

    function validateGroup(group: CalendarGroup): boolean {
        if (group.name.length < 3) {
            showErrorToast("Group name must be more than 3 characters long.");
            return false;
        }

        if (group.calendarIds.length === 0) {
            showErrorToast("Group must contain one or more calendars");

            return false;
        }

        return true;
    }

    return {
        updateForm,
        backToCalendarGroupList,
        save,
        nullifyStore
    };
}
