import React from "react";
import SMSFilters from "./SMSFilters";
import SMSBody from "./SMSBody";
import SMSActions, {SMSModalConfirmationProps} from "./SMSActions";
import SMSSwitch from "./SMSSwitch";
import {useSelector} from "react-redux";
import {RootStore} from "../../../store/Store";

const SMSForm = ({onClose}: SMSModalConfirmationProps) => {
    const {sendToEventsStaff} = useSelector(
        (state: RootStore) =>
            state.sms.data || {staffMembers: [], bodyText: "", sendToEventsStaff: true}
    );
    return (
        <React.Fragment>
            <SMSSwitch />
            {!sendToEventsStaff && <SMSFilters />}
            <SMSBody />
            <SMSActions onClose={onClose} />
        </React.Fragment>
    );
};

export default SMSForm;
