import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {EventCalendarEntry, FrontlineCalendarEntry} from "../../../../api/grs";
import {ATTENDED_STORE} from "../actions/AttendedActionTypes";
import {showErrorToast} from "../../../../utils/toastUtils";

const defaultState = createDefaultStoreState<FrontlineCalendarEntry | EventCalendarEntry>(null);

const attendedReducer = (
    state: StoreServiceData<FrontlineCalendarEntry | EventCalendarEntry> = defaultState,
    actions: ServiceActionTypes<FrontlineCalendarEntry | EventCalendarEntry>
): StoreServiceData<FrontlineCalendarEntry | EventCalendarEntry> =>
    createReducer(state, actions, ATTENDED_STORE, () => showErrorToast(actions.error));

export default attendedReducer;
