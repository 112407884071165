import React, {Fragment} from "react";
import {Loading} from "store-fetch-wrappers";
import AssignUser from "./AssignUser";
import ReserveUser from "./ReserveUser";
import {
    AcceptRegisterRequestProps,
    useAcceptRegisterRequest
} from "./hooks/useAcceptRegisterRequest";

function AcceptRegisterRequest(props: AcceptRegisterRequestProps) {
    const {canBeAssigned, acceptUserInterest, loading, onReserveConfirmed} =
        useAcceptRegisterRequest(props);

    return (
        <Fragment>
            {loading ? (
                <Loading type={"three-ring"} showLoadingText={false} />
            ) : (
                <Fragment>
                    {canBeAssigned ? (
                        <AssignUser
                            action={props}
                            onRateConfirmed={(newRate) => acceptUserInterest(newRate, props)}
                        />
                    ) : (
                        <Fragment>
                            {props.interest.reserveNotification ? (
                                <p className="mb-0">
                                    Staff Member has been added to the reserve list.
                                </p>
                            ) : (
                                <ReserveUser
                                    onConfirm={() =>
                                        onReserveConfirmed({
                                            entry: props.entry,
                                            interest: props.interest
                                        })
                                    }
                                />
                            )}
                        </Fragment>
                    )}
                </Fragment>
            )}
        </Fragment>
    );
}

export default AcceptRegisterRequest;
