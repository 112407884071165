import React from "react";
import {ActiveShiftView} from "../Provider/ShiftOverviewProvider";
import {getUiFriendlyText} from "../../../../utils/textUtils";

function ShiftViewButton({onClick, view, isActive}: Props) {
    return (
        <button
            onClick={() => onClick(view)}
            className={`button-toggle ${isActive ? "selected" : ""} font-weight-normal`}
        >
            {getUiFriendlyText(view)}
        </button>
    );
}

export default ShiftViewButton;

interface Props {
    onClick: (newView: ActiveShiftView) => void;
    view: ActiveShiftView;
    isActive: boolean;
}
