/** Will generate toggle field props based on clinical grade array */
import {GenericNameValueToggle} from "../../../../../utils/sortingUtils";
import {ClinicalGrade} from "../../../../../api/grs";
import {getClinicalGradeOptions} from "../../../../../utils/staffApiUtils";

export function getClinicalGradeToggleOptionsFromClinicalGrades(grades: ClinicalGrade[]) {
    const toggleFieldProps: GenericNameValueToggle[] = [];
    const clinicalGradeOptions = getClinicalGradeOptions();
    for (const option of clinicalGradeOptions) {
        const index = grades.findIndex((el: ClinicalGrade) => el === option.value);

        // If the value is found in the array, allow for the check box to be checked
        const checked = index > -1;

        toggleFieldProps.push({
            name: option.name,
            value: option.value,
            checked
        });
    }

    return toggleFieldProps;
}
