import {StoreServiceData} from "store-fetch-wrappers";
import {StaffPendingAction} from "../../../api/grs";
import {createStoreState} from "store-fetch-wrappers/dist";

export const PENDING_NOTIFICATIONS_STORE_STATE = createStoreState("pending_notifications");

interface PendingNotificationsLoading extends StoreServiceData<StaffPendingAction[]> {
    type: typeof PENDING_NOTIFICATIONS_STORE_STATE.LOADING;
}
interface PendingNotificationsError extends StoreServiceData<StaffPendingAction[]> {
    type: typeof PENDING_NOTIFICATIONS_STORE_STATE.ERROR;
}
interface PendingNotificationsSuccess extends StoreServiceData<StaffPendingAction[]> {
    type: typeof PENDING_NOTIFICATIONS_STORE_STATE.SUCCESS;
}

export type PendingNotificationsDispatchTypes =
    | PendingNotificationsError
    | PendingNotificationsLoading
    | PendingNotificationsSuccess;
