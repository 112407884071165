import React from "react";
import {CalendarEntry, CalendarTypeEnum} from "../../../../../../api/grs";
import FormHeader from "../../../../../Form/FormHeader";
import CalendarEntryEventDetails from "./EventCalendar/CalendarEntryEventDetails";
import CalendarEntryFrontlineDetails from "./FrontlineCalendar/CalendarEntryFrontlineDetails";

const CalendarEntryDetails = (props: CalendarEntry) => {
    /** Renders the correct entry details based on the calendar type */
    const renderCorrectEntryDetails = (calendarType: CalendarTypeEnum) => {
        switch (calendarType) {
            case CalendarTypeEnum.Event:
                return <CalendarEntryEventDetails {...props} />;
            case CalendarTypeEnum.Frontline:
                return <CalendarEntryFrontlineDetails {...props} />;
            default:
                return <div />;
        }
    };
    return (
        <React.Fragment>
            <FormHeader headerName={"Event Details"} />
            {renderCorrectEntryDetails(props.calendarType)}
        </React.Fragment>
    );
};

export default CalendarEntryDetails;
