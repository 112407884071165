import {DateRange} from "../../../../../../utils/filterUtils";
import {useUserData} from "../../../../../Hooks/useUserData";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../../../store/Store";
import {
    getMobileCalendarViewItems,
    nullifyMobileCalendarViewStore
} from "../../../../../../store/mobileCalendar/actions/MobileCalendarActions";
import {useEffect} from "react";

export function useGlobalCalendarMobile() {
    const mobileCalendarStore = useSelector((state: RootStore) => state.mobileCalendarView);
    const user = useUserData();
    const dispatch = useDispatch();

    useEffect(() => {
        //Upon mounting, scroll back to top
        window.scrollTo({top: 0, behavior: "smooth"});
        return function () {
            dispatch(nullifyMobileCalendarViewStore());
        };
    }, []);

    async function fetchData(range: DateRange) {
        if (!user) return;
        dispatch(getMobileCalendarViewItems(range, user));
    }

    return {
        mobileCalendarStore,
        fetchData
    };
}
