/* tslint:disable */
/* eslint-disable */
/**
 * GRS API
 * API to manipulate GRS entities
 *
 * The version of the OpenAPI document: 0.1-SNAPSHOT
 * Contact: dan@pulseadvancedmedia.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @enum {string}
 */

export enum AssignmentState {
    Unassigned = 'Unassigned',
    WaitingAccept = 'WaitingAccept',
    Accepted = 'Accepted',
    Attended = 'Attended',
    BriefingAccepted = 'BriefingAccepted'
}


/**
 * Stats for a specific assignment type. For example the total number of people within the specified assignment type.
 * @export
 * @interface AssignmentStateStats
 */
export interface AssignmentStateStats {
    /**
     *
     * @type {AssignmentState}
     * @memberof AssignmentStateStats
     */
    'state'?: AssignmentState;
    /**
     * Total number of people with this assignment state
     * @type {number}
     * @memberof AssignmentStateStats
     */
    'count'?: number;
}


/**
 * List automatic Xero invoices that have been attempted
 * @export
 * @interface AutoInvoiceListRequest
 */
export interface AutoInvoiceListRequest {
    /**
     * Page to fetch, starting from 0
     * @type {number}
     * @memberof AutoInvoiceListRequest
     */
    'pageNum'?: number;
    /**
     * Maximum number of entries to return in a single page
     * @type {number}
     * @memberof AutoInvoiceListRequest
     */
    'numPerPage'?: number;
    /**
     * Year of the invoices
     * @type {number}
     * @memberof AutoInvoiceListRequest
     */
    'year': number;
    /**
     * Month of the invoices
     * @type {number}
     * @memberof AutoInvoiceListRequest
     */
    'month': number;
}
/**
 * List automatic Xero invoices that have been attempted
 * @export
 * @interface AutoInvoiceListResponse
 */
export interface AutoInvoiceListResponse {
    /**
     * Page to fetch, starting from 0
     * @type {number}
     * @memberof AutoInvoiceListResponse
     */
    'pageNum': number;
    /**
     * Maximum number of entries to return in a single page
     * @type {number}
     * @memberof AutoInvoiceListResponse
     */
    'numPerPage': number;
    /**
     * Total number of invoices matching list requirements
     * @type {number}
     * @memberof AutoInvoiceListResponse
     */
    'totalInvoices': number;
    /**
     * Year of the invoices
     * @type {number}
     * @memberof AutoInvoiceListResponse
     */
    'year': number;
    /**
     * Month of the invoices
     * @type {number}
     * @memberof AutoInvoiceListResponse
     */
    'month': number;
    /**
     *
     * @type {Array<AutoInvoiceRecord>}
     * @memberof AutoInvoiceListResponse
     */
    'invoices'?: Array<AutoInvoiceRecord>;
}
/**
 * Record of an automatic invoice that has been attempted
 * @export
 * @interface AutoInvoiceRecord
 */
export interface AutoInvoiceRecord {
    /**
     * Year of the invoice
     * @type {number}
     * @memberof AutoInvoiceRecord
     */
    'year': number;
    /**
     * Month of the invoice
     * @type {number}
     * @memberof AutoInvoiceRecord
     */
    'month': number;
    /**
     * Username for staff member
     * @type {string}
     * @memberof AutoInvoiceRecord
     */
    'staffId': string;
    /**
     * Name of the staff member
     * @type {string}
     * @memberof AutoInvoiceRecord
     */
    'staffName': string;
    /**
     *
     * @type {StaffMemberInvoice}
     * @memberof AutoInvoiceRecord
     */
    'rawData': StaffMemberInvoice;
    /**
     * Unique ID of the invoice in Xero, if present. If not present the invoice either has errors, or has yet to be submitted.
     * @type {string}
     * @memberof AutoInvoiceRecord
     */
    'invoiceId'?: string;
}
/**
 * Information about available shifts for a specific calendar entry
 * @export
 * @interface AvailableShiftCalendarEntry
 */
export interface AvailableShiftCalendarEntry {
    /**
     * Unique ID for the calendar entry
     * @type {number}
     * @memberof AvailableShiftCalendarEntry
     */
    'id': number;
    /**
     * ID of the calendar the entry is within
     * @type {number}
     * @memberof AvailableShiftCalendarEntry
     */
    'calendarId': number;
    /**
     *
     * @type {CalendarTypeEnum}
     * @memberof AvailableShiftCalendarEntry
     */
    'calendarType': CalendarTypeEnum;
    /**
     *
     * @type {CalendarEntryState}
     * @memberof AvailableShiftCalendarEntry
     */
    'state': CalendarEntryState;
    /**
     * Linux epoch of the event start
     * @type {number}
     * @memberof AvailableShiftCalendarEntry
     */
    'startDate': number;
    /**
     * Linux epoch of the event finish
     * @type {number}
     * @memberof AvailableShiftCalendarEntry
     */
    'endDate': number;
    /**
     * Name of the venue
     * @type {string}
     * @memberof AvailableShiftCalendarEntry
     */
    'venueName': string;
    /**
     * Optional description of the event
     * @type {string}
     * @memberof AvailableShiftCalendarEntry
     */
    'description'?: string;
    /**
     * The available slots for the entry
     * @type {Array<AvailableShiftSection>}
     * @memberof AvailableShiftCalendarEntry
     */
    'requiredStaff': Array<AvailableShiftSection>;
}


/**
 * Number of slots available in a grouping within a calendar entry
 * @export
 * @interface AvailableShiftGrouping
 */
export interface AvailableShiftGrouping {
    /**
     * Unique ID for the grouping
     * @type {string}
     * @memberof AvailableShiftGrouping
     */
    'id': string;
    /**
     * Name of this group
     * @type {string}
     * @memberof AvailableShiftGrouping
     */
    'name': string;
    /**
     * The clinical grade restrictions for this grouping
     * @type {Array<ClinicalGrade>}
     * @memberof AvailableShiftGrouping
     */
    'clinicalGrades'?: Array<ClinicalGrade>;
    /**
     * The number of slots already assigned to staff
     * @type {number}
     * @memberof AvailableShiftGrouping
     */
    'takenSlotCount': number;
    /**
     * The number of slots available for registering interest
     * @type {number}
     * @memberof AvailableShiftGrouping
     */
    'availableSlotCount': number;
}
/**
 * Section within a calendar entry
 * @export
 * @interface AvailableShiftSection
 */
export interface AvailableShiftSection {
    /**
     * Unique ID for this section
     * @type {string}
     * @memberof AvailableShiftSection
     */
    'id': string;
    /**
     * Name of this section
     * @type {string}
     * @memberof AvailableShiftSection
     */
    'name': string;
    /**
     * Groups within this section
     * @type {Array<AvailableShiftGrouping>}
     * @memberof AvailableShiftSection
     */
    'groupings': Array<AvailableShiftGrouping>;
}
/**
 * Get the shifts where staff can register interest to fill
 * @export
 * @interface AvailableShiftsRequestUi
 */
export interface AvailableShiftsRequestUi {
    /**
     * Minimum linux epoch for the shift
     * @type {number}
     * @memberof AvailableShiftsRequestUi
     */
    'startDate': number;
    /**
     * Maximum linux epoch for the shift
     * @type {number}
     * @memberof AvailableShiftsRequestUi
     */
    'endDate': number;
    /**
     * Optional filter to the specified calendar IDs, empty for no filter
     * @type {Array<number>}
     * @memberof AvailableShiftsRequestUi
     */
    'calendarIds': Array<number>;
}
/**
 * Core stats for a calendar summary
 * @export
 * @interface BaseCalendarSummaryStats
 */
export interface BaseCalendarSummaryStats {
    /**
     * Total number of slots in the entries
     * @type {number}
     * @memberof BaseCalendarSummaryStats
     */
    'numRequired': number;
    /**
     * Total number of staff assigned to the slots, regardless of state
     * @type {number}
     * @memberof BaseCalendarSummaryStats
     */
    'numAssignments': number;
    /**
     * Stats for individual states within the system
     * @type {Array<AssignmentStateStats>}
     * @memberof BaseCalendarSummaryStats
     */
    'stateBreakdown'?: Array<AssignmentStateStats>;
}
/**
 *
 * @export
 * @enum {string}
 */

export enum BillingType {
    Hourly = 'Hourly',
    Daily = 'Daily'
}


/**
 * Master container for an individual \'Calendar View\' that the user can see. Entries are then added to the calendar to assign staff to certain events or other objects.
 * @export
 * @interface Calendar
 */
export interface Calendar {
    /**
     * Unique ID for the calendar. Set to 0 to insert a new calendar.
     * @type {number}
     * @memberof Calendar
     */
    'id': number;
    /**
     * Version number for concurrency control. Ignored when initially saving, incremented automatically on each save. The version number must be specified and must match the version in the DB. If the versions don\'t match the save request will fail.
     * @type {number}
     * @memberof Calendar
     */
    'version': number;
    /**
     *
     * @type {string}
     * @memberof Calendar
     */
    'name': string;
    /**
     *
     * @type {CalendarTypeEnum}
     * @memberof Calendar
     */
    'calendarType': CalendarTypeEnum;
    /**
     *
     * @type {BillingType}
     * @memberof Calendar
     */
    'billingType': BillingType;
    /**
     * Direct URL to the background image for the calendar
     * @type {string}
     * @memberof Calendar
     */
    'bgImageUrl': string;
    /**
     *
     * @type {StaffBlock}
     * @memberof Calendar
     */
    'staffBlock': StaffBlock;
    /**
     * If this calendar is \'archived\' and shouldn\'t be shown by default
     * @type {boolean}
     * @memberof Calendar
     */
    'archived': boolean;
    /**
     * Xero account code for this calendar
     * @type {string}
     * @memberof Calendar
     */
    'accountCode'?: string;
}


/**
 * Compliance information for a single calendar
 * @export
 * @interface CalendarCompliance
 */
export interface CalendarCompliance {
    /**
     * Unique ID of the calendar
     * @type {number}
     * @memberof CalendarCompliance
     */
    'id': number;
    /**
     * Name of the calendar
     * @type {string}
     * @memberof CalendarCompliance
     */
    'name': string;
    /**
     *
     * @type {CalendarTypeEnum}
     * @memberof CalendarCompliance
     */
    'type': CalendarTypeEnum;
    /**
     * Whether this calendar has been archived or not
     * @type {boolean}
     * @memberof CalendarCompliance
     */
    'archived': boolean;
    /**
     * Compliance information for the calendar, usually filtered based on request
     * @type {Array<CalendarComplianceDate>}
     * @memberof CalendarCompliance
     */
    'compliant': Array<CalendarComplianceDate>;
}


/**
 * Whether the date is compliant or not
 * @export
 * @interface CalendarComplianceDate
 */
export interface CalendarComplianceDate {
    /**
     * Linux epoch for the start of the day
     * @type {number}
     * @memberof CalendarComplianceDate
     */
    'date': number;
    /**
     *
     * @type {CalendarComplianceLevel}
     * @memberof CalendarComplianceDate
     */
    'compliant': CalendarComplianceLevel;
}


/**
 * The level of compliance of the calendar
 * @export
 * @enum {string}
 */

export enum CalendarComplianceLevel {
    Compliant = 'Compliant',
    NotCompliant = 'NotCompliant',
    NotApplicable = 'NotApplicable',
    PartialCompliant = 'PartialCompliant'
}


/**
 * Filtering options for a compliance request. If dates not provided, will default to the next 3 days inclusive of today
 * @export
 * @interface CalendarComplianceRequest
 */
export interface CalendarComplianceRequest {
    /**
     *
     * @type {number}
     * @memberof CalendarComplianceRequest
     */
    'startDateInclusive'?: number;
    /**
     *
     * @type {number}
     * @memberof CalendarComplianceRequest
     */
    'endDateExclusive'?: number;
}
/**
 * Calendar compliance as requested
 * @export
 * @interface CalendarComplianceResponse
 */
export interface CalendarComplianceResponse {
    /**
     *
     * @type {number}
     * @memberof CalendarComplianceResponse
     */
    'startDateInclusive': number;
    /**
     *
     * @type {number}
     * @memberof CalendarComplianceResponse
     */
    'endDateExclusive': number;
    /**
     * The compliance information requested
     * @type {Array<CalendarCompliance>}
     * @memberof CalendarComplianceResponse
     */
    'compliance': Array<CalendarCompliance>;
}
/**
 * A specific event happening in a specific calendar
 * @export
 * @interface CalendarEntry
 */
export interface CalendarEntry {
    /**
     * Unique ID for the entry. Set to 0 to insert a new entry.
     * @type {number}
     * @memberof CalendarEntry
     */
    'id': number;
    /**
     * Version number for concurrency control. Ignored when initially saving, incremented automatically on each save. The version number must be specified and must match the version in the DB. If the versions don\'t match the save request will fail.
     * @type {number}
     * @memberof CalendarEntry
     */
    'version': number;
    /**
     *
     * @type {CalendarTypeEnum}
     * @memberof CalendarEntry
     */
    'calendarType': CalendarTypeEnum;
    /**
     *
     * @type {CalendarEntryState}
     * @memberof CalendarEntry
     */
    'state': CalendarEntryState;
    /**
     * Linux epoch for when this entry starts
     * @type {number}
     * @memberof CalendarEntry
     */
    'startDate': number;
    /**
     * Linux epoch for when this entry ends
     * @type {number}
     * @memberof CalendarEntry
     */
    'endDate': number;
    /**
     * Linux epoch for when this entry **actually** started
     * @type {number}
     * @memberof CalendarEntry
     */
    'actualStartDate'?: number;
    /**
     * Linux epoch for when this entry **actually** ended
     * @type {number}
     * @memberof CalendarEntry
     */
    'actualEndDate'?: number;
    /**
     * Quick summary for this entry
     * @type {string}
     * @memberof CalendarEntry
     */
    'description'?: string;
    /**
     * Any additional notes entered by users
     * @type {string}
     * @memberof CalendarEntry
     */
    'notes'?: string;
    /**
     *
     * @type {StaffBlock}
     * @memberof CalendarEntry
     */
    'requiredStaff': StaffBlock;
    /**
     * Unique ID to reference an entity
     * @type {number}
     * @memberof CalendarEntry
     */
    'calendarId': number;
    /**
     * Unique ID to reference an entity
     * @type {number}
     * @memberof CalendarEntry
     */
    'venueId'?: number;
    /**
     *
     * @type {BillingType}
     * @memberof CalendarEntry
     */
    'billingType'?: BillingType;
    /**
     * Optional subtitle for entries, usually displayed next/under default entry naming
     * @type {string}
     * @memberof CalendarEntry
     */
    'subTitle'?: string;
}


/**
 * Get calendar entries that meet the requirements of this object. Automatically filters to the user\'s entries, unless they\'re an admin in which case all entries are returned.
 * @export
 * @interface CalendarEntryListRequest
 */
export interface CalendarEntryListRequest {
    /**
     * Linux Epoch for the earliest date/time to get an entry for
     * @type {number}
     * @memberof CalendarEntryListRequest
     */
    'startDate': number;
    /**
     * Linux Epoch for the latest date/time to get an entry for
     * @type {number}
     * @memberof CalendarEntryListRequest
     */
    'endDate': number;
    /**
     * Optional. The ID of the calendar to restrict the entries to
     * @type {number}
     * @memberof CalendarEntryListRequest
     */
    'calendarId'?: number;
    /**
     * Optional. Additional calendar IDs to filter to, can be used with or without calendarId
     * @type {Array<number>}
     * @memberof CalendarEntryListRequest
     */
    'additionalCalendarIds'?: Array<number>;
    /**
     * Optional. If the requesting user in an admin, filter entries to only those that contain staffId
     * @type {string}
     * @memberof CalendarEntryListRequest
     */
    'staffId'?: string;
    /**
     * Optional. Include ALL on-call entries in the specified date range
     * @type {boolean}
     * @memberof CalendarEntryListRequest
     */
    'includeOnCall'?: boolean;
}
/**
 * Response calendar entries that meet the requirements of this object. Automatically filters to the user\'s entries, unless they\'re an admin in which case all entries are returned.
 * @export
 * @interface CalendarEntryListResponse
 */
export interface CalendarEntryListResponse {
    /**
     * Linux Epoch for the earliest date/time to get an entry for
     * @type {number}
     * @memberof CalendarEntryListResponse
     */
    'startDate': number;
    /**
     * Linux Epoch for the latest date/time to get an entry for
     * @type {number}
     * @memberof CalendarEntryListResponse
     */
    'endDate': number;
    /**
     * Optional. Calendar IDs to filter to. Will contain the IDs from \'calendarId\' and \'additionalCalendarIds\'
     * @type {Array<number>}
     * @memberof CalendarEntryListResponse
     */
    'calendarIds'?: Array<number>;
    /**
     * Optional. If the requesting user in an admin, filter entries to only those that contain staffId
     * @type {string}
     * @memberof CalendarEntryListResponse
     */
    'staffId'?: string;
    /**
     * Optional. Include ALL on-call entries in the specified date range
     * @type {boolean}
     * @memberof CalendarEntryListResponse
     */
    'includeOnCall'?: boolean;
    /**
     * Calendars found in the response or in the original request. If request specified no calendar IDs, and NO entries returned, this will be an empty array.
     * @type {Array<CalendarSummary>}
     * @memberof CalendarEntryListResponse
     */
    'calendars': Array<CalendarSummary>;
    /**
     * Found calendar entries for the request
     * @type {Array<CalendarEntry>}
     * @memberof CalendarEntryListResponse
     */
    'entries': Array<CalendarEntry>;
}
/**
 * Current state of a calendar entry
 * @export
 * @enum {string}
 */

export enum CalendarEntryState {
    Active = 'Active',
    Suspended = 'Suspended',
    Cancelled = 'Cancelled'
}


/**
 * Stats for a single event
 * @export
 * @interface CalendarEventSummaryStats
 */
export interface CalendarEventSummaryStats {
    /**
     * Total number of slots in the entries
     * @type {number}
     * @memberof CalendarEventSummaryStats
     */
    'numRequired': number;
    /**
     * Total number of staff assigned to the slots, regardless of state
     * @type {number}
     * @memberof CalendarEventSummaryStats
     */
    'numAssignments': number;
    /**
     * Stats for individual states within the system
     * @type {Array<AssignmentStateStats>}
     * @memberof CalendarEventSummaryStats
     */
    'stateBreakdown'?: Array<AssignmentStateStats>;
    /**
     * ID of the calendar entry these stats are for
     * @type {number}
     * @memberof CalendarEventSummaryStats
     */
    'entryId': number;
    /**
     * Linux epoch for the date these stats are for
     * @type {number}
     * @memberof CalendarEventSummaryStats
     */
    'eventDate': number;
}
/**
 *
 * @export
 * @interface CalendarEventSummaryStatsAllOf
 */
export interface CalendarEventSummaryStatsAllOf {
    /**
     * ID of the calendar entry these stats are for
     * @type {number}
     * @memberof CalendarEventSummaryStatsAllOf
     */
    'entryId': number;
    /**
     * Linux epoch for the date these stats are for
     * @type {number}
     * @memberof CalendarEventSummaryStatsAllOf
     */
    'eventDate': number;
}
/**
 * Logical grouping of calendars, mostly for UI purposes
 * @export
 * @interface CalendarGroup
 */
export interface CalendarGroup {
    /**
     * Unique ID for this grouping
     * @type {number}
     * @memberof CalendarGroup
     */
    'id': number;
    /**
     * Name for this group
     * @type {string}
     * @memberof CalendarGroup
     */
    'name': string;
    /**
     * Unique IDs for the calendars within this group
     * @type {Array<number>}
     * @memberof CalendarGroup
     */
    'calendarIds': Array<number>;
    /**
     *
     * @type {CalendarTypeEnum}
     * @memberof CalendarGroup
     */
    'viewType': CalendarTypeEnum;
}


/**
 * Overall stats for a single month
 * @export
 * @interface CalendarMonthSummaryStats
 */
export interface CalendarMonthSummaryStats {
    /**
     * Total number of slots in the entries
     * @type {number}
     * @memberof CalendarMonthSummaryStats
     */
    'numRequired': number;
    /**
     * Total number of staff assigned to the slots, regardless of state
     * @type {number}
     * @memberof CalendarMonthSummaryStats
     */
    'numAssignments': number;
    /**
     * Stats for individual states within the system
     * @type {Array<AssignmentStateStats>}
     * @memberof CalendarMonthSummaryStats
     */
    'stateBreakdown'?: Array<AssignmentStateStats>;
    /**
     * The month of the year (1-12) for this set of stats
     * @type {number}
     * @memberof CalendarMonthSummaryStats
     */
    'month': number;
    /**
     * The year (eg. 2020) for this set of stats
     * @type {number}
     * @memberof CalendarMonthSummaryStats
     */
    'year': number;
}
/**
 *
 * @export
 * @interface CalendarMonthSummaryStatsAllOf
 */
export interface CalendarMonthSummaryStatsAllOf {
    /**
     * The month of the year (1-12) for this set of stats
     * @type {number}
     * @memberof CalendarMonthSummaryStatsAllOf
     */
    'month': number;
    /**
     * The year (eg. 2020) for this set of stats
     * @type {number}
     * @memberof CalendarMonthSummaryStatsAllOf
     */
    'year': number;
}
/**
 * Core properties for a calendar object
 * @export
 * @interface CalendarSummary
 */
export interface CalendarSummary {
    /**
     * Unique ID for the calendar. Set to 0 to insert a new calendar.
     * @type {number}
     * @memberof CalendarSummary
     */
    'id': number;
    /**
     * Version number for concurrency control. Ignored when initially saving, incremented automatically on each save. The version number must be specified and must match the version in the DB. If the versions don\'t match the save request will fail.
     * @type {number}
     * @memberof CalendarSummary
     */
    'version': number;
    /**
     *
     * @type {string}
     * @memberof CalendarSummary
     */
    'name': string;
    /**
     *
     * @type {CalendarTypeEnum}
     * @memberof CalendarSummary
     */
    'calendarType': CalendarTypeEnum;
    /**
     *
     * @type {string}
     * @memberof CalendarSummary
     */
    'billingType': CalendarSummaryBillingTypeEnum;
    /**
     * Direct URL to the background image for the calendar
     * @type {string}
     * @memberof CalendarSummary
     */
    'bgImageUrl': string;
    /**
     * If this calendar is \'archived\' and shouldn\'t be shown by default
     * @type {boolean}
     * @memberof CalendarSummary
     */
    'archived': boolean;
    /**
     * Xero account code for this calendar
     * @type {string}
     * @memberof CalendarSummary
     */
    'accountCode'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CalendarSummaryBillingTypeEnum {
    Hourly = 'Hourly',
    Daily = 'Daily'
}

/**
 * Summary of a calendar as well as associated stats
 * @export
 * @interface CalendarSummaryStats
 */
export interface CalendarSummaryStats {
    /**
     *
     * @type {CalendarSummary}
     * @memberof CalendarSummaryStats
     */
    'summary': CalendarSummary;
    /**
     *
     * @type {CalendarEventSummaryStats}
     * @memberof CalendarSummaryStats
     */
    'nextEventStats': CalendarEventSummaryStats;
    /**
     *
     * @type {Array<CalendarEventSummaryStats>}
     * @memberof CalendarSummaryStats
     */
    'nextDayStats'?: Array<CalendarEventSummaryStats>;
    /**
     *
     * @type {Array<CalendarMonthSummaryStats>}
     * @memberof CalendarSummaryStats
     */
    'futureMonthStats': Array<CalendarMonthSummaryStats>;
}
/**
 * The type of calendar this is
 * @export
 * @enum {string}
 */

export enum CalendarTypeEnum {
    Event = 'Event',
    Frontline = 'Frontline'
}


/**
 *
 * @export
 * @enum {string}
 */

export enum ClinicalGrade {
    Paramedic = 'Paramedic',
    Technician = 'Technician',
    FirstResponder = 'FirstResponder',
    EAC = 'EAC',
    Nurse = 'Nurse'
}


/**
 * Delete UserInterest with matching fields
 * @export
 * @interface DeleteUserInterestRequest
 */
export interface DeleteUserInterestRequest {
    /**
     * Unique ID of the calendar entry the interest is for
     * @type {number}
     * @memberof DeleteUserInterestRequest
     */
    'entryId': number;
    /**
     * ID of the staff member the interest is for
     * @type {string}
     * @memberof DeleteUserInterestRequest
     */
    'staffId': string;
}
/**
 *
 * @export
 * @interface DutyManagerReport
 */
export interface DutyManagerReport {
    /**
     * Unique ID for the report. 0 to insert, >0 to update
     * @type {number}
     * @memberof DutyManagerReport
     */
    'id': number;
    /**
     * ID of the calendar entry this report is for
     * @type {number}
     * @memberof DutyManagerReport
     */
    'calendarEntryId': number;
    /**
     * Username of the duty manager that created this report
     * @type {string}
     * @memberof DutyManagerReport
     */
    'dutyManagerId': string;
    /**
     * Name of the duty manager that created this report
     * @type {string}
     * @memberof DutyManagerReport
     */
    'dutyManagerName': string;
    /**
     * ID of the venue, or NULL if the venue has since been deleted
     * @type {number}
     * @memberof DutyManagerReport
     */
    'venueId'?: number;
    /**
     * Name of the venue for this report
     * @type {string}
     * @memberof DutyManagerReport
     */
    'venueName': string;
    /**
     * Date this report was created
     * @type {number}
     * @memberof DutyManagerReport
     */
    'date': number;
    /**
     * JSON string that contains all information required by the report
     * @type {string}
     * @memberof DutyManagerReport
     */
    'payload': string;
}
/**
 * Cut down version of DutyManagerReport, used for instances where only the core fields are required
 * @export
 * @interface DutyManagerReportListing
 */
export interface DutyManagerReportListing {
    /**
     * Unique ID for the report. 0 to insert, >0 to update
     * @type {number}
     * @memberof DutyManagerReportListing
     */
    'id': number;
    /**
     * ID of the calendar entry this report is for
     * @type {number}
     * @memberof DutyManagerReportListing
     */
    'calendarEntryId': number;
    /**
     * Username of the duty manager that created this report
     * @type {string}
     * @memberof DutyManagerReportListing
     */
    'dutyManagerId': string;
    /**
     * Name of the duty manager that created this report
     * @type {string}
     * @memberof DutyManagerReportListing
     */
    'dutyManagerName': string;
    /**
     * ID of the venue, or NULL if the venue has since been deleted
     * @type {number}
     * @memberof DutyManagerReportListing
     */
    'venueId'?: number;
    /**
     * Name of the venue for this report
     * @type {string}
     * @memberof DutyManagerReportListing
     */
    'venueName': string;
    /**
     * Date this report was created
     * @type {number}
     * @memberof DutyManagerReportListing
     */
    'date': number;
}
/**
 *
 * @export
 * @interface DutyManagerReportPagedRequest
 */
export interface DutyManagerReportPagedRequest {
    /**
     * The page of data to return, starting from 0
     * @type {number}
     * @memberof DutyManagerReportPagedRequest
     */
    'pageNum'?: number;
    /**
     * The maximum number of entries to return for a single page
     * @type {number}
     * @memberof DutyManagerReportPagedRequest
     */
    'numPerPage'?: number;
    /**
     * Optional linux epoch for the earliest date allowed for the reports
     * @type {number}
     * @memberof DutyManagerReportPagedRequest
     */
    'startDateInclusive'?: number;
    /**
     * Optional linux epoch for the last date allowed for the reports
     * @type {number}
     * @memberof DutyManagerReportPagedRequest
     */
    'endDateExclusive'?: number;
    /**
     * Optional username for the duty manager to filter reports
     * @type {string}
     * @memberof DutyManagerReportPagedRequest
     */
    'dutyManagerId'?: string;
    /**
     * Optional ID of the calendar entry to filter reports
     * @type {number}
     * @memberof DutyManagerReportPagedRequest
     */
    'calendarEntryId'?: number;
}
/**
 *
 * @export
 * @interface DutyManagerReportPagedResponse
 */
export interface DutyManagerReportPagedResponse {
    /**
     * The page of data to return, starting from 0
     * @type {number}
     * @memberof DutyManagerReportPagedResponse
     */
    'pageNum': number;
    /**
     * The maximum number of entries to return for a single page
     * @type {number}
     * @memberof DutyManagerReportPagedResponse
     */
    'numPerPage': number;
    /**
     * The total number of reports that meet the criteria, can be used to calculate number of pages
     * @type {number}
     * @memberof DutyManagerReportPagedResponse
     */
    'totalEntries': number;
    /**
     * Found reports for this page
     * @type {Array<DutyManagerReportListing>}
     * @memberof DutyManagerReportPagedResponse
     */
    'entries': Array<DutyManagerReportListing>;
    /**
     * Optional linux epoch for the earliest date allowed for the reports
     * @type {number}
     * @memberof DutyManagerReportPagedResponse
     */
    'startDateInclusive'?: number;
    /**
     * Optional linux epoch for the last date allowed for the reports
     * @type {number}
     * @memberof DutyManagerReportPagedResponse
     */
    'endDateExclusive'?: number;
    /**
     * Optional username for the duty manager to filter reports
     * @type {string}
     * @memberof DutyManagerReportPagedResponse
     */
    'dutyManagerId'?: string;
    /**
     * Optional ID of the calendar entry to filter reports
     * @type {number}
     * @memberof DutyManagerReportPagedResponse
     */
    'calendarEntryId'?: number;
}
/**
 * Single error for an individual line within the invoice. A line is an event / shift / or other discrete action performed.
 * @export
 * @interface ErrorLine
 */
export interface ErrorLine {
    /**
     *
     * @type {string}
     * @memberof ErrorLine
     */
    'calendarName': string;
    /**
     *
     * @type {string}
     * @memberof ErrorLine
     */
    'venueName': string;
    /**
     *
     * @type {number}
     * @memberof ErrorLine
     */
    'calendarId'?: number;
    /**
     *
     * @type {number}
     * @memberof ErrorLine
     */
    'entryId'?: number;
    /**
     * Linux epoch for the start of the line (event, shift, etc)
     * @type {number}
     * @memberof ErrorLine
     */
    'date': number;
    /**
     * The actual error for this line
     * @type {string}
     * @memberof ErrorLine
     */
    'error': string;
}
/**
 * An error has occurred, and this object contains the detailed information
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     *
     * @type {string}
     * @memberof ErrorResponse
     */
    'message': string;
}
/**
 * A specific basic event happening in the calendar
 * @export
 * @interface EventCalendarEntry
 */
export interface EventCalendarEntry {
    /**
     * Unique ID for the entry. Set to 0 to insert a new entry.
     * @type {number}
     * @memberof EventCalendarEntry
     */
    'id': number;
    /**
     * Version number for concurrency control. Ignored when initially saving, incremented automatically on each save. The version number must be specified and must match the version in the DB. If the versions don\'t match the save request will fail.
     * @type {number}
     * @memberof EventCalendarEntry
     */
    'version': number;
    /**
     *
     * @type {CalendarTypeEnum}
     * @memberof EventCalendarEntry
     */
    'calendarType': CalendarTypeEnum;
    /**
     *
     * @type {CalendarEntryState}
     * @memberof EventCalendarEntry
     */
    'state': CalendarEntryState;
    /**
     * Linux epoch for when this entry starts
     * @type {number}
     * @memberof EventCalendarEntry
     */
    'startDate': number;
    /**
     * Linux epoch for when this entry ends
     * @type {number}
     * @memberof EventCalendarEntry
     */
    'endDate': number;
    /**
     * Linux epoch for when this entry **actually** started
     * @type {number}
     * @memberof EventCalendarEntry
     */
    'actualStartDate'?: number;
    /**
     * Linux epoch for when this entry **actually** ended
     * @type {number}
     * @memberof EventCalendarEntry
     */
    'actualEndDate'?: number;
    /**
     * Quick summary for this entry
     * @type {string}
     * @memberof EventCalendarEntry
     */
    'description'?: string;
    /**
     * Any additional notes entered by users
     * @type {string}
     * @memberof EventCalendarEntry
     */
    'notes'?: string;
    /**
     *
     * @type {StaffBlock}
     * @memberof EventCalendarEntry
     */
    'requiredStaff': StaffBlock;
    /**
     * Unique ID to reference an entity
     * @type {number}
     * @memberof EventCalendarEntry
     */
    'calendarId': number;
    /**
     * Unique ID to reference an entity
     * @type {number}
     * @memberof EventCalendarEntry
     */
    'venueId'?: number;
    /**
     *
     * @type {BillingType}
     * @memberof EventCalendarEntry
     */
    'billingType'?: BillingType;
    /**
     * Optional subtitle for entries, usually displayed next/under default entry naming
     * @type {string}
     * @memberof EventCalendarEntry
     */
    'subTitle'?: string;
    /**
     * Linux epoch when staff should be at the depo
     * @type {number}
     * @memberof EventCalendarEntry
     */
    'depotTime': number;
    /**
     * Linux epoch when staff should be at the depo
     * @type {number}
     * @memberof EventCalendarEntry
     */
    'venueTime': number;
}


/**
 *
 * @export
 * @interface EventCalendarEntryAllOf
 */
export interface EventCalendarEntryAllOf {
    /**
     * Linux epoch when staff should be at the depo
     * @type {number}
     * @memberof EventCalendarEntryAllOf
     */
    'depotTime': number;
    /**
     * Linux epoch when staff should be at the depo
     * @type {number}
     * @memberof EventCalendarEntryAllOf
     */
    'venueTime': number;
}
/**
 *
 * @export
 * @interface EventsReportEntry
 */
export interface EventsReportEntry {
    /**
     *
     * @type {number}
     * @memberof EventsReportEntry
     */
    'entryId': number;
    /**
     *
     * @type {any}
     * @memberof EventsReportEntry
     */
    'entryName': any;
    /**
     *
     * @type {number}
     * @memberof EventsReportEntry
     */
    'venueId'?: number;
    /**
     *
     * @type {string}
     * @memberof EventsReportEntry
     */
    'venueName'?: string;
    /**
     *
     * @type {number}
     * @memberof EventsReportEntry
     */
    'startDate': number;
    /**
     *
     * @type {number}
     * @memberof EventsReportEntry
     */
    'endDate': number;
    /**
     *
     * @type {Array<EventsReportEntryStaffSection>}
     * @memberof EventsReportEntry
     */
    'sections': Array<EventsReportEntryStaffSection>;
}
/**
 *
 * @export
 * @interface EventsReportEntryAssignment
 */
export interface EventsReportEntryAssignment {
    /**
     *
     * @type {AssignmentState}
     * @memberof EventsReportEntryAssignment
     */
    'state': AssignmentState;
    /**
     *
     * @type {string}
     * @memberof EventsReportEntryAssignment
     */
    'staffId'?: string;
    /**
     *
     * @type {string}
     * @memberof EventsReportEntryAssignment
     */
    'staffName'?: string;
}


/**
 *
 * @export
 * @interface EventsReportEntryStaffSection
 */
export interface EventsReportEntryStaffSection {
    /**
     *
     * @type {string}
     * @memberof EventsReportEntryStaffSection
     */
    'sectionName': string;
    /**
     *
     * @type {Array<EventsReportEntryStaffSectionGrouping>}
     * @memberof EventsReportEntryStaffSection
     */
    'groups': Array<EventsReportEntryStaffSectionGrouping>;
}
/**
 *
 * @export
 * @interface EventsReportEntryStaffSectionGrouping
 */
export interface EventsReportEntryStaffSectionGrouping {
    /**
     *
     * @type {string}
     * @memberof EventsReportEntryStaffSectionGrouping
     */
    'groupName': string;
    /**
     *
     * @type {number}
     * @memberof EventsReportEntryStaffSectionGrouping
     */
    'numSlots': number;
    /**
     *
     * @type {Array<EventsReportEntryAssignment>}
     * @memberof EventsReportEntryStaffSectionGrouping
     */
    'assignments': Array<EventsReportEntryAssignment>;
}
/**
 *
 * @export
 * @interface EventsReportRequest
 */
export interface EventsReportRequest {
    /**
     *
     * @type {number}
     * @memberof EventsReportRequest
     */
    'calendarId': number;
    /**
     *
     * @type {number}
     * @memberof EventsReportRequest
     */
    'startDateInclusive': number;
    /**
     *
     * @type {number}
     * @memberof EventsReportRequest
     */
    'endDateExclusive': number;
}
/**
 *
 * @export
 * @interface EventsReportResponse
 */
export interface EventsReportResponse {
    /**
     *
     * @type {number}
     * @memberof EventsReportResponse
     */
    'calendarId'?: number;
    /**
     *
     * @type {number}
     * @memberof EventsReportResponse
     */
    'startDateInclusive'?: number;
    /**
     *
     * @type {number}
     * @memberof EventsReportResponse
     */
    'endDateExclusive'?: number;
    /**
     *
     * @type {Array<EventsReportEntry>}
     * @memberof EventsReportResponse
     */
    'entries'?: Array<EventsReportEntry>;
}
/**
 * A specific front-line event happening in the calendar
 * @export
 * @interface FrontlineCalendarEntry
 */
export interface FrontlineCalendarEntry {
    /**
     * Unique ID for the entry. Set to 0 to insert a new entry.
     * @type {number}
     * @memberof FrontlineCalendarEntry
     */
    'id': number;
    /**
     * Version number for concurrency control. Ignored when initially saving, incremented automatically on each save. The version number must be specified and must match the version in the DB. If the versions don\'t match the save request will fail.
     * @type {number}
     * @memberof FrontlineCalendarEntry
     */
    'version': number;
    /**
     *
     * @type {CalendarTypeEnum}
     * @memberof FrontlineCalendarEntry
     */
    'calendarType': CalendarTypeEnum;
    /**
     *
     * @type {CalendarEntryState}
     * @memberof FrontlineCalendarEntry
     */
    'state': CalendarEntryState;
    /**
     * Linux epoch for when this entry starts
     * @type {number}
     * @memberof FrontlineCalendarEntry
     */
    'startDate': number;
    /**
     * Linux epoch for when this entry ends
     * @type {number}
     * @memberof FrontlineCalendarEntry
     */
    'endDate': number;
    /**
     * Linux epoch for when this entry **actually** started
     * @type {number}
     * @memberof FrontlineCalendarEntry
     */
    'actualStartDate'?: number;
    /**
     * Linux epoch for when this entry **actually** ended
     * @type {number}
     * @memberof FrontlineCalendarEntry
     */
    'actualEndDate'?: number;
    /**
     * Quick summary for this entry
     * @type {string}
     * @memberof FrontlineCalendarEntry
     */
    'description'?: string;
    /**
     * Any additional notes entered by users
     * @type {string}
     * @memberof FrontlineCalendarEntry
     */
    'notes'?: string;
    /**
     *
     * @type {StaffBlock}
     * @memberof FrontlineCalendarEntry
     */
    'requiredStaff': StaffBlock;
    /**
     * Unique ID to reference an entity
     * @type {number}
     * @memberof FrontlineCalendarEntry
     */
    'calendarId': number;
    /**
     * Unique ID to reference an entity
     * @type {number}
     * @memberof FrontlineCalendarEntry
     */
    'venueId'?: number;
    /**
     *
     * @type {BillingType}
     * @memberof FrontlineCalendarEntry
     */
    'billingType'?: BillingType;
    /**
     * Optional subtitle for entries, usually displayed next/under default entry naming
     * @type {string}
     * @memberof FrontlineCalendarEntry
     */
    'subTitle'?: string;
}


/**
 * ID of a related entity, and a name should the entity disappear / not be fetched
 * @export
 * @interface IdName
 */
export interface IdName {
    /**
     * ID of the item linked to
     * @type {string}
     * @memberof IdName
     */
    'id': string;
    /**
     * Name of the item, at the time of link creation
     * @type {string}
     * @memberof IdName
     */
    'name': string;
}
/**
 * Individual line within the invoice. A line is an event / shift / or other discrete action performed.
 * @export
 * @interface InvoiceLine
 */
export interface InvoiceLine {
    /**
     *
     * @type {string}
     * @memberof InvoiceLine
     */
    'calendarName': string;
    /**
     *
     * @type {string}
     * @memberof InvoiceLine
     */
    'venueName': string;
    /**
     *
     * @type {number}
     * @memberof InvoiceLine
     */
    'calendarId'?: number;
    /**
     *
     * @type {number}
     * @memberof InvoiceLine
     */
    'entryId'?: number;
    /**
     * Xero account code, should match up with a specific calendar
     * @type {string}
     * @memberof InvoiceLine
     */
    'accountCode': string;
    /**
     * Linux epoch for the start of the line (event, shift, etc)
     * @type {number}
     * @memberof InvoiceLine
     */
    'date': number;
    /**
     * Date, but as a standard string used for invoice lines
     * @type {string}
     * @memberof InvoiceLine
     */
    'dateString': string;
    /**
     *
     * @type {BillingType}
     * @memberof InvoiceLine
     */
    'type': BillingType;
    /**
     * Number of hours worked on the event
     * @type {number}
     * @memberof InvoiceLine
     */
    'numberHours': number;
    /**
     * Rate per hour or for the whole day, depending on the type
     * @type {number}
     * @memberof InvoiceLine
     */
    'rate': number;
    /**
     * Read-only total calculated for this line (don\'t calculate based on hours / rate / type independently)
     * @type {number}
     * @memberof InvoiceLine
     */
    'amount': number;
}


/**
 * Log a user as being on the reserve list, email will be sent regardless on if it was sent before.
 * @export
 * @interface LogUserInterestReserve
 */
export interface LogUserInterestReserve {
    /**
     * ID of the entry the interest is for
     * @type {number}
     * @memberof LogUserInterestReserve
     */
    'entryId': number;
    /**
     * ID of the staff member the interest is for
     * @type {string}
     * @memberof LogUserInterestReserve
     */
    'staffId': string;
}
/**
 *
 * @export
 * @interface MarkAttendedRequest
 */
export interface MarkAttendedRequest {
    /**
     * Unique ID of the event that needs to have staff marked as attended
     * @type {number}
     * @memberof MarkAttendedRequest
     */
    'entryId': number;
    /**
     * Unique ID of each staff member that should be marked as attended
     * @type {Array<string>}
     * @memberof MarkAttendedRequest
     */
    'staffIds': Array<string>;
}
/**
 * A specific day for on-call staff
 * @export
 * @interface OnCallCalendarEntry
 */
export interface OnCallCalendarEntry {
    /**
     * Unique ID for the entry. Set to 0 to insert a new entry.
     * @type {number}
     * @memberof OnCallCalendarEntry
     */
    'id': number;
    /**
     * Version number for concurrency control. Ignored when initially saving, incremented automatically on each save. The version number must be specified and must match the version in the DB. If the versions don\'t match the save request will fail.
     * @type {number}
     * @memberof OnCallCalendarEntry
     */
    'version': number;
    /**
     *
     * @type {CalendarTypeEnum}
     * @memberof OnCallCalendarEntry
     */
    'calendarType': CalendarTypeEnum;
    /**
     *
     * @type {CalendarEntryState}
     * @memberof OnCallCalendarEntry
     */
    'state': CalendarEntryState;
    /**
     * Linux epoch for when this entry starts
     * @type {number}
     * @memberof OnCallCalendarEntry
     */
    'startDate': number;
    /**
     * Linux epoch for when this entry ends
     * @type {number}
     * @memberof OnCallCalendarEntry
     */
    'endDate': number;
    /**
     * Linux epoch for when this entry **actually** started
     * @type {number}
     * @memberof OnCallCalendarEntry
     */
    'actualStartDate'?: number;
    /**
     * Linux epoch for when this entry **actually** ended
     * @type {number}
     * @memberof OnCallCalendarEntry
     */
    'actualEndDate'?: number;
    /**
     * Quick summary for this entry
     * @type {string}
     * @memberof OnCallCalendarEntry
     */
    'description'?: string;
    /**
     * Any additional notes entered by users
     * @type {string}
     * @memberof OnCallCalendarEntry
     */
    'notes'?: string;
    /**
     *
     * @type {StaffBlock}
     * @memberof OnCallCalendarEntry
     */
    'requiredStaff': StaffBlock;
    /**
     * Unique ID to reference an entity
     * @type {number}
     * @memberof OnCallCalendarEntry
     */
    'calendarId': number;
    /**
     * Unique ID to reference an entity
     * @type {number}
     * @memberof OnCallCalendarEntry
     */
    'venueId'?: number;
    /**
     *
     * @type {BillingType}
     * @memberof OnCallCalendarEntry
     */
    'billingType'?: BillingType;
    /**
     * Optional subtitle for entries, usually displayed next/under default entry naming
     * @type {string}
     * @memberof OnCallCalendarEntry
     */
    'subTitle'?: string;
    /**
     * Current UK terrorism alert level
     * @type {string}
     * @memberof OnCallCalendarEntry
     */
    'alertLevel': string;
    /**
     *
     * @type {Array<OnCallClinicalNote>}
     * @memberof OnCallCalendarEntry
     */
    'clinicalNotes'?: Array<OnCallClinicalNote>;
}


/**
 *
 * @export
 * @interface OnCallCalendarEntryAllOf
 */
export interface OnCallCalendarEntryAllOf {
    /**
     * Current UK terrorism alert level
     * @type {string}
     * @memberof OnCallCalendarEntryAllOf
     */
    'alertLevel': string;
    /**
     *
     * @type {Array<OnCallClinicalNote>}
     * @memberof OnCallCalendarEntryAllOf
     */
    'clinicalNotes'?: Array<OnCallClinicalNote>;
}
/**
 * Single note for an On-Call calendar event
 * @export
 * @interface OnCallClinicalNote
 */
export interface OnCallClinicalNote {
    /**
     *
     * @type {number}
     * @memberof OnCallClinicalNote
     */
    'date'?: number;
    /**
     *
     * @type {string}
     * @memberof OnCallClinicalNote
     */
    'staffName'?: string;
    /**
     *
     * @type {string}
     * @memberof OnCallClinicalNote
     */
    'note'?: string;
}
/**
 * Remove a user from all events from a certain date
 * @export
 * @interface RemoveUserRequest
 */
export interface RemoveUserRequest {
    /**
     * ID / Username of the user to remove
     * @type {string}
     * @memberof RemoveUserRequest
     */
    'staffId'?: string;
    /**
     * Earliest date, inclusive, to remove the user from
     * @type {number}
     * @memberof RemoveUserRequest
     */
    'fromDate'?: number;
}
/**
 * If an event is already in place (same start date, venue and calendar), use this conflict resolution strategy
 * @export
 * @enum {string}
 */

export enum RepeatAssignmentConflictResolution {
    Skip = 'Skip',
    Replace = 'Replace',
    ReplaceStaff = 'ReplaceStaff'
}


/**
 * Repeat multiple entries across multiple calendars until a specified date
 * @export
 * @interface RepeatAssignmentsRequest
 */
export interface RepeatAssignmentsRequest {
    /**
     * IDs of the calendars to repeat the entries for
     * @type {Array<number>}
     * @memberof RepeatAssignmentsRequest
     */
    'calendarIds': Array<number>;
    /**
     * Linux timestamp for the earliest start date, inclusive, for entries to fetch as part of the repeat sequence
     * @type {number}
     * @memberof RepeatAssignmentsRequest
     */
    'copyStartDateInclusive': number;
    /**
     * Linux timestamp for the last start date, exclusive, for entries to fetch as part of the repeat sequence
     * @type {number}
     * @memberof RepeatAssignmentsRequest
     */
    'copyEndDateExclusive': number;
    /**
     * Linux timestamp for the very last valid start date, exclusive, for repeating entries
     * @type {number}
     * @memberof RepeatAssignmentsRequest
     */
    'repeatEndDateExclusive': number;
    /**
     *
     * @type {RepeatAssignmentConflictResolution}
     * @memberof RepeatAssignmentsRequest
     */
    'conflictResolution'?: RepeatAssignmentConflictResolution;
    /**
     *
     * @type {AssignmentState}
     * @memberof RepeatAssignmentsRequest
     */
    'assignmentState'?: AssignmentState;
}


/**
 * Repeat an entry until a specified date
 * @export
 * @interface RepeatEntryRequest
 */
export interface RepeatEntryRequest {
    /**
     * The first entry in the sequence, this should be ready populated
     * @type {number}
     * @memberof RepeatEntryRequest
     */
    'baseEntryId': number;
    /**
     *
     * @type {RepeatType}
     * @memberof RepeatEntryRequest
     */
    'type': RepeatType;
    /**
     * The multiplier against the repeat type, to dictate how far apart each entry should be. If repeat type is \'Day\', 1 will result in an entry every day. 2 will result in an entry every other day.
     * @type {number}
     * @memberof RepeatEntryRequest
     */
    'spacing': number;
    /**
     * Linux epoch for when the repeat should stop. Once this date is hit, no more entries will be created. Generally set this to the day after the very last entry you desire. If you want to repeat until the end of the year, set this to the first day of the new year.
     * @type {number}
     * @memberof RepeatEntryRequest
     */
    'endDateExclusive': number;
}


/**
 * Repeat as calendar function, for example repeat \'Weekly\'
 * @export
 * @enum {string}
 */

export enum RepeatType {
    Day = 'Day',
    Week = 'Week',
    Month = 'Month'
}


/**
 *
 * @export
 * @interface ResendUserNotificationsRequest
 */
export interface ResendUserNotificationsRequest {
    /**
     * Unique ID for the staff member
     * @type {string}
     * @memberof ResendUserNotificationsRequest
     */
    'staffId': string;
    /**
     * Unique ID of the event that needs to have staff marked as attended
     * @type {number}
     * @memberof ResendUserNotificationsRequest
     */
    'entryId': number;
}
/**
 * The level of access a staff member has
 * @export
 * @enum {string}
 */

export enum StaffAccessLevelEnum {
    SystemAdministrator = 'SystemAdministrator',
    ComplianceManager = 'ComplianceManager',
    DutyManager = 'DutyManager'
}


/**
 * Individual assignment of a staff member to an event
 * @export
 * @interface StaffAssignment
 */
export interface StaffAssignment {
    /**
     * Unique ID for this section
     * @type {string}
     * @memberof StaffAssignment
     */
    'id': string;
    /**
     *
     * @type {StaffLink}
     * @memberof StaffAssignment
     */
    'staffMember'?: StaffLink;
    /**
     *
     * @type {AssignmentState}
     * @memberof StaffAssignment
     */
    'state': AssignmentState;
    /**
     *
     * @type {number}
     * @memberof StaffAssignment
     */
    'rate'?: number;
    /**
     * Date the staff member is due to start work. Overrides event\'s start date. If endDate is specified, startDate MUST be specified.
     * @type {number}
     * @memberof StaffAssignment
     */
    'startDate'?: number;
    /**
     * Date the staff member is due to finish work. Overrides event\'s end date. If startDate is specified, endDate MUST be specified.
     * @type {number}
     * @memberof StaffAssignment
     */
    'endDate'?: number;
}


/**
 * Grouping with direct staff assignments
 * @export
 * @interface StaffAssignmentGrouping
 */
export interface StaffAssignmentGrouping {
    /**
     * Unique ID for this section
     * @type {string}
     * @memberof StaffAssignmentGrouping
     */
    'id': string;
    /**
     * Name of the grouping, for example \'Technicians\'
     * @type {string}
     * @memberof StaffAssignmentGrouping
     */
    'name': string;
    /**
     * Restrict the clinical grades of staff to this list. Empty list to signify no restrictions.
     * @type {Array<ClinicalGrade>}
     * @memberof StaffAssignmentGrouping
     */
    'clinicalGrades': Array<ClinicalGrade>;
    /**
     * Assignments if applicable. If this is a template, this should be an empty array
     * @type {Array<StaffAssignment>}
     * @memberof StaffAssignmentGrouping
     */
    'assignments': Array<StaffAssignment>;
}
/**
 * The slots for staff to be assigned to a calendar entry, as well as the assignments themselves if applicable
 * @export
 * @interface StaffBlock
 */
export interface StaffBlock {
    /**
     *
     * @type {Array<StaffBlockSection>}
     * @memberof StaffBlock
     */
    'sections': Array<StaffBlockSection>;
}
/**
 * Section of staff members, for example \'Stadium and Ambulance Crew\'
 * @export
 * @interface StaffBlockSection
 */
export interface StaffBlockSection {
    /**
     * Unique ID for this section
     * @type {string}
     * @memberof StaffBlockSection
     */
    'id': string;
    /**
     * Name of this section, for example \'Stadium and Ambulance Crew\'
     * @type {string}
     * @memberof StaffBlockSection
     */
    'name': string;
    /**
     * Sub groupings for this section, for example \'Technicians\'
     * @type {Array<StaffAssignmentGrouping>}
     * @memberof StaffBlockSection
     */
    'groupings': Array<StaffAssignmentGrouping>;
}
/**
 * Named empty StaffBlock that can be used in other parts of the system to kick-start a staffblock
 * @export
 * @interface StaffBlockTemplate
 */
export interface StaffBlockTemplate {
    /**
     * Unique ID for the calendar. Set to 0 to insert a new calendar.
     * @type {number}
     * @memberof StaffBlockTemplate
     */
    'id': number;
    /**
     *
     * @type {string}
     * @memberof StaffBlockTemplate
     */
    'name': string;
    /**
     *
     * @type {StaffBlock}
     * @memberof StaffBlockTemplate
     */
    'staffBlock': StaffBlock;
}
/**
 * Basic information on a StaffBlockTemplate for listing purposes
 * @export
 * @interface StaffBlockTemplateListEntry
 */
export interface StaffBlockTemplateListEntry {
    /**
     * Unique ID for the calendar. Set to 0 to insert a new calendar.
     * @type {number}
     * @memberof StaffBlockTemplateListEntry
     */
    'id': number;
    /**
     *
     * @type {string}
     * @memberof StaffBlockTemplateListEntry
     */
    'name': string;
}
/**
 * Fields required to get combined calendar entry and interest data
 * @export
 * @interface StaffInterestQueryFields
 */
export interface StaffInterestQueryFields {
    /**
     * Linux epoch of the minimum date, inclusive, for the data
     * @type {number}
     * @memberof StaffInterestQueryFields
     */
    'startDate': number;
    /**
     * Linux epoch for the maximum date, exclusive, for the data
     * @type {number}
     * @memberof StaffInterestQueryFields
     */
    'endDate': number;
    /**
     * Optionally include calendar entries that have no empty slots. By default only entries that have at least one empty slot will be returned. Set this to true to include entries with 0 empty slots.
     * @type {boolean}
     * @memberof StaffInterestQueryFields
     */
    'includeFullEntries'?: boolean;
}
/**
 * Combined calendar entries and interest results
 * @export
 * @interface StaffInterestQueryResponse
 */
export interface StaffInterestQueryResponse {
    /**
     *
     * @type {Array<CalendarSummary>}
     * @memberof StaffInterestQueryResponse
     */
    'calendarSummaries'?: Array<CalendarSummary>;
    /**
     *
     * @type {Array<CalendarEntry>}
     * @memberof StaffInterestQueryResponse
     */
    'entries': Array<CalendarEntry>;
    /**
     *
     * @type {Array<UserInterest>}
     * @memberof StaffInterestQueryResponse
     */
    'interestList': Array<UserInterest>;
}
/**
 * Direct reference to a staff member
 * @export
 * @interface StaffLink
 */
export interface StaffLink {
    /**
     * Unique reference to the staff member. Alternatively email address if user is external.
     * @type {string}
     * @memberof StaffLink
     */
    'staffId': string;
    /**
     * Real name of the staff member at moment of assignment
     * @type {string}
     * @memberof StaffLink
     */
    'staffName': string;
    /**
     * Whether this user is an external user, and therefore not in staff management.
     * @type {boolean}
     * @memberof StaffLink
     */
    'external'?: boolean;
    /**
     * List of flags, if any, for the user
     * @type {Array<string>}
     * @memberof StaffLink
     */
    'flags'?: Array<string>;
}
/**
 * Invoice for a month for a single staff member, all data
 * @export
 * @interface StaffMemberInvoice
 */
export interface StaffMemberInvoice {
    /**
     * Username for staff member
     * @type {string}
     * @memberof StaffMemberInvoice
     */
    'staffId': string;
    /**
     * Name of the staff member
     * @type {string}
     * @memberof StaffMemberInvoice
     */
    'staffName': string;
    /**
     * If the invoice is complete and ready for full submission. If false there are fundamental issues preventing the completion of the invoice - these will be visible in the errors
     * @type {boolean}
     * @memberof StaffMemberInvoice
     */
    'incomplete': boolean;
    /**
     * Individual invoice components / tasks that make up the invoice
     * @type {Array<InvoiceLine>}
     * @memberof StaffMemberInvoice
     */
    'lines': Array<InvoiceLine>;
    /**
     * Any errors that have been encountered when generating the invoice, or when attempting to submit it to Xero
     * @type {Array<ErrorLine>}
     * @memberof StaffMemberInvoice
     */
    'errors': Array<ErrorLine>;
}
/**
 *
 * @export
 * @interface StaffPendingAction
 */
export interface StaffPendingAction {
    /**
     *
     * @type {string}
     * @memberof StaffPendingAction
     */
    'staffId': string;
    /**
     *
     * @type {number}
     * @memberof StaffPendingAction
     */
    'calendarId': number;
    /**
     *
     * @type {string}
     * @memberof StaffPendingAction
     */
    'calendarName': string;
    /**
     *
     * @type {CalendarTypeEnum}
     * @memberof StaffPendingAction
     */
    'calendarType': CalendarTypeEnum;
    /**
     *
     * @type {number}
     * @memberof StaffPendingAction
     */
    'venueId'?: number;
    /**
     *
     * @type {string}
     * @memberof StaffPendingAction
     */
    'venueName'?: string;
    /**
     *
     * @type {number}
     * @memberof StaffPendingAction
     */
    'entryId': number;
    /**
     *
     * @type {string}
     * @memberof StaffPendingAction
     */
    'entryName': string;
    /**
     *
     * @type {number}
     * @memberof StaffPendingAction
     */
    'startDate': number;
    /**
     *
     * @type {number}
     * @memberof StaffPendingAction
     */
    'endDate': number;
    /**
     *
     * @type {string}
     * @memberof StaffPendingAction
     */
    'assignmentId': string;
    /**
     *
     * @type {AssignmentState}
     * @memberof StaffPendingAction
     */
    'currentState': AssignmentState;
}


/**
 *
 * @export
 * @interface StaffReportEntry
 */
export interface StaffReportEntry {
    /**
     *
     * @type {number}
     * @memberof StaffReportEntry
     */
    'calendarId': number;
    /**
     *
     * @type {string}
     * @memberof StaffReportEntry
     */
    'calendarName': string;
    /**
     *
     * @type {number}
     * @memberof StaffReportEntry
     */
    'venueId'?: number;
    /**
     *
     * @type {string}
     * @memberof StaffReportEntry
     */
    'venueName'?: string;
    /**
     *
     * @type {number}
     * @memberof StaffReportEntry
     */
    'entryId': number;
    /**
     *
     * @type {string}
     * @memberof StaffReportEntry
     */
    'entryName': string;
    /**
     *
     * @type {number}
     * @memberof StaffReportEntry
     */
    'entryStartDate': number;
    /**
     *
     * @type {number}
     * @memberof StaffReportEntry
     */
    'entryEndDate': number;
    /**
     *
     * @type {boolean}
     * @memberof StaffReportEntry
     */
    'attended': boolean;
    /**
     *
     * @type {AssignmentState}
     * @memberof StaffReportEntry
     */
    'state': AssignmentState;
    /**
     *
     * @type {CalendarEntryState}
     * @memberof StaffReportEntry
     */
    'entryState'?: CalendarEntryState;
    /**
     *
     * @type {number}
     * @memberof StaffReportEntry
     */
    'actualStartDate'?: number;
    /**
     *
     * @type {number}
     * @memberof StaffReportEntry
     */
    'actualEndDate'?: number;
    /**
     * The wage rate for this specific assignment, how this value is used depends on \'billingType\'
     * @type {number}
     * @memberof StaffReportEntry
     */
    'rate'?: number;
    /**
     * The calculated number of hours for this assignment, using best-guess based on all dates in the assignment / entry
     * @type {number}
     * @memberof StaffReportEntry
     */
    'numHours'?: number;
    /**
     *
     * @type {BillingType}
     * @memberof StaffReportEntry
     */
    'billingType'?: BillingType;
    /**
     * The calculated wage for the user using the number of hours, assignment rate, and billing type
     * @type {number}
     * @memberof StaffReportEntry
     */
    'wage'?: number;
}


/**
 *
 * @export
 * @interface StaffReportRequest
 */
export interface StaffReportRequest {
    /**
     *
     * @type {number}
     * @memberof StaffReportRequest
     */
    'startDateInclusive': number;
    /**
     *
     * @type {number}
     * @memberof StaffReportRequest
     */
    'endDateExclusive': number;
    /**
     * Unique ID for the staff member
     * @type {string}
     * @memberof StaffReportRequest
     */
    'staffId': string;
}
/**
 *
 * @export
 * @interface StaffReportResponse
 */
export interface StaffReportResponse {
    /**
     *
     * @type {number}
     * @memberof StaffReportResponse
     */
    'startDateExclusive': number;
    /**
     *
     * @type {number}
     * @memberof StaffReportResponse
     */
    'endDateExclusive': number;
    /**
     * Unique ID for the staff member
     * @type {string}
     * @memberof StaffReportResponse
     */
    'staffId': string;
    /**
     *
     * @type {Array<StaffReportEntry>}
     * @memberof StaffReportResponse
     */
    'entries': Array<StaffReportEntry>;
}
/**
 *
 * @export
 * @interface StaffWageReportEntry
 */
export interface StaffWageReportEntry {
    /**
     *
     * @type {number}
     * @memberof StaffWageReportEntry
     */
    'calendarId': number;
    /**
     *
     * @type {string}
     * @memberof StaffWageReportEntry
     */
    'calendarName': string;
    /**
     *
     * @type {CalendarTypeEnum}
     * @memberof StaffWageReportEntry
     */
    'calendarType'?: CalendarTypeEnum;
    /**
     *
     * @type {number}
     * @memberof StaffWageReportEntry
     */
    'entryId': number;
    /**
     *
     * @type {string}
     * @memberof StaffWageReportEntry
     */
    'entryName': string;
    /**
     *
     * @type {number}
     * @memberof StaffWageReportEntry
     */
    'startDate': number;
    /**
     *
     * @type {number}
     * @memberof StaffWageReportEntry
     */
    'endDate': number;
    /**
     *
     * @type {BillingType}
     * @memberof StaffWageReportEntry
     */
    'billingType': BillingType;
    /**
     *
     * @type {Array<StaffWageReportEntryStaffMember>}
     * @memberof StaffWageReportEntry
     */
    'staff': Array<StaffWageReportEntryStaffMember>;
}


/**
 *
 * @export
 * @interface StaffWageReportEntryStaffMember
 */
export interface StaffWageReportEntryStaffMember {
    /**
     *
     * @type {string}
     * @memberof StaffWageReportEntryStaffMember
     */
    'staffId': string;
    /**
     *
     * @type {string}
     * @memberof StaffWageReportEntryStaffMember
     */
    'staffName': string;
    /**
     *
     * @type {number}
     * @memberof StaffWageReportEntryStaffMember
     */
    'assignmentRate': number;
    /**
     *
     * @type {number}
     * @memberof StaffWageReportEntryStaffMember
     */
    'numHours': number;
    /**
     *
     * @type {number}
     * @memberof StaffWageReportEntryStaffMember
     */
    'wage': number;
    /**
     *
     * @type {number}
     * @memberof StaffWageReportEntryStaffMember
     */
    'startDate': number;
    /**
     *
     * @type {number}
     * @memberof StaffWageReportEntryStaffMember
     */
    'endDate': number;
}
/**
 *
 * @export
 * @interface StaffWageReportRequest
 */
export interface StaffWageReportRequest {
    /**
     *
     * @type {number}
     * @memberof StaffWageReportRequest
     */
    'startDateInclusive': number;
    /**
     *
     * @type {number}
     * @memberof StaffWageReportRequest
     */
    'endDateExclusive': number;
}
/**
 *
 * @export
 * @interface StaffWageReportResponse
 */
export interface StaffWageReportResponse {
    /**
     *
     * @type {number}
     * @memberof StaffWageReportResponse
     */
    'startDateInclusive'?: number;
    /**
     *
     * @type {number}
     * @memberof StaffWageReportResponse
     */
    'endDateExclusive'?: number;
    /**
     *
     * @type {Array<StaffWageReportEntry>}
     * @memberof StaffWageReportResponse
     */
    'entries'?: Array<StaffWageReportEntry>;
}
/**
 *
 * @export
 * @interface UserEntriesRequest
 */
export interface UserEntriesRequest {
    /**
     * Unique ID for the staff member
     * @type {string}
     * @memberof UserEntriesRequest
     */
    'userId': string;
    /**
     * Start date to get entries for, inclusive
     * @type {number}
     * @memberof UserEntriesRequest
     */
    'startDate': number;
    /**
     * End date to get entries for, exclusive
     * @type {number}
     * @memberof UserEntriesRequest
     */
    'endDate': number;
}
/**
 * Record of a user\'s interest in an event
 * @export
 * @interface UserInterest
 */
export interface UserInterest {
    /**
     * ID of the entry the user is interested in
     * @type {number}
     * @memberof UserInterest
     */
    'entryId': number;
    /**
     *
     * @type {StaffLink}
     * @memberof UserInterest
     */
    'user': StaffLink;
    /**
     *
     * @type {IdName}
     * @memberof UserInterest
     */
    'section': IdName;
    /**
     *
     * @type {IdName}
     * @memberof UserInterest
     */
    'group': IdName;
    /**
     * User has received a \'your are on the reserve list\' notification
     * @type {boolean}
     * @memberof UserInterest
     */
    'reserveNotification': boolean;
}
/**
 * Request a list of matching UserInterests
 * @export
 * @interface UserInterestListRequest
 */
export interface UserInterestListRequest {
    /**
     * Linux epoch of the minimum date, inclusive, for the entry the UserInterest is for
     * @type {number}
     * @memberof UserInterestListRequest
     */
    'startDate': number;
    /**
     * Linux epoch for the maximum date, exclusive, for the entry the UserInterest is for
     * @type {number}
     * @memberof UserInterestListRequest
     */
    'endDate': number;
    /**
     * OPTIONAL filter to UserInterest with this staffId
     * @type {string}
     * @memberof UserInterestListRequest
     */
    'staffId'?: string;
}
/**
 * Notify that a user is interested in a shift
 * @export
 * @interface UserInterestShiftRequest
 */
export interface UserInterestShiftRequest {
    /**
     * The name of the event
     * @type {string}
     * @memberof UserInterestShiftRequest
     */
    'eventName': string;
    /**
     * Linux epoch for when the event starts
     * @type {number}
     * @memberof UserInterestShiftRequest
     */
    'eventDate': number;
    /**
     * Name of the section where the user is interested
     * @type {string}
     * @memberof UserInterestShiftRequest
     */
    'sectionName': string;
    /**
     * Name of the group within the section that the user is interested
     * @type {string}
     * @memberof UserInterestShiftRequest
     */
    'groupName': string;
}
/**
 * Location where a calendar entry can take place
 * @export
 * @interface Venue
 */
export interface Venue {
    /**
     * Unique ID for the venue. Set to 0 to insert a new venue.
     * @type {number}
     * @memberof Venue
     */
    'id': number;
    /**
     *
     * @type {string}
     * @memberof Venue
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof Venue
     */
    'state': VenueStateEnum;
    /**
     * Unique ID of the calendar this venue is for
     * @type {number}
     * @memberof Venue
     */
    'calendarId': number;
    /**
     * Postal address for Venue
     * @type {string}
     * @memberof Venue
     */
    'address': string;
    /**
     * Post code for Venue
     * @type {string}
     * @memberof Venue
     */
    'postcode': string;
    /**
     * Optional URL for the logo for the Venue
     * @type {string}
     * @memberof Venue
     */
    'logoUrl'?: string;
    /**
     * Optional tracking items in Xero for this venue
     * @type {Array<XeroTrackingLink>}
     * @memberof Venue
     */
    'xeroTracking'?: Array<XeroTrackingLink>;
    /**
     * Optional unique ID for the region this venue is within
     * @type {number}
     * @memberof Venue
     */
    'regionId'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum VenueStateEnum {
    Active = 'Active',
    Inactive = 'Inactive'
}

/**
 * Basic overview of linkage with Xero from GRS
 * @export
 * @interface XeroAuthStatus
 */
export interface XeroAuthStatus {
    /**
     * If there is a link currently setup
     * @type {boolean}
     * @memberof XeroAuthStatus
     */
    'linked': boolean;
    /**
     * The Xero tenant ID, useful for debugging
     * @type {string}
     * @memberof XeroAuthStatus
     */
    'tenantId'?: string;
}
/**
 * Trigger an individual invoice to be re-created and submitted to Xero
 * @export
 * @interface XeroAutoIndividual
 */
export interface XeroAutoIndividual {
    /**
     * Year of the invoice
     * @type {number}
     * @memberof XeroAutoIndividual
     */
    'year': number;
    /**
     * Month of the invoice
     * @type {number}
     * @memberof XeroAutoIndividual
     */
    'month': number;
    /**
     * Username for staff member
     * @type {string}
     * @memberof XeroAutoIndividual
     */
    'staffId': string;
}
/**
 * Data required to start the Xero auth process
 * @export
 * @interface XeroStartData
 */
export interface XeroStartData {
    /**
     * URL to send the user to, to initiate the Xero auth process
     * @type {string}
     * @memberof XeroStartData
     */
    'loginUrl': string;
}
/**
 * Individual Xero \'tracking\' category
 * @export
 * @interface XeroTrackingCategory
 */
export interface XeroTrackingCategory {
    /**
     * Unique Xero ID of the category
     * @type {string}
     * @memberof XeroTrackingCategory
     */
    'id': string;
    /**
     * Name of the category
     * @type {string}
     * @memberof XeroTrackingCategory
     */
    'name': string;
    /**
     * List of items available within the category
     * @type {Array<XeroTrackingItem>}
     * @memberof XeroTrackingCategory
     */
    'items': Array<XeroTrackingItem>;
}
/**
 * Full data on ALL Xero tracking categories and items
 * @export
 * @interface XeroTrackingInfo
 */
export interface XeroTrackingInfo {
    /**
     * The available categories. Only categories with at least 1 item will be returned
     * @type {Array<XeroTrackingCategory>}
     * @memberof XeroTrackingInfo
     */
    'categories'?: Array<XeroTrackingCategory>;
}
/**
 * Individual Xero \'tracking\' item
 * @export
 * @interface XeroTrackingItem
 */
export interface XeroTrackingItem {
    /**
     * Unique ID (from Xero) ID of the tracked item
     * @type {string}
     * @memberof XeroTrackingItem
     */
    'id': string;
    /**
     * Name of the Xero tracked item
     * @type {string}
     * @memberof XeroTrackingItem
     */
    'name': string;
}
/**
 * Link to a Xero \'tracking\' item
 * @export
 * @interface XeroTrackingLink
 */
export interface XeroTrackingLink {
    /**
     * Xero tracking category ID
     * @type {string}
     * @memberof XeroTrackingLink
     */
    'categoryId': string;
    /**
     * Xero tracking item ID
     * @type {string}
     * @memberof XeroTrackingLink
     */
    'itemId': string;
    /**
     * Name of the tracking item in Xero, for convenience
     * @type {any}
     * @memberof XeroTrackingLink
     */
    'name': any;
}

/**
 * CalendarApi - axios parameter creator
 * @export
 */
export const CalendarApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Delete calendar with the specified ID
         * @param {number} id Unique ID for the calendar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCalendar: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCalendar', 'id', id)
            const localVarPath = `/calendars/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete calendar entry with the specified ID
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCalendarEntry: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCalendarEntry', 'id', id)
            const localVarPath = `/calendars/entries/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete calendar group with the specified ID
         * @param {number} id Unique ID for the group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCalendarGroup: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCalendarGroup', 'id', id)
            const localVarPath = `/calendars/groups/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete a specific duty manager report
         * @param {number} id Unique ID for the duty manager report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDutyManagerReport: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDutyManagerReport', 'id', id)
            const localVarPath = `/reports/dutymanager/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete an existing interest record
         * @param {DeleteUserInterestRequest} deleteUserInterestRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInterest: async (deleteUserInterestRequest: DeleteUserInterestRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteUserInterestRequest' is not null or undefined
            assertParamExists('deleteInterest', 'deleteUserInterestRequest', deleteUserInterestRequest)
            const localVarPath = `/interest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteUserInterestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete an existing interest record
         * @param {UserInterest} userInterest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInterestForExisting: async (userInterest: UserInterest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userInterest' is not null or undefined
            assertParamExists('deleteInterestForExisting', 'userInterest', userInterest)
            const localVarPath = `/interest/forinterest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userInterest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete staff block template with the specified ID
         * @param {number} id Unique ID for the template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStaffBlockTemplate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteStaffBlockTemplate', 'id', id)
            const localVarPath = `/staffblocktemplates/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get a calendar with the specified ID
         * @param {number} id Unique ID for the calendar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCalendarById', 'id', id)
            const localVarPath = `/calendars/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get a calendar entry with the specified ID
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarEntryById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCalendarEntryById', 'id', id)
            const localVarPath = `/calendars/entries/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get a calendar group with the specified ID
         * @param {number} id Unique ID for the group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarGroupById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCalendarGroupById', 'id', id)
            const localVarPath = `/calendars/groups/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get a specific duty manager report
         * @param {number} id Unique ID for the duty manager report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDutyManagerReportById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDutyManagerReportById', 'id', id)
            const localVarPath = `/reports/dutymanager/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get the duty manager reports for a specific calendar entry
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDutyManagerReportsForCalendarEntry: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDutyManagerReportsForCalendarEntry', 'id', id)
            const localVarPath = `/reports/dutymanager/forcalentry/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get duty manager reports list, paged and filtered to specific criteria
         * @param {DutyManagerReportPagedRequest} dutyManagerReportPagedRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDutyManagerReportsPaged: async (dutyManagerReportPagedRequest: DutyManagerReportPagedRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dutyManagerReportPagedRequest' is not null or undefined
            assertParamExists('getDutyManagerReportsPaged', 'dutyManagerReportPagedRequest', dutyManagerReportPagedRequest)
            const localVarPath = `/reports/dutymanager/paged`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dutyManagerReportPagedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get a staff block template with the specified ID
         * @param {number} id Unique ID for the template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaffBlockTemplateById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getStaffBlockTemplateById', 'id', id)
            const localVarPath = `/staffblocktemplates/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all of the calendar entries that a user is booked on between some dates. Unlike the normal list method, this will return all entries that in any way overlap the provided date range, not just events that start and finish within the range.
         * @summary Get all of the calendar entries that a user is booked on between some dates.
         * @param {UserEntriesRequest} userEntriesRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserEntries: async (userEntriesRequest: UserEntriesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userEntriesRequest' is not null or undefined
            assertParamExists('getUserEntries', 'userEntriesRequest', userEntriesRequest)
            const localVarPath = `/calendars/entries/existing/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userEntriesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all calendar entries and interests within the date range. If admin, returns everything. If user, only returns interests for the user, along with entries that have a corresponding interest record.
         * @summary Get all of the calendar entries and interests for a date range.
         * @param {StaffInterestQueryFields} staffInterestQueryFields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interestQuery: async (staffInterestQueryFields: StaffInterestQueryFields, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'staffInterestQueryFields' is not null or undefined
            assertParamExists('interestQuery', 'staffInterestQueryFields', staffInterestQueryFields)
            const localVarPath = `/interest/query`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(staffInterestQueryFields, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get a list of all calendars in the system, and associated compliance
         * @param {CalendarComplianceRequest} calendarComplianceRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCalendarCompliance: async (calendarComplianceRequest: CalendarComplianceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calendarComplianceRequest' is not null or undefined
            assertParamExists('listCalendarCompliance', 'calendarComplianceRequest', calendarComplianceRequest)
            const localVarPath = `/calendars/list/summary/compliance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calendarComplianceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get a list of all calendar entries that meet the provided requirements
         * @param {CalendarEntryListRequest} calendarEntryListRequest Restrictions on the list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCalendarEntries: async (calendarEntryListRequest: CalendarEntryListRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calendarEntryListRequest' is not null or undefined
            assertParamExists('listCalendarEntries', 'calendarEntryListRequest', calendarEntryListRequest)
            const localVarPath = `/calendars/entries/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calendarEntryListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get a list of all calendar entries that meet the provided requirements, returning entries as well as calendar info
         * @param {CalendarEntryListRequest} calendarEntryListRequest Restrictions on the list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCalendarEntriesWithCalendars: async (calendarEntryListRequest: CalendarEntryListRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calendarEntryListRequest' is not null or undefined
            assertParamExists('listCalendarEntriesWithCalendars', 'calendarEntryListRequest', calendarEntryListRequest)
            const localVarPath = `/calendars/entries/listwithcalendars`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calendarEntryListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get a list of all calendar groups in the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCalendarGroups: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/calendars/groups/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get a list of all calendars in the system, core fields only
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCalendarSummary: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/calendars/list/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get a list of all calendars in the system, as well as general stats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCalendarSummaryStats: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/calendars/list/summary/stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get all of the interests that match the provided criteria. If the user isn\'t an admin, the interests will be filtered to the logged in user.
         * @param {UserInterestListRequest} userInterestListRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInterests: async (userInterestListRequest: UserInterestListRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userInterestListRequest' is not null or undefined
            assertParamExists('listInterests', 'userInterestListRequest', userInterestListRequest)
            const localVarPath = `/interest/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userInterestListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get all of the interests for a specific calendar entry. Only system admins can call.
         * @param {number} entryid Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInterestsForEntry: async (entryid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entryid' is not null or undefined
            assertParamExists('listInterestsForEntry', 'entryid', entryid)
            const localVarPath = `/interest/forentry/{entryid}`
                .replace(`{${"entryid"}}`, encodeURIComponent(String(entryid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get a list of all calendar entries that the user can register their interest in
         * @param {AvailableShiftsRequestUi} availableShiftsRequestUi Restrictions on the list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listShiftInterestEntries: async (availableShiftsRequestUi: AvailableShiftsRequestUi, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'availableShiftsRequestUi' is not null or undefined
            assertParamExists('listShiftInterestEntries', 'availableShiftsRequestUi', availableShiftsRequestUi)
            const localVarPath = `/calendars/entries/list/shiftinterest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(availableShiftsRequestUi, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get a list of ALL templates in the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStaffBlockTemplates: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/staffblocktemplates/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Save an interest record for a specific calendar entry
         * @param {UserInterest} userInterest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerInterest: async (userInterest: UserInterest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userInterest' is not null or undefined
            assertParamExists('registerInterest', 'userInterest', userInterest)
            const localVarPath = `/interest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userInterest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Register a user\'s interest in a specific shift. Will attempt to automatically register an interest in the DB if a perfect single match can be found for the entry.
         * @param {UserInterestShiftRequest} [userInterestShiftRequest] Information about the shift
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerShiftInterest: async (userInterestShiftRequest?: UserInterestShiftRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications/registershiftinterest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userInterestShiftRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Remove specified user from all events from a specified date
         * @param {RemoveUserRequest} [removeUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromFutureEvents: async (removeUserRequest?: RemoveUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/calendars/entries/removeuser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Take multiple entries across multiple calendars and repeat, including the assignments. If entries already exist, use conflict resolution strategy.
         * @param {RepeatAssignmentsRequest} [repeatAssignmentsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        repeatCalendarAssignments: async (repeatAssignmentsRequest?: RepeatAssignmentsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/calendars/entries/repeatassignments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repeatAssignmentsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Take a starting calendar entry, and then repeat it multiple times until a specified end date. If the calendar entry does not exist, this will simply return OK.
         * @param {RepeatEntryRequest} [repeatEntryRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        repeatCalendarEntry: async (repeatEntryRequest?: RepeatEntryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/calendars/entries/repeat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repeatEntryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Save a calendar to the DB. If the ID is 0 it will be added, if it is greater than 0 it\'ll be updated
         * @param {Calendar} calendar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCalendar: async (calendar: Calendar, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calendar' is not null or undefined
            assertParamExists('saveCalendar', 'calendar', calendar)
            const localVarPath = `/calendars`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calendar, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Save a calendar entry to the DB. If the ID is 0 it will be added, if it is greater than 0 it\'ll be updated
         * @param {CalendarEntry} calendarEntry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCalendarEntry: async (calendarEntry: CalendarEntry, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calendarEntry' is not null or undefined
            assertParamExists('saveCalendarEntry', 'calendarEntry', calendarEntry)
            const localVarPath = `/calendars/entries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calendarEntry, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Save a calendar grouping. If the ID is 0 it will be added, if it is greater than 0 it\'ll be updated
         * @param {CalendarGroup} calendarGroup
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCalendarGroup: async (calendarGroup: CalendarGroup, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calendarGroup' is not null or undefined
            assertParamExists('saveCalendarGroup', 'calendarGroup', calendarGroup)
            const localVarPath = `/calendars/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calendarGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Save a report made by a duty manager about an event (calendar entry)
         * @param {DutyManagerReport} dutyManagerReport
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDutyManagerReport: async (dutyManagerReport: DutyManagerReport, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dutyManagerReport' is not null or undefined
            assertParamExists('saveDutyManagerReport', 'dutyManagerReport', dutyManagerReport)
            const localVarPath = `/reports/dutymanager`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dutyManagerReport, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Save a staff block template to the DB. If the ID is 0 it will be added, if it is greater than 0 it\'ll be updated
         * @param {StaffBlockTemplate} staffBlockTemplate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveStaffBlockTemplate: async (staffBlockTemplate: StaffBlockTemplate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'staffBlockTemplate' is not null or undefined
            assertParamExists('saveStaffBlockTemplate', 'staffBlockTemplate', staffBlockTemplate)
            const localVarPath = `/staffblocktemplates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(staffBlockTemplate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Mark the interest record as going into the \'reserve\' list. An email will automatically be sent.
         * @param {LogUserInterestReserve} logUserInterestReserve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setInterestAsReserve: async (logUserInterestReserve: LogUserInterestReserve, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'logUserInterestReserve' is not null or undefined
            assertParamExists('setInterestAsReserve', 'logUserInterestReserve', logUserInterestReserve)
            const localVarPath = `/interest/reservelist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logUserInterestReserve, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CalendarApi - functional programming interface
 * @export
 */
export const CalendarApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CalendarApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Delete calendar with the specified ID
         * @param {number} id Unique ID for the calendar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCalendar(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCalendar(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Delete calendar entry with the specified ID
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCalendarEntry(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCalendarEntry(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Delete calendar group with the specified ID
         * @param {number} id Unique ID for the group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCalendarGroup(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCalendarGroup(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Delete a specific duty manager report
         * @param {number} id Unique ID for the duty manager report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDutyManagerReport(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDutyManagerReport(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Delete an existing interest record
         * @param {DeleteUserInterestRequest} deleteUserInterestRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInterest(deleteUserInterestRequest: DeleteUserInterestRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInterest(deleteUserInterestRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Delete an existing interest record
         * @param {UserInterest} userInterest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInterestForExisting(userInterest: UserInterest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInterestForExisting(userInterest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Delete staff block template with the specified ID
         * @param {number} id Unique ID for the template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteStaffBlockTemplate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStaffBlockTemplate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get a calendar with the specified ID
         * @param {number} id Unique ID for the calendar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCalendarById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Calendar>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCalendarById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get a calendar entry with the specified ID
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCalendarEntryById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCalendarEntryById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get a calendar group with the specified ID
         * @param {number} id Unique ID for the group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCalendarGroupById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCalendarGroupById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get a specific duty manager report
         * @param {number} id Unique ID for the duty manager report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDutyManagerReportById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DutyManagerReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDutyManagerReportById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get the duty manager reports for a specific calendar entry
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDutyManagerReportsForCalendarEntry(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DutyManagerReportListing>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDutyManagerReportsForCalendarEntry(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get duty manager reports list, paged and filtered to specific criteria
         * @param {DutyManagerReportPagedRequest} dutyManagerReportPagedRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDutyManagerReportsPaged(dutyManagerReportPagedRequest: DutyManagerReportPagedRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DutyManagerReportPagedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDutyManagerReportsPaged(dutyManagerReportPagedRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get a staff block template with the specified ID
         * @param {number} id Unique ID for the template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStaffBlockTemplateById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffBlockTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStaffBlockTemplateById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all of the calendar entries that a user is booked on between some dates. Unlike the normal list method, this will return all entries that in any way overlap the provided date range, not just events that start and finish within the range.
         * @summary Get all of the calendar entries that a user is booked on between some dates.
         * @param {UserEntriesRequest} userEntriesRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserEntries(userEntriesRequest: UserEntriesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserEntries(userEntriesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all calendar entries and interests within the date range. If admin, returns everything. If user, only returns interests for the user, along with entries that have a corresponding interest record.
         * @summary Get all of the calendar entries and interests for a date range.
         * @param {StaffInterestQueryFields} staffInterestQueryFields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async interestQuery(staffInterestQueryFields: StaffInterestQueryFields, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffInterestQueryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.interestQuery(staffInterestQueryFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get a list of all calendars in the system, and associated compliance
         * @param {CalendarComplianceRequest} calendarComplianceRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCalendarCompliance(calendarComplianceRequest: CalendarComplianceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarComplianceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCalendarCompliance(calendarComplianceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get a list of all calendar entries that meet the provided requirements
         * @param {CalendarEntryListRequest} calendarEntryListRequest Restrictions on the list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCalendarEntries(calendarEntryListRequest: CalendarEntryListRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCalendarEntries(calendarEntryListRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get a list of all calendar entries that meet the provided requirements, returning entries as well as calendar info
         * @param {CalendarEntryListRequest} calendarEntryListRequest Restrictions on the list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCalendarEntriesWithCalendars(calendarEntryListRequest: CalendarEntryListRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEntryListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCalendarEntriesWithCalendars(calendarEntryListRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get a list of all calendar groups in the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCalendarGroups(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarGroup>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCalendarGroups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get a list of all calendars in the system, core fields only
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCalendarSummary(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarSummary>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCalendarSummary(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get a list of all calendars in the system, as well as general stats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCalendarSummaryStats(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarSummaryStats>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCalendarSummaryStats(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get all of the interests that match the provided criteria. If the user isn\'t an admin, the interests will be filtered to the logged in user.
         * @param {UserInterestListRequest} userInterestListRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listInterests(userInterestListRequest: UserInterestListRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserInterest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listInterests(userInterestListRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get all of the interests for a specific calendar entry. Only system admins can call.
         * @param {number} entryid Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listInterestsForEntry(entryid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserInterest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listInterestsForEntry(entryid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get a list of all calendar entries that the user can register their interest in
         * @param {AvailableShiftsRequestUi} availableShiftsRequestUi Restrictions on the list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listShiftInterestEntries(availableShiftsRequestUi: AvailableShiftsRequestUi, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AvailableShiftCalendarEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listShiftInterestEntries(availableShiftsRequestUi, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get a list of ALL templates in the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStaffBlockTemplates(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StaffBlockTemplateListEntry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listStaffBlockTemplates(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Save an interest record for a specific calendar entry
         * @param {UserInterest} userInterest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerInterest(userInterest: UserInterest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerInterest(userInterest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Register a user\'s interest in a specific shift. Will attempt to automatically register an interest in the DB if a perfect single match can be found for the entry.
         * @param {UserInterestShiftRequest} [userInterestShiftRequest] Information about the shift
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerShiftInterest(userInterestShiftRequest?: UserInterestShiftRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerShiftInterest(userInterestShiftRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Remove specified user from all events from a specified date
         * @param {RemoveUserRequest} [removeUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeUserFromFutureEvents(removeUserRequest?: RemoveUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeUserFromFutureEvents(removeUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Take multiple entries across multiple calendars and repeat, including the assignments. If entries already exist, use conflict resolution strategy.
         * @param {RepeatAssignmentsRequest} [repeatAssignmentsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async repeatCalendarAssignments(repeatAssignmentsRequest?: RepeatAssignmentsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.repeatCalendarAssignments(repeatAssignmentsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Take a starting calendar entry, and then repeat it multiple times until a specified end date. If the calendar entry does not exist, this will simply return OK.
         * @param {RepeatEntryRequest} [repeatEntryRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async repeatCalendarEntry(repeatEntryRequest?: RepeatEntryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.repeatCalendarEntry(repeatEntryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Save a calendar to the DB. If the ID is 0 it will be added, if it is greater than 0 it\'ll be updated
         * @param {Calendar} calendar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveCalendar(calendar: Calendar, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Calendar>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveCalendar(calendar, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Save a calendar entry to the DB. If the ID is 0 it will be added, if it is greater than 0 it\'ll be updated
         * @param {CalendarEntry} calendarEntry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveCalendarEntry(calendarEntry: CalendarEntry, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveCalendarEntry(calendarEntry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Save a calendar grouping. If the ID is 0 it will be added, if it is greater than 0 it\'ll be updated
         * @param {CalendarGroup} calendarGroup
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveCalendarGroup(calendarGroup: CalendarGroup, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveCalendarGroup(calendarGroup, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Save a report made by a duty manager about an event (calendar entry)
         * @param {DutyManagerReport} dutyManagerReport
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveDutyManagerReport(dutyManagerReport: DutyManagerReport, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DutyManagerReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveDutyManagerReport(dutyManagerReport, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Save a staff block template to the DB. If the ID is 0 it will be added, if it is greater than 0 it\'ll be updated
         * @param {StaffBlockTemplate} staffBlockTemplate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveStaffBlockTemplate(staffBlockTemplate: StaffBlockTemplate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffBlockTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveStaffBlockTemplate(staffBlockTemplate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Mark the interest record as going into the \'reserve\' list. An email will automatically be sent.
         * @param {LogUserInterestReserve} logUserInterestReserve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setInterestAsReserve(logUserInterestReserve: LogUserInterestReserve, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setInterestAsReserve(logUserInterestReserve, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CalendarApi - factory interface
 * @export
 */
export const CalendarApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CalendarApiFp(configuration)
    return {
        /**
         *
         * @summary Delete calendar with the specified ID
         * @param {number} id Unique ID for the calendar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCalendar(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCalendar(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete calendar entry with the specified ID
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCalendarEntry(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCalendarEntry(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete calendar group with the specified ID
         * @param {number} id Unique ID for the group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCalendarGroup(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCalendarGroup(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete a specific duty manager report
         * @param {number} id Unique ID for the duty manager report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDutyManagerReport(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDutyManagerReport(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete an existing interest record
         * @param {DeleteUserInterestRequest} deleteUserInterestRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInterest(deleteUserInterestRequest: DeleteUserInterestRequest, options?: any): AxiosPromise<void> {
            return localVarFp.deleteInterest(deleteUserInterestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete an existing interest record
         * @param {UserInterest} userInterest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInterestForExisting(userInterest: UserInterest, options?: any): AxiosPromise<void> {
            return localVarFp.deleteInterestForExisting(userInterest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete staff block template with the specified ID
         * @param {number} id Unique ID for the template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStaffBlockTemplate(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteStaffBlockTemplate(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get a calendar with the specified ID
         * @param {number} id Unique ID for the calendar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarById(id: number, options?: any): AxiosPromise<Calendar> {
            return localVarFp.getCalendarById(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get a calendar entry with the specified ID
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarEntryById(id: number, options?: any): AxiosPromise<CalendarEntry> {
            return localVarFp.getCalendarEntryById(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get a calendar group with the specified ID
         * @param {number} id Unique ID for the group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarGroupById(id: number, options?: any): AxiosPromise<CalendarGroup> {
            return localVarFp.getCalendarGroupById(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get a specific duty manager report
         * @param {number} id Unique ID for the duty manager report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDutyManagerReportById(id: number, options?: any): AxiosPromise<DutyManagerReport> {
            return localVarFp.getDutyManagerReportById(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get the duty manager reports for a specific calendar entry
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDutyManagerReportsForCalendarEntry(id: number, options?: any): AxiosPromise<Array<DutyManagerReportListing>> {
            return localVarFp.getDutyManagerReportsForCalendarEntry(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get duty manager reports list, paged and filtered to specific criteria
         * @param {DutyManagerReportPagedRequest} dutyManagerReportPagedRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDutyManagerReportsPaged(dutyManagerReportPagedRequest: DutyManagerReportPagedRequest, options?: any): AxiosPromise<DutyManagerReportPagedResponse> {
            return localVarFp.getDutyManagerReportsPaged(dutyManagerReportPagedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get a staff block template with the specified ID
         * @param {number} id Unique ID for the template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaffBlockTemplateById(id: number, options?: any): AxiosPromise<StaffBlockTemplate> {
            return localVarFp.getStaffBlockTemplateById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all of the calendar entries that a user is booked on between some dates. Unlike the normal list method, this will return all entries that in any way overlap the provided date range, not just events that start and finish within the range.
         * @summary Get all of the calendar entries that a user is booked on between some dates.
         * @param {UserEntriesRequest} userEntriesRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserEntries(userEntriesRequest: UserEntriesRequest, options?: any): AxiosPromise<Array<CalendarEntry>> {
            return localVarFp.getUserEntries(userEntriesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all calendar entries and interests within the date range. If admin, returns everything. If user, only returns interests for the user, along with entries that have a corresponding interest record.
         * @summary Get all of the calendar entries and interests for a date range.
         * @param {StaffInterestQueryFields} staffInterestQueryFields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interestQuery(staffInterestQueryFields: StaffInterestQueryFields, options?: any): AxiosPromise<StaffInterestQueryResponse> {
            return localVarFp.interestQuery(staffInterestQueryFields, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get a list of all calendars in the system, and associated compliance
         * @param {CalendarComplianceRequest} calendarComplianceRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCalendarCompliance(calendarComplianceRequest: CalendarComplianceRequest, options?: any): AxiosPromise<Array<CalendarComplianceResponse>> {
            return localVarFp.listCalendarCompliance(calendarComplianceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get a list of all calendar entries that meet the provided requirements
         * @param {CalendarEntryListRequest} calendarEntryListRequest Restrictions on the list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCalendarEntries(calendarEntryListRequest: CalendarEntryListRequest, options?: any): AxiosPromise<Array<CalendarEntry>> {
            return localVarFp.listCalendarEntries(calendarEntryListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get a list of all calendar entries that meet the provided requirements, returning entries as well as calendar info
         * @param {CalendarEntryListRequest} calendarEntryListRequest Restrictions on the list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCalendarEntriesWithCalendars(calendarEntryListRequest: CalendarEntryListRequest, options?: any): AxiosPromise<CalendarEntryListResponse> {
            return localVarFp.listCalendarEntriesWithCalendars(calendarEntryListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get a list of all calendar groups in the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCalendarGroups(options?: any): AxiosPromise<Array<CalendarGroup>> {
            return localVarFp.listCalendarGroups(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get a list of all calendars in the system, core fields only
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCalendarSummary(options?: any): AxiosPromise<Array<CalendarSummary>> {
            return localVarFp.listCalendarSummary(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get a list of all calendars in the system, as well as general stats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCalendarSummaryStats(options?: any): AxiosPromise<Array<CalendarSummaryStats>> {
            return localVarFp.listCalendarSummaryStats(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get all of the interests that match the provided criteria. If the user isn\'t an admin, the interests will be filtered to the logged in user.
         * @param {UserInterestListRequest} userInterestListRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInterests(userInterestListRequest: UserInterestListRequest, options?: any): AxiosPromise<Array<UserInterest>> {
            return localVarFp.listInterests(userInterestListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get all of the interests for a specific calendar entry. Only system admins can call.
         * @param {number} entryid Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInterestsForEntry(entryid: number, options?: any): AxiosPromise<Array<UserInterest>> {
            return localVarFp.listInterestsForEntry(entryid, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get a list of all calendar entries that the user can register their interest in
         * @param {AvailableShiftsRequestUi} availableShiftsRequestUi Restrictions on the list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listShiftInterestEntries(availableShiftsRequestUi: AvailableShiftsRequestUi, options?: any): AxiosPromise<Array<AvailableShiftCalendarEntry>> {
            return localVarFp.listShiftInterestEntries(availableShiftsRequestUi, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get a list of ALL templates in the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStaffBlockTemplates(options?: any): AxiosPromise<Array<StaffBlockTemplateListEntry>> {
            return localVarFp.listStaffBlockTemplates(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Save an interest record for a specific calendar entry
         * @param {UserInterest} userInterest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerInterest(userInterest: UserInterest, options?: any): AxiosPromise<void> {
            return localVarFp.registerInterest(userInterest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Register a user\'s interest in a specific shift. Will attempt to automatically register an interest in the DB if a perfect single match can be found for the entry.
         * @param {UserInterestShiftRequest} [userInterestShiftRequest] Information about the shift
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerShiftInterest(userInterestShiftRequest?: UserInterestShiftRequest, options?: any): AxiosPromise<void> {
            return localVarFp.registerShiftInterest(userInterestShiftRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Remove specified user from all events from a specified date
         * @param {RemoveUserRequest} [removeUserRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromFutureEvents(removeUserRequest?: RemoveUserRequest, options?: any): AxiosPromise<void> {
            return localVarFp.removeUserFromFutureEvents(removeUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Take multiple entries across multiple calendars and repeat, including the assignments. If entries already exist, use conflict resolution strategy.
         * @param {RepeatAssignmentsRequest} [repeatAssignmentsRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        repeatCalendarAssignments(repeatAssignmentsRequest?: RepeatAssignmentsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.repeatCalendarAssignments(repeatAssignmentsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Take a starting calendar entry, and then repeat it multiple times until a specified end date. If the calendar entry does not exist, this will simply return OK.
         * @param {RepeatEntryRequest} [repeatEntryRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        repeatCalendarEntry(repeatEntryRequest?: RepeatEntryRequest, options?: any): AxiosPromise<void> {
            return localVarFp.repeatCalendarEntry(repeatEntryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Save a calendar to the DB. If the ID is 0 it will be added, if it is greater than 0 it\'ll be updated
         * @param {Calendar} calendar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCalendar(calendar: Calendar, options?: any): AxiosPromise<Calendar> {
            return localVarFp.saveCalendar(calendar, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Save a calendar entry to the DB. If the ID is 0 it will be added, if it is greater than 0 it\'ll be updated
         * @param {CalendarEntry} calendarEntry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCalendarEntry(calendarEntry: CalendarEntry, options?: any): AxiosPromise<CalendarEntry> {
            return localVarFp.saveCalendarEntry(calendarEntry, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Save a calendar grouping. If the ID is 0 it will be added, if it is greater than 0 it\'ll be updated
         * @param {CalendarGroup} calendarGroup
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCalendarGroup(calendarGroup: CalendarGroup, options?: any): AxiosPromise<CalendarGroup> {
            return localVarFp.saveCalendarGroup(calendarGroup, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Save a report made by a duty manager about an event (calendar entry)
         * @param {DutyManagerReport} dutyManagerReport
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDutyManagerReport(dutyManagerReport: DutyManagerReport, options?: any): AxiosPromise<DutyManagerReport> {
            return localVarFp.saveDutyManagerReport(dutyManagerReport, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Save a staff block template to the DB. If the ID is 0 it will be added, if it is greater than 0 it\'ll be updated
         * @param {StaffBlockTemplate} staffBlockTemplate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveStaffBlockTemplate(staffBlockTemplate: StaffBlockTemplate, options?: any): AxiosPromise<StaffBlockTemplate> {
            return localVarFp.saveStaffBlockTemplate(staffBlockTemplate, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Mark the interest record as going into the \'reserve\' list. An email will automatically be sent.
         * @param {LogUserInterestReserve} logUserInterestReserve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setInterestAsReserve(logUserInterestReserve: LogUserInterestReserve, options?: any): AxiosPromise<void> {
            return localVarFp.setInterestAsReserve(logUserInterestReserve, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CalendarApi - object-oriented interface
 * @export
 * @class CalendarApi
 * @extends {BaseAPI}
 */
export class CalendarApi extends BaseAPI {
    /**
     *
     * @summary Delete calendar with the specified ID
     * @param {number} id Unique ID for the calendar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public deleteCalendar(id: number, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).deleteCalendar(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Delete calendar entry with the specified ID
     * @param {number} id Unique ID for the calendar entry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public deleteCalendarEntry(id: number, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).deleteCalendarEntry(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Delete calendar group with the specified ID
     * @param {number} id Unique ID for the group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public deleteCalendarGroup(id: number, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).deleteCalendarGroup(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Delete a specific duty manager report
     * @param {number} id Unique ID for the duty manager report
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public deleteDutyManagerReport(id: number, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).deleteDutyManagerReport(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Delete an existing interest record
     * @param {DeleteUserInterestRequest} deleteUserInterestRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public deleteInterest(deleteUserInterestRequest: DeleteUserInterestRequest, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).deleteInterest(deleteUserInterestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Delete an existing interest record
     * @param {UserInterest} userInterest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public deleteInterestForExisting(userInterest: UserInterest, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).deleteInterestForExisting(userInterest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Delete staff block template with the specified ID
     * @param {number} id Unique ID for the template
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public deleteStaffBlockTemplate(id: number, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).deleteStaffBlockTemplate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get a calendar with the specified ID
     * @param {number} id Unique ID for the calendar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getCalendarById(id: number, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getCalendarById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get a calendar entry with the specified ID
     * @param {number} id Unique ID for the calendar entry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getCalendarEntryById(id: number, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getCalendarEntryById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get a calendar group with the specified ID
     * @param {number} id Unique ID for the group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getCalendarGroupById(id: number, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getCalendarGroupById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get a specific duty manager report
     * @param {number} id Unique ID for the duty manager report
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getDutyManagerReportById(id: number, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getDutyManagerReportById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get the duty manager reports for a specific calendar entry
     * @param {number} id Unique ID for the calendar entry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getDutyManagerReportsForCalendarEntry(id: number, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getDutyManagerReportsForCalendarEntry(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get duty manager reports list, paged and filtered to specific criteria
     * @param {DutyManagerReportPagedRequest} dutyManagerReportPagedRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getDutyManagerReportsPaged(dutyManagerReportPagedRequest: DutyManagerReportPagedRequest, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getDutyManagerReportsPaged(dutyManagerReportPagedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get a staff block template with the specified ID
     * @param {number} id Unique ID for the template
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getStaffBlockTemplateById(id: number, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getStaffBlockTemplateById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all of the calendar entries that a user is booked on between some dates. Unlike the normal list method, this will return all entries that in any way overlap the provided date range, not just events that start and finish within the range.
     * @summary Get all of the calendar entries that a user is booked on between some dates.
     * @param {UserEntriesRequest} userEntriesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getUserEntries(userEntriesRequest: UserEntriesRequest, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getUserEntries(userEntriesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all calendar entries and interests within the date range. If admin, returns everything. If user, only returns interests for the user, along with entries that have a corresponding interest record.
     * @summary Get all of the calendar entries and interests for a date range.
     * @param {StaffInterestQueryFields} staffInterestQueryFields
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public interestQuery(staffInterestQueryFields: StaffInterestQueryFields, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).interestQuery(staffInterestQueryFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get a list of all calendars in the system, and associated compliance
     * @param {CalendarComplianceRequest} calendarComplianceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public listCalendarCompliance(calendarComplianceRequest: CalendarComplianceRequest, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).listCalendarCompliance(calendarComplianceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get a list of all calendar entries that meet the provided requirements
     * @param {CalendarEntryListRequest} calendarEntryListRequest Restrictions on the list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public listCalendarEntries(calendarEntryListRequest: CalendarEntryListRequest, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).listCalendarEntries(calendarEntryListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get a list of all calendar entries that meet the provided requirements, returning entries as well as calendar info
     * @param {CalendarEntryListRequest} calendarEntryListRequest Restrictions on the list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public listCalendarEntriesWithCalendars(calendarEntryListRequest: CalendarEntryListRequest, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).listCalendarEntriesWithCalendars(calendarEntryListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get a list of all calendar groups in the system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public listCalendarGroups(options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).listCalendarGroups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get a list of all calendars in the system, core fields only
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public listCalendarSummary(options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).listCalendarSummary(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get a list of all calendars in the system, as well as general stats
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public listCalendarSummaryStats(options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).listCalendarSummaryStats(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get all of the interests that match the provided criteria. If the user isn\'t an admin, the interests will be filtered to the logged in user.
     * @param {UserInterestListRequest} userInterestListRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public listInterests(userInterestListRequest: UserInterestListRequest, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).listInterests(userInterestListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get all of the interests for a specific calendar entry. Only system admins can call.
     * @param {number} entryid Unique ID for the calendar entry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public listInterestsForEntry(entryid: number, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).listInterestsForEntry(entryid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get a list of all calendar entries that the user can register their interest in
     * @param {AvailableShiftsRequestUi} availableShiftsRequestUi Restrictions on the list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public listShiftInterestEntries(availableShiftsRequestUi: AvailableShiftsRequestUi, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).listShiftInterestEntries(availableShiftsRequestUi, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get a list of ALL templates in the system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public listStaffBlockTemplates(options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).listStaffBlockTemplates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Save an interest record for a specific calendar entry
     * @param {UserInterest} userInterest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public registerInterest(userInterest: UserInterest, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).registerInterest(userInterest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Register a user\'s interest in a specific shift. Will attempt to automatically register an interest in the DB if a perfect single match can be found for the entry.
     * @param {UserInterestShiftRequest} [userInterestShiftRequest] Information about the shift
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public registerShiftInterest(userInterestShiftRequest?: UserInterestShiftRequest, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).registerShiftInterest(userInterestShiftRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Remove specified user from all events from a specified date
     * @param {RemoveUserRequest} [removeUserRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public removeUserFromFutureEvents(removeUserRequest?: RemoveUserRequest, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).removeUserFromFutureEvents(removeUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Take multiple entries across multiple calendars and repeat, including the assignments. If entries already exist, use conflict resolution strategy.
     * @param {RepeatAssignmentsRequest} [repeatAssignmentsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public repeatCalendarAssignments(repeatAssignmentsRequest?: RepeatAssignmentsRequest, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).repeatCalendarAssignments(repeatAssignmentsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Take a starting calendar entry, and then repeat it multiple times until a specified end date. If the calendar entry does not exist, this will simply return OK.
     * @param {RepeatEntryRequest} [repeatEntryRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public repeatCalendarEntry(repeatEntryRequest?: RepeatEntryRequest, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).repeatCalendarEntry(repeatEntryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Save a calendar to the DB. If the ID is 0 it will be added, if it is greater than 0 it\'ll be updated
     * @param {Calendar} calendar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public saveCalendar(calendar: Calendar, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).saveCalendar(calendar, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Save a calendar entry to the DB. If the ID is 0 it will be added, if it is greater than 0 it\'ll be updated
     * @param {CalendarEntry} calendarEntry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public saveCalendarEntry(calendarEntry: CalendarEntry, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).saveCalendarEntry(calendarEntry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Save a calendar grouping. If the ID is 0 it will be added, if it is greater than 0 it\'ll be updated
     * @param {CalendarGroup} calendarGroup
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public saveCalendarGroup(calendarGroup: CalendarGroup, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).saveCalendarGroup(calendarGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Save a report made by a duty manager about an event (calendar entry)
     * @param {DutyManagerReport} dutyManagerReport
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public saveDutyManagerReport(dutyManagerReport: DutyManagerReport, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).saveDutyManagerReport(dutyManagerReport, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Save a staff block template to the DB. If the ID is 0 it will be added, if it is greater than 0 it\'ll be updated
     * @param {StaffBlockTemplate} staffBlockTemplate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public saveStaffBlockTemplate(staffBlockTemplate: StaffBlockTemplate, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).saveStaffBlockTemplate(staffBlockTemplate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Mark the interest record as going into the \'reserve\' list. An email will automatically be sent.
     * @param {LogUserInterestReserve} logUserInterestReserve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public setInterestAsReserve(logUserInterestReserve: LogUserInterestReserve, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).setInterestAsReserve(logUserInterestReserve, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FileApi - axios parameter creator
 * @export
 */
export const FileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Get binary file data previously saved to the server
         * @param {string} filename Filename of the desired file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFile: async (filename: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('getFile', 'filename', filename)
            const localVarPath = `/file/storage/file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (filename !== undefined) {
                localVarQueryParameter['filename'] = filename;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileApi - functional programming interface
 * @export
 */
export const FileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Get binary file data previously saved to the server
         * @param {string} filename Filename of the desired file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFile(filename: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFile(filename, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileApi - factory interface
 * @export
 */
export const FileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileApiFp(configuration)
    return {
        /**
         *
         * @summary Get binary file data previously saved to the server
         * @param {string} filename Filename of the desired file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFile(filename: string, options?: any): AxiosPromise<void> {
            return localVarFp.getFile(filename, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileApi - object-oriented interface
 * @export
 * @class FileApi
 * @extends {BaseAPI}
 */
export class FileApi extends BaseAPI {
    /**
     *
     * @summary Get binary file data previously saved to the server
     * @param {string} filename Filename of the desired file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public getFile(filename: string, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).getFile(filename, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvoiceApi - axios parameter creator
 * @export
 */
export const InvoiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Get the current link status with Xero
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getXeroStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/xero/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Complete Xero tracking data, used for linking invoice line items to things
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getXeroTrackingFullInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/xero/info/tracking`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get automatically created invoices for Xero
         * @param {AutoInvoiceListRequest} [autoInvoiceListRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listXeroAutoInvoices: async (autoInvoiceListRequest?: AutoInvoiceListRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/xero/invoices/auto/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(autoInvoiceListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get all info required to initiate a link from GRS to Xero
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startXeroAuth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/xero/startdata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Complete Xero link with returned data from Xero
         * @param {string} [code] Auth code from Xero
         * @param {string} [state] State string from Xero (must match from login, server will validate)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xeroCallback: async (code?: string, state?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/xero/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Disconnect from Xero, clearing all tokens and removing the app from the customer\'s Xero account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xeroDisconnect: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/xero/disconnect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Trigger a new invoice for a specific user for a specific month, adding or replacing as required
         * @param {XeroAutoIndividual} [xeroAutoIndividual]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xeroTriggerIndividualAutoInvoice: async (xeroAutoIndividual?: XeroAutoIndividual, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/xero/invoices/auto/trigger/individual`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(xeroAutoIndividual, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoiceApi - functional programming interface
 * @export
 */
export const InvoiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvoiceApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Get the current link status with Xero
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getXeroStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<XeroAuthStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getXeroStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Complete Xero tracking data, used for linking invoice line items to things
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getXeroTrackingFullInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<XeroTrackingInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getXeroTrackingFullInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get automatically created invoices for Xero
         * @param {AutoInvoiceListRequest} [autoInvoiceListRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listXeroAutoInvoices(autoInvoiceListRequest?: AutoInvoiceListRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AutoInvoiceListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listXeroAutoInvoices(autoInvoiceListRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get all info required to initiate a link from GRS to Xero
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startXeroAuth(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<XeroStartData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startXeroAuth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Complete Xero link with returned data from Xero
         * @param {string} [code] Auth code from Xero
         * @param {string} [state] State string from Xero (must match from login, server will validate)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xeroCallback(code?: string, state?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xeroCallback(code, state, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Disconnect from Xero, clearing all tokens and removing the app from the customer\'s Xero account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xeroDisconnect(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xeroDisconnect(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Trigger a new invoice for a specific user for a specific month, adding or replacing as required
         * @param {XeroAutoIndividual} [xeroAutoIndividual]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xeroTriggerIndividualAutoInvoice(xeroAutoIndividual?: XeroAutoIndividual, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AutoInvoiceRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xeroTriggerIndividualAutoInvoice(xeroAutoIndividual, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvoiceApi - factory interface
 * @export
 */
export const InvoiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvoiceApiFp(configuration)
    return {
        /**
         *
         * @summary Get the current link status with Xero
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getXeroStatus(options?: any): AxiosPromise<XeroAuthStatus> {
            return localVarFp.getXeroStatus(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Complete Xero tracking data, used for linking invoice line items to things
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getXeroTrackingFullInfo(options?: any): AxiosPromise<XeroTrackingInfo> {
            return localVarFp.getXeroTrackingFullInfo(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get automatically created invoices for Xero
         * @param {AutoInvoiceListRequest} [autoInvoiceListRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listXeroAutoInvoices(autoInvoiceListRequest?: AutoInvoiceListRequest, options?: any): AxiosPromise<AutoInvoiceListResponse> {
            return localVarFp.listXeroAutoInvoices(autoInvoiceListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get all info required to initiate a link from GRS to Xero
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startXeroAuth(options?: any): AxiosPromise<XeroStartData> {
            return localVarFp.startXeroAuth(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Complete Xero link with returned data from Xero
         * @param {string} [code] Auth code from Xero
         * @param {string} [state] State string from Xero (must match from login, server will validate)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xeroCallback(code?: string, state?: string, options?: any): AxiosPromise<void> {
            return localVarFp.xeroCallback(code, state, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Disconnect from Xero, clearing all tokens and removing the app from the customer\'s Xero account
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xeroDisconnect(options?: any): AxiosPromise<void> {
            return localVarFp.xeroDisconnect(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Trigger a new invoice for a specific user for a specific month, adding or replacing as required
         * @param {XeroAutoIndividual} [xeroAutoIndividual]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xeroTriggerIndividualAutoInvoice(xeroAutoIndividual?: XeroAutoIndividual, options?: any): AxiosPromise<AutoInvoiceRecord> {
            return localVarFp.xeroTriggerIndividualAutoInvoice(xeroAutoIndividual, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvoiceApi - object-oriented interface
 * @export
 * @class InvoiceApi
 * @extends {BaseAPI}
 */
export class InvoiceApi extends BaseAPI {
    /**
     *
     * @summary Get the current link status with Xero
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public getXeroStatus(options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).getXeroStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Complete Xero tracking data, used for linking invoice line items to things
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public getXeroTrackingFullInfo(options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).getXeroTrackingFullInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get automatically created invoices for Xero
     * @param {AutoInvoiceListRequest} [autoInvoiceListRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public listXeroAutoInvoices(autoInvoiceListRequest?: AutoInvoiceListRequest, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).listXeroAutoInvoices(autoInvoiceListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get all info required to initiate a link from GRS to Xero
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public startXeroAuth(options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).startXeroAuth(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Complete Xero link with returned data from Xero
     * @param {string} [code] Auth code from Xero
     * @param {string} [state] State string from Xero (must match from login, server will validate)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public xeroCallback(code?: string, state?: string, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).xeroCallback(code, state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Disconnect from Xero, clearing all tokens and removing the app from the customer\'s Xero account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public xeroDisconnect(options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).xeroDisconnect(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Trigger a new invoice for a specific user for a specific month, adding or replacing as required
     * @param {XeroAutoIndividual} [xeroAutoIndividual]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public xeroTriggerIndividualAutoInvoice(xeroAutoIndividual?: XeroAutoIndividual, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).xeroTriggerIndividualAutoInvoice(xeroAutoIndividual, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Delete a specific duty manager report
         * @param {number} id Unique ID for the duty manager report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDutyManagerReport: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDutyManagerReport', 'id', id)
            const localVarPath = `/reports/dutymanager/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get a specific duty manager report
         * @param {number} id Unique ID for the duty manager report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDutyManagerReportById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDutyManagerReportById', 'id', id)
            const localVarPath = `/reports/dutymanager/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get the duty manager reports for a specific calendar entry
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDutyManagerReportsForCalendarEntry: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDutyManagerReportsForCalendarEntry', 'id', id)
            const localVarPath = `/reports/dutymanager/forcalentry/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get duty manager reports list, paged and filtered to specific criteria
         * @param {DutyManagerReportPagedRequest} dutyManagerReportPagedRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDutyManagerReportsPaged: async (dutyManagerReportPagedRequest: DutyManagerReportPagedRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dutyManagerReportPagedRequest' is not null or undefined
            assertParamExists('getDutyManagerReportsPaged', 'dutyManagerReportPagedRequest', dutyManagerReportPagedRequest)
            const localVarPath = `/reports/dutymanager/paged`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dutyManagerReportPagedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get an overview of all the entries for a specific calendar in a specific time range
         * @param {EventsReportRequest} eventsReportRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventsReport: async (eventsReportRequest: EventsReportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventsReportRequest' is not null or undefined
            assertParamExists('getEventsReport', 'eventsReportRequest', eventsReportRequest)
            const localVarPath = `/reports/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventsReportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary For a specific member of staff, all of the entries the staff member is booked on for a date range and whether they\'ve attended or not
         * @param {StaffReportRequest} staffReportRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaffMemberAssignmentsReport: async (staffReportRequest: StaffReportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'staffReportRequest' is not null or undefined
            assertParamExists('getStaffMemberAssignmentsReport', 'staffReportRequest', staffReportRequest)
            const localVarPath = `/reports/staff`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(staffReportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get the calculated wages for all staff across all calendars for a specific time period
         * @param {StaffWageReportRequest} staffWageReportRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaffWageReport: async (staffWageReportRequest: StaffWageReportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'staffWageReportRequest' is not null or undefined
            assertParamExists('getStaffWageReport', 'staffWageReportRequest', staffWageReportRequest)
            const localVarPath = `/reports/staffwage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(staffWageReportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Save a report made by a duty manager about an event (calendar entry)
         * @param {DutyManagerReport} dutyManagerReport
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDutyManagerReport: async (dutyManagerReport: DutyManagerReport, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dutyManagerReport' is not null or undefined
            assertParamExists('saveDutyManagerReport', 'dutyManagerReport', dutyManagerReport)
            const localVarPath = `/reports/dutymanager`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dutyManagerReport, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Delete a specific duty manager report
         * @param {number} id Unique ID for the duty manager report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDutyManagerReport(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDutyManagerReport(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get a specific duty manager report
         * @param {number} id Unique ID for the duty manager report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDutyManagerReportById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DutyManagerReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDutyManagerReportById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get the duty manager reports for a specific calendar entry
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDutyManagerReportsForCalendarEntry(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DutyManagerReportListing>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDutyManagerReportsForCalendarEntry(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get duty manager reports list, paged and filtered to specific criteria
         * @param {DutyManagerReportPagedRequest} dutyManagerReportPagedRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDutyManagerReportsPaged(dutyManagerReportPagedRequest: DutyManagerReportPagedRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DutyManagerReportPagedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDutyManagerReportsPaged(dutyManagerReportPagedRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get an overview of all the entries for a specific calendar in a specific time range
         * @param {EventsReportRequest} eventsReportRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventsReport(eventsReportRequest: EventsReportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventsReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventsReport(eventsReportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary For a specific member of staff, all of the entries the staff member is booked on for a date range and whether they\'ve attended or not
         * @param {StaffReportRequest} staffReportRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStaffMemberAssignmentsReport(staffReportRequest: StaffReportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStaffMemberAssignmentsReport(staffReportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get the calculated wages for all staff across all calendars for a specific time period
         * @param {StaffWageReportRequest} staffWageReportRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStaffWageReport(staffWageReportRequest: StaffWageReportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffWageReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStaffWageReport(staffWageReportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Save a report made by a duty manager about an event (calendar entry)
         * @param {DutyManagerReport} dutyManagerReport
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveDutyManagerReport(dutyManagerReport: DutyManagerReport, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DutyManagerReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveDutyManagerReport(dutyManagerReport, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsApiFp(configuration)
    return {
        /**
         *
         * @summary Delete a specific duty manager report
         * @param {number} id Unique ID for the duty manager report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDutyManagerReport(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDutyManagerReport(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get a specific duty manager report
         * @param {number} id Unique ID for the duty manager report
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDutyManagerReportById(id: number, options?: any): AxiosPromise<DutyManagerReport> {
            return localVarFp.getDutyManagerReportById(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get the duty manager reports for a specific calendar entry
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDutyManagerReportsForCalendarEntry(id: number, options?: any): AxiosPromise<Array<DutyManagerReportListing>> {
            return localVarFp.getDutyManagerReportsForCalendarEntry(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get duty manager reports list, paged and filtered to specific criteria
         * @param {DutyManagerReportPagedRequest} dutyManagerReportPagedRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDutyManagerReportsPaged(dutyManagerReportPagedRequest: DutyManagerReportPagedRequest, options?: any): AxiosPromise<DutyManagerReportPagedResponse> {
            return localVarFp.getDutyManagerReportsPaged(dutyManagerReportPagedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get an overview of all the entries for a specific calendar in a specific time range
         * @param {EventsReportRequest} eventsReportRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventsReport(eventsReportRequest: EventsReportRequest, options?: any): AxiosPromise<EventsReportResponse> {
            return localVarFp.getEventsReport(eventsReportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary For a specific member of staff, all of the entries the staff member is booked on for a date range and whether they\'ve attended or not
         * @param {StaffReportRequest} staffReportRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaffMemberAssignmentsReport(staffReportRequest: StaffReportRequest, options?: any): AxiosPromise<StaffReportResponse> {
            return localVarFp.getStaffMemberAssignmentsReport(staffReportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get the calculated wages for all staff across all calendars for a specific time period
         * @param {StaffWageReportRequest} staffWageReportRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaffWageReport(staffWageReportRequest: StaffWageReportRequest, options?: any): AxiosPromise<StaffWageReportResponse> {
            return localVarFp.getStaffWageReport(staffWageReportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Save a report made by a duty manager about an event (calendar entry)
         * @param {DutyManagerReport} dutyManagerReport
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDutyManagerReport(dutyManagerReport: DutyManagerReport, options?: any): AxiosPromise<DutyManagerReport> {
            return localVarFp.saveDutyManagerReport(dutyManagerReport, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
    /**
     *
     * @summary Delete a specific duty manager report
     * @param {number} id Unique ID for the duty manager report
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public deleteDutyManagerReport(id: number, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).deleteDutyManagerReport(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get a specific duty manager report
     * @param {number} id Unique ID for the duty manager report
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getDutyManagerReportById(id: number, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).getDutyManagerReportById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get the duty manager reports for a specific calendar entry
     * @param {number} id Unique ID for the calendar entry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getDutyManagerReportsForCalendarEntry(id: number, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).getDutyManagerReportsForCalendarEntry(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get duty manager reports list, paged and filtered to specific criteria
     * @param {DutyManagerReportPagedRequest} dutyManagerReportPagedRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getDutyManagerReportsPaged(dutyManagerReportPagedRequest: DutyManagerReportPagedRequest, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).getDutyManagerReportsPaged(dutyManagerReportPagedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get an overview of all the entries for a specific calendar in a specific time range
     * @param {EventsReportRequest} eventsReportRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getEventsReport(eventsReportRequest: EventsReportRequest, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).getEventsReport(eventsReportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary For a specific member of staff, all of the entries the staff member is booked on for a date range and whether they\'ve attended or not
     * @param {StaffReportRequest} staffReportRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getStaffMemberAssignmentsReport(staffReportRequest: StaffReportRequest, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).getStaffMemberAssignmentsReport(staffReportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get the calculated wages for all staff across all calendars for a specific time period
     * @param {StaffWageReportRequest} staffWageReportRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getStaffWageReport(staffWageReportRequest: StaffWageReportRequest, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).getStaffWageReport(staffWageReportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Save a report made by a duty manager about an event (calendar entry)
     * @param {DutyManagerReport} dutyManagerReport
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public saveDutyManagerReport(dutyManagerReport: DutyManagerReport, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).saveDutyManagerReport(dutyManagerReport, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StaffApi - axios parameter creator
 * @export
 */
export const StaffApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Change the user\'s assignment state for WaitingAccept to Accepted. Uses the user\'s auth token to know which assignment.
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptAssignment: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('acceptAssignment', 'id', id)
            const localVarPath = `/staff/actions/acceptassignment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Change the user\'s assignment state for Accepted to BriefingAccepted. Uses the user\'s auth token to know which assignment.
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptBriefing: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('acceptBriefing', 'id', id)
            const localVarPath = `/staff/actions/acceptbriefing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * If the state is \'WaitingAccept\' the next step is to accept/reject the assignment. If the state is \'Accepted\' the next step is to accept the briefing document.
         * @summary Get all of the entries the user has to either accept, or accept the briefing doc.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingActions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/staff/actions/pending`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Remove a user on WaitingAccept from the entry entirely. Uses the user\'s auth token to know which assignment.
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectAssignment: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rejectAssignment', 'id', id)
            const localVarPath = `/staff/actions/rejectassignment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Resend notifications for a specific user for a specific calendar entry
         * @param {ResendUserNotificationsRequest} [resendUserNotificationsRequest] Information about the user and calendar entry to send notifications for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendUserNotifications: async (resendUserNotificationsRequest?: ResendUserNotificationsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/staff/actions/notifications/resend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resendUserNotificationsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Set one or more staff as having attended the event
         * @param {MarkAttendedRequest} [markAttendedRequest] Staff that attended
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setStaffAttended: async (markAttendedRequest?: MarkAttendedRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/staff/actions/attended`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(markAttendedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StaffApi - functional programming interface
 * @export
 */
export const StaffApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StaffApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Change the user\'s assignment state for WaitingAccept to Accepted. Uses the user\'s auth token to know which assignment.
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptAssignment(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptAssignment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Change the user\'s assignment state for Accepted to BriefingAccepted. Uses the user\'s auth token to know which assignment.
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptBriefing(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptBriefing(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * If the state is \'WaitingAccept\' the next step is to accept/reject the assignment. If the state is \'Accepted\' the next step is to accept the briefing document.
         * @summary Get all of the entries the user has to either accept, or accept the briefing doc.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPendingActions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StaffPendingAction>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPendingActions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Remove a user on WaitingAccept from the entry entirely. Uses the user\'s auth token to know which assignment.
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rejectAssignment(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rejectAssignment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Resend notifications for a specific user for a specific calendar entry
         * @param {ResendUserNotificationsRequest} [resendUserNotificationsRequest] Information about the user and calendar entry to send notifications for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendUserNotifications(resendUserNotificationsRequest?: ResendUserNotificationsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendUserNotifications(resendUserNotificationsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Set one or more staff as having attended the event
         * @param {MarkAttendedRequest} [markAttendedRequest] Staff that attended
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setStaffAttended(markAttendedRequest?: MarkAttendedRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setStaffAttended(markAttendedRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StaffApi - factory interface
 * @export
 */
export const StaffApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StaffApiFp(configuration)
    return {
        /**
         *
         * @summary Change the user\'s assignment state for WaitingAccept to Accepted. Uses the user\'s auth token to know which assignment.
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptAssignment(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.acceptAssignment(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Change the user\'s assignment state for Accepted to BriefingAccepted. Uses the user\'s auth token to know which assignment.
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptBriefing(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.acceptBriefing(id, options).then((request) => request(axios, basePath));
        },
        /**
         * If the state is \'WaitingAccept\' the next step is to accept/reject the assignment. If the state is \'Accepted\' the next step is to accept the briefing document.
         * @summary Get all of the entries the user has to either accept, or accept the briefing doc.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingActions(options?: any): AxiosPromise<Array<StaffPendingAction>> {
            return localVarFp.getPendingActions(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Remove a user on WaitingAccept from the entry entirely. Uses the user\'s auth token to know which assignment.
         * @param {number} id Unique ID for the calendar entry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectAssignment(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.rejectAssignment(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Resend notifications for a specific user for a specific calendar entry
         * @param {ResendUserNotificationsRequest} [resendUserNotificationsRequest] Information about the user and calendar entry to send notifications for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendUserNotifications(resendUserNotificationsRequest?: ResendUserNotificationsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.resendUserNotifications(resendUserNotificationsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Set one or more staff as having attended the event
         * @param {MarkAttendedRequest} [markAttendedRequest] Staff that attended
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setStaffAttended(markAttendedRequest?: MarkAttendedRequest, options?: any): AxiosPromise<CalendarEntry> {
            return localVarFp.setStaffAttended(markAttendedRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StaffApi - object-oriented interface
 * @export
 * @class StaffApi
 * @extends {BaseAPI}
 */
export class StaffApi extends BaseAPI {
    /**
     *
     * @summary Change the user\'s assignment state for WaitingAccept to Accepted. Uses the user\'s auth token to know which assignment.
     * @param {number} id Unique ID for the calendar entry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public acceptAssignment(id: number, options?: AxiosRequestConfig) {
        return StaffApiFp(this.configuration).acceptAssignment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Change the user\'s assignment state for Accepted to BriefingAccepted. Uses the user\'s auth token to know which assignment.
     * @param {number} id Unique ID for the calendar entry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public acceptBriefing(id: number, options?: AxiosRequestConfig) {
        return StaffApiFp(this.configuration).acceptBriefing(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * If the state is \'WaitingAccept\' the next step is to accept/reject the assignment. If the state is \'Accepted\' the next step is to accept the briefing document.
     * @summary Get all of the entries the user has to either accept, or accept the briefing doc.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public getPendingActions(options?: AxiosRequestConfig) {
        return StaffApiFp(this.configuration).getPendingActions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Remove a user on WaitingAccept from the entry entirely. Uses the user\'s auth token to know which assignment.
     * @param {number} id Unique ID for the calendar entry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public rejectAssignment(id: number, options?: AxiosRequestConfig) {
        return StaffApiFp(this.configuration).rejectAssignment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Resend notifications for a specific user for a specific calendar entry
     * @param {ResendUserNotificationsRequest} [resendUserNotificationsRequest] Information about the user and calendar entry to send notifications for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public resendUserNotifications(resendUserNotificationsRequest?: ResendUserNotificationsRequest, options?: AxiosRequestConfig) {
        return StaffApiFp(this.configuration).resendUserNotifications(resendUserNotificationsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Set one or more staff as having attended the event
     * @param {MarkAttendedRequest} [markAttendedRequest] Staff that attended
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaffApi
     */
    public setStaffAttended(markAttendedRequest?: MarkAttendedRequest, options?: AxiosRequestConfig) {
        return StaffApiFp(this.configuration).setStaffAttended(markAttendedRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VenueApi - axios parameter creator
 * @export
 */
export const VenueApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary Delete venue with the specified ID
         * @param {number} id Unique ID for the venue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVenue: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteVenue', 'id', id)
            const localVarPath = `/venues/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get a list of ALL venues in the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllVenues: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/venues/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get a venue with the specified ID
         * @param {number} id Unique ID for the venue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVenueById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getVenueById', 'id', id)
            const localVarPath = `/venues/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get a list of ALL venues assigned to the provided calendar ID
         * @param {number} id Unique ID for the calendar ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVenuesForCalendarId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getVenuesForCalendarId', 'id', id)
            const localVarPath = `/venues/forcalendar/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Save a venue to the DB. If the ID is 0 it will be added, if it is greater than 0 it\'ll be updated
         * @param {Venue} venue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveVenue: async (venue: Venue, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'venue' is not null or undefined
            assertParamExists('saveVenue', 'venue', venue)
            const localVarPath = `/venues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(venue, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VenueApi - functional programming interface
 * @export
 */
export const VenueApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VenueApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary Delete venue with the specified ID
         * @param {number} id Unique ID for the venue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteVenue(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteVenue(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get a list of ALL venues in the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllVenues(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Venue>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllVenues(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get a venue with the specified ID
         * @param {number} id Unique ID for the venue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVenueById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Venue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVenueById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get a list of ALL venues assigned to the provided calendar ID
         * @param {number} id Unique ID for the calendar ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVenuesForCalendarId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Venue>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVenuesForCalendarId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Save a venue to the DB. If the ID is 0 it will be added, if it is greater than 0 it\'ll be updated
         * @param {Venue} venue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveVenue(venue: Venue, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Venue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveVenue(venue, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VenueApi - factory interface
 * @export
 */
export const VenueApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VenueApiFp(configuration)
    return {
        /**
         *
         * @summary Delete venue with the specified ID
         * @param {number} id Unique ID for the venue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVenue(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteVenue(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get a list of ALL venues in the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllVenues(options?: any): AxiosPromise<Array<Venue>> {
            return localVarFp.getAllVenues(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get a venue with the specified ID
         * @param {number} id Unique ID for the venue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVenueById(id: number, options?: any): AxiosPromise<Venue> {
            return localVarFp.getVenueById(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get a list of ALL venues assigned to the provided calendar ID
         * @param {number} id Unique ID for the calendar ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVenuesForCalendarId(id: number, options?: any): AxiosPromise<Array<Venue>> {
            return localVarFp.getVenuesForCalendarId(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Save a venue to the DB. If the ID is 0 it will be added, if it is greater than 0 it\'ll be updated
         * @param {Venue} venue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveVenue(venue: Venue, options?: any): AxiosPromise<Venue> {
            return localVarFp.saveVenue(venue, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VenueApi - object-oriented interface
 * @export
 * @class VenueApi
 * @extends {BaseAPI}
 */
export class VenueApi extends BaseAPI {
    /**
     *
     * @summary Delete venue with the specified ID
     * @param {number} id Unique ID for the venue
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VenueApi
     */
    public deleteVenue(id: number, options?: AxiosRequestConfig) {
        return VenueApiFp(this.configuration).deleteVenue(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get a list of ALL venues in the system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VenueApi
     */
    public getAllVenues(options?: AxiosRequestConfig) {
        return VenueApiFp(this.configuration).getAllVenues(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get a venue with the specified ID
     * @param {number} id Unique ID for the venue
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VenueApi
     */
    public getVenueById(id: number, options?: AxiosRequestConfig) {
        return VenueApiFp(this.configuration).getVenueById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get a list of ALL venues assigned to the provided calendar ID
     * @param {number} id Unique ID for the calendar ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VenueApi
     */
    public getVenuesForCalendarId(id: number, options?: AxiosRequestConfig) {
        return VenueApiFp(this.configuration).getVenuesForCalendarId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Save a venue to the DB. If the ID is 0 it will be added, if it is greater than 0 it\'ll be updated
     * @param {Venue} venue
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VenueApi
     */
    public saveVenue(venue: Venue, options?: AxiosRequestConfig) {
        return VenueApiFp(this.configuration).saveVenue(venue, options).then((request) => request(this.axios, this.basePath));
    }
}


