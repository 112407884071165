import React from "react";
import {Calendar, CalendarEntryListRequest, CalendarTypeEnum} from "../../../../api/grs";
import EventCalendar from "./Event/EventCalendar";
import {CalendarContext} from "../../../Context/CalendarContext";
import CalendarFilters from "./CalendarFilters";
import {getCalendarEntriesListForCalendarPage} from "../../../../store/fullCalendarItemList/actions/FullCalendarItemListActions";
import {useDispatch, useSelector} from "react-redux";
import {WithServiceState} from "store-fetch-wrappers/dist";
import {RootStore} from "../../../../store/Store";
import FilterContainer from "../../../Filters/FilterContainer";
import FrontlineCalendar from "./Frontline/FrontlineCalendar";
const EventCalendarServiceWrapper = WithServiceState(EventCalendar);
const FrontlineCalendarServiceWrapper = WithServiceState(FrontlineCalendar);

const CalendarGridWrapper = (props: Calendar) => {
    const dispatch = useDispatch();
    const calendarItemListStore = useSelector((state: RootStore) => state.fullCalendarItemList);

    /** Gets a list of entries for the calendar */
    const onEntryListRequestChanged = (request: CalendarEntryListRequest) => {
        dispatch(getCalendarEntriesListForCalendarPage(request));
    };
    return (
        <React.Fragment>
            <CalendarContext.Provider value={props}>
                {props.calendarType === CalendarTypeEnum.Frontline ? (
                    <React.Fragment>
                        <FilterContainer closed={false}>
                            <CalendarFilters onRequestChanged={onEntryListRequestChanged} />
                        </FilterContainer>
                        <FrontlineCalendarServiceWrapper
                            showLoadingText={true}
                            loaderType={"overlay"}
                            loaderWheelType={"three-ring"}
                            placeholderBlockCount={1}
                            {...calendarItemListStore}
                        />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <FilterContainer closed={false}>
                            <CalendarFilters onRequestChanged={onEntryListRequestChanged} />
                        </FilterContainer>
                        <EventCalendarServiceWrapper
                            showLoadingText={true}
                            loaderType={"overlay"}
                            loaderWheelType={"three-ring"}
                            placeholderBlockCount={1}
                            {...calendarItemListStore}
                        />
                    </React.Fragment>
                )}
            </CalendarContext.Provider>
        </React.Fragment>
    );
};

export default CalendarGridWrapper;
