import React, {useContext} from "react";
import {
    DutyManagerReportPayload,
    VehicleDetails
} from "../../../../../../../store/dutyManagerReport/actions/DutyManagerReportActionTypes";
import {DutyManagerReportEditableContext} from "../../Context/DutyManagerReportEditable";
import {useDispatch} from "react-redux";
import {addNewVehicle} from "../../../../../../../store/dutyManagerReport/actions/DutyManagerReportActions";
import VehicleDetailItem from "./VehicleDetailItem";
import FormHeaderWithAction from "../../../../../../Form/FormHeaderWithAction";
import {IconType} from "../../../../../../Icon/Icon";

const VehicleSection = (props: DutyManagerReportPayload) => {
    const dispatch = useDispatch();
    const editable = useContext(DutyManagerReportEditableContext);

    const addVehicleDetails = () => {
        dispatch(addNewVehicle());
    };
    return (
        <React.Fragment>
            <FormHeaderWithAction
                headerName={"Vehicles"}
                showIcon={editable}
                icon={IconType.Add}
                onClick={addVehicleDetails}
                tooltipText={"Add Vehicle"}
            />
            {props.vehicles.length > 0 ? (
                <React.Fragment>
                    {props.vehicles.map((details: VehicleDetails, index: number) => {
                        return (
                            <VehicleDetailItem
                                details={details}
                                vehicleList={props.vehicles}
                                key={index}
                            />
                        );
                    })}
                </React.Fragment>
            ) : (
                <div className="row ml-0 mr-0 mt-2">
                    <div className="col">
                        <p className="align-items-center align-self-center text-center mb-2">
                            {/* eslint-disable-next-line react/no-unescaped-entities */}
                            Vehicle details haven't been assigned to this report. Click the 'Plus'
                            to start
                        </p>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default VehicleSection;
