import {
    AssignmentState,
    CalendarTypeEnum,
    EventCalendarEntry,
    FrontlineCalendarEntry
} from "../../../../api/grs";
import moment from "moment";
import {
    blueGradient,
    greenGradient,
    redGradient,
    yellowGradient
} from "../../../../utils/colourUtils";
import {getTotalAssignments} from "../../AdminCalendarEntry/Helpers/calenderEntryHelpers";
import {routeNames} from "../../../Navigation/routeNames";
import React from "react";

/** Gets the query string for the calendar based on calendar type. Frontline calendars == week. Event Calendars == Month */
export function getMonthWeekQueryString(
    type: CalendarTypeEnum,
    weekValue: number,
    monthValue: number
) {
    return type === CalendarTypeEnum.Frontline ? `week=${weekValue}` : `month=${monthValue}`;
}

/** Gets the query string for the calendar based on calendar type. Frontline calendars == week. Event Calendars == Month.
 * Will get the query strings based on today's date.*/
export function getTodaysMonthWeekQueryString(type: CalendarTypeEnum) {
    const currentMonth = moment
        .unix(new Date().getTime() / 1000)
        .startOf("month")
        .unix();
    const currentWeek = moment
        .unix(new Date().getTime() / 1000)
        .startOf("isoWeek")
        .unix();

    return type === CalendarTypeEnum.Frontline ? `week=${currentWeek}` : `month=${currentMonth}`;
}

interface EntryAssignmentCounts {
    totalAccepted: number;
    totalBriefingAccepted: number;
    totalAttended: number;
    totalWaiting: number;
    totalUnassigned: number;
    trueAcceptedTotal: number;
    trueAttendedBriefingTotal: number;
    totalAssignments: number;
}

export function getAssignmentCountForEntry(
    entry: EventCalendarEntry | FrontlineCalendarEntry
): EntryAssignmentCounts {
    const totalAssignments = getTotalAssignments(entry);

    let totalAccepted = 0;
    let totalBriefingAccepted = 0;
    let totalAttended = 0;
    let totalWaiting = 0;
    let totalUnassigned = 0;

    for (const assignment of totalAssignments) {
        switch (assignment.state) {
            case AssignmentState.Attended:
                totalAttended++;
                break;
            case AssignmentState.Accepted:
                totalAccepted++;
                break;
            case AssignmentState.BriefingAccepted:
                totalBriefingAccepted++;
                break;
            case AssignmentState.WaitingAccept:
                totalWaiting++;
                break;
            case AssignmentState.Unassigned:
                totalUnassigned++;
                break;
        }
    }

    const trueAcceptedTotal = totalAccepted + totalBriefingAccepted + totalAttended;
    const trueAttendedBriefingTotal = totalBriefingAccepted + totalAttended;

    return {
        totalAccepted,
        totalBriefingAccepted,
        totalAttended,
        totalUnassigned,
        totalWaiting,
        trueAcceptedTotal,
        trueAttendedBriefingTotal,
        totalAssignments: totalAssignments.length
    };
}

/** Controls what colour is shown on the calendar
 * Legacy = green
 * No assignments = red
 * partially assigned = orange
 * all accepted = blue
 * all briefed = green
 * */
export function getCalendarColours(entry: EventCalendarEntry | FrontlineCalendarEntry) {
    const totalAssignments = getTotalAssignments(entry);

    const {totalUnassigned, trueAttendedBriefingTotal, trueAcceptedTotal} =
        getAssignmentCountForEntry(entry);

    const isHistoric = isEntryHistoric(entry);
    if (isHistoric) {
        return greenGradient;
    }

    if (totalUnassigned > 0 || totalAssignments.length === 0) {
        return redGradient;
    }

    // All staff assigned have accepted the briefing or have attended.
    if (trueAttendedBriefingTotal === totalAssignments.length) {
        return greenGradient;
    }

    // All staff are either accepted or briefing accepted.
    if (trueAcceptedTotal === totalAssignments.length) {
        return blueGradient;
    }

    // If none of the above are met, set the default to this
    return yellowGradient;
}

/** Checks to see if the entry is historic */
export function isEntryHistoric(entry: EventCalendarEntry | FrontlineCalendarEntry) {
    const endOfDay = moment.unix(entry.startDate).endOf("day").unix();
    const startOfCurrentDay = moment().startOf("day").unix();

    return startOfCurrentDay > endOfDay;
}

// eslint-disable-next-line no-shadow
export enum CalendarMonthView {
    Staff = "Staff Calendar",
    Global = "Global Calendar"
}

/** Creates the link to go back to the calendar for sidebar and actions that occur in child routes of the calendar. */
export function getCalendarPathForGlobalStaffCalendar(view: CalendarMonthView) {
    return `${routeNames.globalCalendar.path}/${encodeURI(view)}`;
}

export function renderEventContent(eventInfo: any) {
    const {calendarType} = eventInfo.event.extendedProps;
    return (
        <div
            className="custom-event-tab w-100 cursor-pointer"
            style={{background: eventInfo.backgroundColor, border: "none"}}
        >
            <p className="calendar-item pl-1 mb-0 text-wrap" style={{color: eventInfo.textColor}}>
                {getEventTitleBasedOnEventType(eventInfo, calendarType)}
            </p>
        </div>
    );
}

function getEventTitleBasedOnEventType(eventInfo: any, type: string) {
    const {timeText} = eventInfo.event.extendedProps;
    switch (type) {
        case CalendarTypeEnum.Event:
            return eventInfo.event.title;
        case CalendarTypeEnum.Frontline:
            return `${eventInfo.event.title} ${timeText}`;
    }
}
