import React from "react";
import FilterContainer from "../../../Filters/FilterContainer";
import EventCalendarFilters from "../Slices/Components/EventCalendarFilters";
import BackgroundContainer from "../../../Containers/BackgroundContainer";
import {ActiveDashView, useGrsDashContainer} from "../Shared/Hooks/useGrsDashContainer";
import DashViewButton from "./DashViewButton";
import {getActiveDashViewFromString} from "../../../../utils/enumUtils";
import {WithServiceState} from "store-fetch-wrappers/dist";
import EventCalendarCardContainer from "../Slices/Components/EventCalendarCardContainer";
import CalendarComplianceTileContainer from "../Tiles/CalendarComplianceTileContainer";

const SummaryStatsWrapper = WithServiceState(EventCalendarCardContainer);
const CalendarComplianceWrapper = WithServiceState(CalendarComplianceTileContainer);

function GrsDashContainer() {
    const {
        onRequestChanged,
        sortItemsByView,
        request,
        calendarSummaryStatsStore,
        calendarComplianceStore
    } = useGrsDashContainer();

    return (
        <BackgroundContainer background={"background-stars"} fullPage>
            {request && (
                <React.Fragment>
                    <div className="dash-tile-button-group">
                        {Object.keys(ActiveDashView).map((view) => {
                            return (
                                <DashViewButton
                                    key={view}
                                    onClick={(newView) => {
                                        if (newView === request?.dashView) return;
                                        onRequestChanged({
                                            ...request,
                                            dashView: newView
                                        });
                                    }}
                                    view={getActiveDashViewFromString(view)}
                                    isActive={request?.dashView === view}
                                />
                            );
                        })}
                    </div>
                    <FilterContainer closed={true}>
                        <EventCalendarFilters
                            request={request}
                            onRequestChanged={onRequestChanged}
                        />
                    </FilterContainer>
                    {request.dashView === ActiveDashView.Slices ? (
                        <SummaryStatsWrapper
                            showLoadingText={false}
                            loaderWheelType={"three-ring"}
                            loaderType={"overlay"}
                            {...calendarSummaryStatsStore}
                            data={sortItemsByView(
                                request?.archiveView,
                                calendarSummaryStatsStore.data || []
                            )}
                        />
                    ) : (
                        <CalendarComplianceWrapper
                            showLoadingText={false}
                            loaderWheelType={"three-ring"}
                            loaderType={"overlay"}
                            {...calendarComplianceStore}
                            data={sortItemsByView(
                                request?.archiveView,
                                calendarComplianceStore.data?.compliance || []
                            )}
                        />
                    )}
                </React.Fragment>
            )}
        </BackgroundContainer>
    );
}

export default GrsDashContainer;
