import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {CalendarEventItem} from "../../fullCalendarItemList/actions/FullCalendarItemListActionTypes";
import {CalendarEntryListResponse} from "../../../api/grs";

export const GLOBAL_CALENDAR_STORE = createStoreState("global_calendar");

interface GlobalCalendarLoading extends StoreServiceData<GlobalCalendarStore> {
    type: typeof GLOBAL_CALENDAR_STORE.LOADING;
}
interface GlobalCalendarError extends StoreServiceData<GlobalCalendarStore> {
    type: typeof GLOBAL_CALENDAR_STORE.ERROR;
}
interface GlobalCalendarSuccess extends StoreServiceData<GlobalCalendarStore> {
    type: typeof GLOBAL_CALENDAR_STORE.SUCCESS;
}

export type GlobalCalendarDispatchTypes =
    | GlobalCalendarLoading
    | GlobalCalendarError
    | GlobalCalendarSuccess;

export interface GlobalCalendarStore {
    results: CalendarEventItem[];
    entryListResponse: CalendarEntryListResponse;
    calendarToShow: CalendarToRender;
}

// eslint-disable-next-line no-shadow
export enum CalendarToRender {
    Frontline = "Frontline",
    Event = "Event",
    Pending = "Pending"
}
