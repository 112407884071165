import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {EventCalendarEntry, FrontlineCalendarEntry} from "../../../../api/grs";

export const ATTENDED_STORE = createStoreState("attended");

interface AttendedStoreLoading
    extends StoreServiceData<FrontlineCalendarEntry | EventCalendarEntry> {
    type: typeof ATTENDED_STORE.LOADING;
}
interface AttendedStoreError extends StoreServiceData<FrontlineCalendarEntry | EventCalendarEntry> {
    type: typeof ATTENDED_STORE.ERROR;
}
interface AttendedStoreSuccess
    extends StoreServiceData<FrontlineCalendarEntry | EventCalendarEntry> {
    type: typeof ATTENDED_STORE.SUCCESS;
}

export type AttendanceDispatchTypes =
    | AttendedStoreLoading
    | AttendedStoreError
    | AttendedStoreSuccess;
