import React from "react";
import Tooltip from "../../../components/Tooltip/Tooltip";
import Icon, {IconType} from "../../../components/Icon/Icon";
import {ExpandingTableData, isPrimitive} from "../Helpers/TableHelpers";
import {ExpandAction, ExpandingTableRowCellProps} from "../Components/ExpandingTableRowCell";

export function useExpandingRowCell<T extends ExpandingTableData>({
    itemKey,
    viewActionText,
    customRenderer,
    item,
    onExpandableItemClicked,
    expanded
}: ExpandingTableRowCellProps<T>) {
    function getMarkup() {
        return (
            <React.Fragment>
                {itemKey === "actions" ? getActionMarkup() : getColumnMarkup()}
            </React.Fragment>
        );
    }

    function getColumnMarkup() {
        return (
            <React.Fragment>
                {customRenderer ? (
                    <td align="left" className="pulse-table-row-item">
                        {customRenderer(item)}
                    </td>
                ) : (
                    <td align="left" className="pulse-table-row-item">
                        {isPrimitive(item[itemKey]) ? item[itemKey] : ""}
                    </td>
                )}
            </React.Fragment>
        );
    }

    // Actions column guaranteed to have items
    function getActionMarkup(): React.ReactNode {
        return (
            <td align="left" className="pulse-table-row-item">
                <div className="row ml-0 ml-0">
                    {getViewMoreMarkup()}
                    {customRenderer && customRenderer(item)}
                </div>
            </td>
        );
    }

    function getViewMoreMarkup(): React.ReactNode {
        return (
            <div className="icon-tooltip-wrapper-md mr-3">
                <Tooltip tooltipText={`${viewActionText}`} size={"md"} place={"left"}>
                    <Icon
                        rootElement={"Div"}
                        icon={expanded ? IconType.ChevronDownAlt : IconType.ChevronRightAlt}
                        size={"Medium"}
                        className="icon-dark"
                        onClick={() => {
                            onExpandableItemClicked({item, action: ExpandAction.View});
                        }}
                    />
                </Tooltip>
            </div>
        );
    }

    return {
        markup: getMarkup()
    };
}
