import React from "react";
import {Venue} from "../../../api/grs";
import Select from "react-select";
import {BaseDropdownPropsSingle} from "../Types/types";
import {useDropdownSingle} from "../Hooks/useDropdown/useDropdownSingle";

/** Dropdown for Venues */
const VenueDropdown = ({venueId, onChange, ...rest}: PulseVenueDropdown) => {
    const {options, selectedOption, handleDropdownChange} = useDropdownSingle<Venue>({
        storeName: "venueList",
        onChange,
        id: venueId,
        storeKeyLabel: "name",
        storeKeyValue: "id"
    });

    return (
        <React.Fragment>
            <Select
                className="search-bar"
                classNamePrefix="select-options"
                options={options}
                onChange={handleDropdownChange}
                isSearchable={rest.searchable}
                value={selectedOption}
                noOptionsMessage={() => rest.noItemsMessage}
                placeholder={rest.placeholder}
                isDisabled={rest.disabled}
                isClearable={rest.clearable}
                menuPortalTarget={document.body}
            />
        </React.Fragment>
    );
};

export default VenueDropdown;

export interface PulseVenueDropdown extends BaseDropdownPropsSingle<Venue> {
    venueId?: number;
}
