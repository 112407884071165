import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {Venue} from "../../../api/grs";
import {VENUE_STORE_STATE} from "../actions/VenueActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";

/** Default state of the store. */
const defaultState = createDefaultStoreState<Venue>(null);

/** Infer type of the reducer for code completion and quality */
const venueReducer = (
    state: StoreServiceData<Venue> = defaultState,
    action: ServiceActionTypes<Venue>
): StoreServiceData<Venue> =>
    createReducer(state, action, VENUE_STORE_STATE, () => showErrorToast(action.error));

export default venueReducer;
