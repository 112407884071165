import React from "react";

function ExpandingTableInnerRow({colSpan, children, className, height}: Props) {
    return (
        <tr>
            <td
                colSpan={colSpan || undefined}
                className={className || ""}
                height={height || undefined}
            >
                {children}
            </td>
        </tr>
    );
}

export default ExpandingTableInnerRow;

interface Props {
    colSpan?: number;
    children?: React.ReactNode;
    className?: string;
    height?: number;
}
