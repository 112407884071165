import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {XeroTrackingInfo} from "../../../api/grs";
import {XERO_TRACKING_STORE} from "../actions/XeroTrackingActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<XeroTrackingInfo>({
    categories: []
});

const xeroTrackingReducer = (
    state: StoreServiceData<XeroTrackingInfo> = defaultState,
    action: ServiceActionTypes<XeroTrackingInfo>
) => createReducer(state, action, XERO_TRACKING_STORE, () => showErrorToast(action.error));

export default xeroTrackingReducer;
