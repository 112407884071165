import {Dispatch} from "redux";
import {
    AVAILABLE_SHIFTS_ENTRY_LIST_STORE_STATE,
    AvailableShiftsEntryListDispatchTypes
} from "./AvailableShiftsEntryListActionTypes";
import {AvailableShiftsRequestUi} from "../../../api/grs";
import GrsApiModel from "../../apiModel/GrsApiModel";
import {getDataFromServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";

/** Nukes store of stale data */
export const nullifyAvailableShiftsEntryListStore = () => {
    return async (dispatch: Dispatch<AvailableShiftsEntryListDispatchTypes>) => {
        dispatch({
            type: AVAILABLE_SHIFTS_ENTRY_LIST_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

/** gets a list of calendar entries */
export const listAvailableShiftEntries = (args: AvailableShiftsRequestUi) => {
    return async (dispatch: Dispatch<AvailableShiftsEntryListDispatchTypes>) => {
        try {
            return await getDataFromServiceWithRedux(
                AVAILABLE_SHIFTS_ENTRY_LIST_STORE_STATE,
                dispatch,
                () => GrsApiModel.getCalendarApi().listShiftInterestEntries(args),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: AVAILABLE_SHIFTS_ENTRY_LIST_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
