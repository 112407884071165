import React from "react";
import Icon, {IconType} from "../../../../../Icon/Icon";

const FormMessageBlock = (props: FormMessageBlockProps) => (
    <div className="mt-3 ml-0 mr-0 row mb-3 readonly-warning pb-3 pt-3 w-100 d-flex">
        <div className="col-1">
            <Icon rootElement={"Span"} icon={IconType.Error} size={"Large"} />
        </div>
        <div className="col-11">
            <p className="mb-0">{props.message}</p>
        </div>
    </div>
);

export default FormMessageBlock;

interface FormMessageBlockProps {
    message: string;
}
