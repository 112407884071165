import React, {createRef, useEffect} from "react";
import {Moment} from "moment";
import {formatUnixToCustomFormat} from "../../utils/momentUtils";

function MobileDate({date, index, isActive, onRender, onClick}: Props) {
    const ref = createRef<HTMLDivElement>();

    useEffect(() => {
        if (!ref.current) return;
        const {width} = ref.current.getBoundingClientRect();
        onRender(index, width);
    }, [ref.current]);

    return (
        <React.Fragment>
            <div
                ref={ref}
                className={`horizontal-calendar-date ${isActive ? "active" : ""}`}
                onClick={() => onClick(index)}
            >
                <p className={`horizontal-calendar-day-text ${isActive ? "active" : ""}`}>
                    {formatUnixToCustomFormat(date.unix(), "ddd")}
                </p>
                <p className={`horizontal-calendar-date-text ${isActive ? "active" : ""}`}>
                    {formatUnixToCustomFormat(date.unix(), "DD")}
                </p>
            </div>
        </React.Fragment>
    );
}

export default MobileDate;

interface Props {
    date: Moment;
    index: number;
    isActive: boolean;
    onClick: (index: number) => void;
    onRender: (index: number, width: number) => void;
}
