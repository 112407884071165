import {Dispatch} from "redux";
import GrsApiModel from "../../apiModel/GrsApiModel";
import store, {RootStore} from "../../Store";
import {VENUE_STORE_STATE, VenueDispatchTypes} from "./VenueActionTypes";
import {fetchAllVenues} from "../../venueList/actions/VenueListActions";
import {Venue, VenueStateEnum} from "../../../api/grs";
import {
    deleteDataFromServiceWithRedux,
    getDataFromServiceWithRedux,
    postDataToServiceWithRedux
} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";
import {getRegionListing} from "../../regions/list/actions/RegionListActions";

/** Nukes store of stale data */
export const nullifyVenueStore = () => {
    return async (dispatch: Dispatch<VenueDispatchTypes>) => {
        dispatch({
            type: VENUE_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

export const createNewVenue = (calendarId: number) => {
    return async (dispatch: Dispatch<VenueDispatchTypes>) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        await dispatch(getRegionListing());
        dispatch({
            type: VENUE_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: {
                id: 0,
                calendarId,
                name: "",
                state: VenueStateEnum.Active,
                address: "",
                postcode: ""
            }
        });
    };
};

export const setVenue = (venue: Venue) => {
    return async (dispatch: Dispatch<VenueDispatchTypes>) => {
        dispatch({
            type: VENUE_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: venue
        });
    };
};

/** Gets venue by id */
export const fetchVenueById = (id: number) => {
    return async (dispatch: Dispatch<VenueDispatchTypes>, state: () => RootStore) => {
        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await dispatch(getRegionListing());

            await getDataFromServiceWithRedux(
                VENUE_STORE_STATE,
                dispatch,
                () => GrsApiModel.getVenueApi().getVenueById(id),
                statusCodeCallback
            );

            const data = state().venue.data;

            return data ? data : null;
        } catch (e: any) {
            dispatch({
                type: VENUE_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

/** deletes venue by id */
export const deleteVenueById = (id: number) => {
    return async (dispatch: Dispatch<VenueDispatchTypes>) => {
        try {
            await deleteDataFromServiceWithRedux(
                VENUE_STORE_STATE,
                dispatch,
                () => GrsApiModel.getVenueApi().deleteVenue(id),
                statusCodeCallback
            );
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return await store.dispatch(fetchAllVenues());
        } catch (e: any) {
            dispatch({
                type: VENUE_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

/** Saves venue to service */
export const saveVenueToService = (venue: Venue) => {
    return async (dispatch: Dispatch<VenueDispatchTypes>) => {
        try {
            return await postDataToServiceWithRedux(
                VENUE_STORE_STATE,
                dispatch,
                () => GrsApiModel.getVenueApi().saveVenue(venue),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: VENUE_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

/** gets venue from venue list */
export function getVenueFromVenueList(id: number | undefined, venues: Venue[]): Venue {
    const defaultVenue: Venue = {
        id: 0,
        name: "",
        calendarId: 0,
        postcode: "",
        address: "",
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state: "Active"
    };

    if (!id) return defaultVenue;

    const index = venues.findIndex((venue: Venue) => venue.id === id);
    if (index < 0) return defaultVenue;

    return venues[index];
}
