import React from "react";
import {HorizontalCalendarProps} from "./Store/actions/HorizontalCalendarActionTypes";
import {useHorizontalCalendar} from "./Hooks/useHorizontalCalendar";
import MobileDate from "./MobileDate";
import Icon, {IconType} from "../../components/Icon/Icon";

function HorizontalCalendar(props: HorizontalCalendarProps) {
    const {
        scrollViewRef,
        state,
        onRenderDay,
        onSelectDay,
        visibleDate,
        resetToCurrentDate,
        goToPreviousMonth,
        goToNextMonth
    } = useHorizontalCalendar(props);

    return (
        <React.Fragment>
            <div className="horizontal-calendar-wrapper">
                <div className="horizontal-calendar">
                    {state && (
                        <React.Fragment>
                            <div className="horizontal-calendar-actions-wrapper">
                                <div className="horizontal-calendar-action-arrows">
                                    <Icon
                                        rootElement={"Span"}
                                        icon={IconType.ChevronLeft}
                                        size={"Medium"}
                                        className={"horizontal-calendar-action-arrow ml-2"}
                                        onClick={goToPreviousMonth}
                                    />
                                </div>
                                <div className="horizontal-calendar-current-month">
                                    <p className="visible-month-and-year">
                                        {visibleDate}
                                        <Icon
                                            rootElement={"Span"}
                                            icon={IconType.Reset}
                                            size={"Large"}
                                            className={"horizontal-calendar-action-arrow mr-2"}
                                            onClick={resetToCurrentDate}
                                        />
                                    </p>
                                </div>
                                <div className="horizontal-calendar-action-arrows">
                                    <Icon
                                        rootElement={"Span"}
                                        icon={IconType.ChevronRight}
                                        size={"Medium"}
                                        className={"horizontal-calendar-action-arrow mr-2"}
                                        onClick={goToNextMonth}
                                    />
                                </div>
                            </div>
                            <div ref={scrollViewRef} className="horizontal-date-wrapper">
                                {state.dates.map((item, index) => {
                                    return (
                                        <MobileDate
                                            key={index}
                                            date={item}
                                            index={index}
                                            isActive={index === state?.currentDateIndex}
                                            onRender={onRenderDay}
                                            onClick={onSelectDay}
                                        />
                                    );
                                })}
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}
export default HorizontalCalendar;
