import React from "react";
import {StaffAssignment, StaffAssignmentGrouping} from "../../../../../../../api/grs";
import ReadOnlyCalendarEntryStaffAssignment from "../Assignment/ReadOnlyCalendarEntryStaffAssignment";

/** The grouping for the Calendar Entry */
const ReadOnlyCalendarEntryGroup = (props: StaffAssignmentGrouping) => {
    return (
        <React.Fragment>
            <div className="row ml-0 mr-0">
                <h6>{props.name}</h6>
            </div>
            <div className="row">
                <div className="col">
                    {props.assignments.map((el: StaffAssignment) => {
                        return <ReadOnlyCalendarEntryStaffAssignment {...el} key={el.id} />;
                    })}
                </div>
            </div>
        </React.Fragment>
    );
};

export default ReadOnlyCalendarEntryGroup;
