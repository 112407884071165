import React from "react";
import {StaffMemberInvoice} from "../../../../../../../api/grs";
import ExpandingTableInnerRow from "../../../../../../../modules/Table/Components/ExpandingTableInnerRow";
import InvoiceErrorsTable from "./InvoiceErrorsTable";
import HeaderWithActions from "../../../../../../../modules/Table/Components/HeaderWithActions";

function InvoiceErrorsTableContainer(props: StaffMemberInvoice) {
    return (
        <React.Fragment>
            {props.errors.length > 0 && (
                <React.Fragment>
                    <ExpandingTableInnerRow height={20} colSpan={5} />
                    <ExpandingTableInnerRow colSpan={5}>
                        <HeaderWithActions headerName={"Errors"} actions={[]} textColour={"red"} />
                    </ExpandingTableInnerRow>
                    <InvoiceErrorsTable {...props} />
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default InvoiceErrorsTableContainer;
