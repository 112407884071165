import React from "react";
import {AvailableShiftEntrySectionGroupProps} from "./AvailableShiftEntrySectionGroup";
import {useUserData} from "../../../../Hooks/useUserData";
import {StaffLink, UserInterest} from "../../../../../api/grs";
import {UserData} from "../../../../../api/staff";
import {useDispatch, useSelector} from "react-redux";
import {registerUserInterest} from "../../../../../store/notifications/registerInterest/actions/RegisterInterestActions";
import {RootStore} from "../../../../../store/Store";
import {Modal, useModal} from "pulse-modal";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../Button/MCButton";
import {Loading} from "store-fetch-wrappers";
import {showSuccessToast} from "../../../../../utils/toastUtils";

const UrgentActionButton = (props: UrgentActionButtonProps) => {
    const {isShown, toggle} = useModal();
    const user = useUserData();
    const dispatch = useDispatch();
    const registerUserInterestStore = useSelector((state: RootStore) => state.registerInterest);

    const registerInterest = async () => {
        if (user.username.length === 0) return;
        const request = getUserInterestRequest(user);

        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const success: boolean = await dispatch(registerUserInterest(request));

            if (!success) return;
            showSuccessToast("Successfully registered interest for this shift.");

            toggle();
            props.callback();
        } catch (e: any) {
            showSuccessToast("Could not register interest");
        }
    };

    const getUserInterestRequest = (incomingUser: UserData): UserInterest => {
        const staffLink: StaffLink = {
            staffId: incomingUser.username,
            staffName: `${incomingUser.firstName} ${incomingUser.lastName}`,
            flags: incomingUser.flags
        };
        return {
            section: {
                id: props.section.id,
                name: props.section.name
            },
            group: {
                id: props.group.id,
                name: props.group.name
            },
            entryId: props.entry.id,
            user: staffLink,
            reserveNotification: false
        };
    };

    return (
        <React.Fragment>
            {props.isMobileView ? (
                <button className="available-shift-calendar-action-button" onClick={toggle}>
                    Register Interest
                </button>
            ) : (
                <MCButton
                    size={ButtonSize.Large}
                    innerValue={"Register Interest"}
                    onClick={toggle}
                    colour={ButtonColourOptions.Yellow}
                    roundedCorner
                />
            )}

            <Modal
                isShown={isShown}
                onClose={toggle}
                title="Event Details"
                modalSize={"sm"}
                bodyChildren={
                    <React.Fragment>
                        <div className="row ml-0 mr-0">
                            {registerUserInterestStore.loading ? (
                                <Loading type={"three-ring"} showLoadingText={false} />
                            ) : (
                                <p className="mb-0">
                                    Are you sure you want to register interest for{" "}
                                    {props.group.name} - {props.section.name}?
                                </p>
                            )}
                        </div>
                    </React.Fragment>
                }
                footerChildren={
                    <div className="row ml-0 mr-0">
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Yes"}
                            onClick={registerInterest}
                            colour={ButtonColourOptions.Yellow}
                            roundedCorner
                        />
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Cancel"}
                            onClick={toggle}
                            colour={ButtonColourOptions.DarkBlue}
                            roundedCorner
                        />
                    </div>
                }
            />
        </React.Fragment>
    );
};

export default UrgentActionButton;

interface UrgentActionButtonProps extends AvailableShiftEntrySectionGroupProps {
    isMobileView?: boolean;
}
