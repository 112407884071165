import React, {useContext, useEffect, useState} from "react";
import {AvailableStaffLinkListContext} from "../../Context/AvailableStaffLinkListContext";
import {
    convertStaffLinkDetailsToDropdownProps,
    DDProps,
    StaffAssignmentDropdownProps
} from "../Helpers/dropdownUtils";
import {StaffLinkAddedDetails} from "../../Pages/AdminCalendarEntry/Helpers/calenderEntryHelpers";
import Select, {SingleValue} from "react-select";

/** Dropdown used to assign staff in a calendar entry in the GRS */
const CalendarEntryStaffAssignmentDropdown = (props: StaffAssignmentDropdownProps) => {
    const availableStaff = useContext(AvailableStaffLinkListContext);
    const [staffOptions, setStaffOptions] = useState<DDProps[]>([]);
    const [selectedOption, setSelectedOption] = useState<DDProps | undefined>(undefined);

    /** Detects when props have changed and when staff options have changed */
    useEffect(() => {
        const options = convertStaffLinkDetailsToDropdownProps(availableStaff);
        setStaffOptions(options);
    }, [availableStaff]);

    /** Update the assignment  */
    useEffect(() => {
        // If the initial assignment can't be found, set everything as undefined.
        if (!props.initialAssignment) {
            props.changeOption(undefined);
            setSelectedOption(undefined);
            return;
        }
        if (props.initialAssignment.staffId.length === 0) {
            props.changeOption(undefined);
            setSelectedOption(undefined);
            return;
        }
        setSelectedOption({
            label: props.initialAssignment.staffName,
            value: props.initialAssignment.staffId
        });
    }, [props]);

    /** Gets assignment from the available staff list based on the staff id */
    const getAssignmentFromAvailableStaffList = (staffId: string) => {
        const index = availableStaff.findIndex(
            (el: StaffLinkAddedDetails) => el.staffId === staffId
        );

        return index < 0 ? undefined : availableStaff[index];
    };

    /** When the selected option has changed */
    const handleAssignmentChange = (p: SingleValue<DDProps>) => {
        //If the option has been cleared, or we can't find the assignment, we return undefined.
        if (!p) {
            props.changeOption(undefined);
            setSelectedOption(undefined);
            return;
        }
        const assignment = getAssignmentFromAvailableStaffList(p.value.toString());
        if (!assignment) {
            props.changeOption(undefined);
            setSelectedOption(undefined);
            return;
        }

        // Otherwise, we set the new value and provide new object in callback
        props.changeOption(assignment);
        setSelectedOption(p);
    };

    return (
        <React.Fragment>
            <Select
                maxMenuHeight={200}
                className="search-bar"
                classNamePrefix="select-options"
                options={staffOptions}
                onChange={handleAssignmentChange}
                value={selectedOption}
                noOptionsMessage={() => "No Staff Members Found"}
                placeholder="Select Assignment..."
                isDisabled={props.disabled}
                isClearable={true}
                isSearchable={props.isClearable}
                menuPortalTarget={document.body}
            />
        </React.Fragment>
    );
};

export default CalendarEntryStaffAssignmentDropdown;
