import {EventsReportResponse} from "../../../api/grs";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const EVENT_REPORT_STORE = createStoreState("event_report");

interface EventReportLoading extends StoreServiceData<EventsReportResponse> {
    type: typeof EVENT_REPORT_STORE.LOADING;
}
interface EventReportError extends StoreServiceData<EventsReportResponse> {
    type: typeof EVENT_REPORT_STORE.ERROR;
}
interface EventReportSuccess extends StoreServiceData<EventsReportResponse> {
    type: typeof EVENT_REPORT_STORE.SUCCESS;
}

export type EventReportDispatchTypes = EventReportSuccess | EventReportLoading | EventReportError;
