import React from "react";
import {CalendarCompliance} from "../../../../api/grs";
import DayCompliance from "./DayCompliance";
import Icon, {IconType} from "../../../Icon/Icon";
import {useCalendarComplianceTile} from "./Hooks/useCalendarComplianceTile";
import {useCalendarDashActions} from "./Hooks/useCalendarDashActions";
import {PlatformOS, usePlatform} from "../../../Hooks/usePlatform";
import {useLongPress} from "../../../Hooks/useLongPress";

function CalendarComplianceTile(props: CalendarCompliance) {
    const {setShowEditIcon, showEditIcon} = useCalendarComplianceTile();
    const {editCalendar, goToCalendar} = useCalendarDashActions();
    const platform = usePlatform();
    const longPressEvent = useLongPress(() => setShowEditIcon(true), 500);
    return (
        <React.Fragment>
            <div
                data-testid={`${props.name}-dash-tile`}
                className={"dash-tile"}
                onMouseEnter={() => {
                    //Only allow for edit icon to show up on desktop
                    if (platform === PlatformOS.Mobile) return;
                    setShowEditIcon(true);
                }}
                onMouseLeave={() => {
                    //Only allow for edit icon to show up on desktop
                    if (platform === PlatformOS.Mobile) return;
                    setShowEditIcon(false);
                }}
                onTouchStart={longPressEvent.onTouchStart}
                onTouchEnd={longPressEvent.onTouchEnd}
                onClick={(event) => {
                    event.stopPropagation();
                    const {id, name, type} = props;
                    goToCalendar(id, name, type);
                }}
            >
                <div className="dash-tile-header">{props.name}</div>
                <div
                    data-testid={`${props.name}-dash-tile-icon`}
                    className={`fadeIn dash-tile-edit-icon-wrapper ${
                        showEditIcon ? "show" : "hide"
                    }`}
                    onClick={(e) => {
                        e.stopPropagation();
                        const {id, name} = props;

                        editCalendar(id, name);
                    }}
                >
                    <Icon
                        rootElement={"Span"}
                        icon={IconType.Edit}
                        size={"Medium"}
                        className="dash-tile-edit-icon"
                    />
                </div>
                <div className="tile-day-compliance-wrapper">
                    {props.compliant?.map((compliance, index) => {
                        return <DayCompliance {...compliance} key={index} />;
                    })}
                </div>
            </div>
        </React.Fragment>
    );
}

export default CalendarComplianceTile;
