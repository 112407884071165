import {StaffAccessLevel, UserData} from "../../../../api/staff";
import {NavigationItem} from "../../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../../Navigation/routeNames";
import {CalendarMonthView} from "../../AdminCalendar/Helpers/calendarUtils";
import {getPathToGlobalStaffCalendar} from "../../GlobalCalendar/Helpers/globalCalendarHelpers";

export function getNavigationItemsForAttendanceRoute(
    user: UserData,
    isBriefingRoute: boolean,
    query: URLSearchParams,
    fullPath: string
): NavigationItem[] {
    const {groupRosteringSystem, pendingNotifications, confirmAttendance, acceptBriefing} =
        routeNames;
    switch (user.accessLevel) {
        case StaffAccessLevel.SystemAdministrator:
            return [
                {
                    name: groupRosteringSystem.name,
                    path: groupRosteringSystem.path
                },
                {
                    name: CalendarMonthView.Global,
                    path: getPathToGlobalStaffCalendar(CalendarMonthView.Global, query)
                },
                {
                    name: pendingNotifications.name,
                    path: pendingNotifications.path
                },
                {
                    name: isBriefingRoute ? acceptBriefing.name : confirmAttendance.name,
                    path: fullPath
                }
            ];
        case StaffAccessLevel.DutyManager:
        case StaffAccessLevel.Staff:
            return [
                {
                    name: CalendarMonthView.Staff,
                    path: getPathToGlobalStaffCalendar(CalendarMonthView.Staff, query)
                },
                {
                    name: pendingNotifications.name,
                    path: pendingNotifications.path
                },
                {
                    name: isBriefingRoute ? acceptBriefing.name : confirmAttendance.name,
                    path: fullPath
                }
            ];
    }
}

export function convertStringToBoolean(value: string) {
    switch (value) {
        case "true":
            return true;
        case "false":
            return false;
        default:
            return false;
    }
}
