import React from "react";

export const CarouselItem = ({children, width}: Props) => {
    return (
        <div className="pulse-carousel-item" style={{width: width}}>
            {children}
        </div>
    );
};

interface Props {
    children: React.ReactNode;
    width?: number;
}
