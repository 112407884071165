import {useEffect} from "react";
import {ArchivedView, EventCalendarRequest} from "../../Slices/Components/EventCalendarFilters";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../../store/Store";
import {
    fetchCalendarSummaryStats,
    nullifyCalendarSummaryStatsStore
} from "../../../../../store/calendarSummaryStats/actions/CalendarSummaryStatsActions";
import {
    getCalendarCompliance,
    nullifyCalendarComplianceStore
} from "../../../../../store/calendarCompliance/actions/CalendarComplianceActions";
import {useGrsDashFilters} from "./useGrsDashFilters";

export function useGrsDashContainer() {
    const {request, updateRequest} = useGrsDashFilters({initialFetch: onRequestChanged});
    const calendarComplianceStore = useSelector((state: RootStore) => state.calendarCompliance);
    const calendarSummaryStatsStore = useSelector((state: RootStore) => state.calendarSummaryStats);
    const dispatch = useDispatch();

    useEffect(() => {
        return function () {
            dispatch(nullifyCalendarComplianceStore());
            dispatch(nullifyCalendarSummaryStatsStore());
        };
    }, []);

    function onViewSwitched(targetView: ActiveDashView) {
        switch (targetView) {
            case ActiveDashView.Slices:
                dispatch(fetchCalendarSummaryStats());
                break;
            case ActiveDashView.Tiles:
                dispatch(getCalendarCompliance());
                break;
        }
    }

    function onRequestChanged(incomingRequest: EventCalendarRequest) {
        if (JSON.stringify(incomingRequest) === JSON.stringify(request)) return;
        updateRequest(incomingRequest);
        onViewSwitched(incomingRequest.dashView);
    }

    function sortItemsByView<T extends Props>(
        view: ArchivedView | undefined,
        incomingStats: T[]
    ): T[] {
        if (!view) return incomingStats;
        switch (view) {
            case ArchivedView.All:
                return incomingStats.sort((a, b) => sortByAll(a, b));
            case ArchivedView.Archived:
                return incomingStats.filter((item) => filterArchived(item));
            case ArchivedView.Unarchived:
                return incomingStats.filter((item) => filterUnarchived(item));
        }
    }

    function sortByAll<T extends Props>(a: T, b: T): number {
        if ("summary" in a && "summary" in b) {
            return Number(a.summary?.archived) - Number(b.summary?.archived);
        }
        return Number(a.archived) - Number(b.archived);
    }

    function filterArchived<T extends Props>(value: T): boolean {
        if ("summary" in value) {
            return Number(value.summary?.archived) > 0;
        }

        return Number(value.archived) > 0;
    }

    function filterUnarchived<T extends Props>(value: T): boolean {
        if ("summary" in value) {
            return Number(value.summary?.archived) === 0;
        }

        return Number(value.archived) === 0;
    }

    return {
        onRequestChanged,
        sortItemsByView,
        request,
        calendarComplianceStore,
        calendarSummaryStatsStore
    };
}

interface Props {
    summary?: Summary;
    archived?: boolean;
}

interface Summary {
    archived: boolean;
}

// eslint-disable-next-line no-shadow
export enum ActiveDashView {
    Tiles = "Tiles",
    Slices = "Slices"
}

export const GrsDashQueryStrings = {
    dashView: "dashView",
    archivedFilter: "archivedFilter"
};
