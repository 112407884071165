import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes
} from "store-fetch-wrappers/dist";
import {EXPANDING_TABLE_STORE, ExpandingTableRows} from "../Actions/ExpandingTableActionTypes";
import {StoreServiceData} from "store-fetch-wrappers";
import {showErrorToast} from "../../../../utils/toastUtils";

const defaultState = createDefaultStoreState<ExpandingTableRows>({rows: []});

const expandingTableReducer = (
    state: StoreServiceData<ExpandingTableRows> = defaultState,
    action: ServiceActionTypes<ExpandingTableRows>
) => createReducer(state, action, EXPANDING_TABLE_STORE, () => showErrorToast(action.error));

export default expandingTableReducer;
