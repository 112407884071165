import React from "react";
import {
    Calendar,
    CalendarTypeEnum,
    EventsReportEntry,
    EventsReportEntryStaffSection
} from "../../../../../api/grs";
import {formatUnixToDateTime} from "../../../../../utils/momentUtils";
import EventReportSummarySection from "./EventReportSummarySection";
import FormHeader from "../../../../Form/FormHeader";
import {useSelector} from "react-redux";
import {RootStore} from "../../../../../store/Store";

/** Component that represents each entry in the response */
const EventReportSummary = (props: EventsReportEntry) => {
    const calendar = useSelector((state: RootStore) => state.calendar.data);
    return (
        <React.Fragment>
            <FormHeader headerName={getHeaderName(calendar, props)} />
            <div className="row ml-0 mr-0 mb-2">
                <div className="col pl-0 pr-0">
                    {props.sections.map((el: EventsReportEntryStaffSection, index: number) => {
                        return <EventReportSummarySection {...el} key={index} />;
                    })}
                </div>
            </div>
        </React.Fragment>
    );
};

export default EventReportSummary;

function getHeaderName(calendar: Calendar | undefined | null, entry: EventsReportEntry): string {
    if (!calendar) {
        return formatUnixToDateTime(entry.startDate);
    }

    return `${formatUnixToDateTime(entry.startDate)} - (${getEntryVenueNameForCalendar(
        calendar.calendarType,
        entry
    )})`;
}

function getEntryVenueNameForCalendar(
    calendarType: CalendarTypeEnum,
    entry: EventsReportEntry
): string {
    switch (calendarType) {
        case CalendarTypeEnum.Event:
            return entry.venueName || "Venue Deleted";
        case CalendarTypeEnum.Frontline:
            return entry.entryName;
    }
}
