import React, {useEffect} from "react";
import {routeNames} from "../../../../Navigation/routeNames";
import {useDispatch, useSelector} from "react-redux";
import {NavigationItem} from "../../../../../store/navigation/actions/NavigationActionTypes";
import {useUserData} from "../../../../Hooks/useUserData";
import {StaffAccessLevel, UserData} from "../../../../../api/staff";
import {setNavigationItems} from "../../../../../store/navigation/actions/NavigationActions";
import {usePageUrl} from "../../../../Hooks/usePageUrl";
import EditDutyManagerReportForm from "./Components/EditDutyManagerReportForm";
import {WithServiceState} from "store-fetch-wrappers/dist";
import {RootStore} from "../../../../../store/Store";
import {useQuery} from "../../../../Hooks/useQuery";
import {
    createNewDutyManagerReport,
    nullifyDutyManagerReportStore
} from "../../../../../store/dutyManagerReport/actions/DutyManagerReportActions";
import {nullifyCalendarEntryStore} from "../../../../../store/calendarEntry/actions/CalendarEntryActions";
import {DutyManagerReportEditableContext} from "./Context/DutyManagerReportEditable";
import {McConfig} from "../../../../../McConfigPlugin";
import {getUserAccessLevel} from "../../../../../utils/userDataUtils";
import useMcConfig from "../../../../Hooks/useMcConfig";

const DutyManagerReportFormServiceWrapper = WithServiceState(EditDutyManagerReportForm);

const CreateDutyManagerReport = () => {
    const dispatch = useDispatch();
    const user = useUserData();
    const {fullPath} = usePageUrl();
    const query = useQuery();
    const {config} = useMcConfig();
    const dutyManagerReportStore = useSelector((state: RootStore) => state.dutyManagerReport);

    useEffect(() => {
        return function () {
            dispatch(nullifyDutyManagerReportStore());
            dispatch(nullifyCalendarEntryStore());
        };
    }, []);

    useEffect(() => {
        const redirectUri = localStorage.getItem("calendar_entry_redirect_uri");
        if (!redirectUri) return;
        if (user.username.length === 0) return;
        const navItems: NavigationItem[] = getNavItemsForRoute(user, fullPath, redirectUri, config);

        dispatch(setNavigationItems(navItems));

        const entryId = query.get("calendarEntryId");
        const venueId = query.get("venueId");

        if (!entryId) return;
        if (!venueId) return;
        dispatch(createNewDutyManagerReport({calendarEntryId: +entryId, venueId: +venueId}, user));
    }, [user]);
    return (
        <React.Fragment>
            <DutyManagerReportEditableContext.Provider value={true}>
                <DutyManagerReportFormServiceWrapper
                    showLoadingText={true}
                    placeholderBlockCount={1}
                    loaderType={"overlay"}
                    loaderWheelType={"three-ring"}
                    {...dutyManagerReportStore}
                />
            </DutyManagerReportEditableContext.Provider>
        </React.Fragment>
    );
};

export default CreateDutyManagerReport;

function getNavItemsForRoute(
    user: UserData,
    currentPath: string,
    redirectUri: string,
    config: McConfig
): NavigationItem[] {
    if (!redirectUri) return [];

    const accessLevel = getUserAccessLevel(user, config);
    switch (accessLevel) {
        case StaffAccessLevel.SystemAdministrator:
            return [
                {
                    name: routeNames.groupRosteringSystem.name,
                    path: routeNames.groupRosteringSystem.path
                },
                {
                    name: routeNames.editCalendarEntry.name,
                    path: redirectUri
                },
                {
                    name: routeNames.createDutyManagerReport.name,
                    path: currentPath
                }
            ];
        case StaffAccessLevel.DutyManager:
            return [
                {
                    name: routeNames.readOnlyEntry.name,
                    path: redirectUri
                },
                {
                    name: routeNames.createDutyManagerReport.name,
                    path: currentPath
                }
            ];
        default:
            return [];
    }
}
