import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {SelectedSMSStaffMember} from "../../../components/SMS/Helpers/smsHelpers";

export const SMS_STORE = createStoreState("sms");

interface SMSLoading extends StoreServiceData<SMSFormDetails> {
    type: typeof SMS_STORE.LOADING;
}

interface SMSError extends StoreServiceData<SMSFormDetails> {
    type: typeof SMS_STORE.ERROR;
}

interface SMSSuccess extends StoreServiceData<SMSFormDetails> {
    type: typeof SMS_STORE.SUCCESS;
}

export type SMSDispatchTypes = SMSSuccess | SMSError | SMSLoading;

export interface SMSFormDetails {
    staffMembers: SelectedSMSStaffMember[];
    bodyText: string;
    sendToEventsStaff: boolean;
}
