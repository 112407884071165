import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {DUTY_MANAGER_REPORT_STORE_STATE} from "../actions/DutyManagerReportActionTypes";
import {DutyManagerReport} from "../../../api/grs";
import {showErrorToast} from "../../../utils/toastUtils";

/** Default state of the store. */
const defaultState = createDefaultStoreState<DutyManagerReport>(null);

/** Infer type of the reducer for code completion and quality */
const dutyManagerReportReducer = (
    state: StoreServiceData<DutyManagerReport> = defaultState,
    action: ServiceActionTypes<DutyManagerReport>
): StoreServiceData<DutyManagerReport> =>
    createReducer(state, action, DUTY_MANAGER_REPORT_STORE_STATE, () =>
        showErrorToast(action.error)
    );

export default dutyManagerReportReducer;
