import {useEffect, useState} from "react";
import moment, {Moment} from "moment";
import {CalendarEntryListRequest, CalendarGroup} from "../../../../../../../api/grs";
import {useQuery} from "../../../../../../Hooks/useQuery";
import {GrsChangeEvent} from "../../../../../../../utils/filterUtils";
import {useBaseCalendarFilters} from "../../../../Hooks/useBaseCalendarFilters";
import {useDispatch} from "react-redux";
import {setCalendarGroup} from "../../../../../../../store/calendarGroup/actions/CalendarGroupActions";

export function useGlobalCalendarMonthFilters({
    onChange
}: GrsChangeEvent<CalendarEntryListRequest>) {
    const {getIdsFromQueryString, buildRequestUrl} = useBaseCalendarFilters();
    const [startDate, setStartDate] = useState<Moment>();
    const [groupId, setGroupId] = useState<number>();
    const [currentCalendarGroup, setCurrentCalendarGroup] = useState<CalendarGroup>();
    const [calendarIds, setCalendarIds] = useState<number[]>();
    const query = useQuery();
    const dispatch = useDispatch();

    /** Upon mounting, see if we can get query string exists, if so, set date from this. */
    useEffect(() => {
        const cIds = query.get("calendarIds");
        setCalendarIds(cIds ? getIdsFromQueryString(cIds) : undefined);
        const month = query.get("month");
        const group = query.get("calendarGroup");
        setGroupId(group ? +group : undefined);

        if (month) {
            setStartDate(moment.unix(+month));
            return;
        }
        setStartDate(moment());
    }, []);

    /** Selected when the date changes */
    const onDateChanged = (date: Date | null) => {
        if (!date) return;

        setStartDate(moment(date));
    };

    function onGroupChanged(newGroup: CalendarGroup | undefined) {
        setCurrentCalendarGroup(newGroup);
        setCalendarIds(newGroup?.calendarIds);
        dispatch(setCalendarGroup(newGroup));
    }

    /** Watches for changes to start date */
    useEffect(() => {
        if (!startDate) return;
        const newReq = buildCalendarEntryRequest(startDate, calendarIds);
        onChange(newReq);

        buildRequestUrl(newReq, currentCalendarGroup, false);
    }, [startDate, currentCalendarGroup, calendarIds]);

    /** Builds request based on the calendar, if there is no calendar, we are looking at a staff calendar */
    const buildCalendarEntryRequest = (
        start: Moment,
        incCalendarIds: number[] | undefined
    ): CalendarEntryListRequest => {
        const startOfMonth = start.clone().startOf("month");
        const endOfMonth = start.clone().endOf("month");

        return {
            startDate: startOfMonth.clone().subtract(8, "days").unix(),
            endDate: endOfMonth.clone().add(8, "days").unix(),
            additionalCalendarIds: incCalendarIds
        };
    };

    return {
        startDate,
        groupId,
        onDateChanged,
        onGroupChanged
    };
}
