import {AutoInvoiceListRequest, AutoInvoiceRecord} from "../../../../../api/grs";
import {useDispatch} from "react-redux";
import {
    getInvoices,
    nullifyXeroInvoicesStore,
    triggerIndividualInvoice
} from "../../../../../store/xeroInvoices/actions/XeroInvoicesActions";
import {useXeroInvoiceFilters} from "./useXeroInvoiceFilters";
import {showErrorToast, showSuccessToast} from "../../../../../utils/toastUtils";
import {useEffect} from "react";

export function useXeroInvoicesList() {
    const {request, updateRequest} = useXeroInvoiceFilters({initialFetch: fetchData});
    const dispatch = useDispatch();

    useEffect(() => {
        return function () {
            dispatch(nullifyXeroInvoicesStore());
        };
    }, []);

    function onRequestChanged(entity: AutoInvoiceListRequest) {
        if (JSON.stringify(entity) === JSON.stringify(request)) return;
        fetchData(entity);
    }

    function fetchData(entity: AutoInvoiceListRequest) {
        updateRequest(entity);
        dispatch(getInvoices(entity));
    }

    async function generateInvoice(staffId: string, staffName: string) {
        if (!request) return;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const invoice: AutoInvoiceRecord = await dispatch(
            triggerIndividualInvoice(staffId, staffName)
        );

        if (isErroredInvoice(invoice)) {
            showErrorToast(
                `There were errors when generating an invoice for ${staffName}. Expand the row to view more.`
            );
            fetchData(request);
            return;
        }

        showSuccessToast(`Invoice generated for ${staffName}`);
        fetchData(request);
    }

    function isErroredInvoice(invoice: AutoInvoiceRecord): boolean {
        return invoice.rawData.errors.length > 0;
    }

    return {
        request,
        onRequestChanged,
        fetchData,
        generateInvoice
    };
}
