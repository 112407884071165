import React, {useEffect, useState} from "react";
import {ErrorLine, StaffMemberInvoice} from "../../../../../../../../api/grs";
import {formatUnixToDoMMMMYYYY} from "../../../../../../../../utils/momentUtils";

export function useInvoiceErrorsTable(props: StaffMemberInvoice) {
    const [items, setItems] = useState<TableRow[]>([]);
    function getHeaders() {
        return {
            location: "Location",
            date: "Date",
            error: "Error"
        };
    }

    useEffect(() => {
        setItems(getTableRows(props.errors));
    }, [props]);

    function getTableRows(entity: ErrorLine[]): TableRow[] {
        return entity.map(({calendarName, error, venueName, date}) => {
            return {
                location: getLocationMarkup({calendarName, venueName}),
                date: formatUnixToDoMMMMYYYY(date),
                error
            };
        });
    }

    function getLocationMarkup({calendarName, venueName}: Partial<ErrorLine>) {
        return (
            <React.Fragment>
                <h6 className="inner-table-item-header">{calendarName}</h6>
                <p className="mb-0">{venueName}</p>
            </React.Fragment>
        );
    }

    return {
        items,
        headers: getHeaders()
    };
}

interface TableRow {
    location: React.ReactNode;
    date: string;
    error: string;
}
