import React, {useEffect, useState} from "react";
import {AssignmentState, EventCalendarEntry, FrontlineCalendarEntry} from "../../../../../api/grs";
import {getTotalAssignments} from "../../Helpers/calenderEntryHelpers";
import {DonutChart, getDonutStat} from "../../../../../utils/donutChartUtils";
import {vibrantGreenPrimary, vibrantYellowPrimary} from "../../../../../utils/colourUtils";
import DonutChartList from "../../../../DonutChart/DonutChartList";

const CalendarEntryStats = (props: EventCalendarEntry | FrontlineCalendarEntry) => {
    const [donutStats, setDonutStats] = useState<DonutChart[]>([]);
    useEffect(() => {
        setDonutStats(calculateDonutChartStatsForEntry(props));
    }, [props]);

    const calculateDonutChartStatsForEntry = (
        entry: EventCalendarEntry | FrontlineCalendarEntry
    ) => {
        const totalAssignments = getTotalAssignments(entry);

        let briefingAccepted = 0;
        let accepted = 0;
        let attended = 0;

        for (const assignment of totalAssignments) {
            switch (assignment.state) {
                case AssignmentState.Attended:
                    attended++;
                    break;
                case AssignmentState.Accepted:
                    accepted++;
                    break;
                case AssignmentState.BriefingAccepted:
                    briefingAccepted++;
                    break;
            }
        }
        const trueAcceptedTotal = accepted + briefingAccepted + attended;
        const trueBriefingTotal = briefingAccepted + attended;

        const acceptedStat = getDonutStat(
            `${trueAcceptedTotal.toString()} / ${totalAssignments.length}`,
            "Staff Confirmed",
            trueAcceptedTotal,
            totalAssignments.length,
            vibrantGreenPrimary
        );
        const briefingStat = getDonutStat(
            `${trueBriefingTotal.toString()} / ${totalAssignments.length}`,
            "Briefing Accepted",
            trueBriefingTotal,
            totalAssignments.length,
            vibrantYellowPrimary
        );

        return [acceptedStat, briefingStat];
    };
    return (
        <React.Fragment>
            <div className="mt-2 mb-4">
                <DonutChartList chartData={donutStats} headerText={"Calendar Entry Stats"} />
            </div>
        </React.Fragment>
    );
};

export default CalendarEntryStats;
