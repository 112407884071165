import {Dispatch} from "redux";
import {
    GlobalStaffBlockDispatchTypes,
    GLOBAL_STAFF_BLOCK_STORE
} from "./GlobalStaffBlockActionTypes";
import GrsApiModel from "../../apiModel/GrsApiModel";
import {StaffBlockTemplate} from "../../../api/grs";
import {
    deleteDataFromServiceWithRedux,
    getDataFromServiceWithData,
    getDataFromServiceWithRedux,
    postDataToServiceWithRedux
} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";

export const nullifyGlobalStaffBlockStore = () => {
    return async (dispatch: Dispatch<GlobalStaffBlockDispatchTypes>) => {
        dispatch({
            type: GLOBAL_STAFF_BLOCK_STORE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

export const createNewGlobalStaffBlock = () => {
    return async (dispatch: Dispatch<GlobalStaffBlockDispatchTypes>) => {
        dispatch({
            type: GLOBAL_STAFF_BLOCK_STORE.SUCCESS,
            error: null,
            loading: false,
            data: {
                id: 0,
                name: "New Staff Block",
                staffBlock: {sections: []}
            }
        });
    };
};

export const setGlobalStaffBlock = (globalStaffBlock: StaffBlockTemplate) => {
    return async (dispatch: Dispatch<GlobalStaffBlockDispatchTypes>) => {
        dispatch({
            type: GLOBAL_STAFF_BLOCK_STORE.SUCCESS,
            error: null,
            loading: false,
            data: globalStaffBlock
        });
    };
};

export const deleteGlobalStaffBlock = (id: number) => {
    return async (dispatch: Dispatch<GlobalStaffBlockDispatchTypes>) => {
        try {
            return await deleteDataFromServiceWithRedux(
                GLOBAL_STAFF_BLOCK_STORE,
                dispatch,
                () => GrsApiModel.getCalendarApi().deleteStaffBlockTemplate(id),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: GLOBAL_STAFF_BLOCK_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

export const getGlobalStaffBlockFromServiceWithRedux = (id: number) => {
    return async (dispatch: Dispatch<GlobalStaffBlockDispatchTypes>) => {
        try {
            return await getDataFromServiceWithRedux(
                GLOBAL_STAFF_BLOCK_STORE,
                dispatch,
                () => GrsApiModel.getCalendarApi().getStaffBlockTemplateById(id),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: GLOBAL_STAFF_BLOCK_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

export const getGlobalStaffBlock = (id: number) => {
    return async (dispatch: Dispatch<GlobalStaffBlockDispatchTypes>) => {
        try {
            const data = await getDataFromServiceWithData(
                GLOBAL_STAFF_BLOCK_STORE,
                dispatch,
                () => GrsApiModel.getCalendarApi().getStaffBlockTemplateById(id),
                statusCodeCallback
            );

            dispatch({
                type: GLOBAL_STAFF_BLOCK_STORE.SUCCESS,
                error: null,
                loading: false,
                data
            });

            return data;
        } catch (e: any) {
            dispatch({
                type: GLOBAL_STAFF_BLOCK_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

export const saveGlobalStaffBlockToService = (staffBlock: StaffBlockTemplate) => {
    return async (dispatch: Dispatch<GlobalStaffBlockDispatchTypes>) => {
        try {
            return await postDataToServiceWithRedux(
                GLOBAL_STAFF_BLOCK_STORE,
                dispatch,
                () => GrsApiModel.getCalendarApi().saveStaffBlockTemplate(staffBlock),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: GLOBAL_STAFF_BLOCK_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
