import {useState} from "react";
import {
    AssignmentState,
    RepeatAssignmentConflictResolution,
    RepeatAssignmentsRequest
} from "../../../../api/grs";
import moment from "moment";
import {showErrorToast} from "../../../../utils/toastUtils";
import {useDispatch, useSelector} from "react-redux";
import {createRepetitiveAssignments} from "../../../../store/repeatAssignment/actions/RepeatAssignmentActions";
import {formatUnixToDDMMYYYY} from "../../../../utils/momentUtils";
import {getUiFriendlyText} from "../../../../utils/textUtils";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../../Navigation/routeNames";
import {ArchivedView} from "../../GroupRosteringSystem/Slices/Components/EventCalendarFilters";
import {RootStore} from "../../../../store/Store";

export function useRotaForm() {
    const dispatch = useDispatch();
    const [request, setRequest] = useState<RepeatAssignmentsRequest>(getBlankRequest());
    const history = useHistory();
    const rotaStore = useSelector((state: RootStore) => state.repeatAssignment);
    const summaries = useSelector((state: RootStore) =>
        (state.calendarSummaryStats.data || []).map((item) => item.summary)
    );

    function updateRequest(newReq: RepeatAssignmentsRequest) {
        setRequest(newReq);
    }

    async function save(): Promise<boolean> {
        const valid = validateRequest();
        if (!valid) return false;

        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return await dispatch(createRepetitiveAssignments(request));
        } catch (e: any) {
            showErrorToast(
                "Could not create rota for the dates specified. Contact System Administrator."
            );
            return false;
        }
    }

    function backToGRS() {
        const path = routeNames.groupRosteringSystem.path;
        const filter = ArchivedView.Unarchived;

        history.push({
            pathname: path,
            search: `?archivedFilter=${filter}`
        });
    }

    function validateRequest(): boolean {
        const {calendarIds, copyStartDateInclusive, copyEndDateExclusive, repeatEndDateExclusive} =
            request;

        if (calendarIds.length === 0) {
            showErrorToast("Calendar(s) must be selected to create a rota.");
            return false;
        }

        if (copyEndDateExclusive < copyStartDateInclusive) {
            showErrorToast("The copy date end cannot be before the copy date start.");
            return false;
        }

        if (repeatEndDateExclusive < copyEndDateExclusive) {
            showErrorToast("The final day to repeat to cannot be before the copy date end.");
            return false;
        }

        return true;
    }

    function getConflictResFromString(
        value?: string
    ): RepeatAssignmentConflictResolution | undefined {
        if (!value) return;

        return RepeatAssignmentConflictResolution[
            value as keyof typeof RepeatAssignmentConflictResolution
        ];
    }

    function getAssignmentStateFromString(value?: string): AssignmentState | undefined {
        if (!value) return;

        return AssignmentState[value as keyof typeof AssignmentState];
    }

    function message(): string {
        const {
            assignmentState,
            conflictResolution,
            repeatEndDateExclusive,
            copyEndDateExclusive,
            copyStartDateInclusive,
            calendarIds
        } = request;
        if (!assignmentState) return "";
        if (!conflictResolution) return "";
        const start = formatUnixToDDMMYYYY(copyStartDateInclusive);
        const end = formatUnixToDDMMYYYY(copyEndDateExclusive);
        const until = formatUnixToDDMMYYYY(repeatEndDateExclusive);
        const state = getUiFriendlyText(assignmentState);
        const res = getUiFriendlyText(conflictResolution);
        const names = getSelectedCalendarNames(calendarIds);
        return `Assignments will repeat from ${start} until ${end} until ${until}. Conflict resolution: ${res}. Assignment State: ${state}. ${
            names.length > 0 ? `Calendar(s) affected: ${names}.` : ""
        }`;
    }

    function getSelectedCalendarNames(ids: number[]): string {
        const names: string[] = [];
        for (const id of ids) {
            const summary = summaries.find((it) => it.id === id);
            if (!summary) continue;

            names.push(summary.name);
        }
        return names.join(", ");
    }

    function getBlankRequest(): RepeatAssignmentsRequest {
        const today = moment().startOf("day");
        return {
            calendarIds: [],
            copyEndDateExclusive: today.unix(),
            copyStartDateInclusive: today.unix(),
            repeatEndDateExclusive: today.unix(),
            conflictResolution: RepeatAssignmentConflictResolution.Skip,
            assignmentState: AssignmentState.Accepted
        };
    }

    return {
        request,
        updateRequest,
        save,
        getConflictResFromString,
        getAssignmentStateFromString,
        message,
        backToGRS,
        store: rotaStore
    };
}
