import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {AVAILABLE_SHIFTS_ENTRY_LIST_STORE_STATE} from "../actions/AvailableShiftsEntryListActionTypes";
import {AvailableShiftCalendarEntry} from "../../../api/grs";
import {showErrorToast} from "../../../utils/toastUtils";

/** Default state of the store. */
const defaultState = createDefaultStoreState<AvailableShiftCalendarEntry[]>([]);

/** Infer type of the reducer for code completion and quality */
const availableShiftsEntryListReducer = (
    state: StoreServiceData<AvailableShiftCalendarEntry[]> = defaultState,
    action: ServiceActionTypes<AvailableShiftCalendarEntry[]>
): StoreServiceData<AvailableShiftCalendarEntry[]> =>
    createReducer(state, action, AVAILABLE_SHIFTS_ENTRY_LIST_STORE_STATE, () =>
        showErrorToast(action.error)
    );

export default availableShiftsEntryListReducer;
