import React from "react";
import {CalendarEntry, StaffBlockSection} from "../../../../../../api/grs";
import FormHeader from "../../../../../Form/FormHeader";
import ReadOnlyCalendarEntrySection from "./Section/ReadOnlyCalendarEntrySection";

const ReadOnlyStaffManagement = (props: CalendarEntry) => {
    return (
        <React.Fragment>
            <FormHeader headerName={"Staff Management"} />
            {props.requiredStaff.sections.map((el: StaffBlockSection) => {
                return <ReadOnlyCalendarEntrySection {...el} key={el.id} />;
            })}
        </React.Fragment>
    );
};

export default ReadOnlyStaffManagement;
