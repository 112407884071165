import React from "react";
import {AssignmentState, CalendarMonthSummaryStats} from "../../../../../../api/grs";
import {
    getAssignmentCountFromStats,
    getBarPercentageForAssignmentState
} from "../../Helpers/eventCalendarUtils";
import {nanoid} from "nanoid";
import {getUiFriendlyText} from "../../../../../../utils/textUtils";
import ReactTooltip from "react-tooltip";

const EventCalendarStackedBarChartSegment = (props: Props) => {
    const width = getBarPercentageForAssignmentState(props.monthStats, props.assignmentState);
    const barClass = getClassNameForAssignmentState(props.assignmentState);
    const tooltipId = nanoid();
    return (
        <React.Fragment>
            {width > 0 && (
                <React.Fragment>
                    <div
                        data-tip
                        data-for={tooltipId}
                        className={`bar-base ${barClass} cursor-pointer`}
                        style={{width: `${width}%`}}
                    />
                    <ReactTooltip
                        place="bottom"
                        id={tooltipId}
                        type="dark"
                        effect="solid"
                        className="tooltip-override-lg"
                    >
                        <span>{getTooltipText(props)}</span>
                    </ReactTooltip>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default EventCalendarStackedBarChartSegment;

interface Props {
    monthStats: CalendarMonthSummaryStats;
    assignmentState: AssignmentState;
}

function getClassNameForAssignmentState(state: AssignmentState) {
    switch (state) {
        case AssignmentState.Unassigned:
            return "bar-unassigned";
        case AssignmentState.WaitingAccept:
            return "bar-waitingAccept";
        case AssignmentState.Accepted:
            return "bar-accepted";
        case AssignmentState.BriefingAccepted:
            return "bar-briefed";
        default:
            return "";
    }
}

function getTooltipText(props: Props) {
    const count = getAssignmentCountFromStats(
        props.monthStats.stateBreakdown,
        props.assignmentState
    );

    const uiFriendlyState = getUiFriendlyText(props.assignmentState);

    return `${uiFriendlyState}: ${count} / ${props.monthStats.numRequired}`;
}
