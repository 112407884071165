import {routeNames} from "../../../../Navigation/routeNames";
import {EventReportListItemResponse} from "../../../../../store/eventReportList/actions/EventReportListActionTypes";
import {AssignmentState} from "../../../../../api/grs";

/** Gets the full path of the Event Report Route */
export function getEventReportPath(calendarName: string, id: number) {
    return `${routeNames.calendar.path}/${encodeURI(calendarName)}${
        routeNames.eventReport.path
    }?id=${id}`;
}

/** Used to help sort raw data into readable format for event report. */
export interface EventReportFormat {
    sectionInformation: string;
    className: string;
}

/** Formats section and group info for Events Report format into a readable format. */
export function formatSectionInformationToString(sectionName: string, groupName: string): string {
    if (sectionName !== groupName) {
        return `${sectionName}: (${groupName})`;
    } else {
        return `${sectionName}`;
    }
}

/** Holds information regarding the staff count for the Event report */
export interface EventReportConfirmedStaffCount {
    confirmedCount: number;
    totalAssignmentCount: number;
}

/** Gets the total amount of confirmed staff for an event and the total assignments for that event too */
export function getConfirmedStaffCountForEventReport(
    report: EventReportListItemResponse
): EventReportConfirmedStaffCount {
    if (!report.entries) return {confirmedCount: 0, totalAssignmentCount: 0};

    let confirmed = 0;
    let totalAssignments = 0;

    for (const entry of report.entries) {
        for (const section of entry.sections) {
            for (const group of section.groups) {
                totalAssignments += group.numSlots;

                for (const assignment of group.assignments) {
                    switch (assignment.state) {
                        case AssignmentState.Accepted:
                        case AssignmentState.BriefingAccepted:
                        case AssignmentState.Attended:
                            confirmed++;
                            break;
                    }
                }
            }
        }
    }

    return {
        confirmedCount: confirmed,
        totalAssignmentCount: totalAssignments
    };
}
