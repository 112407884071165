import {Dispatch} from "redux";
import {
    SELECTED_WAGE_REPORT_STORE,
    SelectedWageReportDispatchTypes
} from "./SelectedWageReportActionTypes";
import {StaffWageReportEntry} from "../../../api/grs";

/** Updates the selected wage report */
export const setSelectedWageReportEntry = (report: StaffWageReportEntry | null | undefined) => {
    return async (dispatch: Dispatch<SelectedWageReportDispatchTypes>) => {
        dispatch({
            type: SELECTED_WAGE_REPORT_STORE.SUCCESS,
            error: null,
            loading: false,
            data: report
        });
    };
};
