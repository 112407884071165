import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const REPEAT_ENTRY_STORE = createStoreState("repeat_entry");

interface RepeatEntryLoading extends StoreServiceData<string> {
    type: typeof REPEAT_ENTRY_STORE.LOADING;
}
interface RepeatEntryError extends StoreServiceData<string> {
    type: typeof REPEAT_ENTRY_STORE.ERROR;
}
interface RepeatEntrySuccess extends StoreServiceData<string> {
    type: typeof REPEAT_ENTRY_STORE.SUCCESS;
}

export type RepeatEntryDispatchTypes = RepeatEntryLoading | RepeatEntryError | RepeatEntrySuccess;
