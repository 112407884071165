import {useHistory} from "react-router-dom";
import {routeNames} from "../../../../Navigation/routeNames";
import {usePageUrl} from "../../../../Hooks/usePageUrl";
import moment from "moment";
import {getMonthWeekQueryString} from "../../../AdminCalendar/Helpers/calendarUtils";
import {CalendarTypeEnum} from "../../../../../api/grs";

export function useCalendarDashActions() {
    const history = useHistory();
    const {searchQueries} = usePageUrl();

    function editCalendar(id: number, name: string) {
        const search = searchQueries.split("?")[1];
        const path = `${routeNames.editCalendar.path}/${encodeURI(name)}?id=${id}&${search}`;

        history.push(path);
    }

    function goToCalendar(id: number, name: string, calendarType: CalendarTypeEnum) {
        const currentMonth = moment().startOf("month").unix();
        const currentWeek = moment().startOf("isoWeek").unix();

        const monthWeekQueryString = getMonthWeekQueryString(
            calendarType,
            currentWeek,
            currentMonth
        );

        const path = `${routeNames.calendar.path}/${encodeURI(
            name
        )}?id=${id}&${monthWeekQueryString}`;

        history.push(path);
    }

    return {
        editCalendar,
        goToCalendar
    };
}
