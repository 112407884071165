import React from "react";
import Select, {components} from "react-select";
import {StaffLink} from "../../../api/grs";
import {StaffLinkAddedDetails} from "../../Pages/AdminCalendarEntry/Helpers/calenderEntryHelpers";
import {
    DropdownOptionWithDistance,
    useEntryAssignmentDropdown
} from "../Hooks/useEntryAssignmentDropdown";

function EntryAssignmentDropdown({
    initialAssignment,
    isSearchable,
    changeOption,
    disabled
}: EntryAssignmentDropdownProps) {
    const {staffOptions, selectedOption, onDropdownOptionChanged} = useEntryAssignmentDropdown({
        initialAssignment,
        isSearchable,
        changeOption,
        disabled
    });

    return (
        <Select
            maxMenuHeight={200}
            className="search-bar"
            classNamePrefix="select-options"
            options={staffOptions}
            onChange={onDropdownOptionChanged}
            value={selectedOption}
            noOptionsMessage={() => "No Staff Members Found"}
            placeholder="Select Assignment..."
            isDisabled={disabled}
            isClearable={true}
            isSearchable={isSearchable}
            menuPortalTarget={document.body}
            components={{Option: SelectRender}}
        />
    );
}

export default EntryAssignmentDropdown;

export interface EntryAssignmentDropdownProps {
    initialAssignment?: StaffLink;
    changeOption: (newAssignment?: StaffLinkAddedDetails) => void;
    isSearchable?: boolean;
    disabled?: boolean;
}

function SelectRender(props: any) {
    const {Option} = components;
    function renderLabel(data: DropdownOptionWithDistance) {
        const {distance, label, value} = data;

        if (distance) {
            return (
                <React.Fragment>
                    {label} <b>({distance.toFixed(2)} miles)</b>
                </React.Fragment>
            );
        }

        return label;
    }
    return <Option {...props}>{renderLabel(props.data)}</Option>;
}
