import React from "react";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import {Modal, useModal} from "pulse-modal";
import AvailableShiftsListEntryTable from "./AvailableShiftsListEntryTable";
import {AvailableShiftItemProps} from "./Admin/ShiftsOverviewListTable";

const AvailableShiftEntryAction = (props: AvailableShiftItemProps) => {
    const {toggle, isShown} = useModal();
    return (
        <React.Fragment>
            <MCButton
                size={ButtonSize.Large}
                roundedCorner
                innerValue={"View"}
                onClick={toggle}
                colour={ButtonColourOptions.DarkBlue}
            />
            <Modal
                modalSize={"xl"}
                title={"Shift List"}
                bodyChildren={<AvailableShiftsListEntryTable {...props} />}
                onClose={toggle}
                isShown={isShown}
            />
        </React.Fragment>
    );
};

export default AvailableShiftEntryAction;
