import {Dispatch} from "redux";
import {
    DUTY_MANAGER_REPORT_LISTING_STORE_STATE,
    DutyManagerReportListingDispatchTypes
} from "./DutyManagerReportListingActionTypes";
import GrsApiModel from "../../apiModel/GrsApiModel";
import {getDataFromServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";

/** Clears data in store */
export const nullifyDutyManagerReportListingStore = () => {
    return async (dispatch: Dispatch<DutyManagerReportListingDispatchTypes>) => {
        dispatch({
            type: DUTY_MANAGER_REPORT_LISTING_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: null
        });
    };
};

/** Clears data in store */
export const getDutyManagerReportByEntryId = (entryId: number) => {
    return async (dispatch: Dispatch<DutyManagerReportListingDispatchTypes>) => {
        try {
            return await getDataFromServiceWithRedux(
                DUTY_MANAGER_REPORT_LISTING_STORE_STATE,
                dispatch,
                () => GrsApiModel.getCalendarApi().getDutyManagerReportsForCalendarEntry(entryId),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: DUTY_MANAGER_REPORT_LISTING_STORE_STATE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};
