import React, {useEffect} from "react";
import {GrsChangeEvent} from "../../../../../../utils/filterUtils";
import {useToggle} from "../../../../../Hooks/useToggle";
import Toggle, {ToggleSize} from "../../../../../Toggle/Toggle";
import Tooltip from "../../../../../Tooltip/Tooltip";

const EntrySettingsToggle = (props: EntrySettingsToggleProps) => {
    const [showDetails, setShowDetails] = useToggle(props.initialValue);

    useEffect(() => {
        props.onChange(showDetails);
    }, [showDetails]);
    return (
        <React.Fragment>
            <Tooltip tooltipText={props.tooltipText} place={"right"} size={"lg"}>
                <Toggle
                    checked={showDetails}
                    onChange={() => setShowDetails()}
                    size={ToggleSize.Small}
                />
            </Tooltip>
        </React.Fragment>
    );
};

export default EntrySettingsToggle;

interface EntrySettingsToggleProps extends GrsChangeEvent<boolean> {
    initialValue: boolean;
    tooltipText: string;
}
