import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {Region} from "../../../../api/staff";
import {REGION_LIST_STORE} from "../actions/RegionListActionTypes";
import {showErrorToast} from "../../../../utils/toastUtils";

const initialState = createDefaultStoreState<Region[]>([]);

const regionListReducer = (
    state: StoreServiceData<Region[]> = initialState,
    action: ServiceActionTypes<Region[]>
) => createReducer(state, action, REGION_LIST_STORE, () => showErrorToast(action.error));

export default regionListReducer;
