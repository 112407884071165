import React from "react";
import {Venue, XeroTrackingCategory} from "../../../../api/grs";
import {useXeroTrackingCategoryListContainer} from "./Hooks/useXeroTrackingCategoryListContainer";
import NoItemsMessage from "../../../Table/NoItemsMessage";
import {IconType} from "../../../Icon/Icon";
import FormRowWithTooltip from "../../../Form/FormRowWithTooltip";

function XeroTrackingCategoryListContainer({
    categories,
    venue,
    updateVenue
}: XeroTrackingCategoryListContainerProps) {
    const {isChecked, onClick, isDisabled, getTooltipText} = useXeroTrackingCategoryListContainer({
        categories,
        venue,
        updateVenue
    });

    return (
        <React.Fragment>
            <FormRowWithTooltip
                icon={IconType.Circle}
                tooltipText={getTooltipText()}
                rowName={"Xero Tracking"}
                iconSize={"Small"}
                columnDetailClassName={"pl-0 pr-0"}
                iconClassName={categories.length > 0 ? "success-dot" : "error-dot"}
            >
                {categories.length > 0 ? (
                    categories?.map((category, index) => {
                        return (
                            <div className="xero-tracking mt-3" key={index}>
                                <p className="category-header mb-1">{category.name}</p>
                                {category.items.map((item, itemIndex) => {
                                    const disabled = isDisabled(category.id, item.id);
                                    return (
                                        <div key={itemIndex}>
                                            <span
                                                className={`item-wrapper ${
                                                    disabled ? "disabled" : ""
                                                }`}
                                            >
                                                <input
                                                    type="checkbox"
                                                    id={item.name}
                                                    name={item.name}
                                                    checked={isChecked(category.id, item.id)}
                                                    onClick={() =>
                                                        onClick(category.id, item.id, item.name)
                                                    }
                                                    className={`xero-tracking-item-input ${
                                                        disabled ? "disabled" : ""
                                                    }`}
                                                    disabled={disabled}
                                                />
                                                <label
                                                    htmlFor={item.name}
                                                    className={`xero-tracking-item ${
                                                        disabled ? "disabled" : ""
                                                    }`}
                                                >
                                                    {item.name}
                                                </label>
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })
                ) : (
                    <NoItemsMessage
                        message={
                            "Link to Xero hasn't been made. Could not load Tracking Information"
                        }
                    />
                )}
            </FormRowWithTooltip>
        </React.Fragment>
    );
}

export default XeroTrackingCategoryListContainer;

export interface XeroTrackingCategoryListContainerProps {
    venue: Venue;
    categories: XeroTrackingCategory[];
    updateVenue: (newVenue: Venue) => void;
}
