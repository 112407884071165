import {showErrorToast} from "../../../../../utils/toastUtils";
import GrsApiModel from "../../../../../store/apiModel/GrsApiModel";
import {XeroAuthStatus, XeroStartData} from "../../../../../api/grs";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {setNavigationItems} from "../../../../../store/navigation/actions/NavigationActions";
import {useXeroRoutes} from "../../Hooks/useXeroRoutes";
import {NonNullableStore} from "../../../../../store/helpers/storeHelpers";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../../../Navigation/routeNames";

export function useXero() {
    const [state, setState] = useState<NonNullableStore<XeroState>>({
        loading: false,
        error: null,
        data: {}
    });
    const dispatch = useDispatch();
    const {getSystemAdminRoutes} = useXeroRoutes();
    const history = useHistory();

    useEffect(() => {
        dispatch(setNavigationItems(getSystemAdminRoutes()));
        setState({...state, loading: true});
        fetchData();
        setState({...state, loading: false});
    }, []);

    async function fetchData() {
        const authStatus = await getStatus();
        // Already a link to Xero. No need to get start data
        if (authStatus && authStatus.linked) {
            history.replace(routeNames.xeroInvoices.path);
            return;
        }

        const startData = await getStartData();

        if (startData) {
            setState({...state, data: {...state.data, startData}});
            return;
        }

        showErrorToast("Could not initialise connection with Xero");
    }

    async function getStatus(): Promise<XeroAuthStatus | undefined> {
        try {
            const response = await GrsApiModel.getInvoiceApi().getXeroStatus();

            if (response.status === 200) {
                return response.data;
            }
        } catch (e: any) {
            showErrorToast("Could not get Xero Status. Please contact system support.");
        }
    }

    async function getStartData(): Promise<XeroStartData | undefined> {
        try {
            const response = await GrsApiModel.getInvoiceApi().startXeroAuth();

            if (response.status === 200) {
                return response.data;
            }
        } catch (e: any) {
            showErrorToast("Could not get Xero start data. Please contact system support");
        }
    }

    return {
        state
    };
}

interface XeroState {
    startData?: XeroStartData;
}
