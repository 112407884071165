import {combineReducers} from "redux";
import authReducer from "./auth/reducer/AuthReducer";
import navigationReducer from "./navigation/reducer/NavigationReducer";
import sidebarReducer from "./sidebar/reducer/sidebarReducer";
import mcConfigReducer from "./mcConfig/reducer/McConfigReducer";
import calendarSummaryStatsReducer from "./calendarSummaryStats/reducer/CalendarSummaryStatsReducer";
import calendarReducer from "./calendar/reducer/CalendarReducer";
import staffReportReducer from "./staffReport/reducer/StaffReportReducer";
import staffListReducer from "./staffList/reducer/StaffListReducer";
import staffWageReportReducer from "./staffWageReport/reducer/staffWageReportReducer";
import selectedWageReportReducer from "./selectedWageReport/reducer/SelectedWageReportReducer";
import FullCalendarItemListReducer from "./fullCalendarItemList/reducer/FullCalendarItemListReducer";
import calendarEntryListReducer from "./calendarEntryList/reducer/CalendarEntryListReducer";
import calendarEntryReducer from "./calendarEntry/reducer/CalendarEntryReducer";
import venueListReducer from "./venueList/reducer/VenueListReducer";
import venueReducer from "./venue/reducer/VenueReducer";
import eventReportReducer from "./eventReport/reducer/EventReportReducer";
import eventReportListReducer from "./eventReportList/reducer/EventReportListReducer";
import overlappingEntryListReducer from "./overlappingEntriesList/redcuer/OverlappingEntryListReducer";
import attendedReducer from "./notifications/attended/reducer/AttendedReducer";
import resendReducer from "./notifications/resend/reducer/ResendReducer";
import dutyManagerReportPagedReducer from "./dutyManagerReportPaged/reducer/DutyManagerReportPagedReducer";
import dutyManagerReportReducer from "./dutyManagerReport/reducer/DutyManagerReportReducer";
import dutyManagerReportListingReducer from "./dutyManagerReportListing/reducer/DutyManagerReportListingReducer";
import availableShiftsEntryListReducer from "./availableShiftsEntryList/reducer/AvailableShiftsEntryListReducer";
import registerInterestReducer from "./notifications/registerInterest/reducer/RegisterInterestReducer";
import staffInterestQueryResponseReducer from "./staffInterestQuery/reducer/StaffInterestQueryResponseReducer";
import userInterestListReducer from "./userInterest/reducer/UserInterestListReducer";
import pendingNotificationsReducer from "./pendingNotifications/reducer/PendingNotificationsReducer";
import globalStaffBlockReducer from "./globalStaffBlock/reducer/GlobalStaffBlockReducer";
import globalStaffBlockListReducer from "./globalStaffBlockList/reducer/GlobalStaffBlockListReducer";
import smsReducer from "./sms/reducer/SMSReducer";
import repeatEntryReducer from "./repeatEntry/reducer/RepeatEntryReducer";
import calendarGroupReducer from "./calendarGroup/reducer/CalendarGroupReducer";
import calendarGroupListReducer from "./calendarGroupList/reducer/CalendarGroupListReducer";
import globalCalendarReducer from "./globalCalendar/reducer/GlobalCalendarReducer";
import repeatAssignmentReducer from "./repeatAssignment/reducer/RepeatAssignmentReducer";

import horizontalCalendarReducer from "../modules/HorizontalDatePicker/Store/reducer/HorizontalCalendarReducer";
import mobileCalendarReducer from "./mobileCalendar/reducer/MobileCalendarReducer";
import calendarComplianceReducer from "./calendarCompliance/reducer/CalendarComplianceReducer";
import xeroInvoicesReducer from "./xeroInvoices/reducer/XeroInvoicesReducer";
import expandingTableReducer from "../modules/Table/Store/Reducer/ExpandingTableReducer";
import xeroTrackingReducer from "./xeroTracking/reducer/XeroTrackingReducer";
import regionListReducer from "./regions/list/reducer/RegionListReducer";
import regionReducer from "./regions/single/reducer/RegionReducer";
import geoLocationReducer from "./geolocation/single/reducer/GeoLocationReducer";

const RootReducer = combineReducers({
    navigation: navigationReducer,
    sidebar: sidebarReducer,
    auth: authReducer,
    mcConfig: mcConfigReducer,
    calendarSummaryStats: calendarSummaryStatsReducer,
    calendar: calendarReducer,
    staffReport: staffReportReducer,
    staffList: staffListReducer,
    staffWageReport: staffWageReportReducer,
    selectedWageReport: selectedWageReportReducer,
    fullCalendarItemList: FullCalendarItemListReducer,
    calendarEntryList: calendarEntryListReducer,
    calendarEntry: calendarEntryReducer,
    venueList: venueListReducer,
    venue: venueReducer,
    eventReport: eventReportReducer,
    eventReportList: eventReportListReducer,
    overlappingEntryList: overlappingEntryListReducer,
    attended: attendedReducer,
    resend: resendReducer,
    dutyManagerReportPaged: dutyManagerReportPagedReducer,
    dutyManagerReport: dutyManagerReportReducer,
    dutyManagerReportListing: dutyManagerReportListingReducer,
    availableShiftsEntryList: availableShiftsEntryListReducer,
    registerInterest: registerInterestReducer,
    staffInterestQueryResponse: staffInterestQueryResponseReducer,
    userInterest: userInterestListReducer,
    pendingNotifications: pendingNotificationsReducer,
    globalStaffBlock: globalStaffBlockReducer,
    globalStaffBlockList: globalStaffBlockListReducer,
    sms: smsReducer,
    repeatEntry: repeatEntryReducer,
    repeatAssignment: repeatAssignmentReducer,
    calendarGroup: calendarGroupReducer,
    calendarGroupList: calendarGroupListReducer,
    globalCalendar: globalCalendarReducer,
    horizontalCalendar: horizontalCalendarReducer,
    mobileCalendarView: mobileCalendarReducer,
    calendarCompliance: calendarComplianceReducer,
    xeroInvoices: xeroInvoicesReducer,
    xeroTracking: xeroTrackingReducer,
    expandingTable: expandingTableReducer,
    regionList: regionListReducer,
    region: regionReducer,
    geoLocation: geoLocationReducer
});

export default RootReducer;
