import {useSelector} from "react-redux";
import {RootStore} from "../../../../store/Store";
import {useEffect, useState} from "react";
import {CalendarTypeEnum} from "../../../../api/grs";

export function useGlobalCalendar() {
    const calendarItemListStore = useSelector((state: RootStore) => state.globalCalendar);
    const [showWeekElements, setShowWeekElements] = useState(false);
    const calendarGroupStore = useSelector((state: RootStore) => state.calendarGroup);

    useEffect(() => {
        const {data} = calendarGroupStore;
        if (!data) {
            setShowWeekElements(false);
            return;
        }
        setShowWeekElements(data.viewType === CalendarTypeEnum.Frontline);
    }, [calendarGroupStore]);

    return {
        store: calendarItemListStore,
        showWeekElements
    };
}
