import React, {useEffect, useState} from "react";
import {
    DDProps,
    GenericTypeDropDownProps,
    getSelectedDropdownOption
} from "../Helpers/dropdownUtils";
import Select, {SingleValue} from "react-select";
import {Enum} from "../../../utils/enumUtils";

/** Generic type dropdown which can take an enum to turn them into options */
const GenericTypeDropdown = (props: GenericTypeDropDownProps<Enum<any>>) => {
    const [genericOptions, setGenericOptions] = useState<DDProps[]>([]);
    const [selectedOption, setSelectedOption] = useState<DDProps>({value: 0, label: ""});

    /** Upon mounting, convert the enum into dropdown options */
    useEffect(() => {
        const enumOptions: (string | number)[] = Object.values(props.enumOptions);
        const options: DDProps[] = [];

        for (const option of enumOptions) {
            const label = props.splitByCapitalLetter
                ? option
                      .toString()
                      .split(/(?=[A-Z])+/g)
                      .join(" ")
                : option.toString();
            //Label is selectively rendered based on the props
            options.push({
                value: option,
                label
            });
        }
        setGenericOptions(options);

        // const option = getSelectedDropdownOption(props.id, options);
        //
        // setSelectedOption(option);
        // props.changeOption(option.value);
    }, []);

    useEffect(() => {
        if (genericOptions.length === 0) return;
        if (!props.id) {
            props.changeOption("");
            setSelectedOption({
                value: "",
                label: "Select an option"
            });
            return;
        }
        const option = getSelectedDropdownOption(props.id, genericOptions);
        if (!option) return;
        setSelectedOption(option);
        props.changeOption(option.value);
    }, [props.id, genericOptions]);

    /** Fired when a new option is selected */
    const handleGenericOptionChange = (p: SingleValue<DDProps>) => {
        if (!p) {
            props.changeOption("");
            setSelectedOption({
                value: "",
                label: "Select an option"
            });
            return;
        }

        props.changeOption(p.value);

        const option = getSelectedDropdownOption(p.value, genericOptions);

        setSelectedOption(option);
        props.changeOption(option.value);
    };

    return (
        <React.Fragment>
            <Select
                className="search-bar"
                classNamePrefix="select-options"
                options={genericOptions}
                onChange={handleGenericOptionChange}
                isSearchable={false}
                value={selectedOption}
                noOptionsMessage={() => "No Options Found"}
                isDisabled={props.disabled}
                isClearable={props.clearable}
                menuPortalTarget={props.appendToBody ? document.body : undefined}
            />
        </React.Fragment>
    );
};

export default GenericTypeDropdown;
