import React, {useEffect, useState} from "react";
import {AssignmentState, StaffReportEntry} from "../../../../../api/grs";
import {DonutChart, getDonutStat} from "../../../../../utils/donutChartUtils";
import {
    vibrantBluePrimary,
    vibrantGreenPrimary,
    vibrantRedPrimary,
    vibrantYellowPrimary
} from "../../../../../utils/colourUtils";
import DonutChartList from "../../../../DonutChart/DonutChartList";
import {getWageForStaffReport} from "../Helpers/staffReportHelpers";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const numeral = require("numeral");

const StaffReportDonutList = ({entries, headerText}: Props) => {
    const [donutList, setDonutList] = useState<DonutChart[]>([]);

    useEffect(() => {
        let briefingAccepted = 0;
        let accepted = 0;
        let attended = 0;
        let wage = 0;

        for (const entry of entries) {
            const shiftWage = getWageForStaffReport(entry);

            wage += shiftWage;
            switch (entry.state) {
                case AssignmentState.Accepted:
                    accepted++;
                    break;
                case AssignmentState.BriefingAccepted:
                    briefingAccepted++;
                    break;
                case AssignmentState.Attended:
                    attended++;
                    break;
            }
        }

        const numberOfShiftsThisMonth = getDonutStat(
            entries.length.toString(),
            "Number of Shifts",
            entries.length,
            entries.length,
            vibrantGreenPrimary
        );

        const trueAcceptedTotal = accepted + briefingAccepted + attended;
        const trueBriefingTotal = briefingAccepted + attended;

        const eventsAccepted = getDonutStat(
            `${trueAcceptedTotal.toString()} / ${entries.length}`,
            "Shifts Accepted",
            trueAcceptedTotal,
            entries.length,
            vibrantYellowPrimary
        );
        const numberOfBriefingsAccepted = getDonutStat(
            `${trueBriefingTotal.toString()} / ${entries.length}`,
            "Briefings Accepted",
            trueBriefingTotal,
            entries.length,
            vibrantBluePrimary
        );

        const formattedWage = numeral(wage).format("0,0.00");
        const totalCostOfMember = getDonutStat(
            `£${formattedWage}`,
            "Total Monthly Wage",
            wage,
            wage,
            vibrantRedPrimary
        );

        setDonutList([
            numberOfShiftsThisMonth,
            eventsAccepted,
            numberOfBriefingsAccepted,
            totalCostOfMember
        ]);
    }, [entries]);
    return (
        <React.Fragment>
            <div className="mt-4">
                <DonutChartList headerText={headerText} chartData={donutList} />
            </div>
        </React.Fragment>
    );
};

export default StaffReportDonutList;

interface Props {
    headerText: string;
    entries: StaffReportEntry[];
}
