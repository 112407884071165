import React, {useEffect, useState} from "react";
import {AssignmentState} from "../../api/grs";

const AssignmentStateAmI = ({assignmentStates, currentAssignmentState, children}: Props) => {
    const [canSee, setCanSee] = useState<boolean>(false);

    useEffect(() => {
        if (assignmentStates.length === 0) {
            setCanSee(true);
            return;
        }

        setCanSee(assignmentStates.indexOf(currentAssignmentState) > -1);
    }, [assignmentStates, currentAssignmentState]);
    return <React.Fragment>{canSee ? children : null}</React.Fragment>;
};

export default AssignmentStateAmI;

interface Props {
    currentAssignmentState: AssignmentState;
    assignmentStates: AssignmentState[];
    children: JSX.Element | JSX.Element[] | number | string | boolean | undefined | null;
}
