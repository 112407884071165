import React, {useContext, useEffect, useState} from "react";
import {CalendarEventItem} from "../../../../../store/fullCalendarItemList/actions/FullCalendarItemListActionTypes";
import WeekViewCalendar from "../../../../Calendar/WeekViewCalendar";
import moment, {Moment} from "moment";
import {useQuery} from "../../../../Hooks/useQuery";
import {WeekViewDayClickArgs} from "../../../../Calendar/Components/WeekViewDay";
import {useUserData} from "../../../../Hooks/useUserData";
import {StaffAccessLevel} from "../../../../../api/staff";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../../../Navigation/routeNames";
import {CalendarContext} from "../../../../Context/CalendarContext";
import {
    getQueryStringsForCreateFrontlineCalendarEntry,
    getQueryStringsForViewFrontlineCalendarEntry
} from "../../../../Calendar/Helpers/weekViewCalendarHelpers";
import {usePageUrl} from "../../../../Hooks/usePageUrl";
import useMcConfig from "../../../../Hooks/useMcConfig";
import {getUserAccessLevel} from "../../../../../utils/userDataUtils";

const FrontlineCalendar = (props: CalendarEventItem[]) => {
    const [currentDate, setCurrentDate] = useState<Moment>();
    const query = useQuery();
    const user = useUserData();
    const history = useHistory();
    const calendar = useContext(CalendarContext);
    const {fullPath} = usePageUrl();
    const {config} = useMcConfig();

    /** Watch for user. If the user is not a system admin, they should not be here on a Frontline calendar */
    useEffect(() => {
        if (user.username.length === 0) return;
        if (!config.auth) return;
        const accessLevel = getUserAccessLevel(user, config);
        switch (accessLevel) {
            case StaffAccessLevel.DutyManager:
            case StaffAccessLevel.Staff:
                history.replace(routeNames.notAuthorised.path);
                return;
        }
    }, [user, config]);

    /** Get week date from query string upon mounting */
    /** Anytime the search parameters change, update the date */
    useEffect(() => {
        updateEpochTime();
    }, [window.location.search]);

    /** Updates epoch time for calendar API */
    const updateEpochTime = () => {
        const week = query.get("week");

        if (!week) return;
        setCurrentDate(moment.unix(+week));
    };

    /** Handles when an event is clicked */
    const onEventClicked = (args: WeekViewDayClickArgs) => {
        if (!args.entry) {
            createCalendarEntry(args.startTime);
            return;
        }

        //View Calendar Entry
        viewCalendarEntry(args.entry);
    };
    /** Creates a calendarEntry */
    const createCalendarEntry = (startDate: number) => {
        if (!calendar) return;
        if (!currentDate) return;
        localStorage.setItem("calendar_redirect", fullPath);
        const path = `${routeNames.calendar.path}/${calendar.name}${routeNames.addCalendarEntry.path}`;
        const search = getQueryStringsForCreateFrontlineCalendarEntry(
            startDate,
            calendar.id,
            currentDate.unix()
        );
        history.push({
            pathname: path,
            search
        });
    };

    const viewCalendarEntry = (entry: CalendarEventItem) => {
        if (!calendar) return;
        if (!currentDate) return;
        localStorage.setItem("calendar_redirect", fullPath);
        const path = `${routeNames.calendar.path}/${calendar.name}${routeNames.editCalendarEntry.path}/${entry.id}`;
        const search = getQueryStringsForViewFrontlineCalendarEntry(
            currentDate.unix(),
            calendar.id
        );
        history.push({
            pathname: path,
            search
        });
    };
    return (
        <React.Fragment>
            {currentDate && (
                <div className="mt-4">
                    <WeekViewCalendar
                        items={props}
                        currentDate={currentDate}
                        onEventClick={onEventClicked}
                    />
                </div>
            )}
        </React.Fragment>
    );
};

export default FrontlineCalendar;
