import {Dispatch} from "redux";
import {RESEND_STORE, ResendDispatchTypes} from "./ResendActionTypes";
import GrsApiModel from "../../../apiModel/GrsApiModel";
import {ResendUserNotificationsRequest} from "../../../../api/grs";
import {postDataToServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../../helpers/storeHelpers";

export const nullifyResendActionStore = () => {
    return async (dispatch: Dispatch<ResendDispatchTypes>) => {
        dispatch({
            type: RESEND_STORE.SUCCESS,
            data: null,
            error: null,
            loading: false
        });
    };
};

export const resendNotification = (request: ResendUserNotificationsRequest) => {
    return async (dispatch: Dispatch<ResendDispatchTypes>) => {
        try {
            return await postDataToServiceWithRedux(
                RESEND_STORE,
                dispatch,
                () => GrsApiModel.getStaffApi().resendUserNotifications(request),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: RESEND_STORE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};
