import {useEffect, useState} from "react";
import {AutoInvoiceListRequest} from "../../../../../api/grs";
import {useQuery} from "../../../../Hooks/useQuery";
import moment from "moment";
import {useHistory} from "react-router-dom";
import {pagedRequestConfig} from "../../../../Filters/helpers/filterHelpers";

export function useXeroInvoiceFilters({initialFetch}: Props) {
    const [request, setRequest] = useState<AutoInvoiceListRequest>();
    const query = useQuery();
    const history = useHistory();

    useEffect(() => {
        const month = query.get(XeroQueryStrings.month);
        const year = query.get(XeroQueryStrings.year);
        const pageNum = query.get(XeroQueryStrings.pageNum);

        const now = moment();

        const initialRequest: AutoInvoiceListRequest = {
            // Moment use 0 as Jan and 11 as Dec. For it to make sense to users.
            // We just subtract internally and show the correct number corresponding to the month
            // when generating the query string
            month: month ? +month : now.month() + 1,
            year: year ? +year : now.year(),
            numPerPage: pagedRequestConfig.resultsPerPage,
            pageNum: pageNum ? +pageNum : 0
        };

        initialFetch(initialRequest);
        updateRequest(initialRequest);
    }, []);

    function updateRequest(newRequest: AutoInvoiceListRequest) {
        setRequest(newRequest);
        buildUrlHistory(newRequest);
    }

    function buildUrlHistory(entity: AutoInvoiceListRequest) {
        const queries: string[] = [];

        //month=2
        queries.push(`${XeroQueryStrings.month}=${entity.month}`);

        //year=2023
        queries.push(`${XeroQueryStrings.year}=${entity.year}`);

        queries.push(`${XeroQueryStrings.pageNum}=${entity.pageNum}`);

        const search = queries.join("&");

        history.push({
            search
        });
    }

    return {
        request,
        updateRequest
    };
}

interface Props {
    initialFetch: (request: AutoInvoiceListRequest) => void;
}

export const XeroQueryStrings = {
    month: "month",
    year: "year",
    pageNum: "pageNum"
};
