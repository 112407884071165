import React from "react";
import {EventsReportEntry, EventsReportResponse} from "../../../../../api/grs";
import EventReportSummary from "./EventReportSummary";

/** Wrapper for Entries in report response */
const EventReportSummaryWrapper = (props: EventsReportResponse) => {
    return (
        <React.Fragment>
            <div className="mt-5 mb-5">
                {props.entries && (
                    <React.Fragment>
                        {props.entries.length > 0 ? (
                            <React.Fragment>
                                {props.entries.map((el: EventsReportEntry, index: number) => {
                                    return <EventReportSummary {...el} key={index} />;
                                })}
                            </React.Fragment>
                        ) : (
                            <div className="row ml-0 mr-0 fadeIn">
                                <div className="col">
                                    <h5 className="text-center p-3">
                                        There are no events that require attention for this month
                                    </h5>
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                )}
            </div>
        </React.Fragment>
    );
};

export default EventReportSummaryWrapper;
