import React, {useCallback, useEffect, useState} from "react";
import {EventCalendarEntry, FrontlineCalendarEntry} from "../../../../../../api/grs";
import TimeDropdown from "../../../../../Dropdown/Components/TimeDropdown";
import {useDispatch} from "react-redux";
import {setCalendarEntry} from "../../../../../../store/calendarEntry/actions/CalendarEntryActions";
import FormRow from "../../../../../Form/FormRow";

/** Controls all the times for the calendar entry. Should only appear for Hourly billed calendars */
const HourlyBilledTimeDetails = (props: EventCalendarEntry | FrontlineCalendarEntry) => {
    const dispatch = useDispatch();

    const [startDate, setStartDate] = useState<number>(props.startDate);
    const [endDate, setEndDate] = useState<number>(props.endDate);
    const [actualStartDate, setActualStartDate] = useState<number | undefined>(
        props.actualStartDate
    );
    const [actualEndDate, setActualEndDate] = useState<number | undefined>(props.actualEndDate);

    useEffect(() => {
        setActualStartDate(props.actualStartDate ? props.actualStartDate : 0);
        setActualEndDate(props.actualEndDate ? props.actualEndDate : 0);
    }, [props]);

    /** Triggered when start time has been changed */
    const onStartTimeChanged = useCallback(
        (id?: string | number) => {
            if (!id) return;
            setStartDate(+id);
        },
        [startDate]
    );

    /** Triggered when end time has been changed */
    const onEndTimeChanged = useCallback(
        (id?: string | number) => {
            if (!id) return;
            setEndDate(+id);
        },
        [endDate]
    );

    /** Triggered when actual start time has been changed */
    const onActualStartTimeChanged = useCallback(
        (id?: string | number) => {
            if (!id) {
                setActualStartDate(undefined);
                return;
            }
            setActualStartDate(+id);
        },
        [actualStartDate]
    );

    /** Triggered when actual end time has been changed */
    const onActualEndTimeChanged = useCallback(
        (id?: string | number) => {
            if (!id) {
                setActualEndDate(undefined);
                return;
            }
            setActualEndDate(+id);
        },
        [actualEndDate]
    );

    /** Update Calendar Entry with new start time */
    useEffect(() => {
        dispatch(
            setCalendarEntry({
                ...props,
                startDate,
                actualStartDate: startDate
            })
        );
    }, [startDate]);

    /** Update Calendar Entry with new end time */
    useEffect(() => {
        dispatch(
            setCalendarEntry({
                ...props,
                endDate,
                actualEndDate: endDate
            })
        );
    }, [endDate]);

    /** Update Calendar Entry with new actual start time */
    useEffect(() => {
        let actualStart: number | undefined = actualStartDate;

        //Actual start date comes through as 0 for some reason. if so, we make it undefined here
        if (actualStart === 0) {
            actualStart = undefined;
        }
        dispatch(
            setCalendarEntry({
                ...props,
                actualStartDate: actualStart
            })
        );
    }, [actualStartDate]);

    /** Update Calendar Entry with new actual end time */
    useEffect(() => {
        let actualEnd: number | undefined = actualEndDate;

        //Actual end date comes through as 0 for some reason. if so, we make it undefined here
        if (actualEnd === 0) {
            actualEnd = undefined;
        }
        dispatch(
            setCalendarEntry({
                ...props,
                actualEndDate: actualEnd
            })
        );
    }, [actualEndDate]);

    return (
        <React.Fragment>
            <div className="row ml-0 mr-0 mt-3">
                <div className="col-sm-6 pl-0 calendar-entry-input-override">
                    <FormRow rowName={"Scheduled Shift Time"} columnDetailClassName={"pl-0 pr-0"}>
                        <TimeDropdown
                            entry={props}
                            id={startDate}
                            optionsPerHour={4}
                            clearable={false}
                            disabled={false}
                            daysToGenerate={1}
                            changeOption={onStartTimeChanged}
                            isSearchable={true}
                        />
                    </FormRow>
                </div>
                <div className="col-sm-6 pr-0 calendar-entry-input-override">
                    <FormRow rowName={"Scheduled Finish Time"} columnDetailClassName={"pl-0 pr-0"}>
                        <TimeDropdown
                            entry={props}
                            id={endDate}
                            optionsPerHour={4}
                            clearable={false}
                            disabled={false}
                            daysToGenerate={2}
                            changeOption={onEndTimeChanged}
                            isSearchable={true}
                        />
                    </FormRow>
                </div>
            </div>
            <div className="row ml-0 mr-0 mt-3">
                <div className="col-sm-6 pl-0 calendar-entry-input-override">
                    <FormRow rowName={"Actual Shift Time"} columnDetailClassName={"pl-0 pr-0"}>
                        <TimeDropdown
                            entry={props}
                            id={actualStartDate}
                            optionsPerHour={4}
                            clearable={true}
                            disabled={false}
                            daysToGenerate={1}
                            changeOption={onActualStartTimeChanged}
                            isSearchable={true}
                        />
                    </FormRow>
                </div>
                <div className="col-sm-6 pr-0 calendar-entry-input-override">
                    <FormRow rowName={"Actual Finish Time"} columnDetailClassName={"pl-0 pr-0"}>
                        <TimeDropdown
                            entry={props}
                            id={actualEndDate}
                            optionsPerHour={4}
                            clearable={true}
                            disabled={false}
                            daysToGenerate={2}
                            changeOption={onActualEndTimeChanged}
                            isSearchable={true}
                        />
                    </FormRow>
                </div>
            </div>
        </React.Fragment>
    );
};

export default HourlyBilledTimeDetails;
