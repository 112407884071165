import React, {useEffect, useState} from "react";
import {GenericNameValueToggle} from "../../../../../../utils/sortingUtils";
import {
    AssignmentState,
    ClinicalGrade,
    StaffAssignment,
    StaffAssignmentGrouping
} from "../../../../../../api/grs";
import {GlobalStaffBlockUpdateProps, StaffBlockUpdateType} from "./StaffSection";
import {nanoid} from "nanoid";
import {Modal, useModal} from "pulse-modal";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../../Button/MCButton";
import {getClinicalGradeToggleOptionsFromClinicalGrades} from "../../Helpers/staffBlockHelpers";
import {getClinicalGradeFromString} from "../../../../../../utils/enumUtils";
import FormRow from "../../../../../Form/FormRow";
import {DebounceInput} from "react-debounce-input";
import FormActionContainer from "../../../../../Form/FormActionContainer";

const EditCalendarGrouping = (props: GlobalStaffBlockUpdateProps<StaffAssignmentGrouping>) => {
    const [clinicalGrades, setClinicalGrades] = useState<GenericNameValueToggle[]>([]);
    const [requiredStaffCount, setRequiredStaffCount] = useState<number>(0);
    const {isShown, toggle} = useModal();

    /** Converts the options of clinical grades to toggle options */
    useEffect(() => {
        const toggleFieldProps = getClinicalGradeToggleOptionsFromClinicalGrades(
            props.item.clinicalGrades
        );
        setClinicalGrades(toggleFieldProps);
        setRequiredStaffCount(props.item.assignments.length);
    }, [props.item]);

    const deleteGrouping = () => {
        props.onItemChanged({newItem: props.item, updateType: StaffBlockUpdateType.Delete});
    };

    const changeRequiredStaffCount = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const count = +event.target.value || 0;
        const requiredCount = count > 50 ? 50 : count;

        const assignments = await generateBlankAssignments(requiredCount);
        const newGroup: StaffAssignmentGrouping = {
            ...props.item,
            assignments
        };

        props.onItemChanged({
            updateType: StaffBlockUpdateType.Update,
            newItem: newGroup
        });
    };

    /** Updates the toggled option of the clinical grade for the group */
    const changeToggledOption = (prop: GenericNameValueToggle) => {
        if (!prop.checked) {
            const selectedGrade = getClinicalGradeFromString(prop.value);
            const newGroup: StaffAssignmentGrouping = {
                ...props.item,
                clinicalGrades: [...props.item.clinicalGrades, selectedGrade]
            };
            props.onItemChanged({
                updateType: StaffBlockUpdateType.Update,
                newItem: newGroup
            });
            return;
        }
        const index = props.item.clinicalGrades.findIndex((el: ClinicalGrade) => el === prop.value);
        if (index < 0) return;

        const updatedGrades = props.item.clinicalGrades.filter((el: ClinicalGrade, i: number) => {
            return i !== index;
        });

        const newGroup: StaffAssignmentGrouping = {
            ...props.item,
            clinicalGrades: updatedGrades
        };
        props.onItemChanged({
            updateType: StaffBlockUpdateType.Update,
            newItem: newGroup
        });

        // dispatch(setCalendarGroup(details));
    };
    return (
        <React.Fragment>
            <div className="calendar-group-card float-left mt-2 mb-2 mr-2">
                <FormRow rowName={"Group Name"} columnDetailClassName={"pl-0 pr-0"}>
                    <DebounceInput
                        debounceTimeout={300}
                        className="input-fields"
                        value={props.item.name}
                        onChange={(event) => {
                            const newGroup: StaffAssignmentGrouping = {
                                ...props.item,
                                name: event.target.value
                            };

                            props.onItemChanged({
                                updateType: StaffBlockUpdateType.Update,
                                newItem: newGroup
                            });
                        }}
                        placeholder="Enter group name here..."
                    />
                </FormRow>
                <FormRow rowName={"Clinical Grades"}>
                    {clinicalGrades.map((el: GenericNameValueToggle) => {
                        return (
                            <label key={el.value}>
                                <input
                                    type="checkbox"
                                    name={el.name}
                                    value={el.value}
                                    onChange={() => changeToggledOption(el)}
                                    checked={el.checked}
                                />
                                <span className="ml-2">{el.name} </span>
                            </label>
                        );
                    })}
                </FormRow>
                <FormRow rowName={"Required Number of Staff"} columnDetailClassName={"pl-0 pr-0"}>
                    <DebounceInput
                        debounceTimeout={300}
                        className="input-fields"
                        value={requiredStaffCount}
                        onChange={changeRequiredStaffCount}
                        placeholder="Enter required staff count here."
                        type="number"
                        min={0}
                        step={1}
                        max={50}
                    />
                </FormRow>
                <FormActionContainer>
                    <div className="col d-flex justify-content-center">
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue="Delete"
                            colour={ButtonColourOptions.Red}
                            disabled={false}
                            onClick={toggle}
                            roundedCorner
                        />
                    </div>
                </FormActionContainer>
                <Modal
                    isShown={isShown}
                    onClose={toggle}
                    title={"Delete Group"}
                    modalSize={"sm"}
                    bodyChildren={
                        <div className="row ml-0 mr-0">
                            <p>Are you sure you want to delete this group?</p>
                        </div>
                    }
                    footerChildren={
                        <div className="row ml-0 mr-0">
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"Yes"}
                                onClick={deleteGrouping}
                                colour={ButtonColourOptions.Yellow}
                                roundedCorner
                            />
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"No"}
                                onClick={toggle}
                                colour={ButtonColourOptions.DarkBlue}
                                roundedCorner
                            />
                        </div>
                    }
                />
            </div>
        </React.Fragment>
    );
};

export default EditCalendarGrouping;

async function generateBlankAssignments(amount: number): Promise<StaffAssignment[]> {
    const assignments: StaffAssignment[] = [];
    for (let i = 0; i < amount; ++i) {
        assignments.push({
            id: nanoid(),
            state: AssignmentState.Unassigned
        });
    }
    return assignments;
}
