import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {createNewVenue} from "../../../store/venue/actions/VenueActions";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../Navigation/routeNames";
import {getCalendarPathForCalendarChildRoutes, getVenuesListPath} from "../VenueList/VenueList";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import EditVenueForm from "./Components/EditVenueForm";
import {WithServiceState} from "store-fetch-wrappers/dist";
import {RootStore} from "../../../store/Store";
import {useCalendarPageDetails} from "../../Hooks/useCalendarPageDetails";
import {getAttendanceOverviewPath} from "../AttendanceOverview/Helpers/attendanceOverviewHelpers";
import {getEventReportPath} from "../Reports/EventReport/Helpers/eventReportHelpers";
import {getXeroTrackingInfo} from "../../../store/xeroTracking/actions/XeroTrackingActions";

const ServiceWrapper = WithServiceState(EditVenueForm);

const AddVenue = () => {
    const dispatch = useDispatch();
    const {calendarId, calendarName} = useCalendarPageDetails();

    const venueStore = useSelector((state: RootStore) => state.venue);

    useEffect(() => {
        if (!calendarId) return;

        //Navigation items.
        const navItems: NavigationItem[] = [
            {
                name: routeNames.groupRosteringSystem.name,
                path: routeNames.groupRosteringSystem.path
            },
            {
                name: calendarName,
                path: getCalendarPathForCalendarChildRoutes(calendarName, +calendarId)
            },
            {
                name: routeNames.eventReport.name,
                path: getEventReportPath(calendarName, +calendarId)
            },
            {
                name: routeNames.venueList.name,
                path: getVenuesListPath(calendarName, +calendarId)
            },
            {
                name: routeNames.attendanceOverview.name,
                path: getAttendanceOverviewPath(calendarName, +calendarId)
            }
        ];

        dispatch(setNavigationItems(navItems));

        dispatch(createNewVenue(+calendarId));
        dispatch(getXeroTrackingInfo());
    }, []);

    return (
        <React.Fragment>
            <div className="mt-4 page-container">
                <ServiceWrapper
                    placeholderBlockCount={1}
                    loaderWheelType={"three-ring"}
                    loaderType={"overlay"}
                    showLoadingText={true}
                    {...venueStore}
                />
            </div>
        </React.Fragment>
    );
};

export default AddVenue;

/** Gets the full path of the Add Venue Route */
export function getAddVenuePath(calendarName: string, calendarId: number) {
    return `${routeNames.calendar.path}/${encodeURI(calendarName)}${
        routeNames.addVenue.path
    }?id=${calendarId}`;
}
