import {StoreServiceData} from "store-fetch-wrappers";
import {createStoreState} from "store-fetch-wrappers/dist";
import {Region} from "../../../../api/staff";

export const REGION_LIST_STORE = createStoreState("region_list");

interface RegionListLoading extends StoreServiceData<Region[]> {
    type: typeof REGION_LIST_STORE.LOADING;
}
interface RegionListError extends StoreServiceData<Region[]> {
    type: typeof REGION_LIST_STORE.ERROR;
}
interface RegionListSuccess extends StoreServiceData<Region[]> {
    type: typeof REGION_LIST_STORE.SUCCESS;
}

export type RegionListDispatchTypes = RegionListLoading | RegionListError | RegionListSuccess;
