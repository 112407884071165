import React, {useEffect, useState} from "react";
import {
    getDatesForCalendarEntrySorting,
    getDatesForIsoWeek,
    sortFrontlineEntries,
    WeekViewCalendarItems
} from "../Helpers/weekViewCalendarHelpers";
import {WeekViewCalendarProps} from "../WeekViewCalendar";
import {CalendarEventItem} from "../../../store/fullCalendarItemList/actions/FullCalendarItemListActionTypes";
import {AssignmentState, StaffAssignment} from "../../../api/grs";
import moment from "moment";
import {toArray} from "../../../utils/sortingUtils";

export function useWeekViewCalendar({currentDate, items}: WeekViewCalendarProps) {
    const [dates, setDates] = useState<number[]>([]);
    const [events, setEvents] = useState<WeekViewCalendarItems>({rows: []});

    useEffect(() => {
        setDates(getDatesForIsoWeek(currentDate));
        const epochTimes = getDatesForCalendarEntrySorting(currentDate);
        setEvents(sortFrontlineEntries(epochTimes, toArray(items)));
    }, [currentDate, items]);

    function getComplianceMarkup(index: number): React.ReactNode {
        const {isCompliant, eventQuantity} = isDayCompliant(index);

        if (eventQuantity === 0) {
            return <span className={`compliance-label`} />;
        }

        return (
            <span className={`compliance-label ${isCompliant ? "compliant" : "not-compliant"}`}>
                {isCompliant ? "Compliant" : "Non Compliant"}
            </span>
        );
    }

    /** Gets the compliance for the day. Will filter through events for the day, if assignments array > 0, Day is non-compliant */
    function isDayCompliant(index: number): DayCompliance {
        const eventsForDay = getEventsForDay(index);
        const isHistoric = isDayHistoric(index);

        if (isHistoric) {
            return {
                eventQuantity: eventsForDay.length,
                isCompliant: isHistoric
            };
        }

        return {
            eventQuantity: eventsForDay.length,
            isCompliant: filterNonCompliantAssignments(eventsForDay).length === 0
        };
    }

    function isDayHistoric(index: number) {
        const startOfCurrentDay = moment().startOf("day").unix();
        const targetDate = dates[index];

        return startOfCurrentDay > targetDate;
    }

    function filterNonCompliantAssignments(eventsForDay: CalendarEventItem[]) {
        const assignments = eventsForDay.map((item) => item.assignments).flat();
        return getNonCompliantAssignments(assignments);
    }

    function getNonCompliantAssignments(assignments: StaffAssignment[]) {
        return assignments.filter(
            (item) =>
                item.state === AssignmentState.Unassigned ||
                item.state === AssignmentState.WaitingAccept
        );
    }

    function getEventsForDay(index: number): CalendarEventItem[] {
        const eventsForDay: CalendarEventItem[] = [];

        for (const row of events.rows) {
            const day = row.entries[index];
            if (!day) continue;
            eventsForDay.push(day);
        }

        return eventsForDay;
    }

    /** Testing Purposes only **/
    function getComplianceMarkup_TEST(index: number, currentDay: number): React.ReactNode {
        const {isCompliant, eventQuantity} = isDayCompliant_TEST(index, currentDay);

        if (eventQuantity === 0) {
            return <span className={`compliance-label`} />;
        }

        return (
            <span className={`compliance-label ${isCompliant ? "compliant" : "not-compliant"}`}>
                {isCompliant ? "Compliant" : "Non Compliant"}
            </span>
        );
    }

    function isDayCompliant_TEST(index: number, currentDay: number): DayCompliance {
        const eventsForDay = getEventsForDay(index);
        const isHistoric = isDayHistoric_TEST(index, currentDay);

        if (isHistoric) {
            return {
                eventQuantity: eventsForDay.length,
                isCompliant: isHistoric
            };
        }

        return {
            eventQuantity: eventsForDay.length,
            isCompliant: filterNonCompliantAssignments(eventsForDay).length === 0
        };
    }

    function isDayHistoric_TEST(index: number, currentDay: number) {
        const startOfCurrentDay = moment.unix(currentDay).startOf("day").unix();
        const targetDate = dates[index];

        return startOfCurrentDay > targetDate;
    }

    return {
        dates,
        events,
        getComplianceMarkup,
        //Testing
        getComplianceMarkup_TEST,
        isDayCompliant_TEST,
        isDayHistoric_TEST
    };
}

interface DayCompliance {
    eventQuantity: number;
    isCompliant: boolean;
}
