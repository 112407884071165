import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {OVERLAPPING_ENTRY_LIST_STORE_STATE} from "../actions/OverlappingEntryListActionTypes";
import {CalendarEntry} from "../../../api/grs";
import {showErrorToast} from "../../../utils/toastUtils";

/** Default state of the store. */
const defaultState = createDefaultStoreState<CalendarEntry[]>([]);

/** Infer type of the reducer for code completion and quality */
const overlappingEntryListReducer = (
    state: StoreServiceData<CalendarEntry[]> = defaultState,
    action: ServiceActionTypes<CalendarEntry[]>
): StoreServiceData<CalendarEntry[]> =>
    createReducer(state, action, OVERLAPPING_ENTRY_LIST_STORE_STATE, () =>
        showErrorToast(action.error)
    );

export default overlappingEntryListReducer;
