/** Validates the staff assignment */
import {CalendarEntry, StaffAssignment, StaffLink} from "../../../../../../../../../api/grs";
import {FormErrors, StaffAssignmentFormError} from "../../../../../../../../../utils/formUtils";
import {EMAIL_REGEX} from "../../../../../../../../../utils/regexUtils";
import store from "../../../../../../../../../store/Store";
import {getVenueFromVenueList} from "../../../../../../../../../store/venue/actions/VenueActions";

export function validateStaffAssignment(assignment: StaffAssignment): FormErrors {
    const messages: string[] = [];
    let valid = true;

    if (!assignment.staffMember) {
        messages.push("Staff member must be selected");
        valid = false;
    }

    if (assignment.staffMember?.staffId.length === 0) {
        messages.push("Staff member must be selected");
        valid = false;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (assignment.rate < 0) {
        messages.push("Rate cannot be less than 0");
        valid = false;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (assignment.rate === 0) {
        messages.push("Rate cannot be 0");
        valid = false;
    }

    if (assignment.staffMember) {
        if (assignment.staffMember.staffId.length === 0) {
            messages.push("Staff member cannot be blank");
            valid = false;
        }
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (assignment.startDate >= 0 && assignment.endDate >= 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (assignment.endDate < assignment.startDate) {
            messages.push("End time can't be earlier than the start time");
            valid = false;
        }

        if (assignment.startDate === 0) {
            messages.push("Pick a start time");
            valid = false;
        }

        if (assignment.endDate === 0) {
            messages.push("Pick an end time");
            valid = false;
        }
    }
    return {
        messages,
        valid
    };
}

export function validateExternalAssignment(assignment: StaffAssignment): FormErrors {
    const messages: string[] = [];
    const {staffMember} = assignment;
    let valid = true;
    if (!staffMember) {
        messages.push("Staff member must be selected");
        valid = false;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (assignment.rate < 0) {
        messages.push("Rate cannot be less than 0");
        valid = false;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (assignment.rate === 0) {
        messages.push("Rate cannot be 0");
        valid = false;
    }

    if (staffMember && staffMember.external) {
        if (!EMAIL_REGEX.test(staffMember.staffId)) {
            messages.push("Email for external staff member is not valid");
            valid = false;
        }

        if (staffMember.staffName.length < 3) {
            messages.push("External staff name is not valid");
            valid = false;
        }
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (assignment.startDate >= 0 && assignment.endDate >= 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (assignment.endDate < assignment.startDate) {
            messages.push("End time can't be earlier than the start time");
            valid = false;
        }

        if (assignment.startDate === 0) {
            messages.push("Pick a start time");
            valid = false;
        }

        if (assignment.endDate === 0) {
            messages.push("Pick an end time");
            valid = false;
        }
    }
    return {
        messages,
        valid
    };
}

/** Checks the list of overlapping entries */

export async function checkIfUserHasOverlappingEntries(
    overlappingEntries: CalendarEntry[],
    staff: StaffLink,
    currentEntry: CalendarEntry
): Promise<StaffAssignmentFormError> {
    if (overlappingEntries.length === 0) {
        return {
            valid: true,
            message: "",
            canBeAssigned: true
        };
    }

    /** Venues should be loaded here, having to null check because of typescript */
    const venues = store.getState().venueList.data;
    if (!venues)
        return {
            valid: true,
            message: "",
            canBeAssigned: true
        };

    const startDate = currentEntry.startDate;
    const endDate = currentEntry.endDate;

    // Iterate through all entries, if any do overlap, return true and emit error to error store.
    for (const entry of overlappingEntries) {
        const venueForEntry = getVenueFromVenueList(entry.venueId, venues);
        if (endDate > entry.startDate && endDate < entry.endDate) {
            return {
                valid: false,
                message: `${staff.staffName} cannot be assigned here as their assigment times for this event overlaps times at ${venueForEntry.name}`,
                canBeAssigned: false
            };
        }
        if (startDate > entry.startDate && startDate < entry.endDate) {
            return {
                valid: false,
                message: `${staff.staffName} cannot be assigned here as their assigment times for this event overlaps times at ${venueForEntry.name}`,
                canBeAssigned: false
            };
        }
    }

    return {
        valid: false,
        message: `${staff.staffName} is assigned to ${overlappingEntries.length} events for this day. Do you want to assign this user?`,
        canBeAssigned: true
    };
}
