import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {
    HORIZONTAL_CALENDAR_STORE,
    HorizontalCalendarState
} from "../actions/HorizontalCalendarActionTypes";

const defaultState = createDefaultStoreState<HorizontalCalendarState>(null);

const horizontalCalendarReducer = (
    state: StoreServiceData<HorizontalCalendarState> = defaultState,
    action: ServiceActionTypes<HorizontalCalendarState>
) => createReducer(state, action, HORIZONTAL_CALENDAR_STORE);

export default horizontalCalendarReducer;
