import React, {useEffect, useState} from "react";
import {CalendarEntry, EventCalendarEntry, FrontlineCalendarEntry} from "../../../../api/grs";
import {PulseTableWithLocalPagination} from "pulse_table";
import NoItemsMessage from "../../../Table/NoItemsMessage";
import {toArray} from "../../../../utils/sortingUtils";
import {formatUnixToddddDoMMMMYYYY} from "../../../../utils/momentUtils";
import {pagedRequestConfig} from "../../../Filters/helpers/filterHelpers";
import {DebounceInput} from "react-debounce-input";
import {useDispatch} from "react-redux";
import {nullifyCalendarEntriesListStore} from "../../../../store/calendarEntryList/actions/CalendarEntryListActions";
import TableItemAction from "../../../Table/TableItemAction";
import {IconType} from "../../../Icon/Icon";
import {useHistory} from "react-router-dom";
import {useQuery} from "../../../Hooks/useQuery";
import {useCalendarPageDetails} from "../../../Hooks/useCalendarPageDetails";
import {getAttendanceRegisterPath} from "../../AttendanceRegister/Helpers/attendanceRegisterHelpers";

const AttendanceOverviewTable = (props: EventCalendarEntry[] | FrontlineCalendarEntry[]) => {
    const [searchString, setSearchString] = useState<string>("");
    const [items, setItems] = useState<TableRow[]>([]);
    const history = useHistory();
    const query = useQuery();
    const {calendarId, calendarName} = useCalendarPageDetails();

    const dispatch = useDispatch();
    useEffect(() => {
        return function () {
            dispatch(nullifyCalendarEntriesListStore());
        };
    }, []);

    useEffect(() => {
        const filtered = toArray(props).filter((entry: CalendarEntry) => {
            const desc = (entry.description ? entry.description : "").toLowerCase();
            return desc.includes(searchString.toLowerCase());
        });

        const filteredResults = searchString.length > 0 ? filtered : toArray(props);

        setItems(toTableRow(filteredResults));
    }, [searchString, props]);

    const viewRegister = (entryId: number) => {
        const month = query.get("month");
        if (!calendarId) return;
        const path = getAttendanceRegisterPath(
            calendarName,
            +calendarId,
            entryId,
            month ? +month : undefined
        );
        history.push(path);
    };

    return (
        <React.Fragment>
            {items.length > 0 && (
                <div className="row ml-0 mr-0 mt-5 pt-3 mb-2">
                    <div className="col pr-0 d-flex justify-content-end">
                        <DebounceInput
                            debounceTimeout={300}
                            className={"input-fields width-250px"}
                            inputType={"input"}
                            onChange={(event) => setSearchString(event.target.value)}
                            disabled={false}
                            value={searchString}
                            placeholder="Search register..."
                        />
                    </div>
                </div>
            )}
            <PulseTableWithLocalPagination
                items={items}
                headers={{
                    entryName: "Entry Name",
                    date: "Date",
                    actions: "Actions"
                }}
                customRenderers={{
                    actions: (item: TableRow) => (
                        <React.Fragment>
                            <TableItemAction
                                tooltipText={"View Register"}
                                onClick={() => viewRegister(item.actions)}
                                icon={IconType.Eye}
                            />
                        </React.Fragment>
                    )
                }}
                noItemsSection={
                    <NoItemsMessage message={"There are no events happening this month"} />
                }
                resultsPerPage={pagedRequestConfig.resultsPerPage}
            />
        </React.Fragment>
    );
};

export default AttendanceOverviewTable;

interface TableRow {
    entryName: string;
    date: string;
    actions: number;
}

function toTableRow(entries: EventCalendarEntry[] | FrontlineCalendarEntry[]): TableRow[] {
    return entries.map((item) => {
        return {
            entryName: item.description || "New Calendar Entry",
            date: formatUnixToddddDoMMMMYYYY(item.startDate),
            actions: item.id
        };
    });
}
