import React from "react";
import {useDispatch} from "react-redux";
import {
    saveGlobalStaffBlockToService,
    setGlobalStaffBlock
} from "../../../../../store/globalStaffBlock/actions/GlobalStaffBlockActions";
import StaffBlockManagement from "./StaffBlock/StaffBlockManagement";
import {StaffBlock, StaffBlockTemplate} from "../../../../../api/grs";
import {ItemChangedAction} from "./StaffBlock/StaffSection";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../../../Navigation/routeNames";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../Button/MCButton";
import {showErrorToast, showSuccessToast} from "../../../../../utils/toastUtils";
import FormHeader from "../../../../Form/FormHeader";
import FormRow from "../../../../Form/FormRow";
import {DebounceInput} from "react-debounce-input";

const EditGlobalStaffBlockForm = (props: StaffBlockTemplate) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const onStaffBlockChanged = (item: ItemChangedAction<StaffBlock>) => {
        dispatch(
            setGlobalStaffBlock({
                ...props,
                staffBlock: item.newItem
            })
        );
    };

    const saveStaffBlock = async () => {
        const isValid = isStaffBlockValid(props);

        if (!isValid) {
            return;
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(saveGlobalStaffBlockToService(props));

        if (!success) {
            return;
        }
        showSuccessToast(`Saved ${props.name} staff block`);
        backToStaffBlockList();
    };

    const backToStaffBlockList = () => {
        history.push(routeNames.manageGlobalStaffBlock.path);
    };
    return (
        <React.Fragment>
            <div className="pt-3 pb-3">
                <FormHeader headerName={"Staff Block Form"} />
                <FormRow rowName={"Staff Block Name"} columnDetailClassName={"pl-0 pr-0"}>
                    <DebounceInput
                        debounceTimeout={300}
                        value={props.name}
                        className="input-fields"
                        onChange={(event) => {
                            dispatch(
                                setGlobalStaffBlock({
                                    ...props,
                                    name: event.target.value
                                })
                            );
                        }}
                        placeholder="Enter staff block name here..."
                    />
                </FormRow>
                <FormRow rowName={"Staff Block"} columnDetailClassName={"pl-0 pr-0"}>
                    <StaffBlockManagement
                        item={props.staffBlock}
                        onItemChanged={onStaffBlockChanged}
                    />
                </FormRow>
                <div className="row mt-3">
                    <div className="col">
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Save"}
                            onClick={saveStaffBlock}
                            colour={ButtonColourOptions.Yellow}
                            roundedCorner
                        />
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Cancel"}
                            onClick={backToStaffBlockList}
                            colour={ButtonColourOptions.DarkBlue}
                            roundedCorner
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default EditGlobalStaffBlockForm;

function isStaffBlockValid(block: StaffBlockTemplate): boolean {
    let valid = true;

    //Check to see if the calendar name is invalid
    if (block.name.length < 3) {
        valid = false;
        showErrorToast("Staff block name must contain 3 or more characters.");
    }
    //Check for if any section names are invalid
    for (const section of block.staffBlock.sections) {
        if (section.name.length < 3) {
            valid = false;
            showErrorToast(
                "One or more sections have invalid names. Section names must contain 3 or more characters."
            );
            break;
        }
    }

    //Check for if any group names in a section are invalid
    for (const section of block.staffBlock.sections) {
        for (const group of section.groupings) {
            if (group.name.length < 3) {
                valid = false;
                showErrorToast(
                    `A group in the section '${section.name}' is invalid. Group names must contain 3 or more characters.`
                );
                break;
            }
        }
    }

    return valid;
}
