import {Dispatch} from "redux";

import {
    CalendarToRender,
    GLOBAL_CALENDAR_STORE,
    GlobalCalendarDispatchTypes
} from "./GlobalCalendarActionTypes";
import {
    CalendarEntryListRequest,
    CalendarEntryListResponse,
    CalendarTypeEnum
} from "../../../api/grs";
import {getDataFromServiceWithData} from "store-fetch-wrappers";
import GrsApiModel from "../../apiModel/GrsApiModel";
import {statusCodeCallback} from "../../helpers/storeHelpers";
import {
    CalendarEventItem,
    FULL_CALENDAR_LIST_ITEM_STORE
} from "../../fullCalendarItemList/actions/FullCalendarItemListActionTypes";
import {RootStore} from "../../Store";
import {convertEntriesListToCalendarEventItem} from "../../fullCalendarItemList/actions/FullCalendarItemListActions";
import {StaffAccessLevel, UserData} from "../../../api/staff";
import {getEmptyUser} from "../../../components/Hooks/useUserData";
import {defaultGlobalCalendarStore} from "../reducer/GlobalCalendarReducer";

export function nullifyGlobalCalendarStore() {
    return async (dispatch: Dispatch<GlobalCalendarDispatchTypes>) => {
        dispatch({
            type: GLOBAL_CALENDAR_STORE.SUCCESS,
            error: null,
            loading: false,
            data: defaultGlobalCalendarStore()
        });
    };
}

export function getShiftsForGlobalCalendar(args: CalendarEntryListRequest) {
    return async function (
        dispatch: Dispatch<GlobalCalendarDispatchTypes>,
        state: () => RootStore
    ) {
        try {
            const resp = await getDataFromServiceWithData(
                GLOBAL_CALENDAR_STORE,
                dispatch,
                () => GrsApiModel.getCalendarApi().listCalendarEntriesWithCalendars(args),
                statusCodeCallback
            );

            /** No entries, we just update the store with an empty array */
            if (!resp) {
                dispatch({
                    type: FULL_CALENDAR_LIST_ITEM_STORE.SUCCESS,
                    loading: false,
                    error: null,
                    data: null
                });
                return;
            }

            const results = await convertEntriesListToCalendarEventItem(resp.entries);
            const user = state().auth.data || getEmptyUser();

            dispatch({
                type: GLOBAL_CALENDAR_STORE.SUCCESS,
                loading: false,
                error: null,
                data: {
                    entryListResponse: resp,
                    results: sortEntriesForGlobalCalendar(results, user, resp),
                    calendarToShow: calendarToRender(resp)
                }
            });
        } catch (e: any) {
            dispatch({
                type: GLOBAL_CALENDAR_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
}

function sortEntriesForGlobalCalendar(
    results: CalendarEventItem[],
    user: UserData,
    resp: CalendarEntryListResponse
): CalendarEventItem[] {
    if (user.username.length === 0) return [];

    switch (user.accessLevel) {
        case StaffAccessLevel.SystemAdministrator:
            return filterEventsForSystemAdmin(resp, results);
        default:
            return results;
    }
}

function filterEventsForSystemAdmin(
    resp: CalendarEntryListResponse,
    results: CalendarEventItem[]
): CalendarEventItem[] {
    //Default only show EventCalendar Events if no calendars are present
    if (resp.calendarIds && resp.calendarIds.length === 0) {
        return results.filter((it) => it.calendarType === CalendarTypeEnum.Event);
    }

    if (calendarToRender(resp) === CalendarToRender.Frontline) {
        return results.filter((it) => it.calendarType === CalendarTypeEnum.Frontline);
    }

    return results;
}

export function calendarToRender(resp: CalendarEntryListResponse): CalendarToRender {
    if (resp.calendarIds && resp.calendarIds.length === 0) return CalendarToRender.Event;

    for (const calendar of resp.calendars) {
        if (calendar.calendarType === CalendarTypeEnum.Frontline) {
            return CalendarToRender.Frontline;
        }
    }

    return CalendarToRender.Event;
}
