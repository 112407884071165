import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {STAFF_INTEREST_QUERY_RESPONSE_STORE} from "../actions/StaffInterestQueryResponseActionTypes";
import {StaffInterestQueryResponse} from "../../../api/grs";
import {showErrorToast} from "../../../utils/toastUtils";

/** Default state of the store. */
const defaultState = createDefaultStoreState<StaffInterestQueryResponse>(
    createBlankStaffInterestQueryResponse()
);

/** Infer type of the reducer for code completion and quality */
const staffInterestQueryResponseReducer = (
    state: StoreServiceData<StaffInterestQueryResponse> = defaultState,
    action: ServiceActionTypes<StaffInterestQueryResponse>
): StoreServiceData<StaffInterestQueryResponse> =>
    createReducer(state, action, STAFF_INTEREST_QUERY_RESPONSE_STORE, () =>
        showErrorToast(action.error)
    );

export default staffInterestQueryResponseReducer;

export function createBlankStaffInterestQueryResponse(): StaffInterestQueryResponse {
    return {
        entries: [],
        interestList: []
    };
}
