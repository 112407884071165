import React from "react";
import {Venue, VenueStateEnum} from "../../../../api/grs";
import FormHeader from "../../../Form/FormHeader";
import FormRow from "../../../Form/FormRow";
import {DebounceInput} from "react-debounce-input";
import GenericTypeDropdown from "../../../Dropdown/Components/GenericTypeDropdown";
import {getVenueStateFromString} from "../../../../utils/enumUtils";
import FormActionContainer from "../../../Form/FormActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import {useEditVenueForm} from "./Hooks/useEditVenueForm";
import {WithServiceState} from "store-fetch-wrappers/dist";
import XeroTrackingCategoryListContainer from "./XeroTrackingCategoryListContainer";
import RegionDropdown from "../../../Dropdown/Components/RegionDropdown";
const ServiceWrapper = WithServiceState(XeroTrackingCategoryListContainer);

const EditVenueForm = (props: Venue) => {
    const {saveVenue, backToVenueList, updateVenue, xeroTrackingStore} = useEditVenueForm(props);
    return (
        <React.Fragment>
            <FormHeader headerName={"Venue Form"} />
            <FormRow rowName={"Venue Name"} columnDetailClassName={"pl-0 pr-0"}>
                <DebounceInput
                    debounceTimeout={300}
                    value={props.name}
                    className="input-fields"
                    onChange={(event) => {
                        updateVenue({
                            ...props,
                            name: event.target.value
                        });
                    }}
                    placeholder="Enter venue name here..."
                />
            </FormRow>
            <FormRow rowName={"Venue State"} columnDetailClassName={"pl-0 pr-0"}>
                <GenericTypeDropdown
                    enumOptions={VenueStateEnum}
                    splitByCapitalLetter={false}
                    clearable={false}
                    searchable={false}
                    changeOption={(item) => {
                        if (!item) return;

                        updateVenue({
                            ...props,
                            state: getVenueStateFromString(item.toString())
                        });
                    }}
                    id={props.state}
                    disabled={false}
                />
            </FormRow>
            <FormRow rowName={"Address"} columnDetailClassName={"pl-0 pr-0"}>
                <DebounceInput
                    debounceTimeout={300}
                    value={props.address}
                    element={"textarea"}
                    className="input-fields text-area-inputs"
                    onChange={(event) => {
                        updateVenue({
                            ...props,
                            address: event.target.value
                        });
                    }}
                    placeholder="Enter address here..."
                />
            </FormRow>
            <FormRow rowName={"Postcode"} columnDetailClassName={"pl-0 pr-0"}>
                <DebounceInput
                    debounceTimeout={300}
                    value={props.postcode}
                    className="input-fields"
                    onChange={(event) => {
                        updateVenue({
                            ...props,
                            postcode: event.target.value
                        });
                    }}
                    placeholder="Enter postcode here..."
                />
            </FormRow>

            <FormRow rowName={"Region"} columnDetailClassName={"pl-0 pr-0"}>
                <RegionDropdown
                    regionId={props.regionId}
                    onChange={(region) => {
                        updateVenue({
                            ...props,
                            regionId: region?.id
                        });
                    }}
                    clearable={true}
                />
            </FormRow>

            <ServiceWrapper
                loaderWheelType={"three-ring"}
                loaderType={"overlay"}
                showLoadingText={true}
                {...xeroTrackingStore}
                data={{
                    ...xeroTrackingStore.data,
                    venue: props,
                    updateVenue
                }}
            />
            <FormActionContainer>
                <MCButton
                    size={ButtonSize.Large}
                    innerValue={"Save"}
                    onClick={saveVenue}
                    colour={ButtonColourOptions.Yellow}
                    roundedCorner
                />
                <MCButton
                    size={ButtonSize.Large}
                    innerValue={"Cancel"}
                    onClick={backToVenueList}
                    colour={ButtonColourOptions.DarkBlue}
                    roundedCorner
                />
            </FormActionContainer>
        </React.Fragment>
    );
};

export default EditVenueForm;
