import React from "react";
import {Switch, Redirect} from "react-router-dom";

/** Auth Pages */
import Login from "../../Pages/Auth/Login/Login";
import LoggedIn from "../../Pages/Auth/Loggedin/LoggedIn";
import NotFound from "../../Pages/Auth/NotFound/NotFound";
import NotAuthorised from "../../Pages/Auth/NotAuthorised/NotAuthorised";
import InActive from "../../Pages/Auth/InActive/InActive";
import Logout from "../../Pages/Auth/Logout/Logout";

import {routeNames} from "../routeNames";
import {GuardProvider} from "react-router-guards";
import {StaffAccessLevel} from "../../../api/staff";
import store from "../../../store/Store";
import {LoadingWheel, Error} from "react-state-helpers";

/** Route specific items */
import CustomGuardedRoute from "./CustomGuardedRoute";
import MedicareLayout from "../../Pages/Layouts/Layout/MedicareLayout";
import NoNavLayout from "../../Pages/Layouts/Layout/NoNavLayout";
import GroupRosteringSystem from "../../Pages/GroupRosteringSystem/GroupRosteringSystem";
import ManageStaffBlocks from "../../Pages/GlobalStaffBlock/Manage/ManageStaffBlocks";
import AddGlobalStaffBlock from "../../Pages/GlobalStaffBlock/AddEdit/AddGlobalStaffBlock";
import EditGlobalStaffBlock from "../../Pages/GlobalStaffBlock/AddEdit/EditGlobalStaffBlock";
import WageReport from "../../Pages/Reports/WageReport/WageReport";
import AddCalendar from "../../Pages/CalendarForm/AddCalendar";
import EditCalendar from "../../Pages/CalendarForm/EditCalendar";
import DutyManagerReportPaged from "../../Pages/Reports/DutyManager/DutyManagerReportPaged/DutyManagerReportPaged";
import StaffReport from "../../Pages/Reports/StaffReport/StaffReport";
import PendingNotifications from "../../Pages/Notifications/PendingNotifications";
import AvailableShiftsList from "../../Pages/AvailableShiftsList/AvailableShiftsList";
import AvailableShiftsCalendar from "../../Pages/AvailableShifts/Calendar/AvailableShiftsCalendar";
import AdminCalendar from "../../Pages/AdminCalendar/AdminCalendar";
import VenueList from "../../Pages/VenueList/VenueList";
import AddVenue from "../../Pages/Venue/AddVenue";
import EditVenue from "../../Pages/Venue/EditVenue";
import AttendanceOverview from "../../Pages/AttendanceOverview/AttendanceOverview";
import AttendanceRegister from "../../Pages/AttendanceRegister/AttendanceRegister";
import EventReport from "../../Pages/Reports/EventReport/EventReport";
import AddCalendarEntry from "../../Pages/AdminCalendarEntry/AddCalendarEntry";
import EditCalendarEntry from "../../Pages/AdminCalendarEntry/EditCalendarEntry";
import GlobalCalendar from "../../Pages/GlobalCalendar/GlobalCalendar";
import ReadOnlyCalendarEntry from "../../Pages/ReadOnlyCalendarEntry/ReadOnlyCalendarEntry";
import CreateDutyManagerReport from "../../Pages/Reports/DutyManager/DutyManagerReport/CreateDutyManagerReport";
import ViewDutyManagerReport from "../../Pages/Reports/DutyManager/DutyManagerReport/ViewDutyManagerReport";
import EditDutyManagerReport from "../../Pages/Reports/DutyManager/DutyManagerReport/EditDutyManagerReport";
import ConfirmAttendance from "../../Pages/Attendance/ConfirmAttendance";
import ConfirmBriefing from "../../Pages/Attendance/ConfirmBriefing";
import ManageCalendarGroups from "../../Pages/CalendarGroups/Manage/ManageCalendarGroups";
import EditCalendarGroups from "../../Pages/CalendarGroups/AddEdit/EditCalendarGroups";
import AddCalendarGroups from "../../Pages/CalendarGroups/AddEdit/AddCalendarGroups";
import StaffRota from "../../Pages/StaffRota/StaffRota";
import XeroLogin from "../../Pages/Xero/Login/XeroLogin";
import XeroCallback from "../../Pages/Xero/Callback/XeroCallback";
import XeroInvoices from "../../Pages/Xero/Invoices/XeroInvoices";
import {getConfig} from "../../../McConfigPlugin";
import {getUserAccessLevel} from "../../../utils/userDataUtils";

const requireLogin = async (to: any, from: any, next: any) => {
    const accessLevels = to.meta.auth?.accessLevels as Array<StaffAccessLevel>;
    //No auth required, carry on
    if (!accessLevels) {
        next();
        return;
    }

    const prevRoutesBlackList = [
        routeNames.login.path,
        routeNames.loggedin.path,
        routeNames.notFound.path,
        routeNames.notFound.path,
        routeNames.inActive.path,
        routeNames.logout.path
    ];

    const idx = prevRoutesBlackList.findIndex((route: string) => route === from.location.pathname);
    const fromPath = combinePathnameAndSearch(from.location.pathname, from.location.search);
    const toPath = combinePathnameAndSearch(to.location.pathname, to.location.search);

    // if (toPath.includes(routeNames.xeroCallback.path)) debugger;
    if (toPath.includes(routeNames.loggedin.path)) debugger;

    if (idx < 0) {
        if (fromPath != toPath) {
            localStorage.setItem("previous_route", fromPath);
        }
        localStorage.setItem("redirect_uri", toPath);
    }

    localStorage.setItem("current_route", toPath);

    const authenticated = store.getState().auth.authenticated;
    const user = store.getState().auth.data;

    if (!authenticated) {
        next.redirect(routeNames.login.path);
        return;
    }

    if (user) {
        const config = await getConfig();

        const userAccessLevel = getUserAccessLevel(user, config);

        const index = accessLevels.findIndex((x) => x === userAccessLevel);
        if (index < 0) {
            next.redirect(routeNames.notAuthorised.path);
            return;
        }
    }

    next();
};

const Routes = () => {
    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <GuardProvider guards={[requireLogin]} loading={LoadingWheel} error={Error}>
            <Switch>
                <CustomGuardedRoute
                    exact
                    path={routeNames.login.path}
                    layout={NoNavLayout}
                    component={Login}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.loggedin.path}
                    layout={NoNavLayout}
                    component={LoggedIn}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.notAuthorised.path}
                    layout={MedicareLayout}
                    component={NotAuthorised}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.inActive.path}
                    layout={NoNavLayout}
                    component={InActive}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.logout.path}
                    layout={NoNavLayout}
                    component={Logout}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.xeroLogin.path}
                    component={XeroLogin}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.xeroCallback.path}
                    component={XeroCallback}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.xeroInvoices.path}
                    component={XeroInvoices}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.groupRosteringSystem.path}
                    component={GroupRosteringSystem}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.addCalendar.path}
                    component={AddCalendar}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={`${routeNames.editCalendar.path}/:calendarName`}
                    component={EditCalendar}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.staffReport.path}
                    component={StaffReport}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [
                                StaffAccessLevel.SystemAdministrator,
                                StaffAccessLevel.DutyManager,
                                StaffAccessLevel.Staff
                            ]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.wageReport.path}
                    component={WageReport}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.staffRota.path}
                    component={StaffRota}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={`${routeNames.calendar.path}/:calendarName`}
                    component={AdminCalendar}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={`${routeNames.globalCalendar.path}/:calendarName`}
                    component={GlobalCalendar}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [
                                StaffAccessLevel.SystemAdministrator,
                                StaffAccessLevel.DutyManager,
                                StaffAccessLevel.Staff
                            ]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={`${routeNames.calendar.path}/:calendarName${routeNames.eventReport.path}`}
                    component={EventReport}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={`${routeNames.calendar.path}/:calendarName${routeNames.addVenue.path}`}
                    component={AddVenue}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={`${routeNames.calendar.path}/:calendarName${routeNames.editVenues.path}`}
                    component={EditVenue}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={`${routeNames.calendar.path}/:calendarName${routeNames.venueList.path}`}
                    component={VenueList}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={`${routeNames.calendar.path}/:calendarName${routeNames.addCalendarEntry.path}`}
                    component={AddCalendarEntry}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={`${routeNames.calendar.path}/:calendarName${routeNames.editCalendarEntry.path}/:id`}
                    component={EditCalendarEntry}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={`${routeNames.globalCalendar.path}/:calendarName${routeNames.readOnlyEntry.path}/:id`}
                    component={ReadOnlyCalendarEntry}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [
                                StaffAccessLevel.SystemAdministrator,
                                StaffAccessLevel.DutyManager
                            ]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={`${routeNames.calendar.path}/:calendarName${routeNames.attendanceOverview.path}`}
                    component={AttendanceOverview}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={`${routeNames.calendar.path}/:calendarName${routeNames.attendanceRegister.path}`}
                    component={AttendanceRegister}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={`${routeNames.dutyManagerReportList.path}`}
                    component={DutyManagerReportPaged}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={`${routeNames.createDutyManagerReport.path}`}
                    component={CreateDutyManagerReport}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [
                                StaffAccessLevel.SystemAdministrator,
                                StaffAccessLevel.DutyManager
                            ]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={`${routeNames.editDutyManagerReport.path}/:reportId`}
                    component={EditDutyManagerReport}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={`${routeNames.viewDutyManagerReport.path}/:reportId`}
                    component={ViewDutyManagerReport}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [
                                StaffAccessLevel.SystemAdministrator,
                                StaffAccessLevel.DutyManager
                            ]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={`${routeNames.availableShifts.path}`}
                    component={AvailableShiftsCalendar}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [
                                StaffAccessLevel.SystemAdministrator,
                                StaffAccessLevel.DutyManager,
                                StaffAccessLevel.Staff
                            ]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={`${routeNames.availableShiftsList.path}`}
                    component={AvailableShiftsList}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [
                                StaffAccessLevel.SystemAdministrator,
                                StaffAccessLevel.DutyManager,
                                StaffAccessLevel.Staff
                            ]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={`${routeNames.manageGlobalStaffBlock.path}`}
                    component={ManageStaffBlocks}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={`${routeNames.addGlobalStaffBlock.path}`}
                    component={AddGlobalStaffBlock}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={`${routeNames.editGlobalStaffBlock.path}/:staffBlockId`}
                    component={EditGlobalStaffBlock}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={`${routeNames.pendingNotifications.path}`}
                    component={PendingNotifications}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [
                                StaffAccessLevel.SystemAdministrator,
                                StaffAccessLevel.DutyManager,
                                StaffAccessLevel.Staff
                            ]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={`${routeNames.calendar.path}/:calendarName${routeNames.confirmAttendance.path}`}
                    component={ConfirmAttendance}
                    layout={NoNavLayout}
                    meta={{
                        auth: {
                            accessLevels: [
                                StaffAccessLevel.SystemAdministrator,
                                StaffAccessLevel.DutyManager,
                                StaffAccessLevel.Staff
                            ]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={`${routeNames.calendar.path}/:calendarName${routeNames.acceptBriefing.path}`}
                    component={ConfirmBriefing}
                    layout={NoNavLayout}
                    meta={{
                        auth: {
                            accessLevels: [
                                StaffAccessLevel.SystemAdministrator,
                                StaffAccessLevel.DutyManager,
                                StaffAccessLevel.Staff
                            ]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={`${routeNames.manageCalendarGroups.path}`}
                    component={ManageCalendarGroups}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={`${routeNames.editCalendarGroups.path}/:id`}
                    component={EditCalendarGroups}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={`${routeNames.addCalendarGroups.path}`}
                    component={AddCalendarGroups}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.notFound.path}
                    layout={MedicareLayout}
                    component={NotFound}
                />
                <CustomGuardedRoute
                    path="/"
                    render={(): JSX.Element => <Redirect to={routeNames.login.path} />}
                />

                <CustomGuardedRoute
                    render={(): JSX.Element => <Redirect to={routeNames.notFound.path} />}
                />
            </Switch>
        </GuardProvider>
    );
};

export default Routes;

/** Combines path and search string together */
export function combinePathnameAndSearch(pathname: string, search: string): string {
    return `${pathname}${search}`;
}
