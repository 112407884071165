import {Dispatch} from "redux";
import {REPEAT_ENTRY_STORE, RepeatEntryDispatchTypes} from "./RepeatEntryActionTypes";
import {RepeatEntryRequest} from "../../../api/grs";
import {postDataToServiceWithRedux} from "store-fetch-wrappers";
import GrsApiModel from "../../apiModel/GrsApiModel";
import {statusCodeCallback} from "../../helpers/storeHelpers";

export const nullifyRepeatActionsStore = () => {
    return async (dispatch: Dispatch<RepeatEntryDispatchTypes>) => {
        dispatch({
            type: REPEAT_ENTRY_STORE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

export const createRepetitiveEntries = (request: RepeatEntryRequest) => {
    return async (dispatch: Dispatch<RepeatEntryDispatchTypes>) => {
        try {
            return await postDataToServiceWithRedux(
                REPEAT_ENTRY_STORE,
                dispatch,
                () => GrsApiModel.getCalendarApi().repeatCalendarEntry(request),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: REPEAT_ENTRY_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
