import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {Venue} from "../../../api/grs";

export const VENUE_STORE_STATE = createStoreState("venue");

interface VenueStoreLoading extends StoreServiceData<Venue> {
    type: typeof VENUE_STORE_STATE.LOADING;
}
interface VenueStoreError extends StoreServiceData<Venue> {
    type: typeof VENUE_STORE_STATE.ERROR;
}
interface VenueStoreSuccess extends StoreServiceData<Venue> {
    type: typeof VENUE_STORE_STATE.SUCCESS;
}

export type VenueDispatchTypes = VenueStoreLoading | VenueStoreError | VenueStoreSuccess;
