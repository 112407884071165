import {BillingType, StaffWageReportEntry} from "../../../../../api/grs";

export function sortWageReportsByDateAscending(
    entries: StaffWageReportEntry[]
): StaffWageReportEntry[] {
    return entries.sort((a: StaffWageReportEntry, b: StaffWageReportEntry) => {
        if (a.startDate > b.startDate) {
            return 1;
        }

        if (a.startDate < b.startDate) {
            return -1;
        }

        return 0;
    });
}

export function sortWageReportsByDateDescending(
    entries: StaffWageReportEntry[]
): StaffWageReportEntry[] {
    return entries.sort((a: StaffWageReportEntry, b: StaffWageReportEntry) => {
        if (a.startDate < b.startDate) {
            return 1;
        }

        if (a.startDate > b.startDate) {
            return -1;
        }

        return 0;
    });
}

/** Based on calendar billing type, will return the correct value. */
export function getWageForWageReport(report: StaffWageReportEntry) {
    switch (report.billingType) {
        case BillingType.Daily:
            // eslint-disable-next-line no-case-declarations
            let dailyCost = 0;
            // eslint-disable-next-line no-case-declarations
            for (const staff of report.staff) {
                dailyCost += staff.wage;
            }
            return dailyCost;
        case BillingType.Hourly:
            // eslint-disable-next-line no-case-declarations
            let hourlyCost = 0;
            // eslint-disable-next-line no-case-declarations
            for (const staff of report.staff) {
                const wage = staff.assignmentRate * staff.numHours;

                hourlyCost += wage;
            }
            return hourlyCost;
    }

    return 0;
}
