import {Dispatch} from "redux";
import {
    CALENDAR_SUMMARY_STATS_STORE,
    CalendarSummaryStatsDispatchTypes
} from "./CalendarSummaryStatsActionTypes";
import GrsApiModel from "../../apiModel/GrsApiModel";
import {getDataFromServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";

/** Nukes store of stale date */
export const nullifyCalendarSummaryStatsStore = () => {
    return async (dispatch: Dispatch<CalendarSummaryStatsDispatchTypes>) => {
        dispatch({
            type: CALENDAR_SUMMARY_STATS_STORE.SUCCESS,
            loading: false,
            error: null,
            data: null
        });
    };
};

/** Fetches calendar Summary stats */
export const fetchCalendarSummaryStats = () => {
    return async (dispatch: Dispatch<CalendarSummaryStatsDispatchTypes>) => {
        try {
            return await getDataFromServiceWithRedux(
                CALENDAR_SUMMARY_STATS_STORE,
                dispatch,
                () => GrsApiModel.getCalendarApi().listCalendarSummaryStats(),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: CALENDAR_SUMMARY_STATS_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
