import React from "react";
import {AssignmentState, CalendarEventSummaryStats} from "../../../../../api/grs";
import {formatUnixToDoMMMMYYYY} from "../../../../../utils/momentUtils";
import {ChartData} from "../../../../../utils/donutChartUtils";
import {DataEntry} from "react-minimal-pie-chart/types/commonTypes";
import {getAssignmentCountFromStats, getRoundedPercentages} from "../Helpers/eventCalendarUtils";
import {getPercentage} from "../../../../../utils/mathUtils";
import {nanoid} from "nanoid";
import {
    vibrantGreenPrimary,
    vibrantRedPrimary,
    vibrantYellowPrimary
} from "../../../../../utils/colourUtils";
import HorizontalBarChartContainer from "../../../../Statistics/Components/HorizontalBarChartContainer";

const EventCalendarNextEventStats = ({nextDayStats}: Props) => {
    const chartData = getChartData(nextDayStats);
    const containsEvent = () => {
        return nextDayStats.length > 0;
    };
    return (
        <React.Fragment>
            {containsEvent() ? (
                <React.Fragment>
                    <h6 className="body-font mt-2">
                        Next shift - {formatUnixToDoMMMMYYYY(nextDayStats[0].eventDate)}
                    </h6>
                    <HorizontalBarChartContainer labelCharts={true} chartData={chartData} />
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <h6 className="body-font mt-2 mb-0 text-center">
                        Next Shift Details Not Available - No Shift Has Been Scheduled
                    </h6>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default EventCalendarNextEventStats;

interface Props {
    nextDayStats: CalendarEventSummaryStats[];
}

function getChartData(nextDayStats: CalendarEventSummaryStats[]): ChartData {
    return createDonutStat(nextDayStats, "");
}

/** Creates the donut stats with the outer label name being populated */
export function createDonutStat(
    nextDayStats: CalendarEventSummaryStats[],
    outerLabel: string
): ChartData {
    const percentageDataEntries: DataEntry[] = [];
    const rawValueDataEntries: DataEntry[] = [];
    const uids: string[] = [];

    const assignmentCounts = getAssignmentCounts(nextDayStats);
    const percentages = getStatPercentagesFromNextDayStats(assignmentCounts);
    const rawValues = getRawValuesFromAssignmentCounts(assignmentCounts);

    for (let i = 0; i < percentages.length; ++i) {
        const percentage = percentages[i];
        const rawValue = rawValues[i];
        const label = donutChartStatsLabels[i];

        percentageDataEntries.push({
            title: getBarChartTitle(label, rawValue, assignmentCounts.totalNumRequired),
            value: percentage,
            color: getBarChartColourFromPercentage(percentage)
        });

        rawValueDataEntries.push({
            title: donutChartStatsLabels[i],
            value: rawValue,
            color: getBarChartColourFromPercentage(percentage)
        });

        uids.push(nanoid());
    }

    return {
        percentageDataEntries,
        rawValueDataEntries,
        uids,
        innerLabel: "",
        outerLabel,
        totalValue: assignmentCounts.totalNumRequired
    };
}

function getBarChartTitle(label: string, currentCount: number, totalCount: number): string {
    return `${label} (${currentCount}/${totalCount})`;
}

function getBarChartColourFromPercentage(percentage: number) {
    if (percentage === 100) {
        return "horizontal-bar-briefed";
    }
    if (percentage > 66 && percentage <= 99) {
        return "horizontal-bar-accepted";
    }
    if (percentage > 33 && percentage <= 66) {
        return "horizontal-bar-waitingAccept";
    }
    return "horizontal-bar-unassigned";
}

// Labels for donut chart stats
export const donutChartStatsLabels: string[] = [
    "Number of Staff Assigned",
    "Number of Staff Confirmed",
    "Number of Staff Briefing Confirmed"
];

function getStatPercentagesFromNextDayStats({
    allocatedStaffCount,
    trueAcceptedTotal,
    trueBriefingAcceptedCount,
    totalNumRequired
}: AssignmentCounts): number[] {
    return [
        Math.round(getPercentage(allocatedStaffCount, totalNumRequired)),
        Math.round(getPercentage(trueAcceptedTotal, totalNumRequired)),
        Math.round(getPercentage(trueBriefingAcceptedCount, totalNumRequired))
    ];
}

/** Calculates the percentages of the audit stats and will account for rounding issues. */
export function getPercentagesFromAuditStats(counts: AssignmentCounts): number[] {
    const rawValues = getStatPercentagesFromNextDayStats(counts);
    return getRoundedPercentages(rawValues);
}

export function getRawValuesFromAssignmentCounts({
    allocatedStaffCount,
    trueAcceptedTotal,
    trueBriefingAcceptedCount
}: AssignmentCounts): number[] {
    return [allocatedStaffCount, trueAcceptedTotal, trueBriefingAcceptedCount];
}

export function getAssignmentCounts(nextDayStats: CalendarEventSummaryStats[]): AssignmentCounts {
    let waitingAcceptCount = 0;
    let acceptedCount = 0;
    let briefingAcceptedCount = 0;
    let attendedCount = 0;
    let totalNumRequired = 0;

    for (const stat of nextDayStats) {
        waitingAcceptCount += getAssignmentCountFromStats(
            stat.stateBreakdown,
            AssignmentState.WaitingAccept
        );

        acceptedCount += getAssignmentCountFromStats(stat.stateBreakdown, AssignmentState.Accepted);
        briefingAcceptedCount += getAssignmentCountFromStats(
            stat.stateBreakdown,
            AssignmentState.BriefingAccepted
        );
        attendedCount += getAssignmentCountFromStats(stat.stateBreakdown, AssignmentState.Attended);

        totalNumRequired += stat.numRequired;
    }
    const trueAcceptedTotal = acceptedCount + briefingAcceptedCount + attendedCount;
    return {
        waitingAcceptCount,
        acceptedCount,
        briefingAcceptedCount,
        attendedCount,
        totalNumRequired,
        trueAcceptedTotal,
        trueBriefingAcceptedCount: briefingAcceptedCount + attendedCount,
        allocatedStaffCount: trueAcceptedTotal + waitingAcceptCount
    };
}

export interface AssignmentCounts {
    waitingAcceptCount: number;
    acceptedCount: number;
    briefingAcceptedCount: number;
    attendedCount: number;
    totalNumRequired: number;
    trueAcceptedTotal: number;
    trueBriefingAcceptedCount: number;
    allocatedStaffCount: number;
}

// Colours for donut chart stats.
export const donutChartStatsColours: string[] = [
    vibrantRedPrimary,
    vibrantYellowPrimary,
    vibrantGreenPrimary
];
