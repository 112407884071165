import React from "react";
import {useSelector} from "react-redux";
import {PulseTable} from "pulse_table";
import {
    CalendarEntry,
    StaffInterestQueryResponse,
    UserInterest,
    Venue
} from "../../../../../api/grs";
import {RootStore} from "../../../../../store/Store";
import AvailableShiftEntryAction from "../AvailableShiftEntryAction";
import {
    getAvailableSpaces,
    getFilteredInterest,
    getLocationName,
    getShiftTimes,
    getTakenSpaces
} from "../../Helpers/availableShiftsListHelpers";
const ShiftsOverviewListTable = (props: StaffInterestQueryResponse) => {
    const venueList = useSelector((state: RootStore) => state.venueList.data || []);

    return (
        <React.Fragment>
            <PulseTable
                items={toShiftOverviewTableRow(props, venueList)}
                headers={{
                    shiftDetails: "Shift Details",
                    shiftTimes: "Shift Times",
                    availableSpaces: "Available Spaces",
                    takenSpaces: "Taken Spaces",
                    shiftInterest: "Shift Interest",
                    actions: "Actions"
                }}
                customRenderers={{
                    actions: (item: ShiftOverviewTableRow) => (
                        <React.Fragment>
                            <AvailableShiftEntryAction {...item.actions} />
                        </React.Fragment>
                    )
                }}
                noItemsSection={
                    <div className="row ml-0 mr-0 fadeIn">
                        <div className="col">
                            <h5 className="text-center p-3">
                                There are no available shifts on this date.
                            </h5>
                        </div>
                    </div>
                }
            />
        </React.Fragment>
    );
};

export default ShiftsOverviewListTable;

interface ShiftOverviewTableRow {
    shiftDetails: string;
    shiftTimes: string;
    availableSpaces: number;
    takenSpaces: number;
    shiftInterest: number;
    actions: AvailableShiftItemProps;
}

export interface AvailableShiftItemProps {
    entry: CalendarEntry;
    userInterest: UserInterest[];
}

function toShiftOverviewTableRow(
    resp: StaffInterestQueryResponse,
    venueList: Venue[]
): ShiftOverviewTableRow[] {
    //Filter entries by those who have shift interest
    return resp.entries
        .filter((entry) => {
            const shiftInterest = getFilteredInterest(entry, resp.interestList);
            return shiftInterest > 0;
        })
        .map((entry) => {
            return {
                shiftDetails: getLocationName(entry, venueList),
                shiftTimes: getShiftTimes(entry),
                availableSpaces: getAvailableSpaces(entry),
                takenSpaces: getTakenSpaces(entry),
                shiftInterest: getFilteredInterest(entry, resp.interestList),
                actions: {
                    entry,
                    userInterest: resp.interestList
                }
            };
        });
}
