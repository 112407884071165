import React from "react";
import Icon, {IconType} from "../Icon/Icon";
import Tooltip from "../Tooltip/Tooltip";

const FormHeaderWithAction = ({
    headerName,
    icon,
    showIcon,
    onClick,
    tooltipText,
    headerType
}: FormHeaderProps) => {
    const renderHeaderType = () => {
        switch (headerType) {
            case "h1":
                return (
                    <h1 className="mb-0">
                        {headerName}
                        {showIcon && (
                            <Tooltip
                                tooltipText={tooltipText}
                                wrapperClassName={"ml-2 cursor-pointer"}
                                size={"md"}
                                place={"right"}
                                theme={"light"}
                            >
                                <Icon
                                    rootElement={"Span"}
                                    icon={icon}
                                    size={"Medium"}
                                    onClick={onClick}
                                />
                            </Tooltip>
                        )}
                    </h1>
                );
            case "h2":
                return (
                    <h2 className="mb-0">
                        {headerName}
                        {showIcon && (
                            <Tooltip
                                tooltipText={tooltipText}
                                wrapperClassName={"ml-2 cursor-pointer"}
                                size={"md"}
                                place={"right"}
                                theme={"light"}
                            >
                                <Icon
                                    rootElement={"Span"}
                                    icon={icon}
                                    size={"Medium"}
                                    onClick={onClick}
                                />
                            </Tooltip>
                        )}
                    </h2>
                );
            case "h3":
                return (
                    <h3 className="mb-0">
                        {headerName}
                        {showIcon && (
                            <Tooltip
                                tooltipText={tooltipText}
                                wrapperClassName={"ml-2 cursor-pointer"}
                                size={"md"}
                                place={"right"}
                                theme={"light"}
                            >
                                <Icon
                                    rootElement={"Span"}
                                    icon={icon}
                                    size={"Medium"}
                                    onClick={onClick}
                                />
                            </Tooltip>
                        )}
                    </h3>
                );
            case "h4":
                return (
                    <h4 className="mb-0">
                        {headerName}
                        {showIcon && (
                            <Tooltip
                                tooltipText={tooltipText}
                                wrapperClassName={"ml-2 cursor-pointer"}
                                size={"md"}
                                place={"right"}
                                theme={"light"}
                            >
                                <Icon
                                    rootElement={"Span"}
                                    icon={icon}
                                    size={"Medium"}
                                    onClick={onClick}
                                />
                            </Tooltip>
                        )}
                    </h4>
                );
            case "h5":
                return (
                    <h5 className="mb-0">
                        {headerName}
                        {showIcon && (
                            <Tooltip
                                tooltipText={tooltipText}
                                wrapperClassName={"ml-2 cursor-pointer"}
                                size={"md"}
                                place={"right"}
                                theme={"light"}
                            >
                                <Icon
                                    rootElement={"Span"}
                                    icon={icon}
                                    size={"Medium"}
                                    onClick={onClick}
                                />
                            </Tooltip>
                        )}
                    </h5>
                );
            case "h6":
                return (
                    <h6 className="mb-0">
                        {headerName}
                        {showIcon && (
                            <Tooltip
                                tooltipText={tooltipText}
                                wrapperClassName={"ml-2 cursor-pointer"}
                                size={"md"}
                                place={"right"}
                                theme={"light"}
                            >
                                <Icon
                                    rootElement={"Span"}
                                    icon={icon}
                                    size={"Medium"}
                                    onClick={onClick}
                                />
                            </Tooltip>
                        )}
                    </h6>
                );
            default:
                return (
                    <h5 className="mb-0">
                        {headerName}
                        {showIcon && (
                            <Tooltip
                                tooltipText={tooltipText}
                                wrapperClassName={"ml-2 cursor-pointer"}
                                size={"md"}
                                place={"right"}
                                theme={"light"}
                            >
                                <Icon
                                    rootElement={"Span"}
                                    icon={icon}
                                    size={"Medium"}
                                    onClick={onClick}
                                />
                            </Tooltip>
                        )}
                    </h5>
                );
        }
    };
    return (
        <div className="row ml-0 mr-0 mc-form-heading">
            <div className="col">{renderHeaderType()}</div>
        </div>
    );
};

export default FormHeaderWithAction;

interface FormHeaderProps {
    headerName: string;
    icon: IconType;
    onClick: () => void;
    showIcon: boolean;
    tooltipText: string;
    headerType?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
}
