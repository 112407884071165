import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {CALENDAR_COMPLIANCE_STORE} from "../actions/CalendarComplianceActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";
import {CalendarComplianceResponse} from "../../../api/grs";

export const defaultCalendarComplianceState = createDefaultStoreState<CalendarComplianceResponse>({
    startDateInclusive: 0,
    endDateExclusive: 0,
    compliance: []
});

const calendarComplianceReducer = (
    state: StoreServiceData<CalendarComplianceResponse> = defaultCalendarComplianceState,
    action: ServiceActionTypes<CalendarComplianceResponse>
) => createReducer(state, action, CALENDAR_COMPLIANCE_STORE, () => showErrorToast(action.error));

export default calendarComplianceReducer;
