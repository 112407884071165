import React from "react";
import {StaffInterestQueryResponse} from "../../../../api/grs";
import AvailableShiftListFilters from "./AvailableShiftListFilters";

import {ActiveShiftView} from "../Provider/ShiftOverviewProvider";
import AuthAmI from "../../../AuthAmI/AuthAmI";
import {StaffAccessLevel} from "../../../../api/staff";
import ShiftsOverviewListTable from "./Admin/ShiftsOverviewListTable";
import BulkAcceptListTable from "./Admin/BulkAcceptListTable";
import {useShiftOverviewContext} from "../Hooks/useShiftOverviewContext";
import BulkUnregisterListTable from "./Staff/BulkUnregisterListTable";

const AvailableShiftsListTable = (props: StaffInterestQueryResponse) => {
    const {state} = useShiftOverviewContext();

    return (
        <React.Fragment>
            <AvailableShiftListFilters />
            <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                {state.view === ActiveShiftView.ShiftOverview ? (
                    <ShiftsOverviewListTable {...props} />
                ) : (
                    <BulkAcceptListTable {...props} />
                )}
            </AuthAmI>
            <AuthAmI accessLevels={[StaffAccessLevel.Staff, StaffAccessLevel.DutyManager]}>
                <BulkUnregisterListTable {...props} />
            </AuthAmI>
        </React.Fragment>
    );
};

export default AvailableShiftsListTable;
