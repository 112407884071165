/** Creates a csv file from JSON data. NOTE: JSON data needs to be formatted correctly */
import {capitalizeFirstLetter, splitStringByCapitalLetter} from "./textUtils";

export function jsonToCsv(filename: string, rows: any[]) {
    // if the object has no rows. stop the process here.
    if (!rows || !rows.length) {
        return;
    }

    /** Generate CSV here.
     *  use the ',' as a separator (New Line is JSON)
     *  if there are any dates in date format, regex the cell to stop it from breaking
     * */
    const separator = ",";
    const rawKeys = Object.keys(rows[0]);
    const keys: string[] = [];

    for (let i = 0; i < rawKeys.length; ++i) {
        const rowName = capitalizeFirstLetter(rawKeys[i]);
        keys.push(splitStringByCapitalLetter(rowName));
    }

    const csvContent =
        keys.join(separator) +
        "\n" +
        rows
            .map((row: any) => {
                return rawKeys
                    .map((k: string) => {
                        let cell = row[k] === null || row[k] === undefined ? "" : row[k];
                        cell =
                            cell instanceof Date
                                ? cell.toLocaleDateString()
                                : cell.toString().replace(/"/g, '""');
                        if (cell.search(/([",\n])/g) >= 0) {
                            cell = `"${cell}"`;
                        }
                        return cell;
                    })
                    .join(separator);
            })
            .join("\n");

    // Allows for download to appear in browser.
    const BOM = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([BOM, csvContent], {type: "text/csv;charset=utf-8;"});

    downloadBlob(filename, blob);
}

export function downloadBlob(filename: string, blob: Blob) {
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function isSafariBrowser() {
    const isChrome = navigator.userAgent.indexOf("Chrome") > -1;
    const isSafari = navigator.userAgent.indexOf("Safari") > -1;

    if (isSafari) {
        return !isChrome; // Chrome seems to have both Chrome and Safari userAgents
    }
    return false;
}

function deepCopy<T>(original: T): T {
    return JSON.parse(JSON.stringify(original));
}
export default deepCopy;
