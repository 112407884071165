import {CalendarSummaryStats} from "../../../api/grs";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const CALENDAR_SUMMARY_STATS_STORE = createStoreState("calendar_summary_stats");

interface CalendarSummaryStatsLoading extends StoreServiceData<CalendarSummaryStats[]> {
    type: typeof CALENDAR_SUMMARY_STATS_STORE.LOADING;
}
interface CalendarSummaryStatsError extends StoreServiceData<CalendarSummaryStats[]> {
    type: typeof CALENDAR_SUMMARY_STATS_STORE.ERROR;
}
interface CalendarSummaryStatsSuccess extends StoreServiceData<CalendarSummaryStats[]> {
    type: typeof CALENDAR_SUMMARY_STATS_STORE.SUCCESS;
}

export type CalendarSummaryStatsDispatchTypes =
    | CalendarSummaryStatsSuccess
    | CalendarSummaryStatsError
    | CalendarSummaryStatsLoading;
