import React, {useState} from "react";
import {useSwipeable} from "react-swipeable";
import {CarouselProps} from "../Components/Carousel";

export function useCarousel({children}: CarouselProps) {
    const [activeIndex, setActiveIndex] = useState(0);

    const updateIndex = (newIndex: number) => {
        if (newIndex < 0) {
            newIndex = React.Children.count(children) - 1;
        } else if (newIndex >= React.Children.count(children)) {
            newIndex = 0;
        }

        setActiveIndex(newIndex);
    };

    const handlers = useSwipeable({
        onSwipedLeft: () => updateIndex(activeIndex + 1),
        onSwipedRight: () => updateIndex(activeIndex - 1)
    });

    return {
        activeIndex,
        handlers,
        updateIndex
    };
}
