import React, {useEffect, useState} from "react";
import {pagedRequestConfig, RequestFilterProps} from "../../../../../Filters/helpers/filterHelpers";
import {DutyManagerReportPagedRequest} from "../../../../../../api/grs";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {useQuery} from "../../../../../Hooks/useQuery";
import moment, {Moment} from "moment";
import {nullifyDutyManagerReportPagedStore} from "../../../../../../store/dutyManagerReportPaged/actions/DutyManagerReportPagedActions";
import DatePicker from "react-datepicker";
import DatePickerInputButton from "../../../../../Button/DatePickerInputButton";

const DutyManagerReportPagedFilters = (
    props: RequestFilterProps<DutyManagerReportPagedRequest>
) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const query = useQuery();
    const [startDate, setStartDate] = useState<Moment>(moment());
    /** Upon mounting, see if we can get query string exists, if so, set date from this. */
    useEffect(() => {
        const month = query.get("month");

        if (month) {
            setStartDate(moment.unix(+month));
            return;
        }
        setStartDate(moment());
        return function () {
            dispatch(nullifyDutyManagerReportPagedStore());
        };
    }, []);

    /** Selected when the date changes */
    const onDateChanged = (date: Date | null) => {
        if (!date) return;

        setStartDate(moment(date));
    };

    function buildUrlHistory(request: DutyManagerReportPagedRequest) {
        const queryStrings: string[] = [];
        if (request.startDateInclusive) {
            queryStrings.push(`month=${request.startDateInclusive}`);
        }

        history.push({search: `?${queryStrings.join("&")}`});
    }

    /** Watches for changes to start date */
    useEffect(() => {
        if (!startDate) return;
        const request = buildDutyManagerReportRequest(startDate);

        props.onRequestChanged(request);
        buildUrlHistory(request);
    }, [startDate]);

    const buildDutyManagerReportRequest = (start: Moment): DutyManagerReportPagedRequest => {
        const startOfMonth = start.clone().startOf("month");
        const endOfMonth = start.clone().endOf("month");
        return {
            pageNum: 0,
            startDateInclusive: startOfMonth.unix(),
            endDateExclusive: endOfMonth.unix(),
            numPerPage: pagedRequestConfig.resultsPerPage
        };
    };
    return (
        <React.Fragment>
            <table className="filters-table mt-3">
                <tbody className="filters-lg">
                    <tr>
                        <th className="filter-heading w-50 pl-3 pr-3">Date</th>
                    </tr>
                    <td className="filter-heading w-50 pl-3 pr-3 pb-2">
                        <DatePicker
                            selected={startDate.toDate()}
                            onChange={onDateChanged}
                            showMonthYearPicker
                            dateFormat="MMMM yyyy"
                            portalId="root-portal"
                            customInput={<DatePickerInputButton />}
                        />
                    </td>
                </tbody>
                <tbody className="filters-md">
                    <tr>
                        <th className="filter-heading w-50 pl-3 pr-3">Date</th>
                    </tr>
                    <td className="filter-heading w-50 pl-3 pr-3 pb-2">
                        <DatePicker
                            selected={startDate.toDate()}
                            onChange={onDateChanged}
                            showMonthYearPicker
                            dateFormat="MMMM yyyy"
                            portalId="root-portal"
                            customInput={<DatePickerInputButton />}
                        />
                    </td>
                </tbody>
                <tbody className="filters-sm">
                    <tr>
                        <th className="filter-heading w-100 pl-3 pr-3">Date</th>
                    </tr>
                    <td className="filter-heading w-100 pl-3 pr-3 pb-2">
                        <DatePicker
                            selected={startDate.toDate()}
                            onChange={onDateChanged}
                            showMonthYearPicker
                            dateFormat="MMMM yyyy"
                            portalId="root-portal"
                            customInput={<DatePickerInputButton />}
                        />
                    </td>
                </tbody>
            </table>
        </React.Fragment>
    );
};

export default DutyManagerReportPagedFilters;
