import React from "react";
import {StaffMemberInvoice} from "../../../../../../../api/grs";
import {useInvoiceLinesTable} from "./Hooks/useInvoiceLinesTable";
import InvoiceLinesTableRow from "./InvoiceLinesTableRow";
import InvoiceLinesTableHeader from "./InvoiceLinesTableHeader";

function InvoiceLinesTable(props: StaffMemberInvoice) {
    const {headers, items} = useInvoiceLinesTable(props);
    return (
        <React.Fragment>
            <InvoiceLinesTableHeader headers={headers} />
            {items.map((item, key) => {
                return <InvoiceLinesTableRow {...item} key={key} />;
            })}
        </React.Fragment>
    );
}

export default InvoiceLinesTable;
