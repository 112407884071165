import React from "react";
import {DynamicKeyValue} from "../../../../../../../modules/Table/Helpers/TableHelpers";

function InvoiceErrorsTableHeader({headers}: Props) {
    return (
        <React.Fragment>
            <tr>
                {Object.keys(headers).map((item: string) => {
                    return (
                        <th
                            className="expanding-table-inner-table-header-item"
                            key={item}
                            colSpan={headers[item] === "Error" ? 3 : 1}
                        >
                            {
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                headers[item]
                            }
                        </th>
                    );
                })}
            </tr>
        </React.Fragment>
    );
}

export default InvoiceErrorsTableHeader;

interface Props {
    headers: DynamicKeyValue;
}
