import React from "react";
import FormRowWithTooltip from "../../../../../../../../Form/FormRowWithTooltip";
import {IconType} from "../../../../../../../../Icon/Icon";
import GenericTypeDropdown from "../../../../../../../../Dropdown/Components/GenericTypeDropdown";
import {RepeatType} from "../../../../../../../../../api/grs";
import {useRepetitiveEntryForm} from "../Hooks/useRepetitiveEntryForm";
import PulseDatePicker from "../../../../../../../../DatePicker/PulseDatePicker";
import moment from "moment";
import FormActionContainer from "../../../../../../../../Form/FormActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../../../../../Button/MCButton";
import {showSuccessToast} from "../../../../../../../../../utils/toastUtils";
import FormRow from "../../../../../../../../Form/FormRow";

function RepeatEntryForm({onClose}: Props) {
    const {save, request, updateRequest, repeatFrequencyMessage} = useRepetitiveEntryForm();

    function getRepeatTypeFromString(value: string): RepeatType {
        return RepeatType[value as keyof typeof RepeatType];
    }
    return (
        <React.Fragment>
            <FormRowWithTooltip
                icon={IconType.Question}
                tooltipText={"The frequency to repeat."}
                rowName={"Repeat Type"}
            >
                <GenericTypeDropdown
                    enumOptions={RepeatType}
                    splitByCapitalLetter={false}
                    clearable={false}
                    searchable={false}
                    changeOption={(value) => {
                        if (!value) return;
                        const repeatType = getRepeatTypeFromString(value.toString());

                        updateRequest({
                            ...request,
                            type: repeatType
                        });
                    }}
                    id={request.type}
                    disabled={false}
                />
            </FormRowWithTooltip>
            <FormRowWithTooltip
                icon={IconType.Question}
                tooltipText={
                    "The multiplier against the repeat type, to dictate how far apart each entry should be. If repeat type is 'Day', 1 will result in an entry every day. 2 will result in an entry every other day."
                }
                rowName={"Spacing"}
            >
                <input
                    type={"tel"}
                    value={request.spacing}
                    className="input-fields"
                    onChange={(event) => {
                        const {value} = event.target;

                        updateRequest({
                            ...request,
                            spacing: +value
                        });
                    }}
                />
            </FormRowWithTooltip>
            <FormRowWithTooltip
                icon={IconType.Question}
                tooltipText={
                    "Date for when the repeat should stop. Once this date is hit, no more entries will be created. Generally set this to the day after the very last entry you desire. If you want to repeat until the end of the year, set this to the first day of the new year."
                }
                rowName={"Date to"}
            >
                <PulseDatePicker
                    onChange={(value) => {
                        if (!value) return;
                        updateRequest({
                            ...request,
                            endDateExclusive: moment(value).startOf("day").unix()
                        });
                    }}
                    minDate={moment().startOf("day").toDate()}
                />
            </FormRowWithTooltip>
            <FormRow rowName={"Frequency"}>
                <p>{repeatFrequencyMessage()}</p>
            </FormRow>
            <FormActionContainer withColumn>
                <MCButton
                    size={ButtonSize.Large}
                    innerValue={"Save"}
                    onClick={async () => {
                        const success = await save();

                        if (!success) return;
                        showSuccessToast(`Success! ${repeatFrequencyMessage()}`);
                        onClose();
                    }}
                    colour={ButtonColourOptions.Yellow}
                    roundedCorner
                />
                <MCButton
                    size={ButtonSize.Large}
                    innerValue={"Cancel"}
                    onClick={onClose}
                    colour={ButtonColourOptions.DarkBlue}
                    roundedCorner
                />
            </FormActionContainer>
        </React.Fragment>
    );
}

interface Props {
    onClose: () => void;
}

export default RepeatEntryForm;
