import {Venue, XeroTrackingCategory, XeroTrackingLink} from "../../../../../api/grs";
import deepCopy from "../../../../../utils/csvUtils";

export function useXeroTrackingCategoryListContainer({
    categories,
    venue,
    updateVenue
}: XeroTrackingCategoryListContainerProps) {
    function isChecked(categoryId: string, itemId: string) {
        const xeroTracking = deepCopy(venue.xeroTracking || []);
        const index = xeroTracking.findIndex(
            (item) => item.categoryId === categoryId && item.itemId === itemId
        );
        return index > -1;
    }

    function isDisabled(categoryId: string, itemId: string): boolean {
        //Want to be able to click enabled inputs
        if (isChecked(categoryId, itemId)) return false;
        const xeroTracking = deepCopy(venue.xeroTracking || []);
        const uniqueCategories = getUniqueCategories(xeroTracking);

        //No more than 2 categories
        if (uniqueCategories.length >= 2) return true;

        // An item from that category has been selected, so we cannot allow for anymore
        // items to be selected from that category
        if (isCategorySelected(categoryId)) return true;

        return false;
    }

    function getUniqueCategories(tracking: XeroTrackingLink[]) {
        const categoryIds = tracking.map((item) => item.categoryId);

        return categoryIds.filter((value, index, array) => array.indexOf(value) === index);
    }

    function isCategorySelected(categoryId: string) {
        const xeroTracking = deepCopy(venue.xeroTracking || []);

        const index = xeroTracking.findIndex((item) => item.categoryId === categoryId);
        return index > -1;
    }

    function onClick(categoryId: string, itemId: string, name: string) {
        const xeroTracking = deepCopy(venue.xeroTracking || []);
        const index = xeroTracking.findIndex(
            (item) => item.categoryId === categoryId && item.itemId === itemId
        );

        if (index > -1) {
            xeroTracking.splice(index, 1);
        } else {
            xeroTracking.push({
                name,
                itemId,
                categoryId
            });
        }

        updateVenue({
            ...venue,
            xeroTracking
        });
    }

    function getTooltipText() {
        if (categories.length === 0) {
            return "The link to Xero hasn't been made. Tracking information could not be loaded.";
        }

        return "The link to Xero has been made. Currently viewing tracking information from Xero.";
    }

    return {
        isDisabled,
        isChecked,
        onClick,
        getTooltipText
    };
}

export interface XeroTrackingCategoryListContainerProps {
    venue: Venue;
    categories: XeroTrackingCategory[];
    updateVenue: (newVenue: Venue) => void;
}
