import React, {Fragment, useContext} from "react";
import {PulseTable} from "pulse_table";
import Tooltip from "../../../../Tooltip/Tooltip";
import UrgentActionButton from "./UrgentActionButton";
import {AvailableShiftEntrySectionGroupProps} from "./AvailableShiftEntrySectionGroup";
import {useAvailableShiftsInfoUtils} from "../Hooks/useAvailableShiftsInfoUtils";
import {ShiftInterestContext} from "../Context/ShiftInterestContext";
import UnregisterInterest from "../../../AvailableShiftsList/Components/Actions/UnregisterInterest";

const AvailableShiftEntrySectionGroupDetails = ({
    group,
    section,
    entry,
    callback
}: AvailableShiftEntrySectionGroupProps) => {
    const {tooltipText, indicatorClassName, urgentActionNeeded} = useAvailableShiftsInfoUtils();
    //At this current moment in time, User can only register for one section. So will either be null or populated
    const shiftInterest = useContext(ShiftInterestContext);

    function hasUserInterestRegistered() {
        if (!shiftInterest) return false;

        return shiftInterest.section.id === section.id && shiftInterest.group.id === group.id;
    }

    return (
        <React.Fragment>
            <div className="mb-3">
                <PulseTable
                    items={toTableRow({group, section, entry, callback})}
                    headers={{
                        sectionGroupName: "Group",
                        bookedSpaces: "Booked Spaces",
                        availableSpaces: "Available Spaces",
                        actions: "Actions"
                    }}
                    noItemsSection={
                        <div className="row ml-0 mr-0 fadeIn">
                            <div className="col">
                                <h5 className="text-center p-3">There are no available spaces.</h5>
                            </div>
                        </div>
                    }
                    customRenderers={{
                        bookedSpaces: (item: TableRow) => (
                            <Fragment>
                                <div className="d-inline-block">
                                    <p className="mb-0">{item.bookedSpaces}</p>
                                </div>
                                <div className="d-inline-block ml-3">
                                    <Tooltip
                                        rootElement={"span"}
                                        place={"right"}
                                        tooltipText={tooltipText(group)}
                                        size={"lg"}
                                    >
                                        <div className="read-only cursor-pointer">
                                            <div className={indicatorClassName(group)} />
                                        </div>
                                    </Tooltip>
                                </div>
                            </Fragment>
                        ),
                        actions: (item: TableRow) => (
                            <Fragment>
                                {hasUserInterestRegistered() ? (
                                    <UnregisterInterest
                                        interest={shiftInterest!}
                                        location={"available-shifts-calendar"}
                                        onSuccessfulUnregister={callback}
                                    />
                                ) : (
                                    <Fragment>
                                        {urgentActionNeeded(group) ? (
                                            <UrgentActionButton {...item.actions} />
                                        ) : (
                                            <p className="mb-0">N/A</p>
                                        )}
                                    </Fragment>
                                )}
                            </Fragment>
                        )
                    }}
                />
            </div>
        </React.Fragment>
    );
};

export default AvailableShiftEntrySectionGroupDetails;

function toTableRow(props: AvailableShiftEntrySectionGroupProps): TableRow[] {
    return [
        {
            sectionGroupName: `${props.group.name} - (${props.section.name})`,
            bookedSpaces: props.group.takenSlotCount,
            availableSpaces: props.group.availableSlotCount,
            actions: props
        }
    ];
}

interface TableRow {
    sectionGroupName: string;
    bookedSpaces: number;
    availableSpaces: number;
    actions: AvailableShiftEntrySectionGroupProps;
}
