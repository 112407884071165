import React from "react";
import {Loading} from "store-fetch-wrappers";
import Select from "react-select";
import {useCalendarSummariesDropdown} from "../Hooks/useCalendarSummariesDropdown";
import {CalendarTypeEnum} from "../../../../../api/grs";

function CalendarSummaryFilters({calendarIds, onChange}: Props) {
    const {summariesStore, value, options, handleFiltersChanged} = useCalendarSummariesDropdown(
        calendarIds,
        onChange
    );
    return (
        <React.Fragment>
            {summariesStore.loading ? (
                <Loading type={"three-ring"} showLoadingText={false} />
            ) : (
                <Select
                    className="search-bar"
                    classNamePrefix="select-options"
                    options={options}
                    onChange={handleFiltersChanged}
                    isSearchable={true}
                    value={value}
                    noOptionsMessage={() => "No Calendars Found"}
                    placeholder="Select Calendars"
                    isDisabled={false}
                    isClearable={false}
                    isMulti={true}
                    menuPortalTarget={document.body}
                />
            )}
        </React.Fragment>
    );
}

export default CalendarSummaryFilters;

interface Props {
    calendarIds: number[];
    onChange: (ids: number[], viewType: CalendarTypeEnum) => void;
}
