import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootStore} from "../../../store/Store";
import {StaffBlockTemplateListEntry} from "../../../api/grs";
import {
    DDProps,
    getSelectedDropdownOption,
    StaffBlockDropdownProps
} from "../Helpers/dropdownUtils";
import Select, {SingleValue} from "react-select";
import {Loading} from "store-fetch-wrappers";

const StaffBlockDropdown = (props: StaffBlockDropdownProps) => {
    const staffBlockStore = useSelector((state: RootStore) => state.globalStaffBlockList);
    const [options, setOptions] = useState<DDProps[]>([]);
    const [selectedOption, setSelectedOption] = useState<DDProps>();

    useEffect(() => {
        if (!staffBlockStore.data) return;

        processStaffBlocksIncoming(staffBlockStore.data);
    }, []);

    useEffect(() => {
        if (props.id === 0) {
            setSelectedOption(undefined);
            props.changeOption(undefined);
        }
    }, [props]);

    const processStaffBlocksIncoming = (items: StaffBlockTemplateListEntry[]) => {
        const optionList = getDropdownOptions(items);
        setOptions(optionList);

        if (props.clearable) {
            setSelectedOption(undefined);
            props.changeOption(undefined);
            return;
        }

        const option = getSelectedDropdownOption(props.id, optionList);
        setSelectedOption(option);
        props.changeOption({name: option.label, id: +option.value});
    };

    const handleStaffBlockChanged = (item: SingleValue<DDProps>) => {
        if (!item) {
            setSelectedOption(undefined);
            props.changeOption(undefined);
            return;
        }
        const option = getSelectedDropdownOption(props.id, options);
        setSelectedOption(option);
        props.changeOption({name: option.label, id: +option.value});
    };

    return (
        <React.Fragment>
            {staffBlockStore.loading ? (
                <Loading type={"three-ring"} showLoadingText={false} />
            ) : (
                <Select
                    className="search-bar"
                    classNamePrefix="select-options"
                    options={options}
                    onChange={handleStaffBlockChanged}
                    isSearchable={props.searchable}
                    value={selectedOption}
                    noOptionsMessage={() => "No Staff Blocks Found"}
                    placeholder="Select Staff Block"
                    isDisabled={props.disabled}
                    isClearable={props.clearable}
                    menuPortalTarget={document.body}
                />
            )}
        </React.Fragment>
    );
};

export default StaffBlockDropdown;

function getDropdownOptions(items: StaffBlockTemplateListEntry[]): DDProps[] {
    return items.map((item) => {
        return {
            value: item.id,
            label: item.name
        };
    });
}
