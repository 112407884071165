import React, {useContext} from "react";
import {StaffAccessLevel} from "../../../../../api/staff";
import {DutyManagerReportEditableContext} from "./Context/DutyManagerReportEditable";
import AuthAmI from "../../../../AuthAmI/AuthAmI";
import FormMessageBlock from "./Components/FormMessageBlock";

const DutyManagerWarning = () => {
    const editable = useContext(DutyManagerReportEditableContext);
    return (
        <React.Fragment>
            <AuthAmI accessLevels={[StaffAccessLevel.DutyManager]}>
                {!editable && (
                    <FormMessageBlock
                        message={
                            "You are viewing a read only version of this report. To make changes, contact a system administrator"
                        }
                    />
                )}
            </AuthAmI>
        </React.Fragment>
    );
};

export default DutyManagerWarning;
