import {StaffWageReportEntry} from "../../../api/grs";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const SELECTED_WAGE_REPORT_STORE = createStoreState("selected_wage_report");

interface SelectedWageReportLoading extends StoreServiceData<StaffWageReportEntry> {
    type: typeof SELECTED_WAGE_REPORT_STORE.LOADING;
}
interface SelectedWageReportError extends StoreServiceData<StaffWageReportEntry> {
    type: typeof SELECTED_WAGE_REPORT_STORE.ERROR;
}
interface SelectedWageReportSuccess extends StoreServiceData<StaffWageReportEntry> {
    type: typeof SELECTED_WAGE_REPORT_STORE.SUCCESS;
}

export type SelectedWageReportDispatchTypes =
    | SelectedWageReportError
    | SelectedWageReportLoading
    | SelectedWageReportSuccess;
