import {Dispatch} from "redux";
import GrsApiModel from "../../apiModel/GrsApiModel";
import {CALENDAR_STORE_STATE, CalendarDispatchTypes} from "./CalendarActionTypes";
import {BillingType, Calendar, CalendarTypeEnum, StaffBlockSection} from "../../../api/grs";
import {getDataFromServiceWithData, postDataToServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";

/** LOCAL */
/** Nukes data in store */
export const nullifyCalendarStore = () => {
    return async (dispatch: Dispatch<CalendarDispatchTypes>) => {
        dispatch({
            type: CALENDAR_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

/** Creates a blank calendar */
export const createBlankCalendar = () => {
    return async (dispatch: Dispatch<CalendarDispatchTypes>) => {
        dispatch({
            type: CALENDAR_STORE_STATE.LOADING,
            error: null,
            loading: true
        });

        const calendar: Calendar = {
            id: 0,
            version: 0,
            name: "",
            archived: false,
            bgImageUrl: "",
            calendarType: CalendarTypeEnum.Event,
            billingType: BillingType.Hourly,
            staffBlock: {
                sections: Array<StaffBlockSection>()
            },
            accountCode: ""
        };

        dispatch({
            type: CALENDAR_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: calendar
        });
    };
};

/** Updates the calendar locally */
export const setCalendar = (calendar: Calendar) => {
    return async (dispatch: Dispatch<CalendarDispatchTypes>) => {
        dispatch({
            type: CALENDAR_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: calendar
        });
    };
};

/** SERVICE */
/** Gets calendar from the service */
export const getCalendar = (id: number) => {
    return async (dispatch: Dispatch<CalendarDispatchTypes>) => {
        try {
            const calendar = await getDataFromServiceWithData(
                CALENDAR_STORE_STATE,
                dispatch,
                () => GrsApiModel.getCalendarApi().getCalendarById(id),
                statusCodeCallback
            );

            dispatch({
                type: CALENDAR_STORE_STATE.SUCCESS,
                error: null,
                loading: false,
                data: calendar
            });

            return calendar;
        } catch (e: any) {
            dispatch({
                type: CALENDAR_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

/** Saves calendar to the service. */
export const saveCalendarToService = (calendar: Calendar) => {
    return async (dispatch: Dispatch<CalendarDispatchTypes>) => {
        try {
            return await postDataToServiceWithRedux(
                CALENDAR_STORE_STATE,
                dispatch,
                () => GrsApiModel.getCalendarApi().saveCalendar(calendar),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: CALENDAR_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
