import React from "react";
import {ActiveDashView} from "../Shared/Hooks/useGrsDashContainer";
import Icon, {IconType} from "../../../Icon/Icon";
import {getActiveDashViewFromString} from "../../../../utils/enumUtils";

function DashViewButton({onClick, view, isActive}: Props) {
    function getInnerValue(): React.ReactNode {
        const targetView = getActiveDashViewFromString(view);

        switch (targetView) {
            case ActiveDashView.Slices:
                return <Icon rootElement={"Span"} icon={IconType.Slices} size={"Large"} />;
            case ActiveDashView.Tiles:
                return <Icon rootElement={"Span"} icon={IconType.Tiles} size={"Large"} />;
        }
    }
    return (
        <button
            className={`dash-tile-button ${isActive ? "selected" : ""} font-weight-normal`}
            onClick={() => onClick(view)}
        >
            {getInnerValue()}
        </button>
    );
}

export default DashViewButton;

interface Props {
    onClick: (newView: ActiveDashView) => void;
    view: ActiveDashView;
    isActive: boolean;
}
