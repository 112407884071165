import {Dispatch} from "redux";
import GrsApiModel from "../../apiModel/GrsApiModel";
import {
    PENDING_NOTIFICATIONS_STORE_STATE,
    PendingNotificationsDispatchTypes
} from "./PendingNotificationsActionTypes";
import {RootStore} from "../../Store";
import {getDataFromServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";

export const getPendingNotifications = () => {
    return async (
        dispatch: Dispatch<PendingNotificationsDispatchTypes>,
        state: () => RootStore
    ) => {
        try {
            const success = await getDataFromServiceWithRedux(
                PENDING_NOTIFICATIONS_STORE_STATE,
                dispatch,
                () => GrsApiModel.getStaffApi().getPendingActions(),
                statusCodeCallback
            );

            //Get the data from the store upon success
            if (success) {
                const data = state().pendingNotifications.data;

                if (!data) return [];

                return data;
            }
        } catch (e: any) {
            dispatch({
                type: PENDING_NOTIFICATIONS_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
