import {Calendar} from "../../../api/grs";
import {CALENDAR_STORE_STATE} from "../actions/CalendarActionTypes";
import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

/** Default state of the store. */
const defaultState = createDefaultStoreState<Calendar>(null);

/** Infer type of the reducer for code completion and quality */
const calendarReducer = (
    state: StoreServiceData<Calendar> = defaultState,
    action: ServiceActionTypes<Calendar>
): StoreServiceData<Calendar> =>
    createReducer(state, action, CALENDAR_STORE_STATE, () => showErrorToast(action.error));

export default calendarReducer;
