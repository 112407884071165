import React, {useEffect} from "react";
import {routeNames} from "../../../Navigation/routeNames";
import {useDispatch, useSelector} from "react-redux";
import {NavigationItem} from "../../../../store/navigation/actions/NavigationActionTypes";
import {setNavigationItems} from "../../../../store/navigation/actions/NavigationActions";
import {usePageUrl} from "../../../Hooks/usePageUrl";
import {getAttendanceOverviewPath} from "../../AttendanceOverview/Helpers/attendanceOverviewHelpers";
import {useCalendarPageDetails} from "../../../Hooks/useCalendarPageDetails";
import {getCalendarPathForCalendarChildRoutes, getVenuesListPath} from "../../VenueList/VenueList";
import FilterContainer from "../../../Filters/FilterContainer";
import EventReportFilters from "./Components/EventReportFilters";
import {EventsReportRequest} from "../../../../api/grs";
import {getEventReportForCalendar} from "../../../../store/eventReport/actions/EventReportActions";
import {getEventReportListForCalendar} from "../../../../store/eventReportList/actions/EventReportListActions";
import {WithServiceState} from "store-fetch-wrappers/dist";
import EventReportSummaryWrapper from "./Components/EventReportSummaryWrapper";
import {RootStore} from "../../../../store/Store";
import {
    getCalendar,
    nullifyCalendarStore
} from "../../../../store/calendar/actions/CalendarActions";
import EventReportStats from "./Components/EventReportStats";
import FormActionContainer from "../../../Form/FormActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import {formatUnixToMMMMYYYY} from "../../../../utils/momentUtils";
import {eventReportFile} from "../../../../utils/reportUtils";
import {jsonToCsv} from "../../../../utils/csvUtils";

const EventReportServiceWrapper = WithServiceState(EventReportSummaryWrapper);
const EventReportStatsServiceWrapper = WithServiceState(EventReportStats);

/** Parent component for the Event report */
const EventReport = () => {
    const {fullPath} = usePageUrl();
    const dispatch = useDispatch();
    const eventReportStore = useSelector((state: RootStore) => state.eventReport);

    const eventReportListStore = useSelector((state: RootStore) => state.eventReportList);
    const {calendarId, calendarName} = useCalendarPageDetails();

    useEffect(() => {
        if (!calendarId) return;
        dispatch(getCalendar(+calendarId));
        setupRoutes();

        return function () {
            dispatch(nullifyCalendarStore());
        };
    }, []);

    /** Sets up routes for the venues page. */
    const setupRoutes = () => {
        if (!calendarId) return;
        //Gets the query string for the month/week for when the user navigates back to the calendar page.
        const calendarPath = getCalendarPathForCalendarChildRoutes(calendarName, +calendarId);

        //Navigation items.
        const navItems: NavigationItem[] = [
            {
                name: routeNames.groupRosteringSystem.name,
                path: routeNames.groupRosteringSystem.path
            },
            {
                name: calendarName,
                path: calendarPath
            },
            {
                name: routeNames.eventReport.name,
                path: fullPath
            },
            {
                name: routeNames.venueList.name,
                path: getVenuesListPath(calendarName, +calendarId)
            },
            {
                name: routeNames.attendanceOverview.name,
                path: getAttendanceOverviewPath(calendarName, +calendarId)
            }
        ];

        dispatch(setNavigationItems(navItems));
    };

    /** fired when filters have been updated */
    const onEventReportFilterChanged = (request: EventsReportRequest) => {
        //Dispatch to get report for event info
        dispatch(getEventReportForCalendar(request));
        //Dispatch to get reports for Stats
        dispatch(getEventReportListForCalendar(request));
    };
    // /** Allows user to download report. Action available to System admins only */
    const downloadReport = async () => {
        const eventsReport = eventReportStore.data;
        // if (!calendar) return;
        if (!eventsReport) return;
        if (!eventsReport.startDateInclusive || !eventsReport.entries) return;
        //
        const reportDate = formatUnixToMMMMYYYY(eventsReport.startDateInclusive);
        const fileName = `Event Report - ${calendarName} - ${reportDate}.csv`;

        const report = eventReportFile(eventsReport.entries, calendarName);
        jsonToCsv(fileName, report);
    };

    return (
        <React.Fragment>
            <div className="page-container">
                <FilterContainer closed={false}>
                    <EventReportFilters onRequestChanged={onEventReportFilterChanged} />
                </FilterContainer>
                <EventReportServiceWrapper
                    showLoadingText={true}
                    loaderType={"overlay"}
                    loaderWheelType={"three-ring"}
                    placeholderBlockCount={1}
                    {...eventReportStore}
                />
                <EventReportStatsServiceWrapper
                    showLoadingText={true}
                    loaderType={"normal"}
                    loaderWheelType={"three-ring"}
                    placeholderBlockCount={1}
                    {...eventReportListStore}
                />
                <FormActionContainer>
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Download"}
                        onClick={downloadReport}
                        colour={ButtonColourOptions.Yellow}
                        roundedCorner
                    />
                </FormActionContainer>
            </div>
        </React.Fragment>
    );
};

export default EventReport;
