import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {Venue} from "../../../api/grs";
import {VENUE_LIST_STORE_STATE} from "../actions/VenueListActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";

/** Default state of the store. */
const defaultState = createDefaultStoreState<Venue[]>([]);

/** Infer type of the reducer for code completion and quality */
const venueListReducer = (
    state: StoreServiceData<Venue[]> = defaultState,
    action: ServiceActionTypes<Venue[]>
): StoreServiceData<Venue[]> =>
    createReducer(state, action, VENUE_LIST_STORE_STATE, () => showErrorToast(action.error));

export default venueListReducer;
