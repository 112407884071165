import {ExpandAction} from "../../Components/ExpandingTableRowCell";
import {createStoreState} from "store-fetch-wrappers/dist";
import {StoreServiceData} from "store-fetch-wrappers";

export interface ExpandingTableRows {
    rows: ExpandTableRow[];
}

export interface ExpandTableRow {
    id: number;
    expandAction?: ExpandAction;
}

export const EXPANDING_TABLE_STORE = createStoreState("expanding_table");

interface ExpandingTableLoading extends StoreServiceData<ExpandingTableRows> {
    type: typeof EXPANDING_TABLE_STORE.LOADING;
}

interface ExpandingTableError extends StoreServiceData<ExpandingTableRows> {
    type: typeof EXPANDING_TABLE_STORE.ERROR;
}

interface ExpandingTableSuccess extends StoreServiceData<ExpandingTableRows> {
    type: typeof EXPANDING_TABLE_STORE.SUCCESS;
}

export type ExpandingTableDispatchTypes =
    | ExpandingTableLoading
    | ExpandingTableError
    | ExpandingTableSuccess;

export interface TableRedirect {
    url: string;
    selectedRow: ExpandTableRow[];
}
