import {Dispatch} from "redux";
import {
    STAFF_INTEREST_QUERY_RESPONSE_STORE,
    StaffInterestQueryResponseDispatchTypes
} from "./StaffInterestQueryResponseActionTypes";
import {StaffInterestQueryFields} from "../../../api/grs";
import GrsApiModel from "../../apiModel/GrsApiModel";
import {getDataFromServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";
import {createBlankStaffInterestQueryResponse} from "../reducer/StaffInterestQueryResponseReducer";

/** Nukes store of stale data */
export const nullifyStaffInterestQueryResponseStore = () => {
    return async (dispatch: Dispatch<StaffInterestQueryResponseDispatchTypes>) => {
        dispatch({
            type: STAFF_INTEREST_QUERY_RESPONSE_STORE.SUCCESS,
            error: null,
            loading: false,
            data: createBlankStaffInterestQueryResponse()
        });
    };
};

/** Nukes store of stale data */
export const getStaffInterestForDates = (request: StaffInterestQueryFields) => {
    return async (dispatch: Dispatch<StaffInterestQueryResponseDispatchTypes>) => {
        try {
            return await getDataFromServiceWithRedux(
                STAFF_INTEREST_QUERY_RESPONSE_STORE,
                dispatch,
                () =>
                    GrsApiModel.getCalendarApi().interestQuery({
                        ...request,
                        includeFullEntries: true
                    }),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: STAFF_INTEREST_QUERY_RESPONSE_STORE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};
