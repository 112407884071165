import React, {useEffect, useState} from "react";
import {StaffWageReportEntry} from "../../../../../api/grs";
import {DonutChart, getDonutStat} from "../../../../../utils/donutChartUtils";
import {getWageForWageReport} from "../Helpers/wageReportHelpers";
import {
    vibrantBluePrimary,
    vibrantGreenPrimary,
    vibrantYellowPrimary
} from "../../../../../utils/colourUtils";
import DonutChartList from "../../../../DonutChart/DonutChartList";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const numeral = require("numeral");

const SelectedWageReportStats = (props: StaffWageReportEntry) => {
    const [donutList, setDonutList] = useState<DonutChart[]>([]);

    useEffect(() => {
        const cost = getWageForWageReport(props);

        let averageCost = cost / props.staff.length;

        if (isNaN(averageCost)) {
            averageCost = 0;
        }

        const numOfStaffStats = getDonutStat(
            props.staff.length.toString(),
            "Number of Staff",
            props.staff.length,
            props.staff.length,
            vibrantGreenPrimary
        );

        const formattedCost = numeral(cost).format("0,0.00");
        const eventCostStat = getDonutStat(
            `£${formattedCost}`,
            "Cost of Shift",
            cost,
            cost,
            vibrantYellowPrimary
        );
        const formattedAverageCost = numeral(averageCost).format("0,0.00");
        const costPerStaffStat = getDonutStat(
            `£${formattedAverageCost}`,
            "Cost Per Staff Member",
            averageCost,
            averageCost,
            vibrantBluePrimary
        );

        setDonutList([numOfStaffStats, eventCostStat, costPerStaffStat]);
    }, [props]);
    return (
        <React.Fragment>
            <DonutChartList chartData={donutList} headerText={"Wage Report Stats"} />
        </React.Fragment>
    );
};

export default SelectedWageReportStats;
