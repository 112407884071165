import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {DutyManagerReport, StaffLink} from "../../../api/grs";

export const DUTY_MANAGER_REPORT_STORE_STATE = createStoreState("duty_manager_report");

interface DutyManagerReportLoading extends StoreServiceData<DutyManagerReport> {
    type: typeof DUTY_MANAGER_REPORT_STORE_STATE.LOADING;
}
interface DutyManagerReportError extends StoreServiceData<DutyManagerReport> {
    type: typeof DUTY_MANAGER_REPORT_STORE_STATE.ERROR;
}
interface DutyManagerReportSuccess extends StoreServiceData<DutyManagerReport> {
    type: typeof DUTY_MANAGER_REPORT_STORE_STATE.SUCCESS;
}

export type DutyManagerReportDispatchTypes =
    | DutyManagerReportLoading
    | DutyManagerReportError
    | DutyManagerReportSuccess;

export interface CreateDutyManagerReportArgs {
    calendarEntryId: number;
    venueId: number | undefined;
}

export interface DutyManagerReportPayload {
    vehicles: Array<VehicleDetails>;
    damageAndDefects: string;
    equipmentAndConsumables: string;
    operationalComments: string;
}

export interface VehicleDetails {
    assignments: Array<VehicleDriver>;
    vehicleRegistration: string;
    uid: string;
}

export interface SetDriverDetailArgs {
    vehicleUID: string;
    previousAssignmentUID: string;
    newAssignment: StaffLink;
}

export interface SetDriverDirectionArgs {
    assignmentUID: string;
    newDirection: DrivingDirection;
}

export interface AddDriverDetailArgs {
    vehicleUID: string;
}

export interface RemoveDriverDetailsArgs {
    uid: string;
}

export interface RemoveVehicleDetailsArgs {
    uid: string;
}

export interface VehicleDriver extends StaffLink {
    uid: string;
    drivingDirection?: DrivingDirection;
}

// eslint-disable-next-line no-shadow
export enum DrivingDirection {
    Inbound = "Inbound",
    Outbound = "Outbound",
    Both = "Both"
}
