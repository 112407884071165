import {
    AssignmentState,
    BillingType,
    CalendarEntry,
    CalendarEntryListRequest,
    CalendarTypeEnum,
    EventCalendarEntry,
    FrontlineCalendarEntry,
    Venue
} from "../../../api/grs";
import {Dispatch} from "redux";
import GrsApiModel from "../../apiModel/GrsApiModel";
import store from "../../Store";
import {fetchAllVenues} from "../../venueList/actions/VenueListActions";
import {
    CalendarEventItem,
    FULL_CALENDAR_LIST_ITEM_STORE,
    FullCalendarListItemDispatchTypes
} from "./FullCalendarItemListActionTypes";
import {formatUnixToHHmmddd} from "../../../utils/momentUtils";
import {getDataFromServiceWithData} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";
import {
    getAssignmentCountForEntry,
    getCalendarColours,
    isEntryHistoric
} from "../../../components/Pages/AdminCalendar/Helpers/calendarUtils";
import {lightThemeWhite} from "../../../utils/colourUtils";
import {
    getTotalAssignmentsWithGroupName,
    StaffAssignmentWithGroupName
} from "../../../components/Pages/AdminCalendarEntry/Helpers/calenderEntryHelpers";

export const nullifyCalendarEntriesListForCalendarPage = () => {
    return async (dispatch: Dispatch<FullCalendarListItemDispatchTypes>) => {
        dispatch({
            type: FULL_CALENDAR_LIST_ITEM_STORE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};
/** Gets list of entries and converts into items that works with full calendar */
export const getCalendarEntriesListForCalendarPage = (
    args: CalendarEntryListRequest,
    excludeFrontlineEntries = false
) => {
    return async (dispatch: Dispatch<FullCalendarListItemDispatchTypes>) => {
        try {
            const entries = await getDataFromServiceWithData(
                FULL_CALENDAR_LIST_ITEM_STORE,
                dispatch,
                () => GrsApiModel.getCalendarApi().listCalendarEntries(args),
                statusCodeCallback
            );

            /** No entries, we just update the store with an empty array */
            if (!entries) {
                dispatch({
                    type: FULL_CALENDAR_LIST_ITEM_STORE.SUCCESS,
                    loading: false,
                    error: null,
                    data: []
                });
                return;
            }

            dispatch({
                type: FULL_CALENDAR_LIST_ITEM_STORE.SUCCESS,
                loading: false,
                error: null,
                data: await convertEntriesListToCalendarEventItem(entries, excludeFrontlineEntries)
            });
        } catch (e: any) {
            dispatch({
                type: FULL_CALENDAR_LIST_ITEM_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

/** Converts list of calendar entries into Full calendar items */
export async function convertEntriesListToCalendarEventItem(
    entries: EventCalendarEntry[] | FrontlineCalendarEntry[],
    excludeFrontlineEntries = false
): Promise<CalendarEventItem[]> {
    const calendarItems: CalendarEventItem[] = [];

    //If the venue store is empty, we get them again.
    let venues: Venue[] | undefined | null = store.getState().venueList.data;
    if (!venues) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        venues = await store.dispatch(fetchAllVenues());
    }

    for (const entry of entries) {
        if (excludeFrontlineEntries && entry.calendarType === CalendarTypeEnum.Frontline) continue;
        const eventColour = getCalendarColours(entry);
        const actualStartDate = new Date(entry.startDate * 1000);
        const actualEndDate = new Date(entry.endDate * 1000);

        // The title of the calendar item is determined by whether the entry is of type EventCalendarEntry,
        // if so, we get the Venue name from the list, otherwise, it is a frontline entry and we show the start and end date
        const title = getNameOfEntryBasedOnCalendarType(entry.calendarType, entry, venues);

        calendarItems.push({
            title,
            start: actualStartDate,
            end: actualEndDate,
            allDay: false,
            id: entry.id.toString(),
            color: eventColour,
            groupId: entry.calendarId.toString(),
            textColor: lightThemeWhite,
            entryState: getEntryStateForEntry(entry),
            assignments: getFilledAssignmentsForEntry(entry),
            timeText: getTimeTextForEntry(entry.startDate, entry.endDate),
            calendarType: entry.calendarType,
            isHistoric: isEntryHistoric(entry),
            billingType: entry.billingType,
            venueTime: getVenueDepotTimes(entry, "venueTime"),
            depotTime: getVenueDepotTimes(entry, "depotTime")
        });
    }

    return calendarItems;
}

function getVenueDepotTimes(entry: CalendarEntry, key: string): number | undefined {
    if (!entry.billingType) return;
    if (entry.billingType !== BillingType.Daily) return;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return entry[key] || undefined;
}

export function getTimeTextForEntry(startDate: number, endDate: number): string {
    return `${formatUnixToHHmmddd(startDate)} - ${formatUnixToHHmmddd(endDate)}`;
}

/** Returns assignments that are filled */
function getFilledAssignmentsForEntry(
    entry: EventCalendarEntry | FrontlineCalendarEntry
): StaffAssignmentWithGroupName[] {
    return getTotalAssignmentsWithGroupName(entry);
}

/** Gets the entry state for the calendar item */
function getEntryStateForEntry(
    entry: EventCalendarEntry | FrontlineCalendarEntry
): AssignmentState {
    const {totalUnassigned, trueAttendedBriefingTotal, trueAcceptedTotal, totalAssignments} =
        getAssignmentCountForEntry(entry);

    if (totalUnassigned > 0 || totalAssignments === 0) {
        return AssignmentState.Unassigned;
    }

    // All staff assigned have accepted the breifing or have attended.
    if (trueAttendedBriefingTotal === totalAssignments) {
        return AssignmentState.BriefingAccepted;
    }

    // All staff are either accepted or briefing accepted.
    if (trueAcceptedTotal === totalAssignments) {
        return AssignmentState.Accepted;
    }

    // If none of the above are met, set the default to this
    return AssignmentState.WaitingAccept;
}

/** Gets name based on calendar type */
function getNameOfEntryBasedOnCalendarType(
    calenderType: CalendarTypeEnum,
    entry: EventCalendarEntry | FrontlineCalendarEntry,
    venues: Venue[] | undefined | null
): string {
    if (entry.subTitle) {
        return entry.subTitle;
    }
    switch (calenderType) {
        case CalendarTypeEnum.Frontline:
            return getCallSignForFrontlineEntry(entry);
        case CalendarTypeEnum.Event:
            return getTitleNameForEventCalendarEntry(entry, venues);
    }
}

/** Gets the callsign for frontline entry */
function getCallSignForFrontlineEntry(entry: EventCalendarEntry | FrontlineCalendarEntry) {
    return `${entry.description}`;
}

/** Gets the venue name based on location */
function getTitleNameForEventCalendarEntry(
    entry: EventCalendarEntry | FrontlineCalendarEntry,
    venues: Venue[] | undefined | null
) {
    if (!entry.description) {
        return "No description available";
    }

    if (!venues || venues.length === 0) {
        //Fallback if the request returns null or with a length of 0
        return entry.description;
    }

    //Find index of Venue and return the name of the venue.
    const index = venues.findIndex((x: Venue) => x.id === entry.venueId);
    if (index >= 0) {
        return venues[index].name;
    }

    //Fallback if index < 0,  shouldn't happen, but account for edge case.
    return entry.description;
}
