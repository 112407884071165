import React, {useEffect, useState} from "react";
import {useUserData} from "../../Hooks/useUserData";
import {useQuery} from "../../Hooks/useQuery";
import {useDispatch} from "react-redux";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {
    convertStringToBoolean,
    getNavigationItemsForAttendanceRoute
} from "./Helpers/attendanceHelpers";
import {usePageUrl} from "../../Hooks/usePageUrl";
import AssignmentActions from "./Components/AssignmentActions";

const ConfirmAttendance = () => {
    const user = useUserData();
    const query = useQuery();
    const dispatch = useDispatch();
    const [accepted, setAccepted] = useState<boolean>(false);
    const [entryId, setEntryId] = useState<number>(0);
    const {fullPath} = usePageUrl();
    useEffect(() => {
        const accept = query.get("accept");

        if (!accept) return;
        const convertedValue = convertStringToBoolean(accept);
        setAccepted(convertedValue);

        const incomingEntryId = query.get("entryId");
        if (!incomingEntryId) return;
        setEntryId(+incomingEntryId);
    }, []);

    useEffect(() => {
        if (user.username.length === 0) return;
        dispatch(
            setNavigationItems(getNavigationItemsForAttendanceRoute(user, false, query, fullPath))
        );
    }, [user]);
    return (
        <React.Fragment>
            {entryId > 0 && (
                <div className="page-container">
                    <AssignmentActions
                        isBriefingRoute={false}
                        confirm={accepted}
                        entryId={entryId}
                    />
                </div>
            )}
        </React.Fragment>
    );
};

export default ConfirmAttendance;
