import React from "react";
import {
    AvailableShiftCalendarEntry,
    AvailableShiftGrouping,
    AvailableShiftSection
} from "../../../../../api/grs";
import AvailableShiftEntrySectionGroupDetails from "./AvailableShiftEntrySectionGroupDetails";
import {useAvailableShiftsInfoUtils} from "../Hooks/useAvailableShiftsInfoUtils";

const AvailableShiftEntrySectionGroup = (props: AvailableShiftEntrySectionGroupProps) => {
    const {showGroup} = useAvailableShiftsInfoUtils();

    return (
        <React.Fragment>
            {showGroup(props.group) && (
                <React.Fragment>
                    <AvailableShiftEntrySectionGroupDetails
                        group={props.group}
                        section={props.section}
                        entry={props.entry}
                        callback={props.callback}
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default AvailableShiftEntrySectionGroup;

export interface AvailableShiftEntrySectionGroupProps {
    entry: AvailableShiftCalendarEntry;
    section: AvailableShiftSection;
    group: AvailableShiftGrouping;
    callback: () => void;
}
