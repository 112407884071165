import React, {useEffect, useState} from "react";
import {
    StaffWageReportEntry,
    StaffWageReportRequest,
    StaffWageReportResponse
} from "../../../../../api/grs";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../../store/Store";
import {setSelectedWageReportEntry} from "../../../../../store/selectedWageReport/actions/SelectedWageReportActions";
import {
    fetchStaffWageReport,
    nullifyStaffWageReport
} from "../../../../../store/staffWageReport/actions/StaffWageReportActions";
import FilterContainer from "../../../../Filters/FilterContainer";
import WageReportFilters from "./WageReportFilters";
import {PulseTableWithLocalPagination} from "pulse_table";
import {pagedRequestConfig} from "../../../../Filters/helpers/filterHelpers";
import {formatUnixToDoMMMMYYYY, formatUnixToMMMMYYYY} from "../../../../../utils/momentUtils";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../Button/MCButton";
import SelectedWageReport from "./SelectedWageReport";
import {DebounceInput} from "react-debounce-input";
import {wageReportFile} from "../../../../../utils/reportUtils";
import {jsonToCsv} from "../../../../../utils/csvUtils";

const WageReportTable = (props: StaffWageReportResponse) => {
    const dispatch = useDispatch();
    const [searchString, setSearchString] = useState<string>("");
    const selectedWageReportStore = useSelector((state: RootStore) => state.selectedWageReport);
    const [items, setItems] = useState<TableRow[]>([]);

    useEffect(() => {
        //Removing stale data from stores when component is unmounted
        return function () {
            dispatch(setSelectedWageReportEntry(null));
            dispatch(nullifyStaffWageReport());
        };
    }, []);

    /** Fired when the filters are changed */
    const onStaffWageReportFilterChanged = (request: StaffWageReportRequest) => {
        dispatch(fetchStaffWageReport(request));
    };

    useEffect(() => {
        if (!props.entries) return;

        const filtered = props.entries.filter((item) => {
            const calendar = item.calendarName.toLowerCase();
            const entry = item.entryName.toLowerCase();
            const billingType = item.billingType.toLowerCase();
            return (
                calendar.includes(searchString.toLowerCase()) ||
                billingType.includes(searchString.toLowerCase()) ||
                entry.includes(searchString.toLowerCase())
            );
        });

        const results = searchString.length > 0 ? filtered : props.entries;
        setItems(toTableRow(results));
    }, [searchString, props]);

    /** Downloads all wage reports */
    const downloadAllReports = () => {
        //Should never be the case as all reports are controlled through a month picker.
        if (!props.startDateInclusive) return;
        if (!props.entries) return;

        const date = formatUnixToMMMMYYYY(props.startDateInclusive);
        const fileName = `Wage Report - ${date}.csv`;

        const report = wageReportFile(props.entries);
        jsonToCsv(fileName, report);
    };
    return (
        <React.Fragment>
            {selectedWageReportStore.data ? (
                <SelectedWageReport {...selectedWageReportStore.data} />
            ) : (
                <React.Fragment>
                    <div className="fadeIn">
                        <FilterContainer closed={false}>
                            <WageReportFilters onRequestChanged={onStaffWageReportFilterChanged} />
                        </FilterContainer>
                        <div className="row ml-0 mr-0 pt-3 mb-2">
                            <div className="col pr-0 d-flex align-items-center justify-content-end">
                                {items.length > 0 && (
                                    <React.Fragment>
                                        <MCButton
                                            className="mt-2 mr-3"
                                            size={ButtonSize.Large}
                                            roundedCorner
                                            innerValue={"Download All Reports"}
                                            onClick={downloadAllReports}
                                            colour={ButtonColourOptions.Yellow}
                                        />
                                        <DebounceInput
                                            debounceTimeout={300}
                                            className={"input-fields width-250px"}
                                            inputType={"input"}
                                            onChange={(event) =>
                                                setSearchString(event.target.value)
                                            }
                                            disabled={false}
                                            value={searchString}
                                            placeholder="Search wage reports..."
                                        />
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                        <PulseTableWithLocalPagination
                            resultsPerPage={pagedRequestConfig.resultsPerPage}
                            paginate
                            items={items}
                            headers={{
                                shiftDetails: "Shift Details",
                                calendar: "Calendar",
                                billingType: "Billing Type",
                                shiftDate: "Shift Date",
                                actions: "Actions"
                            }}
                            customRenderers={{
                                actions: (item: TableRow) => (
                                    <React.Fragment>
                                        <MCButton
                                            size={ButtonSize.Large}
                                            innerValue={"View Report"}
                                            onClick={() => {
                                                if (!props.entries) return;
                                                const entry:
                                                    | StaffWageReportEntry
                                                    | undefined
                                                    | null = props.entries.find(
                                                    (el) => el.entryId === item.actions
                                                );
                                                dispatch(setSelectedWageReportEntry(entry));
                                            }}
                                            colour={ButtonColourOptions.DarkBlue}
                                            roundedCorner
                                        />
                                    </React.Fragment>
                                )
                            }}
                            noItemsSection={
                                <div className="row ml-0 mr-0 fadeIn">
                                    <div className="col">
                                        <h5 className="text-center p-3">
                                            There are no wage reports for this month
                                        </h5>
                                    </div>
                                </div>
                            }
                        />
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default WageReportTable;

interface TableRow {
    shiftDetails: string;
    calendar: string;
    billingType: string;
    shiftDate: string;
    actions: number;
}

function toTableRow(entries: StaffWageReportEntry[] | undefined): TableRow[] {
    if (!entries) return [];

    return entries.map((item) => {
        return {
            shiftDetails: item.entryName,
            calendar: item.calendarName,
            billingType: item.billingType,
            shiftDate: formatUnixToDoMMMMYYYY(item.startDate),
            actions: item.entryId
        };
    });
}
