import React, {useContext, useEffect, useState} from "react";
import {Modal, useModal} from "pulse-modal";
import {
    getAssignedStaffWithEmailList,
    StaffLinkAddedDetails
} from "../../../../Helpers/calenderEntryHelpers";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../../../Button/MCButton";
import {CalendarEntryContext} from "../../../../../../Context/CalendarEntryContext";
import {StaffListContext} from "../../../../../../Context/StaffListContext";
import {PulseTable} from "pulse_table";
import NoItemsMessage from "../../../../../../Table/NoItemsMessage";

const CalendarEntryStaffDetails = () => {
    const entry = useContext(CalendarEntryContext);
    const {toggle, isShown} = useModal();
    const staffList = useContext(StaffListContext);
    const [assignedStaff, setAssignedStaff] = useState<StaffLinkAddedDetails[]>([]);

    useEffect(() => {
        const availableStaff = getAssignedStaffWithEmailList(entry, staffList);
        setAssignedStaff(availableStaff);
    }, [entry, staffList]);
    return (
        <React.Fragment>
            {assignedStaff.length > 0 && (
                <React.Fragment>
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue="Staff Details"
                        onClick={toggle}
                        colour={ButtonColourOptions.DarkBlue}
                        className={"mr-3"}
                        roundedCorner
                    />
                    <Modal
                        isShown={isShown}
                        onClose={toggle}
                        title={"Staff Details"}
                        modalSize={"md"}
                        bodyChildren={
                            <React.Fragment>
                                <PulseTable
                                    items={toTableRow(assignedStaff)}
                                    headers={{
                                        staffName: "Staff Name",
                                        email: "Email"
                                    }}
                                    noItemsSection={
                                        <NoItemsMessage
                                            message={
                                                "There are no assigned staff members to this shift"
                                            }
                                        />
                                    }
                                />
                            </React.Fragment>
                        }
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default CalendarEntryStaffDetails;

interface TableRow {
    staffName: string;
    email: string;
}

function toTableRow(assignedStaff: StaffLinkAddedDetails[]): TableRow[] {
    return assignedStaff.map((staff) => {
        return {
            staffName: staff.staffName,
            email: staff.email || ""
        };
    });
}
