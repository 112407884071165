import {useEffect, useState} from "react";
import moment, {Moment} from "moment";
import {CalendarEntryListRequest, CalendarGroup} from "../../../../../../../api/grs";
import {useQuery} from "../../../../../../Hooks/useQuery";
import {GrsChangeEvent} from "../../../../../../../utils/filterUtils";
import {formatUnixToDDMMYYYY} from "../../../../../../../utils/momentUtils";
import {useBaseCalendarFilters} from "../../../../Hooks/useBaseCalendarFilters";
import {CalendarEntryListRequestWithViewType} from "../../GlobalCalendarDesktop";
import {useDispatch} from "react-redux";
import {setCalendarGroup} from "../../../../../../../store/calendarGroup/actions/CalendarGroupActions";

export function useGlobalCalendarWeekFilters({
    onChange
}: GrsChangeEvent<CalendarEntryListRequestWithViewType>) {
    const {getIdsFromQueryString, buildRequestUrl} = useBaseCalendarFilters();
    const [currentCalendarGroup, setCurrentCalendarGroup] = useState<CalendarGroup>();
    const [calendarIds, setCalendarIds] = useState<number[]>();
    const [startDate, setStartDate] = useState<Moment>();
    const [groupId, setGroupId] = useState<number>();
    const query = useQuery();
    const dispatch = useDispatch();

    /** Upon mounting, see if we can get query string exists, if so, set date from this. */
    useEffect(() => {
        const cIds = query.get("calendarIds");
        setCalendarIds(cIds ? getIdsFromQueryString(cIds) : undefined);
        const week = query.get("week");
        const group = query.get("calendarGroup");
        setGroupId(group ? +group : undefined);
        if (week) {
            setStartDate(moment.unix(+week));
            return;
        }
        setStartDate(moment());
    }, []);

    /** Selected when the date changes */
    const onDateChanged = (date: Date | null) => {
        if (!date) return;

        setStartDate(moment(date));
    };

    function onGroupChanged(newGroup: CalendarGroup | undefined) {
        setCurrentCalendarGroup(newGroup);
        setCalendarIds(newGroup?.calendarIds);
        dispatch(setCalendarGroup(newGroup));
    }

    /** Watches for changes to start date */
    useEffect(() => {
        if (!startDate) return;
        const newReq = buildCalendarEntryRequest(startDate, calendarIds);
        onChange(newReq);

        buildRequestUrl(newReq, currentCalendarGroup, true);
    }, [startDate, currentCalendarGroup, calendarIds]);

    /** Builds request based on the calendar, if there is no calendar, we are looking at a staff calendar */
    const buildCalendarEntryRequest = (
        start: Moment,
        incCalendarIds: number[] | undefined
    ): CalendarEntryListRequest => {
        const startOfMonth = start.clone().startOf("isoWeek");
        const endOfMonth = start.clone().endOf("isoWeek");

        return {
            startDate: startOfMonth.clone().subtract(1, "days").unix(),
            endDate: endOfMonth.clone().add(1, "days").unix(),
            additionalCalendarIds: incCalendarIds
        };
    };

    function goToPreviousWeek() {
        if (!startDate) return;
        const previousWeek = startDate.clone().subtract(1, "week");
        setStartDate(previousWeek);
    }

    function goToNextWeek() {
        if (!startDate) return;
        const nextWeek = startDate.clone().add(1, "week");
        setStartDate(nextWeek);
    }

    function previousWeekButtonText() {
        if (!startDate) return "";
        const unixTime = startDate.clone().subtract(1, "week").startOf("isoWeek").unix();
        return `Previous (${formatUnixToDDMMYYYY(unixTime)})`;
    }

    function nextWeekButtonText() {
        if (!startDate) return "";
        const unixTime = startDate.clone().add(1, "week").startOf("isoWeek").unix();
        return `Next (${formatUnixToDDMMYYYY(unixTime)})`;
    }

    return {
        startDate,
        groupId,
        onDateChanged,
        onGroupChanged,
        goToPreviousWeek,
        goToNextWeek,
        previousWeekButtonText,
        nextWeekButtonText
    };
}
