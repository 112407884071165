import GrsApiModel from "../../../../../store/apiModel/GrsApiModel";
import {showErrorToast, showSuccessToast} from "../../../../../utils/toastUtils";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../../../Navigation/routeNames";

export function useDisconnectConfirmation() {
    const history = useHistory();
    async function onDisconnect() {
        const success = await disconnect();

        if (!success) return;

        showSuccessToast("Successfully disconnected from Xero");

        history.replace(routeNames.xeroLogin.path);
    }
    async function disconnect(): Promise<boolean> {
        try {
            const response = await GrsApiModel.getInvoiceApi().xeroDisconnect();

            return response.status === 200;
        } catch (e: any) {
            showErrorToast("Could not disconnect from Xero, Please contact system support");
            return false;
        }
    }

    return {
        onDisconnect
    };
}
