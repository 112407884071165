import React, {useEffect, useState} from "react";
import {
    DDProps,
    getSelectedDropdownOption,
    sortAssignedStaffInEntryToDropdownProps,
    sortUserDataToDropdownProps,
    StaffDropdownProps
} from "../Helpers/dropdownUtils";
import {UserData} from "../../../api/staff";
import {useDispatch, useSelector} from "react-redux";
import {
    getAllMedicareStaffMembers,
    getStaffMembersByAccessLevel,
    nullifyStaffListStore
} from "../../../store/staffList/actions/StaffListActions";
import Select, {SingleValue} from "react-select";
import {RootStore} from "../../../store/Store";
import {Loading} from "store-fetch-wrappers/dist";

/** Dropdown that will show all users assigned in an entry or all users in the database. */
const StaffDropdown = (props: StaffDropdownProps) => {
    const [staffList, setStaffList] = useState<UserData[]>([]);
    const [staffOptions, setStaffOptions] = useState<DDProps[]>([]);
    const [selectedOption, setSelectedOption] = useState<DDProps>({value: "", label: ""});

    const calendarEntryStore = useSelector((state: RootStore) => state.calendarEntry);
    const staffListStore = useSelector((state: RootStore) => state.staffList);
    const dispatch = useDispatch();

    /** Get the staff list upon mounting */
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useEffect(() => {
        (async function getStaffList() {
            let staff: UserData[] = [];

            if (props.accessLevelFilter) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                staff = await dispatch(getStaffMembersByAccessLevel(props.accessLevelFilter));
            } else {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                staff = await dispatch(getAllMedicareStaffMembers());
            }

            if (!staff) {
                setStaffList([]);
                return;
            }
            setStaffList(staff);
        })();

        return async function () {
            dispatch(nullifyStaffListStore());
        };
    }, []);

    /** Sort the staff list into dropdown props */
    useEffect(() => {
        if (staffList.length === 0) return;
        const options = sortUserDataToDropdownProps(staffList);
        setStaffOptions(options);

        //Treat it as an optional field if it is clearable. We let the code generate options before we do anything.
        if (props.clearable) {
            setSelectedOption({
                value: "",
                label: "Select Staff Member"
            });
            props.changeOption({staffId: "", staffName: ""});
            return;
        }

        const option = getSelectedDropdownOption(props.id, options);
        setSelectedOption(option);
        props.changeOption({staffId: option.value.toString(), staffName: option.label});
    }, [staffList]);

    /** If an entry is passed in as props. We get the assigned staff in the entry and present that as the options */
    useEffect(() => {
        if (!props.entry) {
            props.changeOption({staffId: "", staffName: ""});
            return;
        }

        const options = sortAssignedStaffInEntryToDropdownProps(props.entry);
        setStaffOptions(options);

        //Treat it as an optional field if it is clearable. We let the code generate options before we do anything.
        if (props.clearable) {
            setSelectedOption({
                value: "",
                label: "Select Staff Member"
            });
            props.changeOption({staffId: "", staffName: ""});
            return;
        }

        const option = getSelectedDropdownOption(props.id, options);
        if (!option) {
            props.changeOption({staffId: "", staffName: ""});
            return;
        }
        setSelectedOption(option);
        props.changeOption({staffId: option.value.toString(), staffName: option.label});
    }, [props.entry]);

    /** Fired when a new option is selected */
    const handleStaffChange = (p: SingleValue<DDProps>) => {
        if (!p) {
            props.changeOption({staffId: "", staffName: ""});
            setSelectedOption({
                value: "",
                label: "Select Staff Member"
            });
            return;
        }

        //If the staff dropdown is using the entry to get the staff members
        if (props.entry) {
            const option = getSelectedDropdownOption(p.value, staffOptions);
            if (!option) {
                props.changeOption({staffId: "", staffName: ""});
                return;
            }
            setSelectedOption(option);
            props.changeOption({staffId: option.value.toString(), staffName: option.label});
            return;
        }
        //Using the data from the staffList
        const option = getSelectedDropdownOption(p.value, staffOptions);

        setSelectedOption(option);
        props.changeOption({staffId: option.value.toString(), staffName: option.label});
    };

    return (
        <React.Fragment>
            {props.entry ? (
                <React.Fragment>
                    {calendarEntryStore.loading ? (
                        <Loading type={"three-ring"} showLoadingText={false} />
                    ) : (
                        <Select
                            className="search-bar"
                            classNamePrefix="select-options"
                            options={staffOptions}
                            onChange={handleStaffChange}
                            isSearchable={props.searchable}
                            value={selectedOption}
                            noOptionsMessage={() => "No Staff Members Found"}
                            placeholder="Select Staff Member"
                            isDisabled={props.disabled}
                            isClearable={props.clearable}
                            menuPortalTarget={document.body}
                        />
                    )}
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {staffListStore.loading ? (
                        <Loading type={"three-ring"} showLoadingText={false} />
                    ) : (
                        <Select
                            className="search-bar"
                            classNamePrefix="select-options"
                            options={staffOptions}
                            onChange={handleStaffChange}
                            isSearchable={props.searchable}
                            value={selectedOption}
                            noOptionsMessage={() => "No Staff Members Found"}
                            placeholder="Select Staff Member"
                            isDisabled={props.disabled}
                            isClearable={props.clearable}
                            menuPortalTarget={document.body}
                        />
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default StaffDropdown;
