import React from "react";
import {formatUnixToCustomFormat} from "../../../../../../utils/momentUtils";
import {CalendarEventItem} from "../../../../../../store/fullCalendarItemList/actions/FullCalendarItemListActionTypes";
import moment from "moment";
import {useMobileEventListItem} from "../Hooks/useMobileEventListItem";
import {DateRange} from "../../../../../../utils/filterUtils";
import {Modal, useModal} from "pulse-modal";
import {AvailableRolesContainer} from "./AvailableRoles/AvailableRolesContainer";
import {AvailableShiftCalendarEntry, CalendarEntry} from "../../../../../../api/grs";

function MobileEventListItem({
    calendarEventItem,
    dateRange,
    rawEntry,
    isAvailableShiftsItem
}: MobileEventListItemProps) {
    const {
        getAssignmentGroupName,
        getTimeText,
        getLatestShifts,
        getAvailableGroupSpaceCount,
        onStaffCalendarItemClicked,
        getDateText
    } = useMobileEventListItem({
        calendarEventItem,
        dateRange,
        rawEntry,
        isAvailableShiftsItem
    });
    const {toggle, isShown} = useModal();

    return (
        <React.Fragment>
            <div
                className="mobile-calendar-event-item"
                onClick={() => {
                    // Looking at My Shifts Tab
                    if (!isAvailableShiftsItem) {
                        onStaffCalendarItemClicked();
                        return;
                    }
                    toggle();
                }}
            >
                <div className="mobile-calendar-event-item-date-wrapper">
                    <div className="mobile-calendar-event-item-day">
                        {formatUnixToCustomFormat(moment(calendarEventItem.start).unix(), "ddd")}
                    </div>
                    <div className="mobile-calendar-event-item-date">
                        {getDateText(calendarEventItem.start, calendarEventItem.end)}
                    </div>
                </div>
                <div className="mobile-calendar-event-item-details">
                    <div className="mobile-calendar-event-item-detail-item-header">
                        {getTimeText()}
                    </div>
                    <div className="mobile-calendar-event-item-detail-item-body">
                        {calendarEventItem.title}
                    </div>
                    {isAvailableShiftsItem
                        ? getAvailableGroupSpaceCount()
                        : getAssignmentGroupName()}
                </div>
            </div>
            {isAvailableShiftsItem && (
                <Modal
                    modalSize={"sm"}
                    title={"Available Shifts"}
                    bodyChildren={
                        <AvailableRolesContainer
                            callback={() => {
                                getLatestShifts(dateRange);
                                toggle();
                            }}
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            entry={rawEntry}
                        />
                    }
                    onClose={toggle}
                    isShown={isShown}
                />
            )}
        </React.Fragment>
    );
}

export default MobileEventListItem;

export interface MobileEventListItemProps {
    isAvailableShiftsItem?: boolean;
    rawEntry: CalendarEntry | AvailableShiftCalendarEntry;
    calendarEventItem: CalendarEventItem;
    dateRange: DateRange;
}
