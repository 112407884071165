import React from "react";
import {CalendarSummaryStats} from "../../../../../api/grs";
import {useHistory} from "react-router-dom";
import FormHeaderWithAction from "../../../../Form/FormHeaderWithAction";
import {IconType} from "../../../../Icon/Icon";
import EventCalendarNextEventStats from "./EventCalendarNextEventStats";
import EventCalendarFutureEventStats from "./EventCalendarFutureEventStats";
import FormActionContainer from "../../../../Form/FormActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../Button/MCButton";
import {useCalendarDashActions} from "../../Tiles/Hooks/useCalendarDashActions";

const EventCalendarCard = (props: CalendarSummaryStats) => {
    const history = useHistory();
    const {editCalendar, goToCalendar} = useCalendarDashActions();

    return (
        <React.Fragment>
            <div className="event-calendar-card">
                <FormHeaderWithAction
                    headerType={"h6"}
                    tooltipText={`Edit ${props.summary.name}`}
                    showIcon={true}
                    icon={IconType.Edit}
                    onClick={() => {
                        const {id, name} = props.summary;
                        editCalendar(id, name);
                    }}
                    headerName={`${props.summary.name}`}
                />
                {props.summary.archived ? (
                    <React.Fragment>
                        <div className="row ml-0 mr-0">
                            <div className="col">
                                <h5 className="mt-2 text-center">Archived</h5>
                                <p className="text-center">
                                    {props.summary.name} has been archived. All historical data has
                                    been retained. Click the Access Calendar button to view historic
                                    events.
                                </p>
                                <p className="text-center">
                                    If {props.summary.name} need to be unarchived, this can be
                                    achieved by editing the calendar by clicking the pencil icon
                                    above.
                                </p>
                            </div>
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="row ml-0 mr-0">
                            <div className="col">
                                <EventCalendarNextEventStats
                                    nextDayStats={props.nextDayStats || []}
                                />
                            </div>
                        </div>
                        <div className="row-splitter" />
                        <div className="row ml-0 mr-0">
                            <div className="col">
                                <EventCalendarFutureEventStats
                                    monthStats={props.futureMonthStats}
                                    barLimit={5}
                                />
                            </div>
                        </div>
                        <div className="row-splitter" />
                    </React.Fragment>
                )}

                <FormActionContainer>
                    <div className="col d-flex justify-content-center">
                        <MCButton
                            size={ButtonSize.ExtraLarge}
                            innerValue={"Access Calendar"}
                            onClick={() => {
                                const {id, name, calendarType} = props.summary;

                                goToCalendar(id, name, calendarType);
                            }}
                            colour={ButtonColourOptions.DarkBlue}
                        />
                    </div>
                </FormActionContainer>
            </div>
        </React.Fragment>
    );
};

export default EventCalendarCard;
