import React, {Fragment, useState} from "react";
import {Modal, useModal} from "pulse-modal";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../Button/MCButton";
import {useDispatch} from "react-redux";
import {deleteUserInterest} from "../../../../../store/notifications/registerInterest/actions/RegisterInterestActions";
import {useShiftOverviewRequestBuilder} from "../../Hooks/useShiftOverviewRequestBuilder";
import {getStaffInterestForDates} from "../../../../../store/staffInterestQuery/actions/StaffInterestQueryResponseActions";
import {Loading} from "store-fetch-wrappers";
import {showSuccessToast} from "../../../../../utils/toastUtils";
import {UserInterest} from "../../../../../api/grs";

function UnregisterInterest(props: Props) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const {isShown, toggle} = useModal();
    const {getStaffInterestQueryRequest} = useShiftOverviewRequestBuilder();
    return (
        <Fragment>
            {loading ? (
                <Loading type={"three-ring"} showLoadingText={false} />
            ) : (
                <MCButton
                    size={ButtonSize.Large}
                    roundedCorner
                    innerValue={"Unregister"}
                    onClick={toggle}
                    colour={ButtonColourOptions.Red}
                />
            )}

            <Modal
                modalSize={"sm"}
                title={"Unregister"}
                bodyChildren={
                    <React.Fragment>
                        <div className="row ml-0 mr-0">
                            Are you sure you want to unregister? You will have to re-register for
                            this event in the future
                        </div>
                    </React.Fragment>
                }
                footerChildren={
                    <React.Fragment>
                        <div className="row ml-0 mr-0">
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"Yes"}
                                onClick={async () => {
                                    setLoading(true);
                                    const {user, entryId} = props.interest;
                                    // @ts-ignore
                                    const success: boolean = await dispatch(
                                        deleteUserInterest({
                                            staffId: user.staffId,
                                            entryId
                                        })
                                    );
                                    if (!success) {
                                        setLoading(false);
                                        return;
                                    }

                                    //If we unregister on the available
                                    if (props.location === "available-shifts-calendar") {
                                        if (!props.onSuccessfulUnregister)
                                            throw Error(
                                                "If this location is set, the onSuccessfulUnregister prop must be included."
                                            );

                                        props.onSuccessfulUnregister();
                                        showSuccessToast("Successfully unregistered from shift.");
                                        setLoading(false);
                                        return;
                                    }

                                    const {request} = getStaffInterestQueryRequest();
                                    dispatch(getStaffInterestForDates(request));

                                    setLoading(false);
                                    showSuccessToast("Successfully unregistered from shift.");
                                    toggle();
                                }}
                                colour={ButtonColourOptions.Yellow}
                                roundedCorner
                            />
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"Cancel"}
                                onClick={toggle}
                                roundedCorner
                                colour={ButtonColourOptions.DarkBlue}
                            />
                        </div>
                    </React.Fragment>
                }
                onClose={toggle}
                isShown={isShown}
            />
        </Fragment>
    );
}

export default UnregisterInterest;

interface Props {
    interest: UserInterest;
    location: "available-shifts-calendar" | "shift-interest-list";
    onSuccessfulUnregister?: () => void;
}
