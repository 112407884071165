import React, {useEffect} from "react";
import {CalendarGroup} from "../../../../../api/grs";
import FormHeader from "../../../../Form/FormHeader";
import {useCalendarGroupForm} from "../Hooks/useCalendarGroupForm";
import FormRow from "../../../../Form/FormRow";
import {DebounceInput} from "react-debounce-input";
import CalendarSummaryFilters from "./CalendarSummaryFilters";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../Button/MCButton";
import FormActionContainer from "../../../../Form/FormActionContainer";
import {showSuccessToast} from "../../../../../utils/toastUtils";

function EditCalendarGroupForm(props: CalendarGroup) {
    const {updateForm, backToCalendarGroupList, save, nullifyStore} = useCalendarGroupForm();

    useEffect(() => {
        return function () {
            nullifyStore();
        };
    }, []);

    return (
        <React.Fragment>
            <FormHeader headerName={"Calendar Group Form"} />
            <FormRow rowName={"Calendar Group Name"} columnDetailClassName={"pl-0 pr-0"}>
                <DebounceInput
                    debounceTimeout={300}
                    value={props.name}
                    onChange={(event) => {
                        const {value} = event.target;
                        updateForm({
                            ...props,
                            name: value
                        });
                    }}
                    className="input-fields"
                    placeholder="Enter calendar group name here"
                    required={true}
                />
            </FormRow>
            <FormRow rowName={"Applicable Calendars"} columnDetailClassName={"pl-0 pr-0"}>
                <CalendarSummaryFilters
                    calendarIds={props.calendarIds}
                    onChange={(calendarIds, viewType) => {
                        updateForm({
                            ...props,
                            calendarIds,
                            viewType
                        });
                    }}
                />
            </FormRow>
            <FormActionContainer>
                <MCButton
                    size={ButtonSize.Large}
                    innerValue="Save"
                    colour={ButtonColourOptions.Yellow}
                    onClick={async () => {
                        const success = await save(props);

                        if (!success) return;

                        showSuccessToast(`Saved ${props.name} Calendar Group`);

                        backToCalendarGroupList();
                    }}
                    roundedCorner
                />
                <MCButton
                    size={ButtonSize.Large}
                    innerValue="Cancel"
                    colour={ButtonColourOptions.DarkBlue}
                    onClick={backToCalendarGroupList}
                    roundedCorner
                />
            </FormActionContainer>
        </React.Fragment>
    );
}

export default EditCalendarGroupForm;
