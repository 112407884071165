import React, {useEffect} from "react";
import {setNavigationItems} from "../../../../store/navigation/actions/NavigationActions";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../store/Store";

import EditGlobalStaffBlockForm from "./Components/EditGlobalStaffBlockForm";
import {WithServiceState} from "store-fetch-wrappers/dist";
import {
    getGlobalStaffBlockFromServiceWithRedux,
    nullifyGlobalStaffBlockStore
} from "../../../../store/globalStaffBlock/actions/GlobalStaffBlockActions";
import {useParams} from "react-router-dom";
import {useXeroRoutes} from "../../Xero/Hooks/useXeroRoutes";
const ServiceWrapper = WithServiceState(EditGlobalStaffBlockForm);

const EditGlobalStaffBlock = () => {
    const dispatch = useDispatch();

    const globalStaffBlockStore = useSelector((state: RootStore) => state.globalStaffBlock);
    const params: any = useParams();
    const {getSystemAdminRoutes} = useXeroRoutes();

    useEffect(() => {
        window.scrollTo({top: 0});
        dispatch(setNavigationItems(getSystemAdminRoutes()));

        dispatch(getGlobalStaffBlockFromServiceWithRedux(+params.staffBlockId));

        return function () {
            dispatch(nullifyGlobalStaffBlockStore());
        };
    }, []);
    return (
        <React.Fragment>
            <div className="page-container mt-4">
                <ServiceWrapper
                    showLoadingText={true}
                    loaderWheelType={"three-ring"}
                    loaderType={"overlay"}
                    placeholderBlockCount={1}
                    {...globalStaffBlockStore}
                />
            </div>
        </React.Fragment>
    );
};

export default EditGlobalStaffBlock;
