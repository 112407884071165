import React from "react";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import {Modal, useModal} from "pulse-modal";
import Icon, {IconType} from "../../../Icon/Icon";
import {useDisconnectConfirmation} from "./Hooks/useDisconnectConfirmation";

function DisconnectConfirmation({message}: Props) {
    const {isShown, toggle} = useModal();
    const {onDisconnect} = useDisconnectConfirmation();

    return (
        <React.Fragment>
            <button className="xero-disconnect-button" onClick={toggle}>
                <Icon rootElement={"Span"} className="mr-3" icon={IconType.Logout} size={"Small"} />
                Disconnect
            </button>
            <Modal
                isShown={isShown}
                onClose={toggle}
                title={"Disconnect"}
                modalSize={"sm"}
                bodyChildren={
                    <React.Fragment>
                        <div className="row ml-0 mr-0">
                            <p>{message}</p>
                        </div>
                    </React.Fragment>
                }
                footerChildren={
                    <React.Fragment>
                        <div className="row mt-3 ml-0 mr-0">
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"Yes"}
                                onClick={async () => {
                                    await onDisconnect();
                                    toggle();
                                }}
                                colour={ButtonColourOptions.Yellow}
                                roundedCorner
                            />
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"No"}
                                onClick={toggle}
                                colour={ButtonColourOptions.DarkBlue}
                                roundedCorner
                            />
                        </div>
                    </React.Fragment>
                }
            />
        </React.Fragment>
    );
}

export default DisconnectConfirmation;

interface Props {
    message: string;
}
