import React, {useEffect} from "react";
import {CalendarEntry} from "../../../../../../api/grs";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../../../store/Store";
import {
    fetchVenueById,
    nullifyVenueStore
} from "../../../../../../store/venue/actions/VenueActions";
import FormRow from "../../../../../Form/FormRow";
import {Loading} from "store-fetch-wrappers";

const ReadOnlyEntryVenue = (props: CalendarEntry) => {
    const dispatch = useDispatch();
    const venueStore = useSelector((state: RootStore) => state.venue);

    useEffect(() => {
        return function () {
            dispatch(nullifyVenueStore());
        };
    }, []);

    useEffect(() => {
        if (!props.venueId) return;
        dispatch(fetchVenueById(props.venueId));
    }, [props]);
    return (
        <React.Fragment>
            <FormRow rowName={"Venue"}>
                {venueStore.loading ? (
                    <Loading type={"three-ring"} showLoadingText={false} />
                ) : (
                    <p className="mb-0">{venueStore.data?.name}</p>
                )}
            </FormRow>
        </React.Fragment>
    );
};

export default ReadOnlyEntryVenue;
