import {Dispatch} from "redux";
import {
    CALENDAR_GROUP_LIST_STORE,
    CalendarGroupListDispatchTypes
} from "./CalendarGroupListActionTypes";
import {getDataFromServiceWithRedux} from "store-fetch-wrappers";
import GrsApiModel from "../../apiModel/GrsApiModel";
import {statusCodeCallback} from "../../helpers/storeHelpers";
import store from "../../Store";
import {fetchCalendarSummaryStats} from "../../calendarSummaryStats/actions/CalendarSummaryStatsActions";

export const nullifyCalendarGroupListStore = () => {
    return async (dispatch: Dispatch<CalendarGroupListDispatchTypes>) => {
        dispatch({
            type: CALENDAR_GROUP_LIST_STORE.SUCCESS,
            error: null,
            loading: false,
            data: []
        });
    };
};

export function getCalendarGroupList() {
    return async (dispatch: Dispatch<CalendarGroupListDispatchTypes>) => {
        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await store.dispatch(fetchCalendarSummaryStats());
            return await getDataFromServiceWithRedux(
                CALENDAR_GROUP_LIST_STORE,
                dispatch,
                () => GrsApiModel.getCalendarApi().listCalendarGroups(),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: CALENDAR_GROUP_LIST_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
}
