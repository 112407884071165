import {Dispatch} from "redux";
import {ATTENDED_STORE, AttendanceDispatchTypes} from "./AttendedActionTypes";
import GrsApiModel from "../../../apiModel/GrsApiModel";
import {MarkAttendedRequest} from "../../../../api/grs";
import {postDataToServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../../helpers/storeHelpers";

export const nullifyAttendedActionStore = () => {
    return async (dispatch: Dispatch<AttendanceDispatchTypes>) => {
        dispatch({
            type: ATTENDED_STORE.SUCCESS,
            data: null,
            error: null,
            loading: false
        });
    };
};

export const saveStaffAttendedToService = (request: MarkAttendedRequest) => {
    return async (dispatch: Dispatch<AttendanceDispatchTypes>) => {
        try {
            return await postDataToServiceWithRedux(
                ATTENDED_STORE,
                dispatch,
                () => GrsApiModel.getStaffApi().setStaffAttended(request),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: ATTENDED_STORE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};
