import {Dispatch} from "redux";
import {
    CALENDAR_COMPLIANCE_STORE,
    CalendarComplianceDispatchTypes
} from "./CalendarComplianceActionTypes";
import {defaultCalendarComplianceState} from "../reducer/CalendarComplianceReducer";
import {
    CalendarCompliance,
    CalendarComplianceLevel,
    CalendarComplianceRequest,
    CalendarComplianceResponse,
    CalendarTypeEnum
} from "../../../api/grs";
import moment from "moment";
import GrsApiModel from "../../apiModel/GrsApiModel";
import {postDataToServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";
import {getRandomNumber, isEven} from "../../../utils/mathUtils";

export const nullifyCalendarComplianceStore = () => {
    return async (dispatch: Dispatch<CalendarComplianceDispatchTypes>) => {
        dispatch({
            ...defaultCalendarComplianceState,
            type: CALENDAR_COMPLIANCE_STORE.SUCCESS
        });
    };
};

export const getCalendarCompliance = () => {
    return async (dispatch: Dispatch<CalendarComplianceDispatchTypes>) => {
        const now = moment().startOf("day");
        const request: CalendarComplianceRequest = {
            startDateInclusive: now.clone().unix(),
            endDateExclusive: now.clone().add(3, "day").unix()
        };
        try {
            return await postDataToServiceWithRedux(
                CALENDAR_COMPLIANCE_STORE,
                dispatch,
                () => GrsApiModel.getCalendarApi().listCalendarCompliance(request),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: CALENDAR_COMPLIANCE_STORE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};

/** DEVELOPMENT ONLY */
export const getCalendarCompliance_LOCAL = () => {
    return async (dispatch: Dispatch<CalendarComplianceDispatchTypes>) => {
        dispatch({
            ...defaultCalendarComplianceState,
            type: CALENDAR_COMPLIANCE_STORE.SUCCESS,
            data: getCalendarComplianceDevResponse()
        });
    };
};

function getCalendarComplianceDevResponse(): CalendarComplianceResponse {
    return {
        endDateExclusive: 0,
        startDateInclusive: 0,
        compliance: generateCalendarCompliance()
    };
}

function generateCalendarCompliance(): CalendarCompliance[] {
    return calendarNames.map((name, index) => {
        return {
            id: index + 1,
            name,
            type: getCalendarType(index),
            archived: !isEven(index),
            compliant: dates.map((date) => {
                return {
                    date,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    compliant: getCalendarComplianceLevel(getRandomNumber(0, 2))
                };
            })
        };
    });
}

function getCalendarType(index: number) {
    switch (index) {
        case 0:
            return CalendarTypeEnum.Frontline;
        case 1:
            return CalendarTypeEnum.Event;
        default:
            return CalendarTypeEnum.Event;
    }
}

function getCalendarComplianceLevel(index: number) {
    switch (index) {
        case 0:
            return CalendarComplianceLevel.Compliant;
        case 1:
            return CalendarComplianceLevel.NotCompliant;
        case 2:
            return CalendarComplianceLevel.NotApplicable;
        default:
            return CalendarComplianceLevel.NotApplicable;
    }
}

const dates = [
    1687132800, // June 19th 2023
    1687219200, // June 20th 2023
    1687305600 // June 21st 2023
];

const calendarNames = [
    "Ambulance Fleet Services",
    "Chelsea Football Club",
    "EEAST Basildon A&E Operations",
    "EEAST Chelmsford A&E Operations",
    "EEAST Colchester A&E Operations",
    "EEAST Ipswitch A&E Operations",
    "EEAST Kings Lynn A&E Operations",
    "EEAST Norwich A&E Operations",
    "EEAST Southend A&E Operations",
    "Emirates Stadium",
    "LAS Frontline Operations",
    "Manchester AO Arena",
    "Racecourse Operations",
    "SCAS Aylesbury A&E Operations",
    "SCAS Newbury A&E Operations",
    "The 02 Arena",
    "Tottenham Hotspur"
];
