import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {
    CalendarToRender,
    GLOBAL_CALENDAR_STORE,
    GlobalCalendarStore
} from "../actions/GlobalCalendarActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<GlobalCalendarStore>(defaultGlobalCalendarStore());

const globalCalendarReducer = (
    state: StoreServiceData<GlobalCalendarStore> = defaultState,
    action: ServiceActionTypes<GlobalCalendarStore>
) => createReducer(state, action, GLOBAL_CALENDAR_STORE, () => showErrorToast(action.error));

export default globalCalendarReducer;

export function defaultGlobalCalendarStore(): GlobalCalendarStore {
    return {
        calendarToShow: CalendarToRender.Pending,
        results: [],
        entryListResponse: {
            calendars: [],
            entries: [],
            endDate: 0,
            startDate: 0
        }
    };
}
