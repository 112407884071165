import React from "react";
import {EventCalendarEntry, FrontlineCalendarEntry} from "../../../../api/grs";
import {useDispatch, useSelector} from "react-redux";
import CalendarEntryGeneralDetails from "./Admin/General/CalendarEntryGeneralDetails";
import CalendarEntryDetails from "./Admin/EventDetails/CalendarEntryDetails";
import CalendarEntryStaffManagement from "./Admin/StaffManagement/CalendarEntryStaffManagement";
import BackgroundContainer from "../../../Containers/BackgroundContainer";
import CalendarEntryAdminActions from "./Admin/Actions/CalendarEntryAdminActions";
import {CalendarEntryContext} from "../../../Context/CalendarEntryContext";
import {RootStore} from "../../../../store/Store";
import {StaffListContext} from "../../../Context/StaffListContext";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import {useHistory, useParams} from "react-router-dom";
import {useQuery} from "../../../Hooks/useQuery";
import {routeNames} from "../../../Navigation/routeNames";
import {saveCalendarEntry} from "../../../../store/calendarEntry/actions/CalendarEntryActions";
import FormHeader from "../../../Form/FormHeader";
import CalendarEntryStats from "./Stats/CalendarEntryStats";
import {useIsEntryHistoric} from "../../../Hooks/useIsEntryHistoric";
import DeleteCalendarEntry from "./Admin/Actions/Components/DeleteCalendarEntry";
import {showSuccessToast} from "../../../../utils/toastUtils";
import RescheduleCalendarEntry from "./Admin/Actions/Components/RescheduleCalendarEntry";

const EditCalendarEntryForm = (props: EventCalendarEntry | FrontlineCalendarEntry) => {
    const dispatch = useDispatch();
    const staffListStore = useSelector((state: RootStore) => state.staffList);
    const history = useHistory();
    const query = useQuery();
    const params: any = useParams();
    const isHistoric = useIsEntryHistoric(props);

    const saveEntry = async () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(saveCalendarEntry(props));
        if (!success) {
            return;
        }
        showSuccessToast("Saved entry");
        goBackToLastScreen();
    };

    const goBackToLastScreen = () => {
        const previousLocation = localStorage.getItem("calendar_redirect");
        if (previousLocation) {
            history.replace(previousLocation);
            return;
        }

        const path = getCalendarPath(params.calendarName);
        history.replace(path);
    };

    /** Gets the path back to the calendar on fallback */
    const getCalendarPath = (calendarName: string) => {
        const calendarId = query.get("calendarId");
        const month = query.get("month");
        const week = query.get("week");

        if (month) {
            return `${routeNames.calendar.path}/${encodeURI(
                calendarName
            )}?id=${calendarId}&month=${month}`;
        }
        if (week) {
            return `${routeNames.calendar.path}/${encodeURI(
                calendarName
            )}?id=${calendarId}&week=${week}`;
        }

        return "";
    };
    return (
        <React.Fragment>
            <BackgroundContainer background={"background-stars"} pageContainerClass={"pt-4"}>
                <CalendarEntryContext.Provider value={props}>
                    {staffListStore.data && (
                        <StaffListContext.Provider value={staffListStore.data}>
                            <CalendarEntryGeneralDetails {...props} />
                            <CalendarEntryDetails {...props} />
                            <CalendarEntryStaffManagement {...props} />
                            <CalendarEntryAdminActions />
                            <FormHeader headerName={"Shift Actions"} />
                            <div className="row ml-0 mr-0 mt-3">
                                <MCButton
                                    size={ButtonSize.Large}
                                    innerValue="Save"
                                    onClick={saveEntry}
                                    colour={ButtonColourOptions.Yellow}
                                    className="mr-3"
                                    roundedCorner
                                />
                                <MCButton
                                    size={ButtonSize.Large}
                                    innerValue="Cancel"
                                    onClick={goBackToLastScreen}
                                    colour={ButtonColourOptions.DarkBlue}
                                    className="mr-3"
                                    roundedCorner
                                />
                                {props.id > 0 && !isHistoric && (
                                    <React.Fragment>
                                        <DeleteCalendarEntry onActionSuccess={goBackToLastScreen} />
                                        <RescheduleCalendarEntry />
                                    </React.Fragment>
                                )}
                            </div>
                        </StaffListContext.Provider>
                    )}
                </CalendarEntryContext.Provider>
                <CalendarEntryStats {...props} />
            </BackgroundContainer>
        </React.Fragment>
    );
};

export default EditCalendarEntryForm;
