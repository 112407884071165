import React, {useState} from "react";
import {AssignmentState} from "../../api/grs";
import GrsApiModel from "../../store/apiModel/GrsApiModel";
import {showErrorToast, showSuccessToast} from "../../utils/toastUtils";
import {Loading} from "store-fetch-wrappers";
import MCButton, {ButtonColourOptions, ButtonSize} from "../Button/MCButton";
import {useUserData} from "../Hooks/useUserData";
import AssignmentStateAmI from "./AssignmentStateAmI";

const ShiftActions = (props: ShiftActionProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const user = useUserData();

    const acceptAssignment = async () => {
        setLoading(true);

        try {
            setLoading(true);
            const request = await GrsApiModel.getStaffApi().acceptAssignment(props.entryId);
            if (request.status === 200) {
                await onCompletedRequest();
                setLoading(false);
                showSuccessToast("Accepted Assignment");
            }
        } catch (error: any) {
            handleAssignmentErrorMessages(error, props.entryId);
            setLoading(false);
        }
    };

    const rejectAssignment = async () => {
        try {
            setLoading(true);
            const request = await GrsApiModel.getStaffApi().rejectAssignment(props.entryId);
            if (request.status === 200) {
                await onCompletedRequest();
                setLoading(false);
                showSuccessToast("Rejected Assignment");
            }
        } catch (error: any) {
            handleAssignmentErrorMessages(error, props.entryId);
            setLoading(false);
        }
    };

    const acceptBriefing = async () => {
        try {
            setLoading(true);
            const request = await GrsApiModel.getStaffApi().acceptBriefing(props.entryId);
            if (request.status === 200) {
                await onCompletedRequest();
                setLoading(false);
                showSuccessToast("Accepted Briefing");
            }
        } catch (error: any) {
            handleAssignmentErrorMessages(error, props.entryId);
            setLoading(false);
        }
    };

    const onCompletedRequest = async () => {
        setLoading(false);
        props.callback();
    };

    return (
        <React.Fragment>
            {props.staffId === user.username ? (
                <React.Fragment>
                    {loading ? (
                        <Loading type={"three-ring"} showLoadingText={false} />
                    ) : (
                        <React.Fragment>
                            {props.state === AssignmentState.WaitingAccept && (
                                <React.Fragment>
                                    <div className="row mb-2 ml-0 mr-0">
                                        <MCButton
                                            size={ButtonSize.Large}
                                            innerValue={loading ? "Accepting..." : "Accept"}
                                            onClick={acceptAssignment}
                                            colour={ButtonColourOptions.Yellow}
                                            roundedCorner
                                        />
                                        <MCButton
                                            size={ButtonSize.Large}
                                            innerValue={loading ? "Rejecting..." : "Reject"}
                                            onClick={rejectAssignment}
                                            colour={ButtonColourOptions.DarkBlue}
                                            roundedCorner
                                        />
                                    </div>
                                </React.Fragment>
                            )}
                            {props.state === AssignmentState.Accepted && (
                                <React.Fragment>
                                    <div className="row mb-2 ml-0 mr-0">
                                        <MCButton
                                            size={ButtonSize.Large}
                                            innerValue={
                                                loading ? "Accepting..." : "Accept Briefing"
                                            }
                                            onClick={acceptBriefing}
                                            colour={ButtonColourOptions.Yellow}
                                            roundedCorner
                                        />
                                    </div>
                                </React.Fragment>
                            )}
                            <AssignmentStateAmI
                                currentAssignmentState={props.state}
                                assignmentStates={[
                                    AssignmentState.BriefingAccepted,
                                    AssignmentState.Attended
                                ]}
                            >
                                <p className="mt-2 mb-0">N/A</p>
                            </AssignmentStateAmI>
                        </React.Fragment>
                    )}
                </React.Fragment>
            ) : (
                <p className="mb-0">N/A</p>
            )}
        </React.Fragment>
    );
};

export default ShiftActions;

interface ShiftActionProps {
    state: AssignmentState;
    entryId: number;
    callback: () => void;
    staffId: string;
}

export function handleAssignmentErrorMessages(error: any, entryId: number) {
    const status = error.response.status;
    const serviceError = error.response.data.message;

    if (status === 400) {
        const message = `ERROR: ENTRY_ID: ${entryId} ERROR CODE: ${status} ERROR MESSAGE: ${
            serviceError ? serviceError : "NULL"
        }`;
        showErrorToast(message);
        return;
    }
    if (status === 406) {
        const message = `Assignment not present, entry not present, or assignment is not in a 'WaitingAccept' state: ENTRY_ID: ${entryId} ERROR CODE: ${status}`;
        showErrorToast(message);
        return;
    }

    showErrorToast(
        `Could not accept assignment! Contact a system admin for information: ENTRY_ID: ${entryId} ERROR CODE: ${status} ERROR MESSAGE: ${
            serviceError ? serviceError : "NULL"
        }`
    );
}
