import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {REPEAT_ASSIGNMENT_STORE} from "../actions/RepeatAssignmentActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<string>(null);

const repeatAssignmentReducer = (
    state: StoreServiceData<string> = defaultState,
    action: ServiceActionTypes<string>
) => createReducer(state, action, REPEAT_ASSIGNMENT_STORE, () => showErrorToast(action.error));

export default repeatAssignmentReducer;
