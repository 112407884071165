import React from "react";
import AssignedStaffMembersTable from "./Tables/AssignedStaffMembersTable";
import InterestedStaffMembersTable from "./Tables/InterestedStaffMembersTable";
import {AvailableShiftItemProps} from "./Admin/ShiftsOverviewListTable";

const AvailableShiftsListEntryTable = (props: AvailableShiftItemProps) => {
    return (
        <React.Fragment>
            <InterestedStaffMembersTable location={"shifts-list"} {...props} />
            <AssignedStaffMembersTable {...props} />
        </React.Fragment>
    );
};

export default AvailableShiftsListEntryTable;
