import React from "react";
import {EventCalendarEntry, FrontlineCalendarEntry} from "../../../../../../../api/grs";
import ReadOnlyEntryActivityState from "../ReadOnlyEntryActivityState";
import {isFrontlineCalendarEntry} from "../../../../../../../store/calendarEntry/actions/CalendarEntryActions";
import ReadOnlyEntryTimeDetails from "../ReadOnlyEntryTimeDetails";
import ReadOnlyEntryNotes from "../ReadOnlyEntryNotes";

const ReadOnlyFrontlineDetails = (props: EventCalendarEntry | FrontlineCalendarEntry) => {
    return (
        <React.Fragment>
            <ReadOnlyEntryActivityState {...props} />
            {isFrontlineCalendarEntry(props) && <ReadOnlyEntryTimeDetails {...props} />}
            <ReadOnlyEntryNotes {...props} />
        </React.Fragment>
    );
};

export default ReadOnlyFrontlineDetails;
