import React from "react";
import {CalendarComplianceDate, CalendarComplianceLevel} from "../../../../api/grs";
import {formatUnixToCustomFormat} from "../../../../utils/momentUtils";
import Icon, {IconType} from "../../../Icon/Icon";

function DayCompliance(props: CalendarComplianceDate) {
    function getComplianceClassName(): string {
        switch (props.compliant) {
            case CalendarComplianceLevel.Compliant:
                return "day-compliance-base compliant";
            case CalendarComplianceLevel.NotCompliant:
                return "day-compliance-base not-compliant";
            case CalendarComplianceLevel.PartialCompliant:
                return "day-compliance-base partial-compliant";
            default:
                return "day-compliance-base";
        }
    }

    function getIcon(): React.ReactNode {
        switch (props.compliant) {
            case CalendarComplianceLevel.Compliant:
                return (
                    <Icon
                        rootElement={"Span"}
                        icon={IconType.Tick}
                        size={"XX-Large"}
                        className="day-compliance-icon"
                    />
                );
            case CalendarComplianceLevel.NotCompliant:
                return (
                    <Icon
                        rootElement={"Span"}
                        icon={IconType.Close}
                        size={"XX-Large"}
                        className="day-compliance-icon"
                    />
                );
            case CalendarComplianceLevel.PartialCompliant:
                return (
                    <Icon
                        rootElement={"Span"}
                        icon={IconType.Warning}
                        size={"XXX-Large"}
                        className="day-compliance-icon"
                    />
                );
            default:
                return;
        }
    }

    return (
        <React.Fragment>
            <div className="tile-day-compliance">
                <div className="tile-day-compliance-date">
                    {formatUnixToCustomFormat(props.date, "MMM DD YYYY")}
                </div>
                <div className={getComplianceClassName()}>{getIcon()}</div>
            </div>
        </React.Fragment>
    );
}

export default DayCompliance;
