import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {CalendarGroup} from "../../../api/grs";

export const CALENDAR_GROUP_STORE = createStoreState("calendar_group");

interface CalendarGroupLoading extends StoreServiceData<CalendarGroup> {
    type: typeof CALENDAR_GROUP_STORE.LOADING;
}
interface CalendarGroupError extends StoreServiceData<CalendarGroup> {
    type: typeof CALENDAR_GROUP_STORE.ERROR;
}
interface CalendarGroupSuccess extends StoreServiceData<CalendarGroup> {
    type: typeof CALENDAR_GROUP_STORE.SUCCESS;
}

export type CalendarGroupDispatchTypes =
    | CalendarGroupLoading
    | CalendarGroupError
    | CalendarGroupSuccess;
