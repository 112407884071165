import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {REGISTER_INTEREST_STORE} from "../actions/RegisterInterestActionTypes";
import {showErrorToast} from "../../../../utils/toastUtils";

const defaultState = createDefaultStoreState<string>("");

const registerInterestReducer = (
    state: StoreServiceData<string> = defaultState,
    actions: ServiceActionTypes<string>
): StoreServiceData<string> =>
    createReducer(state, actions, REGISTER_INTEREST_STORE, () => showErrorToast(actions.error));

export default registerInterestReducer;
