import React from "react";
import EventCalendarTimes from "./EventCalendarTimes";
import {
    BillingType,
    CalendarTypeEnum,
    EventCalendarEntry,
    FrontlineCalendarEntry
} from "../../../../../../../api/grs";
import {useSelector} from "react-redux";
import {RootStore} from "../../../../../../../store/Store";
import CalendarEntryActivityState from "../CalendarEntryActivityState";
import {isEventCalendarEntry} from "../../../../../../../store/calendarEntry/actions/CalendarEntryActions";
import HourlyBilledTimeDetails from "../HourlyBilledTimeDetails";
import CalendarEntryNotes from "../CalendarEntryNotes";

/** Details for Calendar Entry */
const CalendarEntryEventDetails = (props: EventCalendarEntry | FrontlineCalendarEntry) => {
    const calendar = useSelector((state: RootStore) => state.calendar.data);

    const getBillingType = () => {
        if (props.billingType) {
            return props.billingType;
        }

        return calendar?.billingType || BillingType.Hourly;
    };
    return (
        <React.Fragment>
            <CalendarEntryActivityState {...props} />
            {getBillingType() === BillingType.Hourly && <HourlyBilledTimeDetails {...props} />}
            {/** Shows Venue and Depot time if the calendar is an Event Calendar. */}
            {props.calendarType === CalendarTypeEnum.Event && (
                <React.Fragment>
                    {isEventCalendarEntry(props) && <EventCalendarTimes {...props} />}
                </React.Fragment>
            )}
            <CalendarEntryNotes {...props} />
        </React.Fragment>
    );
};

export default CalendarEntryEventDetails;
