import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {DUTY_MANAGER_REPORT_LISTING_STORE_STATE} from "../actions/DutyManagerReportListingActionTypes";
import {DutyManagerReportListing} from "../../../api/grs";
import {showErrorToast} from "../../../utils/toastUtils";

/** Default state of the store. */
const defaultState = createDefaultStoreState<DutyManagerReportListing[]>([]);

/** Infer type of the reducer for code completion and quality */
const dutyManagerReportListingReducer = (
    state: StoreServiceData<DutyManagerReportListing[]> = defaultState,
    action: ServiceActionTypes<DutyManagerReportListing[]>
): StoreServiceData<DutyManagerReportListing[]> =>
    createReducer(state, action, DUTY_MANAGER_REPORT_LISTING_STORE_STATE, () =>
        showErrorToast(action.error)
    );

export default dutyManagerReportListingReducer;
