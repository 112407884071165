/** For the FullCalendar Plugin */
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {AssignmentState, BillingType, CalendarTypeEnum} from "../../../api/grs";
import {StaffAssignmentWithGroupName} from "../../../components/Pages/AdminCalendarEntry/Helpers/calenderEntryHelpers";

export interface CalendarEventItem {
    id: string;
    groupId: string;
    allDay: boolean;
    start: Date;
    end: Date;
    color: string;
    textColor: string;
    title: string;
    entryState: AssignmentState;
    assignments: StaffAssignmentWithGroupName[];
    timeText: string;
    calendarType: CalendarTypeEnum;
    isHistoric: boolean;
    billingType?: BillingType;
    depotTime?: number;
    venueTime?: number;
}

export const FULL_CALENDAR_LIST_ITEM_STORE = createStoreState("full_calendar_list_item");

interface FullCalendarListItemLoading extends StoreServiceData<CalendarEventItem[]> {
    type: typeof FULL_CALENDAR_LIST_ITEM_STORE.LOADING;
}
interface FullCalendarListItemError extends StoreServiceData<CalendarEventItem[]> {
    type: typeof FULL_CALENDAR_LIST_ITEM_STORE.LOADING;
}
interface FullCalendarListItemSuccess extends StoreServiceData<CalendarEventItem[]> {
    type: typeof FULL_CALENDAR_LIST_ITEM_STORE.LOADING;
}

export type FullCalendarListItemDispatchTypes =
    | FullCalendarListItemLoading
    | FullCalendarListItemError
    | FullCalendarListItemSuccess;
