import React from "react";
import FormRowWithTooltip from "../../Form/FormRowWithTooltip";
import {IconType} from "../../Icon/Icon";
import Toggle, {ToggleSize} from "../../Toggle/Toggle";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../store/Store";
import {setSMSSendingOption} from "../../../store/sms/actions/SMSActions";

const SMSSwitch = () => {
    const dispatch = useDispatch();
    const {sendToEventsStaff} = useSelector(
        (state: RootStore) =>
            state.sms.data || {staffMembers: [], bodyText: "", sendToEventsStaff: true}
    );

    function getRowName(): string {
        const prefix = "Message";
        return sendToEventsStaff ? `${prefix} Events Staff` : `${prefix} All Staff`;
    }

    function getTooltipText(): string {
        const eventStaffText =
            "Currently set to message staff assigned to this event. To switch to all staff, click the toggle below.";
        const allStaffText =
            "Currently set to message staff filtered by flags. To switch to events staff, click the toggle below.";

        return sendToEventsStaff ? eventStaffText : allStaffText;
    }
    return (
        <React.Fragment>
            <FormRowWithTooltip
                rowName={getRowName()}
                icon={IconType.Question}
                tooltipText={getTooltipText()}
            >
                <Toggle
                    checked={sendToEventsStaff}
                    onChange={() => {
                        let toggled = sendToEventsStaff;

                        dispatch(setSMSSendingOption((toggled = !toggled)));
                    }}
                    size={ToggleSize.Large}
                />
            </FormRowWithTooltip>
        </React.Fragment>
    );
};

export default SMSSwitch;
