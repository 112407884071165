import React, {useContext} from "react";
import {DutyManagerReportPayload} from "../../../../../../store/dutyManagerReport/actions/DutyManagerReportActionTypes";
import {useDispatch} from "react-redux";
import {setPayload} from "../../../../../../store/dutyManagerReport/actions/DutyManagerReportActions";
import {DutyManagerReportEditableContext} from "../Context/DutyManagerReportEditable";
import FormHeader from "../../../../../Form/FormHeader";
import FormRow from "../../../../../Form/FormRow";
import {DebounceInput} from "react-debounce-input";

const DutyManagerFormDetails = (props: DutyManagerReportPayload) => {
    const editable = useContext(DutyManagerReportEditableContext);
    const dispatch = useDispatch();

    const onEquipmentAndConsumablesAdded = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const payload: DutyManagerReportPayload = {
            ...props,
            equipmentAndConsumables: event.target.value
        };

        dispatch(setPayload(payload));
    };

    const onOperationalCommentsAdded = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const payload: DutyManagerReportPayload = {
            ...props,
            operationalComments: event.target.value
        };

        dispatch(setPayload(payload));
    };
    return (
        <React.Fragment>
            <FormHeader headerName={"Details"} />
            <FormRow
                rowName={"Damage and Defects"}
                columnDetailClassName={editable ? "pl-0 pr-0" : ""}
            >
                {editable ? (
                    <DebounceInput
                        className="input-fields text-area-inputs"
                        element={"textarea"}
                        value={props.damageAndDefects}
                        onChange={(event) => {
                            const {value} = event.target;
                            const payload: DutyManagerReportPayload = {
                                ...props,
                                damageAndDefects: value
                            };

                            dispatch(setPayload(payload));
                        }}
                        placeholder="Enter damage and defect details here"
                    />
                ) : (
                    <p className="mb-0 text-area-inputs">{props.damageAndDefects}</p>
                )}
            </FormRow>
            <FormRow
                rowName={"Equipment and Consumables"}
                columnDetailClassName={editable ? "pl-0 pr-0" : ""}
            >
                {editable ? (
                    <DebounceInput
                        className="input-fields text-area-inputs"
                        element={"textarea"}
                        value={props.equipmentAndConsumables}
                        onChange={(event) => {
                            const {value} = event.target;
                            const payload: DutyManagerReportPayload = {
                                ...props,
                                equipmentAndConsumables: value
                            };

                            dispatch(setPayload(payload));
                        }}
                        placeholder="Enter equipment and consumables here"
                    />
                ) : (
                    <p className="mb-0 text-area-inputs">{props.equipmentAndConsumables}</p>
                )}
            </FormRow>
            <FormRow
                rowName={"Operational Comments"}
                columnDetailClassName={editable ? "pl-0 pr-0" : ""}
            >
                {editable ? (
                    <DebounceInput
                        className="input-fields text-area-inputs"
                        element={"textarea"}
                        value={props.operationalComments}
                        onChange={(event) => {
                            const {value} = event.target;
                            const payload: DutyManagerReportPayload = {
                                ...props,
                                operationalComments: value
                            };

                            dispatch(setPayload(payload));
                        }}
                        placeholder="Enter operational comments here"
                    />
                ) : (
                    <p className="mb-0 text-area-inputs">{props.operationalComments}</p>
                )}
            </FormRow>
        </React.Fragment>
    );
};

export default DutyManagerFormDetails;
