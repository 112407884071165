import React from "react";
import {CalendarEntry, CalendarTypeEnum} from "../../../../../../api/grs";
import FormRow from "../../../../../Form/FormRow";

const ReadOnlyEntryDescription = (props: CalendarEntry) => {
    const getFieldName = () => {
        switch (props.calendarType) {
            case CalendarTypeEnum.Frontline:
                return "Vehicle Call Sign";
            case CalendarTypeEnum.Event:
                return "Event Description";
        }
    };
    return (
        <React.Fragment>
            <FormRow rowName={getFieldName()}>
                <p className="mb-0">{props.description}</p>
            </FormRow>
            {props.calendarType === CalendarTypeEnum.Event ? (
                <FormRow rowName={"Subtitle"}>
                    <p className="mb-0">{props.subTitle || "No subtitle added"}</p>
                </FormRow>
            ) : null}
        </React.Fragment>
    );
};

export default ReadOnlyEntryDescription;
