import {
    AssignmentState,
    AssignmentStateStats,
    CalendarMonthSummaryStats
} from "../../../../../api/grs";
import {
    vibrantBluePrimary,
    vibrantGreenPrimary,
    vibrantRedPrimary,
    vibrantYellowPrimary
} from "../../../../../utils/colourUtils";

//Function to get the exact count of each assignment state
export function getAssignmentCountFromStats(
    assignmentStats: AssignmentStateStats[] | undefined,
    assignmentType: AssignmentState
) {
    if (!assignmentStats) {
        return 0;
    }

    const assignment = assignmentStats.find(
        (x: AssignmentStateStats) => x.state === assignmentType
    );

    //Can't find any assignments of this type. Return 0 as a count
    if (!assignment) {
        return 0;
    }

    if (!assignment.count) {
        return 0;
    }

    return assignment.count;
}

export function getDonutChartColourFromPercentage(percentage: number) {
    if (percentage === 100) {
        return vibrantGreenPrimary;
    }
    if (percentage > 66 && percentage <= 99) {
        return vibrantBluePrimary;
    }
    if (percentage > 33 && percentage <= 66) {
        return vibrantYellowPrimary;
    }
    return vibrantRedPrimary;
}

export function getBarPercentageForAssignmentState(
    monthBreakdown: CalendarMonthSummaryStats,
    state: AssignmentState
) {
    const rawValues = getStatsForAllStates(monthBreakdown);
    const percentages = getRoundedPercentages(rawValues);

    switch (state) {
        case AssignmentState.Unassigned:
            return percentages[0];
        case AssignmentState.WaitingAccept:
            return percentages[1];
        case AssignmentState.Accepted:
            return percentages[2];
        case AssignmentState.BriefingAccepted:
            return percentages[3] + percentages[4];
        default:
            return 0;
    }
}

/** Gets values within 100% that have been shared out*/
export function getRoundedPercentages(values: number[]): number[] {
    // We get the raw values and get their percentages.
    const roundedValues = values.map((value: number) => Math.floor(value));

    // Calculate the difference made from adding all the rounded values together and subtracting them from 100
    const diff = 100 - roundedValues.reduce((a: number, b: number) => a + b, 0);

    // Distribute the remaining difference
    return roundedValues.map((value: number, index) => {
        return index < diff ? Math.floor(value) + 1 : Math.floor(value);
    });
}

/** gets all the stats values */
export function getStatsForAllStates(monthBreakdown: CalendarMonthSummaryStats): number[] {
    return [
        calculateFlooredPercentages(monthBreakdown, AssignmentState.Unassigned),
        calculateFlooredPercentages(monthBreakdown, AssignmentState.WaitingAccept),
        calculateFlooredPercentages(monthBreakdown, AssignmentState.Accepted),
        calculateFlooredPercentages(monthBreakdown, AssignmentState.BriefingAccepted),
        calculateFlooredPercentages(monthBreakdown, AssignmentState.Attended)
    ];
}

export function calculateFlooredPercentages(
    monthBreakdown: CalendarMonthSummaryStats,
    assignmentState: AssignmentState
): number {
    const count = getAssignmentCountFromStats(monthBreakdown.stateBreakdown, assignmentState);
    const percent = Math.floor((count / monthBreakdown.numRequired) * 100);
    return isNaN(percent) ? 0 : percent;
}

export function calculateRawPercentage(
    monthBreakdown: CalendarMonthSummaryStats,
    assignmentState: AssignmentState
): number {
    const count = getAssignmentCountFromStats(monthBreakdown.stateBreakdown, assignmentState);
    return (count / monthBreakdown.numRequired) * 100;
}
