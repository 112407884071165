import {useDispatch, useSelector} from "react-redux";
import {useXeroRoutes} from "../../Hooks/useXeroRoutes";
import {useEffect} from "react";
import {setNavigationItems} from "../../../../../store/navigation/actions/NavigationActions";
import {RootStore} from "../../../../../store/Store";

export function useXeroInvoices() {
    const dispatch = useDispatch();
    const {getInvoiceListingRoutes} = useXeroRoutes();
    const invoiceStore = useSelector((state: RootStore) => state.xeroInvoices);

    useEffect(() => {
        dispatch(setNavigationItems(getInvoiceListingRoutes()));
    }, []);

    return {
        invoiceStore
    };
}
