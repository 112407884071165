import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {CalendarGroup} from "../../../api/grs";
import {CALENDAR_GROUP_LIST_STORE} from "../actions/CalendarGroupListActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<CalendarGroup[]>([]);

const calendarGroupListReducer = (
    state: StoreServiceData<CalendarGroup[]> = defaultState,
    action: ServiceActionTypes<CalendarGroup[]>
) => createReducer(state, action, CALENDAR_GROUP_LIST_STORE, () => showErrorToast(action.error));

export default calendarGroupListReducer;
